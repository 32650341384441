<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="col-content show-display">
  <div class="header-content mb-xl-5 mb-3">
    <div class="heading">{{ gestorInfo?.razonSocial }}</div>
  </div>

  
  <form [formGroup]="formGroup">
    <div class="row form">
      <div class="col-sm flex-grow-2">
        <label for="envase">Envase <span class="oblig">* </span></label>
        <div class="custom-mat-field">
          <mat-form-field appearance="outline">
            <mat-select formControlName="envase" id="envase" placeholder="" >
              <mat-option *ngFor="let envase of envases" [value]="envase.id">
                {{envase.descripcion}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controlHasError('envase', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="col-sm flex-grow-2">
        <label for="codigoLer">Acondicionamiento <span class="oblig">* </span></label>
        <div class="custom-mat-field">
          <mat-form-field appearance="outline">
            <mat-select formControlName="acondicionamiento" id="acondicionamiento" placeholder="" >
              <mat-option *ngFor="let acondicionamiento of acondicionamientos" [value]="acondicionamiento.id">
                {{acondicionamiento.descripcion}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controlHasError('acondicionamiento', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row form">
      <div class="col-sm flex-grow-1">
        <label for="cantidadAnual">Capacidad de acondicionamiento anual (t) <span class="oblig">*</span></label>
        <mat-form-field appearance="outline" class="custom-mat-form-field-width">
          <input matInput formControlName="capacidadAnual" id="capacidadAnual" placeholder="Capacidad de acondicionamiento anual (t)"
                 type="text" appTwoDigitDecimalNumber>
          <mat-error *ngIf="controlHasError('capacidadAnual', 'required')">Campo obligatorio</mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm flex-grow-1">
        <label for="fechaDesde">Fecha actividad acondicionamiento <span class="oblig">*</span></label>
        <mat-form-field appearance="outline" class="custom-mat-field-select">
          <input matInput [matDatepicker]="datepicker" formControlName="fechaDesde">
          <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
          <mat-datepicker #datepicker>
          </mat-datepicker>
          <mat-error *ngIf="controlHasError('fechaDesde', 'required')">Campo
            obligatorio</mat-error>
          <mat-error *ngIf="controlHasError('fechaDesde', 'matDatepickerParse')">Formato incorrecto</mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm flex-grow-0">
        <div class="button-group" style="margin-top:26px;">
          <button *ngIf="canAccessAgregar()" style="float:right" type="button" (click)="addPrevision()"
            class="btn btn-primary">Agregar</button>
        </div>
      </div>
      <div class="col-sm flex-grow-0">
        <div class="button-group" style="margin-top:26px;">
          <button *ngIf="canAccessAgregar()" style="float:right" type="button" (click)="uploadPackageReconditioning()"
            class="btn btn-outline-primary">Importar</button>
        </div>
      </div>


    </div>


  </form>

  <div class="datatable-container table-responsive">
    <lib-ge-table [headArray]="headArray" [gridArray]="grid" (actionButton)="action($event)">
    </lib-ge-table>
  </div>


</div>
