<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title font">{{titulo}}</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrar()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="height: 70vh; overflow-y: auto;">
        <form class="form-modal py-3" [formGroup]="formGroup">
            <div class="title-group">Datos de la solicitud</div>
            <div class="row-fields">
                <div class="field field-grow">
                    <label for="usuarioSddr">Usuario SDDR Abierto <span class="oblig">*</span></label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <mat-select formControlName="usuarioSddr" id="usuarioSddr" placeholder="-- Usuario SDDR Abierto --" (selectionChange)="seleccionUsuarioSDDR()">
                            <mat-option *ngFor="let tipo of tiposUsusariosSddr" [value]="tipo.id!+tipo.tipoUsuario!">
                                {{ tipo?.tipoUsuario === 'POSEEDOR' ? 'PO' : 'AD' }} -
                                {{ tipo?.razonSocial }}
                                {{ tipo?.denominacion ? ' - ' + tipo.denominacion : '' }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="controlHasError('usuarioSddr', 'required', formGroup)">Campo obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="field field-25">
                    <div class="label-tooltip">
                        <label for="tipoEnvase">Tipo de envase <span class="oblig">*</span></label>
                        <div class="tool-help"
                            matTooltip="Envase reutilizable: todo envase que ha sido concebido, diseñado y comercializado para realizar múltiples circuitos o rotaciones a lo largo de su ciclo de vida, siendo rellenado o reutilizado con el mismo fin para el que fue concebido."
                            data-placement="top" title="">
                            <span>?</span>
                        </div>
                    </div>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <mat-select formControlName="tipoEnvase" id="tipoEnvase" placeholder="-- Tipo de envase --"
                            (selectionChange)="seleccionTipoEnvase()">
                            <mat-option *ngFor="let tipo of tiposEnvases" [value]="tipo.id">
                                {{ tipo?.descripcion }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="controlHasError('tipoEnvase', 'required', formGroup)">Campo obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- <div class="field field-25">
                    <div class="label-tooltip">
                        <label for="subtipoEnvase">Subtipo de envase <span class="oblig">*</span></label>
                        <div class="tool-help"
                            matTooltip="Si su envase no es de estos tipos no puede estar en el circuito SDDR de ENVALORA."
                            data-placement="top" title="">
                            <span>?</span>
                        </div>
                    </div>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <mat-select formControlName="subtipoEnvase" id="subtipoEnvase"
                            placeholder="-- Subtipo de envase --">
                            <mat-option *ngFor="let tipo of subtiposEnvases" [value]="tipo.id">
                                {{ tipo?.descripcion }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="controlHasError('subtipoEnvase', 'required', formGroup)">Campo obligatorio
                        </mat-error>
                    </mat-form-field>
                </div> -->
            </div>
            <div class="row-fields">
                <div class="field " [ngClass]="this.solicitudId ? 'field-20' : 'field-30'">
                    <label for="numEnvases">Número de envases <span class="oblig">*</span></label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <input type="text" matInput formControlName="numEnvases" id="numEnvases"
                            placeholder="Número de envases" value="" appNumericPoint maxlength="255">
                        <mat-error *ngIf="controlHasError('numEnvases', 'required', formGroup)">Campo obligatorio
                        </mat-error>
                        <mat-error *ngIf="controlHasError( 'numEnvases', 'maxlength', formGroup)">Máximo 255
                            caracteres</mat-error>
                    </mat-form-field>
                </div>
                <div class="field " [ngClass]="this.solicitudId ? 'field-25' : 'field-35'" *ngIf="false">
                    <label for="loteEnvase">Lote envase</label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <input type="text" matInput formControlName="loteEnvase" id="loteEnvase"
                            placeholder="Lote envase" value="" maxlength="255">
                        <mat-error *ngIf="controlHasError( 'loteEnvase', 'maxlength', formGroup)">Máximo 255
                            caracteres</mat-error>
                    </mat-form-field>
                </div>
                <div class="field " [ngClass]="this.solicitudId ? 'field-30' : 'field-grow'">
                    <label for="usoEnvase">¿Uso del envase? <span class="oblig">*</span></label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <mat-select formControlName="usoEnvase" id="usoEnvase" placeholder="-- ¿Uso del envase? --"
                            (selectionChange)="seleccionUsoEnvase()">
                            <mat-option *ngFor="let tipo of usosEnvases" [value]="tipo.id">
                                {{ tipo.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="field field-grow" *ngIf="this.solicitudId">
                    <label>Prefactura</label>
                    <div class="autofactura" *ngIf="solicitud?.autoFactura">
                        <div [matTooltip]="solicitud?.autoFactura?.documentoAutofactura?.nombre">
                            {{ this.obtenerNombreReducido(solicitud?.autoFactura?.documentoAutofactura?.nombre) }}
                        </div>
                        <mat-icon matTooltip="Descargar" (click)="descargarAutofactura(solicitud?.autoFactura)"
                            class="ml-2 color-autofactura pointer-cursor"> download</mat-icon>
                    </div>
                </div>

            </div>

            <!-- Si elige envasado de residuos-->
            <div *ngIf="formGroup?.get('usoEnvase')?.value==='ENVASADO_DE_RESIDUOS'" class="info-envasado">
                <div class="tool-help" style="margin-top:2px;"><span>i</span></div>
                <div>Para envasar residuos que produce mi actividad (no se considera reutilización de envases). No se
                    retorna el depósito pagado.</div>
            </div>

            <!--Si elige España-->
            <div class="row-fields" *ngIf="formGroup?.get('usoEnvase')?.value==='REUTILIZACION_EN_ESPAÑA_SDDRCA'">
                <div class="field field-35">
                    <div class="label-tooltip">
                        <label for="aceptaCondiciones" class="ml-5 mb-0">Aceptación de condiciones <span
                                class="oblig">*</span></label>
                        <div class="tool-help"
                            matTooltip="Acepto las condiciones fijadas por ENVALORA en su SDDR ABIERTO, y me comprometo a mantener la identificación del envase con el símbolo del SDDR ABIERTO de ENVALORA e incorporar el depósito unitario fijado por ENVALORA en la comercialización de productos."
                            data-placement="top" title="">
                            <span>?</span>
                        </div>
                    </div>
                    <mat-radio-group formControlName="aceptaCondiciones" id="aceptaCondiciones"
                        class="custom-mat-form-radio">
                        <mat-radio-button color="primary" [value]="true">Sí</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="controlHasError('aceptaCondiciones', 'required', formGroup)">Campo obligatorio
                    </mat-error>
                </div>

                <div class="field field-grow">
                    <label for="numRegistroProductor">Nº Registro de Productor (propio) </label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <input type="text" matInput formControlName="numRegistroProductor" id="numRegistroProductor"
                            placeholder="Nº Registro de Productor" value="" maxlength="255">
                        <mat-error *ngIf="controlHasError('numRegistroProductor', 'formatoInvalido', formGroup)">
                            Formato requerido: "ENV/AAAA/XXXXXXXXX"</mat-error>
                        <mat-error *ngIf="controlHasError('numRegistroProductor', 'longitudIncorrecta', formGroup)">La
                            longitud del número de registro debe ser de 16 caracteres
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <!--Si elige Exportación-->
            <div class="row-fields" *ngIf="formGroup?.get('usoEnvase')?.value==='REUTILIZACION_EXPORTACION'">
                <div class="field field-grow">
                <label for="reutilizar">¿Dónde va a reutilizar el envase? </label>
                <mat-form-field class="custom-mat-form-field" appearance="outline">
                    <mat-select formControlName="reutilizar" id="reutilizar"
                                placeholder="-- ¿Dónde va a reutilizar el envase? --">
                    <mat-option *ngFor="let lugar of lugaresReutilizacion" [value]="lugar.id">
                        {{ lugar?.name }}
                    </mat-option>
                    </mat-select>
                    <span *ngIf="showClearButton('reutilizar')" tabindex="0" class="ng-clear-wrapper"
                        (click)="clearSelection($event,'reutilizar')">
                                <span aria-hidden="true" class="ng-clear">×</span>
                            </span>
                </mat-form-field>
                </div>
                <div class="field field-30" *ngIf="false">
                <div class="label-tooltip">
                    <label for="aceptaExportacion" class="ml-5 mb-0">Exportación a la Unión Europea o a terceros
                    países <span class="oblig">*</span></label>
                    <div class="tool-help"
                        matTooltip="Certifica que va a reutilizar los envases, que los mismos se encuentran en buenas condiciones para dicha reutilización, y se compromete a eliminar el símbolo asociado al SDDR CA de Envalora, y a no cobrar en nombre de Envalora ningún depósito asociado al siguiente usuario."
                        data-placement="top" title="">
                    <span>?</span>
                    </div>
                </div>
                <mat-radio-group formControlName="aceptaExportacion" id="aceptaExportacion"
                                class="custom-mat-form-radio">
                    <mat-radio-button color="primary" [value]="true">Sí</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="controlHasError('aceptaExportacion', 'required', formGroup)">Campo obligatorio
                </mat-error>
                </div>
                <!--<div class="field field-30">
                <div class="label-tooltip">
                    <label for="aceptaDevolucion" class="ml-5 mb-0">Aceptación de las condiciones de devolución <span class="oblig">*</span></label>
                    <div class="tool-help"
                        matTooltip="Me comprometo a la eliminación de los símbolos del SDDR CA. Acepto que Envalora me retorne los depósitos indicados conforme a la documentación aportada de nº de envases exportados e importe unitario del depósito pagado a mi proveedor."
                        data-placement="top" title="">
                    <span>?</span>
                    </div>
                </div>
                <mat-radio-group formControlName="aceptaDevolucion" id="aceptaDevolucion"
                                class="custom-mat-form-radio">
                    <mat-radio-button color="primary" [value]="true">Sí</mat-radio-button>
                </mat-radio-group>
                    <mat-error *ngIf="controlHasError('aceptaDevolucion', 'required', formGroup)">Campo obligatorio
                    </mat-error>
                </div>-->
                <div class="field field-40">
                    <div class="label-tooltip">
                    <label for="aceptaDevolucion" class="ml-5 mb-0">Certifico que he pagado el depósito por los  envases que voy a reutilizar para la exportación de productos fuera del territorio nacional <span class="oblig">*</span></label>
                    </div>
                    <mat-radio-group formControlName="certificadoPago" id="certificadoPago"
                                    class="custom-mat-form-radio">
                    <mat-radio-button color="primary" [value]="true">Sí</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="controlHasError('certificadoPago', 'required', formGroup)">Campo obligatorio
                    </mat-error>
                </div>
            </div>
            <div class="row-fields" *ngIf="formGroup?.get('usoEnvase')?.value==='REUTILIZACION_EXPORTACION' || formGroup?.get('usoEnvase')?.value==='REUTILIZACION_EN_ESPAÑA_SDDRCC'">
                <div class="field field-grow">
                    <div *ngIf="formGroup?.get('usoEnvase')?.value==='REUTILIZACION_EXPORTACION'">
                        <div class="label-tooltip">
                            <label for="aceptaCondiciones" class="ml-5 mb-0">Me comprometo a eliminar los símbolos del SDDR ABIERTO de ENVALORA.  </label>
                        </div>
                        <div class="label-tooltip">
                            <label for="aceptaCondiciones" class="ml-5 mb-0">Acepto que ENVALORA me retorne los depósitos indicados conforme a la documentación aportada de número de envases exportados e importe unitario del depósito que he pagado a mi proveedor.  </label>
                        </div>
                        <div class="label-tooltip">
                            <label for="aceptaCondiciones" class="ml-5 mb-0">Autorizo a que, en caso de ser necesario, ENVALORA me solicite información, entre la que se incluye el Documento Único Administrativo (DUA), y me audite sobre el pago de los depósitos de los envases reutilizados para la exportación.  <span class="oblig">*</span></label>
                        </div>
                    </div>
                    <div *ngIf="formGroup?.get('usoEnvase')?.value==='REUTILIZACION_EN_ESPAÑA_SDDRCC'">
                        <div class="label-tooltip">
                            <label for="aceptaCondiciones" class="ml-5 mb-0">Me comprometo a eliminar los símbolos del SDDR ABIERTO de ENVALORA de los envases, y a identificarlos con los símbolos aceptados por ENVALORA para el SDDR CERRADO.  </label>
                        </div>
                        <div class="label-tooltip">
                            <label for="aceptaCondiciones" class="ml-5 mb-0">Acepto que ENVALORA me retorne los depósitos indicados, conforme a la información aportada de tipo y nº de envases reutilizados en el SDDR CERRADO de ENVALORA, y el importe unitario del depósito que he pagado a mi proveedor. </label>
                        </div>
                        <div class="label-tooltip">
                            <label for="aceptaCondiciones" class="ml-5 mb-0">Autorizo a que, en caso de ser necesario, ENVALORA me solicite información, y me audite sobre el pago de los depósitos de los envases reutilizados en el SDDR CERRADO de ENVALORA. <span class="oblig">*</span></label>
                        </div>
                    </div>
                    <mat-radio-group formControlName="aceptaCondiciones" id="aceptaCondiciones"
                                    class="custom-mat-form-radio">
                    <mat-radio-button color="primary" [value]="true">Sí</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="controlHasError('aceptaCondiciones', 'required', formGroup)">Campo obligatorio
                    </mat-error>
                </div>
            </div>

            <div class="row-fields" *ngIf="formGroup?.get('usoEnvase')?.value==='REUTILIZACION_OTROS_SISTEMAS_SIRAP'">
                <div class="field field-grow">
                    <div class="label-tooltip">
                        <label for="aceptaCondiciones" class="ml-5 mb-0">Me comprometo a la eliminación de los símbolos del SDDR ABIERTO de ENVALORA.  <span class="oblig">*</span></label>
                    </div>
                    <mat-radio-group formControlName="aceptaCondiciones" id="aceptaCondiciones"
                                    class="custom-mat-form-radio">
                    <mat-radio-button color="primary" [value]="true">Sí</mat-radio-button>
                    </mat-radio-group>
                    <mat-error *ngIf="controlHasError('aceptaCondiciones', 'required', formGroup)">Campo obligatorio
                    </mat-error>
                </div>
            </div>

        </form>
        <!--si elige Exportación-->
        <div *ngIf="formGroup?.get('usoEnvase')?.value==='REUTILIZACION_EXPORTACION' || formGroup?.get('usoEnvase')?.value==='REUTILIZACION_EN_ESPAÑA_SDDRCC'" style="margin-top: 10px">
            <form class="form-modal py-3" [formGroup]="formGroupFacturas">
                <div class="title-group" *ngIf="false">Facturas en los que se le ha cobrado el importe de los depósitos de los envases
                    reutilizables</div>
                <div class="row-fields">
                    <div class="field field-35">
                        <label for="importeDeposito">Importe depósito/unidad (€/ud)<span class="oblig">*</span></label>
                        <mat-form-field class="custom-mat-form-field" appearance="outline">
                            <input type="text" matInput formControlName="importeDeposito" id="importeDeposito"
                                placeholder="Importe depósito/unidad" value="" appTwoDigitDecimalNumber maxlength="255"
                                readonly>
                            <mat-error *ngIf="controlHasError('importeDeposito', 'required', formGroupFacturas)">Campo
                                obligatorio
                            </mat-error>
                            <mat-error
                                *ngIf="controlHasError( 'importeDeposito', 'maxlength', formGroupFacturas)">Máximo 255
                                caracteres</mat-error>
                        </mat-form-field>
                    </div>
                </div>
                <!--
                <div class="row-fields" style="border: 1px solid #e4e4e4; padding:20px;" *ngIf="false">
                    <div class="field field-35">
                        <div class="label-tooltip">
                            <label for="loteEnvase">Número de factura depósito <span class="oblig">*</span></label>
                            <div class="tool-help"
                                matTooltip="Deberá adjunta factura o certificado acreditativo de haber pagado ese depósito unitario de al menos el mismo número de envases para los que solicita la devolución del depósito."
                                data-placement="top" title="">
                                <span>?</span>
                            </div>
                        </div>
                        <mat-form-field class="custom-mat-form-field" appearance="outline">
                            <input type="text" matInput formControlName="numFacturaDeposito" id="numFacturaDeposito"
                                placeholder="Número de factura depósito" value="" maxlength="255">
                            <mat-error *ngIf="controlHasError('numFacturaDeposito', 'required', formGroupFacturas)">
                                Campo obligatorio
                            </mat-error>
                            <mat-error
                                *ngIf="controlHasError('numFacturaDeposito', 'pattern', formGroupFacturas)">Formato
                                incorrecto, debe ser numérico
                            </mat-error>
                            <mat-error
                                *ngIf="controlHasError( 'numFacturaDeposito', 'maxlength', formGroupFacturas)">Máximo
                                255 caracteres</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="field field-grow">
                        <div class="attach">
                            <div>
                                <button mat-button aria-labe="Adjuntar documento" class="adjuntar-btn" type="button"
                                    (click)="file.click()" [disabled]="adjuntosBlobFacturas!.length>0">
                                    Adjuntar documento
                                </button>
                                <label class="sr-only" for="adjuntar">Adjuntar documento </label>
                                <input #file type="file"
                                    (change)="file && file.files && attachAdjuntoFacturas(file.files[0])" class="hide"
                                    id="adjuntar" name="adjuntar">

                            </div>
                            <div class="tool-help"
                                matTooltip="Deberá adjunta factura o certificado acreditativo de haber pagado ese depósito unitario de al menos el mismo número de envases para los que solicita la devolución del depósito."
                                data-placement="top" title="">
                                <span>?</span>
                            </div>
                            <div>
                                <div *ngFor="let archivo of adjuntosBlobFacturas" class="archivo">
                                    <div><span class="sprite-icon ico-adjuntar"></span><span
                                            class="nombre-archivo">{{archivo?.name}}
                                        </span> </div>
                                    <mat-icon class="color-close close-icon c-pointer" matTooltip="Descartar adjunto"
                                        matTooltipPosition="above" (click)="descartarFac(archivo)">close</mat-icon>
                                </div>
                            </div>
                        </div>
                        <mat-error *ngIf="!hayFactura">Debe seleccionar una factura </mat-error>
                    </div>
                    <div class="field field-10">
                        <div class="button-group" style="margin-top:8px; font-weight: bold; margin-top:25px;">
                            <button type="button" (click)="anadirFactura()" class="btn btn-primary">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>
                -->

            </form>
            <!--
            <div class="row form" *ngIf="false">
                <div class="col-sm-12">
                    <lib-ge-table [headArray]="headArrayFacturas" [gridArray]="gridFacturas"
                        (actionButton)="actionFacturas($event)">
                    </lib-ge-table>
                </div>
            </div>
            -->
        </div>
        <!--si elige Exportación-->
        <!--
        <div *ngIf="false" style="margin-top:30px">
            <form class="form-modal py-3" [formGroup]="formGroupDocumentos">
                <div class="title-group">Documentos acreditación exportación</div>
                <div class="row-fields" style="border: 1px solid #e4e4e4; padding:20px;" *ngIf="!soloLectura">
                    <div class="field field-35">
                        <div class="label-tooltip">
                            <label for="loteEnvase">Tipo documento <span class="oblig">*</span></label>
                            <div class="tool-help"
                                matTooltip="Adjunte Albarán, CMR, DUA o factura que acredite la exportación. Puede borrar toda información comercial que contenga el documento."
                                data-placement="top" title="">
                                <span>?</span>
                            </div>
                        </div>
                        <mat-form-field class="custom-mat-form-field" appearance="outline">
                            <input type="text" matInput formControlName="tipoDocumento" id="tipoDocumento"
                                placeholder="Tipo documento" value="" maxlength="255">
                            <mat-error *ngIf="controlHasError('tipoDocumento', 'required', formGroupDocumentos)">Campo
                                obligatorio
                            </mat-error>
                            <mat-error
                                *ngIf="controlHasError( 'tipoDocumento', 'maxlength', formGroupDocumentos)">Máximo 255
                                caracteres</mat-error>
                        </mat-form-field>
                    </div>
                    <div class="field field-grow">
                        <div class="attach">
                            <div>
                                <button mat-button aria-labe="Adjuntar documento" class="adjuntar-btn" type="button"
                                    (click)="file2.click()" [disabled]="adjuntosBlobDocumentos!.length>0">
                                    Adjuntar documento
                                </button>
                                <label class="sr-only" for="adjuntar">Adjuntar documento </label>
                                <input #file2 type="file"
                                    (change)="file2 && file2.files && attachAdjuntoDocumentos(file2.files[0])"
                                    class="hide" id="adjuntar" name="adjuntar">
                            </div>
                            <div>
                                <div *ngFor="let archivo of adjuntosBlobDocumentos" class="archivo">
                                    <div><span class="sprite-icon ico-adjuntar"></span><span
                                            class="nombre-archivo">{{archivo?.name}}
                                        </span>
                                    </div>
                                    <mat-icon class="color-close close-icon c-pointer" matTooltip="Descartar adjunto"
                                        matTooltipPosition="above" (click)="descartarDoc(archivo)">close</mat-icon>
                                </div>
                            </div>
                        </div>
                        <mat-error *ngIf="!hayDocumento">Debe seleccionar un documento </mat-error>
                    </div>
                    <div class="field field-10">
                        <div class="button-group" style="margin-top:8px; font-weight: bold; margin-top:25px;">
                            <button type="button" (click)="anadirDocumento()" class="btn btn-primary">
                                <mat-icon>add</mat-icon>
                            </button>
                        </div>

                    </div>
                </div>
            </form>
            <div class="row form">
                <div class="col-sm-12">
                    <lib-ge-table [headArray]="headArrayDocumentos" [gridArray]="gridDocumentos"
                        (actionButton)="actionDocumentos($event)">
                    </lib-ge-table>
                </div>
            </div>
        </div>
        -->
        <!--si estado solicitud >1-->
        <form *ngIf="solicitud?.estado?.id>1" class="form-modal py-3" [formGroup]="formGroupInfoSolicitud"
            style="margin-top:30px">
            <div class="title-group">Información solicitud</div>
            <div class="row-fields">
                <div class="field field-35">
                    <label for="usuario">Usuario </label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <input type="text" matInput formControlName="usuario" id="usuario" value="">
                    </mat-form-field>
                </div>
                <div class="field field-grow">
                    <label for="fecha">Fecha </label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <input type="text" matInput formControlName="fecha" id="fecha" value="">
                    </mat-form-field>
                </div>
                <div class="field field-35">
                    <label for="estado">Estado </label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <input type="text" matInput formControlName="estado" id="estado" value="">
                    </mat-form-field>
                </div>
            </div>
        </form>


    </div>
    <div class="modal-footer actions">
        <div class="note"><span>* Campos Obligatorios</span></div>
        <div class="button-group">
            <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
                aria-label="Cancelar">Cancelar</button>
            <button *ngIf="canAccessGuardar()&&(solicitud?.estado?.id===1 || !solicitudId)" type="button"
                class="btn btn-primary" data-dismiss="modal" (click)="guardarBorrador()" aria-label="Guardar">Guardar
                borrador</button>
            <button *ngIf="canAccessGuardarEnviar()&&(solicitud?.estado?.id===1 || !solicitudId)" type="button"
                class="btn btn-primary" data-dismiss="modal" (click)="guardarEnviar()" aria-label="Guardar">Guardar y
                enviar</button>
        </div>
    </div>
</div>