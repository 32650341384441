/**
 * Core API
 * Core API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface JobModel { 
    id?: number;
    status?: JobModel.StatusEnum;
}
export namespace JobModel {
    export type StatusEnum = 'EN_EJECUCION' | 'FINALIZADO' | 'ERROR';
    export const StatusEnum = {
        EnEjecucion: 'EN_EJECUCION' as StatusEnum,
        Finalizado: 'FINALIZADO' as StatusEnum,
        Error: 'ERROR' as StatusEnum
    };
}


