<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<form [formGroup]="formGroup">
  <div class="row-fields">
    <div class="field field-100">
      <label for="razonSocial">Razón social <span class="oblig">*</span></label>
      <mat-form-field appearance="outline">
        <input formControlName="razonSocial" id="razonSocial" placeholder="Razón social" matInput type="text" maxlength="255">
        <mat-error *ngIf="controlHasError( formGroup, 'razonSocial', 'maxlength')">Máximo 255 caracteres</mat-error>
        <mat-error *ngIf="controlHasError(formGroup,'razonSocial', 'required')">Campo obligatorio</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row-fields">
    <div class="field field-33">
      <label for="tipodoc">Tipo documento <span class="oblig">*</span></label>
      <mat-form-field appearance="outline" class="custom-mat-form-field-width">
        <mat-select formControlName="tipoDocumento" (selectionChange)="tipoDocumentoSel($event.value)" id="tipodoc"
          placeholder="Tipo documento">
          <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.id">
            {{tipo.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="controlHasError(formGroup,'tipoDocumento', 'required')">Campo obligatorio</mat-error>
      </mat-form-field>
    </div>
    <div class="field field-33">
      <label for="codigoDocumento">Documento <span class="oblig">*</span></label>
      <div class="custom-mat-field">
        <mat-form-field appearance="outline" class="custom-mat-form-field-width">
          <input type="text" matInput formControlName="codigoDocumento" id="codigoDocumento" placeholder="Documento"
            value="" oninput="this.value = this.value.toUpperCase()" maxlength="15">
          <mat-error *ngIf="controlHasError( formGroup, 'codigoDocumento', 'maxlength')">Máximo 15 caracteres</mat-error>
          <mat-error *ngIf="controlHasError(formGroup,'codigoDocumento', 'pattern')">Formato incorrecto
          </mat-error>
          <mat-error *ngIf="controlHasError(formGroup,'codigoDocumento', 'required')">Campo obligatorio
          </mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="field field-33"></div>
  </div>
  <div class="row-fields">
    <div class="field field-100">
      <label for="cnae1">CNAE principal <span class="oblig">*</span></label>
      <div class="custom-mat-field">
        <lib-ge-select-pagination class="lib-ge-select" [endPointFilter]="cnaeEndpoint" [updateList]="updateList"
          [descripcion]="getCnaDescription" [disabled]="flagDisabledForm"
          [formControlNameSelect]="getFormControl('cnae1')" [objQueryName]="getCnaeObjectQuery"
          [placeHolder]="'CNAE principal'" [customClass]="rightHolder('cnae1')">
        </lib-ge-select-pagination>
        <div class="ge-select-error">
          <mat-error class="ge-select-mat-error" *ngIf="selPaginatorHasError('cnae1')">Campo obligatorio
          </mat-error>
        </div>
      </div>
    </div>
  </div>
  <div class="row-fields">
    <div class="field field-100">
      <label for="cnae2">CNAE secundario</label>
      <div class="custom-mat-field" style="margin-bottom: 20px;">
        <lib-ge-select-pagination class="lib-ge-select" [endPointFilter]="cnaeEndpoint" [updateList]="updateList"
          [descripcion]="getCnaDescription" [disabled]="flagDisabledForm"
          [formControlNameSelect]="getFormControl('cnae2')" [objQueryName]="getCnaeObjectQuery"
          [placeHolder]="'CNAE secundario'" [customClass]="rightHolder('cnae2')">
        </lib-ge-select-pagination>
      </div>
    </div>
  </div>
  <div class="row-fields">
    <div class="field field-50">
      <label for="denominacionCentro">Denominación del centro <span class="oblig">*</span></label>
      <mat-form-field appearance="outline">
        <input formControlName="denominacionCentro" id="denominacionCentro" placeholder="Denominación del centro"
          matInput type="text" maxlength="255">
        <mat-error *ngIf="controlHasError( formGroup, 'denominacionCentro', 'maxlength')">Máximo 255 caracteres</mat-error>
        <mat-error *ngIf="controlHasError(formGroup,'denominacionCentro', 'required')">Campo obligatorio
        </mat-error>
      </mat-form-field>
    </div>
    <div class="field field-50">
      <label for="iban">IBAN del poseedor</label>
      <mat-form-field appearance="outline">
        <input formControlName="iban" id="iban" placeholder="IBAN cuenta adherido" matInput type="text"
               (input)="addSpaceFormatIban($event)">
        <mat-error *ngIf="controlHasError(formGroup,'iban', 'invalidIban')">El IBAN debe comenzar con "ES"</mat-error>
        <mat-error *ngIf="controlHasError(formGroup,'iban', 'invalidLength')">El IBAN debe tener exactamente 24 caracteres"</mat-error>
        <mat-error *ngIf="controlHasError(formGroup,'iban', 'invalidIbanFormat')">Formato requerido ESXX XXXX XXXX XXXX XXXX XXXX</mat-error>
      </mat-form-field>
    </div>
  </div>
  <!--TODO: geoposicionamiento-->
  <div class="row-fields"
    [style]="canUpdateBaja() ? 'justify-content: space-between;' : 'justify-content: start; gap:10px;'">
    <div class="field field-25">
      <label for="nima">NIMA <span class="oblig">*</span></label>
      <mat-form-field appearance="outline">
        <input formControlName="nima" id="nima" placeholder="NIMA" matInput type="text" maxlength="10">
        <mat-error *ngIf="controlHasError( formGroup, 'nima', 'maxlength')">Máximo 10 caracteres</mat-error>
        <mat-error *ngIf="controlHasError(formGroup,'nima', 'required')">Campo obligatorio</mat-error>
        <mat-error *ngIf="controlHasError(formGroup,'nima', 'pattern')">Formato incorrecto</mat-error>
      </mat-form-field>
    </div>
    <div class="field field-25">
      <label for="fechaFirmaAcuerdo">Fecha firma acuerdo</label>
      <mat-form-field appearance="outline">
        <input formControlName="fechaFirmaAcuerdo" id="fechaFirmaAcuerdo" matInput type="text" readonly>
      </mat-form-field>
    </div>
    <div class="field field-25" *ngIf="canUpdateBaja()">
      <label for="fechaBaja">Fecha baja</label>
      <mat-form-field appearance="outline">
        <input matInput [matDatepicker]="datepicker" formControlName="fechaBaja">
        <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker>
        </mat-datepicker>
        <mat-error *ngIf="controlHasError(formGroup,'fechaBaja', 'matDatepickerParse')">Formato incorrecto
        </mat-error>
      </mat-form-field>
    </div>
    <div class="field field-25" *ngIf="canUpdateBaja()">
      <label for="fechaBajaEnvanet">Fecha baja Envanet</label>
      <mat-form-field appearance="outline">
        <input formControlName="fechaBajaEnvanet" id="fechaBajaEnvanet" matInput type="text" readonly>
      </mat-form-field>
    </div>

  </div>

  <div class="row-fields">
    <div class="field field-100">
      <label for="autorizacionComuniaciones">La EMPRESA autoriza a ENVALORA a remitirle información de actividades
        propias o de
        terceros colaboradores de SCRAP <span class="oblig">*</span></label>
      <mat-radio-group formControlName="autorizacionComuniaciones" id="autorizacionComuniaciones"
        class="custom-mat-form-radio">
        <mat-radio-button color="primary" [value]="true">Sí</mat-radio-button>
        <mat-radio-button color="primary" [value]="false">No</mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="controlHasError(formGroup,'autorizacionComuniaciones', 'required')">Campo obligatorio
      </mat-error>
    </div>
  </div>

  <div class="row-fields">
    <div *ngIf="this.canViewAgreement()" class="field field-100">
      <label for="contratoGestor">¿Dispone de un contrato de gestión de residuos con algún gestor?<span class="oblig">*</span></label>
      <mat-radio-group formControlName="contratoGestor" id="contratoGestor"
        class="custom-mat-form-radio" [disabled]="!this.canEditAgreement()">
        <mat-radio-button color="primary" [value]="true">Sí</mat-radio-button>
        <mat-radio-button color="primary" [value]="false">No</mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="controlHasError(formGroup,'contratoGestor', 'required')">Campo obligatorio
      </mat-error>
    </div>
    <div *ngIf="this.canViewAgreement()" class="field field-100">
      <label for="acuerdoGestorEnvalora">¿Requiere gestionar la recogida de algún residuo y no dispone de un contrato de gestión de residuos con un gestor?<span class="oblig">*</span></label>
      <mat-radio-group formControlName="acuerdoGestorEnvalora" id="acuerdoGestorEnvalora"
        class="custom-mat-form-radio" [disabled]="!this.canEditAgreement()">
        <mat-radio-button color="primary" [value]="true">Sí</mat-radio-button>
        <mat-radio-button color="primary" [value]="false">No</mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="controlHasError(formGroup,'acuerdoGestorEnvalora', 'required')">Campo obligatorio
      </mat-error>
    </div>
  </div>

</form>

<div class="title-name pt-2" style="font-size: 1.3rem; font-weight: 700;">Introduzca el nº de Registro de productor de producto de sus proveedores</div>

<form class="form-search py-3" [formGroup]="iteracionFormGroup">
  <!--ano dentro o fuera-->
  <div class="row-fields" style="justify-content:start">
    <div class="field field-25">
      <div style="display: flex; align-items: center;">
        <label for="anio">Año</label>
        <div class="tool-help" matTooltip="Año en el que se realiza la previsión de residuos y número de Registro de Productores de Producto a los que se compran productos envasados en el año seleccionado"
             data-placement="top" title="" style="margin-bottom: 5px">
          <span>?</span>
        </div>
      </div>
      <mat-form-field appearance="outline">
        <mat-select formControlName="anio" id="anio" placeholder="Año" (selectionChange)="anioSelected()">
          <mat-option *ngFor="let anio of anios" [value]="anio.id">
            {{anio.id}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-error *ngIf="controlHasError(iteracionFormGroup,'anio', 'required')">Campo obligatorio</mat-error>
    </div>
    <div class="btn-importar"
      *ngIf="canAccessUpdate() && iteracionFormGroup.get('anio')?.value===anioActual || iteracionFormGroup.get('anio')?.value === anioActual+1">
      <button type="button" class="btn btn-outline-primary" (click)="uploadProductionNum()"
        matTooltip="Importar números registro productor ">
        <mat-icon aria-hidden="false" aria-label="importar" fontIcon="file_upload"></mat-icon>
      </button>
    </div>
  </div>
  <div class="row-fields" style="border: 1px solid #e4e4e4; padding:20px;"
    *ngIf="canAccessUpdate() && iteracionFormGroup.get('anio')?.value===anioActual || iteracionFormGroup.get('anio')?.value === anioActual+1">
    <div class="field field-75">
      <label for="numRegistroProductor">Nº registro de productor</label>
      <mat-form-field appearance="outline">
        <input formControlName="numRegistroProductor" id="numRegistroProductor" placeholder="ENV/AAAA/XXXXXXXXX"
          matInput type="text" maxlength="18" (input)="agregarBarraAutomaticamente($event)" (focus)="agregarPrefijoSiEstaVacio($event)">
        <mat-error *ngIf="controlHasError(iteracionFormGroup, 'numRegistroProductor', 'required')">Campo
          obligatorio</mat-error>
        <mat-error *ngIf="controlHasError(iteracionFormGroup, 'numRegistroProductor', 'formatoInvalido')">Formato requerido: "ENV/AAAA/XXXXXXXXX"</mat-error>
        <mat-error *ngIf="controlHasError(iteracionFormGroup, 'numRegistroProductor', 'longitudIncorrecta')">La
          longitud del número de registro debe ser de 16 caracteres
        </mat-error>
      </mat-form-field>

    </div>
    <div class="field field-25">
      <div class="button-group" style="margin-top:8px;">
        <button type="button" (click)="addNumRegistro()" class="btn btn-primary">Agregar</button>
      </div>
    </div>
  </div>
</form>
<div class="row form">
  <div class="col-sm-12">
    <lib-ge-table [headArray]="headArray" [gridArray]="grid" (actionButton)="action($event)">
    </lib-ge-table>
  </div>
</div>
<form class="form-search py-3" [formGroup]="porcentajeFormGroup">
  <div class="row-fields">
    <div class="field field-33">
      <label for="porcAdheridos">Porcentaje de adheridos</label>
      <mat-form-field appearance="outline">
        <input formControlName="porcAdheridos" id="porcAdheridos" matInput type="text" readonly>
      </mat-form-field>
    </div>
  </div>
  <mat-error *ngIf="mostrarMensajePorc">Debe introducir al menos un adherido de ENVALORA.</mat-error>
</form>
