import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {
  DeclaracionAdheridoControllerService,
  DeclaracionAdheridoDTO,
  DeclaracionCentroDTO,
  DeclaracionEnvaseControllerService,
  DeclaracionEnvaseFilterDTO,
} from 'api-rest';
import { PackageDialogComponent } from '../package-dialog/package-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { PageDTODeclaracionEnvaseDTO } from 'api-rest';
import { DeclaracionEnvaseDTO } from 'api-rest';
import { CombosService } from '../../../services/combos.service';
import Swal from 'sweetalert2';
import { DownloadService } from '../../../services/download.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { FormatCurrencyPipe } from '../../../share/components/pipe/FormatCurrency.pipe';

@Component({
  selector: 'lib-package-center-packaging',
  templateUrl: './package-center-packaging.component.html',
  styleUrls: ['./package-center-packaging.component.scss'],
})
export class PackageCenterPackagingComponent implements OnInit {
  @Input() centro!: DeclaracionCentroDTO;
  @Output() actualizarImportar: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() actualizarDeclaracion: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Input() declaracionAdherido!: DeclaracionAdheridoDTO;

  formGroup: FormGroup;
  tipoEnvasesEndpoint =
    this.environment.urlBackCore + 'api/v1/core/tipoEnvases';
  updateList: boolean = false;

  gridArray: DeclaracionEnvaseDTO[] = [];

  pageNumber: number = 0;
  pageSize: number = 10;
  totalElements: number = 0;
  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  totalSearch: any = 0;

  headArray: any = [
    {
      Head: 'Denominación',
      FieldName: 'denominacion',
      Tooltip: true,
    },
    {
      Head: 'Tipo envase',
      FieldName: 'tipoEnvase',
      Attribute: 'descripcion',
      Tooltip: true,
    },
    {
      Head: 'Peligrosidad',
      FieldName: 'peligrosidad',
      renderValue: (item: DeclaracionEnvaseDTO) =>
        item.peligrosidad ? 'SI' : 'NO',
    },
    {
      Head: 'Tipología',
      FieldName: 'tipologia',
    },
    {
      Head: 'Unidades',
      FieldName: 'unidadesTotales',
      renderValue: (item: any) =>{
        if ([3, 4].includes(item.declaracionCentro?.categoriaEnvase?.id!)) {
          return this.formatCurrencyPipe.transform(item.unidadesNuevas);
        } else {
          return this.formatCurrencyPipe.transform(item.unidadesTotales);
        }
      },
    },
    {
      Head: 'Peso unitario (Kg)',
      FieldName: 'pesoUnitario',
    },
    {
      Head: 'Peso total (Kg)',
      FieldName: 'pesoTotal',
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Detalle',
          show: { params: 'canAccessView', accion: 'view' },
        },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
      ],
      width: '2',
      permanent: true,
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    @Inject('environment') private environment: any,
    private declaracionEnvaseControllerSrv: DeclaracionEnvaseControllerService,
    private declaracionAdheridoControllerSrv: DeclaracionAdheridoControllerService,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private comboSrv: CombosService,
    private downloadSrv: DownloadService,
    @Inject('EmpusaAuthenticationService')
    public authService: EmpusaAuthenticationService,
    private formatCurrencyPipe: FormatCurrencyPipe
  ) {
    this.formGroup = this.formBuilder.group({
      denominacion: [{ value: null, disabled: false }, Validators.maxLength(255)],
      tipoEnvase: [{ value: null, disabled: false }],
    });
  }
  ngOnInit(): void {
    this.buscar();
  }

  limpiar(): void {
    this.formGroup.reset();
  }

  exportar(): void {
    let filtro: DeclaracionEnvaseFilterDTO = this.obtenerFiltro();
    this.spinnerSrv.loadingShow();
    this.declaracionEnvaseControllerSrv.exportCSV1(filtro).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.downloadSrv.downloadBlob(response, 'envases.csv');
      },
      error: (err: any) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al exportar los envases',
          'error'
        );
      },
    });
  }

  obtenerFiltro(): DeclaracionEnvaseFilterDTO {
    // filtrado
    const textFilter = (value: any) =>
      value?.trim() ? [`ci(${value?.trim()})`] : undefined;
    let filtro: DeclaracionEnvaseFilterDTO = {};
    if (this.centro?.id) {
      filtro.declaracionCentro = { id: [this.centro.id!.toString()] };
    }
    if (this.formGroup.get('tipoEnvase')?.value) {
      filtro.tipoEnvase = {
        id: [this.formGroup.get('tipoEnvase')?.value.id.toString()],
      };
    }
    filtro.denominacion = textFilter(this.formGroup.get('denominacion')?.value);

    return filtro;
  }
  buscar(): void {
    // filtrado
    let filtro: DeclaracionEnvaseFilterDTO = this.obtenerFiltro();

    // paginación
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;
    let pag = {
      page: page,
      size: this.pageSize,
      sort: [],
    };
    this.spinnerSrv.loadingShow();
    this.declaracionEnvaseControllerSrv.findAll4(filtro, pag).subscribe({
      next: (response: PageDTODeclaracionEnvaseDTO) => {
        this.spinnerSrv.loadingHide();
        this.gridArray = this.canAccessAll(response?.datos!);
        this.flagSearch = response.datos?.length != 0 ? true : false;
        this.totalSearch = this.gridArray.length;
        this.totalElements = response?.paginacion?.totalElements!;
        this.flagSearch
          ? this.actualizarImportar.emit(false)
          : this.actualizarImportar.emit(true);
      },
      error: (err: any) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al buscar los envases',
          'error'
        );
      },
    });
  }

  action(item: any) {
    if (item.nameButton == 'view') {
      this.abrirModalEnvase(item, false);
    } else if (item.nameButton == 'delete') {
      this.confirmacionEliminar(item);
    }
  }
  confirmacionEliminar(element?: DeclaracionEnvaseDTO): void {
    Swal.fire({
      text: `¿Desea eliminar el envase?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed && element?.id) {
        this.eliminarEnvase(element?.id);
      }
    });
  }

  eliminarEnvase(id: number): void {
    this.spinnerSrv.loadingShow();
    this.declaracionEnvaseControllerSrv.deleteById3(id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.buscar();
        this.snackBarSrv.showSnackBar(
          'El envase se ha borrado correctamente',
          'success'
        );
        this.actualizarDeclaracion.emit(true);
      },
      error: (err: any) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al borrar el envase',
          'error'
        );
      },
    });
  }

  abrirModalEnvase(item: any, titulo: boolean): void {
    const dialogRef = this.dialog.open(PackageDialogComponent, {
      width: '1460px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: {
        item: item,
        declaracionCentro: this.centro,
        nuevoEnvase: titulo,
        declaracionAdherido: this.declaracionAdherido,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.limpiar();
        this.buscar();
        this.actualizarResumenEconomico();
      }
    });
  }

  actualizarResumenEconomico() {
    this.spinnerSrv.loadingShow();
    this.declaracionAdheridoControllerSrv
      .actualizarResumenEconomico(this.declaracionAdherido.id!)
      .subscribe({
        next: (res) => {
          this.declaracionAdherido.contriRapAnioCursoAbierto =
            res.contriRapAnioCursoAbierto;
          this.declaracionAdherido.contriRapAnioAnteriorAbierto =
            res.contriRapAnioAnteriorAbierto;
          this.declaracionAdherido.depoRapAnioCursoAbierto =
            res.depoRapAnioCursoAbierto;
          this.declaracionAdherido.depoRapAnioAnteriorAbierto =
            res.depoRapAnioAnteriorAbierto;

          this.declaracionAdherido.contriRapAnioCursoCerrado =
            res.contriRapAnioCursoCerrado;
          this.declaracionAdherido.contriRapAnioAnteriorCerrado =
            res.contriRapAnioAnteriorCerrado;
          this.declaracionAdherido.depoRapAnioCursoCerrado =
            res.depoRapAnioCursoCerrado;
          this.declaracionAdherido.depoRapAnioAnteriorCerrado =
            res.depoRapAnioAnteriorCerrado;

          this.declaracionAdherido.contriRapAnioCursoSoloUso =
            res.contriRapAnioCursoSoloUso;
          this.declaracionAdherido.contriRapAnioAnteriorSoloUso =
            res.contriRapAnioAnteriorSoloUso;

          this.declaracionAdherido.reqActEconomica = res.reqActEconomica;

          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'El resumen económico se ha actualizado correctamente',
            'success'
          );
          this.actualizarDeclaracion.emit(true);
        },
        error: (error) => {
          if (error.error.detail) {
            this.snackBarSrv.showSnackBar(error.error.detail, 'error');
          } else {
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al actualizar el resumen económico',
              'error'
            );
          }
          this.spinnerSrv.loadingHide();
        },
      });
  }

  getFormControl(): FormControl {
    return this.formGroup.get('tipoEnvase') as FormControl;
  }
  public filterList(data: any) {}

  renderPage(event: number) {
    // console.log('renderpage', event);
    this.pageNumber = event;
    if (this.flagSearch) {
      this.buscar();
    }
  }

  onItemsPerPageChange() {
    console.log('onintemspagechange');
    this.pageSize = this.selectedItemsPerPage;
    this.buscar();
  }

  canAccessNewEnv(): boolean {
    return this.authService.can('dec_envases-envases', 'access-b-envases');
  }

  canAccessExportEnv(): boolean {
    return this.authService.can('dec_envases-envases', 'update-exportar');
  }

  canAccessAll(list: any) {
    const resultView = this.authService.can('dec_envases-envases', 'view');
    const resultDelete = this.authService.can('dec_envases-envases', 'delete');

    const listPermission = list.map((objeto: any) => {
      return {
        ...objeto,
        canAccessView: resultView,
        canAccessDelete: resultDelete && this.canAccessEditAllGlobal(),
        pesoUnitario: this.formatCurrencyPipe.transform(objeto.pesoUnitario),
        pesoTotal: this.formatCurrencyPipe.transform(objeto.pesoTotal),
      };
    });

    return listPermission;
  }

  convertToComma(value: any): string {
    if (typeof value === 'number') {
      let stringValue = value.toFixed(5).replace('.', ',');
      stringValue = stringValue.replace(/,?0+$/, '');
      return stringValue;
    } else if (typeof value === 'string') {
      return value.replace('.', ',').replace(/,?0+$/, '');
    } else {
      return '0';
    }
  }

  canAccessEditAllGlobal(): boolean {
    return (
      this.authService.can('dec_envases', 'update') &&
      this.declaracionAdherido?.estadoDeclaracion?.id == 0
    );
  }
}
