import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  DocumControllerService,
  FacturaGestorAcuerdoControllerService,
  GestoresResiduosDTO,
} from 'api-rest';
import { SnackbarService } from '../../services/snackbar.service';
import { SpinnerService } from '../../services/spinner.service';
import moment from 'moment';

@Component({
  selector: 'lib-managers-agreements-invoice-document-dialog',
  templateUrl: './managers-agreements-invoice-document-dialog.component.html',
  styleUrls: ['./managers-agreements-invoice-document-dialog.component.scss'],
})
export class ManagersAgreementsInvoiceNewDocumentDialogComponent {
  formGroup!: FormGroup;
  gestor!: GestoresResiduosDTO;
  adjuntosBlob: Array<File> = [];
  botonEstado: boolean = false;
  flagTooltip: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<ManagersAgreementsInvoiceNewDocumentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public spinnerSrv: SpinnerService,
    private snackbarSrv: SnackbarService,
    private facturaGestorAcuerdoControllerService: FacturaGestorAcuerdoControllerService
  ) {
    this.formGroup = this.formBuilder.group({
      numeroFacturaGestor: [{ value: null, disabled: false }, { validators: Validators.compose([Validators.required])}],
      fechaFactura: [{ value: null, disabled: false }, { validators: Validators.compose([Validators.required])}]
    });
  }

  ngOnInit(): void {}

  cargarTooltip() {}

  cerrar(): void {
    this.dialogRef.close(null);
  }

  guardar(): void {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.valid) {
      if (this.adjuntosBlob?.length > 0) {
        let id = this.data.itemOpen.id;
        let numeroFacturaGestor =
          this.formGroup.controls['numeroFacturaGestor'].value! ?? null;
        let fechaFactura = moment(
          new Date(this.formGroup.controls['fechaFactura'].value)
        ).format('YYYY-MM-DD');
        this.setBotonEstado(true);
        console.log('id', id)
        this.facturaGestorAcuerdoControllerService
          .uploadDocFactura(id, numeroFacturaGestor, fechaFactura, this.adjuntosBlob[0])
          .subscribe({
            next: () => {
              this.snackbarSrv.showSnackBar(
                'Documento subido correctamente',
                'success'
              );
              this.dialogRef.close(true);
            },
            error: () => {
              this.setBotonEstado(false);
            },
            complete: () => {
              this.spinnerSrv.loadingHide();
            },
          });
      } else {
        this.snackbarSrv.showSnackBar('Adjunte documento', 'error');
        return;
      }
    }
  }
  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }

  attachAdjunto(archivo: File | null): void {
    if (archivo != null) {
      if (archivo.size === 0) {
        this.snackbarSrv.showSnackBar(
          'No se puede subir un fichero vacío',
          'error'
        );
      } else if (archivo.size > 5 * 1024 * 1024) { // 5 MB
        this.snackbarSrv.showSnackBar(
          'El fichero supera el tamaño máximo permitido de 5 MB',
          'error'
        );
      } else {
        this.adjuntosBlob.push(archivo);
      }
    }
  }

  setBotonEstado(estado: boolean) {
    return (this.botonEstado = estado);
  }
}
