import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { Router } from '@angular/router';
import { SolicitudControllerService } from 'api-rest';

@Component({
  selector: 'app-collection-points-requests-down-dialog',
  templateUrl: './collection-points-requests-down-dialog.component.html',
  styleUrls: ['./collection-points-requests-down-dialog.component.scss'],
})
export class CollectionPointsRequestsDownDialogComponent implements OnInit {
  formGroup!: FormGroup;
  info: any;

  constructor(
    public dialogRef: MatDialogRef<CollectionPointsRequestsDownDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private snackBarSrv: SnackbarService,
    public spinnerSrv: SpinnerService,
    private solicitudControllerService: SolicitudControllerService
  ) {
    this.formGroup = this.formBuilder.group({
      comentarioCancelar: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(255)]) },
      ],
    });
    this.info = this.data.info;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {}

  cancelarGestor() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.valid) {
      const comentarioGestor = this.formGroup.get('comentarioCancelar')?.value;
      this.spinnerSrv.loadingShow();
      this.solicitudControllerService
        .cancelarSolicitud(this.info?.id, comentarioGestor)
        .subscribe({
          next: () => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Se ha cancelado la solicitud correctamente',
              'success'
            );
            this.dialogRef.close(true);
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            console.log(err);
          },
        });
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe introduzcir el motivo de la cancelación',
        'error'
      );
    }
  }

  cerrar(): void {
    this.dialogRef.close(null);
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }
}
