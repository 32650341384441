import { Injectable } from "@angular/core";

export interface Combo {
  id: string | number,
  name: string
}

@Injectable({
  providedIn: 'root'
})
export class ComboService {
  // Tipos de usuario solicitud de reutilización / retirada
  getTiposUsuarioSolicitud(): Combo[] {
    return [
      { id: 'ADHERIDO', name: 'Adherido' },
      { id: 'POSEEDOR', name: 'Poseedor' }
    ];
  }
  getUsosEnvases(): Combo[]{
    return [
        { id: 'REUTILIZACION_EN_ESPAÑA_SDDRCA', name: 'Reutilización en España (manteniéndose en el SDDR Abierto de ENVALORA)' },
        { id: 'REUTILIZACION_EXPORTACION', name: 'Reutilización exportación' },
        { id: 'REUTILIZACION_EN_ESPAÑA_SDDRCC', name: 'Reutilización en España (manteniéndose en el SDDR Cerrado de ENVALORA)'},
        { id: 'ENVASADO_DE_RESIDUOS_O_USO_INTERNO_DEL_ENVASE', name: 'Envasado de residuos o uso interno del envase' },
        { id: 'REUTILIZACION_OTROS_SISTEMAS_SIRAP', name: 'Reutilización en otros Sistemas de Responsabilidad Ampliada del Productor (SIRAP)'}
      ];
  }
  getReutilizacionesEnvases(): Combo[]{
    return[
      { id: 'INTRACOMUNITARIO', name: 'Intracomunitario (UE)' },
      { id: 'FUERA_UNION_EUROPEA', name: 'Terceros países, fuera de la Unión Europea ' },
    ]

  }

}
