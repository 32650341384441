<div class="panel">
  <div class="container-form">
    <!-- Formulario de búsqueda -->
    <form class="form-search padding-form" [formGroup]="formGroup" *ngIf="gridArray.length!=0">
      <div class="row-fields" style="margin-top:20px">
        <div class="field field-40">
          <label for="denominacion">Denominación</label>
          <mat-form-field appearance="outline">
            <input formControlName="denominacion" id="denominacion" placeholder="Denominación" matInput type="text">
          </mat-form-field>
        </div>
        <div class="field field-40">
          <label for="cnaeprin">Tipo envase</label>
          <div class="custom-mat-field">
            <lib-ge-select-pagination [endPointFilter]="tipoEnvasesEndpoint" (filteredList)="filterList($event)"
              [updateList]="updateList" [formControlNameSelect]="getFormControl()" [placeHolder]="'Tipo envase'"
              [objQueryName]="'descripcion'" descripcion="descripcion">
            </lib-ge-select-pagination>
          </div>
        </div>
        <div class="buttons-cont">
          <button type="button" class="btn btn-primary" (click)="buscar()" matTooltip="Buscar">
            <mat-icon aria-hidden="false" aria-label="Buscar" fontIcon="search"></mat-icon>
          </button>
          <button type="button" class="btn btn-outline-primary" (click)="limpiar()" matTooltip="Limpiar">
            <mat-icon aria-hidden="false" aria-label="Limpiar" fontIcon="clear"></mat-icon>
          </button>
        </div>
      </div>

    </form>
  </div>
  <div class="btn-actions">
    <button *ngIf="canAccessExportEnv() && gridArray?.length !== 0" type="button" (click)="exportar()"
      class="btn btn-outline-primary" data-toggle="modal">Exportar</button>
    <button *ngIf="canAccessNewEnv() && canAccessEditAllGlobal()" type="button" (click)="abrirModalEnvase({}, true)"
      class="btn btn-primary" data-toggle="modal">Nuevo envase</button>
    <button *ngIf="canAccessDeleteAll() && gridArray?.length !== 0" type="button" (click)="confirmacionEliminarSeleccionados()"
      class="btn btn-outline-primary" data-toggle="modal">Eliminar envases</button>
  </div>

  <!--Tabla de búsqueda de envases-->
  <div *ngIf="gridArray?.length !== 0 else messageClear" class="datatable-container table-responsive">
    <div class=""><span class="align-middle tit-table">{{totalSearch}} registros encontrados</span></div>
    <lib-ge-table [headArray]="headArray" [gridArray]="gridArray" (actionButton)="action($event)" (sort)="sortGrid($event)"></lib-ge-table>
    <div class="d-flex pb-2 flex-row justify-content-center align-items-center">
      <div class="d-flex mt-2">
        <ngb-pagination [pageSize]="pageSize" [collectionSize]="totalElements" [(page)]="pageNumber"
          (pageChange)="renderPage($event)" [maxSize]="5" aria-label="Default pagination" size="sm">
        </ngb-pagination>
      </div>

      <div class="mt-2 selectPage">
        <label for="itemsPerPage">Mostrar:</label>
        <select id="itemsPerPage" [(ngModel)]="selectedItemsPerPage" (change)="onItemsPerPageChange()">
          <option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}</option>
        </select>
      </div>
    </div>
  </div>

  <ng-template #messageClear>
    <div class="container-menu">
      <div class="mensaje-container">
        <ng-container *ngIf="declaracionAdherido?.estadoDeclaracion?.id==0; else noOpen">
          <p class="mensaje-linea">Sin envases creados. Por favor, cree envases desde el botón Nuevo Envase.</p>
        </ng-container>
        <ng-template #noOpen>
          <p class="mensaje-linea">Sin envases creados.</p>
        </ng-template>
      </div>
    </div>
  </ng-template>
</div>
