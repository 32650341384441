import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { Router } from '@angular/router';
import {
  AdheridoControllerService,
  BusquedaAdheridoDTO,
  DeclaracionAdheridoControllerService,
  DeclaracionAdheridoDTO,
  DeclaracionAdheridoPayloadDTO,
} from 'api-rest';
@Component({
  selector: 'lib-select-attached-dialog',
  templateUrl: './select-attached-dialog.component.html',
  styleUrls: ['./select-attached-dialog.component.scss'],
})
export class SelectAttachedDialogComponent implements OnInit {
  formGroup!: FormGroup;
  updateList: boolean = false;
  adheridos: any[] = [];
  payload!: DeclaracionAdheridoPayloadDTO;

  constructor(
    public dialogRef: MatDialogRef<SelectAttachedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService | any,
    private decAdheridoControllerService: DeclaracionAdheridoControllerService,
    private adheridoService: AdheridoControllerService,
    private formBuilder: FormBuilder,
    private snackBarSrv: SnackbarService,
    public spinnerSrv: SpinnerService,
    private router: Router
  ) {
    this.formGroup = this.formBuilder.group({
      adheridoForm: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
    });
    this.payload = this.data.payload;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.getAdheridos(this.data.adheridoIds);
  }

  guardar() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.adheridos.length == 0) {
      this.snackBarSrv.showSnackBar(
        `No existe ningún Adherido asociado que cumpla las condiciones necesarias para crear una declaración de envases`,
        'error'
      );
      return;
    }

    if (this.formGroup.valid) {
      this.payload.adherido = {
        id: this.formGroup.get('adheridoForm')?.value,
      };
      this.dialogRef.close();
      this.crearDeclaracionAdherido(this.payload);
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  cerrar(): void {
    this.dialogRef.close(null);
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }

  getAdheridos(adheridoIds: any) {
    if (adheridoIds.length > 0) {
      const estados = [4, 6]; //Filtrar para que aparezcan los adheridos en estado Pdte Declaración Envases o Completado (5 o 6)
      const filter: BusquedaAdheridoDTO = {
        id: adheridoIds,
        estado: estados,
      };
      this.spinnerSrv.loadingShow();
      this.adheridoService
        .listarAdheridos({ page: 0, size: 100 }, filter)
        .subscribe({
          next: (response: any) => {
            this.spinnerSrv.loadingHide();
            this.adheridos = response?.datos;
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            console.log(err);
          },
        });
    }
  }

  crearDeclaracionAdherido(payLoad: DeclaracionAdheridoPayloadDTO) {
    this.spinnerSrv.loadingShow();
    this.decAdheridoControllerService.create5(payLoad).subscribe({
      next: (data: DeclaracionAdheridoDTO) => {
        this.router.navigate([
          '/attached-app/packaging-declaration-detail',
          data.id,
        ]);
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
      complete: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }
}
