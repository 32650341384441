<div class="modal-content">
  <empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
  <div class="modal-header">
    <h3 class="modal-title font">{{titulo}}</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>

  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
    <div class="title-name mb-3" style="font-size: 1.3rem; font-weight: 700;"> {{adherido?.razonSocial}}</div>

    <!-- Formulario dirección-->
    <form class="form-modal py-3" [formGroup]="formGroup">
      <div class="row-fields">
        <div class="field field-66">
          <label for="iban">Tipo de dirección <span class="oblig">*</span></label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="tipoDireccion" id="tipoDir" placeholder="Tipo de dirección">
              <mat-option *ngFor="let tipo of tiposDirecciones" [value]="tipo.id">
                {{tipo.descripcion}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controlHasError('tipoDireccion', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
        <div class="field field-33" style="margin-top: 25px;">
          <mat-checkbox class="margin-left-10" formControlName="requerido"><label for="tipoDir">Requerido</label>
          </mat-checkbox>
        </div>
      </div>
      <div class="row-fields">
        <div class="field field-66">
          <label for="copiaDir">Copiar datos de dirección de</label>
          <mat-form-field appearance="outline">
            <mat-select (selectionChange)="cargarDireccion($event.value,false)" formControlName="copiaDir" id="copiaDir"
              placeholder="Copiar datos de dirección de">
              <mat-option *ngFor="let dir of copiarDirecciones" [value]="dir">
                {{dir?.fullAddress}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-0">
          <label for="pais">País <span class="oblig">*</span></label>
          <div class="custom-mat-field-select">
            <lib-ge-select-pagination (selectedModel)="selectedModel('pais')" [endPointFilter]="paisesEndpoint"
              (filteredList)="filterList($event,'pais')" [updateList]="updateList" [disabled]="flagDisabledForm"
              [formControlNameSelect]="getFormControl('pais')" [placeHolder]="'País'" [objQueryName]="'nombre'"
              descripcion="nombre" [customClass]="rightHolder('pais')" [defaultValue]=69>
            </lib-ge-select-pagination>
            <div *ngIf="controlHasErrorSelect('pais')" class="mat-error">
              Campo obligatorio
            </div>
          </div>
        </div>

        <div class="col-sm flex-grow-1">
          <label *ngIf="esES()" for="cp">CP <span class="oblig">*</span></label>
          <label *ngIf="!esES()" for="cp">CP</label>
          <div class="custom-mat-form-field-width">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input formControlName="codigoPostal" id="cp" placeholder="CP" matInput type="text" maxlength="255">
              <mat-error *ngIf="controlHasError('codigoPostal', 'required')">Campo obligatorio</mat-error>
              <mat-error *ngIf="controlHasError( 'codigoPostal', 'maxlength')">Máximo 255 caracteres</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1" *ngIf="esShow">
          <label for="prov">Provincia <span class="oblig">*</span></label>
          <div class="custom-mat-form-field-width">
            <lib-ge-select-pagination (selectedModel)="selectedModel('provincia')" [endPointFilter]="provinciasEndpoint"
              (filteredList)="filterList($event,'provincia')" [updateList]="updateList" [disabled]="flagDisabledForm"
              [formControlNameSelect]="getFormControl('provincia')" [placeHolder]="'Provincia'"
              [objQueryName]="'nombre'" descripcion="nombre" [customClass]="rightHolder('provincia')">
            </lib-ge-select-pagination>
            <div *ngIf="controlHasErrorSelect('provincia')" class="mat-error">
              Campo obligatorio
            </div>
          </div>
        </div>
        <div class="col-sm flex-grow-1" *ngIf="!esShow">
          <label for="prov">Provincia <span *ngIf="esES()" class="oblig">*</span></label>
          <div class="custom-mat-form-field-width">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input formControlName="provincia" id="prov" placeholder="Provincia" matInput type="text" maxlength="255">
            </mat-form-field>
            <mat-error *ngIf="controlHasError( 'provincia', 'maxlength')">Máximo 255 caracteres</mat-error>
          </div>
        </div>
      </div>

      <div class="row form">
        <div class="col-sm flex-grow-0" *ngIf="esES()">
          <label for="municipio">Municipio <span class="oblig">*</span></label>
          <div class="custom-mat-field-select">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input formControlName="municipio" id="municipio" placeholder="Municipio" matInput type="text" maxlength="255">
              <mat-error *ngIf="controlHasError('municipio', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
            <mat-error *ngIf="controlHasError( 'municipio', 'maxlength')">Máximo 255 caracteres</mat-error>
          </div>
        </div>
        <div class="col-sm flex-grow-0" *ngIf="!esES()">
          <label for="municipio">Municipio</label>
          <div class="custom-mat-field-select">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input formControlName="municipio" id="municipio" placeholder="Municipio" matInput type="text" maxlength="255">
            </mat-form-field>
            <mat-error *ngIf="controlHasError( 'municipio', 'maxlength')">Máximo 255 caracteres</mat-error>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="nomvia">Tipo y Nombre de vía <span *ngIf="esES()" class="oblig">*</span></label>
          <mat-form-field appearance="outline" class="custom-mat-form-field-width">
            <input formControlName="nombreVia" id="nomvia" placeholder="Tipo y Nombre de vía" matInput type="text" maxlength="100">
            <mat-error *ngIf="controlHasError('nombreVia', 'required')">Campo obligatorio</mat-error>
            <mat-error *ngIf="controlHasError( 'nombreVia', 'maxlength')">Máximo 100 caracteres</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="row form justify-betw">
        <div class="col-sm flex-grow-0">
          <label for="num">Número <span *ngIf="esES()" class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-wpx">
              <input formControlName="numero" id="num" placeholder="Número" matInput type="text" maxlength="5">
              <mat-error *ngIf="esES()?controlHasError('numero', 'required'):false">Campo obligatorio
              </mat-error>
              <mat-error *ngIf="controlHasError( 'numero', 'maxlength')">Máximo 5 caracteres</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-0">
          <label for="bloque">Bloque</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-wpx">
              <input formControlName="bloque" id="bloque" placeholder="Bloque" matInput type="text" maxlength="5">
            </mat-form-field>
            <mat-error *ngIf="controlHasError( 'bloque', 'maxlength')">Máximo 5 caracteres</mat-error>
          </div>
        </div>
        <div class="col-sm flex-grow-0">
          <label for="escal">Escalera</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-wpx">
              <input formControlName="escalera" id="escal" placeholder="Escalera" matInput type="text" maxlength="5">
            </mat-form-field>
            <mat-error *ngIf="controlHasError( 'escalera', 'maxlength')">Máximo 5 caracteres</mat-error>
          </div>
        </div>
        <div class="col-sm flex-grow-0">
          <label for="piso">Piso</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-wpx">
              <input formControlName="piso" id="piso" placeholder="Piso" matInput type="text" maxlength="5">
            </mat-form-field>
            <mat-error *ngIf="controlHasError( 'piso', 'maxlength')">Máximo 5 caracteres</mat-error>
          </div>
        </div>
        <div class="col-sm flex-grow-0">
          <label for="pta">Puerta</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-wpx">
              <input formControlName="puerta" id="pta" placeholder="Puerta" matInput type="text" maxlength="5">
            </mat-form-field>
            <mat-error *ngIf="controlHasError( 'puerta', 'maxlength')">Máximo 5 caracteres</mat-error>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-2">
          <label for="otros">Otros datos</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input formControlName="otrosDatos" id="otros" placeholder="Otros datos" matInput type="text" maxlength="100">
            </mat-form-field>
            <mat-error *ngIf="controlHasError( 'otrosDatos', 'maxlength')">Máximo 100 caracteres</mat-error>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-2">
          <div style="display: flex; align-items: center;">
            <label for="otros">Geoposicionamiento </label>
            <div class="tool-help"
              matTooltip="Introduzca las coordenadas GPS de latitud y longitud. Obtención de coordenadas: Buscar dirección Google Maps > Pinchar dirección y pulsar botón derecho > Copiar las dos primeras coordenadas. Ejemplo: 40.45960,-3.69342"
              data-placement="top" title="">
              <span>?</span>
            </div>
          </div>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field-width">
              <input formControlName="coordenadas" id="coordenadas" placeholder="Geoposicionamiento" matInput
                type="text" maxlength="255">
            </mat-form-field>
            <mat-error *ngIf="controlHasError( 'coordenadas', 'maxlength')">Máximo 255 caracteres</mat-error>
          </div>
        </div>
      </div>
    </form>

    <!-- FIN Formulario dirección -->
  </div>
  <div class="modal-footer actions">
    <div class="note">* Campos Obligatorios</div>
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
        aria-label="Cancelar">Cancelar</button>
      <button type="button" *ngIf="canAccessUpdate()" class="btn btn-primary" data-dismiss="modal" (click)="guardar()"
        aria-label="Guardar">Guardar</button>
    </div>
  </div>
</div>
