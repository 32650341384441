<!-- Formulario -->
<form [formGroup]="datosGeneralesForm">
  <div class="row form">
    <div class="col-sm flex-grow-2">
      <label>Razón social <span class="oblig">*</span></label>
      <mat-form-field appearance="outline" class="custom-mat-form-field-width">
        <input formControlName="razonSocial" id="rsocial" placeholder="Razón Social" matInput type="text" maxlength="255">
        <mat-error *ngIf="controlHasError( 'razonSocial', 'maxlength')">Máximo 255 caracteres</mat-error>
        <mat-error *ngIf="controlHasError('razonSocial', 'required')">Campo obligatorio</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row form">
    <div class="col-sm flex-grow-1">
      <label for="tipodoc">Tipo documento <span class="oblig">*</span></label>
      <mat-form-field appearance="outline" class="custom-mat-form-field-width">
        <mat-select formControlName="tipoDocumento" (selectionChange)="tipoDocumentoSel($event.value)" id="tipodoc"
          placeholder="Tipo documento">
          <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.id">
            {{tipo.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="controlHasError('tipoDocumento', 'required')">Campo obligatorio</mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm flex-grow-1">
      <label for="numdoc">Documento <span class="oblig">*</span></label>
      <mat-form-field appearance="outline" class="custom-mat-form-field-width">
        <input formControlName="codigoDocumento" id="numdoc" placeholder="Documento" matInput type="text"
          oninput="this.value = this.value.toUpperCase()" maxlength="255">
        <mat-error *ngIf="controlHasError( 'codigoDocumento', 'maxlength')">Máximo 255 caracteres</mat-error>
        <mat-error *ngIf="controlHasError('codigoDocumento', 'required')">Campo obligatorio</mat-error>
        <mat-error *ngIf="controlHasError('codigoDocumento', 'pattern')">Formato incorrecto</mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm flex-grow-1">
    </div>
    <div class="col-sm flex-grow-1">
    </div>
  </div>
  <div class="row form">
    <div class="col-sm flex-grow-1">
      <label>NIMA <span class="oblig">*</span></label>
      <mat-form-field appearance="outline" class="custom-mat-form-field-width">
        <input formControlName="nima" id="nima" placeholder="Nima" matInput type="text" maxlength="10">
        <mat-error *ngIf="controlHasError( 'nima', 'maxlength')">Máximo 10 caracteres</mat-error>
        <mat-error *ngIf="controlHasError('nima', 'required')">Campo obligatorio</mat-error>
        <mat-error *ngIf="controlHasError('nima', 'pattern')">Formato incorrecto</mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm flex-grow-1">
      <label>Denominación del centro <span class="oblig">*</span></label>
      <mat-form-field appearance="outline" class="custom-mat-form-field-width">
        <input formControlName="denominacion" id="denominacion" placeholder="Denominación del centro" matInput
          type="text" maxlength="255">
        <mat-error *ngIf="controlHasError( 'denominacion', 'maxlength')">Máximo 255 caracteres</mat-error>
        <mat-error *ngIf="controlHasError('denominacion', 'required')">Campo obligatorio</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div class="row form">
    <div class="col-sm flex-grow-1">
      <div style="display: flex; align-items: center;">
        <label for="enabled">Gestor de residuos </label>
        <div class="tool-help" matTooltip="Seleccione para el registro como gestor de residuos. Incluye gestores con las operaciones R0309 y/o R0404 autorizada" 
                data-placement="top" title="" style="margin-bottom: 5px">
            <span>?</span>
        </div>
      </div>
      <div class="custom-mat-field">
        <mat-checkbox formControlName="gestorResiduos" id="gestorResiduos" color="primary" (change)="onGestorAcondicionadorChange()">
        </mat-checkbox>
        <mat-error *ngIf="datosGeneralesForm.hasError('gestorAcondicionadorChecked') && datosGeneralesForm.touched">Debe seleccionar al menos una opción Gestor residuos/Acondicionador de envases</mat-error>
      </div>
    </div>
    <div class="col-sm flex-grow-1">
      <div style="display: flex; align-items: center;">
        <label for="enabled">Acondicionador de envases </label>
        <div class="tool-help" matTooltip="Seleccione para el registro como acondicionador de envases que participa en el SDDR ABIERTO. 
            También deben registrarse gestores con las operaciones R0309 y/o R0404 autorizadas que participen en el SDDR ABIERTO" 
                data-placement="top" title="" style="margin-bottom: 5px">
            <span>?</span>
        </div>
      </div>
      <div class="custom-mat-field">
        <mat-checkbox formControlName="acondicionador" id="acondicionador" color="primary" (change)="onGestorAcondicionadorChange()">
        </mat-checkbox>
      </div>
    </div>
  </div>

  <div class="row form">
    <div class="col-sm flex-grow-1" *ngIf="datosGeneralesForm.get('gestorResiduos')?.value === true">
      <label>Nº de Autorización Medioambiental </label>
      <mat-form-field appearance="outline" class="custom-mat-form-field-width">
        <input formControlName="n_medioAmbiente" id="n_medioAmbiente" placeholder="Nº de Autorización Medioambiental"
          matInput type="text" maxlength="255">
        <mat-error *ngIf="controlHasError( 'n_medioAmbiente', 'maxlength')">Máximo 255 caracteres</mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm flex-grow-1" *ngIf="datosGeneralesForm.get('gestorResiduos')?.value === true">
      <label>Nº Inscripción en registro (1) <span class="oblig">*</span></label>
      <mat-form-field appearance="outline" class="custom-mat-form-field-width">
        <input formControlName="n_inscrip_1" id="n_inscrip_1" placeholder="Nº Inscripción en registro (1)" matInput
          type="text" maxlength="255">
        <mat-error *ngIf="controlHasError( 'n_inscrip_1', 'maxlength')">Máximo 255 caracteres</mat-error>
        <mat-error *ngIf="controlHasError('n_inscrip_1', 'required')">Campo obligatorio</mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm flex-grow-1" *ngIf="datosGeneralesForm.get('gestorResiduos')?.value === true">
      <label>Nº Inscripción en registro (2) </label>
      <mat-form-field appearance="outline" class="custom-mat-form-field-width">
        <input formControlName="n_inscrip_2" id="n_inscrip_2" placeholder="Nº Inscripción en registro (2)" matInput
          type="text" maxlength="255">
        <mat-error *ngIf="controlHasError( 'n_inscrip_2', 'maxlength')">Máximo 255 caracteres</mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm flex-grow-1" *ngIf="datosGeneralesForm.get('gestorResiduos')?.value === true">
      <label>Nº Inscripción en registro (3) </label>
      <mat-form-field appearance="outline" class="custom-mat-form-field-width">
        <input formControlName="n_inscrip_3" id="n_inscrip_3" placeholder="Nº Inscripción en registro (3)" matInput
          type="text" maxlength="255">
        <mat-error *ngIf="controlHasError( 'n_inscrip_3', 'maxlength')">Máximo 255 caracteres</mat-error>
      </mat-form-field>
    </div>
  </div>
  
  <div class="row form" >
    <div class="col-sm flex-grow-2" *ngIf="datosGeneralesForm.get('acondicionador')?.value === true">
      <div style="display: flex; align-items: center;">
        <label for="enabled">Como acondicionador de envases, ¿va a colaborar en el retorno de los depósitos al usuario que le hace entrega de los envases del SDDR ABIERTO de ENVALORA?  <span class="oblig">*</span> </label>
        <div class="tool-help" matTooltip="ENVALORA le hará llegar el acuerdo según la opción seleccionada"
              data-placement="top" title="" style="margin-bottom: 5px">
          <span>?</span>
        </div>
      </div>
      <mat-form-field appearance="outline">
        <mat-select formControlName="abonoDeposito" id="abonoDeposito" placeholder="-- Seleccione una opción --">
          <mat-option [value]="true">Si</mat-option>
          <mat-option [value]="false">No</mat-option>
        </mat-select>
        <mat-error *ngIf="controlHasError('abonoDeposito', 'required')">Campo obligatorio</mat-error>
      </mat-form-field>
    </div>
  </div>
  
  <div class="row form">

    <div class="col-sm flex-grow-1" *ngIf="this.canViewAgreement()">
      <div style="display: flex; align-items: center;">
        <label for="acuerdoEnv">Acuerdo firmado con Envalora <span
            class="oblig">*</span></label>
        <div class="tool-help"
          matTooltip="Si sólo para gestores que han sido contratados por Envalora para otros modelos de gestión. No para gestores registrados que prestan servicios a poseedores finales a libre elección de los poseedores"
          data-placement="top" title="">
          <span>?</span>
        </div>
      </div>
      <div class="custom-mat-field">
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <mat-select formControlName="acuerdoEnv" id="acuerdoEnv" placeholder="Seleccionar" [disabled]="!this.canEditAgreement()">
            <mat-option *ngFor="let item of optionArray" [value]="item.value">
              {{ item.value }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="col-sm flex-grow-1">
      <label for="fechaAltaFinalizada">Fecha alta finalizada </label>
      <mat-form-field appearance="outline">
        <input formControlName="fechaAltaFinalizada" id="fechaAltaFinalizada" matInput type="text" readonly>
      </mat-form-field>
    </div>

    <div class="col-sm flex-grow-1">
      <ng-container *ngIf="canAccessDate()">
        <label for="fechaBaja">Fecha de baja </label>
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="datepicker" formControlName="fechaBaja">
          <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
          <mat-datepicker #datepicker>
          </mat-datepicker>
          <mat-error *ngIf="controlHasError('fechaBaja', 'matDatepickerParse')">Formato incorrecto</mat-error>
        </mat-form-field>
      </ng-container>
    </div>

    <div class="col-sm flex-grow-1">
      <ng-container *ngIf="canAccessDate()">
        <label for="fechaBajaEnvanet">Fecha baja Envanet</label>
        <mat-form-field appearance="outline">
          <input formControlName="fechaBajaEnvanet" id="fechaBajaEnvanet" matInput type="text" readonly>
        </mat-form-field>
      </ng-container>
    </div>

  </div>
  <div class="row-fields">
    <div class="field field-100">
      <div style="display: flex; align-items: center;">
        <label for="autorizacionComuniaciones">Consiento recibir comunicaciones sobre otras actividades de terceros y de ENVALORA <span class="oblig">*</span></label>
        <div class="tool-help" matTooltip="La base jurídica para el tratamiento de los datos personales con finalidades de envío de comunicaciones es el consentimiento prestado por LA EMPRESA al confirmar esta afirmación. 
        Además, los datos personales de personas de contacto también podrán ser usados para enviarles comunicaciones sobre otras actividades de ENVALORA o de terceros colaboradores con el SCRAP por consentimiento prestado por LA EMPRESA mediante esta confirmación." data-placement="top" title="">
          <span>?</span>
        </div>
      </div>
      <mat-radio-group formControlName="autorizacionComuniaciones" id="autorizacionComuniaciones"
        class="custom-mat-form-radio">
        <mat-radio-button color="primary" [value]="true">Sí</mat-radio-button>
        <mat-radio-button color="primary" [value]="false">No</mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="controlHasError('autorizacionComuniaciones', 'required')">Campo obligatorio
      </mat-error>
    </div>
  </div>
</form>
