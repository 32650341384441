export * from './adheridoController.service';
import { AdheridoControllerService } from './adheridoController.service';
export * from './adheridoEstadoController.service';
import { AdheridoEstadoControllerService } from './adheridoEstadoController.service';
export * from './aportacionDiAdheridoController.service';
import { AportacionDiAdheridoControllerService } from './aportacionDiAdheridoController.service';
export * from './centrosEnvasadoController.service';
import { CentrosEnvasadoControllerService } from './centrosEnvasadoController.service';
export * from './certificadosController.service';
import { CertificadosControllerService } from './certificadosController.service';
export * from './contratoController.service';
import { ContratoControllerService } from './contratoController.service';
export * from './declaracionAdheridoController.service';
import { DeclaracionAdheridoControllerService } from './declaracionAdheridoController.service';
export * from './declaracionCentroController.service';
import { DeclaracionCentroControllerService } from './declaracionCentroController.service';
export * from './declaracionEnvaseController.service';
import { DeclaracionEnvaseControllerService } from './declaracionEnvaseController.service';
export * from './declaracionEnvaseMaterialController.service';
import { DeclaracionEnvaseMaterialControllerService } from './declaracionEnvaseMaterialController.service';
export * from './estadoDeclaracionController.service';
import { EstadoDeclaracionControllerService } from './estadoDeclaracionController.service';
export * from './facturaAdheridoController.service';
import { FacturaAdheridoControllerService } from './facturaAdheridoController.service';
export * from './mailController.service';
import { MailControllerService } from './mailController.service';
export * from './reportController.service';
import { ReportControllerService } from './reportController.service';
export * from './scrapController.service';
import { ScrapControllerService } from './scrapController.service';
export * from './sectorController.service';
import { SectorControllerService } from './sectorController.service';
export * from './tarifaAdheridoSddrAbiertoController.service';
import { TarifaAdheridoSddrAbiertoControllerService } from './tarifaAdheridoSddrAbiertoController.service';
export * from './tarifaAdheridoSddrCerradoController.service';
import { TarifaAdheridoSddrCerradoControllerService } from './tarifaAdheridoSddrCerradoController.service';
export * from './tarifaAdheridoUnUsoController.service';
import { TarifaAdheridoUnUsoControllerService } from './tarifaAdheridoUnUsoController.service';
export * from './tramosCuotaController.service';
import { TramosCuotaControllerService } from './tramosCuotaController.service';
export const APIS = [AdheridoControllerService, AdheridoEstadoControllerService, AportacionDiAdheridoControllerService, CentrosEnvasadoControllerService, CertificadosControllerService, ContratoControllerService, DeclaracionAdheridoControllerService, DeclaracionCentroControllerService, DeclaracionEnvaseControllerService, DeclaracionEnvaseMaterialControllerService, EstadoDeclaracionControllerService, FacturaAdheridoControllerService, MailControllerService, ReportControllerService, ScrapControllerService, SectorControllerService, TarifaAdheridoSddrAbiertoControllerService, TarifaAdheridoSddrCerradoControllerService, TarifaAdheridoUnUsoControllerService, TramosCuotaControllerService];
