import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  ContactoControllerService, AdheridoControllerService, TipoContactoControllerService,
  AdheridoModel, ContactoModel, TipoContactoModel,
} from 'api-rest';
import { ContactDialogComponent } from '../contact-dialog/contact-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import swal from 'sweetalert2';
import { SpinnerService } from '../../../services/spinner.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'lib-detail-contact-data',
  templateUrl: './detail-contact-data.component.html',
  styleUrls: ['./detail-contact-data.component.scss'],
})
export class DetailContactDataComponent implements OnChanges {
  @Input() adheridoInfo!: any;
  @Output() public refrescarAdheridoEvent = new EventEmitter<any>();
  contactos: ContactoModel[] | undefined = [];

  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  gridArray: ContactoModel[] = [];

  headArray: any = [
    {
      Head: 'Requerida',
      FieldName: 'requerido',
      check: true,
      disabled: true,
    },
    {
      Head: 'Tipo contacto',
      FieldName: 'tipo_contacto',
      Attribute: 'descripcionCorta',
      Tooltip: true,
    },
    {
      Head: 'Tipo entidad',
      renderValue: (value: any) => value.persona ? 'Persona' : 'Empresa'
    },
    {
      Head: 'Nombre/Razón social',
      FieldName: 'fullName',
      Tooltip: true,
    },
    {
      Head: 'Activo',
      FieldName: 'obsoleto',
      renderValue: (item: any) => (item.obsoleto ? 'NO' : 'SI'),
    },
    {
      Head: 'Email',
      FieldName: 'email',
      Tooltip: true,
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Detalle',
          show: { params: 'canAccessView', accion: 'view' },
        },
        // { 'nameButton': "edit", "icon": "edit_square", 'toolTip': 'Editar', 'show': { 'params': 'canAccessListEdit', 'accion': 'edit' } },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
        {
          nameButton: 'menu',
          array: [
            {
              nameButton: 'activate',
              name: 'Activar',
              show: { params: 'canAccessActivate', accion: 'activate' },
            },
            {
              nameButton: 'obsolete',
              name: 'Marcar como obsoleto',
              show: { params: 'canAccessObsolete', accion: 'obsolete' },
            }
          ],
        },
      ],
      width: '8',
      permanent: true,
    },
  ];

  constructor(
    private dialog: MatDialog,
    private contactoControllerSrv: ContactoControllerService,
    private adheridoControllerSrv: AdheridoControllerService,
    private spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.adheridoInfo) {
      this.contactos = this.adheridoInfo?.contactos;
      this.gridArray = this.canAccessAll(this.contactos);
    }
  }

  canAccessAll(list: any) {
    const resultListShow = this.authService.can(
      'adheridos-contactos',
      'access'
    );
    const resultListEdit = this.authService.can(
      'adheridos-contactos',
      'update'
    );
    const resultDelete = this.authService.can('adheridos-contactos', 'delete');

    const listPermission = list.map((objeto: any) => {
      const estadoUpdatedContact =
        this.adheridoInfo.estado &&
        [2, 3, 4, 5, 6].includes(this.adheridoInfo.estado!.id);
      const estadoEliminar =
        this.adheridoInfo.estado &&
        [1, 2, 3, 4, 5, 6].includes(this.adheridoInfo.estado!.id) &&
        (!objeto.requerido || objeto.requerido === null);
      const esActivo = !objeto.obsoleto;
      //Si el estado es mayor a Pdte Firma Contrato (3) no permitir editar los contactos de Firmante (3) o Rep Autorizado (4)
      const canEditEstadoTipo = (this.adheridoInfo.estado!.id < 3 || (this.adheridoInfo.estado!.id >= 3 && !([3,4].includes(objeto.tipo_contacto?.id))));
      const estadoObsoleto = this.adheridoInfo.estado && [2, 3, 4, 5, 6].includes(this.adheridoInfo.estado!.id);

      return {
        ...objeto,
        canAccessView: resultListShow, //&& esActivo && canEditEstadoTipo,
        canAccessListEdit: resultListEdit && estadoUpdatedContact,
        canAccessDelete: resultDelete && estadoEliminar,
        canAccessObsolete: esActivo && estadoObsoleto,
        canAccessActivate: !esActivo
      };
    });

    return listPermission;
  }

  canAccessExportar(): boolean {
    return this.authService.can('adheridos-contactos', 'access-b-exportar');
  }

  canAccessCreateContact(): boolean {
    return this.authService.can('adheridos-contactos', 'create');
  }

  openModalContact(element?: any): void {
    const dialogRef = this.dialog.open(ContactDialogComponent, {
      width: '950px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: {
        contacto: element,
        adherido: this.adheridoInfo,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refrescarAdheridoEvent.emit(true);
      }
    });
  }

  checked(item: any) {}

  action(item: any) {
    if (item.nameButton == 'view') {
      this.openModalContact(item);
    } else if (item.nameButton == 'edit') {
      this.openModalContact(item);
    } else if (item.nameButton == 'delete') {
      this.confirmacionEliminar(item);
    } else if (item.nameButton == 'activate') {
      this.comprobarContactosActivos(item);
    } else if (item.nameButton == 'obsolete') {
      this.confirmacionMarcarObsoleto(item);
    }
  }
  confirmacionEliminar(element?: ContactoModel): void {
    swal
      .fire({
        text: '¿Desea eliminar el contacto?',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.eliminarContacto(element);
        }
      });
  }
  eliminarContacto(element?: ContactoModel) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.contactoControllerSrv.borrarContacto(element.id).subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'El contacto se ha eliminado correctamente',
            'success'
          );
          this.refrescarAdheridoEvent.emit(true);
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al eliminar el contacto',
            'error'
          );
          console.log(err);
        },
      });
    }
  }

  comprobarContactosActivos(element?: ContactoModel): void {
    //Si el contacto es de tipo Firmante o Rep Autorizado, solo puede hacer uno activo de cada tipo (incluyendo Empresa/Persona en caso de Rep Autorizado)
    if(element?.tipo_contacto?.id == 3 || element?.tipo_contacto?.id == 4){
      let contactoExistente:any = null;
        if(element?.tipo_contacto?.id == 3){
          contactoExistente = this.contactos?.filter((pContacto:any) => !pContacto.obsoleto && pContacto.tipo_contacto.id == element?.tipo_contacto?.id)[0];
        }else{
          contactoExistente = this.contactos?.filter((pContacto:any) => !pContacto.obsoleto && pContacto.tipo_contacto.id == element?.tipo_contacto?.id && pContacto.persona == element?.persona)[0];
        }
        if(contactoExistente){
          swal.fire({
            text: `No está permitido activar otro contacto de tipo Firmante o Representante autorizado puesto que ya existe uno activo. ¿Quiere marcar el contacto existente como obsoleto?`,
            icon: 'question',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sí',
            denyButtonText: 'No',
            allowOutsideClick: false,
          }).then((result: any) => {
            if (result.isConfirmed) {
              this.activarContacto(element, contactoExistente);
            }
          });
        }else{
          this.activarContacto(element);
        }
    }else{
      this.activarContacto(element);
    }
  }

  activarContacto(element?: ContactoModel, contactoExistente?: any){
    if (element?.id) {
      if(contactoExistente){
        contactoExistente.obsoleto = true;
        const pContacto = this.adheridoInfo?.contactos.filter((item:any) => item.id == element.id)[0];
        pContacto.obsoleto = false;
        this.adheridoControllerSrv
          .actualizarAdherido(this.adheridoInfo.id!, this.adheridoInfo)
          .subscribe({
            next: (response) => {
              this.spinnerSrv.loadingHide();
              this.snackBarSrv.showSnackBar(
                'El contacto se ha activado correctamente',
                'success'
              );
              this.refrescarAdheridoEvent.emit(true);
            },
            error: (err) => {
              this.spinnerSrv.loadingHide();
              this.snackBarSrv.showSnackBar(
                'Ha ocurrido un error al activar el contacto',
                'error'
              );
              console.log(err);
            },
          });

      }else{
        element.obsoleto = false;
        this.spinnerSrv.loadingShow();
        this.contactoControllerSrv.actualizarContacto(element.id, element).subscribe({
          next: (response) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'El contacto se ha activado correctamente',
              'success'
            );
            this.refrescarAdheridoEvent.emit(true);
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al activar el contacto',
              'error'
            );
            console.log(err);
          },
        });
      }
    }
  }

  confirmacionMarcarObsoleto(element?: ContactoModel): void {
    swal
      .fire({
        text: '¿Desea marcar el contacto como obsoleto?',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.marcarContactoObsoleto(element);
        }
      });
  }

  marcarContactoObsoleto(element?: ContactoModel) {
    if (element?.id) {
      element.obsoleto = true;
      this.spinnerSrv.loadingShow();
      this.contactoControllerSrv.actualizarContacto(element.id, element).subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'El contacto se ha marcado como obsoleto',
            'success'
          );
          this.refrescarAdheridoEvent.emit(true);
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al marcar el contacto como obsoleto',
            'error'
          );
          console.log(err);
        },
      });
    }
  }

  renderPage(event: number) {
    this.pageNumber = event;
  }

  canViewRol(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }
}
