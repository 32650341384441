import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  CodigoLerControllerService,
  CodigoOperacionControllerService,
  CodigoOperacionFilterDTO,
  GestoresResiduosControllerService,
  GestoresResiduosDTO,
  OperacionGestionControllerService,
  AcondicionamientoEnvaseControllerService,
  AcondicionamientoTipoControllerService,
  AcondicionamientoTipoDTO,
  TipoEnvasesControllerService,
  TipoEnvasesDTO,
  PageDTOTipoEnvasesDTO
} from 'api-rest';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { MatDialog } from '@angular/material/dialog';
import { WasteManagersDetailPackageDownDialogComponent } from '../waste-managers-detail-package-down-dialog/waste-managers-detail-package-down-dialog.component';
import { WasteManagersPackageReconditioningUploadDialogComponent } from '../waste-managers-package-reconditioning-upload-dialog/waste-managers-package-reconditioning-upload-dialog.component';
import moment from 'moment';
import { FormatCurrencyPipe } from '../../../share/components/pipe/FormatCurrency.pipe';
import { ParseCurrencyPipe } from '../../../share/components/pipe/ParseCurrency.pipe';
import { CombosService } from '../../../services/combos.service';

@Component({
  selector: 'lib-waste-managers-detail-package-reconditioning',
  templateUrl: './waste-managers-detail-package-reconditioning.component.html',
  styleUrls: ['./waste-managers-detail-package-reconditioning.component.scss'],
})
export class WasteManagersDetailPackageReconditioningComponent implements OnInit {
  @Input() gestorInfo!: GestoresResiduosDTO | any;
  @Output() public refrescarGestorEvent = new EventEmitter<any>();

  formGroup!: FormGroup;
  codigosLerEndpoint =
    this.environment.urlBackCore + 'api/v1/core/codigo-ler/filtro-combo?poseedores=true';

  grid: any[] = [];
  operacionArray: any[] = [];

  pageNumber: number = 0;
  pageSize: number = 100;
  recordsTotal: number = 0;
  gestionArray: any[] = [];

  updateList: boolean = false;
  
  envases: any[] = [];
  acondicionamientos: any[] = [];

  headArray: any[] = [
    {
      Head: 'Envase',
      FieldName: 'descripcionEnvase',
      Tooltip: true,
    },
    {
      Head: 'Acondicionamiento',
      FieldName: 'descripcionAcondicionamiento',
      Tooltip: true,
    },
    {
      Head: 'Capacidad de acondicionamiento anual (t)',
      FieldName: 'capacidadAnual',
      renderValue: (item: any) =>
        this.formatCurrencyPipe.transform(item?.capacidadAnual),
    },
    {
      Head: 'Fecha Actividad Acondicionamiento',
      FieldName: 'fechaMDesde',
    },
    {
      Head: 'Fecha hasta',
      FieldName: 'fechaMHasta'
    },
    {
      Head: 'Autorización activa',
      FieldName: 'autorizacionActiva',
      renderValue: (item: any) => {
        const todayDate = new Date();
        const fechaDesde = new Date(item?.fechaDesde);
        const fechaHasta = item?.fechaHasta ? new Date(item.fechaHasta) : null;
        return todayDate > fechaDesde && (!fechaHasta || todayDate < fechaHasta) ? 'SI' : 'NO';
      },
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
        {
          nameButton: 'dar_baja',
          icon: 'move_to_inbox',
          toolTip: 'Dar Baja',
          show: { params: 'canAccessDown', accion: 'dar_baja' },
        },
      ],
      width: '2',
      permanent: true,
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private codigoLerControllerSrv: CodigoLerControllerService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    @Inject('environment') private environment: any,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private operacionGestionControllerService: OperacionGestionControllerService,
    private gestoresResiduosControllerService: GestoresResiduosControllerService,
    private acondicionamientoEnvaseControllerService: AcondicionamientoEnvaseControllerService,
    private acondicionamientoTipoControllerService: AcondicionamientoTipoControllerService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private codigoOperacionControllerService: CodigoOperacionControllerService,
    private formatCurrencyPipe: FormatCurrencyPipe,
    private parseCurrencyPipe: ParseCurrencyPipe,
    public comboSrv: CombosService,
    private tipoEnvasesCtrSrv: TipoEnvasesControllerService,
  ) {
    this.formGroup = this.formBuilder.group({
      envase: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      acondicionamiento: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      capacidadAnual: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      fechaDesde: [
        {value: null, disabled: false},
        {validators: Validators.compose([Validators.required])},
      ],
    });
    
  }

  ngOnInit() {
    this.comboEnvases();
    this.comboAcondicionamientos();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.gestorInfo) {
      this.grid = this.canAccessAll(this.gestorInfo?.acondicionamientoEnvase!);
    }
  }

  comboEnvases() {
    const tipoEnvaseFilter = {sddrca: true};
    this.tipoEnvasesCtrSrv.findAll(tipoEnvaseFilter, {page: 0, size: 100}).subscribe({
      next: (data: any) => {
        this.envases = data.datos;
      },
      error: () => { },
    });
  }

  comboAcondicionamientos() {
    this.acondicionamientoTipoControllerService.findAll14().subscribe({
      next: (data: Array<AcondicionamientoTipoDTO>) => {
        this.acondicionamientos = data!;
      },
      error: () => { },
    });
  }

  canAccessAll(list: any) {
      const resultdown = this.authService.can(
        'poseedores-gestor-acondicionamiento',
        'update-baja'
      );
      const resultDelete = this.authService.can(
        'poseedores-gestor-acondicionamiento',
        'delete'
      );
      
      const listPermission = list.map((objeto: any) => {
        let fechaMDesde = moment(new Date(objeto.fechaDesde)).format(
          'DD/MM/YYYY'
        );
        objeto.fechaMDesde = fechaMDesde;
        let fechaMHasta =
                objeto.fechaHasta != null
                  ? moment(new Date(objeto.fechaHasta)).format('DD/MM/YYYY')
                  : null;
        objeto.fechaMHasta = fechaMHasta;
        const estadoUpdatedDown = this.gestorInfo?.gestoresResiduosEstado && [2].includes(this.gestorInfo?.gestoresResiduosEstado?.id!);
        const estadoEliminar = this.gestorInfo.gestoresResiduosEstado && [1].includes(this.gestorInfo?.gestoresResiduosEstado?.id!);
        let descripcionEnvase = objeto.tipoEnvase.descripcion;
        let descripcionAcondicionamiento = objeto.acondicionamientoTipo.descripcion;
  
        return {
          ...objeto,
          descripcionEnvase: descripcionEnvase,
          descripcionAcondicionamiento: descripcionAcondicionamiento,
          canAccessDown: resultdown && estadoUpdatedDown && objeto.fechaHasta == null,
          canAccessDelete: resultDelete && estadoEliminar,
        };
      });
      return listPermission;
      
    }

  canAccessAgregar(): boolean {
    const resultAuth = (
      this.authService.can('poseedores-gestor-acondicionamiento', 'create') &&
      this.gestorInfo.gestoresResiduosEstado &&
      [1, 2].includes(this.gestorInfo.gestoresResiduosEstado?.id));

    return resultAuth;
  }

  addPrevision() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
      return;
    }
    let pEnvase = this.envases.find(
      (x: any) => x.id === this.formGroup.get('envase')?.value
    );
    let pAcondicionamiento = this.acondicionamientos.find(
      (x: any) => x.id === this.formGroup.get('acondicionamiento')?.value
    );
    const dataObjtable = {
      id: Math.random(),
      envase: this.formGroup.get('envase')?.value,
      acondicionamiento: this.formGroup.get('acondicionamiento')?.value,
      tipoEnvase: pEnvase,
      acondicionamientoTipo: pAcondicionamiento,
      capacidadAnual: this.parseCurrencyPipe.transform(this.formGroup.get('capacidadAnual')?.value),
      autorizacionActiva: true,
      fechaDesde: moment(this.formGroup.get('fechaDesde')?.value).format('YYYY-MM-DD'),
      fechaMDesde: moment(
        new Date(this.formGroup.get('fechaDesde')?.value)
      ).format('DD/MM/YYYY'),
      gestoresResiduos: { id: this.gestorInfo?.id },
      canAccessDelete:
        this.gestorInfo.gestoresResiduosEstado?.id == 1 &&
        this.authService.hasCurrentUserRole('PO-GESTOR')
          ? true
          : false,
      descripcionEnvase: pEnvase?.descripcion,
      descripcionAcondicionamiento: pAcondicionamiento?.descripcion,
    };
    //if (
    //  !(
    //    this.gestorInfo.gestoresResiduosEstado?.id == 1 &&
    //    this.authService.hasCurrentUserRole('PO-GESTOR')
    //  )
    //) {
      this.grid.push(dataObjtable);
      if (this.grid?.length > 0) {
        let body = this.gestorInfo;

        body.acondicionamientoEnvase = this.grid;
        this.gestorInfo.numeroAutorizacion = this.gestorInfo.numeroAutorizacion ?? '';
        this.gestorInfo.numInscripcion1 = this.gestorInfo.numInscripcion1 ?? '';
        this.gestorInfo.numInscripcion2 = this.gestorInfo.numInscripcion2 ?? '';
        this.gestorInfo.numInscripcion3 = this.gestorInfo.numInscripcion3 ?? '';
        this.gestoresResiduosControllerService
          .editById4(this.gestorInfo.id!, body)
          .subscribe({
            next: (response: any) => {
              this.snackBarSrv.showSnackBar(
                'Los datos se han guardado correctamente.',
                'success'
              );
              this.refrescarGestorEvent.emit(true);
              this.formGroup.reset();
            },
            error: (err) => {},
          });
      } else {
        this.snackBarSrv.showSnackBar(
          'Al menos debe tener un registro de residuos gestionado',
          'error'
        );
      }
    //} else {
    //  this.grid.push(dataObjtable);
    //  this.grid = [...this.grid];
    //  this.formGroup.reset();
    //}

  }
  
  finalizar() {
    if (this.grid?.length > 0) {
      let body = this.gestorInfo;
      body.acondicionamientoEnvase = this.grid;
      body.gestoresResiduosEstado.id = 2;
      this.gestoresResiduosControllerService
        .editById4(this.gestorInfo.id!, body)
        .subscribe({
          next: (response: any) => {
            this.snackBarSrv.showSnackBar(
              'Los datos se han guardado correctamente. El gestor se ha actualizado a estado Completado. Ya puede comenzar con sus aportaciones DIS de sus empresas',
              'success'
            );
            this.router.navigate(['/initial-page']);
          },
          error: (err) => {},
        });
    } else {
      this.snackBarSrv.showSnackBar(
        'Al menos debe tener un registro de acondicionamiento de envases',
        'error'
      );
    }
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }

  showClearButton(formName: string): boolean {
    return this.formGroup.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.formGroup.get(formName)?.setValue(null);
  }

  action(event: any) {
    if (event.nameButton == 'delete') {
      this.confirmacionEliminarPrevision(event);
    } 
    else if (event.nameButton == 'dar_baja') {
      this.opendDialgDarBajaResiduo(event);
    }
  }

  confirmacionEliminarPrevision(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar el acondicionamiento del envase?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        if (
          !(
            this.gestorInfo.gestoresResiduosEstado?.id == 1 &&
            this.authService.hasCurrentUserRole('PO-GESTOR')
          )
        ) {
          let body = this.gestorInfo;
          this.grid = this.grid.filter((x: any) => x.id !== element.id);
          body.acondicionamientoEnvase = this.grid;
          this.gestoresResiduosControllerService
            .editById4(this.gestorInfo?.id, body)
            .subscribe({
              next: (response: any) => {
                this.snackBarSrv.showSnackBar(
                  'Se ha eliminado correctamente el residuo',
                  'success'
                );
                this.refrescarGestorEvent.emit(true);
              },
              error: (err) => {},
            });
        } else {
          this.snackBarSrv.showSnackBar(
            `Se ha eliminando correctamente`,
            'success'
          );
          this.grid = this.grid.filter((x: any) => x.id !== element.id);
        }
      }
    });
  }


  opendDialgDarBajaResiduo(event: any) {
    const dialogRef = this.dialog.open(
      WasteManagersDetailPackageDownDialogComponent,
      {
        width: '950px',
        maxWidth: '95%',
        maxHeight: '95vh',
        data: {
          objetoResiduo: event,
          gestorInfo: this.gestorInfo,
        },
        disableClose: true,
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refrescarGestorEvent.emit(true);
      }
    });
  }

  uploadPackageReconditioning(){
    const dialogRef = this.dialog.open(WasteManagersPackageReconditioningUploadDialogComponent, {
      width: '950px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: {
        gestorInfo: this.gestorInfo
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refrescarGestorEvent.emit(true);
      }
    });
  }



}
