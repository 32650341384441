<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="col-content show-display">
  <div class="header-content mb-xl-5 mb-3">
    <div class="heading">{{puntoRecogidaInfo?.razonSocial}}</div>
  </div>
  <form [formGroup]="formGroup" *ngIf="canAccessDataViewForm()">

    <div class="row form">
      <div class="col-sm flex-grow-1">
        <label for="prov">Provincia </label>
        <div class="custom-mat-field">
          <lib-ge-select-pagination [endPointFilter]="provinciasEndpoint" [updateList]="updateList"
            [formControlNameSelect]="getFormControl('provincia')" [placeHolder]="'Provincia'" [objQueryName]="'nombre'"
            descripcion="nombre" [customClass]="rightHolder('provincia')">
          </lib-ge-select-pagination>
          <div *ngIf="controlHasErrorSelect('provincia')" class="mat-error">
            Campo obligatorio
          </div>
        </div>
      </div>
      <div class="col-sm flex-grow-1">
        <label for="ptoRecogida">Punto de recogida <span class="oblig">*</span></label>
        <div class="custom-mat-field">
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <mat-select formControlName="ptoRecogida" id="ptoRecogida" placeholder="Punto de recogida">
              <mat-option *ngFor="let item of ptoRecogidaArray" [value]="item">
                {{ item.razonSocial }}
              </mat-option>
            </mat-select>
            <span *ngIf="showClearButton('ptoRecogida')" tabindex="0" class="ng-clear-wrapper"
              (click)="clearSelection($event,'ptoRecogida')">
              <span aria-hidden="true" class="ng-clear">×</span>
            </span>
          </mat-form-field>
        </div>
      </div>

    </div>
    <div class="row form">
      <div class="col-sm flex-grow-2">
        <label for="codigoLer">Código LER y descripción de ENVALORA <span class="oblig">* </span><em
          *ngIf="formGroup.get('codigoLer')?.value?.peligrosidad === true" class="fas fa-skull-crossbones"
          style="color: red;" matTooltip="Envase de tipo peligroso"></em></label>
        <div class="custom-mat-field">
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <mat-select formControlName="codigoLer" id="codigoLer" placeholder="Código LER y descripción de ENVALORA">
              <mat-option *ngFor="let item of codigoLerArray" [value]="item">
                {{ item?.tipoCodigo }} {{ item?.descripcion }}
              </mat-option>
            </mat-select>
            <span *ngIf="showClearButton('codigoLer')" tabindex="0" class="ng-clear-wrapper"
              (click)="clearSelection($event,'codigoLer')">
              <span aria-hidden="true" class="ng-clear">×</span>
            </span>
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm flex-grow-0" *ngIf="canAccessAgregar()">
        <div class="button-group" style="margin-top:26px;">
          <button style="float:right" type="button" (click)="addPrevision()" class="btn btn-primary">Agregar</button>
        </div>
      </div>
    </div>

  </form>
  <div class="datatable-container table-responsive overflow-table table-detail-width-100">
    <lib-ge-table [headArray]="headArray" [gridArray]="grid" (actionButton)="action($event)">
    </lib-ge-table>
  </div>
</div>
