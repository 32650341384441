import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { GetAttributePipe } from './pipe/get-attribute.pipe';

@Component({
  selector: 'lib-ge-table',
  templateUrl: './ge-table.component.html',
  styleUrls: ['./ge-table.component.scss'],
  providers: [GetAttributePipe]
})
export class GeTableComponent implements OnInit, OnChanges {
  @Input() headArray: any[] = [];
  @Input() gridArray: any[] = [];
  @Output() actionButton = new EventEmitter<any>();
  @Output() checked = new EventEmitter<any>();
  @Output() allChecked = new EventEmitter<any>();
  @Output() sort = new EventEmitter<any>();

  @Input() pageNumber: number = 1;
  @Input() pageSize: number = 10;
  @Input() isDragAndDrog: boolean = false;
  @Input() dataPaintRow: any = null;
  @Input() dataPaintColumn: any = null;
  @Input() sortByClient: boolean = false;

  public selectAllItems = false;
  public colorResultsRow: any[] = [];
  public colorResultsColumn: any[] = [];

  maxLengthText: number = 9999999;

  orderByAsc = true;

  constructor(public attributePipe: GetAttributePipe) { }

  ngOnInit(): void {
    if (this.gridArray?.length < 1) {
      this.gridArray = [{}];
      this.isDragAndDrog = false;
    } else {
      for (const item of this.gridArray) {
        if (typeof item.checked !== 'undefined' && item.checked === true) {
          this.fieldsChange({ currentTarget: { checked: item.checked } }, item);
        }
      }
    }
    this.colorResultsRow = [];
    this.colorResultsColumn = [];
    this.initializeColorResults();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  sortColumn(head: { FieldName:string, Attribute?: string, renderValue?: Function  }) {
    const property = head.Attribute ? head.FieldName + "." + head.Attribute : head.FieldName;
    // Cambia la dirección de ordenación
    this.orderByAsc = !this.orderByAsc;

    if(this.sortByClient){
      // Ordena el array gridArray alfabéticamente en función del campo especificado (fieldName)
      this.gridArray.sort((a, b) => {
        let valueA, valueB;
        if(head.renderValue) {
          valueA = head.renderValue(a);
          valueB = head.renderValue(b);
        } else {
          valueA = this.attributePipe.getPropertyValue(a, property);
          valueB = this.attributePipe.getPropertyValue(b, property);
        }

        if(typeof valueA == 'string' || typeof valueB == 'string' ) {
          valueA = valueA?.toUpperCase();
          valueB = valueB?.toUpperCase();
        }

        let comparison = 0;

        if (valueA > valueB || (valueA != null && valueB == null )) {
          comparison = 1;
        } else if (valueA < valueB || (valueA == null && valueB != null )) {
          comparison = -1;
        }

        // Multiplica por -1 si la dirección de ordenación es descendente
        return this.orderByAsc ? comparison : comparison * -1;
      });
    }else{
      let sortCol = {
        name: property,
        type: this.orderByAsc ? 'asc' : 'desc'
      }
      this.sort.emit(sortCol);
    }
  }


  actions(item: any, name: any) {
    item['nameButton'] = name;
    this.actionButton.emit(item);
  }

  fieldsChange(event: any, item: any): void {
    item['checked'] = event.currentTarget.checked;
    this.checked.emit(item);
  }

  selectAllRows(event: any, gridArray: any) {
    this.selectAllItems = event.currentTarget.checked;
    const gridArrayAux = gridArray.map((item: any) => {
      item['checked'] = this.selectAllItems;
      return item;
    });
    this.allChecked.emit(gridArrayAux);
  }

  onDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.gridArray, event.previousIndex, event.currentIndex);
    this.gridArray.forEach((gridArray, idx) => {
      gridArray.order = idx + 1;
    });
  }

  isEmpty(obj: any): boolean {
    if (Object.keys(obj).length === 0) return true;
    return false;
  }

  getRowColorClassAction(item: any, objlist: any) {
    let colorClass = '';
    let foundColorObject = objlist.optionColor.find(
      (obj: any) => obj.info == item[objlist.fieldName]
    );
    if (foundColorObject) {
      colorClass = foundColorObject.color;
    }
    return colorClass;
  }

  initializeColorResults() {
    if (this.dataPaintColumn !== null && this.gridArray?.length != 0) {
      for (let item of this.gridArray) {
        this.colorResultsColumn.push(
          this.getRowColorClassAction(item, this.dataPaintColumn)
        );
      }
    }
    if (this.dataPaintRow !== null && this.gridArray?.length != 0) {
      for (let item of this.gridArray) {
        this.colorResultsRow.push(
          this.getRowColorClassAction(item, this.dataPaintRow)
        );
      }
    }
  }


  shouldShowButton(button: any, item: any): boolean {
    if (button.show) {
      if(typeof button.show == 'function') {
        return button.show(item);
      }
      
      if (item[button.show.params] || button.nameButton == item[button.show.accion]) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  shouldShowModal(arrayBottons: any[], item: any): boolean {
    let shouldShow = false;

    arrayBottons.forEach(button => {
      if (button.show) {
        if (item[button.show.params] || button.nameButton == item[button.show.accion]) {
          shouldShow = true;
        }
      } else {
        shouldShow = true;
      }
    });

    return shouldShow;
  }
}
