import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { HoldersComponent } from '../pages/holders.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material.module';
import { ApiCore, ApiPoseedores, ApiDocum } from 'api-rest';
import {
  NgbPaginationModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { EmpusaSpinnerComponent } from '@empusa/empusa-core';
import { GeTableComponent } from '../share/components/ge-table.component';
import { GetAttributePipe } from '../share/components/pipe/get-attribute.pipe';
import { GeSelectPaginationComponent } from '../share/components/ge-select-pagination/ge-select-pagination.component';
import { HoldersSearchComponent } from '../pages/components-holders/holders-search/holders-search.component';
import { HoldersDetailComponent } from '../pages/components-holders/holders-detail/holders-detail.component';
import { WasteManagersSearchComponent } from '../pages/components-managers/waste-managers-search/waste-managers-search.component';
import { HoldersDetailGeneralDataComponent } from '../pages/components-holders/holders-detail-general-data/holders-detail-general-data.component';
import { HoldersDetailContactDataComponent } from '../pages/components-holders/holders-detail-contact-data/holders-detail-contact-data.component';
import { HoldersDetailAddressComponent } from '../pages/components-holders/holders-detail-address/holders-detail-address.component';
import { CustomDatePickerModule } from './custom-date-picker.module';
import { HoldersDetailDocumentsComponent } from '../pages/components-holders/holders-detail-documents/holders-detail-documents.component';
import { HoldersContactDialogComponent } from '../pages/components-holders/holders-contact-dialog/holders-contact-dialog.component';
import { HoldersAddressDialogComponent } from '../pages/components-holders/holders-address-dialog/holders-address-dialog.component';
import { HoldersWasteForecastComponent } from '../pages/components-holders/holders-waste-forecast/holders-waste-forecast.component';
import { HoldersContributionsDisComponent } from '../pages/components-holders/holders-contributions-dis/holders-contributions-dis.component';
import { HoldersContributionsDisDialogComponent } from '../pages/components-holders/holders-contributions-dis-dialog/holders-contributions-dis-dialog.component';
import { HoldersSelfInvoicesSearchComponent } from '../pages/components-holders/holders-self-invoices-search/holders-self-invoices-search.component';
import { HoldersUploadProductionNumComponent } from '../pages/components-holders/holders-upload-production-num/holders-upload-production-num.component';
import { WasteManagersDetailComponent } from '../pages/components-managers/waste-managers-detail/waste-managers-detail.component';
import { WasteManagersDetailAddressComponent } from '../pages/components-managers/waste-managers-detail-address/waste-managers-detail-address.component';
import { WasteManagersDetailAuthorizedWasteComponent } from '../pages/components-managers/waste-managers-detail-authorized-waste/waste-managers-detail-authorized-waste.component';
import { WasteManagersDetailContactDataComponent } from '../pages/components-managers/waste-managers-detail-contact-data/waste-managers-detail-contact-data.component';
import { WasteManagersDetailGeneralDataComponent } from '../pages/components-managers/waste-managers-detail-general-data/waste-managers-detail-general-data.component';
import { WasteManagersDetailDocumentComponent } from '../pages/components-managers/waste-managers-detail-document/waste-managers-detail-document.component';
import { WasteManagersAddressDialogComponent } from '../pages/components-managers/waste-managers-address-dialog/waste-managers-address-dialog.component';
import { WasteManagersContactDialogComponent } from '../pages/components-managers/waste-managers-contact-dialog/waste-managers-contact-dialog.component';
import { WasteManagersDetailDocumentDialogComponent } from '../pages/components-managers/waste-managers-detail-document-dialog/waste-managers-detail-document-dialog.component';
import { HoldersNewDocumentDialogComponent } from '../pages/components-holders/holders-new-document-dialog/holders-new-document-dialog.component';
import { HoldersInvoiceNewDocumentDialogComponent } from '../pages/components-holders/holders-invoice-document-dialog/holders-invoice-document-dialog.component';
import { HoldersManagerSelectionDialogComponent } from '../pages/components-holders/holders-manager-selection-dialog/holders-manager-selection-dialog.component';
import { WasteManagersAuthorizedDownDialogComponent } from '../pages/components-managers/waste-managers-authorized-down-dialog/waste-managers-authorized-down-dialog.component';
import { DecimalNumberDirective } from '../share/components/utils/DecimalNumberDirective.directive';
import {TwoDigitDecimalNumberDirective} from "../share/components/utils/TwoDigitDecimalNumberDirective.directive";

export const CoreConfigFactory = () =>
  new ApiCore.Configuration(<ApiCore.ConfigurationParameters>{});

export const CoreBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE CORE ' + environment.urlBackCore);
  const basePath = environment.urlBackCore;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const PoseedoresConfigFactory = () =>
  new ApiPoseedores.Configuration(<ApiPoseedores.ConfigurationParameters>{});

export const PoseedoresBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE POSEEDORES ' + environment.urlBackPoseedores);
  const basePath = environment.urlBackPoseedores;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const DocumConfigFactory = () =>
  new ApiDocum.Configuration(<ApiDocum.ConfigurationParameters>{});

export const DocumBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE DOCUM ' + environment.urlBackDocum);
  const basePath = environment.urlBackDocum;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const explorerRoutes = [
  {
    path: '',
    component: HoldersComponent,
    children: [
      { path: 'search', component: HoldersSearchComponent },
      { path: 'detail/:id', component: HoldersDetailComponent },
      {
        path: 'waste-managers-detail/:id',
        component: WasteManagersDetailComponent,
      },
      {
        path: 'waste-managers-search',
        component: WasteManagersSearchComponent,
      },
      {
        path: 'contributions-dis-search',
        component: HoldersContributionsDisComponent,
      },
      {
        path: 'invoices-search',
        component: HoldersSelfInvoicesSearchComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [
    HoldersComponent,
    HoldersSearchComponent,
    HoldersDetailComponent,
    HoldersDetailGeneralDataComponent,
    HoldersDetailContactDataComponent,
    HoldersDetailAddressComponent,
    HoldersDetailDocumentsComponent,
    HoldersWasteForecastComponent,
    HoldersContactDialogComponent,
    HoldersAddressDialogComponent,
    HoldersNewDocumentDialogComponent,
    GeTableComponent,
    GetAttributePipe,
    GeSelectPaginationComponent,
    WasteManagersSearchComponent,
    HoldersContributionsDisComponent,
    HoldersContributionsDisDialogComponent,
    HoldersSelfInvoicesSearchComponent,
    HoldersInvoiceNewDocumentDialogComponent,
    HoldersUploadProductionNumComponent,
    WasteManagersDetailComponent,
    WasteManagersDetailAddressComponent,
    WasteManagersDetailAuthorizedWasteComponent,
    WasteManagersDetailContactDataComponent,
    WasteManagersDetailGeneralDataComponent,
    WasteManagersDetailDocumentComponent,
    WasteManagersAddressDialogComponent,
    WasteManagersContactDialogComponent,
    WasteManagersDetailDocumentDialogComponent,
    WasteManagersAuthorizedDownDialogComponent,
    HoldersManagerSelectionDialogComponent,
    DecimalNumberDirective,
    TwoDigitDecimalNumberDirective
  ],
  imports: [
    NgbTooltipModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgSelectModule,
    CommonModule,
    // ApiPoseedores.ApiModule.forRoot(PoseedoresConfigFactory),
    ApiDocum.ApiModule.forRoot(DocumConfigFactory),
    RouterModule.forChild(explorerRoutes),

    MaterialModule,
    EmpusaSpinnerComponent,
    CustomDatePickerModule,
  ],
  exports: [HoldersComponent],
  providers: [
    DatePipe,
    {
      provide: ApiPoseedores.BASE_PATH,
      useFactory: PoseedoresBasePathFactory,
      deps: ['environment'],
    },
    {
      provide: ApiDocum.BASE_PATH,
      useFactory: DocumBasePathFactory,
      deps: ['environment'],
    },
  ],
})
export class HoldersHomeModule {
  constructor() {
    let compilationVersion = '0.0.1-R->04/10/23-12:14:49';
    console.log('Home version:' + compilationVersion);
  }
}
