import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { AttachedService } from '../../../services/attached.service';
import { CombosService } from '../../../services/combos.service';
import { DownloadService } from '../../../services/download.service';
import { SpinnerService } from '../../../services/spinner.service';
import Swal from 'sweetalert2';
import { CategoryCenterDialogComponent } from '../category-center-dialog/category-center-dialog.component';
import { PackageDialogComponent } from '../package-dialog/package-dialog.component';
import { PackageDeclarationDeclineDialogComponent } from '../package-declaration-decline-dialog/package-declaration-decline-dialog.component';
import {
  DeclaracionAdheridoControllerService,
  DeclaracionAdheridoFilterDTO,
  PageDTODeclaracionAdheridoDTO,
  DeclaracionAdheridoPayloadDTO,
  DeclaracionAdheridoDTO,
  EstadoDeclaracionControllerService,
  AdheridoControllerService,
  BusquedaAdheridoDTO,
} from 'api-rest';
import moment from 'moment';
import { SnackbarService } from '../../../services/snackbar.service';
import { SelectAttachedDialogComponent } from '../select-attached-dialog/select-attached-dialog.component';
import { BillingReportDialogComponent } from '../billing-report-dialog/billing-report-dialog.component';

@Component({
  selector: 'lib-package-declaration-search',
  templateUrl: './package-declaration-search.component.html',
  styleUrls: ['./package-declaration-search.component.scss'],
})
export class PackageDeclarationSearchComponent {
  pageNumber: number = 0;
  pageSize: number = 10;
  totalElements: number = 0;
  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  estados: any[] = [];
  headArray: any = [
    {
      Head: 'Adherido',
      FieldName: 'adherido',
      Attribute: 'razonSocial',
    },
    {
      Head: 'Documento',
      FieldName: 'adherido',
      Attribute: 'codigoDocumento',
    },
    {
      Head: 'Cod. declaración',
      FieldName: 'codigoDeclaracion',
      Maxtext: 30,
    },
    {
      Head: 'Año de los datos',
      FieldName: 'anioDatos',
    },
    {
      Head: 'Estado',
      FieldName: 'estadoDeclaracion',
      Attribute: 'descripcion',
    },
    {
      Head: 'A facturar',
      FieldName: 'facturar',
      renderValue: (item: any) => (item.facturar ? 'SI' : 'NO'),
    },
    {
      Head: 'Facturada',
      FieldName: 'facturada',
      renderValue: (item: any) => (item.facturada ? 'SI' : 'NO'),
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Detalle',
          show: { params: 'canAccessView', accion: 'view' },
        },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
        {
          nameButton: 'menu',
          array: [
            {
              nameButton: 'inf_dec',
              name: 'Informe Declaración',
              show: { params: 'canAccessInfDec', accion: 'inf_dec' },
            },
            /* Se elimina la opción de finalizar declaración de esta pantalla debido a la comprobación de sectores y %comercialización
             si queremos hacer esa comprobación habría que llamar a un servicio extra (/adheridos/declaracion-centro?declaracionAdherido.id=142)
           {
               nameButton: 'fin_dec',
               name: 'Finalizar Declaración',
               show: { params: 'canAccessFinDec', accion: 'fin_dec' },
             },*/
            {
              nameButton: 'dec_fact',
              name: 'Declaración para facturar',
              show: { params: 'canAccessDecFact', accion: 'dec_fact' },
            },
            {
              nameButton: 'inf_mit',
              name: 'Informe MITERD',
              show: { params: 'canAccessInfMit', accion: 'inf_mit' },
            },
            {
              nameButton: 'rech_dec',
              name: 'Rechazar Declaración',
              show: { params: 'canAccessRechDec', accion: 'rech_dec' },
            },
            {
              nameButton: 'dup_dec',
              name: 'Duplicar Declaración',
              show: { params: 'canAccessDupDec', accion: 'dup_dec' },
            },
            {
              nameButton: 'crear_dec_sust',
              name: 'Crear Declaración Sustitutiva',
              show: {
                params: 'canAccessCrearDecSust',
                accion: 'crear_dec_sust',
              },
            },
            {
              nameButton: 'marcar_facturada',
              name: 'Marcar como facturada',
              show: {
                params: 'canAccessMarcarFacturada',
                accion: 'marcar_facturada',
              },
            },
          ],
        },
      ],
      width: '8',
      permanent: true,
    },
  ];

  headArray2: any = [
    {
      Head: 'Adherido',
      FieldName: 'adherido',
      Attribute: 'razonSocial',
    },
    {
      Head: 'Documento',
      FieldName: 'adherido',
      Attribute: 'codigoDocumento',
    },
    {
      Head: 'Cod. declaración',
      FieldName: 'codigoDeclaracion',
      Maxtext: 30,
    },
    {
      Head: 'Año de los datos',
      FieldName: 'anioDatos',
    },
    {
      Head: 'Estado',
      FieldName: 'estadoDeclaracion',
      Attribute: 'descripcion',
    },
    {
      Head: 'A facturar',
      FieldName: 'facturar',
      renderValue: (item: any) => (item.facturar ? 'SI' : 'NO'),
    },
    {
      Head: 'Facturada',
      FieldName: 'facturada',
      renderValue: (item: any) => (item.facturada ? 'SI' : 'NO'),
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      check: true,
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Detalle',
          show: { params: 'canAccessView', accion: 'view' },
        },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
        {
          nameButton: 'menu',
          array: [
            {
              nameButton: 'inf_dec',
              name: 'Informe Declaración',
              show: { params: 'canAccessInfDec', accion: 'inf_dec' },
            },
            /* Se elimina la opción de finalizar declaración de esta pantalla debido a la comprobación de sectores y %comercialización
             si queremos hacer esa comprobación habría que llamar a un servicio extra (/adheridos/declaracion-centro?declaracionAdherido.id=142)
           {
               nameButton: 'fin_dec',
               name: 'Finalizar Declaración',
               show: { params: 'canAccessFinDec', accion: 'fin_dec' },
             },*/
            {
              nameButton: 'dec_fact',
              name: 'Declaración para facturar',
              show: { params: 'canAccessDecFact', accion: 'dec_fact' },
            },
            {
              nameButton: 'inf_mit',
              name: 'Informe MITERD',
              show: { params: 'canAccessInfMit', accion: 'inf_mit' },
            },
            {
              nameButton: 'rech_dec',
              name: 'Rechazar Declaración',
              show: { params: 'canAccessRechDec', accion: 'rech_dec' },
            },
            {
              nameButton: 'dup_dec',
              name: 'Duplicar Declaración',
              show: { params: 'canAccessDupDec', accion: 'dup_dec' },
            },
            {
              nameButton: 'crear_dec_sust',
              name: 'Crear Declaración Sustitutiva',
              show: {
                params: 'canAccessCrearDecSust',
                accion: 'crear_dec_sust',
              },
            },
            {
              nameButton: 'marcar_facturada',
              name: 'Marcar como facturada',
              show: {
                params: 'canAccessMarcarFacturada',
                accion: 'marcar_facturada',
              },
            },
          ],
        },
      ],
      width: '8',
      permanent: true,
    },
  ];

  gridArray: any[] = [];
  adheridosAll: any[] = [];

  searchForm: FormGroup;

  updateList: boolean = false;
  adheridosEndpoint: string =
    this.environment.urlBackAdheridos +
    `api/v1/adheridos/adheridos/listar-todos?estado=4&estado=6`;
  flagDisabledForm: boolean = false;
  totalSearch: any = 0;
  recordsTotal: number = 0;
  defaultSort: string = 'adherido,desc';
  currentSort: string = '';

  constructor(
    private attachedService: AttachedService,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private downloadSrv: DownloadService,
    private estadoDeclaracionService: EstadoDeclaracionControllerService,
    @Inject('EmpusaAuthenticationService')
    public authService: EmpusaAuthenticationService | any,
    @Inject('environment') private environment: any,
    public spinnerSrv: SpinnerService,
    public comboSrv: CombosService,
    private decAdheridoControllerService: DeclaracionAdheridoControllerService,
    private snackBarSrv: SnackbarService,
    private adheridoService: AdheridoControllerService
  ) {
    this.searchForm = this.formBuilder.group({
      adherido: [{ value: null, disabled: false }],
      anio: [{ value: null, disabled: false }],
      estadoDeclaracion: [{ value: null, disabled: false }],
      afacturar: [{ value: null, disabled: false }],
      facturada: [{ value: null, disabled: false }],
    });
  }
  abrirPantalla(id: number): void {
    this.router.navigate(['/attached-app/packaging-declaration-detail', id]);
  }

  ngOnInit(): void {
    if (this.canViewResource('DE-ENV-GEST')) {
      this.headArray = this.headArray2;
      this.getListarTodosAdheridos();
    } else {
      this.getAdheridos();
    }

    this.headArray = this.canViewResource('DE-ENV-GEST')
      ? this.headArray
      : this.headArray.filter(
        (head: any) =>
          head.FieldName !== 'facturar' &&
          head.Head !== 'Documento' &&
          head.FieldName !== 'facturada'
      );
    this.cargarCombos();
    if (this.attachedService.searchFormPackDecl)
      this.searchForm = this.attachedService.searchFormPackDecl;
  }

  cargarCombos(): void {
    this.estadoDeclaracionService
      .findAll3({}, { page: 0, size: 500, sort: [] })
      .subscribe({
        next: (responses: any) => {
          this.estados = responses.datos;
        },
      });
  }

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.searchData();
    }
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  checked(item: any) { }

  action(item: any) {
    switch (item.nameButton) {
      case 'view':
      case 'edit':
        this.abrirPantalla(item.id);
        break;
      case 'cancel':
        // Trabajar en el caso de 'cancel'
        break;
      case 'delete':
        this.confirmacionEliminar(item);
        break;
      case 'inf_dec':
        this.spinnerSrv.loadingShow();
        this.decAdheridoControllerService
          .informeDeclaracion(item.id)
          .subscribe({
            next: (response: any) => {
              this.spinnerSrv.loadingHide();
              this.downloadSrv.downloadBlob(
                response,
                'Informe_declaración.xls'
              );
              this.snackBarSrv.showSnackBar(
                'Se ha generado el informe de declaración correctamente',
                'success'
              );
              this.searchData();
            },
            error: () => {
              this.spinnerSrv.loadingHide();
            },
          });
        break;
      /* Se elimina la opción de finalizar declaración de esta pantalla debido a la comprobación de sectores y %comercialización
         si queremos hacer esa comprobación habría que llamar a un servicio extra. (/adheridos/declaracion-centro?declaracionAdherido.id=142)
      case 'fin_dec':
         console.log('item', item);
         Swal.fire({
           text: `¿Está seguro de finalizar y dar por completada la declaración? No podrán agregarse más envases a esta declaración.`,
           icon: 'question',
           showDenyButton: true,
           showCancelButton: false,
           confirmButtonText: 'Sí',
           denyButtonText: 'No',
           allowOutsideClick: false,
         }).then((result: any) => {
           if (result.isConfirmed) {
             this.finalizarDeclaracion(item);
           }
         });
         break;*/
      case 'dec_fact':
        this.spinnerSrv.loadingShow();
        this.decAdheridoControllerService.paraFacturar(item.id).subscribe({
          next: () => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'El proceso de declaración para facturar finalizado correctamente',
              'success'
            );
            this.searchData();
          },
          error: () => {
            this.spinnerSrv.loadingHide();
          },
        });
        break;
      case 'inf_mit':
        this.spinnerSrv.loadingShow();
        this.decAdheridoControllerService.informeMiter(item.id).subscribe({
          next: (response) => {
            this.spinnerSrv.loadingHide();
            this.downloadSrv.downloadBlob(response, 'Informe_MITERD.xls');
            this.snackBarSrv.showSnackBar(
              'El informe del MITERD se ha generado correctamente',
              'success'
            );
          },
          error: () => {
            this.spinnerSrv.loadingHide();
          },
        });
        break;
      case 'rech_dec':
        this.openModalMotivoRechazo(item);
        break;
      case 'dup_dec':
        this.spinnerSrv.loadingShow();
        this.decAdheridoControllerService
          .duplicarDeclaracion(item.id)
          .subscribe({
            next: () => {
              this.spinnerSrv.loadingHide();
              this.snackBarSrv.showSnackBar(
                'El proceso duplicar declaración finalizado correctamente',
                'success'
              );
              this.searchData();
            },
            error: () => {
              this.spinnerSrv.loadingHide();
            },
          });
        break;
      case 'crear_dec_sust':
        this.spinnerSrv.loadingShow();
        this.decAdheridoControllerService.crearSustitutiva(item.id).subscribe({
          next: () => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'El proceso crear sustitutiva finalizado correctamente',
              'success'
            );
            this.searchData();
          },
          error: () => {
            this.spinnerSrv.loadingHide();
          },
        });
        break;
      case 'marcar_facturada':
        this.spinnerSrv.loadingShow();
        this.decAdheridoControllerService.facturada(item.id).subscribe({
          next: () => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'La declaración se ha marcado como facturada correctamente',
              'success'
            );
            this.searchData();
          },
          error: () => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al marcar la declaración como facturada',
              'error'
            );
          },
        });
        break;
      default:
        // Manejar un caso por defecto
        break;
    }
  }
  /* Se elimina la opción de finalizar declaración de esta pantalla debido a la comprobación de sectores y %comercialización
          si queremos hacer esa comprobación habría que llamar a un servicio extra. (/adheridos/declaracion-centro?declaracionAdherido.id=142)
  finalizarDeclaracion(item: any): void {
     this.spinnerSrv.loadingShow();
     this.decAdheridoControllerService
       .finalizarDeclaracion(item.id)
       .subscribe({
         next: () => {
           this.spinnerSrv.loadingHide();
           this.snackBarSrv.showSnackBar(
             'Se ha finalizado la declaración correctamente',
             'success'
           );
           this.searchData();
         },
         error: () => {
           this.spinnerSrv.loadingHide();
         },
       });
   }*/
  openModalPackageDeclaration() { }

  private getBackendFilter() {
    const anioDatos = this.searchForm.get('anio')?.value;
    const estadoId = this.searchForm.get('estadoDeclaracion')?.value;

    let adheridos = this.searchForm.get('adherido')?.value;

    if (!this.canViewResource('DE-ENV-GEST') && (!adheridos || adheridos?.length === 0)) {
      //Para AD-ADHERIDO y DE_ADHERIDO si no relleno el campo adherido, envío en el filtro los que tengo disponibles.
      adheridos = this.adheridosAll.map((x) => x.id);
    }
    //Para DE-ENV-GEST si no relleno el campo adherido, no envío nada en el filtro.

    const backendFilter: DeclaracionAdheridoFilterDTO = {
      anioDatos: anioDatos,
      estadoDeclaracion: {
        id: estadoId,
      },
      adherido: {
        id: adheridos?.map((objeto: any) =>
          objeto.id !== undefined ? objeto.id : objeto
        ),
      },
      facturar:
        this.searchForm.get('afacturar')?.value == false
          ? undefined
          : this.searchForm.get('afacturar')?.value,
      facturada:
        this.searchForm.get('facturada')?.value == false
          ? undefined
          : this.searchForm.get('facturada')?.value,
    };

    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;
    const sort = this.currentSort != '' ? this.currentSort : this.defaultSort;
    return { backendFilter, page, size: this.pageSize, sort };
  }

  getDeclaraciones() {
    const { backendFilter, page, size, sort } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.decAdheridoControllerService
      .findAll7(backendFilter, {
        page: page,
        size: size,
        sort: [sort],
      })
      .subscribe({
        next: (data: PageDTODeclaracionAdheridoDTO) => {
          if (data) {
            this.spinnerSrv.loadingHide();
            this.gridArray = this.canAccessAll(data.datos) ?? [];
            this.flagSearch = this.gridArray?.length != 0 ? true : false;
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.totalSearch = this.gridArray.length;
          }
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  searchData(inicialPage?: boolean) {
    if (inicialPage) {
      this.pageNumber = 0;
    }

    if (this.adheridosAll.length == 0) {
      this.snackBarSrv.showSnackBar(
        `El estado de su adherido/s es incorrecto. Por favor, revisa la ficha de sus datos para completa su proceso de registro.`,
        'error'
      );
    } else {
      this.getDeclaraciones();
    }
  }

  clearSearcher() {
    this.searchForm.reset();
    this.flagSearch = false;
    this.gridArray = [];
    this.currentSort = '';
  }

  exportarCsv() {
    let filter = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.decAdheridoControllerService
      .exportCSV2(filter.backendFilter)
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.downloadSrv.downloadBlob(response, 'declaracionesEnvases.csv');
        },
        error: (err: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al exportar las declaraciones de envases',
            'error'
          );
        },
      });
  }

  canAccessExport(): boolean {
    return this.authService.can('dec_envases', 'update-exportar');
  }

  public filterList(data: any) { }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'adherido': {
        return this.searchForm.get('adherido') as FormControl;
      }
      default: {
        return this.searchForm.get('') as FormControl;
      }
    }
  }

  selectedModel() { }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.searchForm.controls[controlName]?.touched ||
        this.searchForm.controls[controlName]?.dirty) &&
      this.searchForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  canAccessPreliminaryDischarge(): boolean {
    return this.authService.can('adherido', 'create');
  }

  canAccessAll(list: any) {
    const resultView = this.authService.can('dec_envases', 'view');
    const resultListEdit = this.authService.can('dec_envases', 'update');
    const resultDelete = this.authService.can('dec_envases', 'delete');
    const resultCanAccessInfDec = this.authService.can(
      'dec_envases',
      'update-informe'
    );
    const resultCanAccessFinDec = this.authService.can(
      'dec_envases',
      'update-finalizar'
    );
    const resultCanAccessDecFact = this.authService.can(
      'dec_envases',
      'update-facturar'
    );
    const resultCanAccessInfMit = this.authService.can(
      'dec_envases',
      'update-miterd'
    );
    const resultCanAccessRechDec = this.authService.can(
      'dec_envases',
      'update-rechazar'
    );
    const resultCanAccessDupDec = this.authService.can(
      'dec_envases',
      'update-duplicar'
    );
    const resultCanAccessCrearDecSust = this.authService.can(
      'dec_envases',
      'update-sustitutiva'
    );
    const resultCanAccessMarcarFacturada = this.authService.can(
      'dec_envases',
      'access-facturar'
    );

    const listPermission = list.map((objeto: any) => {
      const estado0 =
        objeto.estadoDeclaracion && objeto.estadoDeclaracion.id === 0;
      const estadoCanAccessFinDec =
        objeto.estadoDeclaracion && objeto.estadoDeclaracion.id === 0;
      const estadoCanAccessDecFact =
        objeto.estadoDeclaracion && objeto.estadoDeclaracion.id === 1;
      const estadoCanAccessInfMit =
        objeto.estadoDeclaracion && objeto.estadoDeclaracion.id === 1;
      const estadoCanAccessRechDec =
        objeto.estadoDeclaracion && objeto.estadoDeclaracion.id === 1;
      const estadoCanAccessDupDec =
        objeto.estadoDeclaracion && objeto.estadoDeclaracion.id === 1;
      const estadoCanAccessCrearDecSust =
        objeto.estadoDeclaracion && objeto.estadoDeclaracion.id === 2;
      const facturarCanAccessDecFact =
        objeto.estadoDeclaracion && objeto.facturar === true;
      const aFacturar = objeto.facturar === true;
      return {
        ...objeto,
        canAccessView: resultView,
        canAccessListEdit: resultListEdit && estado0,
        canAccessDelete: estado0 && resultDelete,
        canAccessInfDec: resultCanAccessInfDec,
        canAccessFinDec: estadoCanAccessFinDec && resultCanAccessFinDec,
        canAccessDecFact:
          estadoCanAccessDecFact &&
          resultCanAccessDecFact &&
          !facturarCanAccessDecFact,
        canAccessInfMit: estadoCanAccessInfMit && resultCanAccessInfMit,
        canAccessRechDec: estadoCanAccessRechDec && resultCanAccessRechDec,
        canAccessDupDec: estadoCanAccessDupDec && resultCanAccessDupDec,
        canAccessCrearDecSust:
          estadoCanAccessCrearDecSust && resultCanAccessCrearDecSust,
        canAccessMarcarFacturada: resultCanAccessMarcarFacturada && aFacturar,
      };
    });

    return listPermission;
  }

  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  confirmacionCreacionDeclaracion(element?: any): void {
    Swal.fire({
      text: `¿De qué año quiere informar los datos de la declaración?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Declaración Inicial',
      denyButtonText: 'Declaración Ordinaria',
      allowOutsideClick: true,
      didOpen: () => {
        const confirmButtonContainer = document.createElement('div');
        confirmButtonContainer.innerHTML =
          '* Declaración Inicial: Incluya Datos Estimados o últimos datos disponibles';
        confirmButtonContainer.style.fontSize = '12px';
        confirmButtonContainer.style.marginRight = '56px';
        const confirmDeny = Swal.getDenyButton();
        if (confirmDeny) {
          confirmDeny.parentNode?.insertBefore(
            confirmButtonContainer,
            confirmDeny.nextSibling
          );
        }
      },
    }).then((result: any) => {
      if (result.isConfirmed || result.isDenied) {
        const isConfirmed = result.isConfirmed;
        const payLoad: DeclaracionAdheridoPayloadDTO = {
          anioDatos: isConfirmed
            ? moment().year().toString()
            : moment().subtract(1, 'year').year().toString(),
        };
        const allAdheridosIds = this.adheridosAll.map(
          (adherido) => adherido.id
        );
        const adheridosIds = this.getAdheridosId();
        const filteredAdheridosIds = adheridosIds?.filter((id) =>
          allAdheridosIds.includes(id)
        );
        if (filteredAdheridosIds && filteredAdheridosIds.length > 1) {
          this.openModalSelectorAttached(payLoad, filteredAdheridosIds);
        } else if (filteredAdheridosIds && filteredAdheridosIds.length === 1) {
          payLoad.adherido = {
            id: filteredAdheridosIds[0],
          };
          this.crearDeclaracionAdherido(payLoad);
        } else {
          this.snackBarSrv.showSnackBar(
            'No tienes adheridos en estado Pdte Declaración Envases o Completado para crear una nueva declaración',
            'error'
          );
        }
      }
    });
  }

  crearDeclaracionAdherido(payLoad: DeclaracionAdheridoPayloadDTO) {
    this.spinnerSrv.loadingShow();
    this.decAdheridoControllerService.create5(payLoad).subscribe({
      next: (data: DeclaracionAdheridoDTO) => {
        this.router.navigate([
          '/attached-app/packaging-declaration-detail',
          data.id,
        ]);
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
      complete: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  confirmacionEliminar(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar la declaración:"${element.id} - ${element.adherido.razonSocial}"?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarDeclaracion(element);
      }
    });
  }

  eliminarDeclaracion(element?: any) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.decAdheridoControllerService.deleteById5(element.id).subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            `La declaración de envases se ha eliminado correctamente`,
            'success'
          );
          this.gridArray = this.gridArray.filter(
            (objeto) => objeto.id !== element.id
          );
          this.flagSearch = this.gridArray.length != 0 ? true : false;
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
    }
  }

  openModalCategoryCenter(element?: any): void {
    const dialogRef = this.dialog.open(CategoryCenterDialogComponent, {
      width: '950px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: {
        itemOpen: element,
      },
      disableClose: true,
    });
  }

  openModalPackage(element?: any): void {
    const dialogRef = this.dialog.open(PackageDialogComponent, {
      maxWidth: '95%',
      maxHeight: '95vh',
      data: {
        itemOpen: element,
      },
      disableClose: true,
    });
  }

  openModalSelectorAttached(payload?: any, adheridoIds?: any): void {
    const dialogRef = this.dialog.open(SelectAttachedDialogComponent, {
      width: '550px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: {
        payload: payload,
        adheridoIds: adheridoIds,
      },
      disableClose: true,
    });
  }

  openModalMotivoRechazo(item:any){
    const dialogRef = this.dialog.open(
      PackageDeclarationDeclineDialogComponent,
      {
        width: '750px',
        maxWidth: '95%',
        maxHeight: '95vh',
        data: {
          info: item,
        },
        disableClose: true,
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.searchData();
      }
    });
  }

  getAdheridosId(): number[] | null {
    if (this.authService.user?.roleEntities?.length > 0) {
      const adheridosIds = this.authService.user.roleEntities
        .filter((adheridoRol: any) => adheridoRol.type === 'ADHERIDO')
        .map((adheridoRol: any) => adheridoRol.entity as number)
        .filter(
          (entity: number | null, index: any, self: any) =>
            entity !== null && self.indexOf(entity) === index
        );

      return adheridosIds;
    }
    return null;
  }

  getAdheridos() {
    if (this.authService.user?.roleEntities?.length > 0) {
      const adheridoIds = this.authService.user?.roleEntities
        .filter((adheridoRol: any) => adheridoRol.type === 'ADHERIDO')
        .map((adheridoRol: any) => adheridoRol.entity as number);
      const estados = [4, 6]; //Filtrar para que aparezcan los adheridos en estado Pdte Declaración Envases o Completado (5 o 6)
      const filter: BusquedaAdheridoDTO = {
        id: adheridoIds,
        estado: estados,
      };
      this.spinnerSrv.loadingShow();
      this.adheridoService
        .listarAdheridos({ page: 0, size: 100 }, filter)
        .subscribe({
          next: (response: any) => {
            this.adheridosAll = response?.datos;
            this.spinnerSrv.loadingHide();
          },
          error: (err) => {
            console.log(err);
            this.spinnerSrv.loadingHide();
          },
        });
    }
  }

  getListarTodosAdheridos() {
    const estados = [4, 6]; //Filtrar para que aparezcan los adheridos en estado Pdte Declaración Envases o Completado (5 o 6)
    const filter: BusquedaAdheridoDTO = {
      estado: estados,
    };
    this.spinnerSrv.loadingShow();
    this.adheridoService.listarTodos({ page: 0, size: 100 }, filter).subscribe({
      next: (response: any) => {
        this.adheridosAll = response?.datos;
        this.spinnerSrv.loadingHide();
      },
      error: (err) => {
        console.log(err);
        this.spinnerSrv.loadingHide();
      },
    });
  }

  canViewResource(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

  abrirModalInforme() {
    const dialogRef = this.dialog.open(BillingReportDialogComponent, {
      width: '550px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: {},
      disableClose: true,
    });
  }

  sortGrid(element: any){
    this.currentSort = `${element.name},${element.type}`;
    this.pageNumber = 0;
    this.searchData();
  }

  declaracionesAFacturar(){
    const list = this.gridArray
      .filter((x) => x.checked === true);

    if (list.length == 0) {
      this.snackBarSrv.showSnackBar(
        `Debe seleccionar al menos una declaración para facturar`,
        'error'
      );
      return;
    }
    const resultCanAccessDecFact = this.authService.can(
      'dec_envases',
      'update-facturar'
    );
	
    const validEstado = list.every((item:any) => item.estadoDeclaracion?.id === 1	); 	
    const validFacturar = list.every((item:any) => item.facturar == false); 	
    
    if(validEstado && validFacturar && resultCanAccessDecFact){
      const idsFacturar = list.map((item:any) => item.id);
      this.decAdheridoControllerService.paraFacturarList(idsFacturar).subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'El proceso de declaración para facturar finalizado correctamente',
            'success'
          );
          this.searchData();
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
    }else{
      this.snackBarSrv.showSnackBar(
        `Algunas declaraciones seleccionadas no son válidas para facturar.`,
        'error'
      );
      return;
    }

  }

  marcarDeclaracionesFacturadas(){
    const list = this.gridArray
      .filter((x) => x.checked === true);

    if (list.length == 0) {
      this.snackBarSrv.showSnackBar(
        `Debe seleccionar al menos una declaración para marcar como Facturada`,
        'error'
      );
      return;
    }
    const resultCanAccessMarcarFacturada = this.authService.can(
      'dec_envases',
      'access-facturar'
    );

    const validFacturada = list.every((item:any) => item.facturar); 	
    
    if(validFacturada && resultCanAccessMarcarFacturada){
      const idsFacturar = list.map((item:any) => item.id);
      this.decAdheridoControllerService.facturadaList(idsFacturar).subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'El proceso de declaración para facturar finalizado correctamente',
            'success'
          );
          this.searchData();
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });

    }else{
      this.snackBarSrv.showSnackBar(
        `Algunas declaraciones seleccionadas no son válidas para marcar como facturadas.`,
        'error'
      );
      return;
    }
   
  }

}
