<!-- Modal Alta Preliminar-->
<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="modal-content">

  <div class="modal-header">
    <h3 class="modal-title font">{{titulo}}</h3>
    <button type="button" (click)="closeDialog()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
    <form class="form-modal py-3" [formGroup]="dataForm">
      <div class="row form">
      </div>
      <div class="row form pt-2">
        <div class="col-sm">
          <label for="denominacion">Denominación <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field-width" appearance="outline">
              <input type="text" matInput formControlName="denominacion" id="denominacion" placeholder="" value="" maxlength="255">
              <mat-error *ngIf="controlHasError('denominacion', 'required')">Campo obligatorio</mat-error>
              <mat-error *ngIf="controlHasError( 'denominacion', 'maxlength')">Máximo 255 caracteres</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <mat-tab-group class="my-tab-container">
        <mat-tab label="Dirección Física del Centro">
          <div>
            <div class="row form justify-form pt-2">
              <div class="col-sm flex-grow-0">
                <label for="country">País <span class="oblig">*</span></label>
                <div class="custom-mat-field-select">
                  <lib-ge-select-pagination (selectedModel)="selectedModel('country')" [endPointFilter]="paisesEndpoint"
                    (filteredList)="filterList($event,'country')" [updateList]="updateList"
                    [disabled]="flagDisabledForm" [formControlNameSelect]="getFormControl('country')"
                    [placeHolder]="'País'" [objQueryName]="'nombre'" descripcion="nombre" [defaultValue]=69
                    [customClass]="rightHolder('country')">
                  </lib-ge-select-pagination>
                  <div *ngIf="controlHasErrorSelect('country')" class="mat-error">
                    Campo obligatorio
                  </div>
                </div>
              </div>
            </div>
            <div class="row form justify-form mt-2">
              <div class="col-sm flex-grow-0">
                <label *ngIf="esES()" for="cp">CP <span class="oblig">*</span></label>
                <label *ngIf="!esES()" for="cp">CP</label>
                <div class="custom-mat-field-select">
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input formControlName="cp" id="cp" placeholder="CP" matInput type="text">
                    <mat-error *ngIf="controlHasError('cp', 'required')">Campo obligatorio</mat-error>
                    <mat-error *ngIf="controlHasError('cp', 'pattern')">Máximo 5 dígitos</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm flex-grow-1" *ngIf="esES()">
                <label for="county">Provincia <span class="oblig">*</span></label>
                <div class="custom-mat-form-field-width">
                  <lib-ge-select-pagination class="lib-ge-select" (selectedModel)="selectedModel('county')"
                    [endPointFilter]="provinciasEndpoint" (filteredList)="filterList($event,'county')"
                    [updateList]="updateList" [disabled]="flagDisabledForm"
                    [formControlNameSelect]="getFormControl('county')" [placeHolder]="'Provincia'"
                    [objQueryName]="'nombre'" descripcion="nombre" [customClass]="rightHolder('county')">
                  </lib-ge-select-pagination>
                  <div *ngIf="controlHasErrorSelect('county')" class="mat-error">
                    Campo obligatorio
                  </div>
                </div>
              </div>
              <div class="col-sm flex-grow-1" *ngIf="!esES()">
                <label for="prov">Provincia</label>
                <div class="custom-mat-form-field-widtht">
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input formControlName="county" id="prov" placeholder="Provincia" matInput type="text" maxlength="255">
                    <mat-error *ngIf="controlHasError( 'county', 'maxlength')">Máximo 255 caracteres</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm flex-grow-1" *ngIf="!esES()">
                <label for="municipality">Municipio</label>
                <div class="custom-mat-form-field-width">
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input formControlName="municipality" id="municipality" placeholder="Municipio" matInput
                      type="text" maxlength="255">
                    <mat-error *ngIf="controlHasError( 'municipality', 'maxlength')">Máximo 255 caracteres</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm flex-grow-1" *ngIf="esES()">
                <label for="municipality">Municipio <span class="oblig">*</span></label>
                <div class="custom-mat-form-field-width">
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input formControlName="municipality" id="municipality" placeholder="Municipio" matInput
                      type="text" maxlength="255">
                    <mat-error *ngIf="controlHasError('municipality', 'required')">Campo obligatorio</mat-error>
                    <mat-error *ngIf="controlHasError( 'municipality', 'maxlength')">Máximo 255 caracteres</mat-error>
                  </mat-form-field>
                </div>
              </div>

            </div>
            <div class="row form justify-form">
              <div class="col-sm flex-grow-3">
                <label for="roadName">Tipo y Nombre de vía <span *ngIf="esES()" class="oblig">*</span></label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input type="text" matInput formControlName="roadName" id="roadName" placeholder="Nombre vía"
                      value="" maxlength="100">
                    <mat-error *ngIf="controlHasError('roadName', 'required')">Campo obligatorio</mat-error>
                    <mat-error *ngIf="controlHasError( 'roadName', 'maxlength')">Máximo 100 caracteres</mat-error>
                  </mat-form-field>
                </div>
              </div>

            </div>
            <div class="row form justify-betw">
              <div class="col-sm flex-grow-0">
                <label for="number">Número <span *ngIf="esES()" class="oblig">*</span></label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-wpx-block">
                    <input type="text" matInput formControlName="number" id="number" placeholder="Nº" value="" maxlength="5">
                    <mat-error *ngIf="esES()?controlHasError('number', 'required'):false">Campo obligatorio
                    </mat-error>
                    <mat-error *ngIf="controlHasError( 'number', 'maxlength')">Máximo 5 caracteres</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm flex-grow-0">
                <label for="buildingBlock">Bloque</label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-wpx-block">
                    <input type="text" matInput formControlName="buildingBlock" id="buildingBlock" placeholder="Bloque"
                      value="" maxlength="5">
                    <mat-error *ngIf="controlHasError( 'buildingBlock', 'maxlength')">Máximo 5 caracteres</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm flex-grow-0">
                <label for="stairs">Escalera</label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-wpx-block">
                    <input type="text" matInput formControlName="stairs" id="stairs" placeholder="Escalera" value="" maxlength="5">
                    <mat-error *ngIf="controlHasError( 'stairs', 'maxlength')">Máximo 5 caracteres</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm flex-grow-0">
                <label for="floor">Piso</label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-wpx-block">
                    <input type="text" matInput formControlName="floor" id="floor" placeholder="Piso" value="" maxlength="5">
                    <mat-error *ngIf="controlHasError( 'floor', 'maxlength')">Máximo 5 caracteres</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm flex-grow-0">
                <label for="door">Puerta</label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-wpx-block">
                    <input type="text" matInput formControlName="door" id="door" placeholder="Puerta" value="" maxlength="5">
                    <mat-error *ngIf="controlHasError( 'door', 'maxlength')">Máximo 5 caracteres</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row form justify-form">
              <div class="col-sm flex-grow-2">
                <label for="otherData">Otros datos</label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input type="text" matInput formControlName="otherData" id="otherData" placeholder="Otros datos"
                      value="" maxlength="100">
                    <mat-error *ngIf="controlHasError( 'otherData', 'maxlength')">Máximo 100 caracteres</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab label="Contacto">
          <div>
            <div class="row form pt-3">
              <div class="col-sm flex-grow-1">
                <label for="nameContac">Nombre <span class="oblig">*</span></label>
                <div class="custom-mat-field">

                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input type="text" matInput formControlName="nameContac" id="nameContac" placeholder="Nombre"
                      value="" maxlength="50">
                    <mat-error *ngIf="controlHasError('nameContac', 'required')">Campo obligatorio</mat-error>
                    <mat-error *ngIf="controlHasError( 'nameContac', 'maxlength')">Máximo 50 caracteres</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm flex-grow-1">
                <label for="firstSurname">Apellido 1<span class="oblig">*</span></label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input type="text" matInput formControlName="firstSurname" id="firstSurname"
                      placeholder="Apellido 1" value="" maxlength="50">
                    <mat-error *ngIf="controlHasError('firstSurname', 'required')">Campo obligatorio</mat-error>
                    <mat-error *ngIf="controlHasError( 'firstSurname', 'maxlength')">Máximo 50 caracteres</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm flex-grow-1">
                <label for="secondSurname">Apellido 2</label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input type="text" matInput formControlName="secondSurname" id="secondSurname"
                      placeholder="Apellido 2" value="" maxlength="50">
                    <mat-error *ngIf="controlHasError( 'secondSurname', 'maxlength')">Máximo 50 caracteres</mat-error>

                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row form">
              <div class="col-sm flex-grow-1">
                <label for="tlfType">Tipo teléfono <span class="oblig">*</span></label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <mat-select formControlName="tlfType" id="tlfType" placeholder="Tipo teléfono">
                      <mat-option *ngFor="let tipo of tiposTelefono" [value]="tipo.id">
                        {{tipo.name}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="controlHasError('tlfType', 'required')">Campo obligatorio</mat-error>
                    <mat-error *ngIf="controlHasError('tlfType', 'pattern')">Campo obligatorio</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="col-sm flex-grow-1">
                <label for="countryCode">Prefijo País <span class="oblig">*</span></label>
                <div class="custom-mat-form-field-width">
                  <lib-ge-select-pagination class="lib-ge-select" [endPointFilter]="paisesEndpoint"
                    (filteredList)="filterList($event,'country')" [updateList]="updateList"
                    [formControlNameSelect]="getFormControl('countryCode')" [placeHolder]="'Prefijo pais'"
                    [objQueryName]="'prefijo'" descripcion="prefijoNombre" [customClass]="rightHolder('countryCode')"
                    [defaultValue]=69 [selectDefaultValue]="true" (selectedModel)="selectedPrefijoPais($event)" [disabled]="flagDisabledForm">
                  </lib-ge-select-pagination>
                  <div *ngIf="controlHasErrorSelect('countryCode')" class="mat-error">
                    Campo obligatorio
                  </div>
                </div>
              </div>
              <div class="col-sm flex-grow-1">
                <label for="tlfNumber">Nº teléfono <span class="oblig">*</span></label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input type="text" matInput formControlName="tlfNumber" id="tlfNumber" placeholder="Nº teléfono"
                      value="" maxlength="15">
                    <mat-error *ngIf="controlHasError('tlfNumber', 'invalidPhoneFormat')">
                      Formato incorrecto.</mat-error>
                    <mat-error *ngIf="!controlHasError('tlfNumber', 'invalidPhoneFormat') && controlHasError('tlfNumber',
             'invalidTelefonoFijo')">
                      Formato incorrecto para tipo Fijo con prefijo 34.
                    </mat-error>
                    <mat-error *ngIf="!controlHasError('tlfNumber', 'invalidPhoneFormat') && !controlHasError('tlfNumber',
             'invalidTelefonoFijo') && controlHasError('tlfNumber', 'invalidTelefonoMovil')">
                      Formato incorrecto para tipo Movil con prefijo 34.
                    </mat-error>
                    <mat-error *ngIf="controlHasError( 'tlfNumber', 'maxlength')">Máximo 15 caracteres</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="row form">
              <div class="col-sm flex-grow-2">
                <label for="email">Email <span class="oblig">*</span></label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input type="text" matInput formControlName="email" id="email" placeholder="Email" value="" maxlength="100">
                    <mat-error *ngIf="controlHasError('email', 'required')">Campo obligatorio</mat-error>
                    <mat-error *ngIf="controlHasError('email', 'pattern')">Formato incorrecto</mat-error>
                    <mat-error *ngIf="controlHasError( 'email', 'maxlength')">Máximo 100 caracteres</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </mat-tab>
      </mat-tab-group>
    </form>
  </div>

  <div class="fixed-footer">
    <div class="modal-footer actions">
      <div class="note"></div>
      <div class="button-group">
        <button type="button" (click)="closeDialog()" class="btn btn-outline-primary"
          aria-label="Close">Cancelar</button>
        <button *ngIf="btonGuardar" type="button" class="btn btn-primary" aria-label="Close"
          (click)="saveData()">Guardar</button>
      </div>
    </div>
  </div>

</div>
