<div class="bg-second-migas">
  <div class="container mt-cab-fixed">
    <div class="pre-content">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">Adheridos</li>
          <li *ngIf="flagRuta" class="breadcrumb-item active"><a href="#/attached-app/search">Búsqueda de Adheridos</a>
          </li>
          <li class="breadcrumb-item active">Datos de Adherido</li>
        </ol>
      </nav>

      <div class="d-flex content-title">
        <div class="d-flex">
          <h2 class="title-pag">Datos de Adherido</h2>
          <div *ngIf="datosCargados" class="custom-container" [ngStyle]="{'background-color': ((rechazado || pendienteAdherido) ? '#fff3af' : pendienteEnvalora ? '#8edbfff5' :
                pendienteCompletado ?
                '#a4ffb0f5' :'#ffffff')}">
            <div>
              <label style="font-size: larger; padding-top: 5px;">
                <mat-icon class="show-icon" [ngStyle]="{'color': (rechazado ? 'red' : pendienteAdherido ? '#eb1d1d' : pendienteEnvalora ? '#004a9a' :
                pendienteCompletado ?
                'darkgreen' :'initial')}">{{ pendienteCompletado ? 'check_circle' : 'warning_amber'}}</mat-icon>
              </label>
            </div>
            <div>
              <label class="info-texto-estado" [ngStyle]="{'color': ( rechazado ? 'red' :  pendienteAdherido ? '#eb1d1d' : pendienteEnvalora ? '#004a9a' :
                pendienteCompletado ?
                'darkgreen' :'initial')}">
                {{textoEstado}}</label>
            </div>
            <div *ngIf="this.adheridoInfo?.estado?.infoAdicional" style="padding-top: 8px;">
              <div class="tool-help" matTooltip={{this.adheridoInfo?.estado?.infoAdicional}}>
                <span>i</span>
              </div>
            </div>
          </div>
        </div>
        <div class="nota mt-2">Los campos señalados con asterisco <span class="oblig">*</span> son obligatorios
        </div>
      </div>

      <!-- <div class="d-flex content-title-val">
        <div class="d-flex">
          <h2 class="title-pag">Datos de Adherido</h2>
          <span class="etiqueta info-version"><label style="font-size: larger;">
              <mat-icon class="show-icon">warning_amber</mat-icon>
            </label>Pendiente asda asdasd a</span>
        </div>
        <div class="nota mt-2">Los campos señalados con asterisco <span class="oblig">*</span> son obligatorios</div>
      </div> -->
    </div>
  </div>
</div>
<!-- FIN MIGAS DE PAN Y TÍTULO -->

<!-- CONTENIDO - FICHA -->
<div class="bg-second-table-info pt-0 pb-5">
  <div class="container">
    <div class="card-ficha">
      <div class="card-body">
        <div class="ficha-2col">
          <div class="col-tab-list">
            <ul class="list">
              <li [ngClass]=" {'active':(selectedMenu===1)}"
                (click)="((esGestorEnvalora() || !canAccessContinuarAll(1))) ? loadNumMenu(1) : mensajeValidacion()">1.
                Datos generales</li>
              <li [ngClass]="{'active':(selectedMenu===2)}"
                (click)="((esGestorEnvalora() || !canAccessContinuarAll(1)))?loadNumMenu(2):mensajeValidacion()">2.
                Datos
                de contacto</li>
              <li [ngClass]="{'active':(selectedMenu===3)}"
                (click)="((esGestorEnvalora() || !canAccessContinuarAll(1)))?loadNumMenu(3):mensajeValidacion()">3.
                Direcciones</li>
              <li *ngIf="canAccessDocumentos() && estadoAdherido!>=3" [ngClass]="{'active':(selectedMenu===4)}"
                (click)="loadNumMenu(4)">
                4.
                Documentos</li>
              <li *ngIf="canAccessCentroEnvasados() && estadoAdherido!>=4 && estadoAdherido !== 8" [ngClass]="{'active':(selectedMenu===5)}"
                (click)="loadNumMenu(5)">5.
                Centros de envasado</li>
              <li *ngIf="canAccessPrevisionSDDR() && estadoAdherido!>=6 && estadoAdherido !== 8 " [ngClass]="{'active':(selectedMenu===6)}" (click)="loadNumMenu(6)">6.
                Previsión de residuos SDDR Cerrado</li>
            </ul>
          </div>
          <div *ngIf="selectedMenu===1" class="col-content">
            <lib-detail-general-data [adheridoInfo]="adheridoInfo" (refrescarAdheridoEvent)="refrescar(1)">
            </lib-detail-general-data>
          </div>
          <div *ngIf="selectedMenu===2" class="col-content">
            <lib-detail-contact-data [adheridoInfo]="adheridoInfo" (refrescarAdheridoEvent)="refrescar(2)">
            </lib-detail-contact-data>
          </div>
          <div *ngIf="selectedMenu===3" class="col-content">
            <lib-detail-address [adheridoInfo]="adheridoInfo" (refrescarAdheridoEvent)="refrescar(3)">
            </lib-detail-address>
          </div>
          <div *ngIf="selectedMenu===4 && canAccessDocumentos() && estadoAdherido!>=3" class="col-content">
            <lib-detail-documentation [adheridoInfo]="adheridoInfo" (refrescarAdheridoEvent)="refrescar(4)">
            </lib-detail-documentation>
          </div>
          <div *ngIf="selectedMenu===5 && canAccessCentroEnvasados()&& estadoAdherido!>=4 " class="col-content">
            <lib-detail-packaging-centers [adheridoInfo]="adheridoInfo" (refrescarAdheridoEvent)="refrescar(5)">
            </lib-detail-packaging-centers>
          </div>
          <div *ngIf="selectedMenu===6 && canAccessCentroEnvasados()&& estadoAdherido!>=6 " class="col-content">
            <lib-waste-forecast [adheridoInfo]="adheridoInfo" (refrescarAdheridoEvent)="refrescar(6)">
            </lib-waste-forecast>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="button-actions">
        <!-- Guardar y Continuar -->

        <button type="button" *ngIf="canAccessCancelar(selectedMenu)" (click)="buttonCancelar()"
          class="btn btn-outline-primary">
          <div class="text-icon">Cancelar</div>
        </button>
        <button type="button" *ngIf="canAccessVolver()" (click)="buttonVolver()" class="btn btn-outline-primary">
          <div class="text-icon">Volver</div>
        </button>
        <button type="button" *ngIf="canAccessAnteriorAll(selectedMenu) && selectedMenu!==1 "
          class="btn btn-outline-primary" (click)="anterior()">
          <div class="text-icon"><span class="icon-arrow-left"></span>Anterior</div>
        </button>
        <button type="button" *ngIf="canAccessContinuarAll(selectedMenu) || canAccessGuardar(selectedMenu)"
          class="btn btn-outline-primary" (click)="controlAccionsWizard(selectedMenu)">
          <div class="text-icon">{{continuarName(selectedMenu)}}<span *ngIf="continuarName(selectedMenu)!='Guardar'"
              class="icon-arrow-right"></span></div>
        </button>
        <button type="button" *ngIf="canAccessFinalizar(selectedMenu) && selectedMenu===3"
          class="btn btn-outline-primary" (click)="finalizar()">
          <div class="text-icon">Finalizar</div>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- FIN CONTENIDO - FICHA -->
