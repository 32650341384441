/**
 * Puntos de recogida API
 * Puntos de recogida API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GestoresResiduosEstadosModel } from './gestoresResiduosEstadosModel';


export interface GestoresResiduosFacturaDTO { 
    fechaAlta?: string;
    creadoPor?: string;
    fechaModificacion?: string;
    modificadoPor?: string;
    id?: number;
    razonSocial?: string;
    tipoDocumento?: GestoresResiduosFacturaDTO.TipoDocumentoEnum;
    codigoDocumento?: string;
    nima?: string;
    gestoresResiduosEstado?: GestoresResiduosEstadosModel;
    fechaAltaFinalizada?: string;
    fechaBaja?: string;
    fechaBajaEnvanet?: string;
    numeroAutorizacion?: string;
    centro?: string;
    numInscripcion1?: string;
    numInscripcion2?: string;
    numInscripcion3?: string;
    acuerdoEnvalora?: boolean;
}
export namespace GestoresResiduosFacturaDTO {
    export type TipoDocumentoEnum = 'NIF' | 'DNI' | 'NIE';
    export const TipoDocumentoEnum = {
        Nif: 'NIF' as TipoDocumentoEnum,
        Dni: 'DNI' as TipoDocumentoEnum,
        Nie: 'NIE' as TipoDocumentoEnum
    };
}


