<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="modal-content">

  <div class="modal-header">
    <h3 class="modal-title font">{{titulo}}</h3>
    <button type="button" (click)="closeDialog()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="height: 70vh; overflow-y: auto;">
    <mat-tab-group [selectedIndex]="selectedIndex" animationDuration="0ms" (selectedTabChange)="onTabChanged($event)">
      <mat-tab label="DATOS DE SOLICITUD">
        <form class="form-modal" [formGroup]="datosSolicitudForm" style="margin-top: 30px;">
          <div class="row-fields">
            <div class="field field-grow">
              <label for="usuarioSddr">Usuario SDDR CA <span class="oblig">*</span></label>
              <mat-form-field class="custom-mat-form-field" appearance="outline"
                *ngIf="!solicitudId || solicitud?.estado?.id===1">
                <mat-select formControlName="usuarioSddr" id="usuarioSddr" placeholder="-- Usuario SDDR CA --">
                  <mat-option *ngFor="let tipo of tiposUsusariosSddr" [value]="tipo.id!+tipo.tipoUsuario!">
                    {{ tipo?.tipoUsuario === 'POSEEDOR' ? 'PO' : 'AD' }} -
                    {{ tipo?.razonSocial }}
                    {{ tipo?.denominacion ? ' - ' + tipo.denominacion : '' }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="controlHasError(datosSolicitudForm,'usuarioSddr', 'required')">Campo obligatorio
                </mat-error>
              </mat-form-field>
              <mat-form-field class="custom-mat-form-field" appearance="outline" *ngIf="solicitud?.estado?.id>1">
                <input type="text" matInput formControlName="usuarioSddr" id="usuarioSddr">
              </mat-form-field>
            </div>
            <div class="field field-50">
              <label for="tipoEnvase">Tipo de envase <span class="oblig">*</span></label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <mat-select formControlName="tipoEnvase" id="tipoEnvase" placeholder="-- Tipo de envase --"
                  (selectionChange)="seleccionTipoEnvase()">
                  <mat-option *ngFor="let tipo of tipoEnvaseArray" [value]="tipo.id">
                    {{tipo?.descripcion}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="controlHasError(datosSolicitudForm,'tipoEnvase', 'required')">Campo
                  obligatorio</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row-fields">
            <div class="field field-grow">
              <label for="subtipoEnvase">Subtipo de envase <span class="oblig">*</span></label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <mat-select formControlName="subtipoEnvase" id="subtipoEnvase" placeholder="-- Subtipo de envase --">
                  <mat-option *ngFor="let item of subtipoEnvaseArray" [value]="item.id">
                    {{ item.descripcion }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="controlHasError(datosSolicitudForm,'subtipoEnvase', 'required')">Campo
                  obligatorio</mat-error>
              </mat-form-field>
            </div>
            <div class="field field-25">
              <div class="tool-div">
                <label for="unidadesRetirar">Cantidad a retirar (ud) <span class="oblig">*</span></label>
                <div class="tool-help"
                  matTooltip="Cantidad mínima de solicitud de retirada para envases de tipo IBC = 5 y para tipo Pallets = 10"
                  data-placement="top" title="">
                  <span>?</span>
                </div>
              </div>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="unidadesRetirar" id="unidadesRetirar" placeholder="Ud."
                  value="" appNumericPoint maxlength="13" />
                <mat-error *ngIf="controlHasError(datosSolicitudForm,'unidadesRetirar', 'required')">Campo
                  obligatorio</mat-error>
                <mat-error *ngIf="controlHasError(datosSolicitudForm, 'unidadesRetirar', 'maxlength')">Máximo 10
                  caracteres</mat-error>
              </mat-form-field>
            </div>
            <div class="field field-25">
              <label for="pesoRetirar">Cantidad a retirar (kg) </label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="pesoRetirar" id="pesoRetirar" placeholder="Kg." value=""
                  appTwoDigitDecimalNumber maxlength="13" />
                <mat-error *ngIf="controlHasError(datosSolicitudForm, 'pesoRetirar', 'maxlength')">Máximo 10
                  caracteres</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row-fields">
            <div class="field field-grow">
              <div class="tool-div">
                <label for="aceptacionRetirada">Aceptación de condiciones de retirada <span
                    class="oblig">*</span></label>
                <div class="tool-help" matTooltip="ENVALORA retornará el importe del depósito del número de los envases que el gestor/acondicionador determine que son aptos para su reutilización posterior, una vez reciba el informe de dicho agente.
              Los palets no deben tener restos de productos o sustancias que hayan contaminado el envase, ni restos de otros envases, por ejemplo, precintos, flejes o films.
              Los IBC deben estar vacíos y cerrados con menos de un 1% en peso de producton" data-placement="top"
                  title="">
                  <span>?</span>
                </div>
              </div>
              <div class="field field-25">
                <mat-radio-group formControlName="aceptacionRetirada" id="aceptacionRetirada"
                  class="custom-mat-form-radio">
                  <mat-radio-button color="primary" [value]="true">Sí</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="controlHasError(datosSolicitudForm,'aceptacionRetirada', 'required')">Campo
                  obligatorio</mat-error>
              </div>
            </div>
            <div class="field field-33">
              <label for="loteEnvase">Lote del envase</label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="loteEnvase" id="loteEnvase" placeholder="Lote" value=""
                  maxlength="255" />
                <mat-error *ngIf="controlHasError(datosSolicitudForm, 'loteEnvase', 'maxlength')">Máximo 255
                  caracteres</mat-error>
              </mat-form-field>
            </div>
            <div class="field field-33">
              <label *ngIf="this.solicitudId">Autofactura </label>
              <div class="autofactura" *ngIf="solicitud?.autoFactura">
                <div>{{solicitud?.autoFactura?.documentoAutofactura?.nombre}}</div>
                <mat-icon matTooltip="Descargar" (click)="descargarAutofactura(solicitud?.autoFactura)"
                  class="ml-2 color-autofactura pointer-cursor"> download</mat-icon>
              </div>
            </div>
          </div>
          <div class="row-fields">
            <div class="field field-grow">
              <label for="acondicionador">Acondicionador <span class="oblig">*</span></label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="acondicionador" id="acondicionador"
                  placeholder="Pulse la lupa para seleccionar un acondicionador" value="" readonly />
                <mat-error *ngIf="controlHasError(datosSolicitudForm,'acondicionador', 'required')">Campo
                  obligatorio</mat-error>
              </mat-form-field>
              <input formControlName="acondicionadorId" class="hide">
            </div>
            <div class="field field-10" *ngIf="!soloLecturaDatos">
              <span class="lupa" (click)="toogleBuscador();">
                <mat-icon>search</mat-icon>
              </span>
            </div>
          </div>
          <div class="seleccion-acondicionador" *ngIf="verBuscador">
            <form class="form-modal" [formGroup]="acondicionadorForm">
              BUSCADOR DE ACONDICIONADOR
              <div class="row-fields">
                <div class="field field-33">
                  <label for="nima">NIMA del gestor </label>
                  <mat-form-field class="custom-mat-form-field" appearance="outline">
                    <input formControlName="nima" id="nima" matInput type="text" placeholder="NIMA del gestor"
                      maxlength="10">
                      <mat-error *ngIf="controlHasError(acondicionadorForm, 'nima', 'maxlength')">Máximo 10
                        caracteres</mat-error>
                  </mat-form-field>
                </div>
                <div class="field field-33">
                  <label for="comunidadAutonoma">Comunidad Autónoma</label>
                  <mat-form-field class="custom-mat-form-field" appearance="outline">
                    <mat-select formControlName="comunidadAutonoma" id="comunidadAutonoma"
                      placeholder="-- Comunidad --">
                      <mat-option *ngFor="let comunidad of comunidadArray" [value]="comunidad.id">
                        {{ comunidad.nombre }}
                      </mat-option>
                    </mat-select>
                    <span *ngIf="showClearButton('comunidadAutonoma')" tabindex="0" class="ng-clear-wrapper"
                      (click)="clearSelection($event,'comunidadAutonoma')">
                      <span aria-hidden="true" class="ng-clear">×</span>
                    </span>
                  </mat-form-field>
                </div>
                <div class="field field-grow">
                  <label for="razonSocial">Razón Social</label>
                  <mat-form-field class="custom-mat-form-field" appearance="outline">
                    <input type="text" matInput formControlName="razonSocial" id="razonSocial"
                      placeholder="Razón Social" maxlength="255" value="">
                  </mat-form-field>
                  <mat-error *ngIf="controlHasError(acondicionadorForm, 'razonSocial', 'maxlength')">Máximo 255
                    caracteres</mat-error>
                </div>
                <div class="field" style="align-self: center;">
                  <button type="button" class="btn btn-primary" (click)="buscarAcondicionadores();">
                    <mat-icon aria-hidden="false" aria-label="Añadir" fontIcon="search"></mat-icon>
                  </button>
                </div>
              </div>
              <div class="row-fields" *ngIf="flagSearch; else messageClear">
                <div class="field field-100">
                  <div class="datatable-container table-responsive">
                    <lib-ge-table [headArray]="headArrayAcondicionadores" [gridArray]="gridAcondicionadores"
                      (actionButton)="actionAcondicionadores($event)">
                    </lib-ge-table>

                  </div>
                </div>
              </div>
            </form>
          </div>

          <div class="row-fields">
            <div class="field field-100">
              <label for="observacionesRetirada">Observaciones sobre la retirada de envases </label>
              <mat-form-field class="custom-mat-form-field" appearance="outline" class="textarea-field">
                <textarea class="form-control" matInput formControlName="observacionesRetirada"
                  id="observacionesRetirada" rows="4" maxlength="255"></textarea>
                   <mat-error *ngIf="controlHasError( datosSolicitudForm,'observacionesRetirada', 'maxlength')">Máximo 255
                  caracteres</mat-error>
              </mat-form-field>
            </div>
          </div>
        </form>

        <form class="form-modal" [formGroup]="facturaForm">
          <div class="title-group">Facturas en los que se le ha cobrado el importe de los depósitos de los envases
            reutilizables </div>
          <div class="row-fields">
            <div class="field field-35">
              <label for="importeDepositoUnidad">Importe depósito / unidad <span class="oblig">*</span></label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="importeDepositoUnidad" id="importeDepositoUnidad"
                  placeholder="Importe depósito / unidad" value="" appTwoDigitDecimalNumber maxlength="255">
                <mat-error *ngIf="controlHasError(facturaForm, 'importeDepositoUnidad', 'required')">Campo
                  obligatorio
                </mat-error>
                <mat-error *ngIf="controlHasError( facturaForm,'importeDepositoUnidad', 'maxlength')">Máximo 255
                  caracteres</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row-fields" style="border: 1px solid #e4e4e4; padding:20px;" *ngIf="!soloLecturaDatos">
            <div class="field field-35">
              <label for="nFacturaDeposito">Número de factura depósito <span class="oblig">*</span></label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="nFacturaDeposito" id="nFacturaDeposito"
                  placeholder="Número de factura depósito" value="" maxlength="255">
                <mat-error *ngIf="controlHasError(facturaForm, 'nFacturaDeposito', 'required')">
                  Campo obligatorio
                </mat-error>
                <mat-error *ngIf="controlHasError(facturaForm, 'nFacturaDeposito', 'pattern')">Formato incorrecto, debe
                  ser
                  numérico
                </mat-error>
                <mat-error *ngIf="controlHasError( facturaForm, 'nFacturaDeposito', 'maxlength')">Máximo 255
                  caracteres</mat-error>
              </mat-form-field>
            </div>
            <div class="field field-grow">
              <div class="attach">
                <div>
                  <button mat-button aria-labe="Adjuntar documento" class="adjuntar-btn" type="button"
                    (click)="file.click()" [disabled]="adjuntosBlobFacturas!.length>0">
                    Adjuntar documento
                  </button>
                  <label class="sr-only" for="adjuntar">Adjuntar documento </label>
                  <input #file type="file" (change)="file && file.files && attachAdjuntoFacturas(file.files[0])"
                    class="hide" id="adjuntar" name="adjuntar">
                </div>
                <div>
                  <div *ngFor="let archivo of adjuntosBlobFacturas" class="archivo">
                    <div><span class="sprite-icon ico-adjuntar"></span><span class="nombre-archivo">{{archivo?.name}}
                      </span>
                    </div>
                    <mat-icon class="color-close close-icon c-pointer" matTooltip="Descartar adjunto"
                      matTooltipPosition="above" (click)="descartarFac(archivo)">close</mat-icon>
                  </div>
                </div>
              </div>
              <mat-error *ngIf="!hayFactura">Debe seleccionar una factura </mat-error>
            </div>
            <div class="field" style="align-self: center;">
              <button type="button" (click)="addFactura()" class="btn btn-primary ">
                <mat-icon aria-hidden="false" aria-label="Añadir" fontIcon="add"></mat-icon>
              </button>
            </div>
          </div>

          <div class="row-fields">
            <div class="field field-100">
              <div class="datatable-container table-responsive">
                <lib-ge-table [headArray]="headArrayFacturas" [gridArray]="gridFacturas"
                  (actionButton)="actionFacturas($event)">
                </lib-ge-table>

              </div>
            </div>
          </div>
        </form>

        <form *ngIf="solicitud?.estado?.id>1" class="form-modal py-3" [formGroup]="informacionSolicitud">
          <div class="title-group">Información solicitud </div>
          <div class="row-fields">
            <div class="field field-35">
              <label for="usuarioSolicitud">Usuario </label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="usuarioSolicitud" id="usuarioSolicitud" value="" />
              </mat-form-field>
            </div>
            <div class="field field-grow">
              <label for="fechaSolicitud">Fecha de solicitud</label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="fechaSolicitud" id="fechaSolicitud" value="" />
              </mat-form-field>
            </div>
            <div class="field field-35">
              <label for="estadoSolicitud">Estado </label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="estadoSolicitud" id="estadoSolicitud" value="">
              </mat-form-field>
            </div>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="INFORME DE RETIRADA" *ngIf="solicitudId && solicitud?.estado?.id>1">
        <form class="form-modal py-3" [formGroup]="informeRetiradaForm" style="margin-top: 30px">
          <div class="row-fields">
            <div class="field field-25">
              <label for="usuarioSddrCa">Usuario SDDR CA</label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="usuarioSddrCa" id="usuarioSddrCa" value="" />
              </mat-form-field>
            </div>
            <div class="field field-20">
              <label for="udEnvases">Envases solicitados (ud)</label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="udEnvases" id="udEnvases" placeholder="Ud." value=""
                  appNumericPoint/>
              </mat-form-field>
            </div>
            <div class="field field-20">
              <label for="kgEnvases">Envases solicitados (kg) </label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="kgEnvases" id="kgEnvases" placeholder="Kg." value=""
                  appTwoDigitDecimalNumber/>
              </mat-form-field>
            </div>
          </div>
          <div class="row-fields">
            <div class="field field-15">
              <label for="udRetirados">Envases retirados (ud) <span class="oblig">*</span></label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="udRetirados" id="udRetirados" placeholder="Ud." value=""
                  appNumericPoint maxlength="13" />
                <mat-error *ngIf="controlHasError(informeRetiradaForm, 'udRetirados', 'required')">Campo obligatorio
                </mat-error>
                <mat-error *ngIf="controlHasError(informeRetiradaForm, 'udRetirados', 'maxlength')">Máximo 10
                  caracteres</mat-error>
              </mat-form-field>
            </div>
            <div class="field field-15">
              <label for="kgRetirados">Envases retirados (kg) </label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="kgRetirados" id="kgRetirados" placeholder="Kg." value=""
                  appTwoDigitDecimalNumber maxlength="13" />
                <mat-error *ngIf="controlHasError(informeRetiradaForm, 'kgRetirados', 'maxlength')">Máximo 10
                  caracteres</mat-error>
              </mat-form-field>
            </div>
            <div class="field field-15">
              <label for="udAptos">Envases aptos (ud) <span class="oblig">*</span></label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="udAptos" id="udAptos" placeholder="Ud." value=""
                  appNumericPoint maxlength="13" />
                <mat-error *ngIf="controlHasError(informeRetiradaForm, 'udAptos', 'required')">Campo obligatorio
                </mat-error>
                <mat-error *ngIf="controlHasError(informeRetiradaForm, 'udAptos', 'maxlength')">Máximo 10
                  caracteres</mat-error>
              </mat-form-field>
            </div>
            <div class="field field-15">
              <label for="kgAptos">Envases aptos (kg) </label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="kgAptos" id="kgAptos" placeholder="Kg." value=""
                  appTwoDigitDecimalNumber maxlength="13" />
                <mat-error *ngIf="controlHasError(informeRetiradaForm, 'kgAptos', 'maxlength')">Máximo 10
                  caracteres</mat-error>
              </mat-form-field>
            </div>
            <div class="field field-15">
              <label for="udNoAptos">Envases no aptos (ud) <span class="oblig">*</span></label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="udNoAptos" id="udNoAptos" placeholder="Ud." value=""
                       appNumericPoint maxlength="13" />
                <mat-error *ngIf="controlHasError(informeRetiradaForm, 'udNoAptos', 'required')">Campo obligatorio
                </mat-error>
                <mat-error *ngIf="controlHasError(informeRetiradaForm, 'udNoAptos', 'maxlength')">Máximo 10
                  caracteres</mat-error>
              </mat-form-field>
            </div>
            <div class="field field-15">
              <label for="kgNoAptos">Envases no aptos (kg) <span class="oblig">*</span></label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="kgNoAptos" id="kgNoAptos" placeholder="Kg." value=""
                       appTwoDigitDecimalNumber maxlength="13" />
                <mat-error *ngIf="controlHasError(informeRetiradaForm, 'kgNoAptos', 'required')">Campo obligatorio
                </mat-error>
                <mat-error *ngIf="controlHasError(informeRetiradaForm, 'kgNoAptos', 'maxlength')">Máximo 10
                  caracteres</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row-fields">
            <div class="field field-grow">
              <label for="verificacion">Verificación de que se trata del usuario SDDR CA <span
                class="oblig">*</span></label>
              <mat-radio-group formControlName="verificacion" id="verificacion" class="custom-mat-form-radio">
                <mat-radio-button color="primary" [value]="true">Sí</mat-radio-button>
                <mat-radio-button color="primary" [value]="false">No</mat-radio-button>
              </mat-radio-group>
              <mat-error *ngIf="controlHasError(informeRetiradaForm, 'verificacion', 'required')">Campo
                obligatorio
              </mat-error>
            </div>
            <div class="field field-35">
              <div class="tool-div">
                <label for="envasesSalenSddr">Envases que salen del SDDR CA ENVALORA <span
                    class="oblig">*</span></label>
                <div class="tool-help" matTooltip="Se compromete a la eliminación de los símbolos del SDDR CA de Envalora
              de aquellos envases aptos pero que no se van a mantener dentro del SDDR abierto y a no cobrar a ninguno de
              sus clientes ningún concepto de depósito asociado a este envase" data-placement="top" title="">
                  <span>?</span>
                </div>
              </div>
              <div class="field field-100">
                <mat-radio-group formControlName="envasesSalenSddr" id="envasesSalenSddr" class="custom-mat-form-radio">
                  <mat-radio-button color="primary" [value]="true">Sí</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="controlHasError(informeRetiradaForm,'envasesSalenSddr', 'required')">Campo
                  obligatorio</mat-error>
              </div>
            </div>
            <div class="field field-grow">
              <div class="tool-div">
                <label for="envasesMantienenSddr">Envases que se mantienen en el SDDR CA de ENVALORA<span
                    class="oblig">*</span></label>
                <div class="tool-help" matTooltip="Se compromete a realizar una solicitud para aquellos envases aptos para
                reutilizar que quiere que se mantengan dentro del SDDR CA de Envalora, en este caso mantendrá o repondrá el
                Símbolo de Envalora y de envase reutilizable" data-placement="top" title="">
                  <span>?</span>
                </div>
              </div>
              <div class="field field-100">
                <mat-radio-group formControlName="envasesMantienenSddr" id="envasesMantienenSddr"
                  class="custom-mat-form-radio">
                  <mat-radio-button color="primary" [value]="true">Sí</mat-radio-button>
                </mat-radio-group>
                <mat-error *ngIf="controlHasError(informeRetiradaForm,'envasesMantienenSddr', 'required')">Campo
                  obligatorio</mat-error>
              </div>
            </div>
          </div>

          <div class="title-group">Documentos </div>
        </form>
        <form class="form-modal py-3" [formGroup]="documentoInformeForm">
          <div class="row-fields" style="border: 1px solid #e4e4e4; padding:20px;" *ngIf="!soloLecturaInforme">
            <div class="field field-35">
              <label for="nombre">Documento</label>
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input type="text" matInput formControlName="nombre" id="nombre" placeholder="Documento informe"
                  value="" maxlength="255">
                <mat-error *ngIf="controlHasError(documentoInformeForm, 'nombre', 'required')">Campo
                  obligatorio
                </mat-error>
                <mat-error *ngIf="controlHasError( documentoInformeForm,'nombre', 'maxlength')">Máximo 255
                  caracteres</mat-error>
              </mat-form-field>
            </div>
            <div class="field field-grow">
              <div class="attach">
                <div>
                  <button mat-button aria-labe="Adjuntar documento" class="adjuntar-btn" type="button"
                    (click)="file2.click()" [disabled]="adjuntosBlobDocumentos!.length>0">
                    Adjuntar documento
                  </button>
                  <label class="sr-only" for="adjuntar">Adjuntar documento </label>
                  <input #file2 type="file" (change)="file2 && file2.files && attachAdjuntoDocumentos(file2.files[0])"
                    class="hide" id="adjuntar" name="adjuntar">
                </div>
                <div>
                  <div *ngFor="let archivo of adjuntosBlobDocumentos" class="archivo">
                    <div><span class="sprite-icon ico-adjuntar"></span><span class="nombre-archivo">{{archivo?.name}}
                      </span>
                    </div>
                    <mat-icon class="color-close close-icon c-pointer" matTooltip="Descartar adjunto"
                      matTooltipPosition="above" (click)="descartarDoc(archivo)">close</mat-icon>
                  </div>
                </div>
              </div>
              <mat-error *ngIf="!hayDocumento">Debe seleccionar un documento </mat-error>
            </div>
            <div class="field field-10">
              <div class="button-group" style="margin-top:8px; font-weight: bold; margin-top:25px;">
                <button type="button" (click)="anadirDocumento()" class="btn btn-primary">
                  <mat-icon>add</mat-icon>
                </button>
              </div>

            </div>
          </div>
          <div class="row-fields">
            <div class="field field-100">
              <div class="datatable-container table-responsive">
                <lib-ge-table [headArray]="headArrayDocumentos" [gridArray]="gridDocumentos"
                  (actionButton)="actionDocumentos($event)">
                </lib-ge-table>
              </div>
            </div>
          </div>
        </form>
      </mat-tab>
    </mat-tab-group>
  </div>

  <div class="modal-footer actions">
    <div class="note">* Campos Obligatorios</div>
    <div class="button-group">
      <button type="button" (click)="closeDialog()" class="btn btn-outline-primary" data-dismiss="modal"
        aria-label="Close">Volver</button>
      <button *ngIf="canAccessGuardar()&&(solicitud?.estado?.id===1 || !solicitudId)&&selectedIndex===0" type="button"
        class="btn btn-primary" data-dismiss="modal" aria-label="Guardar" (click)="guardarBorrador()">Guardar
        borrador</button>
      <button *ngIf="canAccessGuardarEnviar()&&(solicitud?.estado?.id===1 || !solicitudId)&&selectedIndex===0"
        type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Guardar"
        (click)="guardarEnviar()">Guardar y
        enviar</button>
      <button *ngIf="canAccessGuardarInforme()&&(solicitud?.estado?.id===2)&&selectedIndex===1" type="button"
        class="btn btn-primary" data-dismiss="modal" aria-label="Guardar" (click)="guardarInforme()">Guardar</button>
      <button *ngIf="canAccessGuardarEnviarInforme()&&(solicitud?.estado?.id===2)&&selectedIndex===1" type="button"
        class="btn btn-primary" data-dismiss="modal" aria-label="Guardar" (click)="guardarEnviarInforme()">Guardar y
        completar</button>
    </div>
  </div>
</div>

<ng-template #messageClear>
  <div class="mensaje-container">
    <p class="mensaje-linea">No hay resultados de búsqueda.</p>
  </div>
</ng-template>
