import { Inject, Component, OnInit } from '@angular/core';
import { SnackbarService } from '../services/snackbar.service';
import { SpinnerService } from '../services/spinner.service';
import { DownloadService } from '../services/download.service';
import { AdheridoControllerService, BusquedaAdheridoDTO, DocumentoInteresControllerService, DocumentoInteresModel } from 'api-rest';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';



@Component({
  selector: 'lib-initial-page',
  templateUrl: './initial-page.component.html',
  styleUrls: ['./initial-page.component.scss'],
})
export class InitialPageComponent implements OnInit {

  documentos: DocumentoInteresModel[] = [];

  constructor(
    private documentoInteresCtrlSrv: DocumentoInteresControllerService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private downloadSrv: DownloadService,    
    private adheridoService: AdheridoControllerService
  ) { }

  esEcoembes: boolean = false;

  ngOnInit(): void {
    this.cargarDocumentos();
    this.esAdheridoEcoembes();    
  }

  cargarDocumentos(): void {
    this.spinnerSrv.loadingShow();
    this.documentoInteresCtrlSrv.findAllByUser()
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.documentos = response;
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al consultar los documentos',
            'error'
          );
        },
      });
  }

  descargarDocumento(documento: DocumentoInteresModel): void {
    let byteString: any = documento.documento;
    let fileType = documento.contentType ?? 'application/octet-stream';
    let blob = this.downloadSrv.b64toBlob(byteString, fileType)
    this.downloadSrv.downloadBlob(blob, documento.filename!);
  }

  abrirEnlace(url: string): void {
    window.open(url, '_blank');
  }

  esAdheridoEcoembes() {
    const roleEntities = (this.authService.user as any).roleEntities;
    var adheridosIds = roleEntities?.filter((adheridoRol: any) => adheridoRol.type === 'ADHERIDO')
      .map((adheridoRol: any) => adheridoRol.entity as number);

    adheridosIds = adheridosIds.filter((x: number, y: number) => {
      return adheridosIds.indexOf(x) == y;
    });

    const filter: BusquedaAdheridoDTO = {
      id: adheridosIds
    };
    var adheridoArray: any;
    this.adheridoService.listarAdheridos({ page: 0, size: 100 }, filter)
      .subscribe({
        next: (response: any) => {
          adheridoArray = response?.datos;
          if (!this.esEcoembes) {
            this.esEcoembes = adheridoArray.some(((adherido: { scrapDestino: string; }) => adherido.scrapDestino?.toLowerCase() === 'ecoembes'));            
          }
        },
        error: (err) => {
          this.snackBarSrv.showSnackBar('Ha ocurrido un error al comprobar si el usuario tiene Adheridos de Ecoembes', 'error');
          console.log(err);
        },
      });
  }
}

