import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CombosService } from '../../../services/combos.service';
import { ValidateService } from '../../../services/validate.service';
import { Combo } from '../../../models/combo';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import Swal from 'sweetalert2';
import {
  PuntoRecogidaControllerService,
  PtoRecogidaPayloadDTO,
  SolicitudControllerService,
  PtoRecogidaGestorControllerService,
  PtoRecogidaCodigoControllerService,
} from 'api-rest';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { DatePipe } from '@angular/common';
import { DocumentService } from '../../../services/document.service';
import { DownloadService } from '../../../services/download.service';
import { ParseCurrencyPipe } from '../../../share/components/pipe/ParseCurrency.pipe';
import { FormatCurrencyPipe } from '../../..//share/components/pipe/FormatCurrency.pipe';

@Component({
  selector: 'app-collection-points-requests-detail',
  templateUrl: './collection-points-requests-detail.component.html',
  styleUrls: ['./collection-points-requests-detail.component.scss'],
})
export class CollectionPointsRequestsDetailComponent implements OnInit {
  dataForm: FormGroup;
  tiposDocumento: Combo[] = [];
  pageNumber: number = 0;
  pageSize: number = 100;

  paisesEndpoint = this.environment.urlBackCore + 'api/v1/core/countries';

  //codigosLerEndpoint = this.environment.urlBackCore + 'api/v1/core/codigo-ler';

  flagDisabledForm: boolean = false;
  updateList: boolean = false;

  comunidadArray: any[] = [];
  gestorArray: any[] = [];
  estadosArray: any[] = [];

  infoRequests: any;
  ptoArray: any[] = [];

  nombreArchivoA: string = '';
  nombreArchivoC: string = '';
  fileD: File | undefined;
  fileD2: File | undefined;
  @ViewChild('fileInputA') fileInputA: any;

  flagPrGestor1: boolean = false;
  flagPrGestor2: boolean = false;
  codigoLerArray: any[] = [];

  documento: File | undefined;

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    private validateSrv: ValidateService,
    private dialogRef: MatDialogRef<CollectionPointsRequestsDetailComponent>,
    private comboSrv: CombosService,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private puntoRecogidaControllerService: PuntoRecogidaControllerService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe,
    private documentService: DocumentService,
    private downloadService: DownloadService,
    private solicitudControllerService: SolicitudControllerService,
    private ptoRecogidaGestorControllerService: PtoRecogidaGestorControllerService,
    private ptoRecogidaCodigoControllerService: PtoRecogidaCodigoControllerService,
    private parseCurrencyPipe: ParseCurrencyPipe,
    private formatCurrencyPipe: FormatCurrencyPipe
  ) {
    this.infoRequests = this.data.infoRequests;

    this.dataForm = this.formBuilder.group({
      ptoRecogida: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      direccionPtoRecogida: [{ value: null, disabled: false }],
      contactoPtoRecogida: [{ value: null, disabled: false }],

      gestor: [{ value: null, disabled: false }],
      nimaGestor: [{ value: null, disabled: false }],
      direccionGestor: [{ value: null, disabled: false }],
      contactoGestor: [{ value: null, disabled: false }],

      estadoSolicitud: [{ value: null, disabled: false }],
      fechaSolicitud: [{ value: null, disabled: false }],
      observacionesPto: [{ value: null, disabled: false }, Validators.maxLength(255)],
      fechaCancelacion: [{ value: null, disabled: false }],
      motivo: [{ value: null, disabled: false }, Validators.maxLength(255)],

      codigoLer: [{ value: null, disabled: false }],
      acondicionamiento: [{ value: null, disabled: false }],
      cantidadUnidades: [{ value: null, disabled: false }],

      cantidadSuministro: [{ value: null, disabled: false }],
      acondicionamientoSolicitud: [{ value: null, disabled: false }],

      fechaSugerida: [{ value: null, disabled: false }],
      fechaPrevista: [{ value: null, disabled: false }],
      fechaRecogida: [{ value: null, disabled: false }],
      codigoLerRecogida: [{ value: null, disabled: false }],
      kgReales: [{ value: null, disabled: false }],
      observacionesGestor: [{ value: null, disabled: false }, Validators.maxLength(255)],

      documentoDi: [{ value: null, disabled: false }, Validators.maxLength(255)],
      documento: [{ value: this.infoRequests?.documento, disabled: false }],
      autoFactura: [{ value: null, disabled: false }],
    });
  }

  ngOnInit(): void {
    this.tiposDocumento = this.comboSrv.getTiposDocumentoIdentidad();
    this.comboCodigoLer(this.infoRequests.ptoRecogida);
    this.updateDataForm(this.infoRequests);
    this.dataForm.disable();
    this.validatePrGestor1();
    this.validatePrGestor2();
    this.validatePrGestor1o2();
  }

  validatePrEnvGest4() {
    return (
      this.authService.hasCurrentUserRole('PR-ENV-GEST') &&
      this.infoRequests &&
      [4].includes(this.infoRequests?.estado?.id)
    );
  }

  validatePrGestor1() {
    if (
      this.authService.hasCurrentUserRole('PR-GESTOR') &&
      this.infoRequests &&
      [1].includes(this.infoRequests?.estado?.id)
    ) {
      this.dataForm.get('fechaPrevista')?.enable();
      this.dataForm.get('fechaPrevista')?.setValidators([Validators.required]);
      this.dataForm.get('fechaPrevista')?.updateValueAndValidity();
      this.flagPrGestor1 = true;
    } else {
      this.flagPrGestor1 = false;
    }
  }

  validatePrGestor2() {
    if (
      this.authService.hasCurrentUserRole('PR-GESTOR') &&
      this.infoRequests &&
      [2].includes(this.infoRequests?.estado?.id)
    ) {
      this.dataForm.get('fechaRecogida')?.enable();
      this.dataForm.get('codigoLerRecogida')?.enable();
      this.dataForm.get('kgReales')?.enable();
      this.dataForm.get('documentoDi')?.enable();
      this.dataForm.get('fechaRecogida')?.setValidators([Validators.required]);
      this.dataForm.get('fechaRecogida')?.updateValueAndValidity();
      this.dataForm
        .get('codigoLerRecogida')
        ?.setValidators([Validators.required]);
      this.dataForm.get('codigoLerRecogida')?.updateValueAndValidity();
      this.dataForm.get('kgReales')?.setValidators([Validators.required]);
      this.dataForm.get('kgReales')?.updateValueAndValidity();
      this.dataForm.get('documentoDi')?.setValidators([Validators.required]);
      this.dataForm.get('documentoDi')?.updateValueAndValidity();
      this.flagPrGestor2 = true;
    } else {
      this.flagPrGestor2 = false;
    }
  }

  validatePrGestor1o2() {
    if (
      this.authService.hasCurrentUserRole('PR-GESTOR') &&
      this.infoRequests &&
      [1, 2].includes(this.infoRequests?.estado?.id)
    ) {
      this.dataForm.get('observacionesGestor')?.enable();
    }
  }

  canViewRol(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

  updateDataForm(data?: any) {
    // this.ptoArray.push(data?.ptoRecogida);
    this.dataForm.get('ptoRecogida')?.setValue(data?.ptoRecogida.razonSocial);

    const dirFisica = data.ptoRecogida?.domicilios?.find(
      (domicilio: any) => domicilio.tipoDireccion?.id === 5
    );
    let direccionCompleta = '';

    if (dirFisica) {
      const { tipoVia, nombreVia, numero, municipio, provincia, codigoPostal } =
        dirFisica;

      direccionCompleta = [
        tipoVia,
        nombreVia,
        numero,
        municipio,
        provincia?.nombre,
        codigoPostal?.codigoPostal,
      ]
        .filter(Boolean)
        .join(', ');
    }

    const contactoGestion = data.ptoRecogida?.contactos?.find(
      (contacto: any) => contacto.tipo_contacto?.id === 1
    );

    const prefijoGestion = contactoGestion?.codigoPais?.prefijoNombre || null;
    const telefonoGestion = contactoGestion?.numero_telefono || null;
    const emailGestion = contactoGestion?.email || null;

    const datos = [prefijoGestion, telefonoGestion, emailGestion].filter(
      Boolean
    );

    const cadenaDatos = datos.join(', ');

    this.dataForm.get('direccionPtoRecogida')?.setValue(direccionCompleta);
    this.dataForm.get('contactoPtoRecogida')?.setValue(cadenaDatos);

    // -----infoGestor-------
    this.dataForm.get('gestor')?.setValue(data?.gestor?.razonSocial);

    this.dataForm.get('nimaGestor')?.setValue(data?.gestor?.nima);

    const dirGestorFisica = data.gestor?.domicilios?.find(
      (domicilio: any) => domicilio.tipoDireccion?.id === 5
    );
    let direccionGestorCompleta = '';

    if (dirGestorFisica) {
      const { tipoVia, nombreVia, numero, municipio, provincia, codigoPostal } =
        dirGestorFisica;

      direccionGestorCompleta = [
        tipoVia,
        nombreVia,
        numero,
        municipio,
        provincia?.nombre,
        codigoPostal?.codigoPostal,
      ]
        .filter(Boolean)
        .join(', ');
    }

    this.dataForm.get('direccionGestor')?.setValue(direccionGestorCompleta);

    const contactoGestorGestion = data.gestor?.contactos?.find(
      (contacto: any) => contacto.tipo_contacto?.id === 1
    );
    const prefijoGestorGestion =
      contactoGestion?.codigoPais?.prefijoNombre || null;
    const telefonoGestorGestion =
      contactoGestorGestion?.numero_telefono || null;
    const emailGestorGestion = contactoGestorGestion?.email || null;

    const datosGestor = [
      prefijoGestorGestion,
      telefonoGestorGestion,
      emailGestorGestion,
    ].filter(Boolean);

    const cadenaGestorDatos = datosGestor.join(', ');

    this.dataForm.get('contactoGestor')?.setValue(cadenaGestorDatos);

    // -----infoSolicitud-------
    this.dataForm.get('estadoSolicitud')?.setValue(data?.estado?.descripcion);
    this.dataForm
      .get('fechaSolicitud')
      ?.setValue(this.datePipe.transform(data?.fechaSolicitud, 'dd/MM/yyyy'));
    this.dataForm
      .get('observacionesPto')
      ?.setValue(data?.observacionesPtoRecogida);
    this.dataForm.get('fechaCancelacion')?.setValue(data?.fechaCancelacion);
    this.dataForm.get('motivo')?.setValue(data?.motivoCancelacion);

    // -----infoSolicitudRecogida-------
    this.dataForm
      .get('codigoLer')
      ?.setValue(
        data?.codigoLer?.tipoCodigo + ' ' + data?.codigoLer?.descripcion
      );
    this.dataForm
      .get('acondicionamiento')
      ?.setValue(data?.acondicionamientoRecogida?.descripcion);
    this.dataForm.get('cantidadUnidades')?.setValue(data?.cantidadRecogida);

    // -----infoSolicitudSuministro-------

    this.dataForm.get('cantidadSuministro')?.setValue(data?.cantidadSuministro);
    this.dataForm
      .get('acondicionamientoSolicitud')
      ?.setValue(data?.acondicionamientoSuministro?.descripcion);

    // -----infoSolicitudRECOGIDA-------
    this.dataForm.get('fechaSugerida')?.setValue(data?.fechaSugeridaRecogida);
    this.dataForm.get('fechaPrevista')?.setValue(data?.fechaPrevista);
    this.dataForm.get('fechaRecogida')?.setValue(data?.fechaRecogida);
    this.dataForm.get('kgReales')?.setValue(this.formatCurrencyPipe.transform(data?.cantidadRecogidaReal));

    this.dataForm
      .get('observacionesGestor')
      ?.setValue(data?.observacionesGestor);
    this.dataForm.get('codigoLerRecogida')?.setValue(data?.codigoLerReal?.id);

    // infoAportacionDi---------
    this.dataForm.get('documentoDi')?.setValue(data?.documentoIdentificacion);

    this.dataForm.get('autoFactura')?.setValue(data?.autofactura);

    this.nombreArchivoA =
      data?.documento !== null ? data?.documento?.nombre! : '';
  }

  canEditRequests() {
    return (
      this.authService.can('ptosrecogida-solicitud', 'access-update') &&
      this.infoRequests &&
      [1, 2].includes(this.infoRequests?.estado?.id)
    );
  }

  canSaveRequests() {
    return (
      this.authService.can('ptosrecogida-solicitud', 'access-b-guardar') &&
      this.infoRequests &&
      [1, 2].includes(this.infoRequests?.estado?.id)
    );
  }

  canSaveCourseRequests() {
    return (
      this.authService.can(
        'ptosrecogida-solicitud',
        'access-b-guardar-curso'
      ) &&
      this.infoRequests &&
      [1].includes(this.infoRequests?.estado?.id)
    );
  }

  canSaveFinalRequests() {
    return (
      this.authService.can(
        'ptosrecogida-solicitud',
        'access-b-guardar-finalizar'
      ) &&
      this.infoRequests &&
      [2].includes(this.infoRequests?.estado?.id)
    );
  }

  comboCodigoLer(data?: any) {
    this.ptoRecogidaCodigoControllerService
      .findAllPtoRecogidaCodigo(
        { ptoRecogida: { id: data?.id } },
        { page: 0, size: 100 }
      )
      .subscribe({
        next: (response: any) => {
          const uniqueCodigoLerArray = response?.datos
            .map((item: any) => item.codigoLer)
            .filter(
              (value: any, index: number, self: any[]) =>
                index === self.findIndex((t) => t.id === value.id)
            );

          this.codigoLerArray = uniqueCodigoLerArray;
        },
        error: (err) => { },
      });
  }

  saveData() {
    this.dataForm.markAllAsTouched();
    this.dataForm.updateValueAndValidity();

    if (
      this.infoRequests?.documento == null &&
      this.infoRequests?.estado?.id == 2
    ) {
      if (this.documento == undefined || this.documento == null) {
        this.snackBarSrv.showSnackBar('Adjunte documento', 'error');
        return;
      }
    }

    if (this.dataForm.valid || this.dataForm.disabled) {
      const objPuntosRecogidaFilter = this.infoRequests;

      objPuntosRecogidaFilter.fechaPrevista = this.datePipe.transform(
        this.dataForm.get('fechaPrevista')?.value,
        'yyyy-MM-dd'
      );

      objPuntosRecogidaFilter.fechaRecogida = this.datePipe.transform(
        this.dataForm.get('fechaRecogida')?.value,
        'yyyy-MM-dd'
      );

      objPuntosRecogidaFilter.codigoLerReal = {
        id: this.dataForm.get('codigoLerRecogida')?.value,
      };

      objPuntosRecogidaFilter.cantidadRecogidaReal = this.parseCurrencyPipe.transform(this.dataForm.get('kgReales')?.value);

      objPuntosRecogidaFilter.observacionesGestor = this.dataForm.get(
        'observacionesGestor'
      )?.value;

      objPuntosRecogidaFilter.documentoIdentificacion =
        this.dataForm.get('documentoDi')?.value;

      objPuntosRecogidaFilter.autofactura = {
        id: this.dataForm.get('autoFactura')?.value,
      };
      this.spinnerSrv.loadingShow();
      this.solicitudControllerService
        .guardarSolicitud(
          this.infoRequests?.id,
          objPuntosRecogidaFilter,
          this.documento
        )
        .subscribe({
          next: (value: any) => {
            this.spinnerSrv.loadingHide();
            this.dialogRef.close(true);
            this.snackBarSrv.showSnackBar(
              'Se ha guardado correctamente la solicitud',
              'success'
            );
          },
          error: (error: any) => {
            this.spinnerSrv.loadingHide();
          },
        });
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  saveDataFinal() {
    this.dataForm.markAllAsTouched();
    this.dataForm.updateValueAndValidity();

    if (
      this.infoRequests?.documento == null &&
      this.infoRequests?.estado?.id == 2
    ) {
      if (this.documento == undefined || this.documento == null) {
        this.snackBarSrv.showSnackBar('Adjunte documento', 'error');
        return;
      }
    }

    if (this.dataForm.valid || this.dataForm.disabled) {
      this.spinnerSrv.loadingShow();
      this.solicitudControllerService
        .finalizarSolicitud(this.infoRequests?.id)
        .subscribe({
          next: (value: any) => {
            this.spinnerSrv.loadingHide();
            this.dialogRef.close(true);
            this.snackBarSrv.showSnackBar(
              'Se ha finalizado correctamente la solicitud',
              'success'
            );
          },
          error: (error: any) => {
            this.spinnerSrv.loadingHide();
          },
        });
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  saveDataCourse() {
    this.dataForm.markAllAsTouched();
    this.dataForm.updateValueAndValidity();

    if (
      this.infoRequests?.documento == null &&
      this.infoRequests?.estado?.id == 2
    ) {
      if (this.documento == undefined || this.documento == null) {
        this.snackBarSrv.showSnackBar('Adjunte documento', 'error');
        return;
      }
    }

    if (this.dataForm.valid || this.dataForm.disabled) {
      const objPuntosRecogidaFilter = this.infoRequests;

      objPuntosRecogidaFilter.fechaPrevista = this.datePipe.transform(
        this.dataForm.get('fechaPrevista')?.value,
        'yyyy-MM-dd'
      );

      objPuntosRecogidaFilter.fechaRecogida = this.datePipe.transform(
        this.dataForm.get('fechaRecogida')?.value,
        'yyyy-MM-dd'
      );

      objPuntosRecogidaFilter.codigoLerReal = {
        id: this.dataForm.get('codigoLerRecogida')?.value,
      };

      objPuntosRecogidaFilter.cantidadRecogidaReal = this.parseCurrencyPipe.transform(this.dataForm.get('kgReales')?.value);

      objPuntosRecogidaFilter.observacionesGestor = this.dataForm.get(
        'observacionesGestor'
      )?.value;

      objPuntosRecogidaFilter.documentoIdentificacion =
        this.dataForm.get('documentoDi')?.value;

      objPuntosRecogidaFilter.autofactura = {
        id: this.dataForm.get('autoFactura')?.value,
      };

      this.spinnerSrv.loadingShow();
      this.solicitudControllerService
        .guardarSolicitudEnCurso(this.infoRequests?.id, objPuntosRecogidaFilter)
        .subscribe({
          next: (value: any) => {
            this.spinnerSrv.loadingHide();
            this.dialogRef.close(true);
            this.snackBarSrv.showSnackBar(
              'Se ha guardado correctamente la solicitud en curso',
              'success'
            );
          },
          error: (error: any) => {
            this.spinnerSrv.loadingHide();
          },
        });
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.dataForm.controls[controlName].hasError(errorName) &&
      this.dataForm.controls[controlName].touched
    );
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.dataForm.controls[controlName]?.touched ||
        this.dataForm.controls[controlName]?.dirty) &&
      this.dataForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  showClearButton(formName: string): boolean {
    return this.dataForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.dataForm.get(formName)?.setValue(null);
  }

  adjuntarA() {
    this.fileInputA.nativeElement.click();
  }

  handleFileInputA(event: any) {
    this.documento = event.target.files[0];
    if (this.documento) {
      this.nombreArchivoA = this.documento.name;
      this.dataForm.get('documento')?.setValue(this.nombreArchivoA);
      this.snackBarSrv.showSnackBar(
        'Se adjuntó el archivo correctamente.',
        'success'
      );
    } else {
      this.snackBarSrv.showSnackBar(
        'Por favor, seleccione un archivo.',
        'error'
      );
    }
  }

  attachAdjunto(archivo: File | null): void {
    if (archivo) {
      if (archivo.size === 0) {
        this.snackBarSrv.showSnackBar(
          'No se puede subir un fichero vacío',
          'error'
        );
      } else if (archivo.size > 5 * 1024 * 1024) { // 5 MB
        this.snackBarSrv.showSnackBar(
          'El fichero supera el tamaño máximo permitido de 5 MB',
          'error'
        );
      } else {
        this.documento = archivo;
      }
    }
  }

  descargar(documento: any) {
    this.spinnerSrv.loadingShow();
    this.documentService.descargarDocumento(documento.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Se ha descargado el documento correctamente',
          'success'
        );
        this.downloadService.downloadBlob(response, documento.nombre);
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }
}
