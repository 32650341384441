import { Component, Inject } from '@angular/core';

import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  DocumentoInteresControllerService, DocumentoInteresModel
} from 'api-rest';
import { Combo, ComboService } from '../../../../services/combo.service';
import { DownloadService } from '../../../../services/download.service';
import { SnackbarService } from '../../../../services/snackbar.service';
import { map } from 'rxjs';
import Swal from 'sweetalert2';
import { SpinnerService } from '../../../../services/spinner.service';
import { DocumentsInterestDetailModalComponent } from '../documents-interest-detail-modal/documents-interest-detail-modal.component';

@Component({
  selector: 'lib-documents-interest-search',
  templateUrl: './documents-interest-search.component.html',
  styleUrls: ['./documents-interest-search.component.scss'],
})
export class DocumentsInterestSearchComponent {
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  documentos: DocumentoInteresModel[] = [];

  defaultFilter: boolean = true;
  headArray: any = [
    {
      Head: 'Nombre',
      FieldName: 'nombre',
      Tooltip: true,
    },
    {
      Head: 'Documento',
      FieldName: 'filename',
      Tooltip: true,
      //Maxtext: 70,
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        { nameButton: 'edit', icon: 'open_in_new', toolTip: 'Editar' },
        { nameButton: 'delete', icon: 'delete', toolTip: 'Borrar' },
      ],
      width: '8',
      permanent: true,
    },
  ];
  gridArray: any[] = [];

  searchForm: FormGroup;
  flagSearch: boolean = false;
  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;

  constructor(
    @Inject('environment') private environment: any,
    private documentosInteresService: DocumentoInteresControllerService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private downloadService: DownloadService,
    private snackbarService: SnackbarService,
    private comboService: ComboService,
    public spinnerSrv: SpinnerService
  ) {
    this.searchForm = this.formBuilder.group({});
  }

  ngOnInit(): void {
    this.searchData();
    
  }

  renderPage(event: number) {
    this.pageNumber = event;
    this.obtenerDocumentosInteres();
  }


  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  checked(item: any) {}

  searchData() {
    this.pageNumber = 1;
    this.obtenerDocumentosInteres();
  }

  obtenerDocumentosInteres() {
    if (this.searchForm.invalid) {
      this.searchForm.markAllAsTouched();
      return;
    }
    this.spinnerSrv.loadingShow();
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;
    this.documentosInteresService
      .findAll1({
        page: page,
        size: this.pageSize,
      })
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.spinnerSrv.loadingHide();
            this.flagSearch = data.content?.length != 0 ? true : false;
            this.gridArray = data.content;
            this.recordsTotal = data.totalElements ?? 0;
            //this.totalSearch = this.gridArray.length;
          }
        },
        error: (error) => {
          this.spinnerSrv.loadingHide();
          this.snackbarService.showSnackBar(
            'Error obteniendo listado de documentos de interés',
            'error'
          );
        },
      });
  }

  action(item: any) {
    if (item.nameButton == 'edit') {
      this.openModalDetail(item, true);
    } else if (item.nameButton == 'delete') {
      this.confirmacion(
        `¿Desea eliminar el documento seleccionado?`,
        () => this.eliminarDocumento(item)
      );
    }
  }

  clearSearcher() {
    this.searchForm.reset();
    this.flagSearch = false;
    this.gridArray = [];
  }

  getFormControl(controlName: string): FormControl {
    return this.searchForm.controls[controlName] as FormControl;
  }

  eliminarDocumento(item: any) {
    this.spinnerSrv.loadingShow();
    this.documentosInteresService
      .eliminarDocumentoInteres(item.id)
      .subscribe({
        next: (data: any) => {
          this.spinnerSrv.loadingHide();
          this.snackbarService.showSnackBar(
            'Se ha eliminado el documento correctamente.',
            'success'
          );
          this.searchData();
        },
        error: (error) => {
          this.spinnerSrv.loadingHide();
          this.snackbarService.showSnackBar(
            'Error al eliminar el documento.',
            'error'
          );
        },
      });
  }

  confirmacion(titulo: string, callBack: Function): void {
    Swal.fire({
      text: titulo,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        callBack();
      }
    });
  }

  openModalDetail(element?: any, editMode = false): void {
      const dialogRef = this.dialog.open(DocumentsInterestDetailModalComponent, {
        width: '950px',
        maxWidth: '95%',
        maxHeight: '95vh',
        data: {
          item: element,
          editMode: editMode,
        },
        disableClose: true,
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.searchData();
        }
      });
  }
}
