import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PackagingWasteManagementComponent } from '../pages/packaging-waste-management.component';
import {
  NgbPaginationModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ApiCore, ApiPoseedores } from 'api-rest';
import { MaterialModule } from './material.module';
import { EmpusaSpinnerComponent } from '@empusa/empusa-core';
import { CustomDatePickerModule } from './custom-date-picker.module';
import { GeTableComponent } from '../share/components/ge-table.component';
import { GetAttributePipe } from '../share/components/pipe/get-attribute.pipe';
import { GeSelectPaginationComponent } from '../share/components/ge-select-pagination/ge-select-pagination.component';

export const CoreConfigFactory = () =>
  new ApiCore.Configuration(<ApiCore.ConfigurationParameters>{});

export const CoreBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE CORE ' + environment.urlBackCore);
  const basePath = environment.urlBackCore;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const PoseedoresConfigFactory = () =>
  new ApiPoseedores.Configuration(<ApiPoseedores.ConfigurationParameters>{});

export const PoseedoresBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE POSEEDORES ' + environment.urlBackPoseedores);
  const basePath = environment.urlBackPoseedores;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const explorerRoutes = [
  {
    path: '',
    component: PackagingWasteManagementComponent,
    children: [],
  },
];

@NgModule({
  declarations: [
    PackagingWasteManagementComponent,

    GeTableComponent,
    GetAttributePipe,
    GeSelectPaginationComponent,
  ],
  imports: [
    NgbTooltipModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgSelectModule,
    CommonModule,
    RouterModule.forChild(explorerRoutes),
    ApiPoseedores.ApiModule.forRoot(PoseedoresConfigFactory),
    MaterialModule,
    EmpusaSpinnerComponent,
    CustomDatePickerModule,
  ],
  exports: [PackagingWasteManagementComponent],
  providers: [
    {
      provide: ApiPoseedores.BASE_PATH,
      useFactory: PoseedoresBasePathFactory,
      deps: ['environment'],
    },
  ],
})
export class PackagingWasteManagementHomeModule {
  constructor() {
    let compilationVersion = '0.0.1-R->04/10/23-12:14:49';
    console.log('Home version:' + compilationVersion);
  }
}
