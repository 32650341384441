import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  DeclaracionAdheridoControllerService,
  DeclaracionAdheridoCreatePayloadDTO,
  DeclaracionAdheridoDTO,
  DeclaracionCentroDTO,
} from 'api-rest';
import moment from 'moment';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { ValidateService } from '../../../services/validate.service';
import swal from 'sweetalert2';
import { Router } from '@angular/router';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';

@Component({
  selector: 'lib-package-general-data',
  templateUrl: './package-general-data.component.html',
  styleUrls: ['./package-general-data.component.scss'],
})
export class PackageGeneralDataComponent implements OnChanges {
  formGroup!: FormGroup;
  @Input() declaracionAdherido!: DeclaracionAdheridoDTO | any;
  @Input() centrosDeclaracion!: DeclaracionCentroDTO[];
  @Output() actualizarDeclaracion: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  sumatoriaCurso: number = 0;
  sumatoriaAnterior: number = 0;
  sumatoriaDepCurso: number = 0;
  sumatoriaDepAnterior: number = 0;

  anoDatosCurso: any;
  anoDatosAnterior: any;

  constructor(
    private formBuilder: FormBuilder,
    private declaracionAdheridoControllerSrv: DeclaracionAdheridoControllerService,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private validateSrv: ValidateService,
    private router: Router,
    @Inject('EmpusaAuthenticationService')
    public authService: EmpusaAuthenticationService
  ) {
    this.formGroup = this.formBuilder.group({
      /*iban: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.pattern(this.validateSrv.ibanpattern),
          ]),
        },
      ],*/
      numPedido: [{ value: null, disabled: false }, Validators.maxLength(255)],
      fechaInicio: [{ value: null, disabled: true }],
      fechaFin: [{ value: null, disabled: true }],
      fechaRechazo: [{ value: null, disabled: true }],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.declaracionAdherido) {
      this.anoDatosCurso =
        (Number(this.declaracionAdherido?.anioDatos) || 0) + 1;
      this.anoDatosAnterior = this.declaracionAdherido?.anioDatos;

      if (!this.canAccessEditAllGlobal()) {
        /*this.formGroup.get('iban')?.disable();*/
        this.formGroup.get('numPedido')?.disable();
      }

      /*this.formGroup
        .get('iban')
        ?.setValue(this.declaracionAdherido?.ibanAdherido);*/
      this.formGroup
        .get('numPedido')
        ?.setValue(this.declaracionAdherido?.numeroPedido);
      let fInicio = '';
      if (this.declaracionAdherido?.fechaInicioDec) {
        fInicio = moment(
          new Date(this.declaracionAdherido?.fechaInicioDec)
        ).format('DD/MM/YYYY');
      }
      this.formGroup.get('fechaInicio')?.setValue(fInicio);
      let fFin = '';
      if (this.declaracionAdherido?.fechaFinalizacionDec) {
        fFin = moment(
          new Date(this.declaracionAdherido?.fechaFinalizacionDec)
        ).format('DD/MM/YYYY');
      }
      this.formGroup.get('fechaFin')?.setValue(fFin);
      let fRechazo = '';
      if (this.declaracionAdherido?.fechaRechazoDec) {
        fRechazo = moment(
          new Date(this.declaracionAdherido?.fechaRechazoDec)
        ).format('DD/MM/YYYY');
      }
      this.formGroup.get('fechaRechazo')?.setValue(fRechazo);
    }

    this.calcularSumatorias();
  }

  calcularSumatorias(): void {
    const getValue = (value: any): number => {
      return value != null && !isNaN(value) ? value : 0;
    };

    this.sumatoriaCurso =
      getValue(this.declaracionAdherido?.contriRapAnioCursoSoloUso) +
      getValue(this.declaracionAdherido?.contriRapAnioCursoAbierto) +
      getValue(this.declaracionAdherido?.contriRapAnioCursoCerrado);

    this.sumatoriaAnterior =
      getValue(this.declaracionAdherido?.contriRapAnioAnteriorSoloUso) +
      getValue(this.declaracionAdherido?.contriRapAnioAnteriorAbierto) +
      getValue(this.declaracionAdherido?.contriRapAnioAnteriorCerrado);

    this.sumatoriaDepCurso =
      getValue(this.declaracionAdherido?.depoRapAnioCursoAbierto) +
      getValue(this.declaracionAdherido?.depoRapAnioCursoCerrado);

    this.sumatoriaDepAnterior =
      getValue(this.declaracionAdherido?.depoRapAnioAnteriorAbierto) +
      getValue(this.declaracionAdherido?.depoRapAnioAnteriorCerrado);
  }

  guardar(): void {
    if (this.formGroup.valid) {
      let filtro: DeclaracionAdheridoCreatePayloadDTO = {
        adherido: this.declaracionAdherido?.adherido,
        anioDatos: this.declaracionAdherido?.anioDatos,
        version: this.declaracionAdherido?.version,
        fechaInicioDec: this.declaracionAdherido?.fechaInicioDec,
        estadoDeclaracion: this.declaracionAdherido?.estadoDeclaracion,
        /*ibanAdherido: this.formGroup.get('iban')?.value,*/
        numeroPedido: this.formGroup.get('numPedido')?.value,
      };
      this.spinnerSrv.loadingShow();
      this.declaracionAdheridoControllerSrv
        .editById5(this.declaracionAdherido?.id!, filtro)
        .subscribe({
          next: (responses) => {
            this.snackBarSrv.showSnackBar(
              'Se han guardado los datos generales correctamente',
              'success'
            );
            this.actualizarDeclaracion.emit(true);
            this.spinnerSrv.loadingHide();
          },
          error: () => {
            this.spinnerSrv.loadingHide();
          },
        });
    } else {
      this.snackBarSrv.showSnackBar(
        'Revisa los errores en los campos ',
        'error'
      );
    }
  }

  convertToComma(value: any): string {
    if (typeof value === 'number') {
      let stringValue = value.toFixed(5).replace('.', ',');
      stringValue = stringValue.replace(/,?0+$/, '');
      return stringValue;
    } else if (typeof value === 'string') {
      return value.replace('.', ',').replace(/,?0+$/, '');
    } else {
      return '0';
    }
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }

  buttonCancelar() {
    swal
      .fire({
        text: '¿Desea volver al buscador?',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.router.navigate(['/attached-app/packaging-declaration-search']);
        }
      });
  }

  buttonEconomico() {
    this.spinnerSrv.loadingShow();
    this.declaracionAdheridoControllerSrv
      .actualizarResumenEconomico(this.declaracionAdherido.id!)
      .subscribe({
        next: (res) => {
          this.declaracionAdherido.contriRapAnioCursoAbierto =
            res.contriRapAnioCursoAbierto;
          this.declaracionAdherido.contriRapAnioAnteriorAbierto =
            res.contriRapAnioAnteriorAbierto;
          this.declaracionAdherido.depoRapAnioCursoAbierto =
            res.depoRapAnioCursoAbierto;
          this.declaracionAdherido.depoRapAnioAnteriorAbierto =
            res.depoRapAnioAnteriorAbierto;

          this.declaracionAdherido.contriRapAnioCursoCerrado =
            res.contriRapAnioCursoCerrado;
          this.declaracionAdherido.contriRapAnioAnteriorCerrado =
            res.contriRapAnioAnteriorCerrado;
          this.declaracionAdherido.depoRapAnioCursoCerrado =
            res.depoRapAnioCursoCerrado;
          this.declaracionAdherido.depoRapAnioAnteriorCerrado =
            res.depoRapAnioAnteriorCerrado;

          this.declaracionAdherido.contriRapAnioCursoSoloUso =
            res.contriRapAnioCursoSoloUso;
          this.declaracionAdherido.contriRapAnioAnteriorSoloUso =
            res.contriRapAnioAnteriorSoloUso;

          this.declaracionAdherido.reqActEconomica = res.reqActEconomica;

          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'El resumen económico se ha actualizado correctamente',
            'success'
          );
        },
        error: (error) => {
          if (error.error.detail) {
            this.snackBarSrv.showSnackBar(error.error.detail, 'error');
          } else {
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al actualizar el resumen económico',
              'error'
            );
          }
          this.spinnerSrv.loadingHide();
        },
      });
  }

  canAccessEditAllGlobal(): boolean {
    return (
      this.authService.can('dec_envases', 'update') &&
      this.declaracionAdherido?.estadoDeclaracion?.id == 0
    );
  }

  canAccessFinalizar() {
    return (
      (this.canViewRol('AD-ADHERIDO') || this.canViewRol('AD-ADHERIDO')) &&
      this.declaracionAdherido?.estadoDeclaracion?.id == 0
    );
  }

  canViewRol(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }


  buttonFinalizarDeclaracion() {
    // Mostrar el primer modal para confirmar la finalización de la declaración
    swal.fire({
      text: '¿Está seguro de finalizar y dar por completada la declaración? No podrán agregarse más envases a esta declaración.',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        // Verificar si no hay envases
        this.checkComprobacionEnvases();
      }
    });
  }

  confirmarFinalizacionConEnvasesCero() {
    swal.fire({
      text: 'La declaración tiene cantidad total de envases igual a 0, ¿está seguro que quiere finalizar la declaración?',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Finalizar declaración',
      denyButtonText: 'Cancelar',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.confirmarFinalizacionConSectoresVacios();
      }
    });
  }

  confirmarFinalizacionConSectoresVacios() {
    if (this.sectoresPorcVacios()) {
      swal.fire({
        text: 'En algún centro de envasado no se han indicado los sectores de destino y/o el % de comercialización de las CCAA destino de sus envases y está a punto de finalizar la declaración, por lo que ya no podrá realizar modificaciones. Por favor, informe dicha información antes de finalizar si lo desea.',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Finalizar declaración',
        denyButtonText: 'Cancelar',
        allowOutsideClick: false,
      }).then((result: any) => {
        if (result.isConfirmed) {
          this.finalizarDeclaracion();
        }
      });
    } else {
      this.finalizarDeclaracion();
    }
  }

  checkComprobacionEnvases(): void {
    // Comprueba si la declaración no tiene envases
    this.spinnerSrv.loadingShow();
    this.declaracionAdheridoControllerSrv.comprobarEnvases(this.declaracionAdherido?.id, 'response')
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          if (!response.body) {
            this.confirmarFinalizacionConEnvasesCero();
          } else {
            this.confirmarFinalizacionConSectoresVacios();
          }
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  sectoresPorcVacios(): boolean {
    // Comprueba si hay centros con todos los sectores o todos los centroComunidad vacíos
    return this.centrosDeclaracion.some((centro: DeclaracionCentroDTO) => (!centro?.sectores || centro?.sectores?.length === 0 || !centro?.centroComunidad || centro?.centroComunidad?.length === 0));
  }

  finalizarDeclaracion(): void {
    this.spinnerSrv.loadingShow();
    this.declaracionAdheridoControllerSrv
      .finalizarDeclaracion(this.declaracionAdherido?.id)
      .subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha finalizado la declaración correctamente',
            'success'
          );
          this.actualizarDeclaracion.emit(true);
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
  }
}
