import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SpinnerService } from '../../../services/spinner.service';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'app-managers-search',
  templateUrl: './managers-search.component.html',
  styleUrls: ['./managers-search.component.scss'],
})
export class ManagersSearchComponent implements OnInit {
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  totalElements: number = 0;
  totalSearch: number = 0;

  provinciaArray: any[] = [];
  estadoArray: any[] = [];

  headArray: any = [
    {
      Head: 'ID. Gestor de Residuo',
      FieldName: '',
    },
    {
      Head: 'Razón Social',
      FieldName: '',
    },
    {
      Head: 'Provincia',
      FieldName: '',
    },
    {
      Head: 'Documento (NIE,NIF)',
      FieldName: '',
    },
    {
      Head: 'Estado',
      FieldName: '',
    },
    {
      Head: 'NIMA',
      FieldName: '',
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        { nameButton: 'view', icon: 'open_in_new', toolTip: 'Editar' },
        { nameButton: 'delete', icon: 'delete', toolTip: 'Borrar' },
        {
          nameButton: 'gestor',
          icon: 'delete',
          toolTip: 'Baja de Poseedor',
        },
      ],
      width: '8',
      permanent: true,
    },
  ];
  gridArray: any[] = [];

  searchForm: FormGroup;

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private downloadService: DownloadService,
    private snackBarSrv: SnackbarService
  ) {
    this.searchForm = this.formBuilder.group({
      idGestor: [{ value: null, disabled: false }, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      codigo_documento: [{ value: null, disabled: false }],
      razon_social: [{ value: null, disabled: false }],
      provincia_social: [{ value: null, disabled: false }],
      estado: [{ value: null, disabled: false }],
      nima: [{ value: null, disabled: false }],
    });
  }

  ngOnInit() {}

  checked(event: any) {}

  renderPage(event: any) {}

  exportarExcel() {
    // const { backendFilter, page, size } = this.getBackendFilter();
    // this..exportCSV(
    //   backendFilter,
    //   'response'
    // ).subscribe((res) => {
    //   const filename = this.downloadService.getFileName(
    //     res.headers,
    //     'Exportacion.csv'
    //   );
    //   this.downloadService.downloadCSV(res.body!, filename!);
    // });
  }

  clearSearcher() {
    this.searchForm.reset();
    this.flagSearch = false;
    this.gridArray = [];
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.searchForm.controls[controlName].hasError(errorName) &&
      this.searchForm.controls[controlName].touched
    );
  }

  private getBackendFilter() {
    const backendFilter = {};
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    return { backendFilter, page, size: this.pageSize };
  }

  searchData() {
    if (this.searchForm.invalid) {
      this.searchForm.markAllAsTouched();
      return;
    }
    // const { backendFilter, page, size } = this.getBackendFilter();
    // this.spinnerSrv.loadingShow();
    // this..findAll(backendFilter, {
    //   page: page,
    //   size: size,
    // }).subscribe({
    //   next: (data: ) => {
    //     if (data) {
    //       this.spinnerSrv.loadingHide();
    //       this.flagSearch = data.datos?.length != 0 ? true : false;
    //       this.gridArray =
    //         data.datos
    //       this.recordsTotal = data.paginacion?.totalElements ?? 0;
    //       this.totalSearch = this.gridArray.length;
    //     }
    //   },
    //   error: (error: any) => {
    //     this.spinnerSrv.loadingHide();
    //   },
    // });
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  action(element: any) {
    if (element.nameButton == 'view') {
    } else if (element.nameButton == 'delete') {
      this.confirmacionEliminar(element);
    }
  }

  confirmacionEliminar(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar el gestor?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarGestor(element);
      }
    });
  }

  eliminarGestor(element?: any) {
    if (element?.id) {
      // this.spinnerSrv.loadingShow();
      // this.---.deleteById(element.id).subscribe({
      //   next: (value: any) => {
      //     this.spinnerSrv.loadingHide();
      //     this.snackBarSrv.showSnackBar(
      //       `El poseedor se ha eliminado correctamente`,
      //       'success'
      //     );
      //     this.gridArray = this.gridArray.filter(
      //       (objeto) => objeto.id !== element.id
      //     );
      //     this.flagSearch = this.gridArray.length != 0 ? true : false;
      //   },
      //   error: (err: { message: any }) => {
      //     this.spinnerSrv.loadingHide();
      //     this.snackBarSrv.showSnackBar(
      //       'Ha ocurrido un error al eliminar el poseedor',
      //       'error'
      //     );
      //   },
      // });
    }
  }

  combo() {
    // const backendFilter:  = {};
    // this.
    //   .findAll4(backendFilter, { page: 0, size: 100 })
    //   .subscribe({
    //     next: (data: ) => {
    //       this.codigoLerArray = data?.datos!;
    //     },
    //     error: () => {},
    //   });
  }

  getCnaDescription(cnae: any) {
    return cnae.clase + ' - ' + cnae.descripcion;
  }

  getCnaeObjectQuery(searchterm: string) {
    return {
      descripcion: `or(ci(${searchterm}))`,
      clase: `or(ci(${searchterm}))`,
    };
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.searchForm.controls[controlName]?.touched ||
        this.searchForm.controls[controlName]?.dirty) &&
      this.searchForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  onlyNumberKey(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Control'];

    if (event.ctrlKey && (event.key === 'c' || event.key === 'v')) return;

    // Permitir números y teclas adicionales definidas
    if (!/^[0-9]$/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
