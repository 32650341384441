import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { Router } from '@angular/router';
import { FacturaSddrcaControllerService } from 'api-rest';

@Component({
  selector: 'app-open-sddr-invoice-reject-dialog',
  templateUrl: './open-sddr-invoice-reject-dialog.component.html',
  styleUrls: ['./open-sddr-invoice-reject-dialog.component.scss'],
})
export class OpenSddrInvoiceRejectDialogComponent implements OnInit {
  formGroup!: FormGroup;
  info: any;

  constructor(
    public dialogRef: MatDialogRef<OpenSddrInvoiceRejectDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private snackBarSrv: SnackbarService,
    public spinnerSrv: SpinnerService,
    private facturaSddrcaCtrSrv: FacturaSddrcaControllerService
  ) {
    this.formGroup = this.formBuilder.group({
      comentarioRechazar: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
    });
    this.info = this.data.info;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {}

  rechazarFactura() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.valid) {
      const comentario = this.formGroup.get('comentarioRechazar')?.value;
      this.spinnerSrv.loadingShow();
      this.facturaSddrcaCtrSrv
        .rechazarFactura(this.info?.id, comentario)
        .subscribe({
          next: () => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Se ha rechazado la factura correctamente',
              'success'
            );
            this.dialogRef.close(true);
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            console.log(err);
          },
        });
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe introduzcir el motivo del rechazo',
        'error'
      );
    }
  }

  cerrar(): void {
    this.dialogRef.close(null);
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }
}
