import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  ContactoControllerService,
  ContactoModel,
  PoseedorControllerService,
  PoseedorDTO,
  TipoContactoControllerService,
  TipoContactoModel,
} from 'api-rest';
import { SpinnerService } from '../../../services/spinner.service';
import { ComboService } from '../../../services/combo.service';
import { Combo } from '../../../services/combo.service';
import { ValidateService } from '../../../services/validate.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';

@Component({
  selector: 'lib-holders-contact-dialog',
  templateUrl: './holders-contact-dialog.component.html',
  styleUrls: ['./holders-contact-dialog.component.scss'],
})
export class HoldersContactDialogComponent implements OnInit {
  contacto: any;
  titulo: string = '';
  formGroup!: FormGroup;
  tiposContactos: TipoContactoModel[] = [];
  copiarContacto: any[] = [];
  tiposDocumento: Combo[] = [];
  tiposTelefono: Combo[] = [];
  organos: Combo[] = [];
  paisPrefijo!: any;
  updateList: boolean = false;
  paisesEndpoint = this.environment.urlBackCore + 'api/v1/core/countries';
  poseedor!: PoseedorDTO;
  personType: boolean | undefined = true;
  flagFirmante: boolean = false;
  flagFacturacion: boolean = false;
  flagRadioNo: boolean = false;

  constructor(
    @Inject('environment') private environment: any,
    public dialogRef: MatDialogRef<HoldersContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private validateSrv: ValidateService,
    public spinnerSrv: SpinnerService,
    private tipoContactoControllerSrv: TipoContactoControllerService,
    private comboSrv: ComboService,
    private snackBarSrv: SnackbarService,
    private contactoControllerSrv: ContactoControllerService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    private poseedorControllerSrv: PoseedorControllerService
  ) {
    this.contacto = this.data.contacto;
    this.poseedor = this.data.poseedor;
    this.formGroup = this.formBuilder.group({
      tipoContacto: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      copiaContacto: [{ value: null, disabled: false }],
      requerido: [{ value: null, disabled: false }],
      persona: [
        { value: false, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      nombre: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(50)]) },
      ],
      apellido1: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(50)]) },
      ],
      apellido2: [{ value: null, disabled: false },
        {validators: Validators.compose([Validators.maxLength(50)])}
      ],
      documentType: [{ value: null, disabled: false}],
      document: [
        {value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(15)])}
      ],
      tipoTelefono: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      prefijoPais: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      telefono: [
        { value: null, disabled: false },
        Validators.compose([Validators.required, this.telefonoValidator.bind(this),Validators.maxLength(15)])
      ],
      recibirComunicaciones: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      recibirComunicacionesContrato: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      acreditacionPoderes: [{ value: null, disabled: false }],
      email: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(this.validateSrv.emailPattern),
            Validators.maxLength(100)
          ]),
        },
      ],
    });
  }
  ngOnInit(): void {
    if (this.contacto) {
      this.titulo = 'Editar contacto';
      this.cargarContactos(this.contacto, true);
    } else {
      this.titulo = 'Nuevo contacto';
      this.formGroup.get('persona')?.setValue(true);
    }
    this.getTiposContactos();
    this.tiposDocumento = this.comboSrv.getTiposDocumentoIdentidad();
    this.tiposTelefono = this.comboSrv.getTiposTelefono();
    this.organos = this.comboSrv.getOrganos();

    if (this.poseedor?.contactos) {
      this.copiarContacto = this.poseedor?.contactos?.filter(
        (objeto: any) => objeto.nombre !== null && objeto.nombre !== ''
      );
    }
    if (!this.canAccessUpdate()) {
      this.formGroup.disable();
    }

    this.formGroup.get('tipoTelefono')?.valueChanges.subscribe(() => {
      this.formGroup.get('telefono')?.updateValueAndValidity();
    });

    this.formGroup.get('prefijoPais')?.valueChanges.subscribe(() => {
      this.formGroup.get('telefono')?.updateValueAndValidity();
    });

    this.cargarFirmante();
  }
  canAccessUpdate(): boolean {
    return this.authService.can('poseedores-contactos', 'update');
  }
  getTiposContactos(): void {
    this.spinnerSrv.loadingShow();
    this.tipoContactoControllerSrv
      .listarTiposContacto({ page: 0, size: 50 })
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          if (response?.content) {
            this.tiposContactos = response?.content;
          }
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al cargar los tipos de contactos.',
            'error'
          );
        },
      });
  }

  telefonoValidator(control: AbstractControl): { [key: string]: any } | null {
    const tipoTelefono = this.formGroup?.get('tipoTelefono')?.value;
    const prefijoPais = this.formGroup?.get('prefijoPais')?.value;

    const phonePattern =
      /^(\+?\d{1,3})?[-. (]?(\d{1,4})[-. )]?(\d{1,4})[-. ]?(\d{1,4})[-. ]?(\d{1,9})$/;
    const isGeneralValid = phonePattern.test(control.value);

    if (!isGeneralValid) {
      return { invalidPhoneFormat: true };
    }

    if (
      prefijoPais?.prefijo == '34' ||
      (typeof prefijoPais === 'string' && prefijoPais?.toUpperCase() == '34 (ESPAÑA)')
    ) {
      if (tipoTelefono === 'FIJO') {
        const valid = /^[89][0-9]{8}$/.test(control.value);
        return valid ? null : { invalidTelefonoFijo: true };
      } else if (tipoTelefono === 'MOVIL') {
        const valid = /^[67][0-9]{8}$/.test(control.value);
        return valid ? null : { invalidTelefonoMovil: true };
      }
    }

    return null;
  }

  tipoContactoSel(idTipoContacto?: number | null): void {
    if (idTipoContacto === 3 || idTipoContacto === 4) {
      // Si tipo contacto firmante o reperesentante autorizado tipo documento y documento, son obligatorios
      this.formGroup.get('documentType')?.setValidators([Validators.required]);
      this.formGroup.get('document')?.setValidators([Validators.required]);
    } else {
      // en cualquier otro caso, no serán obligatorios
      this.formGroup.get('documentType')?.clearValidators();
      this.formGroup.get('document')?.clearValidators();
    }
    this.formGroup.get('documentType')?.updateValueAndValidity();
    this.formGroup.get('document')?.updateValueAndValidity();

    if ([1, 2, 5].includes(this.formGroup.get('tipoContacto')?.value)) {
      this.flagFacturacion = true;
      //this.formGroup.get('acreditacionPoderes')?.clearValidators();
      //this.formGroup.get('acreditacionPoderes')?.updateValueAndValidity();
    //} else {
    //  this.formGroup
    //    .get('acreditacionPoderes')
    //    ?.setValidators([Validators.required]);
    //  this.formGroup.get('acreditacionPoderes')?.updateValueAndValidity();
    //  this.formGroup.get('acreditacionPoderes')?.markAllAsTouched();
    }
  }

  onTipoEntidadChange(): void {
    const persona = this.formGroup.get('persona')?.value;
    const documentType = this.formGroup.get('documentType')?.value;

    if (this.formGroup) {
      const nombreControl = this.formGroup.get('nombre');
      const apellido1Control = this.formGroup.get('apellido1');
      const apellido2Control = this.formGroup.get('apellido2');
      const documentTypeControl = this.formGroup.get('documentType');

      if (
        nombreControl &&
        apellido1Control &&
        apellido2Control &&
        documentTypeControl
      ) {
        // Restablecer la visibilidad
        nombreControl?.enable();
        apellido1Control?.enable();
        apellido2Control?.enable();
        documentTypeControl?.enable();
        // documentTypeControl?.reset();
        this.personType = persona;

        // Ocultar y cambiar validación según la opción seleccionada
        if (!persona) {
          // Empresa
          apellido1Control?.disable();
          apellido2Control?.disable();
          documentTypeControl?.setValue('NIF');
          documentTypeControl?.disable();
          this.tipoDocumentoSel('NIF');
        } else {
          this.tipoDocumentoSel(documentType);
        }
        nombreControl?.updateValueAndValidity();
        apellido1Control?.updateValueAndValidity();
        apellido2Control?.updateValueAndValidity();
      }
    }
  }

  tipoDocumentoSel(idTipoDoc: string): void {
    this.formGroup.get('document')?.clearValidators();
    if (idTipoDoc === 'NIF') {
      if (
        this.formGroup.get('tipoContacto')?.value == 3 ||
        this.formGroup.get('tipoContacto')?.value == 4
      ) {
        this.formGroup
          .get('document')
          ?.setValidators([
            Validators.required,
            Validators.pattern(this.validateSrv.nifPattern),
          ]);
      } else {
        this.formGroup
          .get('document')
          ?.setValidators([Validators.pattern(this.validateSrv.nifPattern)]);
      }
    } else if (idTipoDoc === 'DNI') {
      if (
        this.formGroup.get('tipoContacto')?.value == 3 ||
        this.formGroup.get('tipoContacto')?.value == 4
      ) {
        this.formGroup
          .get('document')
          ?.setValidators([
            Validators.required,
            Validators.pattern(this.validateSrv.dniPattern),
          ]);
      } else {
        this.formGroup
          .get('document')
          ?.setValidators([Validators.pattern(this.validateSrv.dniPattern)]);
      }
    } else if (idTipoDoc === 'NIE') {
      if (
        this.formGroup.get('tipoContacto')?.value == 3 ||
        this.formGroup.get('tipoContacto')?.value == 4
      ) {
        this.formGroup
          .get('document')
          ?.setValidators([
            Validators.required,
            Validators.pattern(this.validateSrv.niePattern),
          ]);
      } else {
        this.formGroup
          .get('document')
          ?.setValidators([Validators.pattern(this.validateSrv.niePattern)]);
      }
    }

    this.formGroup.get('document')?.updateValueAndValidity();
  }
  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }
  cerrar(): void {
    this.dialogRef.close(null);
  }
  selectedModel(selectForm: any) {
    switch (selectForm) {
      case 'copiaContacto': {
        this.cargarContactos(
          this.formGroup.controls['copiaContacto']?.value,
          false
        );
        break;
      }
      case 'prefijoPais': {
        this.paisPrefijo = this.formGroup.controls['prefijoPais']?.value;
        break;
      }
      default: {
        break;
      }
    }
  }
  filterList(data: any, selectForm: any) {
    switch (selectForm) {
      case 'copiaContacto': {
        return this.formGroup.get('copiaContacto') as FormControl;
        break;
      }
      case 'prefijoPais': {
        return this.formGroup.get('prefijoPais') as FormControl;
        break;
      }
      default: {
        return this.formGroup.get('') as FormControl;
        break;
      }
    }
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'copiaContacto': {
        return this.formGroup.get('copiaContacto') as FormControl;
        break;
      }
      case 'prefijoPais': {
        return this.formGroup.get('prefijoPais') as FormControl;
        break;
      }
      default: {
        return this.formGroup.get('') as FormControl;
        break;
      }
    }
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }
  controlHasErrorSelect(controlName: string) {
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  cargarContactos(contacto: ContactoModel, editar: boolean): void {
    if (!contacto) return;

    this.personType = contacto.persona;
    this.formGroup.patchValue({
      persona: contacto?.persona,
      nombre: contacto?.nombre,
      apellido1: contacto?.apellido1,
      apellido2: contacto?.apellido2,
      documentType: contacto?.tipo_documento,
      document: contacto?.codigo_documento,
      tipoTelefono: contacto?.tipo_telefono,
      prefijoPais: contacto?.codigoPais?.prefijoNombre,
      telefono: contacto?.numero_telefono,
      recibirComunicaciones: contacto?.recibir_comunicaciones,
      recibirComunicacionesContrato: contacto?.recibir_comunicaciones_contrato,
      acreditacionPoderes: contacto?.acreditacion_poderes,
      email: contacto?.email,
    });

    this.paisPrefijo = contacto?.codigoPais;
    // Si no es edición no se cargan requerido ni tipo de contacto
    if (editar) {
      this.formGroup.get('requerido')?.setValue(contacto?.requerido);
      this.formGroup.get('tipoContacto')?.setValue(contacto?.tipo_contacto?.id);
    }
    this.validacionCargaDatos(contacto);
  }

  validacionCargaDatos(contacto: ContactoModel) {
    this.formGroup.get('documentType')?.clearValidators();
    this.formGroup.get('document')?.clearValidators();
    if (contacto?.requerido && contacto?.tipo_contacto?.id !== 5) {
      this.formGroup.get('tipoContacto')?.disable();
    }

    if (
      contacto?.tipo_contacto?.id === 3 ||
      contacto?.tipo_contacto?.id === 4
    ) {
      // Si tipo contacto firmante o reperesentante autorizado tipo documento y documento, son obligatorios
      this.formGroup.get('documentType')?.setValidators([Validators.required]);
      this.formGroup.get('document')?.setValidators([Validators.required]);
    } else {
      // en cualquier otro caso, no serán obligatorios
      this.formGroup.get('documentType')?.clearValidators();
      this.formGroup.get('document')?.clearValidators();
    }

    if (contacto?.tipo_documento === 'NIF') {
      if (
        this.formGroup.get('tipoContacto')?.value === 3 ||
        this.formGroup.get('tipoContacto')?.value === 4
      ) {
        this.formGroup
          .get('document')
          ?.setValidators([
            Validators.required,
            Validators.pattern(this.validateSrv.nifPattern),
          ]);
      } else {
        this.formGroup
          .get('document')
          ?.setValidators([Validators.pattern(this.validateSrv.nifPattern)]);
      }
    }
    if (contacto?.tipo_documento === 'DNI') {
      if (
        this.formGroup.get('tipoContacto')?.value === 3 ||
        this.formGroup.get('tipoContacto')?.value === 4
      ) {
        this.formGroup
          .get('document')
          ?.setValidators([
            Validators.required,
            Validators.pattern(this.validateSrv.dniPattern),
          ]);
      } else {
        this.formGroup
          .get('document')
          ?.setValidators([Validators.pattern(this.validateSrv.dniPattern)]);
      }
    }
    if (contacto?.tipo_documento === 'NIE') {
      if (
        this.formGroup.get('tipoContacto')?.value === 3 ||
        this.formGroup.get('tipoContacto')?.value === 4
      ) {
        this.formGroup
          .get('document')
          ?.setValidators([
            Validators.required,
            Validators.pattern(this.validateSrv.niePattern),
          ]);
      } else {
        this.formGroup
          .get('document')
          ?.setValidators([Validators.pattern(this.validateSrv.niePattern)]);
      }
    }

    if (this.formGroup.get('tipoContacto')?.value === 3) {
      this.formGroup.get('documentType')?.setValidators([Validators.required]);
      this.formGroup.get('document')?.setValidators([Validators.required]);
    }

    if ([1, 2, 5].includes(this.formGroup.get('tipoContacto')?.value)) {
      this.flagFacturacion = true;
      this.flagRadioNo = false;
      this.formGroup.get('acreditacionPoderes')?.clearValidators();
      this.formGroup.get('acreditacionPoderes')?.updateValueAndValidity();
    }

    if ([3, 4].includes(this.formGroup.get('tipoContacto')?.value)) {
      this.flagRadioNo = true;
      this.flagFirmante = true;
      //this.formGroup
      //  .get('acreditacionPoderes')
      //  ?.setValidators([Validators.required]);
      this.formGroup.get('acreditacionPoderes')?.updateValueAndValidity();

      if (!this.formGroup.get('acreditacionPoderes')?.value) {
        this.formGroup.get('acreditacionPoderes')?.reset();
      }
    }

    if (!this.formGroup.get('persona')?.value) {
      this.formGroup.get('apellido1')?.disable();
    }

    this.formGroup.get('documentType')?.updateValueAndValidity();
    this.formGroup.get('document')?.updateValueAndValidity();

    this.formGroup.get('telefono')?.updateValueAndValidity();

    this.formGroup.markAllAsTouched();
    this.onTipoEntidadChange();
  }

  cargarFirmante() {
    this.formGroup.get('tipoContacto')?.valueChanges.subscribe((value: any) => {
      if ([3, 4].includes(value)) {
        this.flagFirmante = true;
        //this.formGroup
        //  .get('acreditacionPoderes')
        //  ?.setValidators([Validators.required]);
        this.formGroup.get('acreditacionPoderes')?.updateValueAndValidity();
      } else {
        this.flagFirmante = false;
        this.formGroup.get('acreditacionPoderes')?.clearValidators();
        this.formGroup.get('acreditacionPoderes')?.updateValueAndValidity();
      }
      if ([1, 2].includes(value)) {
        this.flagFacturacion = true;
      } else {
        this.flagFacturacion = false;
      }

      if (![3, 4].includes(value)) {
        this.flagRadioNo = false;
      } else {
        this.flagRadioNo = true;
        if (!this.formGroup.get('acreditacionPoderes')?.value) {
          this.formGroup.get('acreditacionPoderes')?.reset();
        }
      }
    });
  }

  guardar() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.valid) {
      let contacto = this.tiposContactos.find(
        (x) => x.id === this.formGroup.get('tipoContacto')?.value
      );

      const editContacto: ContactoModel = {
        id: this.contacto?.id,
        tipo_contacto: contacto,
        requerido: this.formGroup.get('requerido')?.value,
        persona: this.formGroup.get('persona')?.value,
        nombre: this.formGroup.get('nombre')?.value,
        apellido1: this.formGroup.get('persona')?.value ? this.formGroup.get('apellido1')?.value : null,
        apellido2: this.formGroup.get('persona')?.value ? this.formGroup.get('apellido2')?.value : null,
        tipo_documento: this.formGroup.get('documentType')?.value,
        codigo_documento: this.formGroup.get('document')?.value,
        tipo_telefono: this.formGroup.get('tipoTelefono')?.value,
        numero_telefono: this.formGroup.get('telefono')?.value,
        email: this.formGroup.get('email')?.value,
        recibir_comunicaciones: this.formGroup.get('recibirComunicaciones')
          ?.value,
        recibir_comunicaciones_contrato: this.formGroup.get(
          'recibirComunicacionesContrato'
        )?.value,
        acreditacion_poderes: this.formGroup.get('acreditacionPoderes')?.value,
      };
      // Si es un string es porque no he modificado el valor que me ha llegado del servicio
      let prefijo = this.formGroup.get('prefijoPais')?.value;
      if (typeof prefijo === 'string') {
        editContacto.codigoPais = this.paisPrefijo;
      } else {
        editContacto.codigoPais = this.formGroup.get('prefijoPais')?.value;
      }
      this.spinnerSrv.loadingShow();
      if (this.contacto) {
        //public actualizarContacto(id: number, contactoModel: ContactoModel, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: '*/*', context?: HttpContext}): Observable<ContactoModel>;
        this.contactoControllerSrv
          .actualizarContacto(this.contacto.id!, editContacto)
          .subscribe({
            next: () => {
              this.spinnerSrv.loadingHide();
              this.snackBarSrv.showSnackBar(
                'Datos de contacto guardados correctamente',
                'success'
              );
              this.dialogRef.close(true);
            },
            error: () => {
              this.spinnerSrv.loadingHide();
              this.snackBarSrv.showSnackBar(
                'Ha ocurrido un error al guardar los datos del contacto',
                'error'
              );
            },
          });
      } else {
        this.poseedor?.contactos?.push(editContacto);
        //editById2(id: number, poseedorCreatePayloadDTO: PoseedorCreatePayloadDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json';context?: HttpContext;}): Observable<PoseedorDTO>;
        this.poseedorControllerSrv
          .editById3(this.poseedor.id!, this.poseedor)
          .subscribe({
            next: () => {
              this.spinnerSrv.loadingHide();
              this.snackBarSrv.showSnackBar(
                'Contacto creado correctamente',
                'success'
              );
              this.dialogRef.close(true);
            },
            error: () => {
              this.spinnerSrv.loadingHide();
              this.snackBarSrv.showSnackBar(
                'Ha ocurrido un error al guardar los datos del contacto',
                'error'
              );
            },
          });
      }
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }
}
