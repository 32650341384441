import { NgModule } from '@angular/core';
import { ManagersAgreementsComponent } from '../pages/managers-agreements.component';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { ApiCore, ApiPoseedores, ApiDocum } from 'api-rest';
import { ManagersAgreementsSearchComponent } from '../pages/managers-agreements-search/managers-agreements-search.component';
import { EmpusaSpinnerComponent } from '@empusa/empusa-core';
import { GeTableComponent } from '../share/components/ge-table.component';
import { MaterialModule } from './material.module';
import { CustomDatePickerModule } from './custom-date-picker.module';
import { NgbPaginationModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { GetAttributePipe } from '../share/components/pipe/get-attribute.pipe';
import { GeSelectPaginationComponent } from '../share/components/ge-select-pagination/ge-select-pagination.component';
import { DecimalNumberDirective } from '../share/components/utils/DecimalNumberDirective.directive';
import { TwoDigitDecimalNumberDirective } from '../share/components/utils/TwoDigitDecimalNumberDirective.directive';
import { ManagersAgreementsContributionsDisComponent } from '../pages/managers-agreements-contributions-dis/managers-agreements-contributions-dis.component';
import { ManagersAgreementsContributionsDisDialogComponent } from '../pages/managers-agreements-contributions-dis-dialog/managers-agreements-contributions-dis-dialog.component';
import { ManagersAgreementsManagerSelectionDialogComponent } from '../pages/managers-agreements-manager-selection-dialog/managers-agreements-manager-selection-dialog.component';
import { ManagersAgreementsSelfInvoicesSearchComponent } from '../pages/managers-agreements-self-invoices-search/managers-agreements-self-invoices-search.component';
import { ManagersAgreementsInvoiceNewDocumentDialogComponent } from '../pages/managers-agreements-invoice-document-dialog/managers-agreements-invoice-document-dialog.component';
import { ManagersAgreementsContributionsDisDeclineDialogComponent } from '../pages/managers-agreements-contributions-dis-cancel-dialog/managers-agreements-contributions-dis-cancel-dialog.component';
import { ManagersAgreementsInvoiceRejectDialogComponent } from '../pages/managers-agreements-invoice-reject-dialog/managers-agreements-invoice-reject-dialog.component';

export const CoreConfigFactory = () =>
  new ApiCore.Configuration(<ApiCore.ConfigurationParameters>{});

export const CoreBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE CORE ' + environment.urlBackCore);
  const basePath = environment.urlBackCore;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};
export const AcuerdosConGestoresBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE ACUERDOS CON GESTORES ' + environment.urlBackAcuerdosConGestores);
  const basePath = environment.urlBackAcuerdosConGestores;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};
export const DocumBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE DOCUM ' + environment.urlBackDocum);
  const basePath = environment.urlBackDocum;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const DocumConfigFactory = () =>
  new ApiDocum.Configuration(<ApiDocum.ConfigurationParameters>{});

export const explorerRoutes = [
  {
    path: '',
    component: ManagersAgreementsComponent,
    children: [
      { path: 'search', component: ManagersAgreementsSearchComponent },
      { path: 'contributions-dis-search', component: ManagersAgreementsContributionsDisComponent },
      { path: 'invoices-search', component: ManagersAgreementsSelfInvoicesSearchComponent },
    ],
  },
];

@NgModule({
  declarations: [
    ManagersAgreementsComponent,
    ManagersAgreementsSearchComponent,
    GeTableComponent,
    GetAttributePipe,
    GeSelectPaginationComponent,
    ManagersAgreementsContributionsDisComponent,
    ManagersAgreementsContributionsDisDialogComponent,
    ManagersAgreementsManagerSelectionDialogComponent,
    ManagersAgreementsSelfInvoicesSearchComponent,
    ManagersAgreementsInvoiceNewDocumentDialogComponent,
    ManagersAgreementsContributionsDisDeclineDialogComponent,
    ManagersAgreementsInvoiceRejectDialogComponent,
    DecimalNumberDirective,
    TwoDigitDecimalNumberDirective
  ],
  imports: [
    NgbTooltipModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgSelectModule,
    CommonModule,
    ApiDocum.ApiModule.forRoot(DocumConfigFactory),
    RouterModule.forChild(explorerRoutes),

    MaterialModule,
    EmpusaSpinnerComponent,
    CustomDatePickerModule,
  ],
  exports: [ManagersAgreementsComponent],
  providers:[
    DatePipe,
    {
      provide: ApiPoseedores.BASE_PATH,
      useFactory: AcuerdosConGestoresBasePathFactory,
      deps: ['environment'],
    },
    {
      provide: ApiDocum.BASE_PATH,
      useFactory: DocumBasePathFactory,
      deps: ['environment'],
    },
  ]
})
export class ManagersAgreementsHomeModule {
  constructor() {
   let compilationVersion = ""
    console.log('managers-agreements version: ' + compilationVersion);
  }
}
