import { Attribute, Component, Inject, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SpinnerService } from '../../../services/spinner.service';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import {
  FacturaEstadoDTO,
  FacturaPoseedorControllerService,
  FacturaDTO,
  FacturaPoseedorEstadosControllerService,
  FacturaPoseedorFilterDTO,
  PageDTOFacturaDTO,
  PoseedorControllerService,
  PoseedorFilterDTO,
} from 'api-rest';
import { HoldersInvoiceNewDocumentDialogComponent } from '../holders-invoice-document-dialog/holders-invoice-document-dialog.component';
import { DocumentService } from '../../../services/document.service';
import moment from 'moment';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-holders-self-invoices-search',
  templateUrl: './holders-self-invoices-search.component.html',
  styleUrls: ['./holders-self-invoices-search.component.scss'],
})
export class HoldersSelfInvoicesSearchComponent implements OnInit {
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  totalElements: number = 0;
  totalSearch: number = 0;

  poseedorArray: any[] = [];
  estadoArray: any[] = [];

  headArray: any = [
    {
      Head: 'ID. Poseedor',
      FieldName: 'poseedor',
      Attribute: 'id',
    },
    {
      Head: 'Poseedor',
      FieldName: 'poseedor',
      Attribute: 'razonSocial',
    },
    {
      Head: 'Documento',
      FieldName: 'poseedor',
      Attribute: 'codigoDocumento',
    },
    {
      Head: 'NIMA del poseedor',
      FieldName: 'poseedor',
      Attribute: 'nima',
    },
    {
      Head: 'Número de autofactura',
      FieldName: 'id',
      renderValue: (item: FacturaDTO) =>
        item.fechaAutofactura !== null ? item.id : '',
    },
    {
      Head: 'Fecha de autofactura',
      FieldName: 'fechaMAutofactura',
    },
    {
      Head: 'Número factura poseedor ',
      FieldName: 'numeroFacturaPoseedor',
    },
    {
      Head: 'Fecha factura',
      FieldName: 'fechaMFactura',
    },
    {
      Head: 'Estado ',
      FieldName: 'estado',
      renderValue: (item: FacturaDTO) =>
        item.estado?.descripcion
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Eliminar registro',
          show: { params: 'canAccessDelete' },
        },
        {
          nameButton: 'add_invoice',
          icon: 'upload_file',
          toolTip: 'Adjuntar factura',
          show: { params: 'canAccessUpInvoice' },
        },
        {
          nameButton: 'menu',
          array: [
            {
              nameButton: 'donwload_invoice',
              name: 'Descargar factura',
              show: { params: 'canAccessDonwloadInvoice' },
            },
            {
              nameButton: 'donwload_self_invoice',
              name: 'Descargar autofactura',
              show: { params: 'canAccessDonwloadSelfInvoice' },
            },
            {
              nameButton: 'accept_invoice',
              name: 'Aceptar factura',
              show: { params: 'canAccessAcceptInvoice' },
            },
          ],
        },
      ],
      permanent: true,
    },
  ];
  gridArray: any[] = [];

  searchForm: FormGroup;

  constructor(
    @Inject('environment') private environment: any,
    @Inject('EmpusaAuthenticationService')
    public authService: EmpusaAuthenticationService | any,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private facturaPoseedorControllerService: FacturaPoseedorControllerService,
    private facturaPoseedorEstadosControllerService: FacturaPoseedorEstadosControllerService,
    private poseedorControllerService: PoseedorControllerService,
    private downloadService: DownloadService,
    private documentService: DocumentService,
    private snackBarSrv: SnackbarService,
    private datePipe: DatePipe
  ) {
    this.searchForm = this.formBuilder.group({
      poseedor: [{ value: null, disabled: false }],
      idposeedor: [{ value: null, disabled: false }, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      codigo_documento: [{ value: null, disabled: false }],
      razon_social: [{ value: null, disabled: false }],
      nima_poseedor: [{ value: null, disabled: false }],
      numero_autoFactura: [{ value: null, disabled: false }, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      fecha_autoFactura: [{ value: null, disabled: false }],
      numero_facturaPoseedor: [{ value: null, disabled: false }, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      fecha_facturaPoseedor: [{ value: null, disabled: false }],
      estado: [{ value: null, disabled: false }],
    });
  }

  ngOnInit() {
    this.comboEstados();
    this.getPoseedores();
  }

  checked(event: any) { }

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.searchData();
    }
  }

  exportarExcel() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.facturaPoseedorControllerService
      .exportCSV4(backendFilter, 'response')
      .subscribe((res) => {
        const filename = this.downloadService.getFileName(
          res.headers,
          'Exportacion_Factura_Poseedor.csv'
        );
        this.downloadService.downloadCSV(res.body!, filename!);
      });
  }

  clearSearcher() {
    this.searchForm.reset();
    this.flagSearch = false;
    this.gridArray = [];
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.searchForm.controls[controlName].hasError(errorName) &&
      this.searchForm.controls[controlName].touched
    );
  }

  getPoseedores() {
    if (this.authService.user?.roleEntities?.length > 0) {
      const poseedorIds = this.authService.user?.roleEntities
        .filter((poseedorRol: any) => poseedorRol.type === 'POSEEDOR')
        .map((poseedorRol: any) => poseedorRol.entity as number);
      const filter: PoseedorFilterDTO = {
        id: poseedorIds,
      };
      this.spinnerSrv.loadingShow();
      this.poseedorControllerService
        .findAll5(filter, { page: 0, size: 100 })
        .subscribe({
          next: (response: any) => {
            this.poseedorArray = response?.datos;
            this.spinnerSrv.loadingHide();
          },
          error: (err) => {
            console.log(err);
            this.spinnerSrv.loadingHide();
          },
        });
    }
  }

  private getBackendFilter() {
    let poseedorIds;
    if (
      this.authService.user?.roleEntities?.length > 0 &&
      this.canViewResource('PO-POSEEDOR')
    ) {
      poseedorIds = this.authService.user?.roleEntities.map(
        (poseedorRol: any) => poseedorRol.entity as number
      );
    }
    const {
      poseedor,
      idposeedor,
      codigo_documento,
      razon_social,
      nima_poseedor,
      numero_autoFactura,
      fecha_autoFactura,
      numero_facturaPoseedor,
      fecha_facturaPoseedor,
      estado,
    } = this.searchForm.controls;

    const formatDate = (date: any) => {
      if (date && date.value) {
        return moment(new Date(date.value)).format('DD/MM/YYYY');
      }
      return undefined;
    };

    const backendFilter: FacturaPoseedorFilterDTO = {
      poseedor: {
        id:
          idposeedor.value != null
            ? idposeedor.value
            : poseedor.value != null
              ? poseedor.value
              : poseedorIds,
        codigoDocumento: codigo_documento?.value,
        razonSocial:
          razon_social?.value != null ? [`ci(${razon_social?.value})`] : [],
        nima: nima_poseedor?.value,
      },
      numeroAutofactura: numero_autoFactura?.value,
      fechaAutofactura: formatDate(fecha_autoFactura),
      numeroFactura: numero_facturaPoseedor?.value,
      fechaFactura: formatDate(fecha_facturaPoseedor),
      estado: estado?.value,
      origen: 'POSEEDORES'
    };

    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    return { backendFilter, page, size: this.pageSize };
  }

  searchData() {
    if (this.searchForm.invalid) {
      this.searchForm.markAllAsTouched();
      return;
    }
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.facturaPoseedorControllerService
      .findAll10(backendFilter, {
        page: page,
        size: size,
        sort: ['id,desc'],
      })
      .subscribe({
        next: (data: PageDTOFacturaDTO) => {
          if (data) {
            this.spinnerSrv.loadingHide();
            this.flagSearch = data.datos?.length != 0 ? true : false;
            this.gridArray = this.canAccessAll(data.datos) ?? [];
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.totalSearch = this.gridArray.length;
          }
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  action(element: any) {
    if (element.nameButton == 'view') {
    } else if (element.nameButton == 'delete') {
      this.confirmacionEliminar(element);
    } else if (element.nameButton == 'add_invoice') {
      this.openModalUploadInvoice(element);
    } else if (element.nameButton == 'donwload_invoice') {
      this.descargar(element.documento);
    } else if (element.nameButton == 'donwload_self_invoice') {
      this.descargar(element.documentoAutofactura);
    } else if (element.nameButton == 'accept_invoice') {
      this.confirmacionCambioEstado(element.id, 3);
    }
  }

  confirmacionEliminar(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar la autofactura?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarInvoices(element);
      }
    });
  }

  eliminarInvoices(element?: any) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.facturaPoseedorControllerService.deleteById5(element.id).subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            `El registro de factura se ha eliminado correctamente`,
            'success'
          );
          this.gridArray = this.gridArray.filter(
            (objeto) => objeto.id !== element.id
          );
          this.flagSearch = this.gridArray.length != 0 ? true : false;
        },
        error: (err: { message: any }) => {
          this.spinnerSrv.loadingHide();
        },
      });
    }
  }

  comboEstados() {
    this.facturaPoseedorEstadosControllerService.findAll11().subscribe({
      next: (data: Array<FacturaEstadoDTO>) => {
        this.estadoArray = data!;
      },
      error: () => { },
    });
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.searchForm.controls[controlName]?.touched ||
        this.searchForm.controls[controlName]?.dirty) &&
      this.searchForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  openModalUploadInvoice(element?: any): void {
    const dialogRef = this.dialog.open(
      HoldersInvoiceNewDocumentDialogComponent,
      {
        width: '650px',
        maxWidth: '95%',
        maxHeight: '95vh',
        data: {
          itemOpen: element,
        },
        disableClose: true,
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.searchData();
      }
    });
  }

  descargar(documento: any) {
    this.spinnerSrv.loadingShow();
    this.documentService.descargarDocumento(documento.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Se ha descargado el documento correctamente',
          'success'
        );
        this.downloadService.downloadBlob(response, documento.nombre);
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  confirmacionCambioEstado(id?: any, estadoId?: any): void {
    Swal.fire({
      text: `¿Desea cambiar el estado del registro por Aceptada por Envalora?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.actualizarEstado(id, estadoId);
      }
    });
  }

  actualizarEstado(id?: any, estadoId?: any) {
    if (id && estadoId) {
      this.spinnerSrv.loadingShow();
      this.facturaPoseedorControllerService
        .actualizarEstado(id, estadoId)
        .subscribe({
          next: () => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              `El registro de factura se ha actualizado correctamente`,
              'success'
            );
            this.searchData();
          },
          error: (err: { message: any }) => {
            this.spinnerSrv.loadingHide();
          },
        });
    }
  }

  canViewResource(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

  canAccessExport(): boolean {
    return this.authService.can('poseedores-facturas', 'update-exportar');
  }

  canAccessAll(list: any) {
    const resultDelete = this.authService.can('poseedores-facturas', 'delete');
    const resultUpFactura = this.authService.can(
      'poseedores-facturas',
      'update'
    );
    const resultDownloadAutofactura = this.authService.can(
      'poseedores-facturas',
      'download-autofactura'
    );
    const resultDownloadFactura = this.authService.can(
      'poseedores-facturas',
      'download-factura'
    );
    const resultUpdateAccept = this.authService.can(
      'poseedores-facturas',
      'update-accept'
    );

    const listPermission = list.map((objeto: any) => {
      const estado1 = objeto.estado && objeto.estado.id === 1;
      const estado2 = objeto.estado && objeto.estado.id === 2;
      const estado3 = objeto.estado && objeto.estado.id === 3;

      let formattedDate =
        objeto.fechaFactura != null
          ? this.datePipe.transform(objeto.fechaFactura, 'dd/MM/yyyy')
          : null;
      objeto.fechaMFactura = formattedDate;

      let formattedDateM =
        objeto.fechaAutofactura != null
          ? this.datePipe.transform(objeto.fechaAutofactura, 'dd/MM/yyyy')
          : null;
      objeto.fechaMAutofactura = formattedDateM;
      return {
        ...objeto,
        canAccessDelete: resultDelete && estado1,
        canAccessUpInvoice: resultUpFactura && estado1,
        canAccessDonwloadInvoice: resultDownloadFactura && (estado2 || estado3),
        canAccessDonwloadSelfInvoice: resultDownloadAutofactura,
        canAccessAcceptInvoice: resultUpdateAccept && estado2,
      };
    });

    return listPermission;
  }

  onlyNumberKey(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Control'];

    if (event.ctrlKey && (event.key === 'c' || event.key === 'v')) return;

    // Permitir números y teclas adicionales definidas
    if (!/^[0-9]$/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
