<!-- Formulario -->
<form [formGroup]="datosGeneralesForm">
  <div class="row form">
    <div class="col-sm flex-grow-2">
      <label>Razón social <span class="oblig">*</span></label>
      <div class="custom-mat-field">
        <mat-form-field appearance="outline" class="custom-mat-form-field-width">
          <input formControlName="razonSocial" id="rsocial" placeholder="Razón Social" matInput type="text" maxlength="255">
          <mat-error *ngIf="controlHasError( 'razonSocial', 'maxlength')">Máximo 255 caracteres</mat-error>
          <mat-error *ngIf="controlHasError('razonSocial', 'required')">Campo obligatorio</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-sm flex-grow-1">
      <label>Denominación <span class="oblig">*</span></label>
      <div class="custom-mat-field">
        <mat-form-field appearance="outline" class="custom-mat-form-field-width">
          <input formControlName="denominacion" id="denominacion" placeholder="Denominación del centro" matInput
            type="text" maxlength="255">
          <mat-error *ngIf="controlHasError( 'denominacion', 'maxlength')">Máximo 255 caracteres</mat-error>
          <mat-error *ngIf="controlHasError('denominacion', 'required')">Campo obligatorio</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row form">
    <div class="col-sm flex-grow-1">
      <label for="tipodoc">Tipo documento <span class="oblig">*</span></label>
      <div class="custom-mat-field">
        <mat-form-field appearance="outline" class="custom-mat-form-field-width">
          <mat-select formControlName="tipoDocumento" (selectionChange)="tipoDocumentoSel($event.value)" id="tipodoc"
            placeholder="Tipo documento">
            <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.id">
              {{tipo.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="controlHasError('tipoDocumento', 'required')">Campo obligatorio</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-sm flex-grow-1">
      <label for="numdoc">Documento <span class="oblig">*</span></label>
      <div class="custom-mat-field">
        <mat-form-field appearance="outline" class="custom-mat-form-field-width">
          <input formControlName="codigoDocumento" id="numdoc" placeholder="Documento" matInput type="text" oninput="this.value = this.value.toUpperCase()" maxlength="15">
          <mat-error *ngIf="controlHasError( 'codigoDocumento', 'maxlength')">Máximo 15 caracteres</mat-error>
          <mat-error *ngIf="controlHasError('codigoDocumento', 'required')">Campo obligatorio</mat-error>
          <mat-error *ngIf="controlHasError('codigoDocumento', 'pattern')">Formato incorrecto</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-sm flex-grow-1">
      <label for="nimaPdr">NIMA PdR <span class="oblig">*</span></label>
      <div class="custom-mat-field">
        <mat-form-field class="custom-mat-form-field-width" appearance="outline">
          <input type="text" matInput formControlName="nimaPdr" id="nimaPdr" placeholder="NIMA PdR" value="" maxlength="10">
          <mat-error *ngIf="controlHasError( 'nimaPdr', 'maxlength')">Máximo 10 caracteres</mat-error>
          <mat-error *ngIf="controlHasError( 'nimaPdr', 'required')">Campo obligatorio</mat-error>
          <mat-error *ngIf="controlHasError('nimaPdr', 'pattern')">Formato incorrecto</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="col-sm flex-grow-1">
      <label for="nimaOt">NIMA OT </label>
      <div class="custom-mat-field">
        <mat-form-field class="custom-mat-form-field-width" appearance="outline">
          <input type="text" matInput formControlName="nimaOt" id="nimaOt" placeholder="NIMA OT" value="" maxlength="10">
          <mat-error *ngIf="controlHasError( 'nimaOt', 'maxlength')">Máximo 10 caracteres</mat-error>
          <mat-error *ngIf="controlHasError('nimaOt', 'pattern')">Formato incorrecto</mat-error>
        </mat-form-field>
      </div>
    </div>
  </div>

  <div class="row form">
    <div div class="col-sm flex-grow-1" style="max-width: 50%;">
      <label for="cnae1">CNAE <span class="oblig">*</span></label>
      <div class="custom-mat-field">
        <lib-ge-select-pagination class="lib-ge-select" [endPointFilter]="cnaeEndpoint" [updateList]="updateList"
          [descripcion]="getCnaDescription" [disabled]="flagDisabledForm"
          [formControlNameSelect]="getFormControl('cnae')" [objQueryName]="getCnaeObjectQuery" [placeHolder]="'CNAE'"
          [customClass]="rightHolder('cnae')">
        </lib-ge-select-pagination>
        <div class="ge-select-error">
          <mat-error class="ge-select-mat-error" *ngIf="selPaginatorHasError('cnae')">Campo obligatorio
          </mat-error>
        </div>
      </div>
    </div>
    <div class="col-sm flex-grow-1">
      <label>Nº de inscripción productor de residuos </label>
      <div class="custom-mat-field">
        <mat-form-field appearance="outline" class="custom-mat-form-field-width">
          <input formControlName="n_inscripResiduos" id="n_inscripResiduos"
            placeholder="Nº de Autorización Medioambiental" matInput type="text" maxlength="255">
          <mat-error *ngIf="controlHasError( 'n_inscripResiduos', 'maxlength')">Máximo 255 caracteres</mat-error>
        </mat-form-field>
      </div>
    </div>

  </div>
  <div class="row form">
    <div class="col-sm flex-grow-1">
      <label for="fechaComienzo">Fecha comienzo actividad </label>
      <mat-form-field appearance="outline">
        <input formControlName="fechaComienzo" id="fechaComienzo" matInput type="text">
      </mat-form-field>
    </div>

    <div class="col-sm flex-grow-1">
      <label for="fechaBajaEnvanet">Fecha baja Envanet</label>
      <mat-form-field appearance="outline">
        <input formControlName="fechaBajaEnvanet" id="fechaBajaEnvanet" matInput type="text">
      </mat-form-field>
    </div>

    <div class="col-sm flex-grow-1">
      <ng-container *ngIf="canAccessDate()">
        <label for="fechaBaja">Fecha de baja </label>
        <mat-form-field appearance="outline">
          <input matInput [matDatepicker]="datepicker" formControlName="fechaBaja">
          <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
          <mat-datepicker #datepicker>
          </mat-datepicker>
          <mat-error *ngIf="controlHasError('fechaBaja', 'matDatepickerParse')">Formato incorrecto</mat-error>
        </mat-form-field>
      </ng-container>
    </div>

  </div>

  <div class="row-fields">
    <div class="field field-100">
      <div style="display: flex; align-items: center;">
        <label for="autorizacionComuniaciones">Consiento recibir comunicaciones sobre otras actividades de terceros y de ENVALORA <span class="oblig">*</span></label>
        <div class="tool-help" matTooltip="La base jurídica para el tratamiento de los datos personales con finalidades de envío de comunicaciones es el consentimiento prestado por LA EMPRESA al confirmar esta afirmación. 
        Además, los datos personales de personas de contacto también podrán ser usados para enviarles comunicaciones sobre otras actividades de ENVALORA o de terceros colaboradores con el SCRAP por consentimiento prestado por LA EMPRESA mediante esta confirmación." data-placement="top" title="">
          <span>?</span>
        </div>
      </div>
      <mat-radio-group formControlName="autorizacionComuniaciones" id="autorizacionComuniaciones"
        class="custom-mat-form-radio">
        <mat-radio-button color="primary" [value]="true">Sí</mat-radio-button>
        <mat-radio-button color="primary" [value]="false">No</mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="controlHasError('autorizacionComuniaciones', 'required')">Campo obligatorio
      </mat-error>
    </div>
  </div>

</form>
