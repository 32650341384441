import { Injectable } from "@angular/core";
import { KeycloakUserDTO, UserRoleEntityDTO, TarifaAdheridoUnUsoDTO } from "api-rest";

export interface Combo {
    id: string | number,
    name: string
}

@Injectable({
    providedIn: 'root'
})
export class ComboService {


    getUserTypes(): Combo[] {
        const TipoUsuario = UserRoleEntityDTO.EntityTypeEnum;
        const comboOptions: Combo[] = Object.keys(TipoUsuario).map(key => ({
            id: TipoUsuario[key as keyof typeof TipoUsuario],
            name: key
        }));
       return comboOptions;
    }

    getComboTipologia(): Combo[] {
        const TipologiaEnum = TarifaAdheridoUnUsoDTO.TipologiaEnum;
        const comboOptions: Combo[] = Object.keys(TipologiaEnum).map(key => ({
            id: TipologiaEnum[key as keyof typeof TipologiaEnum],
            name: key
        }));
       return comboOptions;
    }

    getComboModulosPrefacturacion(): Combo[] {
        return [
            { id: 'POSEEDORES_APORTACIONES_DIS', name: 'Poseedores - Aportaciones DIS' },
            { id: 'SDDR_ABIERTO_APORTACIONES_DIS', name: 'SDDR Abierto - Aportaciones DIS' },
            { id: 'SDDR_ABIERTO_SOLICITUDES', name: 'SDDR Abierto - Solicitudes de reutilización / retirada' },
        ]
    }

    getDocumentRoles(): Combo[] {
        const comboOptions: Combo[] = [
            {id: 'AD_ADHERIDO', name: 'AD_ADHERIDO'},
            {id: 'ADM_COMUNICACIONES', name: 'ADM_COMUNICACIONES'},
            {id: 'AG_GESTOR', name: 'AG_GESTOR'},
            {id: 'AG_POSEEDOR', name: 'AG_POSEEDOR'},
            {id: 'DE_ADHERIDO', name: 'DE_ADHERIDO'},
            {id: 'PO_GESTOR', name: 'PO_GESTOR'},
            {id: 'PO_POSEEDOR', name: 'PO_POSEEDOR'},
            {id: 'PR_GESTOR', name: 'PR_GESTOR'},
            {id: 'PR_PUNTORECOGIDA', name: 'PR_PUNTORECOGIDA'},
            {id: 'SDDR_CC', name: 'SDDR_CC'},
            {id: 'SDDR_CA', name: 'SDDR_CA'},
            {id: 'PO_APORT_AUTOFACT', name: 'PO_APORT_AUTOFACT'},
            {id: 'AD_ENV_CONS', name: 'AD_ENV_CONS'},
            {id: 'AD_ENV_GEST', name: 'AD_ENV_GEST'},
            {id: 'AG_ENV_CONS', name: 'AG_ENV_CONS'},
            {id: 'AG_ENV_GEST', name: 'AG_ENV_GEST'},
            {id: 'DE_ENV_CONS', name: 'DE_ENV_CONS'},
            {id: 'DE_ENV_GEST', name: 'DE_ENV_GEST'},
            {id: 'PO_ENV_CONS', name: 'PO_ENV_CONS'},
            {id: 'PO_ENV_GEST', name: 'PO_ENV_GEST'},
            {id: 'PR_ENV_CONS', name: 'PR_ENV_CONS'},
            {id: 'PR_ENV_GEST', name: 'PR_ENV_GEST'},
            {id: 'SDDR_CA_ENV_CONS', name: 'SDDR_CA_ENV_CONS'},
            {id: 'SDDR_CA_ENV_GEST', name: 'SDDR_CA_ENV_GEST'},
            {id: 'SDDR_CC_ENV_CONS', name: 'SDDR_CC_ENV_CONS'},
            {id: 'SDDR_CC_ENV_GEST', name: 'SDDR_CC_ENV_GEST'},
        ];
        return comboOptions;
    }
}