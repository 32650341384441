import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,ValidationErrors, ValidatorFn
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Combo, Combo2 } from '../../../models/combo';
import {
  ContactoControllerService, AdheridoControllerService, TipoContactoControllerService,
  AdheridoModel, ContactoModel, TipoContactoModel,
} from 'api-rest';
import { ValidateService } from '../../../services/validate.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { CombosService } from '../../../services/combos.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import Swal from 'sweetalert2';

export interface MappedResponse<T> {
  totalElements: number;
  recordsFiltered: number;
  datos: T[];
}

@Component({
  selector: 'lib-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss'],
})
export class ContactDialogComponent implements OnInit {
  contactosEndpoint = this.environment.urlBackCore + 'api/v1/core/contactos';
  paisesEndpoint = this.environment.urlBackCore + 'api/v1/core/countries';
  contacto: any;
  titulo = '';
  adherido!: AdheridoModel;
  formGroup!: FormGroup;
  direccion: any;
  tiposDirecciones: Combo2[] = [];
  contactos: any[] = [];
  paises: any[] = [];
  provincias: any[] = [];
  codigosPostales: any[] = [];
  municipios: any[] = [];
  tiposVia: any[] = [];
  tiposContactos: any[] = [];
  updateList: boolean = false;
  flagUploadCopy: boolean = false;
  tiposDocumento: Combo[] = [];
  tiposTelefono: Combo[] = [];
  organos: Combo[] = [];
  personType: boolean | undefined = true;
  copiarContacto: ContactoModel[] | undefined = [];
  paisPrefijo!: any;
  flagFirmante: boolean = false;
  flagFacturacion: boolean = false;
  flagRadioNo: boolean = false;
  canEditContacto: boolean = true;

  constructor(
    public dialogRef: MatDialogRef<ContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private contactoControllerSrv: ContactoControllerService,
    private adheridoControllerSrv: AdheridoControllerService,
    private tipoContactoControllerSrv: TipoContactoControllerService,
    private validateSrv: ValidateService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    @Inject('environment') private environment: any,
    public comboSrv: CombosService,
    private snackBarSrv: SnackbarService,
    public spinnerSrv: SpinnerService,
  ) {
    this.contacto = this.data.contacto;
    this.adherido = this.data.adherido;
    this.formGroup = this.formBuilder.group({
      tipoContacto: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            Validators.required,
          ]),
        },
      ],
      copiaContacto: [{ value: null, disabled: false }],
      requerido: [{ value: null, disabled: false }],
      persona: [
        { value: false, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      nombre: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(50)]) },
      ],
      apellido1: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(50)]) },
      ],
      apellido2: [{ value: null, disabled: false },
        {validators: Validators.compose([Validators.maxLength(50)])}
      ],
      documentType: [{ value: null, disabled: false},
        {validators: Validators.compose([this.tipoDocumentoValidator()])}
      ],
      document: [
        {value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(15)])}
      ],
      tipoTelefono: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      prefijoPais: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      telefono: [
        { value: null, disabled: false },
        Validators.compose([Validators.required, this.telefonoValidator.bind(this),Validators.maxLength(15)])
      ],
      recibirComunicaciones: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      recibirComunicacionesContrato: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      acreditacionPoderes: [{ value: null, disabled: false }],
      email: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(this.validateSrv.emailPattern),
            Validators.maxLength(100)
          ]),
        },
      ],
      organoParticipa: [{ value: null, disabled: false }],
    });
  }

  ngOnInit(): void {
    if (this.contacto) {
      this.titulo = 'Editar contacto';
      this.cargarContactos(this.contacto, true);
    } else {
      this.titulo = 'Nuevo contacto';
      this.formGroup.get('persona')?.setValue(true);
    }
    this.getTiposContactos();
    this.tiposTelefono = this.comboSrv.getTiposTelefono();
    this.tiposDocumento = this.comboSrv.getTiposDocumentoIdentidad();
    this.organos = this.comboSrv.getOrganos();

    this.copiarContacto = this.adherido?.contactos?.filter(
      (objeto: any) => objeto.nombre !== null && objeto.nombre !== ''
    );

    if (!this.authService.can('adheridos-contactos', 'update'))
      this.formGroup.disable();

    this.formGroup.get('tipoTelefono')?.valueChanges.subscribe(() => {
      this.formGroup.get('telefono')?.updateValueAndValidity();
    });

    this.formGroup.get('prefijoPais')?.valueChanges.subscribe(() => {
      this.formGroup.get('telefono')?.updateValueAndValidity();
    });

    this.cargarFirmante();
    
    if(this.contacto){
      //Si el contacto esta obsoleto o el estado es mayor a Pdte Firma Contrato (3) no permitir editar los contactos de Firmante (3) o Rep Autorizado (4)
      if(this.contacto.obsoleto){
        this.formGroup.disable();
        this.canEditContacto = false;
      }else{
        if(this.adherido?.estado?.id && this.adherido?.estado?.id >= 3 && [3,4].includes(this.contacto.tipo_contacto.id)){
          this.formGroup.disable();
          this.canEditContacto = false;
        }
      }
    }
  }

  canAccessUpdate(): boolean {
    return this.authService.can('adheridos-contactos', 'update');
  }

  getTiposContactos(): void {
    this.spinnerSrv.loadingShow();
    this.tipoContactoControllerSrv
      .listarTiposContacto({ page: 0, size: 50 })
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          if (response?.content) {
            this.tiposContactos = response?.content;
          }
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al cargar los tipos de contactos.',
            'error'
          );
        },
      });
  }

  onTipoEntidadChange(): void {
    const persona = this.formGroup.get('persona')?.value;
    const documentType = this.formGroup.get('documentType')?.value;

    if (this.formGroup) {
      const nombreControl = this.formGroup.get('nombre');
      const apellido1Control = this.formGroup.get('apellido1');
      const apellido2Control = this.formGroup.get('apellido2');
      const documentTypeControl = this.formGroup.get('documentType');

      if (
        nombreControl &&
        apellido1Control &&
        apellido2Control &&
        documentTypeControl
      ) {
        // Restablecer la visibilidad
        nombreControl?.enable();
        apellido1Control?.enable();
        apellido2Control?.enable();
        documentTypeControl?.enable();
        // documentTypeControl?.reset();
        this.personType = persona;

        // Ocultar y cambiar validación según la opción seleccionada
        if (!persona) {
          // Empresa
          apellido1Control?.disable();
          apellido2Control?.disable();
          documentTypeControl?.setValue('NIF');
          documentTypeControl?.disable();
          this.updateDocumentValidators(persona);
        } else {
          this.tipoDocumentoSel(documentType);
        }
        nombreControl?.updateValueAndValidity();
        apellido1Control?.updateValueAndValidity();
        apellido2Control?.updateValueAndValidity();
      }
    }
  }

  cargarContactos(contacto: ContactoModel, editar: boolean): void {
    if (!contacto) return;
    const persona = this.formGroup.get('persona')?.value;
    this.personType = contacto.persona;
    this.formGroup.patchValue({
      persona: contacto?.persona,
      nombre: contacto?.nombre,
      apellido1: contacto?.apellido1,
      apellido2: contacto?.apellido2,
      documentType: contacto?.tipo_documento,
      document: contacto?.codigo_documento,
      tipoTelefono: contacto?.tipo_telefono,
      prefijoPais: contacto?.codigoPais?.prefijoNombre,
      telefono: contacto?.numero_telefono,
      recibirComunicaciones: contacto?.recibir_comunicaciones,
      recibirComunicacionesContrato: contacto?.recibir_comunicaciones_contrato,
      acreditacionPoderes: contacto?.acreditacion_poderes,
      organoParticipa: contacto?.organo_participa,
      email: contacto?.email,
    });

    this.paisPrefijo = contacto?.codigoPais;
    // Si no es edición no se cargan requerido ni tipo de contacto
    if (editar) {
      this.formGroup.get('requerido')?.setValue(contacto?.requerido);
      this.formGroup.get('tipoContacto')?.setValue(contacto?.tipo_contacto?.id);
    }
    this.validacionCargaDatos(contacto);
  }

  validacionCargaDatos(contacto: ContactoModel) {
    this.formGroup.get('documentType')?.clearValidators();
    this.formGroup.get('document')?.clearValidators();
    if (contacto?.requerido && contacto?.tipo_contacto?.id !== 5) {
      this.formGroup.get('tipoContacto')?.disable();
    }

    if(contacto?.tipo_contacto?.id === 4 && contacto?.requerido) {
      this.formGroup.get('copiaContacto')?.disable();
      this.formGroup.get('persona')?.disable();
    }

    if (
      contacto?.tipo_contacto?.id === 3 ||
      contacto?.tipo_contacto?.id === 4
    ) {
      // Si tipo contacto firmante o reperesentante autorizado tipo documento y documento, son obligatorios
      this.formGroup.get('documentType')?.setValidators([Validators.required, this.tipoDocumentoValidator()]);
      this.formGroup.get('document')?.setValidators([Validators.required]);
    } else {
      // en cualquier otro caso, no serán obligatorios
      this.formGroup.get('documentType')?.clearValidators();
      this.formGroup.get('documentType')?.setValidators([this.tipoDocumentoValidator()]);
      this.formGroup.get('document')?.clearValidators();
    }

    if (contacto?.tipo_documento === 'NIF') {
      if (
        this.formGroup.get('tipoContacto')?.value === 3 ||
        this.formGroup.get('tipoContacto')?.value === 4
      ) {
        this.formGroup
          .get('document')
          ?.setValidators([
            Validators.required,
            Validators.pattern(this.validateSrv.nifPattern),
          ]);
      } else {
        this.formGroup
          .get('document')
          ?.setValidators([Validators.pattern(this.validateSrv.nifPattern)]);
      }
    }
    if (contacto?.tipo_documento === 'DNI') {
      if (
        this.formGroup.get('tipoContacto')?.value === 3 ||
        this.formGroup.get('tipoContacto')?.value === 4
      ) {
        this.formGroup
          .get('document')
          ?.setValidators([
            Validators.required,
            Validators.pattern(this.validateSrv.dniPattern),
          ]);
      } else {
        this.formGroup
          .get('document')
          ?.setValidators([Validators.pattern(this.validateSrv.dniPattern)]);
      }
    }
    if (contacto?.tipo_documento === 'NIE') {
      if (
        this.formGroup.get('tipoContacto')?.value === 3 ||
        this.formGroup.get('tipoContacto')?.value === 4
      ) {
        this.formGroup
          .get('document')
          ?.setValidators([
            Validators.required,
            Validators.pattern(this.validateSrv.niePattern),
          ]);
      } else {
        this.formGroup
          .get('document')
          ?.setValidators([Validators.pattern(this.validateSrv.niePattern)]);
      }
    }

    if ([1, 2, 5].includes(this.formGroup.get('tipoContacto')?.value)) {
      this.flagFacturacion = true;
      this.flagRadioNo = false;
      this.formGroup.get('acreditacionPoderes')?.clearValidators();
      this.formGroup.get('acreditacionPoderes')?.updateValueAndValidity();
    }

    if ([3, 4].includes(this.formGroup.get('tipoContacto')?.value)) {
      this.flagRadioNo = true;
      this.flagFirmante = true;
      this.formGroup
        .get('acreditacionPoderes')
        ?.setValidators([Validators.required]);
      this.formGroup.get('acreditacionPoderes')?.updateValueAndValidity();

      if (!this.formGroup.get('acreditacionPoderes')?.value) {
        this.formGroup.get('acreditacionPoderes')?.reset();
      }
    }

    if (!this.formGroup.get('persona')?.value) {
      this.formGroup.get('apellido1')?.disable();
    }

    if (this.formGroup.get('tipoContacto')?.value === 3) {
      this.formGroup.get('documentType')?.setValidators([Validators.required, this.tipoDocumentoValidator()]);
      this.formGroup.get('document')?.setValidators([Validators.required]);
    }

    this.formGroup.get('documentType')?.updateValueAndValidity();
    this.formGroup.get('document')?.updateValueAndValidity();

    this.formGroup.get('telefono')?.updateValueAndValidity();

    this.formGroup.markAllAsTouched();

    this.onTipoEntidadChange();
  }

  cargarFirmante() {
    this.formGroup.get('tipoContacto')?.valueChanges.subscribe((value: any) => {
      if ([3, 4].includes(value)) {
        this.flagFirmante = true;
        this.formGroup
          .get('acreditacionPoderes')
          ?.setValidators([Validators.required]);
        this.formGroup.get('acreditacionPoderes')?.updateValueAndValidity();
      } else {
        this.flagFirmante = false;
        this.formGroup.get('acreditacionPoderes')?.clearValidators();
        this.formGroup.get('acreditacionPoderes')?.updateValueAndValidity();
      }
      if ([1, 2].includes(value)) {
        this.flagFacturacion = true;
      } else {
        this.flagFacturacion = false;
      }

      if (![3, 4].includes(value)) {
        this.flagRadioNo = false;
      } else {
        this.flagRadioNo = true;
        if (!this.formGroup.get('acreditacionPoderes')?.value) {
          this.formGroup.get('acreditacionPoderes')?.reset();
        }
      }
    });
  }

  comprobarGuardar(){
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.valid) {
      if ((this.contacto == null || this.contacto == undefined) 
        && (this.formGroup.get('tipoContacto')?.value == 3 || this.formGroup.get('tipoContacto')?.value == 4)) {
        //Solo puede hacer un contacto activo de tipo 3 (Firmante) o uno por Persona/Empresa de tipo 4 (Representante autorizado)
        let existeContacto: boolean | undefined = false;
        if(this.formGroup.get('tipoContacto')?.value == 3){
          existeContacto = this.adherido?.contactos?.some((pContacto:any) => !pContacto.obsoleto && pContacto.tipo_contacto.id == this.formGroup.get('tipoContacto')?.value);
        }else{
          existeContacto = this.adherido?.contactos?.some((pContacto:any) => !pContacto.obsoleto && pContacto.tipo_contacto.id == this.formGroup.get('tipoContacto')?.value && pContacto.persona == this.formGroup.get('persona')?.value);
        }
        if(existeContacto){
          Swal.fire({
            text: `No está permitida la creación de otro contacto de tipo Firmante o Representante autorizado como activo puesto que ya existe uno. ¿Quiere marcar el contacto existente como obsoleto?`,
            icon: 'question',
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: 'Sí',
            denyButtonText: 'No',
            allowOutsideClick: false,
          }).then((result: any) => {
            if (result.isConfirmed) {
              this.guardar(true);
            }
          });
        }else{
          this.guardar(false);
        }
      
      }else{
        this.guardar(false);
      }
    }else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  guardar(marcarExistenteObsoleto:boolean) {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.valid) {
      let tipoContacto = this.tiposContactos.find(
        (x) => x.id === this.formGroup.get('tipoContacto')?.value
      );

      const editContacto: ContactoModel = {
        id: this.contacto?.id,
        tipo_contacto: tipoContacto,
        requerido: this.formGroup.get('requerido')?.value,
        persona: this.formGroup.get('persona')?.value,
        nombre: this.formGroup.get('nombre')?.value,
        apellido1: this.formGroup.get('persona')?.value ? this.formGroup.get('apellido1')?.value : null,
        apellido2: this.formGroup.get('persona')?.value ? this.formGroup.get('apellido2')?.value : null,
        tipo_documento: this.formGroup.get('documentType')?.value,
        codigo_documento: this.formGroup.get('document')?.value,
        tipo_telefono: this.formGroup.get('tipoTelefono')?.value,
        numero_telefono: this.formGroup.get('telefono')?.value,
        email: this.formGroup.get('email')?.value,
        recibir_comunicaciones: this.formGroup.get('recibirComunicaciones')
          ?.value,
        recibir_comunicaciones_contrato: this.formGroup.get(
          'recibirComunicacionesContrato'
        )?.value,
        organo_participa: this.formGroup.get('organoParticipa')?.value,
        acreditacion_poderes: this.formGroup.get('acreditacionPoderes')?.value,
        obsoleto: false
      };
      // Si es un string es porque no he modificado el valor que me ha llegado del servicio
      let prefijo = this.formGroup.get('prefijoPais')?.value;
      if (typeof prefijo === 'string') {
        editContacto.codigoPais = this.paisPrefijo;
      } else {
        editContacto.codigoPais = this.formGroup.get('prefijoPais')?.value;
      }

      if (this.contacto) {
        this.contactoControllerSrv
          .actualizarContacto(this.contacto.id!, editContacto)
          .subscribe({
            next: () => this.handleSuccess(this.contacto),
            error: () => this.handleError(),
          });
      } else {
        if(marcarExistenteObsoleto){
          let contactoExistente: any;
          if(this.formGroup.get('tipoContacto')?.value == 3){
            contactoExistente = this.adherido?.contactos?.filter((pContacto:any) => !pContacto.obsoleto && pContacto.tipo_contacto.id == this.formGroup.get('tipoContacto')?.value)[0];
          }else{
            contactoExistente = this.adherido?.contactos?.filter((pContacto:any) => !pContacto.obsoleto && pContacto.tipo_contacto.id == this.formGroup.get('tipoContacto')?.value && pContacto.persona == this.formGroup.get('persona')?.value)[0];
          }
          if(contactoExistente){
            //marcar como obsoleto
            contactoExistente.obsoleto = true;
          }
        }
        this.adherido?.contactos?.push(editContacto);
        this.adheridoControllerSrv
          .actualizarAdherido(this.adherido.id!, this.adherido)
          .subscribe({
            next: () => this.handleSuccess(editContacto),
            error: () => this.handleError(),
          });
      }
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  cpSeleccionado(idCp: number) {
    const cpSeleccionado = this.codigosPostales.find((x) => x.id === idCp);
    this.formGroup.get('municipio')?.setValue(cpSeleccionado?.municipio);
  }
  cerrar(): void {
    this.dialogRef.close(null);
  }
  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }

  selectedModel(selectForm: any, event:any) {
    switch (selectForm) {
      case 'copiaContacto': {
        this.cargarContactos(
          this.formGroup.controls['copiaContacto']?.value,
          false
        );
        break;
      }
      case 'prefijoPais': {
        let prefijo = this.formGroup.get('prefijoPais')?.value;
        if (typeof prefijo === 'string') {
          this.paisPrefijo = event;
        } else {
          this.paisPrefijo = this.formGroup.controls['prefijoPais']?.value;
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  telefonoValidator(control: AbstractControl): { [key: string]: any } | null {
    const tipoTelefono = this.formGroup?.get('tipoTelefono')?.value;
    const prefijoPais = this.formGroup?.get('prefijoPais')?.value;

    const phonePattern =
      /^(\+?\d{1,3})?[-. (]?(\d{1,4})[-. )]?(\d{1,4})[-. ]?(\d{1,4})[-. ]?(\d{1,9})$/;
    const isGeneralValid = phonePattern.test(control.value);

    if (!isGeneralValid) {
      return { invalidPhoneFormat: true };
    }
    
    if (
      prefijoPais?.prefijo == '34' ||
      (typeof prefijoPais === 'string' && prefijoPais?.toUpperCase() == '34 (ESPAÑA)')
    ) {
      if (tipoTelefono === 'FIJO') {
        const valid = /^[89][0-9]{8}$/.test(control.value);
        return valid ? null : { invalidTelefonoFijo: true };
      } else if (tipoTelefono === 'MOVIL') {
        const valid = /^[67][0-9]{8}$/.test(control.value);
        return valid ? null : { invalidTelefonoMovil: true };
      }
    }

    return null;
  }

  tipoDocumentoValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      
      if(control.value){
        if(this.formGroup?.get('persona')?.value){
          if(control.value == 'DNI' || control.value == 'NIE'){
            return null; //es valido
          }else{
            return { invalidTipoDocumento: true };
          }
        }
      }
      
      return null; //es valido
    };
  }

  filterList(data: any, selectForm: any) {
    switch (selectForm) {
      case 'copiaContacto': {
        return this.formGroup.get('copiaContacto') as FormControl;
        break;
      }
      case 'prefijoPais': {
        return this.formGroup.get('prefijoPais') as FormControl;
        break;
      }
      default: {
        return this.formGroup.get('') as FormControl;
        break;
      }
    }
  }
  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'copiaContacto': {
        return this.formGroup.get('copiaContacto') as FormControl;
        break;
      }
      case 'prefijoPais': {
        return this.formGroup.get('prefijoPais') as FormControl;
        break;
      }
      default: {
        return this.formGroup.get('') as FormControl;
        break;
      }
    }
  }
  defaultMapResponse<T>(response: any): MappedResponse<T> {
    return <MappedResponse<T>>{
      totalElements: response.totalElements,
      recordsFiltered: response.totalPages,
      datos: response.content,
    };
  }
  tipoDocumentoSel(idTipoDoc: string) {
    this.formGroup.get('document')?.clearValidators();
    if (idTipoDoc === 'NIF') {
      if (
        this.formGroup.get('tipoContacto')?.value == 3 ||
        this.formGroup.get('tipoContacto')?.value == 4
      ) {
        this.formGroup
          .get('document')
          ?.setValidators([
            Validators.required,
            Validators.pattern(this.validateSrv.nifPattern),
          ]);
      } else {
        this.formGroup
          .get('document')
          ?.setValidators([Validators.pattern(this.validateSrv.nifPattern)]);
      }
    } else if (idTipoDoc === 'DNI') {
      if (
        this.formGroup.get('tipoContacto')?.value == 3 ||
        this.formGroup.get('tipoContacto')?.value == 4
      ) {
        this.formGroup
          .get('document')
          ?.setValidators([
            Validators.required,
            Validators.pattern(this.validateSrv.dniPattern),
          ]);
      } else {
        this.formGroup
          .get('document')
          ?.setValidators([Validators.pattern(this.validateSrv.dniPattern)]);
      }
    } else if (idTipoDoc === 'NIE') {
      if (
        this.formGroup.get('tipoContacto')?.value == 3 ||
        this.formGroup.get('tipoContacto')?.value == 4
      ) {
        this.formGroup
          .get('document')
          ?.setValidators([
            Validators.required,
            Validators.pattern(this.validateSrv.niePattern),
          ]);
      } else {
        this.formGroup
          .get('document')
          ?.setValidators([Validators.pattern(this.validateSrv.niePattern)]);
      }
    }

    this.formGroup.get('document')?.updateValueAndValidity();
  }

  tipoContactoSel(idTipoContacto?: number | null) {
    if (idTipoContacto === 3 || idTipoContacto === 4) {
      // Si tipo contacto firmante o reperesentante autorizado tipo documento y documento, son obligatorios
      this.formGroup.get('documentType')?.setValidators([Validators.required, this.tipoDocumentoValidator()]);
      this.formGroup.get('document')?.setValidators([Validators.required]);
    } else {
      // en cualquier otro caso, no serán obligatorios
      this.formGroup.get('documentType')?.clearValidators();
      this.formGroup.get('documentType')?.setValidators([this.tipoDocumentoValidator()]);
      this.formGroup.get('document')?.clearValidators();
    }
    this.formGroup.get('documentType')?.updateValueAndValidity();
    this.formGroup.get('document')?.updateValueAndValidity();

    if ([1, 2, 5].includes(this.formGroup.get('tipoContacto')?.value)) {
      this.flagFacturacion = true;
      this.formGroup.get('acreditacionPoderes')?.clearValidators();
      this.formGroup.get('acreditacionPoderes')?.updateValueAndValidity();
    } else {
      this.flagFacturacion = false;
      this.formGroup
        .get('acreditacionPoderes')
        ?.setValidators([Validators.required]);
      this.formGroup.get('acreditacionPoderes')?.updateValueAndValidity();
      this.formGroup.get('acreditacionPoderes')?.markAllAsTouched();
    }
  }
  controlHasErrorSelect(controlName: string) {
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }
  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }
  public handleSuccess(contacto: ContactoModel) {
    const message =
      contacto.tipo_contacto?.id == 1
        ? 'Los cambios de datos de contactos no afectan a los usuarios de acceso a la plataforma. Si se requiere dar de baja a usuarios de su entidad o dar de alta nuevos, debe ponerse en contacto con el departamento de administración de Envalora'
        : 'Datos de contacto guardados correctamente';
    this.snackBarSrv.showSnackBar(message, 'success');
    this.dialogRef.close(true);
  }

  public handleError() {
    this.snackBarSrv.showSnackBar(
      'Ha ocurrido un error al guardar los datos del contacto',
      'error'
    );
  }

  updateDocumentValidators(persona: any) {
    const domicilioSocial = this.adherido?.domicilios?.find(
      (domicilio: any) => domicilio.tipoDireccion?.id === 1
    );

    let pais: any = domicilioSocial?.pais;

    if (!persona && pais?.id === 69) {
      this.tipoDocumentoSel(this.formGroup.get('documentType')?.value);
    } else {
      this.formGroup.get('document')?.clearValidators();
      this.formGroup.get('document')?.updateValueAndValidity();
    }
  }

  showClearButton(formName: string): boolean {
    return this.formGroup.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.formGroup.get(formName)?.setValue(null);
  }

  mostrarEtiquetaContacto(){
    //Mostrar si el Tipo Documento del adherido es NIF y empieza por N
    //Y el contacto es de tipo Firmante o Representante autorizado
    //Y es Persona
    if ( this.formGroup.get('tipoContacto')?.value === 3 || this.formGroup.get('tipoContacto')?.value === 4 ) {
      if (this.adherido?.tipoDocumento === 'NIF' && this.adherido?.codigoDocumento?.toUpperCase().startsWith('N') 
        && this.formGroup.get('persona')?.value == true) {
        return true;
      }
    } 
    return false;
  }

}
