/**
 * SDRR-Abierto API
 * SDRR-Abierto API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UsuarioSDDRCaDTO } from './usuarioSDDRCaDTO';
import { SubTipoEnvaseDTO } from './subTipoEnvaseDTO';
import { IdEntityDTO } from './idEntityDTO';
import { PoseedorFilterDTO } from './poseedorFilterDTO';
import { SolicitudReutilizacionEstadoDTO } from './solicitudReutilizacionEstadoDTO';
import { AdheridoFilterDTO } from './adheridoFilterDTO';


export interface SolicitudReutilizacionFilterDTO { 
    id?: number;
    tipoUsuario?: SolicitudReutilizacionFilterDTO.TipoUsuarioEnum;
    idUsuario?: string;
    usuarioSDDRCA?: UsuarioSDDRCaDTO;
    tipoEnvase?: IdEntityDTO;
    subTipoEnvase?: SubTipoEnvaseDTO;
    loteEnvase?: string;
    usoEnvase?: SolicitudReutilizacionFilterDTO.UsoEnvaseEnum;
    fechaSolicitud?: Array<string>;
    estado?: SolicitudReutilizacionEstadoDTO;
    autoFacturada?: boolean;
    poseedor?: PoseedorFilterDTO;
    adherido?: AdheridoFilterDTO;
    certificadoPago?: boolean;
    autorizacionEnvalora?: boolean;
}
export namespace SolicitudReutilizacionFilterDTO {
    export type TipoUsuarioEnum = 'ADHERIDO' | 'POSEEDOR';
    export const TipoUsuarioEnum = {
        Adherido: 'ADHERIDO' as TipoUsuarioEnum,
        Poseedor: 'POSEEDOR' as TipoUsuarioEnum
    };
    export type UsoEnvaseEnum = 'REUTILIZACION_EN_ESPAÑA' | 'REUTILIZACION_EXPORTACION' | 'ENVASADO_DE_RESIDUOS';
    export const UsoEnvaseEnum = {
        ReutilizacionEnEspaa: 'REUTILIZACION_EN_ESPAÑA' as UsoEnvaseEnum,
        ReutilizacionExportacion: 'REUTILIZACION_EXPORTACION' as UsoEnvaseEnum,
        EnvasadoDeResiduos: 'ENVASADO_DE_RESIDUOS' as UsoEnvaseEnum
    };
}


