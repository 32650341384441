import { Component, OnInit } from '@angular/core';
import { SnackbarService } from '../services/snackbar.service';
import { SpinnerService } from '../services/spinner.service';
import { DownloadService } from '../services/download.service';
import { DocumentoInteresControllerService, DocumentoInteresModel } from 'api-rest';


@Component({
  selector: 'lib-initial-page',
  templateUrl: './initial-page.component.html',
  styleUrls: ['./initial-page.component.scss'],
})
export class InitialPageComponent implements OnInit {

  documentos: DocumentoInteresModel[] = [];

  constructor(
    private documentoInteresCtrlSrv: DocumentoInteresControllerService,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private downloadSrv: DownloadService
  ) { }


  ngOnInit(): void {
    this.cargarDocumentos();
  }

  cargarDocumentos(): void {
    this.spinnerSrv.loadingShow();
    this.documentoInteresCtrlSrv.findAll1()
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.documentos = response;
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al consultar los documentos',
            'error'
          );
        },
      });
  }

  descargarDocumento(documento: DocumentoInteresModel): void {
    let byteString: any = documento.documento;
    let fileType = documento.contentType ?? 'application/octet-stream';
    let blob = this.downloadSrv.b64toBlob(byteString, fileType)
    this.downloadSrv.downloadBlob(blob, documento.filename!);
  }

  abrirEnlace(url: string): void {
    window.open(url, '_blank');
  }

}
