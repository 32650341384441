  <!-- MIGAS DE PAN Y TÍTULO -->
  <empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
  <div class="bg-second-migas">
    <div class="container mt-cab-fixed">
      <div class="pre-content">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">Administración</li>
            <li class="breadcrumb-item">Tarifas Poseedores</li>
          </ol>
        </nav>
        <div class="content-title">
          <h2 class="title-pag">Búsqueda de Tarifas de Poseedores</h2>
          <button type="button" (click)="openModalOwner()" class="btn btn-primary" data-toggle="modal"><span
              class="icon-add"></span>Nueva
            Tarifa</button>
        </div>
      </div>
    </div>
  </div>
  <!-- FIN MIGAS DE PAN Y TÍTULO -->
  <!-- CONTENIDO -->

  <div class="container-form">
    <!-- Formulario de búsqueda -->
    <form class="container form-search py-3" [formGroup]="searchForm">
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="year">Año </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input matInput formControlName="year" id="year" placeholder="Año" type="number" [min]="0">
            <mat-error *ngIf="searchForm.get('year')?.invalid && searchForm.get('year')?.touched">Año inválido (1900
              a 2500)
            </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-2">
          <label for="codigoLer">Código LER y descripción de ENVALORA </label>
          <div class="custom-mat-field">
            <lib-ge-select-pagination class="lib-ge-select" [endPointFilter]="codigosLerEndpoint"
                                      [defaultFilter]=defaultFilter [descripcion]="getLerDescription"
                                      [formControlNameSelect]="getFormControl('codigoLer')" [objQueryName]="getObjectQuery"
                                      [placeHolder]="'-- Código LER y descripción de ENVALORA --'" [customClass]="rightHolder('codigoLer')">
            </lib-ge-select-pagination>
          </div>
        </div>
      </div>
      <div class="button-group">
        <button type="button" class="btn btn-outline-primary" (click)="clearSearcher()">Limpiar</button>
        <button type="button" class="btn btn-primary" (click)="searchData()">Buscar</button>
      </div>
    </form>
    <!-- FIN Formulario de búsqueda -->
  </div>
  <div class="bg-second-table-info pt-3 pb-5">
    <div *ngIf="flagSearch; else messageClear" class="container">
      <div class="actions-table">
        <div class=""><span class="align-middle tit-table">{{ recordsTotal }} registros encontrados</span></div>
        <div class="text-right">
          <div class="d-inline">

            <div class="d-inline dropdown show">
              <a (click)="exportarExcel()" class="btn btn-outline-primary" role="button"
                aria-haspopup="true" aria-expanded="false">
                <span class="mr-3">Exportar</span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="card-tabla-proy">
        <div class="card-body">
          <div class="datatable-wrapper datatable-loading no-footer sortable searchable fixed-columns">
            <div class="datatable-container table-responsive">
              <!-- TABLA Listado con resultados de búsqueda -->
              <lib-ge-table [headArray]="headArray" [gridArray]="gridArray" (checked)="checked($event)"
                (actionButton)="action($event)"></lib-ge-table>
              <div class="d-flex pb-2 flex-row justify-content-center align-items-center">
                <div class="d-flex mt-2">
                  <ngb-pagination [pageSize]="pageSize" [collectionSize]="recordsTotal" [(page)]="pageNumber"
                    (pageChange)="renderPage($event)" [maxSize]="5" aria-label="Default pagination" size="sm">
                  </ngb-pagination>
                </div>

                <div class="mt-2 selectPage">
                  <label for="itemsPerPage">Mostrar:</label>
                  <select id="itemsPerPage" [(ngModel)]="selectedItemsPerPage" (change)="onItemsPerPageChange()">
                    <option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}</option>
                  </select>
                </div>
              </div>
              <!-- FIN TABLA Listado con resultados de búsqueda -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #messageClear>
      <div class="mensaje-container">
        <p class="mensaje-linea">No hay resultados de búsqueda, por favor seleccione, al menos, un criterio en el
          formulario,</p>
        <p class="mensaje-linea">o cree un nuevo registro desde el botón "Nueva tarifa"</p>
      </div>
    </ng-template>
  </div>
