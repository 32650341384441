import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {EmpusaAuthenticationService} from '@empusa/empusa-core';
import {SnackbarService} from '../../../services/snackbar.service';
import {SpinnerService} from '../../../services/spinner.service';
import {ComboService} from '../../../services/combo.service';
import {
  SolicitudEnvaseControllerService,
  SolicitudEnvaseEstadoControllerService,
  SolicitudEnvaseEstadoDTO,
  SolicitudEnvaseFilterDTO,
  SolicitudRetiradaFilterDTO,
  SubTipoEnvaseControllerService,
  SubTipoEnvaseDTO,
  SubTipoEnvaseFilterDTO,
  TipoEnvasesControllerService,
  TipoEnvasesDTO
} from 'api-rest';
import {zip} from 'rxjs';
import moment from 'moment';
import {DatePipe} from '@angular/common';
import {OpenSddrService} from '../../../services/open-sddr.service';
import {DownloadService} from '../../../services/download.service';
import Swal from 'sweetalert2';
import { OpenSddrRemainDialogComponent } from '../open-sddr-remain-dialog/open-sddr-remain-dialog.component';

@Component({
  selector: 'open-sddr-open-sddr-remain-search',
  templateUrl: './open-sddr-remain-search.component.html',
  styleUrls: ['./open-sddr-remain-search.component.scss']
})
export class OpenSddrRemainSearchComponent implements OnInit {

  searchForm!: FormGroup;
  tiposEnvases!: TipoEnvasesDTO[];
  subtiposEnvases!: SubTipoEnvaseDTO[];
  estados!: SolicitudEnvaseEstadoDTO[];
  gestores!: any;
  gestoresEndpoint = this.environment.urlBackCore + 'api/v1/poseedores/gestores-residuos/completados';

  flagSearch: boolean = false;
  pageNumber: number = 0;
  pageSize: number = 10;
  totalElements: number = 0;
  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  totalSearch: any = 0;
  ocultarBuscador: boolean = true;
  defaultFilter: boolean = true;


  headArray: any = [
    {
      Head: 'ID',
      FieldName: 'id'
    },
    {
      Head: 'Fecha solicitud',
      FieldName: 'fechaSolicitud',
      renderValue: (item: any) => this.datePipe.transform(item.fechaSolicitud, 'dd/MM/yyyy')
    },
    {
      Head: 'Gest/Acondic.',
      FieldName: 'solicitudRetirada',
      renderValue: (item: any) => {
        return item?.solicitudRetirada?.gestorAcondicionador?.razonSocial;
      }
    },
    {
      Head: 'Provincia',
      FieldName: 'solicitudRetirada',
      renderValue: (item: any) => {
        const domicilio = item?.solicitudRetirada?.gestorAcondicionador?.domicilios?.find((dom: any) => dom.tipoDireccion.descripcion === "Física");
        return domicilio?.provincia?.nombre || '';
      }
    },
    {
      Head: 'ID. Retirada',
      FieldName: 'solicitudRetirada',
      Attribute: 'id'
    },
    {
      Head: 'Tipo de envase',
      FieldName: 'solicitudRetirada',
      renderValue: (item: any) => {
        return item?.solicitudRetirada?.tipoEnvase?.descripcion;
      }
    },
    {
      Head: 'Subtipo de envase',
      FieldName: 'solicitudRetirada',
      renderValue: (item: any) => {
        return item?.solicitudRetirada?.subTipoEnvase?.descripcion;
      }
    },
    {
      Head: 'Lote envase',
      FieldName: 'solicitudRetirada',
      renderValue: (item: any) => {
        return item?.solicitudRetirada?.loteEnvase;
      }
    },
    {
      Head: 'Cantidad aptos (ud)',
      FieldName: 'solicitudRetirada',
      renderValue: (item: any) => {
        return item?.solicitudRetirada?.envasesAptosUd;
      }
    },
    {
      Head: 'Unidades SDDR CA',
      FieldName: 'unidades'
    },
    {
      Head: 'Estado',
      FieldName: 'estado',
      Attribute: 'descripcion'
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Detalle',
          show: {params: 'canAccessView', accion: 'view'},
        },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: {params: 'canAccessDelete', accion: 'delete'},
        }, {
          nameButton: 'menu',
          array: [
            {
              nameButton: 'sendRequest',
              name: 'Enviar solicitud ',
              show: {params: 'canAccessSendReq', accion: 'sendRequest'},
            },
            {
              nameButton: 'rejectRequest',
              name: 'Rechazar solicitud',
              show: {params: 'canAccessRejectReq', accion: 'rejectRequest'},
            }
          ],
        }
      ],
      width: '10.2',
      permanent: true,
    },
  ];
  gridArray: any[] = [];

  constructor(
    @Inject('environment') private environment: any,
    public spinnerSrv: SpinnerService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private snackBarSrv: SnackbarService,
    @Inject('EmpusaAuthenticationService') private authService: EmpusaAuthenticationService,
    private comboSrv: ComboService,
    private tipoEnvasesCtrSrv: TipoEnvasesControllerService,
    private solicitudEnvaseCtrSrv: SolicitudEnvaseControllerService,
    private subtipoEnvaseCtrSrv: SubTipoEnvaseControllerService,
    private solicitudEnvaseEstadoCtrSrv: SolicitudEnvaseEstadoControllerService,
    private datePipe: DatePipe,
    private openSddrSrv: OpenSddrService,
    private downloadSrv: DownloadService) {
    this.searchForm = this.formBuilder.group({
      gestorAcondicionador: [{value: null, disabled: false}],
      idRetirada: [{value: null, disabled: false}, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      tipoEnvase: [{value: null, disabled: false}],
      subtipoEnvase: [{value: null, disabled: false}],
      loteEnvase: [{value: null, disabled: false}],
      fechaDesde: [{value: null, disabled: false}],
      fechaHasta: [{value: null, disabled: false}],
      estado: [{value: null, disabled: false}],
    });
  }

  ngOnInit(): void {
    this.cargarCombos();
    this.headArray = !this.canViewResource('SDDR-CA-ENV-GEST') || !this.canViewResource('SDDR-CA-ENV-CONS')
      ? this.headArray
      : this.headArray.filter(
        (head: any) =>
          head.FieldName !== 'provincia'
      );
    this.search(true);
  }

  cargarCombos(): void {
    const tipoEnvaseFilter = {sddrca: true};
    const subtipoEnvaseFilter: SubTipoEnvaseFilterDTO = {tipoEnvase: {sddrca: true}};
    this.spinnerSrv.loadingShow();
    this.tipoEnvasesCtrSrv.findAll(tipoEnvaseFilter, {page: 0, size: 100}).subscribe({

    })
    zip(
      this.tipoEnvasesCtrSrv.findAll(tipoEnvaseFilter, {page: 0, size: 100}),
      this.subtipoEnvaseCtrSrv.listarSubTipoEnvases(subtipoEnvaseFilter, {page: 0, size: 100}),
      this.solicitudEnvaseEstadoCtrSrv.findAll3()
    ).subscribe({
      next: (responses: any[]) => {
        this.spinnerSrv.loadingHide();
        this.tiposEnvases = responses[0].datos;
        this.subtiposEnvases = responses[1].datos;
        this.estados = responses[2];
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.searchForm.controls[controlName]?.hasError(errorName) &&
      this.searchForm.controls[controlName]?.touched
    );
  }

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.search();
    }
  }

  action(element: any) {
    if (element.nameButton == 'view') {
      this.openModalDetailRequest(element);
    } else if (element.nameButton == 'delete') {
      this.confirmDelete(element);
    } else if (element.nameButton == 'sendRequest') {
      this.confirmSend(element);
    } else if (element.nameButton == 'rejectRequest') {
      this.confirmReject(element);
    }
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.search();
  }

  limpiar(): void {
    this.searchForm.reset();
    this.flagSearch = false;
    this.gridArray = [];
  }

  openModalDetailRequest(solicitud?: any): void {

    const dialogRef = this.dialog.open(
      OpenSddrRemainDialogComponent,
      {
        width: '1460px',
        maxWidth: '95%',
        maxHeight: '95vh',
        data: {
          solicitudId: solicitud?.id,
          soloLectura: !(this.authService.can('sddr-ca-solicitudes-envase-detalle', 'edit') && (solicitud?.estado?.id === 1 || !solicitud?.id))
        },
        disableClose: true,
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.search();
      }
    });
  }

  confirmDelete(element?: any): void {
    Swal.fire({
      text: '¿Desea eliminar la solicitud de envase SDDR CA?',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.deleteRequest(element?.id!);
        }
      });
  }

  confirmReject(element?: any): void {
    Swal.fire({
      text: '¿Desea rechazar la solicitud de envase SDDR CA?',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.rejectRequest(element?.id!);
        }
      });
  }

  confirmSend(element?: any): void {
    Swal.fire({
      text: '¿Desea enviar la solicitud de envase SDDR CA?',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.sendRequest(element?.id!);
        }
      });
  }

  deleteRequest(id: number): void {
    this.spinnerSrv.loadingShow();
    this.solicitudEnvaseCtrSrv.deleteById(id)
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha eliminado la solicitud de envase SDDR CA',
            'success'
          );
          this.search();
        },
        error: (err) => {
          console.log(err);
          this.spinnerSrv.loadingHide();
        },
      });
  }

  rejectRequest(id: number): void {
    this.spinnerSrv.loadingShow();
    this.solicitudEnvaseCtrSrv.rechazarSolicitud2(id)
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha rechazado la solicitud de envase SDDR CA correctamente',
            'success'
          );
          this.search();
        },
        error: (err) => {
          console.log(err);
          this.spinnerSrv.loadingHide();
        },
      });
  }

  sendRequest(id: number): void {
    this.spinnerSrv.loadingShow();
    this.solicitudEnvaseCtrSrv.enviarSolicitud2(id)
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha enviado la solicitud de envase SDDR CA correctamente',
            'success'
          );
          this.search();
        },
        error: (err) => {
          console.log(err);
          this.spinnerSrv.loadingHide();
        },
      });
  }

  search(init?: boolean): void {
    let filtro: SolicitudEnvaseFilterDTO = this.getBackendFilter();
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;
    this.spinnerSrv.loadingShow();
    this.openSddrSrv.buscarSolicitudesEnvase(filtro, {
      page: page,
      size: this.pageSize,
      sort: ['id,desc']
    }).subscribe({
      next: (data: any) => {
        this.spinnerSrv.loadingHide();
        if (data) {
          this.flagSearch = data.datos?.length != 0;
          this.ocultarBuscador = (this.canViewResource('PO-GESTOR') && (!this.flagSearch && init == true));
          this.gridArray = this.canAccessAll(data.datos);
          this.totalElements = data.paginacion?.totalElements ?? 0;
          this.totalSearch = this.gridArray.length;
        }
      },
      error: (error: any) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al buscar las solicitudes de envase SDDR CA',
          'error'
        );
      },
    });
  }

  canAccessAll(list: any) {
    const resultView = this.authService.can('sddr-ca-solicitudes-envase', 'view');
    const resultDelete = this.authService.can('sddr-ca-solicitudes-envase', 'update-delete');
    const resultSendRequest = this.authService.can('sddr-ca-solicitudes-envase', 'update-send');
    const resultRejectRequest = this.authService.can('sddr-ca-solicitudes-envase', 'update-reject');

    const listPermission = list.map((objeto: any) => {
      const userDelete = objeto.creadoPor == this.authService.getCurrentUserMail() && this.canViewResource('PO-GESTOR');
      const isGest = this.canViewResource('SDDR-CA-ENV-GEST');

      return {
        ...objeto,
        canAccessView: resultView,
        canAccessDelete: (resultDelete && isGest && objeto.estado.id === 1) || (resultDelete && userDelete && objeto?.estado?.id === 1),
        canAccessSendReq: resultSendRequest && objeto.creadoPor == this.authService.getCurrentUserMail() && objeto?.estado?.id === 1, // PO-GESTOR (sólo podrán enviar aquellas solicitudes que estén dadas de alta por el mismo)
        canAccessRejectReq: resultRejectRequest && objeto?.estado?.id === 2
      };
    });

    return listPermission;
  }

  exportarCsv(): void {
    let filter = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.solicitudEnvaseCtrSrv.exportCSV3(filter)
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.downloadSrv.downloadBlob(response, 'solicitudesEnvase_SDDR_CA.csv');
        },
        error: (err: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al exportar las solicitudes de envase SDDR CA.',
            'error'
          );
        },
      });
  }

  getBackendFilter() {
    let fechaBusqueda: Array<string> = [];
    let fechaDesde = this.searchForm.get('fechaDesde')?.value;
    let fechaHasta = this.searchForm.get('fechaHasta')?.value;

    if (fechaDesde) {
      let formatDateDesde = moment(new Date(fechaDesde)).format('YYYY-MM-DD');
      formatDateDesde = `gte(${formatDateDesde})`;
      fechaBusqueda.push(formatDateDesde);
    }
    if (fechaHasta) {
      let formatDateHasta = moment(
        new Date(this.searchForm.get('fechaHasta')?.value)
      ).format('YYYY-MM-DD');
      if (fechaDesde) {
        formatDateHasta = `and(lte(${formatDateHasta}))`;
      } else {
        formatDateHasta = `lte(${formatDateHasta})`;
      }
      fechaBusqueda.push(formatDateHasta);
    }
    let solicitudRetiradaFilter: SolicitudRetiradaFilterDTO = {
      gestorAcondicionador: {id: this.searchForm.get('gestorAcondicionador')?.value?.id},
      id: this.searchForm.get('idRetirada')?.value ? this.searchForm.get('idRetirada')?.value : undefined,
      tipoEnvase: {id: this.searchForm.get('tipoEnvase')?.value},
      subTipoEnvase: {id: this.searchForm.get('subtipoEnvase')?.value},
      loteEnvase: this.searchForm.get('loteEnvase')?.value ? this.searchForm.get('loteEnvase')?.value : undefined,
    }


    const backendFilter: SolicitudEnvaseFilterDTO = {
      solicitudRetirada: solicitudRetiradaFilter,
      estado: {id: this.searchForm.get('estado')?.value},
      fechaSolicitud: fechaBusqueda
    };
    return backendFilter;
  }

  canViewResource(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

  canAccessGestor(): boolean {
    return this.canViewResource('PO-GESTOR');
  }

  canAccessEnvalora(): boolean {
    return this.canViewResource('SDDR-CA-ENV-GEST') || this.canViewResource('SDDR-CA-ENV-CONS');
  }

  canAccessCrearSolicitud(): boolean {
    return this.authService.can('sddr-ca-solicitudes-envase', 'create');
  }

  canAccessExportar(): boolean {
    return this.authService.can('sddr-ca-solicitudes-envase', 'update-exportar');
  }

  /*canAccessCrearAutofactura(): boolean {
    return this.authService.can('sddr-ca-solicitudes-envase', 'create-autofactura');
  }*/

  getGestorDescription(gestor: any) {
    return gestor?.razonSocial;
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'gestorAcondicionador': {
        return this.searchForm.get('gestorAcondicionador') as FormControl;
      }
      default: {
        return this.searchForm.get('') as FormControl;
      }
    }
  }

  getObjectQuery(searchterm: string) {
    return {
      descripcion: searchterm
    };
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if ((this.searchForm.controls[controlName]?.touched || this.searchForm.controls[controlName]?.dirty)
      && this.searchForm.controls[controlName]?.errors) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  onlyNumberKey(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Control'];

    if (event.ctrlKey && (event.key === 'c' || event.key === 'v')) return;

    // Permitir números y teclas adicionales definidas
    if (!/^[0-9]$/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }

}
