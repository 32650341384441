<div class="col-content">
  <div class="header-content mb-xl-5 mb-3">
    <div class="heading">{{adheridoInfo?.razonSocial}}</div>
    <div class="actions">
      <button *ngIf="canAccessNuevoCentroEnvasados()" type="button" class="btn btn-primary"
        (click)="openModalPackaging()" data-toggle="modal">Nuevo centro
        envasado</button>
      <!--TODO mat drop down menu
      <div class="dropdown show" *ngIf="canAccessExportarCentro()">
        <a class="btn btn-outline-primary" href="#" role="button"
          aria-haspopup="true" aria-expanded="false"><span>Exportar</span></a>
      </div> -->
    </div>
  </div>
  <div *ngIf="gridArray?.length !== 0 else messageClear" class="datatable-container table-responsive overflow-table">
    <lib-ge-table [headArray]="headArray" [gridArray]="gridArray" (actionButton)="action($event)" [sortByClient]="true"></lib-ge-table>
  </div>
  <ng-template #messageClear>
    <div class="container">
      <div class="mensaje-container">
        <p class="mensaje-linea">Sin información registrada</p>
      </div>
    </div>
  </ng-template>
</div>
