import { NgModule } from '@angular/core';
import { CommonModule, DatePipe, formatCurrency } from '@angular/common';
import { CollectionPointsComponent } from '../pages/collection-points.component';
import { RouterModule } from '@angular/router';
import { ApiCore, ApiPoseedores, ApiDocum } from 'api-rest';
import {
  NgbPaginationModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { MaterialModule } from './material.module';
import { EmpusaSpinnerComponent } from '@empusa/empusa-core';
import { CustomDatePickerModule } from './custom-date-picker.module';
import { GeTableComponent } from '../share/components/ge-table.component';
import { GetAttributePipe } from '../share/components/pipe/get-attribute.pipe';
import { GeSelectPaginationComponent } from '../share/components/ge-select-pagination/ge-select-pagination.component';
import { CollectionPointsSearchComponent } from '../pages/components-collection-points/collection-points-search/collection-points-search.component';
import { CollectionPointsDetailComponent } from '../pages/components-collection-points/collection-points-detail/collection-points-detail.component';
import { CollectionPointsPreliminaryComponent } from '../pages/components-collection-points/collection-points-preliminary/collection-points-preliminary.component';
import { CollectionPointsManagersSearchComponent } from '../pages/components-collection-points-managers/collection-points-managers-search/collection-points-managers-search.component';
import { CollectionPointsManagersPreliminaryComponent } from '../pages/components-collection-points-managers/collection-points-managers-preliminary/collection-points-managers-preliminary.component';
import { CollectionPointsManagersDetailComponent } from '../pages/components-collection-points-managers/collection-points-managers-detail/collection-points-managers-detail.component';
import { CollectionPointsAssociatesManagerComponent } from '../pages/components-collection-points/collection-points-associates-manager/collection-points-associates-manager.component';
import { CollectionPointsCodeLerManagerComponent } from '../pages/components-collection-points/collection-points-codeLer-manager/collection-points-codeLer-manager.component';
import { CollectionPointsContactDataComponent } from '../pages/components-collection-points/collection-points-contact-data/collection-points-contact-data.component';
import { CollectionPointsDocumentsComponent } from '../pages/components-collection-points/collection-points-documents/collection-points-documents.component';
import { CollectionPointsGeneralDataComponent } from '../pages/components-collection-points/collection-points-general-data/collection-points-general-data.component';
import { CollectionPointsAddressDataComponent } from '../pages/components-collection-points/collection-points-address-data/collection-points-address-data.component';
import { CollectionPointsContactDialogComponent } from '../pages/components-collection-points/collection-points-contact-dialog/collection-points-contact-dialog.component';
import { CollectionPointsAddressDialogComponent } from '../pages/components-collection-points/collection-points-address-dialog/collection-points-address-dialog.component';
import { CollectionPointsDocumentsDialogComponent } from '../pages/components-collection-points/collection-points-documents-dialog/collection-points-documents-dialog.component';
import { CollectionPointsManagersGeneralDataComponent } from '../pages/components-collection-points-managers/collection-points-managers-general-data/collection-points-managers-general-data.component';
import { CollectionPointsManagersDataComponent } from '../pages/components-collection-points-managers/collection-points-managers-data/collection-points-managers-data.component';
import { CollectionPointsManagersContactDialogComponent } from '../pages/components-collection-points-managers/collection-points-managers-contact-dialog/collection-points-managers-contact-dialog.component';
import { CollectionPointsManagersContactDataComponent } from '../pages/components-collection-points-managers/collection-points-managers-contact-data/collection-points-managers-contact-data.component';
import { CollectionPointsManagersCodeLerDataComponent } from '../pages/components-collection-points-managers/collection-points-managers-codeLer-data/collection-points-managers-codeLer-data.component';
import { CollectionPointsManagersAddressDialogComponent } from '../pages/components-collection-points-managers/collection-points-managers-address-dialog/collection-points-managers-address-dialog.component';
import { CollectionPointsManagersAddressDataComponent } from '../pages/components-collection-points-managers/collection-points-managers-address-data/collection-points-managers-address-data.component';
import { CollectionPointsManagersDocumentsComponent } from '../pages/components-collection-points-managers/collection-points-managers-documents/collection-points-managers-documents.component';
import { CollectionPointsManagersDocumentsDialogComponent } from '../pages/components-collection-points-managers/collection-points-managers-documents-dialog/collection-points-managers-documents-dialog.component';
import { CollectionPointsRequestsSearchComponent } from '../pages/components-collection-points-requests/collection-points-requests-search/collection-points-requests-search.component';
import { CollectionPointsRequestsDetailComponent } from '../pages/components-collection-points-requests/collection-points-requests-detail/collection-points-requests-detail.component';
import { CollectionPointsRequestsRegistrationComponent } from '../pages/components-collection-points-requests/collection-points-requests-registration/collection-points-requests-registration.component';
import { CollectionPointsSelectManagerDownComponent } from '../pages/components-collection-points-managers/collection-points-select-manager-down/collection-points-select-manager-down.component';
import { TwoDigitDecimalNumberDirective } from '../share/components/utils/TwoDigitDecimalNumberDirective.directive';
import { FormatCurrencyPipe } from '../share/components/pipe/FormatCurrency.pipe';
import { ParseCurrencyPipe } from '../share/components/pipe/ParseCurrency.pipe';
import { NumericPointDirective } from '../share/components/utils/NumericPoint.directive';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { CollectionPointsRequestsDownDialogComponent } from '../pages/components-collection-points-requests/collection-points-requests-down-dialog/collection-points-requests-down-dialog.component';
import { CollectionPointsRequestsDeclineDialogComponent } from '../pages/components-collection-points-requests/collection-points-requests-decline-dialog/collection-points-requests-decline-dialog.component';
import { CPInvoiceNewDocumentDialogComponent } from '../pages/components-collection-points/collection-points-invoice-document-dialog/cp-invoice-document-dialog.component';
import { CPSelfInvoicesSearchComponent } from '../pages/components-collection-points/collection-points-self-invoices-search/cp-self-invoices-search.component';
import { CPInvoiceRejectDialogComponent } from '../pages/components-collection-points/collection-points-invoice-reject-dialog/collection-points-invoice-reject-dialog.component';

export const CoreConfigFactory = () =>
  new ApiCore.Configuration(<ApiCore.ConfigurationParameters>{});

export const CoreBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE CORE ' + environment.urlBackCore);
  const basePath = environment.urlBackCore;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const DocumConfigFactory = () =>
  new ApiDocum.Configuration(<ApiDocum.ConfigurationParameters>{});

export const DocumBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE DOCUM ' + environment.urlBackDocum);
  const basePath = environment.urlBackDocum;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const explorerRoutes = [
  {
    path: '',
    component: CollectionPointsComponent,
    children: [
      { path: 'search', component: CollectionPointsSearchComponent },
      { path: 'detail/:id', component: CollectionPointsDetailComponent },
      {
        path: 'manager-search',
        component: CollectionPointsManagersSearchComponent,
      },
      {
        path: 'manager-detail/:id',
        component: CollectionPointsManagersDetailComponent,
      },
      {
        path: 'requests-search',
        component: CollectionPointsRequestsSearchComponent,
      },
      {
        path: 'cp-invoices-search',
        component: CPSelfInvoicesSearchComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [
    CollectionPointsComponent,
    GeTableComponent,
    GetAttributePipe,
    GeSelectPaginationComponent,

    CollectionPointsSearchComponent,
    CollectionPointsDetailComponent,
    CollectionPointsPreliminaryComponent,
    CollectionPointsAssociatesManagerComponent,
    CollectionPointsCodeLerManagerComponent,
    CollectionPointsContactDataComponent,
    CollectionPointsDocumentsComponent,
    CollectionPointsGeneralDataComponent,
    CollectionPointsAddressDataComponent,
    CollectionPointsContactDialogComponent,
    CollectionPointsAddressDialogComponent,
    CollectionPointsDocumentsDialogComponent,

    CollectionPointsManagersSearchComponent,
    CollectionPointsManagersPreliminaryComponent,
    CollectionPointsManagersDetailComponent,
    CollectionPointsManagersGeneralDataComponent,
    CollectionPointsManagersDataComponent,
    CollectionPointsManagersContactDialogComponent,
    CollectionPointsManagersContactDataComponent,
    CollectionPointsManagersCodeLerDataComponent,
    CollectionPointsManagersAddressDialogComponent,
    CollectionPointsManagersAddressDataComponent,
    CollectionPointsManagersDocumentsComponent,
    CollectionPointsManagersDocumentsDialogComponent,

    CollectionPointsRequestsSearchComponent,
    CollectionPointsRequestsDetailComponent,
    CollectionPointsRequestsRegistrationComponent,
    CollectionPointsSelectManagerDownComponent,
    CollectionPointsRequestsDownDialogComponent,
    CollectionPointsRequestsDeclineDialogComponent,

    CPInvoiceNewDocumentDialogComponent,
    CPSelfInvoicesSearchComponent,
    CPInvoiceRejectDialogComponent,
    TwoDigitDecimalNumberDirective,
    NumericPointDirective,
    FormatCurrencyPipe,
    ParseCurrencyPipe,
  ],
  imports: [
    NgbTooltipModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgSelectModule,
    CommonModule,
    ApiDocum.ApiModule.forRoot(DocumConfigFactory),
    RouterModule.forChild(explorerRoutes),

    MaterialModule,
    EmpusaSpinnerComponent,
    CustomDatePickerModule,
    NgxMatSelectSearchModule,
  ],
  exports: [CollectionPointsComponent],
  providers: [
    DatePipe,
    {
      provide: ApiDocum.BASE_PATH,
      useFactory: DocumBasePathFactory,
      deps: ['environment'],
    },
  ],
})
export class CollectionPointsHomeModule {
  constructor() {
    let compilationVersion = '0.0.1-R->04/10/23-12:14:49';
    console.log('Home version:' + compilationVersion);
  }
}
