/*
  THIS MODULE IS SEPARATED TO INJECT 'dinamically' EXTERNAL MICRO APPS FOR EMPUSA
  ········································································································
  ATTENTION: DON'T COMMIT CHANGES TO THIS FILE!
  The reason for this is you can use this file to add here your testing modules over the empusa meta-app, but
  those aren't real dependencies for the framework.

  In case you need to use this file to add testing/debugging dependencies, prevent the modification using the following in your local:
      · this command will ignore file modifications, use this once in your local repo and git will never track changes for the file
      $ git update-index --skip-worktree macro-app/empusa-app/src/app/external.module.ts

      · in case you need to push changes (THIS CASE IS ONLY FOR THE FRAMEWORK RESPONSIBLE), recover the original status doing this:
      $ git update-index --no-skip-worktree macro-app/empusa-app/src/app/external.module.ts

  echo fs.inotify.max_user_watches=524288 | sudo tee -a /etc/sysctl.conf && sudo sysctl -p

*/

import { NgModule } from '@angular/core';
import { MicroappHomeModule } from 'microapp-home';
import { KeycloakAuthManagerModule } from 'keycloak-auth-manager';
import { HelloWorldModule } from 'hello-world';
import { AttachedAppModule } from 'attached-app';
import { AdministrationModule } from 'administration';
import { CollectionPointsModule } from 'collection-points';
import { HoldersModule } from 'holders';
import { ManagersModule } from 'managers';
import { PackagingReuseModule } from 'packaging-reuse';
import { PackagingWasteManagementModule } from 'packaging-waste-management';
import { InitialPageModule } from 'initial-page';
import { ComunicationsModule } from 'comunications';
import { ManagersAgreementsModule } from 'managers-agreements';
import { OpenSddrModule } from 'open-sddr';

@NgModule({
  imports: [
    MicroappHomeModule,
    KeycloakAuthManagerModule,
    HelloWorldModule,
    AttachedAppModule,
    AdministrationModule,
    CollectionPointsModule,
    HoldersModule,
    ManagersModule,
    PackagingReuseModule,
    PackagingWasteManagementModule,
    InitialPageModule,
    ComunicationsModule,
    ManagersAgreementsModule,
    OpenSddrModule
    
  ],
  exports: [], providers: []
})
export class ExternalEmpusaModule { }
