<!-- Modal Alta Preliminar-->
<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="modal-content">

  <div class="modal-header">
    <h3 class="modal-title font">{{titulo}}</h3>
    <button type="button" (click)="closeDialog()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
    <!-- Formulario Alta preliminar -->
    <form class="form-modal py-3" [formGroup]="modelForm">
      <div class="d-flex content-title">
        <div class="d-flex">
          <h2 class="title-pag">Datos </h2>
          <div class="custom-container" [ngStyle]="{'background-color': '#8edbfff5'}">
            <div>
              <label style="font-size: larger; padding-top: 2px;">
                <mat-icon class="show-icon" [ngStyle]="{'color': '#004a9a'}">{{'warning_amber'}}</mat-icon>
              </label>
            </div>
            <div>
              <label class="info-texto-estado" [ngStyle]="{'color': '#004a9a'}">
                {{this.aportacion?.estado?.descripcion}}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-2">
          <label for="documentoIdentificacion">Documento Identificación (DI) <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input matInput formControlName="documentoIdentificacion" id="documentoIdentificacion"
                placeholder="Documento Identificación (DI)" type="text" maxlength="255">
              <mat-error *ngIf="controlHasError( 'documentoIdentificacion', 'maxlength')">Máximo 255 caracteres</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <div style="display: flex; align-items: center;">
            <label for="fechaAceptacion">Fecha de aceptación <span class="oblig">*</span></label>
            <div class="tool-help" matTooltip="Fecha de aceptación del residuo por parte del gestor (en documento DI)"
              data-placement="top" title="">
              <span>i</span>
            </div>
          </div>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-form-field">
              <input matInput [matDatepicker]="datepicker" formControlName="fechaAceptacion">
              <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
              <mat-datepicker #datepicker>
              </mat-datepicker>
              <mat-error *ngIf="controlHasError('fechaAceptacion', 'matDatepickerParse')">Formato incorrecto</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1" *ngIf="false">
          <label for="estado">Estado </label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <mat-select formControlName="estado" id="estado" placeholder="-- Estado --">
                <mat-option *ngFor="let des of estadoArray" [value]="des" [disabled]="this.edicion">
                  {{ des.descripcion }}
                </mat-option>
              </mat-select>
              <span *ngIf="showClearButton('estado') && !this.edicion" tabindex="0" class="ng-clear-wrapper"
                (click)="clearSelection($event,'estado')">
                <span aria-hidden="true" class="ng-clear">×</span>
              </span>
            </mat-form-field>
          </div>
        </div>
      </div>
        <div class="row form">
            <div class="col-sm flex-grow-1">
                <label for="nimaGestor">NIMA del gestor <span class="oblig">*</span></label>
                <div class="custom-mat-field">
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <mat-select formControlName="nimaGestor" id="nimaGestor" placeholder="-- NIMA del gestor --">
                            <mat-option *ngFor="let des of nimaGestorArray" [value]="des"
                                        [disabled]="this.isGestor || !canAccessEditEstado() || !canViewResource('PO-GESTOR')">
                                {{ des.nima }} - {{ des.razonSocial }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row form">

          <div class="col-sm flex-grow-1">
            <div style="display: flex; align-items: center;">
              <label for="idSolicitud">ID. Solicitud Retirada <span class="oblig">*</span></label>
              <div class="tool-help"
                   matTooltip="Haga clic en la lupa para cargar la solicitud de retirada con el identificador proporcionado"
                   data-placement="top" title="">
                <span>i</span>
              </div>
            </div>
            <div class="custom-mat-field">
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input matInput formControlName="idSolicitud" id="idSolicitud"
                       type="text" maxlength="10"
                       placeholder="Pulse en la lupa para buscar"
                       (keydown)="onlyNumberKey($event)">
                <mat-error *ngIf="controlHasError( 'idSolicitud', 'maxlength')">Máximo 10 caracteres
                </mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="col-sm flex-grow-0" *ngIf="this.canSaveAll()" style="padding-top: 3px;">
            <button type="button" class="btn btn-outline-primary" data-dismiss="modal"
                    (click)="buscarSolicitudRetirada()" aria-label="buscar por Identificador"
                    style="margin-top:25px"><span>
                            <mat-icon>search</mat-icon>
                        </span></button>
          </div>
          <div class="col-sm flex-grow-1">
            <div style="display: flex; align-items: center;">
              <label for="usuarioSddrCa">Poseedor / Adherido <span class="oblig">*</span></label>
              <div class="tool-help"
                   matTooltip="El Poseedor / Adherido se cargará automáticamente al introducir el ID de la Solicitud de Retirada, si está asignada a alguno de sus gestores"
                   data-placement="top" title="">
                <span>i</span>
              </div>
            </div>
            <div class="custom-mat-field">
              <mat-form-field class="custom-mat-form-field" appearance="outline">
                <input matInput formControlName="usuarioSddrCa" id="usuarioSddrCa"
                       placeholder="Poseedor / Adherido" type="text" readonly>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="row form">
        <div class="col-sm flex-grow-1">
          <div style="display: flex; align-items: center;">
            <label for="codigoLer">Código LER y descripción de ENVALORA <span class="oblig">* </span><em
                *ngIf="modelForm.get('codigoLer')?.value?.peligrosidad === true" class="fas fa-skull-crossbones"
                style="color: red;" matTooltip="Envase de tipo peligroso"></em></label>
          </div>
          <div class="custom-mat-form-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <mat-select formControlName="codigoLer" id="codigoLer" placeholder="Código LER y descripción de ENVALORA">
                <mat-option *ngFor="let cod of codigoLerArray" [value]="cod"
                  [disabled]="!canAccessEditEstado() || !canViewResource('PO-GESTOR')">
                  {{ cod.tipoCodigo }} {{ cod.descripcion }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controlHasError('codigoLer', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <div style="display: flex; align-items: center;">
            <label for="operacionGestion">Operación de gestión específica <span class="oblig">*</span></label>
            <div class="tool-help"
              matTooltip="Puede comprobar la operación de gestión en el DI facilitado por su gestor. En caso de duda, consulte con su gestor sobre la operación de gestión específica"
              data-placement="top" title="">
              <span>i</span>
            </div>
          </div>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <mat-select formControlName="operacionGestion" id="operacionGestion"
                placeholder="-- Operación de gestión específica  --">
                <mat-option *ngFor="let des of gestionArray" [value]="des">
                  {{ des.codigo }} {{ des.descripcion }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="cantidadGestionada">Cantidad neta gestionada (Kg) <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input matInput formControlName="cantidadGestionada" id="cantidadGestionada"
                placeholder="Cantidad neta gestionada (Kg)" matInput type="number" [min]="0" DirectDecimalNumber>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-1">
          <label for="documento">Documento <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <div class="attach">
              <div *ngIf="canSaveAll()">
                <input #file type="file" (change)="file && file.files && attachAdjunto(file.files[0])" class="hide"
                  id="adjuntar" name="adjuntar">
                <mat-icon (click)="file.click()" class="ml-2 color-custom pointer-cursor"
                  matTooltip="Adjuntar documento">upload_file</mat-icon>
              </div>
              <div>
                <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="documento != undefined"
                  class="centrar archivo">
                  <div><span class="sprite-icon ico-adjuntar"></span><span class="nombre-archivo">{{documento.name}}
                    </span>
                  </div>
                </div>
                <div fxLayout="row" fxLayoutAlign="space-between center"
                  *ngIf="(this.aportacion?.documento != undefined && documento === undefined)" class="centrar archivo">
                  <div><span class="sprite-icon ico-adjuntar"></span><span
                      class="nombre-archivo">{{this.aportacion.documento!.nombre}}
                    </span>
                    <mat-icon (click)="descargar(this.aportacion.documento)" class="ml-2 color-custom pointer-cursor"
                      matTooltip="Descargar">
                      download</mat-icon>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-sm flex-grow-1">
          <label for="autofactura">Autofactura</label>
          <div fxLayout="row" fxLayoutAlign="space-between center"
            *ngIf="(this.aportacion?.factura?.documentoAutofactura != undefined)" class="centrar archivo">
            <div><span class="sprite-icon ico-adjuntar"></span><span
                class="nombre-archivo">{{this.aportacion?.factura?.documentoAutofactura?.nombre}}
              </span>
              <mat-icon (click)="descargar(this.aportacion?.factura?.documentoAutofactura)" matTooltip="Descargar"
                class="ml-2 color-custom pointer-cursor">
                download</mat-icon>
            </div>
          </div>
        </div>
      </div>

    </form>
    <!-- FIN Formulario de Alta preliminar -->
  </div>

  <div class="fixed-footer">
    <div class="modal-footer actions">
      <div class="note">* Campos Obligatorios</div>
      <div class="button-group">
        <button type="button" (click)="closeDialog()" class="btn btn-outline-primary" data-dismiss="modal"
          aria-label="Close">Volver</button>
        <button *ngIf="canSaveAll()" type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close"
          (click)="saveData(null)">Guardar</button>
        <button *ngIf="canSaveAll()" type="button" class="btn btn-primary" data-dismiss="modal" aria-label="Close"
          (click)="saveData(2)">Guardar y
          Finalizar</button>
      </div>
    </div>
  </div>

</div>
