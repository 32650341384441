import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  AdheridosPrevisionResiduos,
  GestoresResiduosControllerService,
  PageDTOGestoresResiduosDTO,
} from 'api-rest';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import Swal from 'sweetalert2';
import { ValidateService } from '../../../services/validate.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { Adherido } from '../../../models/adherido';
import { AttachedService } from '../../../services/attached.service';
import { catchError, map, of, zip } from 'rxjs';

@Component({
  selector: 'lib-waste-forecast',
  templateUrl: './waste-forecast.component.html',
  styleUrls: ['./waste-forecast.component.scss'],
})
export class WasteForecastComponent implements OnChanges, OnInit {
  @Input() adheridoInfo!: Adherido;
  @Output() public refrescarAdheridoEvent = new EventEmitter<any>();

  formGroup!: FormGroup;
  tiposCodigosLer: any[] = [];
  operacionesGestion: any[] = [];
  suministraInfoList = [
    { id: 'Gestor', name: 'Gestor' },
    { id: 'Adherido', name: 'Adherido' },
  ];
  anios: any[] = [];
  codigosLerEndpoint =
    this.environment.urlBackCore + 'api/v1/core/codigo-ler/filtro-combo?sddrCC=true';
  updateList: boolean = false;
  updateListOpera: boolean = false;
  flagDisabledForm: boolean = false;
  anioActual!: number;
  grid: any[] = [];

  pageNumber: number = 0;
  pageSize: number = 100;
  recordsTotal: number = 0;
  gestionArray: any[] = [];

  headArray: any[] = [
    {
      Head: 'Año',
      FieldName: 'anioDatos',
    },
    {
      Head: 'Código LER y descripción de ENVALORA',
      FieldName: 'descripcionMCodLer',
      width: 60,
      Tooltip: true,
    },
    {
      Head: '',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'warning',
          icon: 'warning',
          toolTip:
            'NIMA de gestor no existe en la base de datos de gestores de ENVALORA',
          show: (row: any) => this.showWarning(row),
        },
      ],
    },
    {
      Head: 'NIMA del gestor',
      FieldName: 'nimaGestor',
    },
    {
      Head: '¿Quién suministra información de gestión?',
      FieldName: 'suministradorInformacion',
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
      ],
      width: '2',
      permanent: true,
    },
  ];
  constructor(
    private formBuilder: FormBuilder,
    @Inject('environment') private environment: any,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private validateSrv: ValidateService,
    private attachedSrv: AttachedService,
    private gestoresResiduosControllerService: GestoresResiduosControllerService,
    @Inject('EmpusaAuthenticationService')
    public authService: EmpusaAuthenticationService | any
  ) {
    this.formGroup = this.formBuilder.group({
      anio: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      codigoLer: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      nima: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(this.validateSrv.tenDigits),
            Validators.maxLength(10)
          ]),
        },
      ],
      suministraInfo: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
    });
  }
  ngOnInit(): void {
    this.anioActual = new Date().getFullYear();
    for (let i = this.anioActual - 3; i <= this.anioActual + 1; i++) {
      this.anios.push({ id: i });
    }
    this.formGroup.get('anio')?.setValue(this.anioActual);
    this.anioSelected();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.adheridoInfo) {
      this.anioSelected();
    }
  }
  getLerDescription(ler: any) {
    return ler?.tipoCodigo + ' - ' + ler?.descripcion;
  }

  getOperacionDescription(op: any) {
    return op?.descripcion;
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'codigoLer': {
        return this.formGroup.get('codigoLer') as FormControl;
      }
      default: {
        return this.formGroup.get('') as FormControl;
      }
    }
  }
  getObjectQuery(searchterm: string) {
    return {
      descripcion: searchterm
    };
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  selPaginatorHasError(controlName: string) {
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }
  addPrevision(): void {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Revisar los erroes en los campos ',
        'error'
      );
      return;
    }
    const existe = this.grid.find(
      (x) =>
        x.anioDatos === this.formGroup.get('anio')?.value &&
        x.codigoLer?.id === this.formGroup.get('codigoLer')?.value?.id &&
        x.nimaGestor === this.formGroup.get('nima')?.value &&
        x.suministradorInformacion ===
          this.formGroup.get('suministraInfo')?.value
    );
    if (existe) {
      this.snackBarSrv.showSnackBar(
        'Ya existe una previsión de residuos generados en el SDDR Cerrado introducida',
        'error'
      );
    } else {
      let obj: AdheridosPrevisionResiduos = {
        anioDatos: this.formGroup.get('anio')?.value,
        codigoLer: this.formGroup.get('codigoLer')?.value,
        nimaGestor: this.formGroup.get('nima')?.value,
        suministradorInformacion: this.formGroup.get('suministraInfo')?.value,
        adherido: {
          id: this.adheridoInfo.id!,
        },
      };
      this.guardarPrevision(obj);

      this.formGroup.patchValue({
        codigoLer: null,
        nima: null,
        suministraInfo: null,
      });
      this.formGroup.markAsPristine();
      this.formGroup.markAsUntouched();
    }
  }
  guardarPrevision(residuo: AdheridosPrevisionResiduos | any): void {
    this.spinnerSrv.loadingShow();
    this.adheridoInfo.previsionResiduos.push(residuo);
    this.attachedSrv
      .putAdherido(this.adheridoInfo.id!, this.adheridoInfo)
      .subscribe({
        next: () => {
          this.gestoresResiduosControllerService
            .findAll8(
              { nima: [residuo.nimaGestor] },
              {
                page: 0,
                size: 10,
              }
            )
            .subscribe({
              next: (data: PageDTOGestoresResiduosDTO) => {
                if (data) {
                  this.spinnerSrv.loadingHide();
                  residuo.existeNima = data.datos?.length != 0 ? true : false;
                  if (residuo.existeNima === false) {
                    this.snackBarSrv.showSnackBar(
                      'Este gestor no está registrado en ENVANET. Le recomendamos que le informe de que debe registrarse, en caso de delegarle el suministro de información por la correcta gestión de sus envases.',
                      'error'
                    );
                  }
                  this.refrescarAdheridoEvent.emit(true);
                }
              },
              error: (error: any) => {
                this.spinnerSrv.loadingHide();
              },
            });
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  action(event: any) {
    if (event.nameButton == 'delete') {
      this.confirmacionEliminarPrevision(event);
    }
  }
  confirmacionEliminarPrevision(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar la previsión de residuos?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        let body = this.adheridoInfo;
        this.grid = this.grid.filter((x: any) => x.id !== element.id);
        body.previsionResiduos = this.grid;
        this.attachedSrv.putAdherido(this.adheridoInfo?.id!, body).subscribe({
          next: (response: any) => {
            this.snackBarSrv.showSnackBar(
              'Se ha eliminado correctamente la previsión de residuos',
              'success'
            );
            this.refrescarAdheridoEvent.emit(true);
          },
          error: (err) => {},
        });
      }
    });
  }

  anioSelected(): void {
    if (
      this.adheridoInfo?.previsionResiduos &&
      this.adheridoInfo?.previsionResiduos?.length > 0
    ) {
      let observables: any[] = [];

      let residuosFiltrados = this.adheridoInfo?.previsionResiduos.filter(
        (x: any) => x.anioDatos == this.formGroup.get('anio')?.value
      );
      if (residuosFiltrados?.length > 0) {
        residuosFiltrados.forEach((residuo: any) => {
          let observable = this.gestoresResiduosControllerService
            .findAll8(
              { nima: [residuo.nimaGestor] },
              {
                page: 0,
                size: 10,
              }
            )
            .pipe(
              map((resultado: any) => {
                residuo.existeNima =
                  resultado.datos?.length != 0 ? true : false;
                return residuo;
              }),
              catchError((error: any) => {
                console.error(
                  `Error comprobando Nima para residuo con id ${residuo.id}:`,
                  error
                );
                residuo.existeNima = false;
                return of(residuo);
              })
            );
          observables.push(observable);
        });

        zip(...observables).subscribe((residuosActualizados: any[]) => {
          this.grid = this.canAccessAll(residuosActualizados);
        });
      } else {
        this.grid = [];
      }
    }
  }

  showWarning(row: any) {
    return !row.existeNima;
  }

  canAccessDelete(): boolean {
    return this.authService.can('adheridos-prevision', 'delete');
  }
  canAccessCreate(): boolean {
    return this.authService.can('adheridos-prevision', 'add-prevision');
  }

  canAccessAll(list: any) {
    const resultDelete = this.canAccessDelete();

    const listPermission = list.map((objeto: any) => {
      let descripcionMCodLer =
        objeto.codigoLer.tipoCodigo + ' ' + objeto.codigoLer.descripcion;
      return {
        ...objeto,
        descripcionMCodLer: descripcionMCodLer,
        canAccessDelete: resultDelete,
      };
    });

    return listPermission;
  }
}
