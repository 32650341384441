import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {SpinnerService} from '../../../services/spinner.service';
import {DownloadService} from '../../../services/download.service';
import {SnackbarService} from '../../../services/snackbar.service';
import Swal from 'sweetalert2';
import {
  ComunidadControllerService,
  PageDTOPoseedorDTO,
  PoseedorControllerService,
  PoseedorDTO,
  PoseedorEstadoControllerService,
  PoseedorFilterDTO,
} from 'api-rest';
import {EmpusaAuthenticationService} from '@empusa/empusa-core';
import {HoldersService} from '../../../services/holders.service';

@Component({
  selector: 'app-holders-search',
  templateUrl: './holders-search.component.html',
  styleUrls: ['./holders-search.component.scss'],
})
export class HoldersSearchComponent implements OnInit {
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  updateList: boolean = false;
  cnaeEndpoint = this.environment.urlBackCore + 'api/v1/core/cnae';

  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  totalElements: number = 0;
  totalSearch: number = 0;

  comunidadArray: any[] = [];
  estadoArray: any[] = [];

  headArray: any = [
    {
      Head: 'ID. Poseedor',
      FieldName: 'id',
    },
    {
      Head: 'Razón Social',
      FieldName: 'razonSocial',
    },
    {
      Head: 'Documento (NIE,NIF)',
      FieldName: 'codigoDocumento',
    },
    {
      Head: 'Estado',
      FieldName: 'estado',
      Attribute: 'descripcionCorta',
    },
    {
      Head: 'CNAE principal',
      FieldName: 'cnae1',
      Attribute: 'descripcion',
    },
    {
      Head: 'Descripción centro',
      FieldName: 'denominacionCentro',
    },
    {
      Head: 'NIMA',
      FieldName: 'nima',
    },
    {
      Head: 'Comunidad autónoma (Dirección Física)',
      FieldName: 'comunidadFisica',
      Attribute: 'nombre',
    },
    {
      Head: '% Adheridos (año en curso)',
      FieldName: 'porcentajeAdheridos',
    },
    {
      Head: 'Contrato con algún gestor',
      FieldName: 'contratoGestor',
      renderValue: (item: any) => (item?.contratoGestor == true ? 'Si' : 'No'),
    },
    {
      Head: 'Acuerdo gestor Envalora',
      FieldName: 'acuerdoGestorEnvalora',
      renderValue: (item: any) => (item?.acuerdoGestorEnvalora == true ? 'Si' : 'No'),
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Editar',
          show: { params: 'canAccessView', accion: 'view' },
        },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
        {
          nameButton: 'menu',
          array: [
            {
              nameButton: 'generar_acuerdo',
              name: 'Generar acuerdo',
              show: {
                params: 'canAccessUpdateCont',
                accion: 'generar_acuerdo',
              },
            },
            {
              nameButton: 'completado',
              name: 'Completado',
              show: {
                params: 'canAccessUpdateCont',
                accion: 'completado',
              },
            },
            {
              nameButton: 'baja_poseedor',
              name: 'Baja de Poseedor',
              show: {
                params: 'canAccessUpdateUnregist',
                accion: 'baja_poseedor',
              },
            },
          ],
        },
      ],
      width: '8',
      permanent: true,
    },
  ];
  gridArray: Array<PoseedorDTO> = [];

  estadosArray: any = [];

  searchForm: FormGroup;

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private downloadService: DownloadService,
    private snackBarSrv: SnackbarService,
    private poseedorControllerService: PoseedorControllerService,
    private comunidadControllerService: ComunidadControllerService,
    private poseedorEstadoControllerService: PoseedorEstadoControllerService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    private holdersService: HoldersService
  ) {
    this.searchForm = this.formBuilder.group({
      idPoseedor: [{ value: null, disabled: false }, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      codigo_documento: [{ value: null, disabled: false }],
      razon_social: [{ value: null, disabled: false }],
      comunidad_autonoma: [{ value: null, disabled: false }],
      estado: [{ value: null, disabled: false }],
      nima: [{ value: null, disabled: false }],
      cnae: [{ value: null, disabled: false }],
      contratoGestor: [{ value: null, disabled: false }],
      acuerdoGestorEnvalora: [{ value: null, disabled: false }],
    });
  }

  ngOnInit() {
    this.comboEstados();
    this.getComboComunidad();
  }

  checked(event: any) {}

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.searchData();
    }
  }

  exportarExcel() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.poseedorControllerService
      .exportCSV2(backendFilter, 'response')
      .subscribe((res) => {
        const filename = this.downloadService.getFileName(
          res.headers,
          'Exportacion_poseedor.csv'
        );
        this.downloadService.downloadCSV(res.body!, filename!);
      });
  }

  clearSearcher() {
    this.searchForm.reset();
    this.flagSearch = false;
    this.gridArray = [];
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'cnae': {
        return this.searchForm.get('cnae') as FormControl;
        break;
      }
      default: {
        return this.searchForm.get('') as FormControl;
        break;
      }
    }
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.searchForm.controls[controlName].hasError(errorName) &&
      this.searchForm.controls[controlName].touched
    );
  }

  private getBackendFilter() {
    const backendFilter: PoseedorFilterDTO = {
      id: this.searchForm.get('idPoseedor')?.value,
      codigoDocumento: this.searchForm.get('codigo_documento')?.value,
      razonSocial: this.searchForm.get('razon_social')?.value,
      cnae1: this.searchForm.get('cnae')?.value,
      nima: this.searchForm.get('nima')?.value,
      comunidadAutonoma: this.searchForm.get('comunidad_autonoma')?.value?.id,
      estado: this.searchForm.get('estado')?.value,
      contratoGestor: this.searchForm.get('contratoGestor')?.value,
      acuerdoGestorEnvalora: this.searchForm.get('acuerdoGestorEnvalora')?.value,
    };
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    return { backendFilter, page, size: this.pageSize };
  }

  searchData() {
    console.log(this.searchForm)
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.poseedorControllerService
      .findAll5(backendFilter, {
        page: page,
        size: size,
        sort: ['id,desc'],
      })
      .subscribe({
        next: (data: PageDTOPoseedorDTO) => {
          if (data) {
            this.spinnerSrv.loadingHide();
            this.flagSearch = data.datos?.length != 0 ? true : false;
            this.gridArray = this.canAccessAll(data.datos);
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.totalSearch = this.gridArray.length;
          }
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  action(element: any) {
    if (element.nameButton == 'view') {
      this.openDetailShow(element.id);
    } else if (element.nameButton == 'delete') {
      this.confirmacionEliminar(element);
    } else if (element.nameButton == 'generar_acuerdo') {
      if(!element.contratoGestor && element.residuosEnvase.length) {
        const currentYear = (new Date()).getFullYear();
        const isWasteForecastExist = element.residuosEnvase.some((e: any) => e.anioDatos - currentYear === 1)
        if(isWasteForecastExist){
          this.spinnerSrv.loadingShow();
          this.poseedorControllerService.generarAcuerdo(element.id).subscribe({
            next: () => {
              this.spinnerSrv.loadingHide();
              this.searchData();
              this.snackBarSrv.showSnackBar(
                'Se ha generado el acuerdo correctamente. El usuario recibirá un correo electrónico con instrucciones para la firma del acuerdo.',
                'success'
              );
            },
            error: (err) => {
              this.spinnerSrv.loadingHide();
              console.log(err);
            },
          });
        } else {
          this.snackBarSrv.showSnackBar(
            'No puede generar el acuerdo si no tiene un contrato previamente firmado con un gestor y una Previsión de residuos cumplimentada',
            'error'
          );
        }
      } else {
        this.snackBarSrv.showSnackBar(
          'No puede generar el acuerdo si no tiene un contrato previamente firmado con un gestor y una Previsión de residuos cumplimentada',
          'error'
        );
      }
    } else if (element.nameButton == 'completado') {
      this.spinnerSrv.loadingShow();
      this.poseedorControllerService.actualizarEstadoPoseedor(element.id, 4).subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.searchData();
          this.snackBarSrv.showSnackBar(
            'Se ha completado el poseedor correctamente',
            'success'
          );
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          console.log(err);
        },
      })
    } else if (element.nameButton == 'baja_poseedor') {
      this.spinnerSrv.loadingShow();
      this.poseedorControllerService.bajaPoseedor(element.id).subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.searchData();
          this.snackBarSrv.showSnackBar(
            'Se ha dado de baja el poseedor correctamente',
            'success'
          );
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          console.log(err);
        },
      });
    }
  }

  confirmacionEliminar(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar el poseedor?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarPoseedor(element);
      }
    });
  }

  eliminarPoseedor(element?: any) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.poseedorControllerService.borrarPoseedor(element.id).subscribe({
        next: (value: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            `El poseedor se ha eliminado correctamente`,
            'success'
          );
          this.gridArray = this.gridArray.filter(
            (objeto) => objeto.id !== element.id
          );
          this.flagSearch = this.gridArray.length != 0 ? true : false;
        },
        error: (err: { message: any }) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al eliminar el poseedor',
            'error'
          );
        },
      });
    }
  }

  comboEstados() {
    this.poseedorEstadoControllerService.findAll6().subscribe({
      next: (data: any) => {
        this.estadosArray = data!;
      },
      error: () => {},
    });
  }

  openDetailShow(id: number): void {
    this.holdersService.setTempHoldersSearch(true);
    this.router.navigate(['/holders/detail', id]);
  }

  getCnaDescription(cnae: any) {
    return cnae.clase + ' - ' + cnae.descripcion;
  }

  getCnaeObjectQuery(searchterm: string) {
    return {
      descripcion: `or(ci(${searchterm}))`,
      clase: `or(ci(${searchterm}))`,
    };
  }

  canAccessAll(list: any) {
    const resultView = this.authService.can('poseedores', 'view');
    const resultDelete = this.authService.can('poseedores', 'delete');
    const resultUpdateCont = this.authService.can(
      'poseedores',
      'update-contract'
    );
    const resultUpdateUnregist = this.authService.can(
      'poseedores',
      'update-unregister'
    );

    const listPermission = list.map((objeto: any) => {
      const estadoDelete =
        objeto.estado && [1, 2, 3, 6].includes(objeto.estado.id);
      const estadoUpdateCont =
        objeto.estado && (objeto.estado.id === 2 || objeto.estado.id === 6);
      const estadoUpdateUnregist = objeto.estado && objeto.estado.id === 4;

      return {
        ...objeto,
        canAccessView: resultView,
        canAccessDelete: resultDelete && estadoDelete,
        canAccessUpdateCont: resultUpdateCont && estadoUpdateCont,
        canAccessUpdateUnregist: resultUpdateUnregist && estadoUpdateUnregist,
      };
    });

    return listPermission;
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.searchForm.controls[controlName]?.touched ||
        this.searchForm.controls[controlName]?.dirty) &&
      this.searchForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  getComboComunidad() {
    this.comunidadControllerService
      .listarComunidades3({}, { page: 0, size: 500, sort: [] })
      .subscribe({
        next: (data: any) => {
          this.comunidadArray = data.datos!;
        },
        error: () => {},
      });
  }

  onlyNumberKey(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Control'];

    if (event.ctrlKey && (event.key === 'c' || event.key === 'v')) return;

    // Permitir números y teclas adicionales definidas
    if (!/^[0-9]$/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
