import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatCurrency',
})
@Injectable({
  providedIn: 'root',
})
export class FormatCurrencyPipe implements PipeTransform {
  transform(value: any, decimals?: number): string {
    let numDec = 5;
    if(decimals){
      numDec = decimals;
    }
    if (typeof value === 'number') {
      return this.formatNumber(value.toFixed(numDec));
    } else if (typeof value === 'string' && !isNaN(parseFloat(value))) {
      return this.formatNumber(parseFloat(value).toFixed(numDec));
    } else {
      return '';
    }
  }

  private formatNumber(value: string): string {
    let [integerPart, decimalPart] = value.split('.');
    integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    let formattedValue = decimalPart
      ? `${integerPart},${decimalPart}`
      : integerPart;

    formattedValue = formattedValue.replace(/,?0+$/, '');

    return `${formattedValue}`;
  }
}
