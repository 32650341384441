/**
 * Poseedores API
 * Poseedores API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { JobModel } from './jobModel';


export interface PrefacturacionResponseDTO { 
    id?: number;
    jobInfo?: JobModel;
    modulo?: PrefacturacionResponseDTO.ModuloEnum;
    proceso?: PrefacturacionResponseDTO.ProcesoEnum;
    usuarioEjecucion?: string;
    fechaEjecucion?: string;
    fechaFinalizacion?: string;
    numeroPrefacturasGeneradas?: number;
    erroresGenerados?: Array<string>;
}
export namespace PrefacturacionResponseDTO {
    export type ModuloEnum = 'POSEEDORES';
    export const ModuloEnum = {
        Poseedores: 'POSEEDORES' as ModuloEnum
    };
    export type ProcesoEnum = 'APORTACIONES_DIS';
    export const ProcesoEnum = {
        AportacionesDis: 'APORTACIONES_DIS' as ProcesoEnum
    };
}


