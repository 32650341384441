import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  Inject,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { AttachedService } from '../../../services/attached.service';
import { Adherido, Cnae } from '../../../models/adherido';
import { Combo } from '../../../models/combo';
import { CombosService } from '../../../services/combos.service';
import { Tramo_cuota } from '../../../models/tramo_cuota';
import { ValidateService } from '../../../services/validate.service';
import moment from 'moment';
import { SnackbarService } from '../../../services/snackbar.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { SpinnerService } from '../../../services/spinner.service';
import { CertificadosControllerService, ScrapControllerService } from 'api-rest';
import { DownloadService } from '../../../services/download.service';

@Component({
  selector: 'lib-detail-general-data',
  templateUrl: './detail-general-data.component.html',
  styleUrls: ['./detail-general-data.component.scss'],
})
export class DetailGeneralDataComponent implements OnInit, OnChanges {
  @Input() adheridoInfo!: Adherido;
  @Output() public refrescarAdheridoEvent = new EventEmitter<any>();

  datosGeneralesForm: FormGroup;
  tiposEmpresa: Combo[] = [];
  tiposDocumento: Combo[] = [];
  tiposTelefono: Combo[] = [];
  tiposPuestaEnMercado: Combo[] = [];
  cifrasNegocio: Tramo_cuota[] = [];
  categoriasEnvases: Combo[] = [];
  paisPrefijo!: any;
  scrapsOrigen: any[] = [];
  scrapsDestino: any[] = [];

  updateList: boolean = false;
  cnaeEndpoint = this.environment.urlBackCore + 'api/v1/core/cnae';
  paisesEndpoint = this.environment.urlBackCore + 'api/v1/core/countries';
  flagDisabledForm: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    public comboSrv: CombosService,
    private attachedSrv: AttachedService,
    private validateSrv: ValidateService,
    @Inject('environment') private environment: any,
    private snackBarSrv: SnackbarService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    public spinnerSrv: SpinnerService,
    private adheridoCertificControllerService: CertificadosControllerService,
    private downloadService: DownloadService, private scrapService: ScrapControllerService
  ) {
    this.datosGeneralesForm = this.formBuilder.group({
      tipoEmpresa: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      razonSocial: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(150)]) },
      ],
      tipoDocumento: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      codigoDocumento: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(this.validateSrv.documentNumber),
            Validators.maxLength(15)
          ]),
        },
      ],
      grupoEmpresarial: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) }
      ],
      nombreGrupo: [{ value: null, disabled: false },
        {validators: Validators.compose([Validators.maxLength(255)])}],
      cifGrupoEmpresarial: [{ value: null, disabled: false }],
      cnaePrincipal: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      cnaeSecundario: [{ value: null, disabled: false }],
      tipoTlfEmpresa: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      prefijoPais: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      tlfEmpresa: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            this.telefonoValidator.bind(this),
            Validators.maxLength(15)
          ]),
        },
      ],
      email: [
        { value: null, disabled: false },
        //{
        //  validators: Validators.compose([
        //    Validators.pattern(this.validateSrv.emailPattern),
        //    Validators.maxLength(100)
        //  ]),
        //},
      ],
      web: [{ value: null, disabled: false },
        {validators: Validators.compose([Validators.maxLength(100)])}
      ],
      categoriaEnvases: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      tipoUso: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      cifraNegocio: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      numRegistroProductor: [
        { value: null, disabled: false },
        [Validators.required, this.formatoValidator()],
      ],
      asociado: [{ value: null, disabled: false },{ validators: Validators.compose([Validators.required]) }],
      fabricante: [{ value: null, disabled: false },{ validators: Validators.compose([Validators.required]) }],
      conAdenda: [{ value: null, disabled: false },{ validators: Validators.compose([Validators.required]) }],
      planEmpresarial: [{ value: null, disabled: false },{ validators: Validators.compose([Validators.required]) }],
      descripcionAdenda: [{ value: null, disabled: false },
        {validators: Validators.compose([Validators.maxLength(100)])}
      ],
      descripcionActividad: [{ value: null, disabled: false },
        {validators: Validators.compose([Validators.maxLength(500)])}
      ],
      remitirInformacion: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      fechaFirmaContrato: [{ value: null, disabled: false }],
      fechaAdhesionFinalizada: [{ value: null, disabled: false }],
      fechaBaja: [{ value: null, disabled: false }],
      fechaBajaEnvanet: [{ value: null, disabled: false }],
      scrapOrigen: [{ value: null, disabled: false }],
      scrapDestino: [{ value: null, disabled: false }],
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.adheridoInfo) {
      this.cargarDatos(this.adheridoInfo);
      this.controlGeneralForm();
    }
  }

  ngOnInit(): void {
    this.attachedSrv.getTramos_cuota().subscribe({
      next: (response: any) => {
        this.cifrasNegocio = response.content;
      },
      error: (err) => {
        console.log(err);
      },
    });
    this.tiposEmpresa = this.comboSrv.getTiposEmpresa();
    this.tiposDocumento = this.comboSrv.getTiposDocumentoIdentidad();
    this.tiposTelefono = this.comboSrv.getTiposTelefono();
    this.tiposPuestaEnMercado = this.comboSrv.getTiposPuestaEnMercado();
    this.categoriasEnvases = this.comboSrv.getCategoriasEnvases();
    this.obtenerScraps();
    this.datosGeneralesForm
      .get('tipoTlfEmpresa')
      ?.valueChanges.subscribe(() => {
        this.datosGeneralesForm.get('tlfEmpresa')?.updateValueAndValidity();
      });

    this.datosGeneralesForm.get('prefijoPais')?.valueChanges.subscribe(() => {
      this.datosGeneralesForm.get('tlfEmpresa')?.updateValueAndValidity();
    });
    
  }

  obtenerScraps(){
    this.scrapService.getScrapOrigen({page: 1, size:200}).subscribe({
      next: (response: any) => {
        this.scrapsOrigen = response;
      },
      error: (err) => {
        console.log(err);
      },
    });
    this.scrapService.getScrapDestino({page: 1, size:200}).subscribe({
      next: (response: any) => {
        this.scrapsDestino = response;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  telefonoValidator(control: AbstractControl): { [key: string]: any } | null {
    const tipoTelefono = this.datosGeneralesForm?.get('tipoTlfEmpresa')?.value;
    const prefijoPais = this.datosGeneralesForm?.get('prefijoPais')?.value;
    
    if (
      prefijoPais?.prefijo == '34' ||
      (typeof prefijoPais === 'string' && prefijoPais?.toUpperCase() == '34 (ESPAÑA)')
    ) {
      if (tipoTelefono === 'FIJO') {
        const valid = /^[89][0-9]{8}$/.test(control.value);
        return valid ? null : { invalidTelefonoFijo: true };
      } else if (tipoTelefono === 'MOVIL') {
        const valid = /^[67][0-9]{8}$/.test(control.value);
        return valid ? null : { invalidTelefonoMovil: true };
      }
    }
    return null;
  }

  tipoDocumentoSel(idTipoDoc: string) {
    this.datosGeneralesForm.get('codigoDocumento')?.clearValidators();
    if (idTipoDoc === 'NIF') {
      this.datosGeneralesForm
        .get('codigoDocumento')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.nifPattern),
        ]);
    } else if (idTipoDoc === 'DNI') {
      this.datosGeneralesForm
        .get('codigoDocumento')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.dniPattern),
        ]);
    } else if (idTipoDoc === 'NIE') {
      this.datosGeneralesForm
        .get('codigoDocumento')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.niePattern),
        ]);
    }
    this.datosGeneralesForm.get('codigoDocumento')?.updateValueAndValidity();
    this.datosGeneralesForm.get('codigoDocumento')?.markAsTouched();
  }

  cargarDatos(adheridoInfo: Adherido) {
    this.datosGeneralesForm.get('tipoEmpresa')?.setValue(adheridoInfo?.tipoEmpresa);
    this.datosGeneralesForm.get('razonSocial')?.setValue(adheridoInfo?.razonSocial);
    this.datosGeneralesForm.get('tipoDocumento')?.setValue(adheridoInfo?.tipoDocumento);
    this.datosGeneralesForm.get('codigoDocumento')?.setValue(adheridoInfo?.codigoDocumento);
    this.datosGeneralesForm.get('nombreGrupo')?.setValue(adheridoInfo?.nombreGrupo);
    this.datosGeneralesForm.get('grupoEmpresarial')?.setValue(adheridoInfo?.grupoEmpresarial);
    if (adheridoInfo?.grupoEmpresarial) {
      this.datosGeneralesForm.controls['nombreGrupo']?.setValidators([Validators.required]);
      this.datosGeneralesForm.controls['nombreGrupo']?.updateValueAndValidity();
    }
    this.datosGeneralesForm.get('cifGrupoEmpresarial')?.setValue(adheridoInfo?.cifGrupo);
    this.datosGeneralesForm.get('tipoTlfEmpresa')?.setValue(adheridoInfo?.tipoTelefono);
    if(adheridoInfo?.codigoPais){
      this.datosGeneralesForm
        .get('prefijoPais')
        ?.setValue(adheridoInfo?.codigoPais?.prefijoNombre);
      this.paisPrefijo = adheridoInfo?.codigoPais;
    }
    this.datosGeneralesForm.get('tlfEmpresa')?.setValue(adheridoInfo?.telefono);
    this.datosGeneralesForm.get('email')?.setValue(adheridoInfo?.email);
    this.datosGeneralesForm.get('web')?.setValue(adheridoInfo?.paginaWeb);
    this.datosGeneralesForm.get('tipoUso')?.setValue(adheridoInfo?.tipoUsoEnvases);
    this.datosGeneralesForm.get('categoriaEnvases')?.setValue(adheridoInfo?.puestaEnMercado);
    this.datosGeneralesForm.get('cifraNegocio')?.setValue(adheridoInfo?.cifraNegocio?.id);
    this.agregarBarraAutomaticamente(adheridoInfo?.registroProductor, true);
    // this.datosGeneralesForm.get("numRegistroProductor")?.setValue(adheridoInfo?.registroProductor);
    this.datosGeneralesForm.get('asociado')?.setValue(adheridoInfo?.asociado);
    this.datosGeneralesForm.get('fabricante')?.setValue(adheridoInfo?.fabricante);
    this.datosGeneralesForm.get('conAdenda')?.setValue(adheridoInfo?.conAdenda);
    this.datosGeneralesForm.get('planEmpresarial')?.setValue(adheridoInfo?.planEmpresarial);
    this.datosGeneralesForm.get('descripcionAdenda')?.setValue(adheridoInfo?.descripcionAdenda);
    this.datosGeneralesForm.get('descripcionActividad')?.setValue(adheridoInfo?.descripcionActividad);
    this.datosGeneralesForm.get('remitirInformacion')?.setValue(adheridoInfo?.remitirInformacion);
    if (this.adheridoInfo?.fechaFirmaContrato) {
      let fechaFirmaContrato = moment(
        new Date(this.adheridoInfo?.fechaFirmaContrato!)
      ).format('DD/MM/YYYY');
      this.datosGeneralesForm.get('fechaFirmaContrato')?.setValue(fechaFirmaContrato);
    }
    if (adheridoInfo?.fechaAdhesionFinalizada) {
      let fechaAdhesionFin = moment(
        new Date(this.adheridoInfo?.fechaAdhesionFinalizada!)
      ).format('DD/MM/YYYY');
      this.datosGeneralesForm.get('fechaAdhesionFinalizada')?.setValue(fechaAdhesionFin);
    }
    if (adheridoInfo?.fechaBajaEnvanet) {
      let fechaAdhesionFin = moment(
        new Date(this.adheridoInfo?.fechaBajaEnvanet!)
      ).format('DD/MM/YYYY');
      this.datosGeneralesForm.get('fechaBajaEnvanet')?.setValue(fechaAdhesionFin);
    }
    this.datosGeneralesForm.get('fechaBaja')?.setValue(this.adheridoInfo.fechaBaja);

    this.datosGeneralesForm.get('cnaePrincipal')?.setValue(this.adheridoInfo.cnae1?.descripcion);
    this.datosGeneralesForm.get('cnaeSecundario')?.setValue(this.adheridoInfo.cnae2?.descripcion);

    this.tipoDocumentoSel(this.datosGeneralesForm.get('tipoDocumento')?.value);
    this.datosGeneralesForm.get('scrapOrigen')?.setValue(this.adheridoInfo.scrapOrigen);
    this.datosGeneralesForm.get('scrapDestino')?.setValue(this.adheridoInfo.scrapDestino);

    this.datosGeneralesForm.get('tlfEmpresa')?.updateValueAndValidity();
    this.datosGeneralesForm.get('tlfEmpresa')?.markAllAsTouched();
  }

  getCnaDescription(cnae: Cnae) {
    return cnae.clase + ' - ' + cnae.descripcion;
  }

  getCnaeObjectQuery(searchterm: string) {
    return {
      descripcion: `or(ci(${searchterm}))`,
      clase: `or(ci(${searchterm}))`,
    };
  }

  formatoValidator() {
    return (control: FormControl) => {
      const valor = control.value;
      if (!valor) {
        return null;
      }
      const regex = /^ENV\/\d{4}\/\d{9}$/;
      if (!regex.test(valor)) {
        return { formatoInvalido: true };
      }

      if (valor.length !== 18) {
        return { longitudIncorrecta: true };
      }

      return null;
    };
  }

  agregarBarraAutomaticamente(event?: any, carga: boolean = false) {
    let valor = carga ? event : event.target.value;
    if (valor != null) {
      valor = valor.replace(/[^A-Za-z0-9]/g, '');

      if (valor.length >= 3 && valor.length < 7) {
        valor = valor.substring(0, 3) + '/' + valor.substring(3);
      } else if (valor.length >= 7) {
        valor =
          valor.substring(0, 3) +
          '/' +
          valor.substring(3, 7) +
          '/' +
          valor.substring(7);
      }
    }
    this.datosGeneralesForm.get('numRegistroProductor')?.setValue(valor);
  }

  guardar() {
    this.datosGeneralesForm.markAllAsTouched();
    this.datosGeneralesForm.updateValueAndValidity();
    if (this.datosGeneralesForm.valid) {
      let body = this.adheridoInfo;
      body.tipoEmpresa = this.datosGeneralesForm.get('tipoEmpresa')?.value;
      body.razonSocial = this.datosGeneralesForm.get('razonSocial')?.value;
      body.tipoDocumento = this.datosGeneralesForm.get('tipoDocumento')?.value;
      body.codigoDocumento =
        this.datosGeneralesForm.get('codigoDocumento')?.value;
      body.nombreGrupo = this.datosGeneralesForm.get('nombreGrupo')?.value;
      body.grupoEmpresarial =
        this.datosGeneralesForm.get('grupoEmpresarial')?.value;
      body.tipoTelefono = this.datosGeneralesForm.get('tipoTlfEmpresa')?.value;
      body.telefono = this.datosGeneralesForm.get('tlfEmpresa')?.value;
      body.email = this.datosGeneralesForm.get('email')?.value;
      body.paginaWeb = this.datosGeneralesForm.get('web')?.value;
      body.tipoUsoEnvases = this.datosGeneralesForm.get('tipoUso')?.value;
      body.puestaEnMercado =
        this.datosGeneralesForm.get('categoriaEnvases')?.value;
      //preguntar como enviar cifra de negocio
      let cifraNegocioSel = this.cifrasNegocio?.find(
        (x) => x.id === this.datosGeneralesForm.get('cifraNegocio')?.value
      );
      body.cifraNegocio = cifraNegocioSel;
      let numeroRegistro = this.datosGeneralesForm
        .get('numRegistroProductor')
        ?.value?.replace(/\//g, '');
      body.registroProductor = numeroRegistro;
      body.asociado = this.datosGeneralesForm.get('asociado')?.value;
      body.fabricante = this.datosGeneralesForm.get('fabricante')?.value;
      body.conAdenda = this.datosGeneralesForm.get('conAdenda')?.value;
      body.planEmpresarial =
        this.datosGeneralesForm.get('planEmpresarial')?.value;
      if (this.datosGeneralesForm.get('conAdenda')?.value) {
        body.descripcionAdenda =
          this.datosGeneralesForm.get('descripcionAdenda')?.value;
      } else {
        body.descripcionAdenda = null;
      }

      body.descripcionActividad = this.datosGeneralesForm.get(
        'descripcionActividad'
      )?.value;
      body.remitirInformacion =
        this.datosGeneralesForm.get('remitirInformacion')?.value;
      body.fechaBaja = this.datosGeneralesForm.get('fechaBaja')?.value;
      body.fechaFirmaContrato = this.adheridoInfo?.fechaFirmaContrato;
      body.fechaAdhesionFinalizada = this.adheridoInfo?.fechaAdhesionFinalizada;
      body.fechaBajaEnvanet = this.adheridoInfo?.fechaBajaEnvanet;
      body.scrapOrigen = this.datosGeneralesForm.get('scrapOrigen')?.value;
      body.scrapDestino = this.datosGeneralesForm.get('scrapDestino')?.value;

      // Si es un string es porque no he modificado el valor que me ha llegado del servicio
      let cnaePrincipal = this.datosGeneralesForm.get('cnaePrincipal')?.value;
      if (typeof cnaePrincipal === 'string') {
        body.cnae1 = this.adheridoInfo?.cnae1;
      } else {
        body.cnae1 = this.datosGeneralesForm.get('cnaePrincipal')?.value;
      }
      let cnaeSecundario = this.datosGeneralesForm.get('cnaeSecundario')?.value;
      if (typeof cnaeSecundario === 'string') {
        body.cnae2 = this.adheridoInfo?.cnae2;
      } else {
        body.cnae2 = this.datosGeneralesForm.get('cnaeSecundario')?.value;
      }

      let prefijo = this.datosGeneralesForm.get('prefijoPais')?.value;
      if (typeof prefijo === 'string') {
        body.codigoPais = this.paisPrefijo;
      } else {
        body.codigoPais = this.datosGeneralesForm.get('prefijoPais')?.value;
      }
      this.attachedSrv.putAdherido(this.adheridoInfo.id!, body).subscribe({
        next: (response: Adherido) => {
          this.snackBarSrv.showSnackBar(
            'Datos generales guardados correctamente',
            'success'
          );
          this.refrescarAdheridoEvent.emit(true);
        }
      });
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  controlFormDisable() {
    this.datosGeneralesForm.disable();
  }

  controlFormEnable() {
    this.datosGeneralesForm.enable();
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.datosGeneralesForm.controls[controlName].hasError(errorName) &&
      this.datosGeneralesForm.controls[controlName].touched
    );
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'cnaePrincipal': {
        return this.datosGeneralesForm.get('cnaePrincipal') as FormControl;
        break;
      }
      case 'cnaeSecundario': {
        return this.datosGeneralesForm.get('cnaeSecundario') as FormControl;
        break;
      }
      case 'prefijoPais': {
        return this.datosGeneralesForm.get('prefijoPais') as FormControl;
        break;
      }
      default: {
        return this.datosGeneralesForm.get('') as FormControl;
        break;
      }
    }
  }

  public filterList(data: any) { }

  selPaginatorHasError(controlName: string) {
    if (
      (this.datosGeneralesForm.controls[controlName]?.touched ||
        this.datosGeneralesForm.controls[controlName]?.dirty) &&
      this.datosGeneralesForm.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  canAccessDetailUpdate(): boolean {
    const responseUpdate = this.authService.can('adheridos-detalle', 'update');
    return responseUpdate;
  }

  canAccessAsociado(): boolean {
    const responseAsociado = this.authService.can(
      'adheridos-detalle',
      'access-asociado'
    );
    return responseAsociado;
  }

  canAccessFabricante(): boolean {
    const responseFabricante = this.authService.can(
      'adheridos-detalle',
      'access-fabricante'
    );
    return responseFabricante;
  }

  canAccessAdenda(): boolean {
    const responseAdenda = this.authService.can(
      'adheridos-detalle',
      'access-adenda'
    );
    return responseAdenda;
  }

  canAccessPlan(): boolean {
    const responsePlan = this.authService.can(
      'adheridos-detalle',
      'access-plan'
    );
    return responsePlan;
  }

  canAccessFechas(): boolean {
    return (this.canViewResource('AD-ENV-GEST') || this.canViewResource('AD-ENV-CONS'));
  }

  canAccessUpdateBaja(): boolean {
    const responseUpdatebaja = this.authService.can(
      'adheridos-detalle',
      'udpate-baja'
    );
    return responseUpdatebaja;
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.datosGeneralesForm.controls[controlName]?.touched ||
        this.datosGeneralesForm.controls[controlName]?.dirty) &&
      this.datosGeneralesForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  controlHasErrorSelect(controlName: string) {
    if (
      (this.datosGeneralesForm.controls[controlName]?.touched ||
        this.datosGeneralesForm.controls[controlName]?.dirty) &&
      this.datosGeneralesForm.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  controlGeneralForm() {
    if (this.canAccessContinuarAll() || this.canAccessGuardar()) {
      this.controlFormEnable();
      if(!this.canViewResource('AD-ENV-GEST') && !this.canViewResource('AD-ENV-CONS')){
        this.datosGeneralesForm?.get('scrapOrigen')?.disable();
      }
      if(!this.canViewResource('AD-ENV-GEST')){
        this.datosGeneralesForm?.get('fechaBaja')?.disable();
      }

    } else {
      this.controlFormDisable();
    }
  }

  canAccessContinuarAll(): boolean {
    return (
      this.authService.can('adheridos-detalle', 'access-b-continuar') &&
      this.adheridoInfo?.estado?.id == 1
    );
  }

  canAccessGuardar() {
    return (
      this.authService.can('adheridos-detalle', 'access-b-guardar') &&
      ((this.adheridoInfo.estado && [2, 3, 4, 5, 6].includes(this.adheridoInfo?.estado?.id)) || this.esGestorEnvalora())
    );
  }

  canShowScrapOrigen(){
    return ((this.canViewResource('AD-ENV-GEST') || this.canViewResource('AD-ENV-CONS')) 
          || (this.adheridoInfo?.scrapOrigen));
  }

  canShowScrapDestino(){
    return ((this.canViewResource('AD-ENV-GEST') || this.canViewResource('AD-ENV-CONS')) 
          || (this.adheridoInfo?.scrapDestino));
  }

  canViewResource(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

  certificadoPeriodico() {
    this.spinnerSrv.loadingShow();
    this.adheridoCertificControllerService
      .generarCertificadoPeriodico(this.adheridoInfo?.id!)
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          try {
            this.snackBarSrv.showSnackBar(
              'Certificado periódico generado correctamente. Puede acceder a él en la sección de documentos.',
              'success'
            );
          } catch (error) {
            this.snackBarSrv.showSnackBar(
              'Error al procesar y descargar el archivo.',
              'error'
            );
          }
        },
        error: (err:any) => {
          this.snackBarSrv.showSnackBar(
            'Dispone de un certificado válido generado en los últimos 3 meses. Puede acceder a él en la sección de documentos.',
            'error'
          );
          this.spinnerSrv.loadingHide();
        },
      });
  }

  grupoEmpresarialChange(): void {
    if (this.datosGeneralesForm.controls['grupoEmpresarial']?.value) {
      this.datosGeneralesForm.controls['nombreGrupo']?.setValidators([Validators.required]);
    } else {
      this.datosGeneralesForm.controls['nombreGrupo']?.clearValidators();
    }
    this.datosGeneralesForm.controls['nombreGrupo']?.updateValueAndValidity();
  }

  agregarPrefijoSiEstaVacio(event: FocusEvent) {
    const inputElement = event.target as HTMLInputElement;
    if (!inputElement.value) {
      inputElement.value = 'ENV/';
      this.datosGeneralesForm.get("numRegistroProductor")?.setValue(inputElement.value);
    }
  }

  selectedPrefijoPais(event:any){
    let prefijo = this.datosGeneralesForm.get('prefijoPais')?.value;
    if (typeof prefijo === 'string') {
      this.paisPrefijo = event;
    }else{
      this.paisPrefijo = this.datosGeneralesForm.get('prefijoPais')?.value;
    }
  }
  
  changeAdenda(){
    const esConAdenda = this.datosGeneralesForm?.get('conAdenda')?.value;
    const descAdendaControl = this.datosGeneralesForm?.get('descripcionAdenda');
    if(esConAdenda){
      descAdendaControl?.setValidators([Validators.required]);
    }else{
      descAdendaControl?.clearValidators();
    }
    descAdendaControl?.updateValueAndValidity();
  }


  esGestorEnvalora(){
    return (this.canViewRol('AD-ENG-GEST') || this.canViewRol('AD-ENV-CONS'));
  }

  canViewRol(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }
}
