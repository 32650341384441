import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Combo2 } from '../../../models/combo';
import { Adherido } from '../../../models/adherido';
import { Domicilio } from '../../../models/domicilio';
import { AttachedService } from '../../../services/attached.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { SnackbarService } from '../../../services/snackbar.service';
import { ValidateService } from '../../../services/validate.service';
import { SpinnerService } from '../../../services/spinner.service';

export interface MappedResponse<T> {
  totalElements: number;
  recordsFiltered: number;
  datos: T[];
}
@Component({
  selector: 'lib-address-dialog',
  templateUrl: './address-dialog.component.html',
  styleUrls: ['./address-dialog.component.scss'],
})
export class AddressDialogComponent implements OnInit {
  //tipoDireccionEndpoint = this.environment.urlBackCore + 'api/v1/core/tipos-direccion';
  direccionesEndpoint =
    this.environment.urlBackCore + 'api/v1/core/direcciones';
  paisesEndpoint = this.environment.urlBackCore + 'api/v1/core/countries';
  provinciasEndpoint = this.environment.urlBackCore + 'api/v1/core/provinces';
  codigoPostalEndpoint =
    this.environment.urlBackCore + 'api/v1/core/postalcodes';
  tipoViaEndpoint = this.environment.urlBackCore + 'api/v1/core/via-types';
  flagDisabledForm: boolean = false;
  updateList: boolean = false;
  flagUploadCopy: boolean = false;
  addresGlobal: any = null;
  direccion: any;
  titulo = '';
  adherido!: Adherido;
  formGroup!: FormGroup;

  tiposDirecciones: Combo2[] = [];
  direcciones: Domicilio[] = [];
  paises: any[] = [];
  provincias: any[] = [];
  codigosPostales: any[] = [];
  municipios: any[] = [];
  tiposVia: any[] = [];
  copiarDirecciones: any[] = [];
  // tipo: string = "";
  provinceEs: any[] = [];
  actionExecuted: boolean = false;
  selectProvince: any;
  pageNumber: number = 0;
  pageSize: number = 100;

  fiveDigitsPattern: string = '^[0-9]{1,5}$';
  esShow: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<AddressDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private attachedSrv: AttachedService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    @Inject('environment') private environment: any,
    private snackBarSrv: SnackbarService,
    private validateSrv: ValidateService,
    public spinnerSrv: SpinnerService
  ) {
    this.direccion = this.data.direccion;
    this.adherido = this.data.adherido;

    this.formGroup = this.formBuilder.group({
      tipoDireccion: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      copiaDir: [{ value: null, disabled: false }],
      requerido: [{ value: null, disabled: true }],
      pais: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      provincia: [{value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(255)])}],
      codigoPostal: [{value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(255)])}],
      municipio: [{value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(255)])}],
      tipoVia: [{value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(255)])}],
      nombreVia: [{value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(100)])}],
      numero: [{value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(5)])}],
      bloque: [{value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(5)])}],
      escalera: [{value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(5)])}],
      piso: [{value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(5)])}],
      puerta: [{value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(5)])}],
      otrosDatos: [{value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(100)])}],
      coordenadas: [{value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(255)])}],
    });
  }
  ngOnInit(): void {
    this.getProvinceByCp();
    this.obtenerCombos();
    if (this.direccion) {
      this.titulo = 'Editar dirección';
      this.cargarDireccion(this.direccion, true);
      //this.tipo ='editar';
    } else {
      this.titulo = 'Nueva dirección';
      //this.tipo ='nueva';
    }
    this.copiarDirecciones = this.adherido?.domicilios?.filter(
      (objeto: any) =>
        objeto.direccionCompleta !== null &&
        objeto.direccionCompleta?.trim() !== ''
    );

    if (!this.authService.can('adheridos-direcciones', 'update'))
      this.formGroup.disable();

    this.cargarCP();
  }

  cargarCP() {
    this.formGroup.get('codigoPostal')?.valueChanges.subscribe((value: any) => {
      this.actionExecuted = false;
      this.formGroup.get('provincia')?.enable();
      if (
        value !== null &&
        !this.actionExecuted &&
        (this.formGroup.get('pais')?.value?.id == 69 ||
          this.formGroup.get('pais')?.value == 'ESPAÑA')
      ) {
        const cpValue = value != null ? value.toString() : null;
        if (/^\d{5}$/.test(cpValue)) {
          const twoDigits = parseInt(cpValue.substring(0, 2), 10);
          const data = this.provinceEs.find(
            (objeto: any) => objeto.id == twoDigits
          );
          if (data) {
            this.selectProvince = data;
            this.formGroup
              .get('provincia')
              ?.setValue(this.selectProvince?.nombre);
            this.formGroup.get('provincia')?.disable();
          }
          this.actionExecuted = true;
        }
      }
    });
  }
  obtenerCombos(): void {
    this.attachedSrv.getTiposDireccion().subscribe({
      next: (response) => {
        this.tiposDirecciones = response?.content;
      },
      error: (err) => {
        console.log(err);
      },
    });
  }

  cargarDireccion(direccion: any, editar: boolean): void {
    console.log('direccion', direccion);
    this.flagUploadCopy = true;
    this.addresGlobal = direccion;

    if (direccion?.pais?.id == 69) {
      this.formGroup.get('provincia')?.disable();
    }

    this.formGroup.get('pais')?.setValue(direccion?.pais?.nombre);
    this.formGroup.get('municipio')?.setValue(direccion?.municipio);
    this.formGroup.get('tipoVia')?.setValue(direccion?.tipoVia?.nombre);
    this.formGroup.get('nombreVia')?.setValue(direccion?.nombreVia);
    this.formGroup.get('numero')?.setValue(direccion?.numero);
    this.formGroup.get('bloque')?.setValue(direccion?.bloque);
    this.formGroup.get('escalera')?.setValue(direccion?.escalera);
    this.formGroup.get('piso')?.setValue(direccion?.piso);
    this.formGroup.get('puerta')?.setValue(direccion?.puerta);
    this.formGroup.get('otrosDatos')?.setValue(direccion?.otrosDatos);
    this.formGroup.get('coordenadas')?.setValue(direccion?.coordenadas);

    //Si no es edición no se cargan requerido ni tipo de dirección
    if (editar) {
      this.formGroup.get('requerido')?.setValue(direccion?.requerido);
      this.formGroup
        .get('tipoDireccion')
        ?.setValue(direccion?.tipoDireccion?.id);
      direccion?.requerido
        ? this.formGroup.get('tipoDireccion')?.disable()
        : this.formGroup.get('tipoDireccion')?.enable();
    }
    this.formGroup.get('provincia')?.setValue(direccion?.provincia?.nombre);
    this.formGroup
      .get('codigoPostal')
      ?.setValue(direccion?.codigoPostal?.codigoPostal);
  }

  guardar() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.valid) {
      let domicilio: any = {};
      if (this.direccion) {
        domicilio.id = this.direccion?.id;
        domicilio.requerido = this.direccion?.requerido;
      }

      let tipoDir = this.tiposDirecciones.find(
        (x) => x.id === this.formGroup.get('tipoDireccion')?.value
      );
      domicilio.tipoDireccion = tipoDir;

      let pais = this.formGroup.get('pais')?.value;
      if (typeof pais === 'string') {
        domicilio.pais = this.flagUploadCopy
          ? this.addresGlobal?.pais
          : this.direccion?.pais;
      } else {
        domicilio.pais = pais;
      }

      let provincia =
        this.selectProvince ?? this.formGroup.get('provincia')?.value;
      let cp = this.formGroup.get('codigoPostal')?.value;

      if (domicilio.pais?.iso === 'ES') {
        if (typeof provincia === 'string') {
          domicilio.provincia = this.flagUploadCopy
            ? this.addresGlobal?.provincia
            : this.direccion?.provincia;
        } else {
          domicilio.provincia = provincia;
        }
        domicilio.codigoPostal = {
          id: null,
          codigoPostal: this.formGroup.get('codigoPostal')?.value,
        };
      } else {
        domicilio.provincia = { nombre: provincia };
        domicilio.codigoPostal = { codigoPostal: cp };
      }
      domicilio.municipio = this.formGroup.get('municipio')?.value;

      let tipoVia = this.formGroup.get('tipoVia')?.value;
      if (typeof tipoVia === 'string') {
        domicilio.tipoVia = this.flagUploadCopy
          ? this.addresGlobal?.tipoVia
          : this.direccion?.tipoVia;
      } else {
        domicilio.tipoVia = tipoVia;
      }

      domicilio.nombreVia = this.formGroup.get('nombreVia')?.value;
      domicilio.numero = this.formGroup.get('numero')?.value;
      domicilio.bloque = this.formGroup.get('bloque')?.value;
      domicilio.escalera = this.formGroup.get('escalera')?.value;
      domicilio.piso = this.formGroup.get('piso')?.value;
      domicilio.puerta = this.formGroup.get('puerta')?.value;
      domicilio.otrosDatos = this.formGroup.get('otrosDatos')?.value ?? null;
      domicilio.coordenadas = this.formGroup.get('coordenadas')?.value ?? null;

      if (this.direccion) {
        // Editar se hace con un put de dirección
        this.attachedSrv.putDireccion(this.direccion.id, domicilio).subscribe({
          next: (response) => {
            this.snackBarSrv.showSnackBar(
              'La dirección se ha actualizado correctamente',
              'success'
            );
            this.dialogRef.close(true);
          },
          error: (err) => {
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al actualizar la dirección',
              'error'
            );
            console.log(err);
          },
        });
      } else {
        //Cear se hace con un put de adherido añadiendo la nueva dirección al array de adheridos
        this.adherido.domicilios.push(domicilio);
        this.attachedSrv
          .putAdherido(this.adherido.id!, this.adherido)
          .subscribe({
            next: (response) => {
              this.snackBarSrv.showSnackBar(
                'La dirección se ha creado correctamente',
                'success'
              );
              this.dialogRef.close(true);
            },
            error: (err) => {
              this.snackBarSrv.showSnackBar(
                'Ha ocurrido un error al crear la dirección',
                'error'
              );
              console.log(err);
            },
          });
      }
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  canAccessUpdate(): boolean {
    return this.authService.can('adheridos-direcciones', 'update');
  }

  cerrar(): void {
    this.dialogRef.close(null);
  }
  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }

  esES(): boolean {
    let esEs = false;
    if (this.formGroup.get('pais')?.value) {
      let pais = this.formGroup.get('pais')?.value;
      esEs = pais === 'ESPAÑA' || pais?.iso === 'ES' ? true : false;
      this.esShow = esEs;
    }

    let tipoDireccion = false;
    if (this.formGroup.get('tipoDireccion')?.value) {
      let id = this.formGroup.get('tipoDireccion')?.value;
      tipoDireccion = [1, 3].includes(id);
    }

    if (esEs || tipoDireccion) {
      this.formGroup.get('nombreVia')?.setValidators([Validators.required]);
      this.formGroup.get('nombreVia')?.updateValueAndValidity();
      this.formGroup.get('provincia')?.setValidators([Validators.required]);
      this.formGroup.get('codigoPostal')?.clearValidators();

      this.formGroup
        .get('codigoPostal')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.fiveDigitsPattern),
        ]);
      this.formGroup.get('codigoPostal')?.updateValueAndValidity();
      this.formGroup.get('municipio')?.setValidators([Validators.required]);
      this.formGroup.get('provincia')?.updateValueAndValidity();
      this.formGroup.get('municipio')?.updateValueAndValidity();

      this.formGroup.get('numero')?.setValidators([Validators.required]);
      this.formGroup.get('numero')?.updateValueAndValidity();
    } else {
      this.formGroup.get('codigoPostal')?.clearValidators();
      this.formGroup
        .get('codigoPostal')
        ?.setValidators([Validators.pattern(this.fiveDigitsPattern)]);

      this.formGroup.get('codigoPostal')?.updateValueAndValidity();

      this.formGroup.get('municipio')?.clearValidators();
      this.formGroup.get('provincia')?.clearValidators();
      this.formGroup.get('municipio')?.updateValueAndValidity();
      this.formGroup.get('provincia')?.updateValueAndValidity();

      this.formGroup.get('nombreVia')?.clearValidators();
      this.formGroup.get('nombreVia')?.updateValueAndValidity();

      this.formGroup.get('numero')?.clearValidators();
      this.formGroup.get('numero')?.updateValueAndValidity();
    }
    return esEs || tipoDireccion;
  }
  selectedModel(selectForm: any) {
    switch (selectForm) {
      case 'copiaDir': {
        this.cargarDireccion(this.formGroup.controls['copiaDir']?.value, false);
        break;
      }
      case 'pais': {
        if (this.formGroup.controls['pais']?.value == null) {
          this.formGroup.controls['provincia']?.setValue(null);
          this.formGroup.controls['codigoPostal']?.setValue(null);
          this.formGroup.controls['municipio']?.setValue(null);
          this.formGroup.get('provincia')?.enable();
        }
        this.selectProvince = null;
        break;
      }
      case 'provincia': {
        break;
      }
      case 'codigoPostal': {
        break;
      }
      case 'tipoVia': {
        break;
      }
      default: {
        break;
      }
    }
  }
  filterList(data: any, selectForm: any) {
    switch (selectForm) {
      case 'copiaDir': {
        return this.formGroup.get('copiaDir') as FormControl;
        break;
      }
      case 'pais': {
        return this.formGroup.get('pais') as FormControl;
        break;
      }
      case 'provincia': {
        return this.formGroup.get('provincia') as FormControl;
        break;
      }
      case 'codigoPostal': {
        return this.formGroup.get('codigoPostal') as FormControl;
        break;
      }
      case 'tipoVia': {
        return this.formGroup.get('tipoVia') as FormControl;
        break;
      }
      default: {
        return this.formGroup.get('') as FormControl;
        break;
      }
    }
  }
  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'copiaDir': {
        return this.formGroup.get('copiaDir') as FormControl;
        break;
      }
      case 'pais': {
        return this.formGroup.get('pais') as FormControl;
        break;
      }
      case 'provincia': {
        return this.formGroup.get('provincia') as FormControl;
        break;
      }
      case 'codigoPostal': {
        return this.formGroup.get('codigoPostal') as FormControl;
        break;
      }
      case 'tipoVia': {
        return this.formGroup.get('tipoVia') as FormControl;
        break;
      }
      default: {
        return this.formGroup.get('') as FormControl;
        break;
      }
    }
  }
  defaultMapResponse<T>(response: any): MappedResponse<T> {
    return <MappedResponse<T>>{
      totalElements: response.totalElements,
      recordsFiltered: response.totalPages,
      datos: response.content,
    };
  }
  controlHasErrorSelect(controlName: string) {
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  getProvinceByCp() {
    this.spinnerSrv.loadingShow();
    this.attachedSrv
      .getProvinciasByCp(this.pageNumber, this.pageSize)
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.provinceEs = data.datos;
            this.spinnerSrv.loadingHide();
          }
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }
}
