import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachedComponent } from '../pages/attached.component';
import { RouterModule } from '@angular/router';
import { GeTableComponent } from '../share/components/ge-table.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbPaginationModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { AttachedDetailComponent } from '../pages/components/attached-detail/attached-detail.component';
import { AttachedSearchComponent } from '../pages/components/attached-search/attached-search.component';
import { DetailAddressComponent } from '../pages/components/detail-address/detail-address.component';
import { DetailContactDataComponent } from '../pages/components/detail-contact-data/detail-contact-data.component';
import { DetailGeneralDataComponent } from '../pages/components/detail-general-data/detail-general-data.component';
import { DetailDocumentationComponent } from '../pages/components/detail-documentation/detail-documentation.component';
import { DetailPackagingCentersComponent } from '../pages/components/detail-packaging-centers/detail-packaging-centers.component';
import { PreliminaryRegistrationComponent } from '../pages/components/preliminary-registration/preliminary-registration.component';
import { MaterialModule } from './material.module';
import { AddressDialogComponent } from '../pages/components/address-dialog/address-dialog.component';
import { DetailPackagingOriginModalComponent } from '../pages/components/detail-packaging-origin-modal/detail-packaging-origin-modal.component';
import { GetAttributePipe } from '../share/components/pipe/get-attribute.pipe';
import { ContactDialogComponent } from '../pages/components/contact-dialog/contact-dialog.component';
import { GeSelectPaginationComponent } from '../share/components/ge-select-pagination/ge-select-pagination.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EmpusaSpinnerComponent } from '@empusa/empusa-core';
import { CustomDatePickerModule } from './custom-date-picker.module';
import { ApiAhderidos, ApiCore } from 'api-rest';

import { NewDocumentDialogComponent } from '../pages/components/new-document-dialog/new-document-dialog.component';
import { PackageDeclarationSearchComponent } from '../pages/package-declaration/package-declaration-search/package-declaration-search.component';
import { PackageDeclarationDetailComponent } from '../pages/package-declaration/package-declaration-detail/package-declaration-detail.component';
import { PackageGeneralDataComponent } from '../pages/package-declaration/package-general-data/package-general-data.component';
import { PackageCenterComponent } from '../pages/package-declaration/package-center/package-center.component';
import { PackageCenterPackagingComponent } from '../pages/package-declaration/package-center-packaging/package-center-packaging.component';
import { PackageCenterSectorsComponent } from '../pages/package-declaration/package-center-sectors/package-center-sectors.component';
import { PackageCenterCommercializationComponent } from '../pages/package-declaration/package-center-commercialization/package-center-commercialization.component';
import { CategoryCenterDialogComponent } from '../pages/package-declaration/category-center-dialog/category-center-dialog.component';
import { PackageDialogComponent } from '../pages/package-declaration/package-dialog/package-dialog.component';
import { SelectAttachedDialogComponent } from '../pages/package-declaration/select-attached-dialog/select-attached-dialog.component';
import { UploadCenterDialogComponent } from '../pages/package-declaration/upload-center-dialog/upload-center-dialog.component';
import { BillingReportDialogComponent } from '../pages/package-declaration/billing-report-dialog/billing-report-dialog.component';

import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { CommaDecimalPipe } from '../share/components/pipe/comma-decimal.pipe';
import { PercentNumberDirective } from '../share/components/utils/PercentNumber.directive';
import { NumericDecimalComaDirective } from '../share/components/utils/NumericDecimalComa.directive';
import { WasteForecastComponent } from '../pages/components/waste-forecast/waste-forecast.component';
import { ContributionsDisComponent } from '../pages/package-declaration/contributions-dis/contributions-dis.component';
import { ManagerSelectionDialogComponent } from '../pages/package-declaration/manager-selection-dialog/manager-selection-dialog.component';
import { ContributionsDisDialogComponent } from '../pages/package-declaration/contributions-dis-dialog/contributions-dis-dialog.component';
import { NumericPointCommaEsDirective } from '../share/components/utils/NumericPointCommaEs.directive';
import { FormatCurrencyPipe } from '../share/components/pipe/FormatCurrency.pipe';
import { ParseCurrencyPipe } from '../share/components/pipe/ParseCurrency.pipe';
import { NumericPointDirective } from '../share/components/utils/NumericPoint.directive';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { SnackbarService } from '../services/snackbar.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {EuroCurrencyDirective} from "../share/components/utils/EuroCurrency.directive";

export const explorerRoutes = [
  {
    path: '',
    component: AttachedComponent,
    children: [
      { path: 'search', component: AttachedSearchComponent },
      { path: 'detail/:id', component: AttachedDetailComponent },
      {
        path: 'packaging-declaration-search',
        component: PackageDeclarationSearchComponent,
      },
      {
        path: 'packaging-declaration-detail/:id',
        component: PackageDeclarationDetailComponent,
      },
      {
        path: 'contributions-dis-search',
        component: ContributionsDisComponent,
      },
      //{ path: 'packaging-declaration-detail', component: PackageDeclarationDetailComponent }
    ],
  },
];

export const ConfigFactory = () =>
  new ApiAhderidos.Configuration(<ApiAhderidos.ConfigurationParameters>{});
export const AdheridosBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE ADHERIDOS ' + environment.urlBackAdheridos);
  const basePath = environment.urlBackAdheridos;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const CoreBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE CORE ' + environment.urlBackCore);
  const basePath = environment.urlBackCore;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AttachedComponent,
    GeTableComponent,
    AttachedSearchComponent,
    AttachedDetailComponent,
    DetailAddressComponent,
    DetailContactDataComponent,
    DetailGeneralDataComponent,
    DetailDocumentationComponent,
    DetailPackagingCentersComponent,
    PreliminaryRegistrationComponent,
    AddressDialogComponent,
    DetailPackagingOriginModalComponent,
    ContactDialogComponent,
    GetAttributePipe,
    GeSelectPaginationComponent,
    NewDocumentDialogComponent,
    PackageDeclarationSearchComponent,
    PackageDeclarationDetailComponent,
    PackageGeneralDataComponent,
    PackageCenterComponent,
    PackageCenterPackagingComponent,
    PackageCenterSectorsComponent,
    PackageCenterCommercializationComponent,
    CategoryCenterDialogComponent,
    PackageDialogComponent,
    SelectAttachedDialogComponent,
    UploadCenterDialogComponent,
    WasteForecastComponent,
    BillingReportDialogComponent,
    CommaDecimalPipe,
    PercentNumberDirective,
    NumericDecimalComaDirective,
    ContributionsDisComponent,
    ContributionsDisDialogComponent,
    ManagerSelectionDialogComponent,
    NumericPointCommaEsDirective,
    FormatCurrencyPipe,
    ParseCurrencyPipe,
    NumericPointDirective,
    EuroCurrencyDirective
  ],
  imports: [
    CommonModule,
    MaterialModule,
    NgbTooltipModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgSelectModule,
    // ApiAhderidos.ApiModule.forRoot(ConfigFactory),
    RouterModule.forChild(explorerRoutes),
    EmpusaSpinnerComponent,
    CustomDatePickerModule,
    MatDialogModule,
    MatSnackBarModule,
  ],
  exports: [AttachedComponent, ParseCurrencyPipe, FormatCurrencyPipe],
  providers: [
    // {
    //   provide: ApiAhderidos.BASE_PATH,
    //   useFactory: AdheridosBasePathFactory,
    //   deps: ['environment']
    // },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: MAT_DIALOG_DATA, useValue: {} },
    SnackbarService,
  ],
})
export class AttachedAppHomeModule {
  constructor() {
    let compilationVersion = '0.0.1-R->04/10/23-12:14:49';
  }
}
