<div class="modal-content">
  <empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
  <div class="modal-header">
    <h3 class="modal-title font">Cambio de estado de adheridos</h3>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>

  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">

    <!-- Formulario dirección-->
    <form class="form-modal py-3" [formGroup]="formGroup">
      <div class="row-fields">
        <div class="field field-66">
          <label for="iban">Nuevo estado <span class="oblig">*</span></label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="estado" id="estado" placeholder="Estado">
              <mat-option *ngFor="let estado of estados" [value]="estado.id">
                {{estado.descripcion_corta}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controlHasError('estado', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
      </div>

    </form>

    <!-- FIN Formulario dirección -->
  </div>
  <div class="modal-footer actions">
    <div class="note">* Campos Obligatorios</div>
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
        aria-label="Cancelar">Cancelar</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="guardar()"
        aria-label="Guardar">Guardar</button>
    </div>
  </div>
</div>
