<div class="modal-content modal-envases">
  <empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
  <div class="modal-header">
    <h3 class="modal-title font">{{ titulo }}</h3>
    <button type="button" (click)="cerrar()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- Modal Body -->
  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
    <form class="form-modal py-3" [formGroup]="formGroup">

      <div class="row form">
        <div class="col-2-6" [ngClass]="{'col-full': isCategoriaSDDRCerrado()}">
          <div class="section-container">
            <span class="section-title" style="margin-top: 0px">Información de envases</span>
            <div class="section-line"></div>
          </div>

          <div class="row-fields">
            <div class="campo field field-100 campo-sin-validacion">
              <div style="display: flex; align-items: center;">
                <label for="denominacionLabel">Denominación </label>
                <div class="tool-help" matTooltip="Nombre o referencia interna del adherido para identificar al envase"
                     data-placement="top" title="">
                  <span>?</span>
                </div>
              </div>
              <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                <input formControlName="denominacion" id="denominacion" placeholder="Denominación" matInput type="text" maxlength="255">
                <mat-error *ngIf="controlHasError( 'denominacion', 'maxlength')">Máximo 255 caracteres</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row-fields" *ngIf="isCategoriaSDDRCerrado()">
            <div class="campo field field-40">
              <label for="tipoEnvase">Tipo de envase <span class="oblig">*</span></label>
              <div class="custom-mat-form-field-width">
                <lib-ge-select-pagination [endPointFilter]="tiposEnvasesEndpoint" (filteredList)="filterList($event)"
                                          [updateList]="updateList" [formControlNameSelect]="getFormControl('tipoEnvase')"
                                          [placeHolder]="'-- Tipo envase --'" [objQueryName]="'descripcion'"
                                          [customClass]="rightHolder(formGroup,'tipoEnvase')"
                                          [disabled]="flagDisabledForm">
                </lib-ge-select-pagination>
                <mat-error *ngIf="controlHasErrorSelect(formGroup,'tipoEnvase')" class="mat-error">
                  Campo obligatorio
                </mat-error>
              </div>
            </div>
            <div class="campo field field-40">
              <label for="tipologiaLabel">Tipología <span class="oblig">*</span></label>
              <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                <mat-select formControlName="tipologia" id="tipologia" placeholder="-- Tipología --">
                  <mat-option *ngFor="let tipo of filteredTipologiaArray" [value]="tipo.id">
                    {{ tipo.descripcion }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="controlHasError('tipologia', 'required')">Campo obligatorio</mat-error>
              </mat-form-field>
            </div>
            <div class="campo field field-20">
              <label for="peligrosidad">Peligrosidad <span class="oblig">*</span>
                <em *ngIf="formGroup.get('peligrosidad')?.value === true" class="fas fa-skull-crossbones"
                   style="color: red;" matTooltip="Envase de tipo peligroso"></em></label>
              <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                <mat-select formControlName="peligrosidad" id="peligrosidad" placeholder="-- Peligrosidad --">
                  <mat-option *ngFor="let option of peligrosidadOptions" [value]="option.value">
                    {{ option.label }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="controlHasError('peligrosidad', 'required')">Campo obligatorio</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row-fields" *ngIf="!isCategoriaSDDRCerrado()">
            <div class="campo field field-100 select-validacion">
              <label for="tipoEnvase">Tipo de envase <span class="oblig">*</span></label>
              <div class="custom-mat-form-field-width">
                <lib-ge-select-pagination [endPointFilter]="tiposEnvasesEndpoint" (filteredList)="filterList($event)"
                                          [updateList]="updateList"
                                          [formControlNameSelect]="getFormControl('tipoEnvase')"
                                          [placeHolder]="'-- Tipo envase --'" [objQueryName]="'descripcion'"
                                          [customClass]="rightHolder(formGroup,'tipoEnvase')"
                                          [disabled]="flagDisabledForm">
                </lib-ge-select-pagination>
                <mat-error *ngIf="controlHasErrorSelect(formGroup,'tipoEnvase')" class="mat-error">
                  Campo obligatorio
                </mat-error>
              </div>
            </div>
          </div>
          <div class="row-fields" *ngIf="!isCategoriaSDDRCerrado()">
            <div class="campo field field-75">
              <label for="tipologiaLabel">Tipología <span class="oblig">*</span></label>
              <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                <mat-select formControlName="tipologia" id="tipologia" placeholder="-- Tipología --">
                  <mat-option *ngFor="let tipo of filteredTipologiaArray" [value]="tipo.id">
                    {{ tipo.descripcion }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="controlHasError('tipologia', 'required')">Campo obligatorio</mat-error>
              </mat-form-field>
            </div>
            <div class="campo field field-25">
              <label for="peligrosidad">Peligrosidad <span class="oblig">*</span>
                <em *ngIf="formGroup.get('peligrosidad')?.value === true" class="fas fa-skull-crossbones"
                   style="color: red;" matTooltip="Envase de tipo peligroso"></em></label>
              <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                <mat-select formControlName="peligrosidad" id="peligrosidad" placeholder="Peligroso">
                  <mat-option *ngFor="let option of peligrosidadOptions" [value]="option.value">
                    {{ option.label }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="controlHasError('peligrosidad', 'required')">Campo obligatorio</mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row-fields">
            <div class="campo field field-33" *ngIf="!isCategoriaSDDRCerrado()">
              <label for="nUnidades">Nº de unidades (Ud) <span class="oblig">*</span></label>
              <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                <input formControlName="nUnidades" placeholder="" id="nUnidades" matInput type="text" appNumericPoint>
                <mat-error *ngIf="formGroup.get('nUnidades')?.touched">
                  <span *ngIf="controlHasError('nUnidades', 'required')">Campo obligatorio</span>
                </mat-error>
              </mat-form-field>
            </div>
            <div class="campo field" [ngClass]="isCategoriaSDDRCerrado() ? 'field-25' : 'field-33'">
              <div style="display: flex; align-items: center;">
                <label for="pesoUnitario">Peso unitario (Kg) </label>
                <div class="tool-help"
                     matTooltip="Campos calculados a partir del peso unitario/total de los materiales"
                     data-placement="top" title="">
                  <span>?</span>
                </div>
              </div>
              <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                <input formControlName="pesoUnitario" id="pesoUnitario" placeholder="(Kg)" matInput type="text">
              </mat-form-field>
            </div>
            <div class="campo field" [ngClass]="isCategoriaSDDRCerrado() ? 'field-25' : 'field-33'">
              <div style="display: flex; align-items: center;">
                <label for="pesoTotal">Peso total (Kg)</label>
                <div class="tool-help"
                     matTooltip="Campos calculados a partir del peso unitario/total de los materiales"
                     data-placement="top" title="">
                  <span>?</span>
                </div>
              </div>
              <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                <input formControlName="pesoTotal" id="pesoTotal" placeholder="(Kg)" matInput type="text">
              </mat-form-field>
            </div>
            <div class="campo field field-25" *ngIf="isCategoriaSDDRCerrado()">
              <div style="display: flex; align-items: center;">
                <label for="vidaUtil">Vida útil (años) <span class="oblig">*</span></label>
                <div class="tool-help" matTooltip="Vida útil estimada del envase" data-placement="top" title="">
                  <span>?</span>
                </div>
              </div>
              <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                <input formControlName="vidaUtil" id="vidaUtil" placeholder="" matInput type="number" [min]="0">
                <mat-error *ngIf="formGroup.get('vidaUtil')?.touched">
                  <span *ngIf="controlHasError('vidaUtil', 'pattern')">No se permiten decimales</span>
                  <span *ngIf="controlHasError('vidaUtil', 'required')">Campo obligatorio</span>
                  <span *ngIf="controlHasError('vidaUtil', 'min')">El valor mínimo es 1</span>
                  <span *ngIf="controlHasError('vidaUtil', 'max')">El valor máximo es 40</span>
                </mat-error>
              </mat-form-field>
            </div>

            <div class="campo field field-25" *ngIf="isCategoriaSDDRCerrado()">
              <div style="display: flex; align-items: center;">
                <label for="cicloReutilizacion">Ciclos de rotación/año <span *ngIf="campoOblig" class="oblig">*</span>
                </label>
                <div class="tool-help" matTooltip="Nº de ciclos previstos en un año de uso" data-placement="top"
                     title="">
                  <span>?</span>
                </div>
              </div>
              <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                <input formControlName="cicloReutilizacion" id="cicloReutilizacion" placeholder="" matInput
                       type="number"
                       [min]="0">
                <mat-error *ngIf="formGroup.get('cicloReutilizacion')?.touched">
                  <span *ngIf="controlHasError('cicloReutilizacion', 'pattern')">No se permiten decimales</span>
                  <span *ngIf="controlHasError('cicloReutilizacion', 'required')">Campo obligatorio</span>
                  <span *ngIf="controlHasError('cicloReutilizacion', 'min')">El valor mínimo es 1</span>
                  <span *ngIf="controlHasError('cicloReutilizacion', 'max')">El valor máximo es 100</span>
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="row-fields">
            <div class="campo field field-33" *ngIf="isCategoriaSDDRCerrado()">
              <div style="display: flex; align-items: center;">
                <label for="unidadesCirculacion">Unidades en circulación (Ud) <span class="oblig">*</span></label>
                <div class="tool-help"
                     matTooltip="Nº ud de envases contabilizados en el stock a 31 de diciembre año anterior al de los datos del año que se declara, (ver manual de instrucciones)"
                     data-placement="top" title="">
                  <span>?</span>
                </div>
              </div>
              <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                <input formControlName="unidadesCirculacion" placeholder="" id="unidadesCirculacion" matInput
                       type="text"
                       appNumericPoint>
              </mat-form-field>
            </div>
            <div class="campo field field-33" *ngIf="isCategoriaSDDRCerrado()">
              <div style="display: flex; align-items: center;">
                <label for="unidadesNuevas">Unidades nuevas (Ud) <span class="oblig">*</span></label>
                <div class="tool-help"
                     matTooltip="Nº Ud de envases que se incorporan nuevos al SDDR en el año de los datos que se declaran. Mismo dato de la declaración al Ministerio"
                     data-placement="top" title="">
                  <span>?</span>
                </div>
              </div>
              <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                <input formControlName="unidadesNuevas" placeholder="" id="unidadesNuevas" matInput type="text"
                       appNumericPoint>
              </mat-form-field>
            </div>
            <div class="campo field field-33" *ngIf="isCategoriaSDDRCerrado()">
              <label for="nUnidades">Total envases (Ud) </label>
              <div class="custom-mat-field">
                <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                  <input formControlName="totalUds" id="totalUds" matInput type="text">
                </mat-form-field>
              </div>
            </div>


            <div class="row-fields">
              <div class="campo field field-50" *ngIf="isCategoriaSDDRAbierto()">
                <div style="display: flex; align-items: center;">
                  <label for="vidaUtil">Vida útil (años) <span class="oblig">*</span></label>
                  <div class="tool-help" matTooltip="Vida útil estimada del envase" data-placement="top" title="">
                    <span>?</span>
                  </div>
                </div>
                <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                  <input formControlName="vidaUtil" id="vidaUtil" placeholder="" matInput type="number" [min]="0">
                  <mat-error *ngIf="formGroup.get('vidaUtil')?.touched">
                    <span *ngIf="controlHasError('vidaUtil', 'pattern')">No se permiten decimales</span>
                    <span *ngIf="controlHasError('vidaUtil', 'required')">Campo obligatorio</span>
                    <span *ngIf="controlHasError('vidaUtil', 'min')">El valor mínimo es 1</span>
                    <span *ngIf="controlHasError('vidaUtil', 'max')">El valor máximo es 40</span>
                  </mat-error>
                </mat-form-field>
              </div>

              <div class="campo field field-50" *ngIf="isCategoriaSDDRAbierto()">
                <div style="display: flex; align-items: center;">
                  <label for="cicloReutilizacion">Ciclos de rotación/año <span *ngIf="campoOblig" class="oblig">*</span>
                  </label>
                  <div class="tool-help" matTooltip="Nº de ciclos previstos en un año de uso" data-placement="top"
                       title="">
                    <span>?</span>
                  </div>
                </div>
                <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                  <input formControlName="cicloReutilizacion" id="cicloReutilizacion" placeholder="" matInput
                         type="number"
                         [min]="0">
                  <mat-error *ngIf="formGroup.get('cicloReutilizacion')?.touched">
                    <span *ngIf="controlHasError('cicloReutilizacion', 'pattern')">No se permiten decimales</span>
                    <span *ngIf="controlHasError('cicloReutilizacion', 'required')">Campo obligatorio</span>
                    <span *ngIf="controlHasError('cicloReutilizacion', 'min')">El valor mínimo es 1</span>
                    <span *ngIf="controlHasError('cicloReutilizacion', 'max')">El valor máximo es 100</span>
                  </mat-error>
                </mat-form-field>
              </div>


            </div>

          </div>
        </div>

        <div class="col-2-6" *ngIf="!isCategoriaSDDRCerrado()">
          <div class="section-container">
            <span class="section-title" style="margin-top: 0px">Información económica</span>
            <div class="section-line"></div>
          </div>

          <div class="container" [ngClass]="{'two-columns': isOrdinaria(), 'one-column': !isOrdinaria()}">
            <div class="column column-1">
              <div class="economico-title">Año en curso</div>

              <div *ngIf="isCategoriaSDDRAbierto()" class="campo-economico">
                <label for="tarifaDepositoAnnoCurso">Depósito unitario (€/Ud)</label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input formControlName="tarifaDepositoAnnoCurso" id="tarifaDepositoAnnoCurso" matInput type="text"
                           [min]="0">
                  </mat-form-field>
                </div>
              </div>
              <div class="campo-economico">
                <label *ngIf="isCategoriaSoloUso()" for="tarifaRapAnnoCurso">Tarifa RAP (€/Kg)</label>
                <label *ngIf="isCategoriaSDDRAbierto()" for="tarifaRapAnnoCurso">Tarifa RAP (€/Ud)</label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input formControlName="tarifaRapAnnoCurso" id="tarifaRapAnnoCurso" matInput type="text"
                           [min]="0">
                  </mat-form-field>
                </div>
              </div>
              <div *ngIf="isCategoriaSDDRAbierto()" class="campo-economico">
                <label for="despositoAnnoCurso">Contribución depósitos (€)</label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input formControlName="despositoAnnoCurso" id="despositoAnnoCurso" matInput type="text"
                           [min]="0">
                  </mat-form-field>
                </div>
              </div>
              <div class="campo-economico">
                <label for="contribucionAnnoCurso">Contribución RAP (€)</label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input formControlName="contribucionAnnoCurso" id="contribucionAnnoCurso" matInput type="text"
                           [min]="0">
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="column column-2" *ngIf="isOrdinaria()">
              <div class="economico-title">Año de los datos</div>

              <div *ngIf="isCategoriaSDDRAbierto()" class="campo-economico">
                <label for="tarifaDepositoAnnoAnt">Depósito unitario (€/Ud)</label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input formControlName="tarifaDepositoAnnoAnt" id="tarifaDepositoAnnoAnt" matInput type="text"
                           [min]="0">
                  </mat-form-field>
                </div>
              </div>
              <div class="campo-economico">
                <label *ngIf="isCategoriaSoloUso()" for="tarifaRapAnnoDatos">Tarifa RAP (€/Kg)</label>
                <label *ngIf="isCategoriaSDDRAbierto()" for="tarifaRapAnnoDatos">Tarifa RAP
                  (€/Ud)</label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input formControlName="tarifaRapAnnoAnt" id="tarifaRapAnnoDatos" matInput type="text" [min]="0">
                  </mat-form-field>
                </div>
              </div>
              <div *ngIf="isCategoriaSDDRAbierto()" class="campo-economico">
                <label for="depositoAnnoAnt">Contribución depósitos (€)</label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input formControlName="depositoAnnoAnt" id="depositoAnnoAnt" matInput type="text"
                           [min]="0">
                  </mat-form-field>
                </div>
              </div>
              <div class="campo-economico">
                <label for="contribucionAnnoAnt">Contribución RAP (€)</label>
                <div class="custom-mat-field">
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input formControlName="contribucionAnnoAnt" id="contribucionAnnoAnt" matInput type="text"
                           [min]="0">
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-sm-12">
            <div class="campo">
              <div class="section-container" style="margin-bottom: 5px !important;">
                <span class="section-title">Material de composición <span class="oblig">*</span></span>
                <div class="section-line"></div>
              </div>

              <form class="form-search py-3" [formGroup]="materialFormGroup">
                <div class="row form">
                  <div class="col-sm flex-grow-3" style="max-width: 40%; min-width: 40%;">
                    <label for="material">Material de composición <span class="oblig">*</span></label>
                    <div class="custom-mat-form-field-width">
                      <lib-ge-select-pagination class="custom-mat-form-field-width"
                                                [endPointFilter]="composicionMaterialEndpoint"
                                                (filteredList)="filterList($event)" [updateList]="updateList"
                                                [formControlNameSelect]="getFormControl('composicionMaterial')"
                                                [placeHolder]="'Material'"
                                                [objQueryName]="'descripcion'"
                                                [customClass]="rightHolder(materialFormGroup,'composicionMaterial')"
                                                [disabled]="flagDisabledForm">
                      </lib-ge-select-pagination>
                    </div>
                  </div>
                  <div class="col-sm flex-grow-0">
                    <label for="pesoMaterial">Peso unitario (Kg) <span class="oblig">*</span></label>
                    <div class="custom-mat-field">
                      <mat-form-field appearance="outline" class="custom-mat-field-select">
                        <input formControlName="pesoUnitario" placeholder="(Kg)" id="pesoUnitario" matInput type="text"
                               appNumericPointCommaEs>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-sm flex-grow-0">
                    <label for="pesoTotalMaterial">Peso total (Kg) <span class="oblig">*</span></label>
                    <div class="custom-mat-field">
                      <mat-form-field appearance="outline" class="custom-mat-field-select">
                        <input formControlName="pesoTotal" id="pesoTotal" placeholder="(Kg)" matInput type="text"
                               appNumericPointCommaEs>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-sm flex-grow-0">
                    <label for="recibidoMaterial">% Reciclado <span class="oblig">*</span></label>
                    <div class="custom-mat-field">
                      <mat-form-field appearance="outline" class="custom-mat-field-select">
                        <input formControlName="porcReciclado" id="porcReciclado" placeholder="" matInput type="number"
                               min="0"
                               max="100">
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-sm flex-grow-1 pt-4" style="margin-top: 2px;">
                    <button *ngIf="canAccessEditAllGlobal()" type="button" (click)="addMaterial()"
                            class="btn btn-primary">Agregar
                    </button>
                  </div>
                </div>
              </form>

              <div class="row form">
                <div class="col-sm-12">
                  <lib-ge-table [headArray]="headArrayMaterial" [gridArray]="gridMaterial"
                                (actionButton)="actionMaterial($event)"></lib-ge-table>
                </div>
              </div>


            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="campo">
              <div class="section-container" style="margin-bottom: 5px !important;">
                <span class="section-title">Otros elementos del envase</span>
                <div class="section-line"></div>
              </div>

              <form class="form-search py-3" [formGroup]="otherElementFormGroup">
                <div class="row form">

                  <div class="col-sm flex-grow-0">
                    <label for="elem1">Tipo elemento</label>
                    <div class="custom-mat-field">
                      <mat-form-field appearance="outline" class="custom-mat-field-select-tipo-elemento">
                        <mat-select formControlName="tipoElemento" id="tipoElemento" placeholder="">
                          <mat-option *ngFor="let tipo of tipoElementoArray" [value]="tipo.id">
                            {{ tipo.descripcion }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div class="row form">
                  <div class="col-sm flex-grow-2" style="max-width: 40%; min-width: 40%;">
                    <label for="material">Material otro elemento <span class="oblig">*</span></label>
                    <div class="ccustom-mat-form-field-width">
                      <lib-ge-select-pagination class="custom-mat-form-field-width"
                                                [endPointFilter]="composicionMaterialEndpoint"
                                                (filteredList)="filterList($event)" [updateList]="updateList"
                                                [formControlNameSelect]="getFormControl('composicionMaterialOther')"
                                                [placeHolder]="'Material'"
                                                [objQueryName]="'descripcion'"
                                                [customClass]="rightHolder(otherElementFormGroup,'composicionMaterialOther')"
                                                [disabled]="flagDisabledForm">
                      </lib-ge-select-pagination>
                    </div>
                  </div>

                  <div class="col-sm flex-grow-0">
                    <label for="pesoMaterialOtros">Peso unitario (Kg) <span class="oblig">*</span></label>
                    <div class="custom-mat-field">
                      <mat-form-field appearance="outline" class="custom-mat-field-select">
                        <input formControlName="pesoUnitario" id="pesoUnitario" placeholder="(Kg)" matInput type="text"
                               appNumericPointCommaEs>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-sm flex-grow-0">
                    <label for="pesoTotalMaterialOtros">Peso Total (Kg) <span class="oblig">*</span></label>
                    <div class="custom-mat-field">
                      <mat-form-field appearance="outline" class="custom-mat-field-select">
                        <input formControlName="pesoTotal" id="pesoTotal" placeholder="(Kg)" matInput type="text"
                               appNumericPointCommaEs>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-sm flex-grow-0">
                    <label for="recibidoMaterialOtros">% Reciclado <span class="oblig">*</span></label>
                    <div class="custom-mat-field">
                      <mat-form-field appearance="outline" class="custom-mat-field-select">
                        <input formControlName="porcReciclado" id="porcReciclado" placeholder="" matInput type="number"
                               min="0"
                               max="100">
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-sm flex-grow-1 pt-4" style="margin-top: 2px;">
                    <button *ngIf="canAccessEditAllGlobal()" type="button" (click)="addOtrosMaterial()"
                            class="btn btn-primary">Agregar
                    </button>
                  </div>
                </div>
              </form>

              <div class="row form">
                <div class="col-sm-12">
                  <lib-ge-table [headArray]="headArrayOtherElement" [gridArray]="gridMaterialOtros"
                                (actionButton)="actionMaterialOtros($event)"></lib-ge-table>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="row" *ngIf="isCategoriaSDDRCerrado() && isOrdinaria()">
          <div class="col-sm-12">
            <div class="section-container">
              <span class="section-title">Información de envases que salen del circuito</span>
              <div class="section-line"></div>
            </div>

            <div class="row-fields">
              <div class="campo field field-33">
                <div style="display: flex; align-items: center;">
                  <label for="unidadesResiduos">Unidades residuos <span class="oblig">*</span></label>
                  <div class="tool-help"
                       matTooltip="Nº de unidades gestionadas como residuos e informadas con Documento de Identificación (DI) el año de los datos de la declaración. Si es declaración inicial no introducir el dato"
                       data-placement="top" title="">
                    <span>?</span>
                  </div>
                </div>
                <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                  <input formControlName="unidadesResiduos" placeholder="" id="unidadesResiduos" matInput type="text"
                         appNumericPoint>
                </mat-form-field>
              </div>

              <div class="campo field field-33">
                <div style="display: flex; align-items: center;">
                  <label for="unidadesNoRetornadas">Unidades no retornadas <span class="oblig">*</span></label>
                  <div class="tool-help"
                       matTooltip="Nº de unidades de envases perdidas (no retornadas por el usuario final) el año de los datos de la declaración. Si es declaración inicial no introducir el dato"
                       data-placement="top" title="">
                    <span>?</span>
                  </div>
                </div>
                <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                  <input formControlName="unidadesNoRetornadas" placeholder="" id="unidadesNoRetornadas" matInput
                         type="text"
                         appNumericPoint>
                </mat-form-field>
              </div>
              <div class="campo field field-33">
                <div style="display: flex; align-items: center;">
                  <label for="unidadesExportadas">Unidades exportadas <span class="oblig">*</span></label>
                  <div class="tool-help"
                       matTooltip="Nº de unidades de envases exportados el año de los datos de la declaración. Si es declaración inicial no introducir el dato"
                       data-placement="top" title="">
                    <span>?</span>
                  </div>
                </div>
                <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                  <input formControlName="unidadesExportadas" placeholder="" id="unidadesExportadas" matInput
                         type="text"
                         appNumericPoint>
                </mat-form-field>
              </div>
            </div>

            <div class="row-fields">
              <div class="campo field field-50">
                <div style="display: flex; align-items: center;">
                  <label for="unidadesEnvaseResiduo">Unidades envasado residuos <span class="oblig">*</span></label>
                  <div class="tool-help"
                       matTooltip="Nº de unidades de envases que se ha utilizado para envasar residuos u otros materiales y no vuelven al circuito, el año de los datos de la declaración. Si es declaración inicial no introducir el dato"
                       data-placement="top" title="">
                    <span>?</span>
                  </div>
                </div>
                <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                  <input formControlName="unidadesEnvaseResiduo" placeholder="" id="unidadesEnvaseResiduo" matInput
                         type="text" appNumericPoint>
                </mat-form-field>
              </div>

              <div class="campo field field-50">
                <label for="udsCirculacionInfo">Unidades en circulación</label>
                <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                  <input formControlName="udsCirculacionInfo" placeholder="" id="udsCirculacionInfo" matInput
                         type="text" appNumericPoint>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="isCategoriaSDDRAbierto()">
        <form class="form-search py-3" [formGroup]="certificadoSDDRAbiertoFormGroup">
          <div class="row form pt-3">
            <div>
              <button type="button" class="btn btn-outline-primary" style="height:auto" data-dismiss="modal"
                      (click)="adjuntarCertificado()"
                      aria-label="adjuntar">
                <em class="fas fa-paperclip mr-2"></em> Certificado envases reutilizables
              </button>
              <input type="file" #fileInputCertificado style="display:none;"
                     (change)="handleFileInputCertificado($event)"/>
              <div *ngIf="nombreArchivoCertificado" class="d-flex justi-end mt-3">
              <span class="text-muted padding-text-upd">{{ nombreArchivoCertificado }}
              </span>
                <mat-icon *ngIf="!this.nuevoEnvase && this.declaracionEnvase.docCertificadoReutilizables !== null"
                          (click)="descargar(this.declaracionEnvase.docCertificadoReutilizables)"
                          class="ml-2 color-custom pointer-cursor" matTooltip="Descargar">
                  download
                </mat-icon>
                <mat-icon *ngIf="nombreArchivoCertificado && this.titulo !== 'Edición de envases'"
                          class="ml-2 color-custom">check_circle
                </mat-icon>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="row" *ngIf="isCategoriaSDDRCerrado()">
        <div class="col-sm-12">
          <div class="campo">
            <div class="section-container">
              <span class="section-title">Acuerdos con clientes</span>
              <div class="section-line"></div>
            </div>

            <form class="form-search py-3" [formGroup]="acuerdosClientesFormGroup">
              <div class="row form pt-3">
                <div class="d-flex flex-column col-sm flex-grow-1">
                  <label for="tipoAcuerdoLabel">Tipo de acuerdo con clientes <span class="oblig">*</span></label>
                  <mat-radio-group formControlName="tipoAcuerdo" id="tipoAcuerdo">
                    <mat-radio-button [value]="true">Aplicación de un depósito</mat-radio-button>
                    <mat-radio-button [value]="false">Modelo Acuerdo de cesión</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="controlHasErrorAcuerdosConClientes('tipoAcuerdo', 'required')" class="mat-error">
                    Campo
                    obligatorio
                  </mat-error>
                </div>
              </div>
              <div class="row form pt-3">
                <div class="d-flex flex-column col-sm flex-grow-1"
                     *ngIf="acuerdosClientesFormGroup.get('tipoAcuerdo')?.value === true">
                  <div style="display: flex; align-items: center;">
                    <label for="importeDeposito">Importe de depósito (€/Ud) <span class="oblig">*</span></label>
                    <div class="tool-help"
                         matTooltip="Indique el depósito medio €/Ud aplicado"
                         data-placement="top" title="">
                      <span>?</span>
                    </div>
                  </div>
                  <mat-form-field appearance="outline" class="custom-mat-form-field-width">
                    <input formControlName="importeDeposito" id="importeDeposito" placeholder="(€/Ud)" matInput
                           type="text"
                           appNumericPointCommaEs>
                    <mat-error>
                      <span
                        *ngIf="controlHasErrorAcuerdosConClientes('importeDeposito', 'required')">Campo obligatorio</span>
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="d-flex flex-column col-sm flex-grow-1"
                     *ngIf="acuerdosClientesFormGroup.get('tipoAcuerdo')?.value === false">
                  <button type="button" class="btn btn-outline-primary" style="height:auto" data-dismiss="modal"
                          (click)="adjuntarAcuerdo()"
                          aria-label="adjuntar">
                    <em class="fas fa-paperclip mr-2"></em> Modelo Acuerdo Cesión
                  </button>
                  <input type="file" #fileInputAcuerdo style="display:none;"
                         (change)="handleFileInputAcuerdo($event)"/>
                  <div *ngIf="nombreArchivoAcuerdo" class="d-flex justi-end mt-3">
                    <span class="text-muted padding-text-upd">{{ nombreArchivoAcuerdo }} </span>
                    <mat-icon *ngIf="!this.nuevoEnvase && this.declaracionEnvase.docModeloCesion !== null"
                              (click)="descargar(this.declaracionEnvase.docModeloCesion)"
                              class="ml-2 color-custom pointer-cursor"
                              matTooltip="Descargar">
                      download
                    </mat-icon>
                    <mat-icon *ngIf="nombreArchivoAcuerdo && this.titulo !== 'Edición de envases'"
                              class="ml-2 color-custom">
                      check_circle
                    </mat-icon>
                  </div>
                </div>
                <div class="d-flex flex-column col-sm flex-grow-1">
                  <label for="fabricanteProductorLabel">¿Es fabricante de envases que asume rol de productor? <span
                    class="oblig">*</span></label>
                  <mat-radio-group formControlName="fabricanteProductor" id="fabricanteProductor">
                    <mat-radio-button [value]="true">Si</mat-radio-button>
                    <mat-radio-button [value]="false">No</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="controlHasErrorAcuerdosConClientes('fabricanteProductor', 'required')"
                             class="mat-error">
                    Campo obligatorio
                  </mat-error>
                </div>
                <div class="d-flex flex-column col-sm flex-grow-1">
                  <label for="prestadorServiciosLabel">¿Es prestador de servicios? <span
                    class="oblig">*</span></label>
                  <mat-radio-group formControlName="prestadorServicios" id="prestadorServicios">
                    <mat-radio-button [value]="true">Si</mat-radio-button>
                    <mat-radio-button [value]="false">No</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="controlHasErrorAcuerdosConClientes('prestadorServicios', 'required')"
                             class="mat-error">
                    Campo obligatorio
                  </mat-error>
                </div>
                <div class="d-flex flex-column col-sm flex-grow-1">
                  <button type="button" class="btn btn-outline-primary" style="height:auto" data-dismiss="modal"
                          (click)="adjuntarCertificado()"
                          aria-label="adjuntar">
                    <em class="fas fa-paperclip mr-2"></em> Certificado envases reutilizables
                  </button>
                  <input type="file" #fileInputCertificado style="display:none;"
                         (change)="handleFileInputCertificado($event)"/>
                  <div *ngIf="nombreArchivoCertificado" class="d-flex justi-end mt-3">
              <span class="text-muted padding-text-upd">{{ nombreArchivoCertificado }}
              </span>
                    <mat-icon *ngIf="!this.nuevoEnvase && this.declaracionEnvase.docCertificadoReutilizables !== null"
                              (click)="descargar(this.declaracionEnvase.docCertificadoReutilizables)"
                              class="ml-2 color-custom pointer-cursor" matTooltip="Descargar">
                      download
                    </mat-icon>
                    <mat-icon *ngIf="nombreArchivoCertificado && this.titulo !== 'Edición de envases'"
                              class="ml-2 color-custom">check_circle
                    </mat-icon>
                  </div>
                </div>
              </div>
              <div class="row form pt-3">
                <div class="d-flex flex-column col-sm flex-grow-1">
                  <label for="simboloSddrcerradoLabel">Certifica que los envases han sido puestos en el mercado con el
                    símbolo
                    SDDR Circuito Cerrado de Envalora <span class="oblig">*</span></label>
                  <mat-radio-group formControlName="simboloSddrcerrado" id="simboloSddrcerrado">
                    <mat-radio-button [value]="true">Si</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="controlHasErrorAcuerdosConClientes('simboloSddrcerrado', 'required')"
                             class="mat-error">
                    Campo obligatorio
                  </mat-error>
                </div>
                <div class="d-flex flex-column col-sm flex-grow-1">
                  <label for="envasesSddrcerradoLabel">Certifica que los envases forman parte del sistema cerrado
                    cumplen con requisitos de Envalora, etc <span class="oblig">*</span></label>
                  <mat-radio-group formControlName="envasesSddrcerrado" id="envasesSddrcerrado">
                    <mat-radio-button [value]="true">Si</mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="controlHasErrorAcuerdosConClientes('envasesSddrcerrado', 'required')"
                             class="mat-error">
                    Campo obligatorio
                  </mat-error>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </form>
  </div>
  <div class="modal-footer actions d-flex justify-content-end align-items-center">
    <div class="note mr-auto">* Campos Obligatorios</div>

    <form *ngIf="this.nuevoEnvase" class="form-search py-3 form-inline mr-20" [formGroup]="actionFormGroup">
      <div class="d-flex align-items-center">
        <mat-checkbox class="margin-left-10" formControlName="seguirAlta"></mat-checkbox>
        <label for="seguirAlta" class="ml-5 mb-0">Seguir dando de alta envases</label>
      </div>
    </form>

    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
              aria-label="Cancelar">Cancelar
      </button>
      <button *ngIf="canAccessEditAllGlobal()" type="button" class="btn btn-primary" data-dismiss="modal"
              (click)="guardar()" aria-label="Guardar">Guardar
      </button>
    </div>
  </div>

</div>
