import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpinnerService } from '../../../services/spinner.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '../../../services/snackbar.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { GestoresResiduosControllerService, GestoresResiduosDTO, SolicitudEnvaseControllerService, SolicitudEnvaseDTO, SolicitudEnvasePayloadDTO, SolicitudInformeRetiradaControllerService, SolicitudInformeRetiradaDTO, SolicitudRetiradaControllerService, SolicitudRetiradaDTO } from 'api-rest';
import { ParseCurrencyPipe } from '../../../share/pipe/ParseCurrency.pipe';
import { FormatCurrencyPipe } from '../../../share/pipe/FormatCurrency.pipe';
import moment from 'moment';

@Component({
  selector: 'open-sddr-open-sddr-remain-dialog',
  templateUrl: './open-sddr-remain-dialog.component.html',
  styleUrls: ['./open-sddr-remain-dialog.component.scss']
})
export class OpenSddrRemainDialogComponent implements OnInit {
  titulo: string = '';
  solicitud: any;
  solicitudId!: number;
  formGroup!: FormGroup;
  infoSolicitudForm!: FormGroup;
  listaGestores: GestoresResiduosDTO[] = [];
  solicitudRetiradaSel!: SolicitudRetiradaDTO | undefined;
  informeRetiradaSel!: SolicitudInformeRetiradaDTO;
  soloLectura!: boolean;

  constructor(
    public dialogRef: MatDialogRef<OpenSddrRemainDialogComponent>,
    public spinnerSrv: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private snackBarSrv: SnackbarService,
    @Inject('EmpusaAuthenticationService') private authService: EmpusaAuthenticationService,
    private gestoresResiduosCtrlSrv: GestoresResiduosControllerService,
    private solicitudRetiradaCtrlSrv: SolicitudRetiradaControllerService,
    private solicitudInformeRetiradaCtrlSrv: SolicitudInformeRetiradaControllerService,
    private parseCurrencyPipe: ParseCurrencyPipe,
    private formatCurrencyPipe: FormatCurrencyPipe,
    private solicitudEnvaseCtrlSrv: SolicitudEnvaseControllerService
  ) {
    this.solicitudId = this.data?.solicitudId;
    this.soloLectura = this.data?.soloLectura;
    this.formGroup = this.formBuilder.group({
      usuarioGestor: [{ value: null, disabled: this.soloLectura }, [Validators.required]],
      idRetirada: [{ value: null, disabled: this.soloLectura }, [Validators.required]],
      tipoEnvase: [{ value: null, disabled: true }],
      //subtipoEnvase: [{ value: null, disabled: true }],
      loteEnvase: [{ value: null, disabled: true }],
      reutilizarUd: [{ value: null, disabled: true }],
      reutilizarKg: [{ value: null, disabled: true }],
      unidades: [{ value: null, disabled: this.soloLectura }, {
        validators: Validators.compose([
          Validators.required,
          Validators.maxLength(13)
        ])
      }],
      importeTotal: [{ value: null, disabled: this.soloLectura }],
      acreditaInformar: [{ value: null, disabled: this.soloLectura }, [Validators.required]]
    });
    this.infoSolicitudForm = this.formBuilder.group({
      usuarioSolicitud: [{ value: null, disabled: true }],
      fechaSolicitud: [{ value: null, disabled: true }],
      estadoSolicitud: [{ value: null, disabled: true }],
    });
  }
  ngOnInit(): void {
    this.cargarCombos();

    if (this.solicitudId) {
      this.titulo = 'Detalle de solicitud de envase SDDR Abierto – ID: ' + this.solicitudId!;
      this.cargarSolicitudPermanecer(this.solicitudId);
    } else {
      this.titulo = 'Alta solicitud de envase SDDR Abierto';
    }
    this.valueChangeUnidades();
  }

  cargarCombos(): void {
    this.spinnerSrv.loadingShow();
    this.gestoresResiduosCtrlSrv.getByUser({ byUser: true }, { page: 0, size: 100 })
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          this.listaGestores = response.datos;
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }
  cargarSolicitudPermanecer(solicitudId: number): void {
    this.spinnerSrv.loadingShow();
    this.solicitudEnvaseCtrlSrv.getById2(solicitudId)
      .subscribe({
        next: (response: SolicitudEnvaseDTO) => {
          this.spinnerSrv.loadingHide();
          this.solicitud = response;
          this.cargarDatosSolicitudPermanecer(response);

        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'No se ha podido cargar la solicitud de envase SDDR CA.',
            'error'
          );

        },
      });
  }
  cargarDatosSolicitudPermanecer(solicitud: SolicitudEnvaseDTO): void {
    this.formGroup.patchValue({
      usuarioGestor: solicitud?.gestorResiduos?.id,
      idRetirada: solicitud?.solicitudRetirada?.id,
      unidades: this.formatCurrencyPipe.transform(solicitud?.unidades),
      acreditaInformar: solicitud?.informarCliente
    });

    let fecha = "";
    if (solicitud?.fechaAlta) {
      fecha = moment(new Date(solicitud?.fechaAlta)).format('DD/MM/YYYY');
    }
    this.infoSolicitudForm.patchValue({
      usuarioSolicitud: solicitud?.gestorResiduos?.razonSocial,
      fechaSolicitud: fecha,
      estadoSolicitud: solicitud?.estado?.descripcion
    });
    this.buscarSolicitudRetirada(false);

    this.formGroup.get('importeTotal')?.setValue(this.formatCurrencyPipe.transform(solicitud?.importeTotal));
  }
  cerrar(): void {
    this.spinnerSrv.loading = 0;
    this.dialogRef.close(null);
  }

  controlHasError(controlName: string, errorName: string, form: FormGroup) {
    return (
      form.controls[controlName]?.hasError(errorName) &&
      form.controls[controlName]?.touched
    );
  }

  buscarSolicitudRetirada(calcularImporte?: boolean): void {
    if (this.formGroup.get('idRetirada')?.value) {
      const id = Number(this.formGroup.get('idRetirada')?.value);
      this.spinnerSrv.loadingShow();
      this.solicitudRetiradaCtrlSrv.getById1(id)
        .subscribe({
          next: (solicitud: SolicitudRetiradaDTO) => {
            this.spinnerSrv.loadingHide();
            if (solicitud?.estado?.id === 4 || solicitud?.estado?.id === 5) {
              this.cargarSolicitudRetirada(solicitud);
              if (!this.soloLectura) {
                this.cargarInformeRetirada(solicitud);
              }
              this.solicitudRetiradaSel = solicitud;
              if (calcularImporte && !this.soloLectura) {
                //Si es solo lectura no se hacen los cálculos if(calcularImporte && !soloLectura)
                this.formGroup.get('importeTotal')?.setValue(this.formatCurrencyPipe.transform(this.calcularImporte()));
              }
            } else {
              this.solicitudRetiradaSel = undefined;
              this.snackBarSrv.showSnackBar(
                'La solicitud indicada debe estar en estado Aceptada o Prefacturada.',
                'error'
              );
            }

          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            let message = err.error?.arguments && err.error?.arguments.length > 0 ? err.error?.arguments[0] : 'No se ha podido encontrar la solicitud de retirada con ID ' + id;
            this.snackBarSrv.showSnackBar(message, 'error');

            this.solicitudRetiradaSel = undefined;
            this.formGroup.get('tipoEnvase')?.setValue('');
            //this.formGroup.get('subtipoEnvase')?.setValue('');
            this.formGroup.get('loteEnvase')?.setValue('');
            this.formGroup.get('reutilizarUd')?.setValue('');
            this.formGroup.get('reutilizarKg')?.setValue('');
            this.formGroup.get('importeTotal')?.setValue(undefined);
          },
        });
    }

  }
  cargarSolicitudRetirada(solicitud: SolicitudRetiradaDTO): void {
    this.formGroup.get('tipoEnvase')?.setValue(solicitud?.tipoEnvase?.descripcion);
    //this.formGroup.get('subtipoEnvase')?.setValue(solicitud?.subTipoEnvase?.descripcion);
    this.formGroup.get('loteEnvase')?.setValue(solicitud?.loteEnvase);
    this.formGroup.get('reutilizarUd')?.setValue(solicitud?.cantidadRetirarUd);
    this.formGroup.get('reutilizarKg')?.setValue(solicitud?.cantidadRetirarKg);
  }
  cargarInformeRetirada(solicitud: SolicitudRetiradaDTO): void {
    if (solicitud?.id) {
      this.spinnerSrv.loadingShow();
      this.solicitudInformeRetiradaCtrlSrv.findAll1({ solicitudRetirada: { id: solicitud?.id } }, { page: 0, size: 1 })
        .subscribe({
          next: (response: any) => {
            this.spinnerSrv.loadingHide();
            this.informeRetiradaSel = response?.datos[0]!;
          },
          error: (error: any) => {
            this.spinnerSrv.loadingHide();
          },
        });
    }
  }

  valueChangeUnidades() {
    this.formGroup.get('unidades')?.valueChanges.subscribe((val) => {
      this.formGroup.get('importeTotal')?.setValue(this.formatCurrencyPipe.transform(this.calcularImporte()));
    });
  }

  calcularImporte(): number {
    let importe = undefined;
    const unidades = this.parseCurrencyPipe.transform(this.formGroup.get('unidades')?.value);
    if (this.solicitudRetiradaSel?.tarifa && this.formGroup.get('unidades')?.value) {
      importe = unidades * this.solicitudRetiradaSel?.tarifa!;
    }
    return importe!;
  }

  filtroNumeros(event: any): void {
    switch (event.keyCode) {
      case 101: //e
      case 46: //.
        event.preventDefault();
        break;
    }
  }

  obtenerPayloadSolicitud(): SolicitudEnvasePayloadDTO {
    let payload: SolicitudEnvasePayloadDTO = {
      gestorResiduos: { id: this.formGroup.get('usuarioGestor')?.value },
      solicitudRetirada: { id: this.formGroup.get('idRetirada')?.value },
      unidades: this.parseCurrencyPipe.transform(this.formGroup.get('unidades')?.value),
      importeTotal: this.parseCurrencyPipe.transform(this.formGroup.get('importeTotal')?.value),
      informarCliente: this.formGroup.get('acreditaInformar')?.value
    }
    return payload;
  }
  guardar(): void {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) {
      this.snackBarSrv.showSnackBar(
        'Complete los campos obligatorios.',
        'error'
      );
    } else if (!this.retiradaImporteValido()) {
      this.snackBarSrv.showSnackBar(
        'Revise la solicitud de retirada y el importe total. Recuerde que debe pulsar en la lupa para buscar la Solicitud de Retirada y cargar sus datos.',
        'error'
      );
    }
    else {
      let request: any;
      if (this.solicitudId) {
        request = this.solicitudEnvaseCtrlSrv.editById3(this.solicitudId, this.obtenerPayloadSolicitud())
      } else {
        request = this.solicitudEnvaseCtrlSrv.create4(this.obtenerPayloadSolicitud());
      }
      this.spinnerSrv.loadingShow();
      request
        .subscribe({
          next: (value: any) => {
            this.spinnerSrv.loadingHide();
            this.dialogRef.close(true);
            this.snackBarSrv.showSnackBar(
              'Se ha guardado la solicitud de envase SDDR CA correctamente.',
              'success'
            );
          },
          error: (error: any) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al guardar la solicitud de envase SDDR CA.',
              'error'
            );
          },
        });
    }
  }
  guardarEnviar(): void {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) {
      this.snackBarSrv.showSnackBar(
        'Complete los campos obligatorios.',
        'error'
      );
    } else if (!this.retiradaImporteValido()) {
      this.snackBarSrv.showSnackBar(
        'Revise la solicitud de retirada y el importe total. Recuerde que debe pulsar en la lupa para buscar la Solicitud de Retirada y cargar sus datos.',
        'error'
      );
    }
    else {
      let request: any;
      if (this.solicitudId) {
        request = this.solicitudEnvaseCtrlSrv.editByIdAndSend1(this.solicitudId, this.obtenerPayloadSolicitud());
      } else {
        request = this.solicitudEnvaseCtrlSrv.saveAndSend1(this.obtenerPayloadSolicitud());
      }
      this.spinnerSrv.loadingShow();
      request
        .subscribe({
          next: (value: any) => {
            this.spinnerSrv.loadingHide();
            this.dialogRef.close(true);
            this.snackBarSrv.showSnackBar(
              'Se ha guardado y enviado la solicitud de envase SDDR CA correctamente.',
              'success'
            );
          },
          error: (error: any) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al guardar y enviar la solicitud de envase SDDR CA.',
              'error'
            );

          },
        });
    }
  }
  retiradaImporteValido(): boolean {
    let valid = false;
    if (this.solicitudRetiradaSel && this.parseCurrencyPipe.transform(this.formGroup.get('importeTotal')?.value)) {
      valid = true;
    }
    return valid;
  }
  canAccessGuardar(): boolean {
    return this.authService.can('sddr-ca-solicitudes-envase-detalle', 'access-b-guardar');
  }
  canAccessGuardarEnviar(): boolean {
    return this.authService.can('sddr-ca-solicitudes-envase-detalle', 'access-b-guardar-enviar');
  }
}
