import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CombosService } from '../../../services/combos.service';
import { ValidateService } from '../../../services/validate.service';
import { Combo } from '../../../models/combo';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import Swal from 'sweetalert2';
import {
  PuntoRecogidaControllerService,
  PtoRecogidaPayloadDTO,
  GestorPtoRecogidaControllerService,
  PtoRecogidaDTO,
  AcondicionamientoControllerService,
  SolicitudControllerService,
  PtoRecogidaCodigoControllerService,
  GestorPtoRecogidaCodigoControllerService,
  PtoRecogidaGestorControllerService,
} from 'api-rest';
import { DatePipe } from '@angular/common';
import { SolicitudCreatePayloadDTO } from '../../../../../../dist/api-rest/api-puntosrecogida/model/solicitudCreatePayloadDTO';
import { ParseCurrencyPipe } from '../../../share/components/pipe/ParseCurrency.pipe';
import { AcondicionamientoFilterDTO } from "../../../../../../dist/api-rest/api-puntosrecogida";

@Component({
  selector: 'app-collection-points-requests-registration',
  templateUrl: './collection-points-requests-registration.component.html',
  styleUrls: ['./collection-points-requests-registration.component.scss'],
})
export class CollectionPointsRequestsRegistrationComponent implements OnInit {
  dataForm: FormGroup;
  tiposDocumento: Combo[] = [];
  pageNumber: number = 0;
  pageSize: number = 100;

  paisesEndpoint = this.environment.urlBackCore + 'api/v1/core/countries';

  //codigosLerEndpoint = this.environment.urlBackCore + 'api/v1/core/codigo-ler';

  flagDisabledForm: boolean = false;
  updateList: boolean = false;

  comunidadArray: any[] = [];
  gestorArray: any[] = [];
  ptoArray: any[] = [];
  codigoLerArray: any[] = [];
  acondicionamientoArray: any[] = [];
  acondicionamientoRecogidaArray: any[] = [];
  selectPtoRecogida!: PtoRecogidaDTO;

  isCantidadSuministroRequired: boolean = false;
  isAcondicionamientoSolicitudRequired: boolean = false;

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    private validateSrv: ValidateService,
    private dialogRef: MatDialogRef<CollectionPointsRequestsRegistrationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private comboSrv: CombosService,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private puntoRecogidaControllerService: PuntoRecogidaControllerService,
    private datePipe: DatePipe,
    private acondicionamientoControllerService: AcondicionamientoControllerService,
    private solicitudControllerService: SolicitudControllerService,
    private parseCurrencyPipe: ParseCurrencyPipe,
    private gestorPtoRecogidaCodigoControllerService: GestorPtoRecogidaCodigoControllerService,
    private ptoRecogidaCodigoControllerService: PtoRecogidaCodigoControllerService,
    private ptoRecogidaGestorControllerService: PtoRecogidaGestorControllerService
  ) {
    this.ptoArray = this.data.ptoArray;
    this.gestorArray = this.data.gestorArray;

    this.dataForm = this.formBuilder.group({
      ptoRecogida: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      direccionPtoRecogida: [{ value: null, disabled: true }],
      contactoPtoRecogida: [{ value: null, disabled: true }],
      estadoSolicitud: [{ value: null, disabled: true }],
      ObservacionesPto: [{ value: null, disabled: false }, Validators.maxLength(255)],
      fechaSugerida: [{ value: null, disabled: false }],

      codigoLer: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      acondicionamiento: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      cantidad: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],

      cantidadSuministro: [{ value: null, disabled: false }],
      acondicionamientoSolicitud: [{ value: null, disabled: false }],
    });
  }

  ngOnInit(): void {
    if (this.ptoArray?.length == 1) {
      this.dataForm.get('ptoRecogida')?.setValue(this.ptoArray[0]);
      this.dataForm.get('ptoRecogida')?.disable();
      this.updateDataForm(this.ptoArray[0]);
    }
    this.comboAcondicionamiento();
    this.valueChangePto();
    this.valueChangeCodigoLerRecogida();

    this.tiposDocumento = this.comboSrv.getTiposDocumentoIdentidad();
    this.onChanges();
  }

  valueChangePto() {
    this.dataForm.get('ptoRecogida')?.valueChanges.subscribe((value) => {
      if (value) {
        this.updateDataForm(value);
      }
    });
  }
  valueChangeCodigoLerRecogida() {
    this.dataForm.get('codigoLer')?.valueChanges.subscribe((value) => {
      if (value) {
        this.comboAcondicionamientoByCodigoLer(value?.id);
        this.dataForm.get('acondicionamiento')?.reset();
      }
    });
  }

  onChanges(): void {
    this.dataForm.get('cantidadSuministro')?.valueChanges.subscribe(() => {
      this.updateValidators();
    });

    this.dataForm
      .get('acondicionamientoSolicitud')
      ?.valueChanges.subscribe(() => {
        this.updateValidators();
      });
  }

  updateValidators() {
    const cantidadControl = this.dataForm.get('cantidadSuministro');
    const acondicionamientoControl = this.dataForm.get(
      'acondicionamientoSolicitud'
    );

    const isCantidadNotEmpty = !!cantidadControl?.value;
    const isAcondicionamientoNotEmpty = !!acondicionamientoControl?.value;

    this.isCantidadSuministroRequired = isAcondicionamientoNotEmpty;
    this.isAcondicionamientoSolicitudRequired = isCantidadNotEmpty;

    if (this.isCantidadSuministroRequired) {
      cantidadControl?.setValidators([Validators.required]);
    } else {
      cantidadControl?.clearValidators();
    }

    if (this.isAcondicionamientoSolicitudRequired) {
      acondicionamientoControl?.setValidators([Validators.required]);
    } else {
      acondicionamientoControl?.clearValidators();
    }

    cantidadControl?.updateValueAndValidity({ emitEvent: false });
    acondicionamientoControl?.updateValueAndValidity({ emitEvent: false });
  }

  comboCodigoLer(data?: any) {
    this.ptoRecogidaGestorControllerService
      .findAllPtoRecogidaGestor(
        { ptoRecogida: { id: data?.id } },
        { page: 0, size: 100 }
      )
      .subscribe({
        next: (response: any) => {
          const uniqueCodigoLerArray = response?.datos
            .map((item: any) => item.codigoLer)
            .filter(
              (value: any, index: number, self: any[]) =>
                index === self.findIndex((t) => t.id === value.id)
            );

          this.codigoLerArray = uniqueCodigoLerArray;
        },
        error: (err) => { },
      });
  }

  updateDataForm(data: PtoRecogidaDTO | any) {
    this.comboCodigoLer(data);

    const dirFisica = data?.domicilios?.find(
      (domicilio: any) => domicilio.tipoDireccion?.id === 5
    );
    let direccionCompleta = '';

    if (dirFisica) {
      const { tipoVia, nombreVia, numero, municipio, provincia, codigoPostal } =
        dirFisica;

      direccionCompleta = [
        tipoVia,
        nombreVia,
        numero,
        municipio,
        provincia?.nombre,
        codigoPostal?.codigoPostal,
      ]
        .filter(Boolean)
        .join(', ');
    }

    const contactoGestion = data?.contactos?.find(
      (contacto: any) => contacto.tipo_contacto?.id === 1
    );

    const prefijoGestion = contactoGestion?.codigoPais?.prefijoNombre || null;
    const telefonoGestion = contactoGestion?.numero_telefono || null;
    const emailGestion = contactoGestion?.email || null;

    const datos = [prefijoGestion, telefonoGestion, emailGestion].filter(
      Boolean
    );

    const cadenaDatos = datos.join(', ');

    this.dataForm.get('direccionPtoRecogida')?.setValue(direccionCompleta);
    this.dataForm.get('contactoPtoRecogida')?.setValue(cadenaDatos);

    this.dataForm.get('estadoSolicitud')?.setValue(data?.estado?.id);
  }

  public filterList(data: any) { }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'codigoLer': {
        return this.dataForm.get('codigoLer') as FormControl;
        break;
      }
      default: {
        return this.dataForm.get('') as FormControl;
        break;
      }
    }
  }

  saveData() {
    this.dataForm.markAllAsTouched();
    this.dataForm.updateValueAndValidity();

    if (this.dataForm.valid) {
      const dataRequests: SolicitudCreatePayloadDTO = {
        ptoRecogida: { id: this.dataForm.get('ptoRecogida')?.value?.id },
        estado: { id: this.dataForm.get('estadoSolicitud')?.value },
        observacionesPtoRecogida: this.dataForm.get('ObservacionesPto')?.value,
        codigoLer: { id: this.dataForm.get('codigoLer')?.value?.id },
        acondicionamientoRecogida: {
          id: this.dataForm.get('acondicionamiento')?.value?.id,
        },
        cantidadRecogida: this.parseCurrencyPipe.transform(
          this.dataForm.get('cantidad')?.value
        ),
        cantidadSuministro: this.parseCurrencyPipe.transform(
          this.dataForm.get('cantidadSuministro')?.value
        ),
        acondicionamientoSuministro: {
          id: this.dataForm.get('acondicionamientoSolicitud')?.value?.id,
        },
      };
   
      if (this.dataForm.get('fechaSugerida')?.value){
        dataRequests.fechaSugeridaRecogida = String(
          this.datePipe.transform(
            this.dataForm.get('fechaSugerida')?.value,
            'yyyy-MM-dd'
          )
        );
      }
      console.log(dataRequests);
     
      this.performSave(dataRequests);
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  comboAcondicionamiento() {
    let filter: AcondicionamientoFilterDTO = {};
    this.acondicionamientoControllerService.findAll2(filter,
      {
        sort: ['orden,asc']
      })
      .subscribe({
        next: (res) => {
          this.spinnerSrv.loadingHide();
          if (res) {
            this.acondicionamientoArray = res?.datos ?? [];
            ;
          }
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  comboAcondicionamientoByCodigoLer(codigoLerId?: any) {
    this.ptoRecogidaCodigoControllerService
      .findAllPtoRecogidaCodigo(
        {
          ptoRecogida: { id: this.dataForm.get('ptoRecogida')?.value?.id },
          codigoLer: { id: codigoLerId },
        },
        { page: 0, size: 100 }
      )
      .subscribe({
        next: (response: any) => {
          this.acondicionamientoRecogidaArray = response?.datos?.map(
            (item: any) => item.acondicionamiento
          );
        },
        error: (err) => { },
      });
  }

  selPaginatorHasError(controlName: string) {
    if (
      (this.dataForm.controls[controlName]?.touched ||
        this.dataForm.controls[controlName]?.dirty) &&
      this.dataForm.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  performSave(dataRequests: any) {
    this.spinnerSrv.loadingShow();
    this.solicitudControllerService.createSolicitud(dataRequests).subscribe({
      next: (value: any) => {
        this.spinnerSrv.loadingHide();
        this.dialogRef.close(true);
        this.snackBarSrv.showSnackBar(
          'Alta de solicitud realizada correctamente',
          'success'
        );
      },
      error: (error: any) => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.dataForm.controls[controlName].hasError(errorName) &&
      this.dataForm.controls[controlName].touched
    );
  }

  controlHasErrorSelect(controlName: string) {
    if (
      (this.dataForm.controls[controlName]?.touched ||
        this.dataForm.controls[controlName]?.dirty) &&
      this.dataForm.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.dataForm.controls[controlName]?.touched ||
        this.dataForm.controls[controlName]?.dirty) &&
      this.dataForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  showClearButton(formName: string): boolean {
    return this.dataForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.dataForm.get(formName)?.setValue(null);
  }

  getLerDescription(ler: any) {
    return ler?.codigoLer?.tipoCodigo + ' - ' + ler?.codigoLer?.descripcion;
  }

  getOperacionDescription(op: any) {
    return op?.descripcion;
  }

}
