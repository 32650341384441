import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { SpinnerService } from '../../../services/spinner.service';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { OpenSddrContributionsDisDialogComponent } from '../open-sddr-contributions-dis-dialog/open-sddr-contributions-dis-dialog.component';

import {
  SolicitudNimaGestoresDTO,
  AportacionDIEstadoControllerService,
  AportacionDIEstadoDTO,
  AportacionDIPoseedorControllerService,
  AportacionDisddrcaControllerService,
  AportacionDISDDRCAFilterDTO,
  PageDTOAportacionDISDDRCADTO,
} from 'api-rest';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import moment from 'moment';
import {
  OpenSddrContributionsDisSelectionDialogComponent
} from "../open-sddr-contributions-dis-selection-dialog/open-sddr-contributions-dis-selection-dialog.component";

@Component({
  selector: 'app-open-sddr-contributions-dis',
  templateUrl: './open-sddr-contributions-dis.component.html',
  styleUrls: ['./open-sddr-contributions-dis.component.scss'],
})
export class OpenSddrContributionsDisComponent implements OnInit {
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  totalElements: number = 0;
  totalSearch: number = 0;
  nimaGestorArray: any[] = [];
  gestorArray: any[] = [];
  estadoArray: any[] = [];
  headArray: any = [
    {
      Head: 'ID. Gestor',
      FieldName: 'gestorResiduo',
      Attribute: 'id'
    },
    {
      Head: 'Razon social',
      FieldName: 'gestorResiduo',
      Attribute: 'razonSocial',
    },
    {
      Head: 'Documento',
      FieldName: 'gestorResiduo',
      Attribute: 'codigoDocumento',
    },
    {
      Head: 'NIMA del gestor',
      FieldName: 'gestorResiduo',
      Attribute: 'nima',
    },
    {
      Head: 'Fecha de aceptación',
      FieldName: 'fechaMAceptacion',
    },
    {
      Head: 'DI',
      FieldName: 'documentoIdentificacion',
    },
    {
      Head: 'Código LER y descripción de ENVALORA',
      FieldName: 'descripcionMCodLer',
      renderValue: (item: any) => `${item?.codigoLer?.tipoCodigo} ${item?.codigoLer?.descripcion}`,
    },
    {
      Head: 'Cantidad neta gestionada (Kg)',
      FieldName: 'cantidadGestionada',
    },
    {
      Head: 'Estado ',
      FieldName: 'estado',
      Attribute: 'descripcionCorta',
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      check: this.canViewResource('SDDR-CA-ENV-GEST'),
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Detalle ',
          show: {
            params: 'canAccessView',
            accion: 'view',
          },
        },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Eliminar',
          show: {
            params: 'canAccessDelete',
            accion: 'delete',
          },
        },
        {
          nameButton: 'menu',
          array: [
            {
              nameButton: 'send',
              name: 'Enviar aportación ',
              show: {
                params: 'canAccessSend',
                accion: 'send',
              },
            },
            {
              nameButton: 'accept',
              name: 'Aceptar aportación',
              show: { params: 'canAccessAccept', accion: 'accept' },
            },
            {
              nameButton: 'cancel',
              name: 'Cancelar aportación',
              show: { params: 'canAccessCancel', accion: 'cancel' },
            },
          ],
        },
      ],
      width: '15',
      permanent: true,
    },
  ];
  gridArray: any[] = [];

  searchForm: FormGroup;

  updateList: boolean = false;

  listaGestores = this.environment.urlBackCore + 'api/v1/poseedores/gestores-residuos';
  listaMisGestores = this.environment.urlBackCore + 'api/v1/poseedores/gestores-residuos/byUser';

  // Gestores completados
  defaultGestoresParameters = { 'gestoresResiduosEstado.id': 2 };

  rolesGestor: any[] = [];

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private downloadService: DownloadService,
    private snackBarSrv: SnackbarService,
    private aportacionDisddrcaCtrSrv: AportacionDisddrcaControllerService,
    private aportacionesDiPoseedorCtrSrv: AportacionDIPoseedorControllerService,
    @Inject('EmpusaAuthenticationService')
    public authService: EmpusaAuthenticationService | any,
    private aportacionDIEstadoCtrSrv: AportacionDIEstadoControllerService,
  ) {
    const fechaInicialDesde = new Date(new Date().getFullYear(), 0, 1);
    const fechaActual = new Date();

    const roleEntities = (this.authService.user as any).roleEntities;
    this.rolesGestor = roleEntities?.filter(
      (roleEntity: any) => roleEntity.role == 'PO-GESTOR'
    );

    this.searchForm = this.formBuilder.group({
      gestor: [{ value: null, disabled: false }],
      idGestor: [{ value: null, disabled: false }, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      codigo_documento: [{ value: null, disabled: false }],
      razon_social: [{ value: null, disabled: false }],
      nima_gestor: [{ value: null, disabled: false }],
      fecha_desde: [
        { value: fechaInicialDesde, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      fecha_hasta: [
        { value: fechaActual, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      doc_identificacion: [{ value: null, disabled: false }],
      estado: [{ value: null, disabled: false }]
    });

    if (this.rolesGestor?.length > 0) {
      this.buscarNimaGestor();
    }
  }

  ngOnInit() {
    this.comboEstados();
  }

  checked(event: any) {
    if (event.estado.id != 3) {
      this.snackBarSrv.showSnackBar(
        'Solo se permitirá autofacturar DIs en estado Aceptada',
        'error'
      );
      event.checked = null;
    }
  }

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.searchData();
    }
  }

  private getBackendFilter() {
    let fechaBusqueda: Array<string> = [];
    let fechaDesde = this.searchForm.get('fecha_desde')?.value;
    let fechaHasta = this.searchForm.get('fecha_hasta')?.value;

    if (fechaDesde) {
      let formatDateDesde = moment(new Date(fechaDesde)).format('YYYY-MM-DD');
      formatDateDesde = `gte(${formatDateDesde})`;
      fechaBusqueda.push(formatDateDesde);
    }
    if (fechaHasta) {
      let formatDateHasta = moment(new Date(fechaHasta)).format('YYYY-MM-DD');
      if (fechaDesde) {
        formatDateHasta = `and(lte(${formatDateHasta}))`;
      } else {
        formatDateHasta = `lte(${formatDateHasta})`;
      }
      fechaBusqueda.push(formatDateHasta);
    }

    const selectGestores = this.searchForm.get('gestor')?.value;

    let backendFilter: AportacionDISDDRCAFilterDTO = {
      gestorResiduo: {
        id: [selectGestores?.map((objeto: any) =>
          objeto.id !== undefined ? objeto.id : objeto
        ),
        this.searchForm.get('idGestor')?.value
        ],
        nima: this.searchForm.get('nima_gestor')?.value,
        codigoDocumento: this.searchForm.get('codigo_documento')?.value
      },
      razonSocialGestor: this.searchForm.get('razon_social')?.value,
      fechaAceptacion: fechaBusqueda,
      documentoIdentificacion: this.searchForm.get('doc_identificacion')?.value,
      estado: this.searchForm.get('estado')?.value,
    };
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;
    return { backendFilter, page, size: this.pageSize };
  }

  exportarExcel() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.aportacionDisddrcaCtrSrv
      .exportCSV5(backendFilter, 'response')
      .subscribe((res: any) => {
        const filename = this.downloadService.getFileName(
          res.headers,
          'ExportacionAportacionesDi.csv'
        );
        this.downloadService.downloadCSV(res.body!, filename!);
      });
  }

  getGestorDescription(obj: any) {
    return obj?.razonSocial;
  }

  getGestorObjectQuery(searchterm: string) {
    return {
      descripcion: searchterm,
      'estado.id': 4,
    };
  }

  clearSearcher() {
    this.searchForm.reset();
    this.flagSearch = false;
    this.gridArray = [];
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.searchForm.controls[controlName].hasError(errorName) &&
      this.searchForm.controls[controlName].touched
    );
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'gestor': {
        return this.searchForm.get('gestor') as FormControl;
      }
      default: {
        return this.searchForm.get('') as FormControl;
      }
    }
  }

  searchData() {
    if (this.searchForm.invalid) {
      this.searchForm.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
      return;
    }
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.aportacionDisddrcaCtrSrv
      ?.findAll6(backendFilter, {
        page: page,
        size: size,
        sort: ['id,desc'],
      })
      .subscribe({
        next: (data: PageDTOAportacionDISDDRCADTO) => {
          if (data) {
            this.spinnerSrv.loadingHide();
            this.flagSearch = data.datos?.length != 0;
            this.gridArray = this.canAccessAll(data.datos);
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.totalSearch = this.gridArray.length;
          }
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  action(element: any) {
    if (element.nameButton == 'view') {
      this.openModalContributions(element);
    } else if (element.nameButton == 'delete') {
      this.confirmacionEliminar(element);
    } else if (element.nameButton == 'send') {
      this.spinnerSrv.loadingShow();
      this.aportacionDisddrcaCtrSrv
        .enviarAportacion(element.id)
        .subscribe({
          next: () => {
            this.spinnerSrv.loadingHide();
            this.searchData();
            this.snackBarSrv.showSnackBar(
              'Se ha enviado la aportación correctamente',
              'success'
            );
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            console.log(err);
          },
        });
    } else if (element.nameButton == 'accept') {
      this.spinnerSrv.loadingShow();
      this.aportacionDisddrcaCtrSrv
        .aceptarAportacion(element.id)
        .subscribe({
          next: () => {
            this.spinnerSrv.loadingHide();
            this.searchData();
            this.snackBarSrv.showSnackBar(
              'Se ha aceptado la aportación correctamente',
              'success'
            );
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            console.log(err);
          },
        });
    } else if (element.nameButton == 'cancel') {
      this.spinnerSrv.loadingShow();
      this.aportacionDisddrcaCtrSrv
        .cancelarAportacion(element.id)
        .subscribe({
          next: () => {
            this.spinnerSrv.loadingHide();
            this.searchData();
            this.snackBarSrv.showSnackBar(
              'Se ha cancelado la aportación correctamente',
              'success'
            );
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            console.log(err);
          },
        });
    }
  }

  confirmacionEliminar(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar la aportación Di?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarDis(element);
      }
    });
  }

  eliminarDis(element?: any) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.aportacionDisddrcaCtrSrv
        .deleteById2(element.id)
        .subscribe({
          next: (value: any) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              `La aportación Di se ha eliminado correctamente`,
              'success'
            );
            this.gridArray = this.gridArray.filter(
              (objeto) => objeto.id !== element.id
            );
            this.flagSearch = this.gridArray.length != 0;
          },
          error: (err: { message: any }) => {
            this.spinnerSrv.loadingHide();
          },
        });
    }
  }

  comboEstados() {
    this.aportacionDIEstadoCtrSrv.findAll14().subscribe({
      next: (data: Array<AportacionDIEstadoDTO>) => {
        this.estadoArray = data!;
      },
      error: () => { },
    });
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.searchForm.controls[controlName]?.touched ||
        this.searchForm.controls[controlName]?.dirty) &&
      this.searchForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  openHolderManagerSelection() {
    const dialogRef = this.dialog.open(OpenSddrContributionsDisSelectionDialogComponent, {
      width: '950px',
      maxWidth: '98%',
      maxHeight: '95vh',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.openModalContributions(result);
      }
    });
  }

  buscarNimaGestor() {
    const filtroNimaGestor: SolicitudNimaGestoresDTO = {
      fechaAceptacion: this.searchForm.get('fechaAceptacion')?.value,
    };
    this.aportacionesDiPoseedorCtrSrv
      .nimaGestores(filtroNimaGestor)
      .subscribe((res: any) => (this.nimaGestorArray = res));
  }

  openModalContributions(element?: any) {
    if (!element) {
      if (this.rolesGestor?.length > 0) {
        if (this.nimaGestorArray?.length === 0) {
          this.snackBarSrv.showSnackBar(
            'No puede crear una Aportación DI porque no tiene ningún Gestor en estado Completado.',
            'error'
          );
        }
        if (this.nimaGestorArray?.length > 1) {
          this.openHolderManagerSelection();
        } else {
          this.openModalContributions({
            gestorResiduo: {
              id: this.nimaGestorArray[0].id,
            },
            estado: { id: 1, descripcion: 'Abierta' },
          });
        }
      }
    } else {
      const dialogRef = this.dialog.open(
        OpenSddrContributionsDisDialogComponent,
        {
          width: '950px',
          maxWidth: '98%',
          maxHeight: '95vh',
          data: element,
          disableClose: true,
        }
      );

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.searchData();
        }
      });
    }
  }

  canViewResource(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

  canAccessAll(list: any) {
    const resultView = this.authService.can('gestores-acuerdo-di', 'view');
    const resultDelete = this.authService.can('gestores-acuerdo-di', 'delete');

    const resultSend = this.authService.can('gestores-acuerdo-di', 'update-send');
    const resultAccept = this.authService.can('gestores-acuerdo-di', 'update-accept');
    const resultCancel = this.authService.can('gestores-acuerdo-di', 'update-cancel');

    const listPermission = list?.map((objeto: any) => {
      const estado1 = objeto.estado && objeto.estado.id === 1;
      const estado2 = objeto.estado && objeto.estado.id === 2;
      objeto.fechaMAceptacion =
        objeto.fechaAceptacion != null
          ? moment(new Date(objeto.fechaAceptacion)).format('DD/MM/YYYY')
          : null;
      let descripcionMCodLer =
        objeto.codigoLer.tipoCodigo + ' ' + objeto.codigoLer.descripcion;

      return {
        ...objeto,
        canAccessView: resultView,
        canAccessDelete:
          resultDelete &&
          estado1 &&
          objeto.creadoPor == this.authService.getCurrentUserMail(),
        canAccessSend:
          resultSend &&
          estado1 &&
          objeto.creadoPor == this.authService.getCurrentUserMail(),
        canAccessAccept: resultAccept && estado2,
        canAccessCancel: resultCancel && estado2,
        descripcionMCodLer: descripcionMCodLer,
      };
    });

    return listPermission;
  }

  crearAutoFactura() {
    const list = this.gridArray.filter((x) => {
      return x.checked === true;
    });

    if (list.length == 0) {
      this.snackBarSrv.showSnackBar(
        `Debe seleccionar al menos una Aportación DI para generar una autofactura`,
        'error'
      );
      return;
    }
    this.spinnerSrv.loadingShow();
    this.aportacionDisddrcaCtrSrv
      .generarAutoFactura(list)
      .subscribe({
        next: () => {
          this.snackBarSrv.showSnackBar(
            `La autofactura se ha creado correctamente`,
            'success'
          );
          this.searchData();
          this.spinnerSrv.loadingHide();
        },
        error: (err: any) => {
          console.log(err);
          this.spinnerSrv.loadingHide();
        },
      });
  }

  canAccessNuevoDis(): boolean {
    return this.authService.can('sddr-ca-di', 'create') || this.canViewResource("PO-GESTOR");
  }

  canAccessCrearAutoFactura(): boolean {
    return this.authService.can('sddr-ca-di', 'access-autofactura') || this.canViewResource("SDDR-CA-ENV-GEST");
  }

  onlyNumberKey(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Control'];

    if (event.ctrlKey && (event.key === 'c' || event.key === 'v')) return;

    // Permitir números y teclas adicionales definidas
    if (!/^[0-9]$/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
