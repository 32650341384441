import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { Adherido } from '../../../models/adherido';
import { AttachedService } from '../../../services/attached.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { Combo } from '../../../models/combo';
import { CombosService } from '../../../services/combos.service';
import { ValidateService } from '../../../services/validate.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import {
  CentrosEnvasadoControllerService,
  KeycloakUserFilterDTO,
  UserManagementControllerService,
  UserRoleEntityDTO,
} from 'api-rest';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-detail-packaging-origin-modal',
  templateUrl: './detail-packaging-origin-modal.component.html',
  styleUrls: ['./detail-packaging-origin-modal.component.scss'],
})
export class DetailPackagingOriginModalComponent implements OnInit {
  @Output() public refrescarAdheridoEvent = new EventEmitter<any>();

  paisesEndpoint = this.environment.urlBackCore + 'api/v1/core/countries';
  provinciasEndpoint = this.environment.urlBackCore + 'api/v1/core/provinces';
  tipoViaEndpoint = this.environment.urlBackCore + 'api/v1/core/via-types';
  codigoPostalEndpoint =
    this.environment.urlBackCore + 'api/v1/core/postalcodes';
  flagDisabledForm: boolean = false;
  updateList: boolean = false;
  flagUploadCopy: boolean = false;
  addresGlobal: any = null;
  paisPrefijo!: any;

  adherido!: Adherido;
  titulo = '';
  nombreAdherido: any = '';
  nombreDocumento: any = '';
  show: any = '';
  btonGuardar: boolean = false;

  itemEnvasado: any;

  onDestroy$: Subject<boolean> = new Subject();
  dataForm: FormGroup;
  tiposDocumento: Combo[] = [];
  tiposTelefono: Combo[] = [];
  flagLib: boolean = false;
  fiveDigitsPattern: string = '^[0-9]{1,5}$';

  provinceEs: any[] = [];
  actionExecuted: boolean = false;
  selectProvince: any;
  pageNumber: number = 0;
  pageSize: number = 100;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<DetailPackagingOriginModalComponent>,
    private attachedService: AttachedService,
    private centrosEnvasadoService: CentrosEnvasadoControllerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    @Inject('environment') private environment: any,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    public comboSrv: CombosService,
    private validateSrv: ValidateService,
    private snackBarSrv: SnackbarService,
    public spinnerSrv: SpinnerService,
    private userService: UserManagementControllerService
  ) {
    this.itemEnvasado = this.data.item;
    this.adherido = this.data.adherido;
    this.show = this.data.show;

    this.dataForm = this.formBuilder.group({
      denominacion: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(255)]) },
      ],
      categoria: [{ value: null, disabled: false }],
      country: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      county: [{ value: null, disabled: false },
        {validators: Validators.compose([Validators.maxLength(255)])}],
      municipality: [{ value: null, disabled: false },
        {validators: Validators.compose([Validators.maxLength(255)])}],
      cp: [{ value: null, disabled: false },
        {validators: Validators.compose([Validators.maxLength(255)])}],
      roadType: [{ value: null, disabled: false },
        {validators: Validators.compose([Validators.maxLength(255)])}],
      roadName: [{ value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(100)])}],
      number: [{value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(5)])}],
      buildingBlock: [{value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(5)])}],
      stairs: [{value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(5)])}],
      floor: [{value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(5)])}],
      door: [{value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(5)])}],
      otherData: [{value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(100)])}],
      nameContac: [
        {value: null, disabled: false},
        { validators: Validators.compose([Validators.required, Validators.maxLength(50)]) },
      ],
      firstSurname: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(50)]) },
      ],
      secondSurname: [{ value: null, disabled: false },
        {validators: Validators.compose([Validators.maxLength(50)])}
      ],
      email: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(this.validateSrv.emailPattern),
            Validators.maxLength(100)
          ]),
        },
      ],
      tlfType: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      countryCode: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      tlfNumber: [
        { value: null, disabled: false },
        Validators.compose([Validators.required, this.telefonoValidator.bind(this),Validators.maxLength(15)])
      ],
      quantityContainers: [{ value: null, disabled: false }],
    });
  }
  ngOnInit(): void {
    this.getProvinceByCp();
    this.nombreAdherido = this.adherido?.razonSocial;
    this.nombreDocumento = this.adherido?.codigoDocumento;
    this.tiposDocumento = this.comboSrv.getTiposDocumentoIdentidad();
    this.tiposTelefono = this.comboSrv.getTiposTelefono();
    this.canAccess();
    if (this.itemEnvasado) {
      this.show == 'view'
        ? (this.titulo = 'Visor Centro de envasado u origen')
        : (this.titulo = 'Editar centro de envasado u origen');
      this.cargarCentroEnvasados(this.itemEnvasado);
    } else {
      this.titulo = 'Nuevo centro de envasado u origen';
      this.btonGuardar = true;
    }
    this.selectedTab = 'direccion';

    this.dataForm.get('tlfType')?.valueChanges.subscribe(() => {
      this.dataForm.get('tlfNumber')?.updateValueAndValidity();
    });

    this.dataForm.get('countryCode')?.valueChanges.subscribe(() => {
      this.dataForm.get('tlfNumber')?.updateValueAndValidity();
    });

    this.cargarCP();
    this.canAccess();
  }

  telefonoValidator(control: AbstractControl): { [key: string]: any } | null {
    const tipoTelefono = this.dataForm?.get('tlfType')?.value;
    const prefijoPais = this.dataForm?.get('countryCode')?.value;

    if (!control.value) {
      return null;
    }

    const phonePattern =
      /^(\+?\d{1,3})?[-. (]?(\d{1,4})[-. )]?(\d{1,4})[-. ]?(\d{1,4})[-. ]?(\d{1,9})$/;
    const isGeneralValid = phonePattern.test(control.value);

    if (!isGeneralValid) {
      return { invalidPhoneFormat: true };
    }

    if (
      prefijoPais?.prefijo == '34' ||
      (typeof prefijoPais === 'string' && prefijoPais?.toUpperCase() == '34 (ESPAÑA)')
    ) {
      if (tipoTelefono === 'FIJO') {
        const valid = /^[89][0-9]{8}$/.test(control.value);
        return valid ? null : { invalidTelefonoFijo: true };
      } else if (tipoTelefono === 'MOVIL') {
        const valid = /^[67][0-9]{8}$/.test(control.value);
        return valid ? null : { invalidTelefonoMovil: true };
      }
    }
    return null;
  }

  esES(): boolean {
    let esEs = false;
    if (this.dataForm.get('country')?.value) {
      let pais = this.dataForm.get('country')?.value;
      esEs = pais === 'ESPAÑA' || pais?.iso === 'ES' ? true : false;
    }
    if (esEs) {
      this.dataForm.get('county')?.setValidators([Validators.required]);
      this.dataForm.get('municipality')?.setValidators([Validators.required]);
      this.dataForm.get('county')?.updateValueAndValidity();
      this.dataForm.get('municipality')?.updateValueAndValidity();

      this.dataForm.get('cp')?.clearValidators();

      this.dataForm
        .get('cp')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.fiveDigitsPattern),
        ]);
      this.dataForm.get('cp')?.updateValueAndValidity();

      this.dataForm.get('roadName')?.setValidators([Validators.required]);
      this.dataForm.get('roadName')?.updateValueAndValidity();

      this.dataForm.get('number')?.setValidators([Validators.required]);
      this.dataForm.get('number')?.updateValueAndValidity();
    } else {
      this.dataForm.get('cp')?.clearValidators();
      this.dataForm
        .get('cp')
        ?.setValidators([Validators.pattern(this.fiveDigitsPattern)]);
      this.dataForm.get('cp')?.updateValueAndValidity();

      this.dataForm.get('municipality')?.clearValidators();
      this.dataForm.get('county')?.clearValidators();
      this.dataForm.get('municipality')?.updateValueAndValidity();
      this.dataForm.get('county')?.updateValueAndValidity();

      this.dataForm.get('number')?.clearValidators();
      this.dataForm.get('number')?.updateValueAndValidity();

      this.dataForm.get('roadName')?.clearValidators();
      this.dataForm.get('roadName')?.updateValueAndValidity();
    }
    return esEs;
  }

  saveData() {
    if (this.dataForm.invalid) {
      this.dataForm.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Completar los datos obligatorios de dirección y contacto.',
        'error'
      );
      return;
    }
    let prefijoPaisSel:any;
    let prefijo = this.dataForm.get('countryCode')?.value;
    if (typeof prefijo === 'string') {
      prefijoPaisSel = this.paisPrefijo;
    } else {
      prefijoPaisSel = this.dataForm.get('countryCode')?.value;
    }

    const objCentroEnvasado: any = {
      id: null,
      denominacion: this.dataForm.get('denominacion')?.value ?? '',
      domicilio: {
        id: null,
        tipoDireccion: {
          id: 4,
          descripcion: 'Otros',
        },
        municipio: this.dataForm.get('municipality')?.value,
        tipoVia: this.dataForm.get('roadType')?.value,
        nombreVia: this.dataForm.get('roadName')?.value,
        numero: this.dataForm.get('number')?.value,
        bloque: this.dataForm.get('buildingBlock')?.value,
        escalera: this.dataForm.get('stairs')?.value,
        piso: this.dataForm.get('floor')?.value,
        puerta: this.dataForm.get('door')?.value,
        otrosDatos: this.dataForm.get('otherData')?.value,
      },
      contacto: {
        id: null,
        tipo_contacto: {
          id: 5,
          descripcionCorta: 'Otros',
          descripcion: 'Otro tipo de contacto',
        },
        nombre: this.dataForm.get('nameContac')?.value ?? '',
        apellido1: this.dataForm.get('firstSurname')?.value ?? '',
        apellido2: this.dataForm.get('secondSurname')?.value ?? '',
        tipo_telefono: this.dataForm.get('tlfType')?.value,
        codigoPais: prefijoPaisSel,
        con_numero: prefijoPaisSel.prefijo,
        numero_telefono: this.dataForm.get('tlfNumber')?.value ?? '',
        email: this.dataForm.get('email')?.value,
      },
    };

    const filtroUsuario: KeycloakUserFilterDTO = {
      email: [objCentroEnvasado.contacto.email],
    };
    this.userService
      .checkUserExists(filtroUsuario)
      .subscribe((checkUser) => {
        if (checkUser.userExists) {
          if (checkUser.userEnvalora) {
            this.snackBarSrv.showSnackBar(
              'El email de contacto pertenece a un usuario existente con rol Envalora'
            );
          } else {
            this.confirmacion(
              `Ya existe un usuario con email ${objCentroEnvasado.contacto.email} ¿Quiere asignar este mismo usuario al nuevo registro de adherido?`,
              () => this.performSavePackagingOrigin(objCentroEnvasado)
            );
          }
        } else {
          this.performSavePackagingOrigin(objCentroEnvasado);
        }
      });
  }

  private performSavePackagingOrigin(objCentroEnvasado: any) {
    let pais = this.dataForm.get('country')?.value;
    if (typeof pais === 'string') {
      objCentroEnvasado.domicilio.pais = this.itemEnvasado?.pais;
    } else {
      objCentroEnvasado.domicilio.pais = pais;
    }

    let provincia = this.selectProvince ?? this.dataForm.get('county')?.value;
    let cp = this.dataForm.get('cp')?.value;

    if (pais?.iso === 'ES') {
      if (typeof provincia === 'string') {
        objCentroEnvasado.domicilio.provincia = this.itemEnvasado?.provincia;
      } else {
        objCentroEnvasado.domicilio.provincia = provincia;
      }
      objCentroEnvasado.domicilio.codigoPostal = { id: null, codigoPostal: cp };
    } else {
      objCentroEnvasado.domicilio.provincia = { nombre: provincia };
      objCentroEnvasado.domicilio.codigoPostal = { codigoPostal: cp };
    }

    if (this.itemEnvasado) {
      objCentroEnvasado.id = this.itemEnvasado.id;
      objCentroEnvasado.contacto.id = this.itemEnvasado.contacto.id;
      objCentroEnvasado.domicilio.id = this.itemEnvasado.domicilio.id;

      const unificarDataActualizada = Object.assign(
        this.itemEnvasado,
        objCentroEnvasado
      );
      this.spinnerSrv.loadingShow();
      this.centrosEnvasadoService
        .actualizarCentro(
          this.adherido.id!,
          this.itemEnvasado?.id,
          unificarDataActualizada
        )
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
          next: (result: any) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'El centro envasado se ha actualizado correctamente',
              'success'
            );
            this.dialogRef.close(true);
          },
          error: (info: any) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al actualizar el centro envasado',
              'error'
            );
          },
        });
    } else {
      this.spinnerSrv.loadingShow();
      this.centrosEnvasadoService
        .crearCentro(this.adherido.id!, objCentroEnvasado)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe({
          next: (result: any) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'El centro envasado se ha creado correctamente',
              'success'
            );
            this.dialogRef.close(true);
          },
          error: (info: any) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al crear el centro envasado',
              'error'
            );
          },
        });
    }
  }

  cargarCP() {
    this.dataForm.get('cp')?.valueChanges.subscribe((value: any) => {
      this.actionExecuted = false;
      // this.dataForm.get('county')?.enable();
      if (
        value !== null &&
        !this.actionExecuted &&
        (this.dataForm.get('country')?.value?.id == 69 ||
          this.dataForm.get('country')?.value == 'ESPAÑA')
      ) {
        const cpValue = value != null ? value.toString() : null;
        if (/^\d{5}$/.test(cpValue)) {
          const twoDigits = parseInt(cpValue.substring(0, 2), 10);
          const data = this.provinceEs.find(
            (objeto: any) => objeto.id == twoDigits
          );
          if (data) {
            this.selectProvince = data;
            this.dataForm.get('county')?.setValue(this.selectProvince?.nombre);
            this.dataForm.get('county')?.disable();
          }
          this.actionExecuted = true;
        }
      }
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  selectedModel(selectForm: any) {
    switch (selectForm) {
      case 'country': {
        if (this.dataForm.controls['country']?.value == null) {
          this.dataForm.get('county')?.enable();
        }
        this.dataForm.controls['county']?.setValue(null);
        this.dataForm.controls['cp']?.setValue(null);
        this.dataForm.controls['municipality']?.setValue(null);
        this.selectProvince = null;
        break;
      }
      case 'county': {
        break;
      }
      case 'roadType': {
        break;
      }
      case 'cp': {
        this.dataForm
          .get('municipality')
          ?.setValue(this.dataForm.controls['cp']?.value?.municipio);
        break;
      }
      case 'countryCode': {
        break;
      }
      default: {
        break;
      }
    }
  }
  filterList(data: any, selectForm: any) {}
  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'country': {
        return this.dataForm.get('country') as FormControl;
        break;
      }
      case 'county': {
        return this.dataForm.get('county') as FormControl;
        break;
      }
      case 'roadType': {
        return this.dataForm.get('roadType') as FormControl;
        break;
      }
      case 'cp': {
        return this.dataForm.get('cp') as FormControl;
        break;
      }
      case 'countryCode': {
        return this.dataForm.get('countryCode') as FormControl;
        break;
      }
      default: {
        return this.dataForm.get('') as FormControl;
        break;
      }
    }
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.dataForm.controls[controlName].hasError(errorName) &&
      this.dataForm.controls[controlName].touched
    );
  }

  controlHasErrorSelect(controlName: string) {
    if (
      (this.dataForm.controls[controlName]?.touched ||
        this.dataForm.controls[controlName]?.dirty) &&
      this.dataForm.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.dataForm.controls[controlName]?.touched ||
        this.dataForm.controls[controlName]?.dirty) &&
      this.dataForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  cargarCentroEnvasados(centroEnvasado: any): void {
    this.dataForm.get('denominacion')?.setValue(centroEnvasado?.denominacion);

    this.dataForm
      .get('country')
      ?.setValue(centroEnvasado?.domicilio?.pais?.nombre);
    this.dataForm
      .get('county')
      ?.setValue(centroEnvasado?.domicilio?.provincia?.nombre);
    this.dataForm
      .get('cp')
      ?.setValue(centroEnvasado?.domicilio?.codigoPostal?.codigoPostal);
    this.dataForm
      .get('municipality')
      ?.setValue(centroEnvasado?.domicilio?.municipio);
    this.dataForm
      .get('roadType')
      ?.setValue(centroEnvasado?.domicilio?.tipoVia?.nombre);
    this.dataForm
      .get('roadName')
      ?.setValue(centroEnvasado?.domicilio?.nombreVia);
    this.dataForm.get('number')?.setValue(centroEnvasado?.domicilio?.numero);
    this.dataForm
      .get('buildingBlock')
      ?.setValue(centroEnvasado?.domicilio?.bloque);
    this.dataForm.get('stairs')?.setValue(centroEnvasado?.domicilio?.escalera);
    this.dataForm.get('floor')?.setValue(centroEnvasado?.domicilio?.piso);
    this.dataForm.get('door')?.setValue(centroEnvasado?.domicilio?.puerta);
    this.dataForm
      .get('otherData')
      ?.setValue(centroEnvasado?.domicilio?.otrosDatos);

    this.dataForm.get('nameContac')?.setValue(centroEnvasado?.contacto?.nombre);
    this.dataForm
      .get('firstSurname')
      ?.setValue(centroEnvasado?.contacto?.apellido1);
    this.dataForm
      .get('secondSurname')
      ?.setValue(centroEnvasado?.contacto?.apellido2);
    this.dataForm
      .get('tlfType')
      ?.setValue(centroEnvasado?.contacto?.tipo_telefono);
    this.dataForm
      .get('countryCode')
      ?.setValue(centroEnvasado?.contacto?.codigoPais?.prefijoNombre);
    this.dataForm
      .get('tlfNumber')
      ?.setValue(centroEnvasado?.contacto?.numero_telefono);
    this.dataForm.get('email')?.setValue(centroEnvasado?.contacto?.email);

    this.dataForm.get('tlfNumber')?.updateValueAndValidity();
    this.paisPrefijo = centroEnvasado?.contacto?.codigoPais;
  }

  canAccess() {
    if (this.show == 'view') {
      this.authService.can('adheridos-centros', 'access')
        ? this.dataForm.disable()
        : this.dataForm.enable();
    } else if (this.show == 'edit') {
      this.authService.can('adheridos-centros', 'update')
        ? this.dataForm.enable()
        : this.dataForm.disable();
      this.btonGuardar = true;
    }
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  // tipoDocumentoSel(idTipoDoc: string) {
  //   this.dataForm.get('docForm')?.clearValidators();
  //   if (idTipoDoc === 'NIF') {
  //     this.dataForm
  //       .get('docForm')
  //       ?.setValidators([Validators.pattern(this.validateSrv.nifPattern)]);
  //   } else if (idTipoDoc === 'DNI') {
  //     this.dataForm
  //       .get('docForm')
  //       ?.setValidators([Validators.pattern(this.validateSrv.dniPattern)]);
  //   } else if (idTipoDoc === 'NIE') {
  //     this.dataForm
  //       .get('docForm')
  //       ?.setValidators([Validators.pattern(this.validateSrv.niePattern)]);
  //   }
  //   this.dataForm.get('docForm')?.updateValueAndValidity();
  //   this.dataForm.get('docForm')?.markAsTouched();
  // }
  selectedTab: string | null = null;

  selectTab(tab: string) {
    this.selectedTab = tab;
  }

  getProvinceByCp() {
    this.spinnerSrv.loadingShow();
    this.attachedService
      .getProvinciasByCp(this.pageNumber, this.pageSize)
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.provinceEs = data.datos;
            this.spinnerSrv.loadingHide();
          }
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  confirmacion(titulo: string, callBack: Function): void {
    Swal.fire({
      text: titulo,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        callBack();
      }
    });
  }

  selectedPrefijoPais(event:any){
    let prefijo = this.dataForm.get('countryCode')?.value;
    if (typeof prefijo === 'string') {
      this.paisPrefijo = event;
    }else{
      this.paisPrefijo = this.dataForm.get('countryCode')?.value;
    }
  }
}
