<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="col-content">
  <div class="header-content">
    <div class="heading">{{adheridoInfo.razonSocial}}</div>
  </div>
  <form [formGroup]="formGroup" style="margin-top:20px">
    <div class="row-fields">
      <div class="field field-25">
        <label for="anio">Año <span class="oblig">*</span></label>
        <mat-form-field appearance="outline">
          <mat-select formControlName="anio" id="anio" placeholder="Año" (selectionChange)="anioSelected()">
            <mat-option *ngFor="let anio of anios" [value]="anio?.id">
              {{anio?.id}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="controlHasError('anio', 'required')">Campo obligatorio</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div style="border: 1px solid #e4e4e4; padding:20px;"
      *ngIf="canAccessCreate()&& (formGroup.get('anio')?.value===anioActual || formGroup.get('anio')?.value === anioActual+1)">
      <div class="row-fields">
        <div class="field field-100">
          <label for="codigoLer">Código LER y descripción de ENVALORA <span class="oblig">* </span><em
            *ngIf="formGroup.get('codigoLer')?.value?.peligrosidad === true" class="fas fa-skull-crossbones"
            style="color: red;" matTooltip="Envase de tipo peligroso"></em></label>
          <div class="custom-mat-field">
            <lib-ge-select-pagination class="lib-ge-select" [endPointFilter]="codigosLerEndpoint"
              [updateList]="updateList" [descripcion]="getLerDescription" [disabled]="flagDisabledForm"
              [formControlNameSelect]="getFormControl('codigoLer')" [objQueryName]="getObjectQuery"
              [placeHolder]="'-- Código LER y descripción de ENVALORA --'" [customClass]="rightHolder('codigoLer')">
            </lib-ge-select-pagination>
            <div class="ge-select-error">
              <mat-error class="ge-select-mat-error" *ngIf="selPaginatorHasError('codigoLer')">Campo
                obligatorio
              </mat-error>
            </div>
          </div>
        </div>
      </div>

      <div class="row-fields">
        <div class="field field-33">
          <label for="nima"><br>NIMA del gestor <span class="oblig">*</span></label>
          <mat-form-field appearance="outline">
            <input formControlName="nima" id="nima" matInput type="text" placeholder="NIMA del gestor" maxlength="10">
            <mat-error *ngIf="controlHasError('nima', 'required')">Campo obligatorio</mat-error>
            <mat-error *ngIf="controlHasError('nima', 'pattern')">Formato incorrecto</mat-error>
            <mat-error *ngIf="controlHasError( 'nima', 'maxlength')">Máximo 10 caracteres</mat-error>
          </mat-form-field>
        </div>

        <div class="field field-33" style="margin-top:22px;">
          <label for="suministraInfo">¿Quién suministra información de gestión?<span class="oblig">*</span></label>
          <mat-form-field appearance="outline">
            <mat-select formControlName="suministraInfo" id="suministraInfo"
              placeholder="¿Quién suministra información de gestión?">
              <mat-option *ngFor="let el of suministraInfoList" [value]="el?.id">
                {{el?.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="controlHasError('suministraInfo', 'required')">Campo obligatorio
            </mat-error>
          </mat-form-field>
        </div>

        <div class="field field-33">
          <div class="button-group" style="margin-top:46px;">
            <button style="float:right" type="button" (click)="addPrevision()" class="btn btn-primary">Agregar</button>
          </div>
        </div>
      </div>
    </div>
  </form>


</div>
<div class="datatable-container table-responsive">
  <lib-ge-table [headArray]="headArray" [gridArray]="grid" (actionButton)="action($event)">
  </lib-ge-table>
</div>
