import { NgModule } from '@angular/core';
import { ApiCore, ApiComunicaciones, ApiDocum } from 'api-rest';
import { RouterModule } from '@angular/router';
import { CommonModule, DatePipe } from '@angular/common';
import { MaterialModule } from './material.module';
import { EmpusaSpinnerComponent } from '@empusa/empusa-core';
import { CustomDatePickerModule } from './custom-date-picker.module';
import { ComunicationsComponent } from '../pages/comunications.component';
import { ComunicationsSearchComponent } from '../pages/_components/comunications-search/comunications-search.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbPaginationModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { GeTableComponent } from '../share/components/ge-table.component';
import { GetAttributePipe } from '../share/components/pipe/get-attribute.pipe';
import { GeSelectPaginationComponent } from '../share/components/ge-select-pagination/ge-select-pagination.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ComunicationDialogComponent } from '../pages/_components/comunication-dialog/comunication-dialog.component';
import { NgxMatTimepickerModule } from 'ngx-mat-timepicker';
import {MatIconModule} from "@angular/material/icon";

export const CoreConfigFactory = () =>
  new ApiCore.Configuration(<ApiCore.ConfigurationParameters>{});

export const CoreBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE CORE ' + environment.urlBackCore);
  const basePath = environment.urlBackCore;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const ComunicacionesConfigFactory = () =>
  new ApiComunicaciones.Configuration(
    <ApiComunicaciones.ConfigurationParameters>{}
  );

export const ComunicacionesBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE POSEEDORES ' + environment.urlBackComunicaciones);
  const basePath = environment.urlBackComunicaciones;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const DocumConfigFactory = () =>
  new ApiDocum.Configuration(<ApiDocum.ConfigurationParameters>{});

export const DocumBasePathFactory = (environment: any) => {
  console.log('USANDO URL DE DOCUM ' + environment.urlBackDocum);
  const basePath = environment.urlBackDocum;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const explorerRoutes = [
  {
    path: '',
    component: ComunicationsComponent,
    children: [{ path: 'search', component: ComunicationsSearchComponent }],
  },
];

@NgModule({
  declarations: [
    ComunicationsComponent,
    ComunicationsSearchComponent,
    ComunicationDialogComponent,
    // Shared components
    GeTableComponent,
    GetAttributePipe,
    GeSelectPaginationComponent,
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(explorerRoutes),
        MaterialModule,
        EmpusaSpinnerComponent,
        CustomDatePickerModule,
        NgbPaginationModule,
        NgSelectModule,
        NgbTooltipModule,
        NgxMatTimepickerModule,
        // ApiComunicaciones.ApiModule.forRoot(ComunicacionesConfigFactory),
        ApiDocum.ApiModule.forRoot(DocumConfigFactory),
        MatIconModule,
    ],
  exports: [ComunicationsComponent],
  providers: [
    DatePipe,
    {
      provide: ApiComunicaciones.BASE_PATH,
      useFactory: ComunicacionesBasePathFactory,
      deps: ['environment'],
    },
    {
      provide: ApiDocum.BASE_PATH,
      useFactory: DocumBasePathFactory,
      deps: ['environment'],
    },
  ],
})
export class ComunicationsHomeModule {
  constructor() {
   let compilationVersion = ""
    console.log('comunications version: ' + compilationVersion);
  }
}
