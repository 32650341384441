<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="col-content show-display">
  <div class="header-content mb-xl-5 mb-3">
    <div class="heading">{{puntoRecogidaInfo?.razonSocial}}</div>
  </div>
  <form [formGroup]="formGroup" *ngIf="canAccessCodeLerViewFrom()">
    <div class="row form">
      <div class="col-sm flex-grow-2">
        <label for="codigoLer">Código LER y descripción de ENVALORA <span class="oblig">* </span> <em
          *ngIf="formGroup.get('codigoLer')?.value?.peligrosidad === true" class="fas fa-skull-crossbones"
          style="color: red;" matTooltip="Envase de tipo peligroso"></em></label>
        <div class="custom-mat-field">
          <lib-ge-select-pagination class="lib-ge-select" [endPointFilter]="codigosLerEndpoint"
            [updateList]="updateList" [descripcion]="getLerDescription"
            [formControlNameSelect]="getFormControl('codigoLer')" [objQueryName]="getObjectQuery"
            [placeHolder]="'-- Código LER y descripción de ENVALORA --'" [customClass]="rightHolder('codigoLer')">
          </lib-ge-select-pagination>
          <!-- <div class="ge-select-error">
            <mat-error class="ge-select-mat-error" *ngIf="selPaginatorHasError('codigoLer')">Campo
              obligatorio
            </mat-error>
          </div> -->
        </div>
      </div>
    </div>
    <div class="row form pt-3">
      <div class="col-sm flex-grow-1">
        <label for="periocidadTraslado">Periodicidad de Traslados <span class="oblig">*</span></label>
        <div class="custom-mat-field">
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <mat-select formControlName="periocidadTraslado" id="periocidadTraslado"
              placeholder="Periodicidad de Traslados">
              <mat-option *ngFor="let item of filteredOptions" [value]="item">
                {{ item.description }}
              </mat-option>
            </mat-select>
            <!-- <span *ngIf="showClearButton('periocidadTraslado')" tabindex="0" class="ng-clear-wrapper"
              (click)="clearSelection($event,'periocidadTraslado')">
              <span aria-hidden="true" class="ng-clear">×</span>
            </span> -->
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm flex-grow-1">
        <label for="operacionTI">Operación de tratamiento (intermedia) <span class="oblig">*</span></label>
        <div class="custom-mat-field">
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <mat-select formControlName="operacionTI" id="operacionTI" placeholder="Operación de tratamiento (intermedia)">
              <mat-option *ngFor="let item of operacionTIArray" [value]="item">
                {{ item.codigo }} {{ item.descripcion }}
              </mat-option>
            </mat-select>
            <!-- <span *ngIf="showClearButton('operacionTI')" tabindex="0" class="ng-clear-wrapper"
              (click)="clearSelection($event,'operacionTI')">
              <span aria-hidden="true" class="ng-clear">×</span>
            </span> -->
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm flex-grow-1">
        <label for="operacionTF">Operación de tratamiento (final) <span class="oblig">*</span></label>
        <div class="custom-mat-field">
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <mat-select formControlName="operacionTF" id="operacionTF" placeholder="Operación de tratamiento (final)">
              <mat-option *ngFor="let item of operacionTFArray" [value]="item">
                {{ item.codigo }} {{ item.descripcion }}
              </mat-option>
            </mat-select>
            <!-- <span *ngIf="showClearButton('operacionTF')" tabindex="0" class="ng-clear-wrapper"
              (click)="clearSelection($event,'operacionTF')">
              <span aria-hidden="true" class="ng-clear">×</span>
            </span> -->
          </mat-form-field>
        </div>
      </div>
    </div>
    <div class="row form">
      <div class="col-sm flex-grow-0">
        <label for="cantidadAutorizada">Cantidad autorizada (Kg) <span class="oblig">*</span> </label>
        <div class="custom-mat-field">
          <mat-form-field appearance="outline" class="custom-mat-form-field-width">
            <input formControlName="cantidadAutorizada" id="cantidadAutorizada" placeholder="Cantidad autorizada (Kg)"
              matInput type="text" appTwoDigitDecimalNumber>
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm flex-grow-1">
        <label for="gestorFinal">NIMA Gestor final <span class="oblig">*</span></label>
        <div class="custom-mat-field">
          <mat-form-field appearance="outline" class="custom-mat-form-field-width">
            <input formControlName="gestorFinal" id="gestorFinal" placeholder="NIMA Gestor final" matInput type="text" maxlength="255">
            <mat-error *ngIf="controlHasError( 'gestorFinal', 'maxlength')">Máximo 255 caracteres</mat-error>
            <mat-error *ngIf="controlHasError('gestorFinal', 'pattern')">Formato incorrecto</mat-error>
          </mat-form-field>
        </div>
      </div>
      <div class="col-sm flex-grow-0">
        <label for="razonSocialGestorFinal">Razón social gestor final <span class="oblig">*</span></label>
        <div class="custom-mat-field">
          <mat-form-field appearance="outline" class="custom-mat-form-field-width">
            <input formControlName="razonSocialGestorFinal" id="razonSocialGestorFinal"
              placeholder="Razón social gestor final" matInput type="text" maxlength="255">
            <mat-error *ngIf="controlHasError( 'razonSocialGestorFinal', 'maxlength')">Máximo 255 caracteres</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="col-sm flex-grow-0" *ngIf="canAccessAgregar()">
        <div class="button-group" style="margin-top:26px;">
          <button style="float:right" type="button" (click)="addPrevision()" class="btn btn-primary">Agregar</button>
        </div>
      </div>
    </div>

  </form>
  <div class="datatable-container table-responsive">
    <lib-ge-table [headArray]="headArray" [gridArray]="grid" (actionButton)="action($event)">
    </lib-ge-table>
  </div>
</div>
