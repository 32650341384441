<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="bg-second-migas">
  <div class="container mt-cab-fixed">
    <div class="pre-content">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">Administración</li>
          <li class="breadcrumb-item active"><a href="index.html">Prefacturación</a></li>
        </ol>
      </nav>
      <div class="content-title">
        <h2 class="title-pag">Prefacturación</h2>
      </div>
    </div>
  </div>
</div>
<!-- FIN MIGAS DE PAN Y TÍTULO -->
<!-- CONTENIDO -->

<div class="container-form">

  <form class="container form-search py-3" [formGroup]="generateForm">
    <div class="row form">
     
      <div class="col-sm flex-grow-2">
        <label for="modulo">Módulo / Proceso <span class="oblig">*</span></label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <mat-select formControlName="modulo" id="modulo" placeholder="-- Módulo / Proceso --">
            <mat-option *ngFor="let modulo of modulos" [value]="modulo">
              {{ modulo.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="controlHasError('modulo', 'required')">Campo obligatorio</mat-error>
        </mat-form-field>
      </div>
      <div class="col-sm flex-grow-1" >
        <div *ngIf="(generateForm.get('modulo')?.value)">
          <label for="fechaHasta">Fecha hasta <span class="oblig">*</span></label>
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <input matInput [matDatepicker]="fechaHasta" formControlName="fechaHasta">
            <mat-datepicker-toggle matIconSuffix [for]="fechaHasta"></mat-datepicker-toggle>
            <mat-datepicker #fechaHasta>
            </mat-datepicker>
            <mat-error *ngIf="controlHasError('fechaHasta', 'matDatepickerParse')">Formato incorrecto</mat-error>
            <mat-error *ngIf="controlHasError('fechaHasta', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
        
      </div>

      
      <div class="col-sm flex-grow-1" *ngIf="(generateForm.get('modulo')?.value)">
        <div *ngIf="(generateForm.get('modulo')?.value)">
          <label for="importeMinimo">Importe mínimo <span class="oblig">*</span></label>
          <mat-form-field class="custom-mat-form-field" appearance="outline">
            <input type="number" matInput formControlName="importeMinimo" id="importeMinimo" placeholder="Importe mínimo" type="text"
              appTwoDigitDecimalNumber>
            <mat-error *ngIf="controlHasError('importeMinimo', 'required')">Campo obligatorio</mat-error>
          </mat-form-field>
        </div>
        
      </div>
     
      <!--<div class="field field-10" style="margin-top:8px;" >
        <div class="button-group">
          <button type="button" class="btn btn-primary" (click)="generarPrefactura()"><span class="icon-add"></span>Generar</button>
        </div>
      </div>-->
    </div>


    <div class="row form">
      <div class="col-sm flex-grow-1" *ngIf="(generateForm.get('modulo')?.value?.id == 'SDDR_ABIERTO_SOLICITUDES')">
        <label for="importeMinimoDepositoIBC">Importe mínimo depósito IBC</label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <input type="number" matInput formControlName="importeMinimoDepositoIBC" id="importeMinimoDepositoIBC" placeholder="Importe mínimo depósito IBC" type="text"
            appTwoDigitDecimalNumber>
        </mat-form-field>
      </div>

      <div class="col-sm flex-grow-1" *ngIf="(generateForm.get('modulo')?.value?.id == 'SDDR_ABIERTO_SOLICITUDES')">
        <label for="importeMinimoDepositoPallet">Importe mínimo depósito Pallet</label>
        <mat-form-field class="custom-mat-form-field" appearance="outline">
          <input type="number" matInput formControlName="importeMinimoDepositoPallet" id="importeMinimoDepositoPallet" placeholder="Importe mínimo depósito Pallet" type="text"
            appTwoDigitDecimalNumber>
        </mat-form-field>
      </div>
      
    </div>


  </form>

  <form class="container form-search py-3">
    <div class="button-group">
      <button type="button" class="btn btn-primary" (click)="generarPrefactura()"><span class="icon-add"></span>Generar</button>
      <button type="button" class="btn btn-outline-primary" (click)="limpiar()">Limpiar</button>
      <button type="button" class="btn btn-primary" (click)="buscar()">Buscar</button>
    </div>
  </form>
</div>

<div class="bg-second-table-info pt-3 pb-5">
  <div *ngIf="flagSearch; else messageClear" class="container">
    <div class="actions-table">
      <div class=""><span class="align-middle tit-table">{{ recordsTotal }} registros encontrados</span></div>
    </div>
    <div class="card-tabla-proy">
      <div class="card-body">
        <div class="datatable-wrapper datatable-loading no-footer sortable searchable fixed-columns">
          <div class="datatable-container table-responsive">
            <!-- TABLA Listado con resultados de búsqueda -->
            <lib-ge-table [headArray]="headArray" [gridArray]="gridArray">
            </lib-ge-table>
            <div class="d-flex pb-2 flex-row justify-content-center align-items-center">
              <div class="d-flex mt-2">
                <ngb-pagination [pageSize]="pageSize" [collectionSize]="recordsTotal" [(page)]="pageNumber"
                  (pageChange)="renderPage($event)" [maxSize]="5" aria-label="Default pagination" size="sm">
                </ngb-pagination>
              </div>

              <div class="mt-2 selectPage">
                <label for="itemsPerPage">Mostrar:</label>
                <select id="itemsPerPage" [(ngModel)]="selectedItemsPerPage" (change)="onItemsPerPageChange()">
                  <option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}</option>
                </select>
              </div>
            </div>
            <!-- FIN TABLA Listado con resultados de búsqueda -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #messageClear>
    <div class="mensaje-container">
      <p class="mensaje-linea">No hay resultados de búsqueda, por favor seleccione, al menos, un criterio en el
        formulario,</p>
      <p class="mensaje-linea">o cree un nuevo registro desde el botón "Generar"</p>
    </div>
  </ng-template>
</div>
<!-- FIN CONTENIDO -->
