import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SpinnerService } from '../../../services/spinner.service';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import {
  AportacionDIEstadoControllerService,
  OperacionGestionControllerService,
  ResiduoEnvaseFilterDTO,
  SolicitudNimaGestoresDTO,
  AportacionDIEstadoDTO,
  GestoresResiduosControllerService,
  CodigoOperacionFilterDTO,
  CodigoLerFilterDTO,
  CodigoLerControllerService,
  AportacionDisddrcaControllerService,
  AportacionDISDDRCADTO,
  EntidadAsociadaDTO,
  IdEntityDTO,
} from 'api-rest';
import {debounceTime, Observable, Subscription, tap} from 'rxjs';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { DocumentService } from '../../../services/document.service';

@Component({
  selector: 'app-open-sddr-contributions-dis-dialog',
  templateUrl: './open-sddr-contributions-dis-dialog.component.html',
  styleUrls: ['./open-sddr-contributions-dis-dialog.component.scss'],
})
export class OpenSddrContributionsDisDialogComponent implements OnInit {
  estadoArray: AportacionDIEstadoDTO[] = [];
  codigoLerArray: any[] = [];
  residuoArray: any[] = [];
  gestionArray: any[] = [];
  nimaGestorArray: any[] = [];
  entityRoles: any[] = [];

  modelForm!: FormGroup;
  subscriptions: Subscription[] = [];
  roleEntities: any[] = [];
  documento: File | undefined;
  titulo: any;
  isGestor = false;
  edicion = false;
  alta = false;
  estado: Number = 1;
  usuarioSddrCa: any = null;

  pageNumber: number = 0;
  pageSize: number = 100;
  recordsTotal: number = 0;

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private dialogRef: MatDialogRef<OpenSddrContributionsDisDialogComponent>,
    private estadoAportacionesService: AportacionDIEstadoControllerService,
    private aportacionDisddrcaCtrSrv: AportacionDisddrcaControllerService,
    private codigoLerService: CodigoLerControllerService,
    private gestorResiduos: GestoresResiduosControllerService,
    private downloadService: DownloadService,
    private documentService: DocumentService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    @Inject(MAT_DIALOG_DATA) public aportacion: AportacionDISDDRCADTO | any,
    private operacionGestionControllerService: OperacionGestionControllerService
  ) {
    this.isGestor = aportacion.isGestor;

    this.inicializarForm();
  }

  ngOnInit() {
    this.spinnerSrv.loadingShow();

    const peticionInicial = this.buscarInicialGestor();
    peticionInicial.subscribe({
      next: () => {
        this.cargaInicial();
        this.spinnerSrv.loadingHide();
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  inicializarForm() {
    this.aportacion.fechaAceptacion = this.aportacion.fechaAceptacion ?? new Date().toISOString();
    this.modelForm = this.formBuilder.group({
      id: [{ value: this.aportacion?.id }],
      idSolicitud: [{value: this.aportacion?.solicitudRetirada?.id, disabled: false},
        {validators: Validators.compose([Validators.required, Validators.pattern('^[0-9]+$'), Validators.maxLength(10)])}],
      usuarioSddrCa: [{value: null, disabled: false},
        {validators: Validators.compose([Validators.required])}
      ],
      documentoIdentificacion: [
        { value: this.aportacion?.documentoIdentificacion, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(255)]) },
      ],
      fechaAceptacion: [
        { value: this.aportacion?.fechaAceptacion, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      estado: [{ value: this.aportacion?.estado, disabled: false }],

      nimaGestor: [
        { value: this.aportacion?.gestorResiduo, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],

      autofactura: [{ value: null, disabled: false }],
      documento: [{ value: this.aportacion?.documento, disabled: false }],

      codigoLer: [
        { value: this.aportacion?.codigoLer, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      gestorResiduo: [
        { value: this.aportacion?.gestorResiduo, disabled: false },
      ],
      operacionGestion: [
        { value: this.aportacion?.operacionGestion, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],

      cantidadGestionada: [
        { value: this.aportacion?.cantidadGestionada, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
    });

    this.buscarNimaGestor();

    const sub3 = this.modelForm.controls['nimaGestor'].valueChanges.subscribe(
      (val) => {
        this.buscaCodigoLer(
          this.modelForm.value?.fechaAceptacion,
          val,
        );
      }
    );
    this.subscriptions.push(sub3);

    this.valueChangeCodigoLer();

    // Set valor usuario poseedor o adherido (edit)
    if (this.aportacion?.solicitudRetirada?.adherido || this.aportacion?.solicitudRetirada?.poseedor) {
      const tipoUsuario = this.aportacion?.solicitudRetirada?.adherido ? 'AD - ' : this.aportacion?.solicitudRetirada?.poseedor ? 'PO - ' : '';
      this.usuarioSddrCa = this.aportacion?.solicitudRetirada?.adherido || this.aportacion?.solicitudRetirada?.poseedor || null;
      this.modelForm.patchValue({
        usuarioSddrCa: tipoUsuario + this.usuarioSddrCa?.razonSocial,
      });
    }
  }

  cargaInicial() {
    this.roleEntities = (this.authService.user as any).roleEntities;
    if (this.aportacion?.id) {
      this.titulo = 'Editar aportación de DI';
      this.buscarOperacionesGestion();
      this.edicion = true;
    } else {
      this.titulo = 'Alta de aportación de DI';
      this.edicion = false;
    }

    if (!this.canSaveAll()) {
      this.modelForm.disable();
    } else {
      this.buscarSolicitudRetirada();
    }

    this.estadoAportacionesService.findAll14().subscribe((estados) => (this.estadoArray = estados));
    this.buscarNimaGestor();
    this.buscaCodigoLer(
      this.modelForm.value?.fechaAceptacion,
      this.aportacion.gestorResiduo,
    );

    this.buscarGestorResiduos();

    this.updateDisableField('documentoIdentificacion');
    this.updateDisableField('fechaAceptacion');
    this.updateDisableField('cantidadGestionada');
  }

  buscarInicialGestor(): Observable<any> {
    return this.gestorResiduos
      .getById3(this.aportacion?.gestorResiduo?.id)
      .pipe(
        tap((res) => {
          this.aportacion.gestorResiduo = res;
        })
      );
  }

  updateDisableField(field: any): void {
    if (this.aportacion.estado) {
      if (!this.canAccessEditEstado()) {
        this.modelForm.get(field)?.disable();
      }
    }
  }

  buscarNimaGestor() {
    this.spinnerSrv.loadingShow();
    this.aportacionDisddrcaCtrSrv.nimaGestores()
      .subscribe({
      next: (res) => {
        this.nimaGestorArray = res;
        this.setItemSelected('nimaGestor', 'id', this.nimaGestorArray);
        this.spinnerSrv.loadingHide();
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  buscaCodigoLer(fechaAceptacion: any, gestorResiduo: any) {
    if (fechaAceptacion && gestorResiduo) {
      const filtroNimaPoseedor: CodigoLerFilterDTO = {
        acuerdoGestores: true
      };
      this.spinnerSrv.loadingShow();
      this.codigoLerService.findAll4(filtroNimaPoseedor, { page: 0, size: 1000 }).subscribe({
        next: (res) => {
          this.codigoLerArray = res?.datos || [];
          this.setItemSelected('codigoLer', 'id', this.codigoLerArray);
          this.spinnerSrv.loadingHide();
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
    } else {
      this.codigoLerArray = [];
    }
  }

  buscarGestorResiduos() {
    const filtroResiduo: ResiduoEnvaseFilterDTO = {};
    this.spinnerSrv.loadingShow();
    this.gestorResiduos
      .findAll8(filtroResiduo, { page: 0, size: 1000 })
      .subscribe({
        next: (res) => {
          this.residuoArray = res?.datos ?? [];
          this.setItemSelected('gestorResiduo', 'id', this.residuoArray);
          this.updateDisableField('gestorResiduo');
          this.spinnerSrv.loadingHide();
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  valueChangeCodigoLer() {
    this.modelForm.get('codigoLer')?.valueChanges.subscribe((value: any) => {
      if (value) {
        this.buscarOperacionesGestion();
      } else {
        this.gestionArray = [];
      }
    });
  }

  buscarOperacionesGestion() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.operacionGestionControllerService
      .findByCodigoOperacion(backendFilter, { page: page, size: size })
      .subscribe({
        next: (res) => {
          if (res) {
            this.gestionArray = res?.datos ?? [];
            this.setItemSelected('operacionGestion', 'id', this.gestionArray);
            this.updateDisableField('operacionGestion');
          }
          this.spinnerSrv.loadingHide();
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  private getBackendFilter() {
    const backendFilter: CodigoOperacionFilterDTO = {
      codigoLer: [this.modelForm.get('codigoLer')?.value?.id],
      poseedores: true
    };
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    return { backendFilter, page, size: this.pageSize };
  }

  private setItemSelected(
    controlName: string,
    propertyName: string,
    items: any[]
  ) {
    let selectedItem = null;
    if (this.modelForm.value[controlName]) {
      if (
        typeof this.modelForm.value[controlName] == 'object' &&
        this.modelForm.value[controlName][propertyName]
      ) {
        selectedItem = items.find(
          (item) =>
            this.modelForm.value[controlName][propertyName] ==
            item[propertyName]
        );
      } else {
        selectedItem = items.find(
          (item) => this.modelForm.value[controlName] == item[propertyName]
        );
      }
    }
    this.modelForm.controls[controlName].patchValue(selectedItem, {
      emitEvent: false,
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  saveData(estadoId: any) {
    this.modelForm.markAllAsTouched();
    this.modelForm.updateValueAndValidity();

    if (this.aportacion?.documento == null) {
      if (this.documento == undefined || this.documento == null) {
        this.snackBarSrv.showSnackBar('Adjunte documento', 'error');
        return;
      }
    }

    if (this.modelForm.valid) {
      const formValue = this.modelForm.value;
      const aportacionPayload: AportacionDISDDRCADTO = formValue;

      aportacionPayload.gestorResiduo = formValue.nimaGestor;
      if (aportacionPayload.estado !== undefined && estadoId !== null) {
        aportacionPayload.estado.id = estadoId;
      }
      if (this.usuarioSddrCa) {
        const { adherido, poseedor } = this.usuarioSddrCa;
        if (adherido) aportacionPayload.adherido = adherido;
        if (poseedor) aportacionPayload.poseedor = poseedor;
      }

      const idSolicitud = this.modelForm.get('idSolicitud')?.value;
      if (idSolicitud) {
        aportacionPayload.solicitudRetirada = {id: idSolicitud};
      }

      let request, successMsg: string, errorMsg: string;
      if (this.aportacion?.id) {
        request = this.aportacionDisddrcaCtrSrv
          .editById5(
          this.aportacion.id,
          aportacionPayload,
          this.documento
        );
        successMsg = 'La aportación DI se ha editado correctamente';
        errorMsg = 'Ha ocurrido un error al editar la aportación DI';
      } else {
        if (!this.documento) {
          this.snackBarSrv.showSnackBar(
            'Debe seleccionar un documento',
            'error'
          );
          return;
        }

        request = this.aportacionDisddrcaCtrSrv.create5(
          aportacionPayload,
          this.documento
        );
        successMsg = 'La aportación DI se ha creado correctamente';
        errorMsg = 'Ha ocurrido un error al crear la nueva aportación DI';
      }

      this.spinnerSrv.loadingShow();
      request.subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.dialogRef.close(true);
          this.snackBarSrv.showSnackBar(successMsg, 'success');
        },
        error: () => {
          this.aportacion.estado.id = 1;
          this.spinnerSrv.loadingHide();
        },
      });
    }
  }

  showClearButton(formName: string): boolean {
    return this.modelForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.modelForm.get(formName)?.setValue(null);
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.modelForm.controls[controlName].hasError(errorName) &&
      this.modelForm.controls[controlName].touched
    );
  }

  attachAdjunto(archivo: File | null): void {
    if (archivo) {
      if (archivo.size === 0) {
        this.snackBarSrv.showSnackBar(
          'No se puede subir un fichero vacío',
          'error'
        );
      } else if (archivo.size > 5 * 1024 * 1024) { // 5 MB
        this.snackBarSrv.showSnackBar(
          'El fichero supera el tamaño máximo permitido de 5 MB',
          'error'
        );
      } else {
        this.documento = archivo;
      }
    }
  }

  descargar(documento: any) {
    this.spinnerSrv.loadingShow();
    this.documentService.descargarDocumento(documento.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Se ha descargado el documento correctamente',
          'success'
        );
        this.downloadService.downloadBlob(response, documento.nombre);
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  canAccessEditEstado(): boolean {
    return this.aportacion?.estado?.id === 1;
  }

  canAccessEditByRol(): boolean {
    return this.authService.can('sddr-ca-di-detalle', 'access-update');
  }

  canViewResource(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

  canSaveAll(): boolean {
    if ((this.canAccessEditByRol() || this.canViewResource('PO-GESTOR')) && this.canAccessEditEstado()) {
      if (
        this.edicion &&
        this.aportacion.creadoPor == this.authService.getCurrentUserMail()
      ) {
        return true;
      } else if (!this.edicion) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  buscarSolicitudRetirada() {
    if (this.modelForm.get('idSolicitud')?.value) {
      const idSolicitud = this.modelForm.get('idSolicitud')?.value;

      if (!idSolicitud) {
        return this.resetUsuarioSddrCa()
      }

      this.spinnerSrv.loadingShow();
      const filter: IdEntityDTO = {id: idSolicitud};

      this.aportacionDisddrcaCtrSrv.comprobarGestorSolicitud(filter)
        .subscribe({
          next: (res: any) => {
            this.spinnerSrv.loadingHide();
            const tipoUsuario = res.adherido ? 'AD - ' : res.poseedor ? 'PO - ' : '';

            if (!tipoUsuario) {
              return this.showSolicitudError();
            }
            this.usuarioSddrCa = res.adherido || res.poseedor || null;
            this.modelForm.patchValue({
              usuarioSddrCa: tipoUsuario + this.usuarioSddrCa?.razonSocial,
            });
          },
          error: () => {
            this.spinnerSrv.loadingHide();
            this.showSolicitudError();
          },
        });
    }
  }

  private showSolicitudError() {
    this.snackBarSrv.showSnackBar(
      'El gestor no tiene permiso para gestionar esta solicitud',
      'warning'
    );
    this.resetUsuarioSddrCa();
  }

  private resetUsuarioSddrCa() {
    this.usuarioSddrCa = null;
    this.modelForm.patchValue({
      usuarioSddrCa: null,
    });
  }

  onlyNumberKey(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Control'];

    if (event.ctrlKey && (event.key === 'c' || event.key === 'v')) return;

    // Permitir números y teclas adicionales definidas
    if (!/^[0-9]$/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
