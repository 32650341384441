import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AgrupacionMaterialControllerService } from './api/agrupacionMaterialController.service';
import { CategoriaEnvaseControllerService } from './api/categoriaEnvaseController.service';
import { CnaeControllerService } from './api/cnaeController.service';
import { CodigoLerControllerService } from './api/codigoLerController.service';
import { CodigoPostalControllerService } from './api/codigoPostalController.service';
import { ComposicionMaterialControllerService } from './api/composicionMaterialController.service';
import { ComunidadControllerService } from './api/comunidadController.service';
import { ContactoControllerService } from './api/contactoController.service';
import { DireccionControllerService } from './api/direccionController.service';
import { EntityControllerService } from './api/entityController.service';
import { GrupoEnvaseControllerService } from './api/grupoEnvaseController.service';
import { PaisControllerService } from './api/paisController.service';
import { PrefacturacionHistoricoControllerService } from './api/prefacturacionHistoricoController.service';
import { ProvinciaControllerService } from './api/provinciaController.service';
import { TipoContactoControllerService } from './api/tipoContactoController.service';
import { TipoDireccionControllerService } from './api/tipoDireccionController.service';
import { TipoDocumentoControllerService } from './api/tipoDocumentoController.service';
import { TipoEnvasesControllerService } from './api/tipoEnvasesController.service';
import { TipoViaControllerService } from './api/tipoViaController.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AgrupacionMaterialControllerService,
    CategoriaEnvaseControllerService,
    CnaeControllerService,
    CodigoLerControllerService,
    CodigoPostalControllerService,
    ComposicionMaterialControllerService,
    ComunidadControllerService,
    ContactoControllerService,
    DireccionControllerService,
    EntityControllerService,
    GrupoEnvaseControllerService,
    PaisControllerService,
    PrefacturacionHistoricoControllerService,
    ProvinciaControllerService,
    TipoContactoControllerService,
    TipoDireccionControllerService,
    TipoDocumentoControllerService,
    TipoEnvasesControllerService,
    TipoViaControllerService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
