<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="cont">
  <div class="fondo-mundo">
    <div class="cont-mundo">
      <div class="texto-mundo">
        Bienvenido a ENVANET, la plataforma informática de ENVALORA, un entorno personal y privado para realizar
        diferentes gestiones con ENVALORA.
        <br><br>Para comenzar, selecciona una opción en el menú superior.
      </div>
    </div>
  </div>
  <div class="fondo-gris">
    <div class="tarjeta">
      <mat-card class="doc-tarjeta">
        <mat-card-content>
          <mat-card-title class="cabecera-tarjeta">Documentación de interés ({{documentos?.length}})</mat-card-title>
          <div class="contenido-tarjeta">
            <div class="documento" *ngFor="let documento of documentos">
              <div class="filas-documentos">
                <mat-icon style="color:#6b4e4e">description</mat-icon>
                <div>{{documento?.nombre}}</div>
              </div>
              <div (click)="descargarDocumento(documento)">
                <mat-icon class="descargar-archivo">
                  download</mat-icon>
              </div>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <div class="tarjeta ecoembes"  *ngIf="this.esEcoembes">
      <mat-card>
        <mat-card-content>     
            <div class="container-ecoembes">
              <div>Si necesitas declarar envases de otros tipos te ofrecemos este acceso directo a Ecoembes.</div>
              <div class="imagen-ecoembes" (click)="abrirEnlace('https://plataformaenvases.ecoembes.com/home')"></div>          
            </div>                                                                                             
        </mat-card-content>
      </mat-card>
    </div>    
    <div class="tarjeta">
      <div class="fondo-rrss">
        <div class="cont-rss">
          <div class="texto-rrss">
            Puede seguirnos en nuestras redes sociales para estar al día de todas las actividades de nuestro sistema.
          </div>
          <div class="imagen"></div>
          <div class="iconos-rrss">
            <div class="imagen imagen-x" (click)="abrirEnlace('https://x.com/Envalora_es?s=20')"></div>
            <div class="imagen imagen-in" (click)="abrirEnlace('https://es.linkedin.com/company/envalora')"></div>
            <div class="imagen imagen-youtube" (click)="abrirEnlace('https://www.youtube.com/@envalora3972')"></div>
          </div>
          <div class="texto-rrss">
            Y en nuestra web <span (click)="abrirEnlace('https://envalora.es/')" class="enlace">www.envalora.es</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>