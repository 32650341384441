import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  URL_CORE: string = this.environment.urlBackCore;
  URL_DOCUM: string = this.environment.urlBackDocum;

  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient
  ) { }
  httpDownloadOptions: Object = {
    headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' }),
    responseType: 'blob',
  };
  descargarDocumento(id: number): Observable<any> {
    const fullRoute = `${this.URL_DOCUM}api/v1/docum/download/${id}`;
    return this.httpClient.get<any>(fullRoute, this.httpDownloadOptions);
  }
}
