import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  Inject,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { SnackbarService } from '../../../services/snackbar.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { ValidateService } from '../../../services/validate.service';
import { Cnae, Combo } from '../../../models/combo';
import { CombosService } from '../../../services/combos.service';
import {
  GestoresResiduosControllerService,
  GestoresResiduosDTO,
} from 'api-rest';
import { DatePipe } from '@angular/common';
import moment from 'moment';

@Component({
  selector: 'lib-waste-managers-detail-general-data',
  templateUrl: './waste-managers-detail-general-data.component.html',
  styleUrls: ['./waste-managers-detail-general-data.component.scss'],
})
export class WasteManagersDetailGeneralDataComponent implements OnInit {
  @Input() gestorInfo!: any;
  @Output() public refrescarGestorEvent = new EventEmitter<any>();

  datosGeneralesForm!: FormGroup;
  tiposDocumento: Combo[] = [];

  updateList: boolean = false;
  flagDisabledForm: boolean = false;

  //abonosDeposito: any[] = [];

  optionArray = [{ value: 'Si' }, { value: 'No' }];

  constructor(
    private formBuilder: FormBuilder,
    @Inject('environment') private environment: any,
    private snackBarSrv: SnackbarService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    private validateSrv: ValidateService,
    public comboSrv: CombosService,
    private gestoresResiduosControllerService: GestoresResiduosControllerService,
    private datePipe: DatePipe
  ) {
    this.datosGeneralesForm = this.formBuilder.group({
      razonSocial: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(255)]) },
      ],
      tipoDocumento: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      codigoDocumento: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(15)]) },
      ],
      nima: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(this.validateSrv.tenDigits),
            Validators.maxLength(10)]
          )
        },
      ],
      denominacion: [{ value: null, disabled: false }, Validators.maxLength(255)],
      n_medioAmbiente: [{ value: null, disabled: false }, Validators.maxLength(255)],
      n_inscrip_1: [
        { value: null, disabled: false },
      ],
      n_inscrip_2: [{ value: null, disabled: false }, Validators.maxLength(255)],
      n_inscrip_3: [{ value: null, disabled: false }, Validators.maxLength(255)],
      fechaAltaFinalizada: [{ value: null, disabled: true }],
      fechaBaja: [{ value: null, disabled: false }],
      fechaBajaEnvanet: [{ value: null, disabled: true }],
      acuerdoEnv: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      gestorResiduos: [{ value: null, disabled: false }],
      acondicionador: [{ value: null, disabled: false }],
      abonoDeposito: [
        { value: null, disabled: false }
      ],
      autorizacionComuniaciones: [
        { value: true, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
    },
    { validators: [this.gestorAcondicionadorValidator()] }
    
  );
  }

  ngOnInit() {
    this.tiposDocumento = this.comboSrv.getTiposDocumentoIdentidad();
    //this.abonosDeposito = this.comboSrv.getAbonosDeposito();
    if (!this.canViewResource('PO-ENV-GEST')) {
      this.datosGeneralesForm.get('acuerdoEnv')?.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.gestorInfo) {
      this.cargarDatos(this.gestorInfo);
      this.controlGeneralForm();
    }
  }

  cargarDatos(gestorInfo: any) {
    this.datosGeneralesForm
      .get('razonSocial')
      ?.setValue(gestorInfo?.razonSocial);
    this.datosGeneralesForm
      .get('tipoDocumento')
      ?.setValue(gestorInfo?.tipoDocumento);
    this.datosGeneralesForm
      .get('codigoDocumento')
      ?.setValue(gestorInfo?.codigoDocumento);
    this.datosGeneralesForm.get('nima')?.setValue(gestorInfo?.nima);
    this.datosGeneralesForm.get('denominacion')?.setValue(gestorInfo?.centro);
    this.datosGeneralesForm
      .get('n_medioAmbiente')
      ?.setValue(gestorInfo?.numeroAutorizacion);
    this.datosGeneralesForm
      .get('n_inscrip_1')
      ?.setValue(gestorInfo?.numInscripcion1);
    this.datosGeneralesForm
      .get('n_inscrip_2')
      ?.setValue(gestorInfo?.numInscripcion2);
    this.datosGeneralesForm
      .get('n_inscrip_3')
      ?.setValue(gestorInfo?.numInscripcion3);
    let fechaAltaFinalizada =
      gestorInfo?.fechaAltaFinalizada != null
        ? this.datePipe.transform(gestorInfo?.fechaAltaFinalizada, 'dd/MM/yyyy')
        : null;
    this.datosGeneralesForm
      .get('fechaAltaFinalizada')
      ?.setValue(fechaAltaFinalizada);
    this.datosGeneralesForm.get('fechaBaja')?.setValue(gestorInfo?.fechaBaja);
    let fechaBajaEvanet =
      gestorInfo?.fechaBajaEnvanet != null
        ? this.datePipe.transform(gestorInfo?.fechaBajaEnvanet, 'dd/MM/yyyy')
        : null;
    this.datosGeneralesForm
      .get('fechaBajaEnvanet')
      ?.setValue(fechaBajaEvanet);
    this.tipoDocumentoSel(this.datosGeneralesForm.get('tipoDocumento')?.value);
    this.datosGeneralesForm
      .get('acuerdoEnv')
      ?.setValue(
        gestorInfo?.acuerdoEnvalora === true
          ? 'Si'
          : gestorInfo?.acuerdoEnvalora === false
            ? 'No'
            : null
      );
    if(gestorInfo?.gestorResiduos != null){
      this.datosGeneralesForm
      .get('gestorResiduos')
      ?.setValue(gestorInfo?.gestorResiduos);
    } else{
      this.datosGeneralesForm
      .get('gestorResiduos')
      ?.setValue(false);
    }
    if(gestorInfo?.acondicionadorEnvase != null){
      this.datosGeneralesForm
      .get('acondicionador')
      ?.setValue(gestorInfo?.acondicionadorEnvase);
    } else{
      this.datosGeneralesForm
      .get('acondicionador')
      ?.setValue(false);
    }
    this.onGestorAcondicionadorChange();
    this.datosGeneralesForm.get('abonoDeposito')?.setValue(gestorInfo?.abonoDeposito);
    if(gestorInfo?.autorizacionComuniaciones != null && gestorInfo?.autorizacionComuniaciones != undefined){
      this.datosGeneralesForm.get('autorizacionComuniaciones')?.setValue(gestorInfo?.autorizacionComuniaciones);
    }else{
      this.datosGeneralesForm.get('autorizacionComuniaciones')?.setValue(null);
    }
  }

  guardar() {
    this.datosGeneralesForm.markAllAsTouched();
    this.datosGeneralesForm.updateValueAndValidity();
    if (this.datosGeneralesForm.valid) {
      let body = this.gestorInfo;
      body.razonSocial = this.datosGeneralesForm.get('razonSocial')?.value;

      body.tipoDocumento = this.datosGeneralesForm.get('tipoDocumento')?.value;

      body.codigoDocumento =
        this.datosGeneralesForm.get('codigoDocumento')?.value;

      body.nima = this.datosGeneralesForm.get('nima')?.value;

      body.centro = this.datosGeneralesForm.get('denominacion')?.value;

      body.numeroAutorizacion =
        this.datosGeneralesForm.get('n_medioAmbiente')?.value;
      body.numInscripcion1 = this.datosGeneralesForm.get('n_inscrip_1')?.value;
      body.numInscripcion2 = this.datosGeneralesForm.get('n_inscrip_2')?.value;

      body.numInscripcion3 = this.datosGeneralesForm.get('n_inscrip_3')?.value;

      body.fechaAltaFinalizada = this.gestorInfo?.fechaAltaFinalizada;
      body.fechaBajaEnvanet = this.gestorInfo?.fechaBajaEnvanet;
      body.gestorResiduos = this.datosGeneralesForm.get('gestorResiduos')?.value;
      body.acondicionadorEnvase = this.datosGeneralesForm.get('acondicionador')?.value;


      body.fechaBaja = (this.datosGeneralesForm.get('fechaBaja')?.value) ? moment(new Date(this.datosGeneralesForm.get('fechaBaja')?.value)).format('YYYY-MM-DD') : '';
      body.abonoDeposito = this.datosGeneralesForm.get('abonoDeposito')?.value;
      body.autorizacionComuniaciones = this.datosGeneralesForm.get('autorizacionComuniaciones')?.value;
      if (this.canViewResource('PO-ENV-GEST')) {
        body.acuerdoEnvalora =
          this.datosGeneralesForm.get('acuerdoEnv')?.value === 'Si'
            ? true
            : this.datosGeneralesForm.get('acuerdoEnv')?.value === 'No'
              ? false
              : null;
      }
      this.gestoresResiduosControllerService
        .editById4(this.gestorInfo.id!, body)
        .subscribe({
          next: (response: any) => {
            this.snackBarSrv.showSnackBar(
              'Datos generales guardados correctamente',
              'success'
            );
            this.refrescarGestorEvent.emit(true);
          },
          error: (err) => {
            let mensaje = "Ha ocurrido un error al guardar los datos generales: " + err.error?.cause;
            this.snackBarSrv.showSnackBar(
              mensaje,
              'error'
            );
          },
        });
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  tipoDocumentoSel(idTipoDoc: string) {
    this.datosGeneralesForm.get('codigoDocumento')?.clearValidators();
    if (idTipoDoc === 'NIF') {
      this.datosGeneralesForm
        .get('codigoDocumento')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.nifPattern),
        ]);
    } else if (idTipoDoc === 'DNI') {
      this.datosGeneralesForm
        .get('codigoDocumento')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.dniPattern),
        ]);
    } else if (idTipoDoc === 'NIE') {
      this.datosGeneralesForm
        .get('codigoDocumento')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.niePattern),
        ]);
    }
    this.datosGeneralesForm.get('codigoDocumento')?.updateValueAndValidity();
    this.datosGeneralesForm.get('codigoDocumento')?.markAsTouched();
  }

  controlGeneralForm() {
    if (this.canAccessContinuarAll() || this.canAccessGuardar()) {
      this.controlFormEnable();
    } else {
      this.controlFormDisable();
    }
    if (!this.canViewResource('PO-ENV-GEST')) {
      this.datosGeneralesForm.get('acuerdoEnv')?.disable();
    } else {
      this.datosGeneralesForm.get('acuerdoEnv')?.enable();
    }
  }

  controlFormDisable() {
    this.datosGeneralesForm.disable();
  }

  controlFormEnable() {
    this.datosGeneralesForm.enable();
    this.datosGeneralesForm.get('fechaBajaEnvanet')?.disable();
    this.datosGeneralesForm.get('fechaAltaFinalizada')?.disable();
    this.datosGeneralesForm.get('acuerdoEnv')?.disable();
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.datosGeneralesForm.controls[controlName].hasError(errorName) &&
      this.datosGeneralesForm.controls[controlName].touched
    );
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.datosGeneralesForm.controls[controlName]?.touched ||
        this.datosGeneralesForm.controls[controlName]?.dirty) &&
      this.datosGeneralesForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  onGestorAcondicionadorChange(): void {
    const acondicionador = this.datosGeneralesForm.get('acondicionador')?.value;
    const gestor = this.datosGeneralesForm.get('gestorResiduos')?.value;
    const nInscripControl = this.datosGeneralesForm.get('n_inscrip_1');
    const abonoDepositoControl = this.datosGeneralesForm.get('abonoDeposito');
    
    if(acondicionador === true){
      abonoDepositoControl?.setValidators([Validators.required]);
    }else{
      abonoDepositoControl?.clearValidators();
    }
    if (gestor === false) {
      nInscripControl?.clearValidators();
      nInscripControl?.setValidators([Validators.maxLength(255)]);
    } else {
      nInscripControl?.setValidators([Validators.required, Validators.maxLength(255)]);
    }
    nInscripControl?.updateValueAndValidity();
    abonoDepositoControl?.updateValueAndValidity();
  }

  canAccessContinuarAll(): boolean {
    return (
      this.authService.can(
        'poseedores-gestor-detalle',
        'access-b-guardar-continuar'
      ) && this.gestorInfo?.gestoresResiduosEstado?.id == 1
    );
  }

  canAccessGuardar() {
    return (
      this.authService.can('poseedores-gestor-detalle', 'access-b-guardar') &&
      this.gestorInfo?.gestoresResiduosEstado &&
      [1, 2].includes(this.gestorInfo?.gestoresResiduosEstado?.id!)
    );
  }

  canAccessDate(): boolean {
    return (
      this.authService.can('poseedores-gestor-detalle', 'udpate-baja') &&
      this.gestorInfo?.gestoresResiduosEstado &&
      ![1].includes(this.gestorInfo?.gestoresResiduosEstado?.id!)
    );
  }

  showClearButton(formName: string): boolean {
    return this.datosGeneralesForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.datosGeneralesForm.get(formName)?.setValue(null);
  }

  canViewResource(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

  canEditAgreement(): boolean {
    return this.canViewResource('PO-ENV-GEST') && this.gestorInfo?.gestoresResiduosEstado?.id !== 3;
  }

  canViewAgreement(): boolean {
    return this.canViewResource('PO-ENV-GEST');
  }
  
  gestorAcondicionadorValidator() {
    return (formGroup: FormBuilder) => {
      const gestorResiduos = this.datosGeneralesForm?.get('gestorResiduos')?.value;
      const acondicionador = this.datosGeneralesForm?.get('acondicionador')?.value;

      if (!gestorResiduos && !acondicionador) {
        return { gestorAcondicionadorChecked: true };
      }

      return null;
    };
  }

}
