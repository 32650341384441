/**
 * SDRR-Abierto API
 * SDRR-Abierto API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UsuarioSDDRCaDTO { 
    id?: number;
    tipoUsuario?: UsuarioSDDRCaDTO.TipoUsuarioEnum;
    razonSocial?: string;
    denominacion?: string;
}
export namespace UsuarioSDDRCaDTO {
    export type TipoUsuarioEnum = 'ADHERIDO' | 'POSEEDOR';
    export const TipoUsuarioEnum = {
        Adherido: 'ADHERIDO' as TipoUsuarioEnum,
        Poseedor: 'POSEEDOR' as TipoUsuarioEnum
    };
}


