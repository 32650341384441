/**
 * Puntos de recogida API
 * Puntos de recogida API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { AdheridoFacturaDTO } from './adheridoFacturaDTO';
import { GestorPtoRecogidaFacturaDTO } from './gestorPtoRecogidaFacturaDTO';
import { GestoresResiduosFacturaDTO } from './gestoresResiduosFacturaDTO';
import { DocumentoDTO } from './documentoDTO';
import { FacturaEstadoDTO } from './facturaEstadoDTO';
import { PoseedorFacturaDTO } from './poseedorFacturaDTO';


export interface FacturaDTO { 
    fechaAlta?: string;
    creadoPor?: string;
    fechaModificacion?: string;
    modificadoPor?: string;
    id?: number;
    poseedor?: PoseedorFacturaDTO;
    gestorPtoRecogida?: GestorPtoRecogidaFacturaDTO;
    gestoresResiduos?: GestoresResiduosFacturaDTO;
    adherido?: AdheridoFacturaDTO;
    origen?: FacturaDTO.OrigenEnum;
    numeroFactura?: number;
    estado?: FacturaEstadoDTO;
    documento?: DocumentoDTO;
    documentoAutofactura?: DocumentoDTO;
    fechaAutofactura?: string;
    fechaFactura?: string;
}
export namespace FacturaDTO {
    export type OrigenEnum = 'POSEEDORES' | 'PUNTOSRECOGIDA' | 'SDDRCC' | 'SDDRCA' | 'ADHERIDO' | 'GESTORESACUERDO';
    export const OrigenEnum = {
        Poseedores: 'POSEEDORES' as OrigenEnum,
        Puntosrecogida: 'PUNTOSRECOGIDA' as OrigenEnum,
        Sddrcc: 'SDDRCC' as OrigenEnum,
        Sddrca: 'SDDRCA' as OrigenEnum,
        Adherido: 'ADHERIDO' as OrigenEnum,
        Gestoresacuerdo: 'GESTORESACUERDO' as OrigenEnum
    };
}


