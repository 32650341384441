import { Inject, OnInit, ViewChild } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AttachedService } from '../../../services/attached.service';
import { Adherido } from '../../../models/adherido';
import { SpinnerService } from '../../../services/spinner.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import swal from 'sweetalert2';
import { SnackbarService } from '../../../services/snackbar.service';
import { DetailGeneralDataComponent } from '../detail-general-data/detail-general-data.component';

@Component({
  selector: 'lib-attached-detail',
  templateUrl: './attached-detail.component.html',
  styleUrls: ['./attached-detail.component.scss'],
})
export class AttachedDetailComponent implements OnInit {
  selectedMenu = 1;
  lastMenu = 1;
  id!: number;
  private sub: any;

  pendienteAdherido: boolean = false;
  pendienteEnvalora: boolean = false;
  pendienteCompletado: boolean = false;
  rechazado: boolean = false;

  adheridoInfo!: Adherido;
  tiposCnae!: any[];
  estadoAdherido!: number | undefined;

  flagRuta: boolean = false;
  textBton: string = 'Cancelar';

  rolActive: any;

  menuEnum: any = {
    1: 'adheridos-detalle',
    2: 'adheridos-contactos',
    3: 'adheridos-direcciones',
  };

  textoEstado: string = '';
  datosCargados = false;

  @ViewChild(DetailGeneralDataComponent, { static: false })
  childGeneralDataComponent: DetailGeneralDataComponent | undefined;

  constructor(
    private route: ActivatedRoute,
    private attachedSrv: AttachedService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    public spinnerSrv: SpinnerService,
    private router: Router,
    private snackBarSrv: SnackbarService
  ) {
    this.sub = this.route.params.subscribe((params) => {
      this.id = +params['id']; // (+) converts string 'id' to a number
    });
  }

  ngOnInit(): void {
    this.flagRuta = this.attachedSrv.getTempRutaSearch;

    this.spinnerSrv.loadingShow();
    this.attachedSrv.getAdheridoPorId(this.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.adheridoInfo = response;
        this.datosCargados = true;
        this.estadoAdherido = this.adheridoInfo?.estado?.id;
        switch (this.estadoAdherido) {
          case 1:
            this.pendienteAdherido = true;
            break;
          case 2:
            this.pendienteAdherido = true;
            break;
          case 3:
            this.pendienteEnvalora = true;
            break;
          case 4:
            this.pendienteAdherido = true;
            break;
          case 6:
            this.pendienteCompletado = true;
            break;
          case 7:
            break;
          case 8:
            this.rechazado = true;
            break;
        }
        this.textoEstado = this.adheridoInfo?.estado?.descripcion_corta!;
        this.controlGeneralForm();
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
        console.log(err);
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al cargar el adherido',
          'error'
        );
      },
    });
  }

  loadNumMenu(num: number): void {
    this.controlGeneralForm();
    this.selectedMenu = num;
    if (this.lastMenu < num) {
      this.lastMenu = num;
    }
  }
  anterior(): void {
    let aux = this.selectedMenu;
    if (aux - 1 > 0) {
      this.selectedMenu = aux - 1;
    }
  }

  siguiente(): void {
    let aux = this.selectedMenu;
    if (aux + 1 <= 3) {
      this.selectedMenu = aux + 1;
    }
  }

  continuarName(menu: number): string {
    switch (menu) {
      case 1:
        return (this.authService.can(
          this.menuEnum[menu],
          'access-b-continuar'
        ) && this.estadoAdherido == 1 && !this.esGestorEnvalora())
          ? 'Guardar y Continuar '
          : 'Guardar';
        break;
      case 2:
        return this.authService.can(
          this.menuEnum[menu],
          'access-b-continuar'
        ) && this.estadoAdherido == 1 && !this.esGestorEnvalora()
          ? 'Continuar'
          : '';
        break;
      default:
        return '';
        break;
    }
  }

  canAccessContinuarAll(menu?: number): boolean {
    switch (menu) {
      case 1:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-continuar') &&
          this.estadoAdherido == 1 && !this.esGestorEnvalora()
        );
      case 2:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-continuar') &&
          this.estadoAdherido == 1 && !this.esGestorEnvalora()
        );
      default:
        return false;
    }
  }

  controlAccionsWizard(idMenu?: number) {
    switch (idMenu) {
      case 1:
        if (this.canAccessContinuarAll(idMenu)) {
          this.childGeneralDataComponent?.guardar();
        } else if (this.canAccessGuardar(idMenu)) {
          this.childGeneralDataComponent?.guardar();
        }
        break;
      case 2:
        if (this.verificarObligatorioContactos()) {
          this.snackBarSrv.showSnackBar(
            'Debe completar los datos en los contactos requeridos',
            'error'
          );
        //} else if (this.verificarContactosRequeridos()) {
        //  this.snackBarSrv.showSnackBar(
        //    'Los contactos requeridos deben estar activos',
        //    'error'
        //  );
        } 
        else {
          this.snackBarSrv.showSnackBar('Contactos satisfactorios', 'success');
          this.siguiente();
        }
        break;
      default:
        break;
    }
  }

  verificarObligatorioContactos() {
    const objetoCampoObligatorios = {
      tipoContacto: true,
      persona: true,
      nombre: true,
      apellido1: false,
      tipo_documento: false,
      codigo_documento: false,
      tipo_telefono: true,
      prefijoPais: true,
      numero_telefono: true,
      email: true,
      recibir_comunicaciones: true,
      recibir_comunicaciones_contrato: true,
      acreditacion_poderes: false,
    };

    if (this.adheridoInfo.contactos.length != 0) {
      const tieneCamposObligatoriosVacios = this.adheridoInfo.contactos
        .filter((objeto: any) => objeto.requerido)
        .map((item: any) => {
          if (item?.tipo_contacto.id === 3 || item?.tipo_contacto.id === 4) {
            objetoCampoObligatorios.tipo_documento = true;
            objetoCampoObligatorios.codigo_documento = true;
            objetoCampoObligatorios.acreditacion_poderes = true;
          } else {
            objetoCampoObligatorios.tipo_documento = false;
            objetoCampoObligatorios.codigo_documento = false;
            objetoCampoObligatorios.acreditacion_poderes = false;
          }
          if (item?.persona) {
            objetoCampoObligatorios.apellido1 = true;
          } else {
            objetoCampoObligatorios.apellido1 = false;
          }
          const data = Object.entries(objetoCampoObligatorios).some(
            ([campo, obligatorio]) =>
              obligatorio && (item[campo] === '' || item[campo] === null)
          );
          return data;
        })
        .some((resultado) => resultado);
      return tieneCamposObligatoriosVacios;
    } else {
      return false;
    }
  }

  verificarContactosRequeridos(){
    if(this.estadoAdherido == 1){
      //Todos los contactos requeridos tienen que estar activos
      const reqObsoletos = this.adheridoInfo.contactos.filter((item:any) => item.obsoleto && item.requerido);
      if(reqObsoletos && reqObsoletos.length > 0){
        return true;
      }
    }
    return false;
  }

  verificarObligatorioDirecciones() {
    // Define un objeto con los campos obligatorios y los inicializa
    const objetoCampoObligatorios = {
      provincia: false,
      codigoPostal: false,
      municipio: false,
      tipoDireccion: false,
      tipoVia: false,
      nombreVia: false,
      numero: false,
      pais: true,
    };

    // Verifica si hay domicilios
    if (this.adheridoInfo?.domicilios?.length != 0) {
      // Filtra los domicilios requeridos y luego verifica si algún campo obligatorio está vacío
      const tieneCamposObligatoriosVacios = this.adheridoInfo?.domicilios
        ?.filter((objeto: any) => objeto.requerido) // Filtra los domicilios requeridos
        .map((item: any) => {
          // Si el país es igual a 69, los campos provincia, codigoPostal y municipio son obligatorios
          if (item.pais?.id === 69) {
            objetoCampoObligatorios.provincia = true;
            objetoCampoObligatorios.codigoPostal = true;
            objetoCampoObligatorios.municipio = true;
            objetoCampoObligatorios.nombreVia = true;
            objetoCampoObligatorios.numero = true;
          } else {
            objetoCampoObligatorios.provincia = false;
            objetoCampoObligatorios.codigoPostal = false;
            objetoCampoObligatorios.municipio = false;
            objetoCampoObligatorios.nombreVia = false;
            objetoCampoObligatorios.numero = false;
          }
          return Object.entries(objetoCampoObligatorios).some(
            ([campo, obligatorio]) =>
              obligatorio && (item[campo] === '' || item[campo] === null)
          );
        })
        .some((resultado: any) => resultado); // Verifica si alguno de los resultados es verdadero
      return tieneCamposObligatoriosVacios; // Devuelve si hay campos obligatorios vacíos
    } else {
      return false; // Si no hay domicilios, devuelve falso
    }
  }

  canAccessAnteriorAll(menu: number): boolean {
    switch (menu) {
      case 2:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-anterior') &&
          this.estadoAdherido == 1 && !this.esGestorEnvalora()
        );
        break;
      case 3:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-anterior') &&
          this.estadoAdherido == 1 && !this.esGestorEnvalora()
        );
        break;
      default:
        return false;
        break;
    }
  }

  canAccessGuardar(menu: number) {
    switch (menu) {
      case 1:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-guardar') &&
          ((this.estadoAdherido && [2, 3, 4, 5, 6].includes(this.estadoAdherido)) || this.esGestorEnvalora())
        );
        break;
      default:
        return false;
        break;
    }
  }

  canAccessDocumentos(): boolean {
    return this.authService.can('adheridos-documentos', 'access');
  }

  canAccessCentroEnvasados(): boolean {
    return this.authService.can('adheridos-centros', 'access');
  }

  canAccessPrevisionSDDR(): boolean {
    return this.authService.can('adheridos-sddr-cc', 'access');
  }

  canAccessVolver(): boolean {
    return this.authService.can('adheridos-detalle', 'access-b-volver');
  }

  buttonVolver() {
    this.router.navigate(['/attached-app/search']);
  }

  canAccessCancelar(menu: number) {
    switch (menu) {
      case 1:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-cancelar') &&
          this.estadoAdherido &&
          [2, 3, 4, 5, 6].includes(this.estadoAdherido)
        );
        break;
      case 2:
        return this.authService.can(this.menuEnum[menu], 'access-b-cancelar');
        break;
      case 3:
        return this.authService.can(this.menuEnum[menu], 'access-b-cancelar');
        break;
      default:
        return false;
        break;
    }
  }

  buttonCancelar() {
    swal
      .fire({
        text: '¿Desea cancelar esta acción?. Regresará al buscador.',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.router.navigate(['/attached-app/search']);
        }
      });
  }

  refrescar(menu: number): void {
    this.controlGeneralForm();
    this.selectedMenu = menu;
    this.spinnerSrv.loadingShow();
    this.attachedSrv.getAdheridoPorId(this.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.adheridoInfo = response;
        this.estadoAdherido = this.adheridoInfo?.estado?.id;
        if (this.selectedMenu == 1 && this.estadoAdherido == 1 && !this.esGestorEnvalora()) {
          this.siguiente();
        }
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
        console.log(err);
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al cargar el adherido',
          'error'
        );
      },
    });
  }

  canAccessFinalizar(menu: number): boolean {
    switch (menu) {
      case 3:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-continuar') &&
          this.estadoAdherido == 1 && !this.esGestorEnvalora()
        );
        break;
      default:
        return false;
        break;
    }
  }

  finalizar() {
    if (this.verificarObligatorioDirecciones()) {
      this.snackBarSrv.showSnackBar(
        'Debe completar los datos en las direcciones requeridas',
        'error'
      );
    } else {
      if (this.adheridoInfo.estado) {
        this.adheridoInfo.estado.id = 2;
      }
      this.attachedSrv.putAdherido(this.id, this.adheridoInfo).subscribe({
        next: () => {
          this.snackBarSrv.showSnackBar(
            'Proceso finalizado correctamente. Los datos serán revisados por Envalora y, en los próximos días, recibirá un correo electrónico con instrucciones para la firma del contrato.',
            'success'
          );
          this.router.navigate(['/initial-page']);
        },
        error: () => {
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al finalizar el registro del adherido',
            'error'
          );
        },
      });
    }
  }

  controlGeneralForm() {
    if (this.canAccessContinuarAll(1) || this.canAccessGuardar(1)) {
      this.childGeneralDataComponent?.controlFormEnable();
    } else {
      this.childGeneralDataComponent?.controlFormDisable();
    }
  }

  mensajeValidacion() {
    this.snackBarSrv.showSnackBar(
      'Para poder desplazarse de una pantalla a otra deberá hacerlo mediante los botones de la parte inferior.'
    );
  }

  esGestorEnvalora(){
    return (this.canViewRol('AD-ENG-GEST') || this.canViewRol('AD-ENV-CONS'));
  }

  canViewRol(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

}
