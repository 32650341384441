import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InitialPageComponent } from '../pages/initial-page.component';
import { RouterModule } from '@angular/router';
import { MaterialModule } from './material.module';
import { DocumControllerService, DocumentoInteresControllerService } from 'api-rest';
import { EmpusaSpinnerComponent } from '@empusa/empusa-core';

export const explorerRoutes = [
  {
    path: '', component: InitialPageComponent, children: []
  },
];

@NgModule({
  declarations: [
    InitialPageComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(explorerRoutes),
    MaterialModule,
    EmpusaSpinnerComponent
  ],
  exports: [
    InitialPageComponent
  ],
  providers:[
    DocumentoInteresControllerService
  ]
})
export class InitialPageHomeModule {
  constructor() {
    let compilationVersion = "0.0.1-R->04/10/23-12:14:49"
    console.log("Initial page version:" + compilationVersion)
  }
}
