import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  AdheridoControllerService,
  AdheridoEstadoControllerService,
  PageAdheridoEstadoModel
} from 'api-rest';
import { AttachedService } from '../../../services/attached.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';


@Component({
  selector: 'lib-attached-status-change-dialog',
  templateUrl: './attached-status-change-dialog.component.html',
  styleUrls: ['./attached-status-change-dialog.component.scss'],
})
export class AttachedStatusChangeDialogComponent implements OnInit {

  estados: any[] = [];
  adheridos:any[] = [];
  estadoActual:any;
  
  formGroup!: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AttachedStatusChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private adheridoSrv: AdheridoControllerService,
    private adheridoEstadoSrv: AttachedService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    @Inject('environment') private environment: any,
    private snackBarSrv: SnackbarService,
    public spinnerSrv: SpinnerService
  ) {
    this.adheridos = this.data.adheridos;
    this.estadoActual = this.data.estadoActual;

    this.formGroup = this.formBuilder.group({
      estado: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) }
      ]
    });
  }

  ngOnInit(): void {
    this.cargarEstados();
  
    this.cargarEstadoActual();
  }

   cargarEstados(): void {
    this.adheridoEstadoSrv.getEstadosAdheridos().subscribe({
      next: (data: any) => {
        this.estados = data!.content;
      },
      error: () => { },
    });
  }

  cargarEstadoActual() {
    this.formGroup.get('estado')?.setValue(this.estadoActual);
  }

  guardar() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.valid) {
      const estadoId = this.formGroup.get('estado')?.value;
      const adheridosId = this.adheridos.map(x => x.id);
      this.spinnerSrv.loadingShow();
      this.adheridoSrv
        .multipleActualizarEstado(estadoId, adheridosId)
        .subscribe({
          next: (value: any) => {
            console.log(value);
            this.spinnerSrv.loadingHide();
            this.dialogRef.close(true);
            let mensaje = 'Se ha cambiado el estado de los adheridos seleccionados.';
            if(value && value.length > 0){
              mensaje = mensaje + '\n' + value.join('\n');
            }
            this.snackBarSrv.showSnackBar(
              mensaje,
              'success'
            );
          },
          error: (err: { message: any }) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al cambiar el estado de los adheridos',
              'error'
            );
          },
        });

    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  cerrar(): void {
    this.dialogRef.close(null);
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }

  controlHasErrorSelect(controlName: string) {
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

}
