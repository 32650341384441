import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder } from '@angular/forms';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import { GestoresResiduosControllerService, GestoresResiduosDTO } from 'api-rest';
import { DownloadService } from '../../..//services/download.service';


@Component({
  selector: 'lib-waste-managers-package-reconditioning-upload-dialog',
  templateUrl: './waste-managers-package-reconditioning-upload-dialog.component.html',
  styleUrls: ['./waste-managers-package-reconditioning-upload-dialog.component.scss'],
})
export class WasteManagersPackageReconditioningUploadDialogComponent implements OnInit {

  titulo = "Importar acondicionamiento de envases";
  nombreArchivo: string = '';
  file: File | undefined;
  @ViewChild('fileInput') fileInput: any;

  gestorInfo!: GestoresResiduosDTO | any

  constructor(
    public dialogRef: MatDialogRef<WasteManagersPackageReconditioningUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private FormBuilder: FormBuilder,
    private snackBarSrv: SnackbarService,
    public spinnerSrv: SpinnerService,
    public gestoresResiduosService: GestoresResiduosControllerService,
    private downloadService: DownloadService
  ) {
    this.gestorInfo = this.data.gestorInfo;
  }

  ngOnInit() {
  }


  adjuntar() {
    this.fileInput.nativeElement.click();
  }

  handleFileInput(event: any) {
    this.file = event.target.files[0];
    if (this.file && this.file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      this.nombreArchivo = this.file.name;
      this.snackBarSrv.showSnackBar('Se ha adjuntado el archivo Excel correctamente.', 'success');
    } else {
      this.snackBarSrv.showSnackBar('Por favor, seleccione un archivo Excel (.xlsx).', 'error');
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  Importar() {
    if(this.file) {
      this.spinnerSrv.loadingShow();
      this.gestoresResiduosService.importAcondicionamientos(this.gestorInfo!.id!,  this.file ).subscribe({
        next: res => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar('Importación realizada correctamente', 'success');
          this.dialogRef.close(true);
        },
        error: error => {
          this.spinnerSrv.loadingHide();
          if(error.error?.detail) {
            this.snackBarSrv.showSnackBar(error.error?.detail, 'error');
          } else {
            this.snackBarSrv.showSnackBar('Ha ocurrido un error al importar la plantilla. Por favor, contacte con el administrador de sistemas', 'error');
          }
        }
      })
    }
  }

  descargar() {
    this.spinnerSrv.loadingShow();
    this.gestoresResiduosService.exportAcondicionamientosTemplate('response').subscribe({
      next: res => {
        const templateName = this.downloadService.getFileName(res.headers);
        this.downloadService.downloadBlob(res.body!, templateName!);
        this.spinnerSrv.loadingHide();
      },
      error: error => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar('Ha ocurrido un error al descargar la plantilla', 'error');
      }
    })
  }



}
