import { EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { Component } from '@angular/core';
import { AttachedService } from '../../../services/attached.service';
import { Adherido } from '../../../models/adherido';
import { MatTableDataSource } from '@angular/material/table';
import { DownloadService } from '../../../services/download.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import swal from 'sweetalert2';
import moment from 'moment';
import { NewDocumentDialogComponent } from '../new-document-dialog/new-document-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import {DocumentoModel} from "api-rest";

const TIPO_DOC_CONTRATO = 1;
const TIPO_DOC_FACTURA = 2;
const TIPO_DOC_CERTIFICADO = 3;
@Component({
  selector: 'lib-detail-documentation',
  templateUrl: './detail-documentation.component.html',
  styleUrls: ['./detail-documentation.component.scss'],
})
export class DetailDocumentationComponent implements OnInit {
  @Output() public refrescarAdheridoEvent = new EventEmitter<any>();
  @Input() adheridoInfo!: any;

  documentos: any;
  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = [
    'tipo',
    'descripcion',
    'fechaCreacion',
    'fechaModificacion',
    'usuarioModificacion',
    'acciones',
  ];

  gridArray: any = [];
  headArray: any = [
    {
      Head: 'Tipo',
      FieldName: 'tipoDocumento',
      Attribute: 'descripcion',
    },
    {
      Head: 'Descripción',
      FieldName: 'descripcion',
      Tooltip: true,
      Maxtext: 30,
    },
    {
      Head: 'Fecha Creación',
      FieldName: 'fechaCreacion',
      Tooltip: true,
    },
    {
      Head: 'Fecha Ult. Modificación',
      FieldName: 'fechaModificacion',
    },
    {
      Head: 'Modificado por',
      FieldName: 'usuarioModificacion',
      Tooltip: true,
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'donwload',
          icon: 'download',
          toolTip: 'Descargar',
          show: { params: 'canAccessDonwload', accion: 'donwload' },
        },
        // {
        //   nameButton: 'upload',
        //   icon: 'upload',
        //   toolTip: 'Subir',
        //   show: { params: 'canAccessUpload', accion: 'upload' },
        // },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
      ],
      width: '8',
      permanent: true,
    },
  ];

  archivoSeleccionado: File | null = null;

  constructor(
    private attachedSrv: AttachedService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    private downloadSrv: DownloadService,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.cargarDocumentos();
  }

  onArchivoSeleccionado(event: any): void {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.archivoSeleccionado = fileList[0];
    }
  }
  cargarDocumentos() {
    this.spinnerSrv.loadingShow();
    this.attachedSrv.getDocumentos(this.adheridoInfo.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.documentos = response;
        const filteredDocuments = response.filter((doc: DocumentoModel) =>
          [1, 2, 3, 4, 5].includes(doc.tipoDocumento?.id!)
        );
        this.gridArray = this.canAccessAll(filteredDocuments.reverse());
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
        console.log(err);
      },
    });
  }

  descargar(element: any) {
    this.spinnerSrv.loadingShow();
    this.attachedSrv.descargarDocumento(element.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Se ha descargado el documento correctamente',
          'success'
        );
        this.downloadSrv.downloadBlob(response, element.nombre);
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al descargar el documento',
          'error'
        );
      },
    });
  }

  subir(archivo: any, element: any): void {
    let id = this.adheridoInfo.id! ?? null;
    this.spinnerSrv.loadingShow();
    this.attachedSrv
      .putDocumento(
        archivo,
        element.id,
        'adheridos-dev',
        id,
        element.tipoDocumento.id,
        'adherido',
        archivo?.name
      )
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha subido correctamente el contrato',
            'success'
          );
          this.cargarDocumentos();
          this.actualizarEstado(element);
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al subir el contrato',
            'error'
          );
        },
      });
  }

  eliminarDocumento(element: any) {
    this.spinnerSrv.loadingShow();
    this.attachedSrv.eliminarDocumento(element.id).subscribe({
      next: (response) => {
        this.snackBarSrv.showSnackBar(
          'Se ha eliminado correctamente el documento',
          'success'
        );
        this.spinnerSrv.loadingHide();
        this.cargarDocumentos();
      },
      error: (err) => {
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al eliminar el documento',
          'error'
        );
        this.spinnerSrv.loadingHide();
        console.log(err);
      },
    });
  }

  canAccessNuevoDoc(): boolean {
    return this.authService.can('adheridos-documentos', 'create');
  }

  canAccessExportar(): boolean {
    return this.authService.can('adheridos-documentos', 'access');
  }

  actualizarEstado(documento: any) {
    if (this.adheridoInfo.estado) {
      this.adheridoInfo.estado.id = 4;
      this.adheridoInfo.estado.descripcion_corta =
        'Pendiente Declaración Envases Inicial';
      this.adheridoInfo.estado.descripcion =
        'Pendiente Declaración Envases Inicial';
    }
    if (documento?.tipoDocumento?.id == TIPO_DOC_CONTRATO) {
      this.adheridoInfo.fechaFirmaContrato = new Date();
    }
    this.attachedSrv
      .putAdherido(this.adheridoInfo.id!, this.adheridoInfo)
      .subscribe({
        next: (response: Adherido) => {
          this.refrescarAdheridoEvent.emit(true);
        },
        error: (err) => {},
      });
  }

  action(item: any) {
    if (item.nameButton == 'donwload') {
      this.descargar(item);
    } else if (item.nameButton == 'upload') {
      this.openFileSelector(item);
    } else if (item.nameButton == 'delete') {
      this.confirmacionEliminar(item);
    }
  }

  openFileSelector(item: any) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.style.display = 'none';
    fileInput.addEventListener('change', (event) =>
      this.handleFileSelection(event, item)
    );
    document.body.appendChild(fileInput);
    fileInput.click();
    document.body.removeChild(fileInput);
  }

  handleFileSelection(event: any, item: any) {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      this.subir(selectedFile, item);
    }
  }

  confirmacionEliminar(element?: any): void {
    swal
      .fire({
        text: '¿Desea eliminar Documento?',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.eliminarDocumento(element);
        }
      });
  }

  canAccessAll(list: any) {
    const descargarView = this.authService.can(
      'adheridos-documentos',
      'update'
    );
    const deleteView = this.authService.can('adheridos-documentos', 'delete');
    const listPermission = list.map((objeto: any) => {
      let fechaFormato = moment(new Date(objeto.fechaCreacion)).format(
        'DD/MM/YYYY'
      );
      let fechaModificacion = moment(new Date(objeto.fechaModificacion)).format(
        'DD/MM/YYYY'
      );
      const doc = [
        TIPO_DOC_CONTRATO,
        TIPO_DOC_FACTURA,
        TIPO_DOC_CERTIFICADO,
      ].includes(objeto?.tipoDocumento?.id);
      objeto.fechaCreacion = fechaFormato;
      objeto.fechaModificacion = fechaModificacion;
      const flagUpload =
        objeto.descripcion == 'Contrato pendiente de firma' ? false : true;
      if (objeto.descripcion === 'null') {
        objeto.descripcion = '';
      }
      return {
        ...objeto,
        canAccessUpload: false, //TODO Pediente
        canAccessDonwload: descargarView && flagUpload,
        canAccessDelete: this.canViewRol('AD-ENV-GEST')
          ? deleteView && this.canViewRol('AD-ENV-GEST')
          : deleteView && !doc,
      };
    });

    return listPermission;
  }

  canViewRol(rol: any) {
    return this.authService.hasCurrentUserRole(rol);
  }

  abrirNuevoDocumento() {
    const dialogRef = this.dialog.open(NewDocumentDialogComponent, {
      width: '950px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: {
        adherido: this.adheridoInfo,
      },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cargarDocumentos();
      }
    });
  }
}
