import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from "@angular/router";
import {SpinnerService} from "../services/spinner.service";

@Component({
  selector: 'lib-open-sddr',
  templateUrl: './open-sddr.component.html',
  styleUrls: ['./open-sddr.component.scss']
})
export class OpenSddrComponent implements OnInit {
  @ViewChild('containerMainRouter') containerMainRouter!: ElementRef;
  constructor(public spinnerSrv: SpinnerService, private router: Router) {}

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd && this.containerMainRouter) {
        this.containerMainRouter.nativeElement.scrollTop = 0;
      }
    });
  }
}
