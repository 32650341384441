import { NgModule } from '@angular/core';
import { EmpusaMicroApp, EmpusaMicroAppType } from '@empusa/empusa-core';
import { OpenSddrHomeModule } from './open-sddr.home.module';

@NgModule({
  declarations: [],
  imports: [],
  exports: [],
})
export class OpenSddrModule implements EmpusaMicroApp {
  name: string = 'open-sddr';
  description: string = 'SDDR abierto';
  icon: string = 'language';
  page: any = OpenSddrHomeModule;
  homeApp: boolean = false;
  path: string = 'open-sddr';
  order: number = 10;
  resourceServerId: string = 'keycloak-auth-manager-resources-server';
  appType: EmpusaMicroAppType = EmpusaMicroAppType.home;

  constructor() {
    //we register ourselves
    (<any>window).empusa(this);
    console.debug('Load microapp ', this.name);
  }
}


