import { Component, Inject } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  DocumentoInteresControllerService,
  KeycloakRoleControllerService,
  KeycloakRoleDTO,
} from 'api-rest';
import { map } from 'rxjs';
import { Combo, ComboService } from '../../../../services/combo.service';
import { SnackbarService } from '../../../../services/snackbar.service';
import { MappedResponse } from '../../../../share/components/ge-select-pagination/ge-select-pagination.component';
import { RateCategoryTypeEnum } from '../../../models/rate-category-type.enum';
import { SpinnerService } from '../../../../services/spinner.service';
import { FormatCurrencyPipe } from '../../../../share/components/pipe/FormatCurrency.pipe';
import { ParseCurrencyPipe } from '../../../../share/components/pipe/ParseCurrency.pipe';
import { DownloadService } from '../../../../services/download.service';


@Component({
  selector: 'lib-documents-interest-detail-modal',
  templateUrl: './documents-interest-detail-modal.component.html',
  styleUrls: ['./documents-interest-detail-modal.component.scss'],
})
export class DocumentsInterestDetailModalComponent {

  documentoInteres: any;
  newItem: boolean = true;
  formGroup!: FormGroup;
  roles: Combo[] = [];
  editMode = false;
  defaultFilter: boolean = true;
  documento: File | undefined;

  constructor(
    @Inject('environment') private environment: any,
    public dialogRef: MatDialogRef<DocumentsInterestDetailModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private roleService: KeycloakRoleControllerService,
    private documentosInteresService: DocumentoInteresControllerService,
    private comboService: ComboService,
    private snackbarService: SnackbarService,
    public spinnerSrv: SpinnerService,
    private formatCurrencyPipe: FormatCurrencyPipe,
    private parseCurrencyPipe: ParseCurrencyPipe,
    private downloadSrv: DownloadService
  ) {
    this.documentoInteres = this.data?.item;
    this.newItem = !this.documentoInteres?.id;
    this.editMode = this.data?.editMode || this.newItem;
  }

  ngOnInit(): void {
    this.initializeForm();
    this.roles = this.comboService.getDocumentRoles();
    if(this.editMode && this.documentoInteres?.id){
      this.buscarRolesDocumento();
    }
    if(this.editMode){
      this.cargarDocumento();
    }
  }

  cargarDocumento(){
    this.formGroup?.get("name")?.setValue(this.documentoInteres?.nombre);
  }

  initializeForm() {
    this.formGroup = this.formBuilder.group({
      rol: [{ value: null, disabled: false }],
      name: [{ value: null, disabled: false }, {validators: Validators.compose([Validators.maxLength(255)])} ]
    });

    if (!this.editMode) {
      this.formGroup.disable();
    }
  }

  habilitarEdicion() {
    this.formGroup.enable();
    this.editMode = true;
  }

  guardar() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.valid) {
      if (this.documentoInteres?.documento == null) {
        if (this.documento == undefined || this.documento == null) {
          this.snackbarService.showSnackBar('Adjunte documento', 'error');
          return;
        }
      }
      const rolesSel = this.formGroup.get("rol")?.value.map((item:any) => item.name);
      let request, successMsg: string, errorMsg: string;
      if(this.documentoInteres){
        let doc:File;
        if(this.documento){
          doc = this.documento;
        }else{
          let byteString: any = this.documentoInteres?.documento;
          let fileType = this.documentoInteres?.contentType ?? 'application/octet-stream';
          doc = this.downloadSrv.b64toFile(byteString, this.documentoInteres.filename, fileType);
        }
        request = this.documentosInteresService.actualizarDocumentoInteres(this.documentoInteres.id, this.formGroup.get("name")?.value, rolesSel, doc);
        successMsg = 'El documento se ha editado correctamente.';
        errorMsg = 'Ha ocurrido un error al editar el documento.';

      }else{
        if(this.documento){
          //Nuevo documento
          request = this.documentosInteresService.subirDocumentoInteres(this.formGroup.get("name")?.value, rolesSel, this.documento);
          successMsg = 'El documento se ha creado correctamente.';
          errorMsg = 'Ha ocurrido un error al crear el nuevo documento.';
        }else{
          this.snackbarService.showSnackBar('Adjunte documento', 'error');
          return;
        }
      }

      this.spinnerSrv.loadingShow();
      request.subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.dialogRef.close(true);
          this.snackbarService.showSnackBar(successMsg, 'success');
        },
        error: () => {
          this.spinnerSrv.loadingHide();
          this.snackbarService.showSnackBar(errorMsg, 'error');
        },
      });


    } else {
      console.log(this.formGroup.errors);
    }
  }

  buscarRolesDocumento(){
    this.spinnerSrv.loadingShow();
    this.documentosInteresService.findAllRoles(this.documentoInteres.id)
      .subscribe({
        next: (data: any) => {
          this.spinnerSrv.loadingHide();
          if (data) {
            const documRolNames = data.map((item:any) => item.role);
            
            const filteredRoles = this.roles.filter(role => documRolNames.includes(role.name));
            this.formGroup?.get("rol")?.setValue(filteredRoles);
          }
        },
        error: (error) => {
          this.spinnerSrv.loadingHide();
          this.snackbarService.showSnackBar(
            'Error obteniendo listado de roles de documento',
            'error'
          );
        },
      });
  }

  cerrar(): void {
    this.dialogRef.close(null);
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }

  getFormControl(controlName: string): FormControl {
    return this.formGroup.controls[controlName] as FormControl;
  }

  mapResponse(response: any): MappedResponse<any> {
    return {
      datos: response.content,
      recordsFiltered: response.recordsTotal,
      totalElements: response.totalElements,
    };
  }

  getEntidadAsociadoFormControl(): FormControl<any> {
    return this.formGroup.controls['entidadAsociada'] as FormControl<any>;
  }

  customValidator() {
    return (control: { value: any }) => {
      const value = control.value;
      if (!value) {
        return null;
      }
      if (/^\d{4}$/.test(value)) {
        const intValue = parseInt(value, 10);
        if (intValue >= 1900 && intValue <= 2500) {
          return null;
        }
      }
      return { invalid: true };
    };
  }
  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  descargar() {
    let byteString: any = this.documentoInteres?.documento;
    let fileType = this.documentoInteres?.contentType ?? 'application/octet-stream';
    let blob = this.downloadSrv.b64toBlob(byteString, fileType)
    this.downloadSrv.downloadBlob(blob, this.documentoInteres?.filename!);
  }

  attachAdjunto(archivo: File | null): void {
    if (archivo) {
      if (archivo.size === 0) {
        this.snackbarService.showSnackBar(
          'No se puede subir un fichero vacío',
          'error'
        );
      } else if (archivo.size > 5 * 1024 * 1024) { // 5 MB
        this.snackbarService.showSnackBar(
          'El fichero supera el tamaño máximo permitido de 5 MB',
          'error'
        );
      } else {
        this.documento = archivo;
      }
    }
  }

}
