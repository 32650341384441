export * from './aportacionDisddrcaController.service';
import { AportacionDisddrcaControllerService } from './aportacionDisddrcaController.service';
export * from './facturaSddrcaController.service';
import { FacturaSddrcaControllerService } from './facturaSddrcaController.service';
export * from './helloWorldController.service';
import { HelloWorldControllerService } from './helloWorldController.service';
export * from './solicitudEnvaseController.service';
import { SolicitudEnvaseControllerService } from './solicitudEnvaseController.service';
export * from './solicitudEnvaseEstadoController.service';
import { SolicitudEnvaseEstadoControllerService } from './solicitudEnvaseEstadoController.service';
export * from './solicitudFacturaExportacionController.service';
import { SolicitudFacturaExportacionControllerService } from './solicitudFacturaExportacionController.service';
export * from './solicitudInformeRetiradaController.service';
import { SolicitudInformeRetiradaControllerService } from './solicitudInformeRetiradaController.service';
export * from './solicitudInformeRetiradaDocumController.service';
import { SolicitudInformeRetiradaDocumControllerService } from './solicitudInformeRetiradaDocumController.service';
export * from './solicitudRetiradaController.service';
import { SolicitudRetiradaControllerService } from './solicitudRetiradaController.service';
export * from './solicitudRetiradaEstadoController.service';
import { SolicitudRetiradaEstadoControllerService } from './solicitudRetiradaEstadoController.service';
export * from './solicitudReutilizacionAcreditacionController.service';
import { SolicitudReutilizacionAcreditacionControllerService } from './solicitudReutilizacionAcreditacionController.service';
export * from './solicitudReutilizacionController.service';
import { SolicitudReutilizacionControllerService } from './solicitudReutilizacionController.service';
export * from './solicitudReutilizacionEstadoController.service';
import { SolicitudReutilizacionEstadoControllerService } from './solicitudReutilizacionEstadoController.service';
export * from './subTipoEnvaseController.service';
import { SubTipoEnvaseControllerService } from './subTipoEnvaseController.service';
export * from './tarifaGestorSddrcaController.service';
import { TarifaGestorSddrcaControllerService } from './tarifaGestorSddrcaController.service';
export const APIS = [AportacionDisddrcaControllerService, FacturaSddrcaControllerService, HelloWorldControllerService, SolicitudEnvaseControllerService, SolicitudEnvaseEstadoControllerService, SolicitudFacturaExportacionControllerService, SolicitudInformeRetiradaControllerService, SolicitudInformeRetiradaDocumControllerService, SolicitudRetiradaControllerService, SolicitudRetiradaEstadoControllerService, SolicitudReutilizacionAcreditacionControllerService, SolicitudReutilizacionControllerService, SolicitudReutilizacionEstadoControllerService, SubTipoEnvaseControllerService, TarifaGestorSddrcaControllerService];
