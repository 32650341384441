<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title font">Generar Certificado de reutilización</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrar()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
        <form class="form-modal py-3" [formGroup]="formGroup">
            <div class="row-fields">
                <div class="field field-100">
                    <label for="usuarioSddr">Usuario SDDR Abierto <span class="oblig">*</span></label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <mat-select formControlName="usuarioSddr" id="usuarioSddr" placeholder="-- Usuario SDDR Abierto --">
                            <mat-option *ngFor="let tipo of tiposUsusariosSddr" [value]="tipo.id!+tipo.tipoUsuario!">
                                {{ tipo?.tipoUsuario === 'POSEEDOR' ? 'PO' : 'AD' }} -
                                {{ tipo?.razonSocial }}
                                {{ tipo?.denominacion ? ' - ' + tipo.denominacion : '' }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="controlHasError('usuarioSddr', 'required', formGroup)">Campo obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>
                
            </div>
          
        </form>
      


    </div>
    <div class="modal-footer actions">
        <div class="note"><span>* Campos Obligatorios</span></div>
        <div class="button-group">
            <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
                aria-label="Cancelar">Cancelar</button>
            <button type="button"
                class="btn btn-primary" data-dismiss="modal" (click)="generarCertificado()" aria-label="Guardar">Generar certificado</button>
        </div>
    </div>
</div>
