import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Observable, TimeoutError, catchError, throwError } from 'rxjs';
import { SnackbarService } from '../services/snackbar.service';
@Injectable({
  providedIn: 'root',
})
export class ResponseHandlerInterceptor
  implements HttpInterceptor, ErrorHandler
{
  constructor(private snackBarSrv: SnackbarService) {}

  //intercept(
  //  req: HttpRequest<any>,
  //  next: HttpHandler
  //): Observable<HttpEvent<any>> {
  //  return next.handle(req).pipe(
  //    catchError((error) => {
  //      return this.handleErrorAndRethrow(error);
  //    })
  //  );
  //}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.error instanceof Blob) {
          return new Observable<HttpEvent<any>>((observer) => {
            const reader = new FileReader();
            reader.onload = () => {
              try {
                const errorJson = JSON.parse(reader.result as string);
                observer.error(errorJson);
              } catch {
                observer.error({ message: 'Error desconocido', originalError: error });
              }
            };
            reader.onerror = () => observer.error({ message: 'Error al leer la respuesta', originalError: error });
            reader.readAsText(error.error);
          });
        }
        return this.handleErrorAndRethrow(error);
      })
    );
  }

  handleError(err: any): void {
    let output: any = { type: 'error', mensaje: 'Se ha producido un error en el sistema. Por favor, consulte con el administrador del sistema' };

    if (err instanceof HttpErrorResponse) {
      switch (err.status) {
        case 409:
          output.mensaje =
            err.error?.validationErrors?.[0]?.defaultMessage ||
            err.error?.arguments?.[0] ||
            err.error?.detail ||
            output.mensaje;
          break;
        case 403:
        case 500:
          output.mensaje = err.error?.cause || output.mensaje;
          break;
        default:
          output.mensaje = err.message || output.mensaje;
          break;
      }
    } else if (err instanceof TimeoutError) {
      output.mensaje = 'global.timeoutError';
    } else {
      output.mensaje = err.message || output.mensaje;
    }

    this.snackBarSrv.showSnackBar(output.mensaje, 'error');
  }

  private handleErrorAndRethrow(error: any) {
    this.handleError(error);
    return throwError(() => error);
  }
}
