import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import {
  SolicitudReutilizacionControllerService,
  UsuarioSDDRCaDTO,
  EntidadCertificadoDTO
} from 'api-rest';
import { Combo, ComboService } from '../../../services/combo.service';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import { ValidateService } from '../../../services/validate.service';
import { zip } from 'rxjs';
import Swal from 'sweetalert2';
import moment from 'moment';
import { ParseCurrencyPipe } from '../../../share/pipe/ParseCurrency.pipe';
import { FormatCurrencyPipe } from '../../../share/pipe/FormatCurrency.pipe';


@Component({
  selector: 'open-ssdr-reuse-certificate-dialog',
  templateUrl: './open-ssdr-reuse-certificate-dialog.component.html',
  styleUrls: ['./open-ssdr-reuse-certificate-dialog.component.scss']
})
export class OpenSsdrReuseCertificateDialogComponent implements OnInit {
  formGroup!: FormGroup;

  tiposUsusariosSddr!: UsuarioSDDRCaDTO[];


  constructor(
    public dialogRef: MatDialogRef<OpenSsdrReuseCertificateDialogComponent>,
    public spinnerSrv: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private comboSrv: ComboService,
    private snackBarSrv: SnackbarService,
    @Inject('EmpusaAuthenticationService') private authService: EmpusaAuthenticationService,
    private validateSrv: ValidateService,
    private solicitudReutilizacionCtrlSrv: SolicitudReutilizacionControllerService,
    private downloadSrv: DownloadService
  ) {
    this.formGroup = this.formBuilder.group({
      usuarioSddr: [{ value: null }, [Validators.required]],
    });
  }
  ngOnInit(): void {
    this.cargarCombos();

    
  }

  cargarCombos(): void {
    this.spinnerSrv.loadingShow();
    this.solicitudReutilizacionCtrlSrv.findAllUsuariosAsociados().subscribe({
      next: (resp) => {
        this.spinnerSrv.loadingHide();
        this.tiposUsusariosSddr = resp;
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
      },
    });
  }


  cerrar(): void {
    this.spinnerSrv.loading = 0;
    this.dialogRef.close(null);
  }
 
 
  generarCertificado(){
    if(this.esFormularioValido() && this.formGroup.get('usuarioSddr')?.value){
      const usuario = this.tiposUsusariosSddr.find((x: UsuarioSDDRCaDTO) => (x.id! + x.tipoUsuario!) === this.formGroup.get('usuarioSddr')?.value);
      if(usuario){
        console.log(usuario);
        let filter:EntidadCertificadoDTO;
        if(usuario.tipoUsuario == 'ADHERIDO'){
          filter = {
            adherido: {
              id: usuario.id
            }
          }
        }else{
          filter = {
            poseedor: {
              id: usuario.id
            }
          }
        }
        
        this.solicitudReutilizacionCtrlSrv.generarCertificado(filter).subscribe({
          next: (resp) => {
            this.spinnerSrv.loadingHide();
            this.downloadSrv.downloadBlob(resp, 'Certificado.pdf');
            this.snackBarSrv.showSnackBar("Certificado generado correctamente.", "success");
            this.cerrar();
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            console.log(err);
            let errorMessage = "Ha ocurrido un error al generar el certificado.";
            if(err?.cause){
              errorMessage = errorMessage + err?.cause;
            }
            this.snackBarSrv.showSnackBar(errorMessage, "error");
            this.cerrar();
          },
        });
      }
    }
    
  }


  esFormularioValido(): boolean {
    let valid = false;
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) { 
      valid = true;
    }
    return valid;
  }


  controlHasError(controlName: string, errorName: string, form: FormGroup) {
    return (
      form.controls[controlName]?.hasError(errorName) &&
      form.controls[controlName]?.touched
    );
  }

  formatoValidator() {
    return (control: FormControl) => {
      const valor = control.value;
      if (!valor) {
        return null;
      }
      const regex = /^ENV\/\d{4}\/\d{9}$/;
      if (!regex.test(valor)) {
        return { formatoInvalido: true };
      }

      if (valor.length !== 18) {
        return { longitudIncorrecta: true };
      }

      return null;
    };
  }

  canAccessAll(list: any) {
    const resultDelete = this.canAccessEdit();
    const listPermission = list.map((objeto: any) => {
      return {
        ...objeto
      };
    });
    return listPermission;
  }
  canAccessEdit(): boolean {
    return this.authService.can('sddr-ca-solicitudes-reutilizacion-detalle', 'edit');
  }
  canAccessGuardar(): boolean {
    return this.authService.can('sddr-ca-solicitudes-reutilizacion-detalle', 'access-b-guardar');
  }
  canAccessGuardarEnviar(): boolean {
    return this.authService.can('sddr-ca-solicitudes-reutilizacion-detalle', 'access-b-guardar-enviar');
  }
  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.formGroup.get(formName)?.setValue(null);
  }
  showClearButton(formName: string): boolean {
    return this.formGroup.get(formName)?.value !== null;
  }

}
