import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { PoseedorControllerService, PoseedorDTO } from 'api-rest';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { HoldersDetailGeneralDataComponent } from '../holders-detail-general-data/holders-detail-general-data.component';
import { HoldersWasteForecastComponent } from '../holders-waste-forecast/holders-waste-forecast.component';
import swal from 'sweetalert2';
import { HoldersService } from '../../../services/holders.service';
import {switchMap} from "rxjs";

@Component({
  selector: 'app-holders-detail',
  templateUrl: './holders-detail.component.html',
  styleUrls: ['./holders-detail.component.scss'],
})
export class HoldersDetailComponent implements OnInit {
  @ViewChild(HoldersDetailGeneralDataComponent, { static: false })
  childHoldersGeneralDataCmp: HoldersDetailGeneralDataComponent | undefined;
  @ViewChild(HoldersWasteForecastComponent, { static: false })
  childHoldersWasteForecastComponent: HoldersWasteForecastComponent | undefined;
  selectedMenu = 1;
  lastMenu = 1;
  id!: number;
  poseedorInfo!: PoseedorDTO;
  estadoPoseedor!: number | undefined;
  flagRuta: boolean = false;

  menuEnum: any = {
    1: 'poseedores-detalle',
    2: 'poseedores-contactos',
    3: 'poseedores-direcciones',
    4: 'poseedores-prevision',
    5: 'poseedores-documentos',
  };

  pendientePoseedor: boolean = false;
  pendienteEnvalora: boolean = false;
  pendienteCompletado: boolean = false;
  rechazado: boolean = false;

  textoEstado: string = '';
  datosCargados: boolean = false;

  constructor(
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    public spinnerSrv: SpinnerService,
    private router: Router,
    private snackBarSrv: SnackbarService,
    private route: ActivatedRoute,
    private poseedorControllerSrv: PoseedorControllerService,
    private holdersService: HoldersService
  ) {
    this.route.params.subscribe((params) => {
      this.id = +params['id']; // (+) converts string 'id' to a number
    });
  }

  ngOnInit() {
    this.flagRuta = this.holdersService.getTempHoldersSearch;
    this.spinnerSrv.loadingShow();
    this.poseedorControllerSrv.getById2(this.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.poseedorInfo = response;
        this.datosCargados = true;
        this.estadoPoseedor = this.poseedorInfo?.estado?.id;
        switch (this.estadoPoseedor) {
          case 1:
            this.pendientePoseedor = true;
            break;
          case 2:
            this.pendienteEnvalora = true;
            break;
          case 3:
            this.pendienteEnvalora = true;
            break;
          case 4:
            this.pendienteCompletado = true;
            break;
          case 6:
            this.rechazado = true;
            break;
        }
        this.textoEstado = this.poseedorInfo?.estado?.descripcion!;
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al cargar el poseedor',
          'error'
        );
      },
    });
  }

  loadNumMenu(num: number): void {
    this.selectedMenu = num;
    if (this.lastMenu < num) {
      this.lastMenu = num;
    }
  }

  canViewRol(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

  refrescar(menu: number): void {
    this.selectedMenu = menu;
    this.spinnerSrv.loadingShow();
    const estadoPoseedorAnterior = this.poseedorInfo?.estado?.id;
    this.poseedorControllerSrv.getById2(this.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.poseedorInfo = response;
        this.pendientePoseedor = false;
        this.pendienteEnvalora = false;
        this.pendienteCompletado = false;
        this.estadoPoseedor = this.poseedorInfo?.estado?.id;
        if (!this.canViewRol('PO-ENV-GEST')) {
          //if (this.selectedMenu == 1 && this.estadoPoseedor == 1) {
          //  this.siguiente();
          //}
          if(this.selectedMenu == 1){
            //Si el estado ha cambiado de Completado a Pdte datos obligatorios, pasar directamente a Prevision de envases
            if(estadoPoseedorAnterior == 4 && this.estadoPoseedor == 1){
              this.cambiarAPrevisionResiduosAutorizados();
            }else if(this.estadoPoseedor == 1){
              this.siguiente();
            }
          }
        }
        switch (this.estadoPoseedor) {
          case 1:
            this.pendientePoseedor = true;
            break;
          case 2:
            this.pendienteEnvalora = true;
            break;
          case 3:
            this.pendienteEnvalora = true;
            break;
          case 4:
            this.pendienteCompletado = true;
            break;
        }
        this.textoEstado = this.poseedorInfo?.estado?.descripcion!;
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al cargar el poseedor',
          'error'
        );
      },
    });
  }

  siguiente(): void {
    let aux = this.selectedMenu;
    let max = 4;
    if (!this.poseedorInfo?.contratoGestor) {
      max = 3
    }
    if (aux + 1 <= max) {
      this.selectedMenu = aux + 1;
    }
  }

  cambiarAPrevisionResiduosAutorizados(): void {
    if (!this.poseedorInfo?.contratoGestor) {
      this.siguiente();
    }else{
      this.selectedMenu = 4;
    }
  }

  continuarName(menu: number): string {
    switch (menu) {
      case 1:
        return this.authService.can(
          this.menuEnum[menu],
          'access-b-guardar-continuar'
        ) && this.estadoPoseedor == 1
          ? 'Guardar y Continuar '
          : 'Guardar';
        break;
      case 2:
        return this.authService.can(
          this.menuEnum[menu],
          'access-b-continuar'
        ) && this.estadoPoseedor == 1
          ? 'Continuar'
          : '';
        break;
      case 3:
        return this.authService.can(
          this.menuEnum[menu],
          'access-b-continuar'
        ) && this.estadoPoseedor == 1
          ? 'Continuar'
          : '';
        break;
      default:
        return '';
        break;
    }
  }

  canAccessContinuarAll(menu?: number): boolean {
    switch (menu) {
      case 1:
        return (
          this.authService.can(
            this.menuEnum[menu],
            'access-b-guardar-continuar'
          ) && this.estadoPoseedor == 1
        );
      case 2:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-continuar') &&
          this.estadoPoseedor == 1
        );
      case 3:
        if (this.poseedorInfo?.contratoGestor) {
          return (
            this.authService.can(this.menuEnum[menu], 'access-b-continuar') &&
            this.estadoPoseedor == 1
          );
        } else {
          return false;
        }
        break
      default:
        return false;
    }
  }

  canAccessGuardar(menu?: number) {
    switch (menu) {
      case 1:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-guardar') //&&
          //(this.authService.hasCurrentUserRole('PO-ENV-GEST') || (this.authService.hasCurrentUserRole('PO-POSEEDOR') && this.poseedorInfo?.estado?.id === 1))   
        );
        break;
      default:
        return false;
        break;
    }
  }

  guardar(idMenu?: number) {
    switch (idMenu) {
      case 1:
        if (
          this.canAccessContinuarAll(idMenu) ||
          this.canAccessGuardar(idMenu)
        ) {
          this.childHoldersGeneralDataCmp?.guardar();
        }
        break;
      case 2:
        if (this.verificarObligatorioContactos()) {
          this.snackBarSrv.showSnackBar(
            'Debe completar los datos en los contactos requeridos',
            'error'
          );
        } else {
          this.snackBarSrv.showSnackBar('Contactos correctos', 'success');
          this.siguiente();
        }
        break;
      case 3:
        if (this.verificarObligatorioDirecciones()) {
          this.snackBarSrv.showSnackBar(
            'Debe completar los datos en las direcciones requeridas',
            'error'
          );
        } else {
          this.snackBarSrv.showSnackBar('Direcciones correctas', 'success');
          this.siguiente();
        }
        break;
      default:
        break;
    }
  }
  verificarObligatorioContactos() {
    const objetoCampoObligatorios = {
      tipo_contacto: true,
      persona: true,
      nombre: true,
      apellido1: false,
      tipo_documento: false,
      codigo_documento: false,
      tipo_telefono: true,
      prefijoPais: true,
      numero_telefono: true,
      email: true,
      recibir_comunicaciones: true,
      recibir_comunicaciones_contrato: true,
      acreditacion_poderes: false,
    };

    if (this.poseedorInfo?.contactos?.length != 0) {
      const tieneCamposObligatoriosVacios = this.poseedorInfo?.contactos
        ?.filter((objeto: any) => objeto.requerido)
        .map((item: any) => {
          if (item?.tipo_contacto?.id === 3 || item?.tipo_contacto?.id === 4) {
            objetoCampoObligatorios.tipo_documento = true;
            objetoCampoObligatorios.codigo_documento = true;
            //objetoCampoObligatorios.acreditacion_poderes = true;
          } else {
            objetoCampoObligatorios.tipo_documento = false;
            objetoCampoObligatorios.codigo_documento = false;
            //objetoCampoObligatorios.acreditacion_poderes = false;
          }
          if (item?.persona) {
            objetoCampoObligatorios.apellido1 = true;
          } else {
            objetoCampoObligatorios.apellido1 = false;
          }
          const data = Object.entries(objetoCampoObligatorios).some(
            ([campo, obligatorio]) =>
              obligatorio && (item[campo] === '' || item[campo] === null)
          );
          return data;
        })
        .some((resultado) => resultado);
      return tieneCamposObligatoriosVacios;
    } else {
      return false;
    }
  }

  verificarObligatorioDirecciones() {
    // Define un objeto con los campos obligatorios y los inicializa
    const objetoCampoObligatorios = {
      provincia: false,
      codigoPostal: false,
      municipio: false,
      tipoDireccion: false,
      tipoVia: false,
      nombreVia: false,
      numero: false,
      pais: true,
    };

    // Verifica si hay domicilios
    if (this.poseedorInfo?.domicilios?.length != 0) {
      // Filtra los domicilios requeridos y luego verifica si algún campo obligatorio está vacío
      const tieneCamposObligatoriosVacios = this.poseedorInfo?.domicilios
        ?.filter((objeto: any) => objeto.requerido) // Filtra los domicilios requeridos
        .map((item: any) => {
          // Si el país es igual a 69, los campos provincia, codigoPostal y municipio son obligatorios
          if (item.pais?.id === 69) {
            objetoCampoObligatorios.provincia = true;
            objetoCampoObligatorios.codigoPostal = true;
            objetoCampoObligatorios.municipio = true;
            objetoCampoObligatorios.nombreVia = true;
            objetoCampoObligatorios.numero = true;
          } else {
            objetoCampoObligatorios.provincia = false;
            objetoCampoObligatorios.codigoPostal = false;
            objetoCampoObligatorios.municipio = false;
            objetoCampoObligatorios.nombreVia = false;
            objetoCampoObligatorios.numero = false;
          }
          return Object.entries(objetoCampoObligatorios).some(
            ([campo, obligatorio]) =>
              obligatorio && (item[campo] === '' || item[campo] === null)
          );
        })
        .some((resultado) => resultado); // Verifica si alguno de los resultados es verdadero
      return tieneCamposObligatoriosVacios; // Devuelve si hay campos obligatorios vacíos
    } else {
      return false; // Si no hay domicilios, devuelve falso
    }
  }

  cancelar() {
    swal
      .fire({
        text: '¿Desea cancelar esta acción?. Regresará al buscador.',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.router.navigate(['/holders/search']);
        }
      });
  }

  canAccessCancelar(menu: number) {
    switch (menu) {
      case 1:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-cancelar') &&
          this.estadoPoseedor &&
          [1, 2, 3, 4].includes(this.estadoPoseedor)
        );
        break;
      case 2:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-cancelar') &&
          this.estadoPoseedor &&
          [1, 2, 3, 4].includes(this.estadoPoseedor)
        );
        break;
      case 3:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-cancelar') &&
          this.estadoPoseedor &&
          [1, 2, 3, 4].includes(this.estadoPoseedor)
        );
        break;
      case 4:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-cancelar') &&
          this.estadoPoseedor &&
          [1, 2, 3, 4].includes(this.estadoPoseedor)
        );
        break;
      case 5:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-cancelar') &&
          this.estadoPoseedor &&
          [1, 2, 3, 4].includes(this.estadoPoseedor)
        );
        break;
      default:
        return false;
        break;
    }
  }

  canAccessVolver(): boolean | any {
    return (
      this.authService.can('poseedores-detalle', 'access-b-volver') &&
      this.estadoPoseedor &&
      [5].includes(this.estadoPoseedor)
    );
  }
  canAccessDocumentos(): boolean {
    return this.authService.can('poseedores-documentos', 'access');
  }

  volver() {
    this.router.navigate(['/holders/search']);
  }

  anterior(): void {
    let aux = this.selectedMenu;
    if (aux - 1 > 0) {
      this.selectedMenu = aux - 1;
    }
    if (this.selectedMenu === 1) {
      this.childHoldersGeneralDataCmp?.inicializar();
    }
  }

  canAccessAnteriorAll(menu: number): boolean {
    switch (menu) {
      case 2:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-anterior') &&
          this.estadoPoseedor == 1
        );
        break;
      case 3:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-anterior') &&
          this.estadoPoseedor == 1
        );
        break;
      case 4:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-anterior') &&
          this.estadoPoseedor == 1
        );
        break;
      default:
        return false;
        break;
    }
  }

  canAccessFinalizar(menu: number): boolean {
    switch (menu) {
      case 3:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-continuar') &&
          this.estadoPoseedor == 1 && !this.poseedorInfo?.contratoGestor
        ) ? true : false;
        break;
      case 4:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-continuar') &&
          this.estadoPoseedor == 1
        );
        break;
      default:
        return false;
        break;
    }
  }

  mensajeValidacion() {
    this.snackBarSrv.showSnackBar(
      'Para poder desplazarse de una pantalla a otra deberá hacerlo mediante los botones de la parte inferior.'
    );
  }

  finalizar(): void {
    if (!this.poseedorInfo?.contratoGestor) {
      if (this.verificarObligatorioDirecciones()) {
        this.snackBarSrv.showSnackBar(
          'Debe completar los datos en las direcciones requeridas',
          'error'
        );
      } else {
        this.poseedorControllerSrv
          .finalizarDetalle(this.poseedorInfo.id!)
          .subscribe({
            next: (response: PoseedorDTO) => {
              this.spinnerSrv.loadingHide();
              let mensaje;
              if(response.estado?.id == 4){
                mensaje = 'Proceso finalizado correctamente.';
              }else{
                mensaje = 'Proceso finalizado correctamente. Los datos serán revisados por Envalora y, en los próximos días, recibirá un correo electrónico con instrucciones para la firma del acuerdo.';
              }
              this.snackBarSrv.showSnackBar(
                mensaje,
                'success'
              );
              this.router.navigate(['/initial-page']);
            },
            error: (err) => {
              this.spinnerSrv.loadingHide();
              this.snackBarSrv.showSnackBar(
                'Ha ocurrido un error al finalizar.',
                'error'
              );
            },
          });
      }
    } else {
      this.childHoldersWasteForecastComponent?.finalizar();
    }
  }
}
