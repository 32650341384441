import { Component, EventEmitter, Inject, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ContactoControllerService, ContactoDTO, PoseedorControllerService, PoseedorDTO } from 'api-rest';
import { HoldersContactDialogComponent } from '../holders-contact-dialog/holders-contact-dialog.component';
import swal from 'sweetalert2'
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';

@Component({
  selector: 'lib-holders-detail-contact-data',
  templateUrl: './holders-detail-contact-data.component.html',
  styleUrls: ['./holders-detail-contact-data.component.scss']
})
export class HoldersDetailContactDataComponent implements OnChanges {
  @Input() poseedorInfo!: PoseedorDTO;
  @Output() public refrescarPoseedorEvent = new EventEmitter<any>();
  contactos: ContactoDTO[] = [];

  gridArray: any[] = [];

  headArray: any = [
    {
      Head: 'Requerido',
      FieldName: 'requerido',
      check: true,
      disabled: true
    },
    {
      Head: 'Tipo contacto',
      FieldName: 'tipo_contacto',
      Attribute: 'descripcionCorta',
      Tooltip: true
    },
    {
      Head: 'Tipo entidad',
      renderValue: (value: any) => value.persona ? 'Persona' : 'Empresa'
    },
    {
      Head: 'Nombre',
      FieldName: 'fullName',
      Tooltip: true
    },
    {
      Head: 'Email',
      FieldName: 'email',
      Tooltip: true
    },
    {
      'Head': 'Acciones', 'FieldName': 'actions', 'buttons':
        [
          { 'nameButton': "view", "icon": "open_in_new", 'toolTip': 'Detalle', 'show': { 'params': 'canAccessView', 'accion': 'view' } },
          { 'nameButton': "delete", "icon": "delete", 'toolTip': 'Borrar', 'show': { 'params': 'canAccessDelete', 'accion': 'delete' }  },
        ], 'width': '8', permanent: true
    },
  ];

  constructor(public dialog: MatDialog,
    private spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    @Inject('EmpusaAuthenticationService') private authService: EmpusaAuthenticationService,
    private contactoControllerSrv: ContactoControllerService) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.poseedorInfo?.contactos) {
      this.contactos = this.poseedorInfo?.contactos;
      //TODO aplicar permisos
      this.gridArray = this.canAccessAll(this.contactos);

    }
  }

  canAccessAll(list: any) {
    const resultListShow = this.authService.can('poseedores-contactos', 'access');
    const resultDelete = this.authService.can('poseedores-contactos', 'delete');

    const listPermission = list.map((objeto: any) => {


      const noRequerido =  (!objeto.requerido || objeto.requerido === null );
      return { ...objeto,
        canAccessView: resultListShow,
        //canAccessListEdit: resultListEdit,
        canAccessDelete: resultDelete && noRequerido };

    });

    return listPermission;
  }
  action(item: any) {
    if (item.nameButton == 'view') {
      this.abrirModalContacto(item);
    } else if (item.nameButton == 'delete') {
      this.confirmacionEliminar(item);
    }
  }
  confirmacionEliminar(element?: ContactoDTO): void {
    swal.fire({
      text: '¿Desea eliminar el contacto?',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarContacto(element);
      }

    });
  }
  eliminarContacto(element?: ContactoDTO) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.contactoControllerSrv.borrarContacto(element.id)
        .subscribe({
          next: response => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar('El contacto se ha eliminado correctamente', 'success');
            this.refrescarPoseedorEvent.emit(true);
          },
          error: err => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar('Ha ocurrido un error al eliminar el contacto', 'error');
          }
        });
    }
  }
  abrirModalContacto(element?: any): void {
    const dialogRef = this.dialog.open(
      HoldersContactDialogComponent,
      {
        width: '950px',
        maxWidth: '95%',
        maxHeight: '95vh',
        data: {
          contacto: element,
          poseedor: this.poseedorInfo
        },
        disableClose: true
      }
    );
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.refrescarPoseedorEvent.emit(true);
        }
      }
    );
  }

  canAccessCreateContact(): boolean {
    return this.authService.can('poseedores-contactos', 'create');
  }
}
