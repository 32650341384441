import { Attribute, Component, Inject } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  AportacionDIPoseedorControllerService,
  PrefacturacionHistoricoControllerService,
  PrefacturacionRequestDTO,
  PrefacturacionHistoricoFilterDTO,
  SolicitudReutilizacionControllerService,
  SolicitudRetiradaControllerService
} from 'api-rest';
import { Combo, ComboService } from '../../../services/combo.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { map } from 'rxjs';
import Swal from 'sweetalert2';
import { SpinnerService } from '../../../services/spinner.service';
import { FormatCurrencyPipe } from '../../../share/components/pipe/FormatCurrency.pipe';
import { DatePipe } from '@angular/common';
import moment from 'moment';
import { ParseCurrencyPipe } from '../../../share/components/pipe/ParseCurrency.pipe';

@Component({
  selector: 'lib-pre-invoicing',
  templateUrl: './pre-invoicing.component.html',
  styleUrls: ['./pre-invoicing.component.scss'],
})
export class PreInvoicingComponent {

  modulos: Combo[] = [];

  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;
  flagSearch: boolean = false;
  totalSearch: number = 0;
  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
 
  generateForm: FormGroup;

  defaultFilter: boolean = true;
  headArray: any = [
    {
      Head: 'Fecha ejecución',
      FieldName: 'fechaEjecucion',
      renderValue: (item: any) =>
        this.datePipe.transform(item.fechaEjecucion, 'dd/MM/yyyy HH:mm'),
    },
    {
      Head: 'Usuario',
      FieldName: 'usuarioEjecucion'
    },
    {
      Head: 'Prefacturas generadas',
      FieldName: 'numeroPrefacturasGeneradas',
    },
    {
      Head: 'Estado',
      FieldName: 'jobInfo',
      Attribute: 'status',
      renderValue: (item: any) =>
        {
          switch (item.jobInfo?.status) {
            case 'ERROR':
              return 'Error';
              break;
            case 'EN_EJECUCION':
              return 'En ejecución';
              break;
            case 'FINALIZADO':
              return 'Finalizado'
              break;

          }
          return '';
        },
    },
    {
      Head: 'Fecha finalización',
      FieldName: 'fechaFinalizacion',
      renderValue: (item: any) =>
        this.datePipe.transform(item.fechaFinalizacion, 'dd/MM/yyyy HH:mm'),
    },
    {
      Head: 'Errores detectados',
      FieldName: 'erroresGenerados',
      Tooltip: true,
      Maxtext: 10,
    }
  ];
  gridArray: any[] = [];
 
  fechaHasta: Date = new Date();
  
  constructor(
    @Inject('environment') private environment: any,
    private aportacionDIPoseedorService: AportacionDIPoseedorControllerService,
    private solicitudReutilizacionService: SolicitudReutilizacionControllerService,
    private solicitudRetiradaService: SolicitudRetiradaControllerService,
    private prefacturacionHistoricoService: PrefacturacionHistoricoControllerService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private snackBarSrv: SnackbarService,
    private comboService: ComboService,
    public spinnerSrv: SpinnerService,
    private datePipe: DatePipe,
    private parseCurrencyPipe: ParseCurrencyPipe
  ) {
    this.generateForm = this.formBuilder.group({
      modulo: [{ value: null, disabled: false }, { validators: Validators.compose([Validators.required]) }],
      fechaHasta: [{ value: this.fechaHasta, disabled: false }, { validators: Validators.compose([Validators.required]) }],
      importeMinimo: [{ value: null, disabled: false }, { validators: Validators.compose([Validators.required]) }],
      importeMinimoDepositoIBC: [{ value: null, disabled: false }],
      importeMinimoDepositoPallet: [{ value: null, disabled: false }]
    });
  }

  ngOnInit(): void {
    this.modulos = this.comboService.getComboModulosPrefacturacion();
  }

  generarPrefactura(): void {
    if (this.generateForm.invalid) {
      this.generateForm.markAllAsTouched();
      return;
    }
    if(this.generateForm.get('modulo')?.value.id == 'POSEEDORES_APORTACIONES_DIS' || this.generateForm.get('modulo')?.value.id == 'SDDR_ABIERTO_APORTACIONES_DIS'){
      this.generarPrefacturaPosAportDIS();
    }else if(this.generateForm.get('modulo')?.value.id == 'SDDR_ABIERTO_SOLICITUDES'){
      this.generarPrefacturaSDDRASolicitud();
    }
  }

  generarPrefacturaPosAportDIS(){
    this.spinnerSrv.loadingShow();
    let incluirSDDRCA = false;
    if(this.generateForm.get('modulo')?.value.id == 'SDDR_ABIERTO_APORTACIONES_DIS'){
      incluirSDDRCA = true;
    }
    const filtroPrefacturacion: PrefacturacionRequestDTO = {
      fechaHasta: moment( this.generateForm.get('fechaHasta')?.value).format('YYYY-MM-DD'),
      importeMinimo: this.parseCurrencyPipe.transform(this.generateForm.get('importeMinimo')?.value),
      incluirSDDRCA: incluirSDDRCA
    };
    this.aportacionDIPoseedorService.procesoPrefacturacion(filtroPrefacturacion).subscribe({
      next: (response: any) => {
        this.spinnerSrv.loadingHide();
        if(response!.erroresGenerados.length > 0){
          this.snackBarSrv.showSnackBar(
            `Ha ocurrido un error al generar la prefacturación. ${response!.erroresGenerados[0]}`,
            'error'
          );
        }else{
          this.snackBarSrv.showSnackBar(
            'Se ha lanzado el proceso de prefacturación correctamente.',
            'success'
          );
          this.buscar();
        }
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
        console.log(err);
      },
    });
  }

  generarPrefacturaSDDRASolicitud(){
    this.spinnerSrv.loadingShow();
    const filtroPrefacturacion: any = {
      fechaHasta: moment( this.generateForm.get('fechaHasta')?.value).format('YYYY-MM-DD'),
      importeMinimo: this.parseCurrencyPipe.transform(this.generateForm.get('importeMinimo')?.value),
      importeMinimoDepositoIBC: this.parseCurrencyPipe.transform(this.generateForm.get('importeMinimoDepositoIBC')?.value),
      importeMinimoDepositoPallet: this.parseCurrencyPipe.transform(this.generateForm.get('importeMinimoDepositoPallet')?.value),
    };
    this.solicitudReutilizacionService.procesoPrefacturacion(filtroPrefacturacion).subscribe({
      next: (response: any) => {
        this.spinnerSrv.loadingHide();
        if(response!.erroresGenerados.length > 0){
          this.snackBarSrv.showSnackBar(
            `Ha ocurrido un error al generar la prefacturación. ${response!.erroresGenerados[0]}`,
            'error'
          );
        }else{
          this.snackBarSrv.showSnackBar(
            'Se ha lanzado el proceso de prefacturación correctamente.',
            'success'
          );
          this.buscar();
        }
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
        console.log(err);
      },
    });
  }

  

  buscar(){
    if(this.generateForm.get('modulo')?.value){
      const { backendFilter, page, size } = this.getBackendFilter();
      this.prefacturacionHistoricoService.findAll1(backendFilter,{page: page, size: size, sort:['fechaEjecucion,desc']})
        .subscribe({
          next: (data: any) => {
            if (data) {
              this.flagSearch = data.datos?.length !== 0 ? true : false;
              this.gridArray = data.datos ?? [];
              this.recordsTotal = data.paginacion?.totalElements ?? 0;
              this.totalSearch = this.gridArray.length;
            }
            this.spinnerSrv.loadingHide();
          },
          error: () => {
            this.spinnerSrv.loadingHide();
          },
        });
    }
  }

  private getBackendFilter() {
    let backendFilter: PrefacturacionHistoricoFilterDTO = {};

    if(this.generateForm.get('modulo')?.value?.id == 'POSEEDORES_APORTACIONES_DIS' || this.generateForm.get('modulo')?.value?.id == 'SDDR_ABIERTO_APORTACIONES_DIS'){
      backendFilter = {
        modulo: PrefacturacionHistoricoFilterDTO.ModuloEnum.Poseedores,
        proceso: PrefacturacionHistoricoFilterDTO.ProcesoEnum.AportacionesDis
      };
    }else if(this.generateForm.get('modulo')?.value?.id == 'SDDR_ABIERTO_SOLICITUDES'){
      backendFilter = {
        modulo: PrefacturacionHistoricoFilterDTO.ModuloEnum.Sddrca,
        //proceso: PrefacturacionHistoricoFilterDTO.ProcesoEnum.SolicitudReutilizacion
      };

    }
    
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;
    return { backendFilter, page, size: this.pageSize };
  }



  limpiar(): void {
    this.generateForm.reset({
      fechaHasta: this.fechaHasta,
    });
    this.flagSearch = false;
    this.gridArray = [];
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.generateForm.controls[controlName].hasError(errorName) &&
      this.generateForm.controls[controlName].touched
    );
  }

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.buscar();
    }
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.buscar();
  }

}
