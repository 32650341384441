import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AttachedService} from '../../../services/attached.service';
import {EmpusaAuthenticationService} from '@empusa/empusa-core';
import {SnackbarService} from '../../../services/snackbar.service';
import {SpinnerService} from '../../../services/spinner.service';
import Swal from 'sweetalert2';
import {DownloadService} from '../../../services/download.service';
import {
  DeclaracionEnvaseControllerService,
  DeclaracionEnvaseMaterialDTO
} from 'api-rest';
import {DocumentService} from '../../../services/document.service';
import {ParseCurrencyPipe} from '../../../share/components/pipe/ParseCurrency.pipe';
import {FormatCurrencyPipe} from '../../../share/components/pipe/FormatCurrency.pipe';
import {concatMap, Observable, takeUntil, tap} from 'rxjs';

export interface MappedResponse<T> {
  totalElements: number;
  recordsFiltered: number;
  datos: T[];
}


@Component({
  selector: 'lib-package-material-dialog',
  templateUrl: './package-material-dialog.component.html',
  styleUrls: ['./package-material-dialog.component.scss'],
})
export class PackageMaterialDialogComponent implements OnInit {
  @ViewChild('fileInputAcuerdo') fileInputAcuerdo: any;
  @ViewChild('fileInputCertificado') fileInputCertificado: any;

  updateList: boolean = false;
  composicionMaterialEndpoint =
    this.environment.urlBackCore + 'api/v1/core/composicionMaterial';

  materialFormGroup!: FormGroup;
  campoOblig: boolean = false;
  flagDisabledForm: boolean = false;

  materialComposicion!: DeclaracionEnvaseMaterialDTO;
  numUnidades: string;
  isOtherMaterial: boolean = false;

  material: any[] = [];
  gridMaterial: any[] = [];

  calculatingPesoTotal: boolean = false;
  calculatingPesoUni: boolean = false;

  isSDDRCerrado:boolean = false;

  tipoElementoArray: any = [
    {id: 'TAPONES', descripcion: 'Tapón'},
    {id: 'ETIQUETAS', descripcion: 'Etiqueta'},
    {id: 'OTROS_ELEMENTOS', descripcion: 'Otro elemento'},
  ];

  constructor(
    public dialogRef: MatDialogRef<PackageMaterialDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    @Inject('environment') private environment: any,
    private snackBarSrv: SnackbarService,
    public spinnerSrv: SpinnerService,
    private parseCurrencyPipe: ParseCurrencyPipe,
    private formatCurrencyPipe: FormatCurrencyPipe
  ) {
    this.materialComposicion = this.data.item;
    this.numUnidades = this.data.numUnidades;
    this.isOtherMaterial = this.data.isOtherMaterial;
    this.isSDDRCerrado = this.data.isSDDRCerrado;

    this.materialFormGroup = this.formBuilder.group({
      tipoElemento: [null],
      composicionMaterial: [null, Validators.required],
      pesoUnitario: [null, Validators.required],
      pesoTotal: [null, Validators.required],
      porcReciclado: [0, Validators.required],
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public filterList(data: any) {}

  ngOnInit(): void {
    if(this.isSDDRCerrado){
      let nUnidades = this.parseCurrencyPipe.transform(
        this.numUnidades
      );
      if(!nUnidades || nUnidades == 0){
        this.materialFormGroup.get('pesoTotal')?.disable();
      }
    }
    
    this.valueChangePesoUni(this.materialFormGroup);
    this.valueChangePesoTotal(this.materialFormGroup);
    this.cargarDatosDeclaracionEnvase();
    
  }

  cargarDatosDeclaracionEnvase() {
    this.materialFormGroup.get('composicionMaterial')?.setValue(this.materialComposicion?.composicionMaterial?.descripcion);
    this.materialFormGroup.get('pesoTotal')?.setValue(this.materialComposicion?.pesoTotal);
    this.materialFormGroup.get('pesoUnitario')?.setValue(this.materialComposicion?.pesoUnitario);
    this.materialFormGroup.get('porcReciclado')?.setValue(this.materialComposicion?.porcReciclado);
    if(this.isOtherMaterial){
      this.materialFormGroup.get('tipoElemento')?.setValue(this.materialComposicion?.tipoElemento);
    }
  }

  cerrar(): void {
    this.dialogRef.close(null);
  }

  guardar(){
    if (this.materialFormGroup.invalid) {
      this.materialFormGroup.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
      return;
    }
    let compMaterial = this.materialFormGroup.get('composicionMaterial')?.value;
    if (typeof compMaterial != 'string') {
      this.materialComposicion.composicionMaterial = this.materialFormGroup.get('composicionMaterial')?.value;
    }
    this.materialComposicion.pesoUnitario = this.materialFormGroup.get('pesoUnitario')?.value;
    this.materialComposicion.pesoTotal = this.materialFormGroup.get('pesoTotal')?.value;
    this.materialComposicion.porcReciclado = this.materialFormGroup.get('porcReciclado')?.value;
    if(this.isOtherMaterial){
      this.materialComposicion.tipoElemento = this.materialFormGroup.get('tipoElemento')?.value;
    }
    
    this.dialogRef.close(true);
  }

  getFormControl(): FormControl {
    return this.materialFormGroup.get('composicionMaterial') as FormControl;
  }

  public rightHolder(dataFormGroup: FormGroup, controlName: string) {
    let cl2 = '';
    if (
      (dataFormGroup.controls[controlName]?.touched ||
        dataFormGroup.controls[controlName]?.dirty) &&
      dataFormGroup.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  valueChangePesoTotal(dataFormGroup: FormGroup) {
    dataFormGroup.get('pesoUnitario')?.valueChanges.subscribe((val) => {
      if (typeof val === 'string' && val.includes(',')) {
        const [integerPart, decimalPart] = val.split(',');
        if (decimalPart.length > 5) {
          return;
        }
      }

      if (!isNaN(parseFloat(val)) && !this.calculatingPesoTotal) {
        this.calculatingPesoTotal = true;

        const valAsNumber = this.parseCurrencyPipe.transform(val);

        let nUnidades = this.parseCurrencyPipe.transform(
          this.numUnidades
        );
        if (!isNaN(nUnidades)) {
          if (!nUnidades && !this.isSDDRCerrado) {
            this.numUnidades = '1'
            nUnidades = 1;
          }
          const calcPesoTotal = nUnidades * valAsNumber;

          const formattedValue =
            this.formatCurrencyPipe.transform(calcPesoTotal);

          dataFormGroup
            .get('pesoTotal')
            ?.setValue(formattedValue, {emitEvent: false});
        }

        this.calculatingPesoTotal = false;
      }
    });
  }

  valueChangePesoUni(dataFormGroup: FormGroup) {
    dataFormGroup.get('pesoTotal')?.valueChanges.subscribe((val) => {
      if (typeof val === 'string' && val.includes(',')) {
        const [integerPart, decimalPart] = val.split(',');
        if (decimalPart.length > 5) {
          return;
        }
      }
      if (!isNaN(parseFloat(val)) && !this.calculatingPesoUni) {
        this.calculatingPesoUni = true;

        const valAsNumber = this.parseCurrencyPipe.transform(val);

        let nUnidades = this.parseCurrencyPipe.transform(
          this.numUnidades
        );

        if (!isNaN(nUnidades)) {
          let calcPesoTotal = 0
          if (!nUnidades && !this.isSDDRCerrado) {
            this.numUnidades = '1';
            nUnidades = 1;
          }
          if(nUnidades != 0){
            calcPesoTotal = valAsNumber / nUnidades;
          }
          
          const formattedValue =
            this.formatCurrencyPipe.transform(calcPesoTotal);

          dataFormGroup
            .get('pesoUnitario')
            ?.setValue(formattedValue, {emitEvent: false});
        }

        this.calculatingPesoUni = false;
      }
    });
  }

}
