/**
 * Poseedores API
 * Poseedores API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PrefacturacionRequestDTO { 
    fechaHasta: string;
    importeMinimo: number;
    incluirSDDRCA: boolean;
}

