import { Injectable } from "@angular/core";
import { CnaeDTO } from "api-rest";


@Injectable({
  providedIn: 'root'
})
export class FilterService {
  
  parseCnaeValueFilter(cnaeList:Array<CnaeDTO>){
    //Elimina la descripcion del cnae para evitar caracteres raros dentro del endpoint que hagan que la busqueda sea erronea
    //Ejemplo: En descripciones con parentesis hace que el back interprete una sola descripcion como dos separadas
    if(cnaeList && cnaeList.length > 0){
      return cnaeList.map(({ descripcion, ...rest }) => rest);
    }
    return [];
  }


}