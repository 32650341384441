/**
 * SDRR-Abierto API
 * SDRR-Abierto API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AdheridoFacturaDTO { 
    fechaAlta?: string;
    creadoPor?: string;
    fechaModificacion?: string;
    modificadoPor?: string;
    id?: number;
    razonSocial?: string;
    tipoDocumento?: AdheridoFacturaDTO.TipoDocumentoEnum;
    codigoDocumento?: string;
}
export namespace AdheridoFacturaDTO {
    export type TipoDocumentoEnum = 'NIF' | 'DNI' | 'NIE';
    export const TipoDocumentoEnum = {
        Nif: 'NIF' as TipoDocumentoEnum,
        Dni: 'DNI' as TipoDocumentoEnum,
        Nie: 'NIE' as TipoDocumentoEnum
    };
}


