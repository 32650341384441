/**
 * Adheridos API
 * Adheridos API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IdEntityDTO } from './idEntityDTO';


export interface DeclaracionEnvaseAnteriorFilterDTO { 
    fechaAlta?: Array<string>;
    creadoPor?: Array<string>;
    fechaModificacion?: Array<string>;
    modificadoPor?: Array<string>;
    declaracionAdherido?: number;
    centro?: number;
    categoriaEnvase?: number;
    tipoEnvases?: IdEntityDTO;
    peligrosidad?: boolean;
    tipologia?: DeclaracionEnvaseAnteriorFilterDTO.TipologiaEnum;
}
export namespace DeclaracionEnvaseAnteriorFilterDTO {
    export type TipologiaEnum = 'PRIMARIO' | 'SECUNDARIO' | 'TERCIARIO';
    export const TipologiaEnum = {
        Primario: 'PRIMARIO' as TipologiaEnum,
        Secundario: 'SECUNDARIO' as TipologiaEnum,
        Terciario: 'TERCIARIO' as TipologiaEnum
    };
}


