import { Attribute, Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SpinnerService } from '../../../services/spinner.service';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import Swal from 'sweetalert2';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import {
  ComunidadControllerService,
  PageDTOPtoRecogidaDTO,
  PtoRecogidaFilterDTO,
  PuntoRecogidaControllerService,
  PtoRecogidaEstadoControllerService,
  PtoRecogidaCodigoControllerService,
  GestorPtoRecogidaControllerService,
  PtoRecogidaGestorControllerService,
} from 'api-rest';
import { CollectionPointsPreliminaryComponent } from '../collection-points-preliminary/collection-points-preliminary.component';
import { NextPageService } from '../../../services/nextPage.service';
import { debounceTime, zip } from 'rxjs';

@Component({
  selector: 'app-collection-points-search',
  templateUrl: './collection-points-search.component.html',
  styleUrls: ['./collection-points-search.component.scss'],
})
export class CollectionPointsSearchComponent implements OnInit {
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  updateList: boolean = false;
  cnaeEndpoint = this.environment.urlBackCore + 'api/v1/core/cnae';

  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  totalElements: number = 0;
  totalSearch: number = 0;

  comunidadArray: any[] = [];
  estadoArray: any[] = [];

  residuosOptionArray = [{ value: 'Si' }, { value: 'No' }];

  headArray: any = [
    {
      Head: 'ID. Punto de Recogida',
      FieldName: 'id',
    },
    {
      Head: 'Razón Social',
      FieldName: 'razonSocial',
    },
    {
      Head: 'Denominación',
      FieldName: 'denominacion',
    },
    {
      Head: 'Documento (NIE,NIF)',
      FieldName: 'codigoDocumento',
    },
    {
      Head: 'Comunidad autónoma (Dirección Física)',
      FieldName: 'domicilios',
      renderValue: (item: any) => {
        const matchingDomicilio = item?.domicilios?.find(
          (domicilio: any) => domicilio?.tipoDireccion?.id == 5
        );
        return matchingDomicilio ? matchingDomicilio.provincia?.nombre : null;
      },
    },
    {
      Head: 'Residuos Peligrosos',
      FieldName: 'residuosPeligrosos',
      renderValue: (item: any) =>
        item.residuosPeligrosos != 'Si' ? 'No' : item.residuosPeligrosos,
    },
    {
      Head: 'Estado',
      FieldName: 'estado',
      Attribute: 'descripcionCorta',
    },
    {
      Head: 'CNAE',
      FieldName: 'cnae',
      Attribute: 'descripcion',
    },
    {
      Head: 'NIMA  OT',
      FieldName: 'nimaOperador',
    },
    {
      Head: 'NIMA Pdr',
      FieldName: 'nimaPtoRecogida',
    },

    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Editar',
          show: { params: 'canAccessView', accion: 'view' },
        },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
        {
          nameButton: 'menu',
          array: [
            {
              nameButton: 'generar_convenio',
              name: 'Generar Convenio con Punto de Recogida',
              show: {
                params: 'canAccessUpdateCont',
                accion: 'generar_convenio',
              },
            },
            {
              nameButton: 'completado_punto',
              name: 'Punto de recogida completado',
              show: {
                params: 'canAccessComplete',
                accion: 'completado_punto',
              },
            },
            {
              nameButton: 'baja_punto',
              name: 'Baja del punto de recogida',
              show: {
                params: 'canAccessDown',
                accion: 'baja_punto',
              },
            },
          ],
        },
      ],
      width: '8',
      permanent: true,
    },
  ];
  gridArray: Array<any> = [];

  estadosArray: any = [];
  ptoAsociadoArray: any = [];
  codigoLerArray: any = [];

  searchForm: FormGroup;
  searchControl: FormControl = new FormControl();
  filteredComunidades: any[] = [];

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private downloadService: DownloadService,
    private snackBarSrv: SnackbarService,
    private comunidadControllerService: ComunidadControllerService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    private puntoRecogidaControllerService: PuntoRecogidaControllerService,
    private ptoRecogidaEstadoControllerService: PtoRecogidaEstadoControllerService,
    private nextPageService: NextPageService,
    private ptoRecogidaCodigoControllerService: PtoRecogidaCodigoControllerService,
    private gestorPtoRecogidaControllerService: GestorPtoRecogidaControllerService,
    private ptoRecogidaGestorControllerService: PtoRecogidaGestorControllerService
  ) {
    this.searchForm = this.formBuilder.group({
      idPuntoRecogida: [{ value: null, disabled: false }, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      codigo_documento: [{ value: null, disabled: false }],
      razon_social: [{ value: null, disabled: false }],
      denominacion: [{ value: null, disabled: false }],

      comunidad_autonoma: [{ value: null, disabled: false }],
      estado: [{ value: null, disabled: false }],
      cnae: [{ value: null, disabled: false }],

      nima: [{ value: null, disabled: false }],
      resiPeligrosos: [{ value: null, disabled: false }],
    });
  }

  ngOnInit() {
    this.getComboComunidad();
    this.comboEstados();

    this.searchControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value) => {
        this.filterComunidades(value);
      });
  }

  filterComunidades(searchTerm: any) {
    if (searchTerm) {
      this.filteredComunidades = this.comunidadArray.filter((comunidad) =>
        comunidad.nombre.toLowerCase().includes(searchTerm.toLowerCase())
      );
    } else {
      this.filteredComunidades = this.comunidadArray;
    }
  }

  checked(event: any) {}

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.searchData();
    }
  }

  exportarExcel() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.puntoRecogidaControllerService
      .exportPtoRecogidaListCSV(backendFilter, 'response')
      .subscribe((res) => {
        const filename = this.downloadService.getFileName(
          res.headers,
          'Exportación Punto Recogida.csv'
        );
        this.downloadService.downloadCSV(res.body!, filename!);
      });
  }

  clearSearcher() {
    this.searchForm.reset();
    this.flagSearch = false;
    this.gridArray = [];
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'cnae': {
        return this.searchForm.get('cnae') as FormControl;
        break;
      }
      default: {
        return this.searchForm.get('') as FormControl;
        break;
      }
    }
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.searchForm.controls[controlName].hasError(errorName) &&
      this.searchForm.controls[controlName].touched
    );
  }

  private getBackendFilter() {
    const backendFilter: PtoRecogidaFilterDTO | any = {
      id: this.searchForm.get('idPuntoRecogida')?.value,
      razonSocial: this.searchForm.get('razon_social')?.value
        ? `ci(${this.searchForm.get('razon_social')?.value})`
        : undefined,
      denominacion: this.searchForm.get('denominacion')?.value
        ? `ci(${this.searchForm.get('denominacion')?.value})`
        : undefined,
      codigoDocumento: this.searchForm.get('codigo_documento')?.value
        ? `ci(${this.searchForm.get('codigo_documento')?.value})`
        : undefined,
      comunidadAutonoma: this.searchForm.get('comunidad_autonoma')?.value?.id,
      estado: { id: this.searchForm.get('estado')?.value?.id },
      nima: this.searchForm.get('nima')?.value
        ? this.searchForm.get('nima')?.value
        : undefined,
      residuosPeligrosos:
        this.searchForm.get('resiPeligrosos')?.value === 'Si'
          ? true
          : this.searchForm.get('resiPeligrosos')?.value === 'No'
          ? false
          : undefined,
    };
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;
    return { backendFilter, page, size: this.pageSize };
  }

  searchData() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.puntoRecogidaControllerService
      .findAllPtoRecogida(backendFilter, {
        page: page,
        size: size,
        sort: ['id,desc'],
      })
      .subscribe({
        next: (data: PageDTOPtoRecogidaDTO) => {
          if (data) {
            this.spinnerSrv.loadingHide();
            this.flagSearch = data.datos?.length != 0 ? true : false;
            this.gridArray = this.canAccessAll(data.datos);
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.totalSearch = this.gridArray.length;
          }
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  action(element: any) {
    if (element.nameButton == 'view') {
      this.openDetailShow(element.id);
    } else if (element.nameButton == 'delete') {
      this.confirmacionEliminar(element);
    } else if (element.nameButton == 'generar_convenio') {
      this.spinnerSrv.loadingShow();
      this.puntoRecogidaControllerService
        .generarConvenioPtoRecogida(element.id)
        .subscribe({
          next: () => {
            this.spinnerSrv.loadingHide();
            this.searchData();
            this.snackBarSrv.showSnackBar(
              'El convenio con el Punto de Recogida ha sido generado correctamente. El usuario recibirá un email con instrucciones para el proceso de firma del convenio.',
              'success'
            );
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            console.log(err);
          },
        });
    } else if (element.nameButton == 'completado_punto') {
      this.verificarCompletado(element);
    } else if (element.nameButton == 'baja_punto') {
      this.spinnerSrv.loadingShow();
      this.puntoRecogidaControllerService
        .bajaPtoRecogida(element.id)
        .subscribe({
          next: () => {
            this.spinnerSrv.loadingHide();
            this.searchData();
            this.snackBarSrv.showSnackBar(
              'Se ha dado de baja el punto recogida correctamente',
              'success'
            );
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            console.log(err);
          },
        });
    }
  }

  confirmacionEliminar(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar el punto de recogida?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarPoseedor(element);
      }
    });
  }

  eliminarPoseedor(element?: any) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.puntoRecogidaControllerService
        .borrarPtoRecogida(element.id)
        .subscribe({
          next: (value: any) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              `El punto de recogida se ha eliminado correctamente`,
              'success'
            );
            this.gridArray = this.gridArray.filter(
              (objeto) => objeto.id !== element.id
            );
            this.flagSearch = this.gridArray.length != 0 ? true : false;
          },
          error: (err: { message: any }) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al eliminar el punto recogida',
              'error'
            );
          },
        });
    }
  }

  comboEstados() {
    this.ptoRecogidaEstadoControllerService
      .findAllPtoRecogidaEstado()
      .subscribe({
        next: (data: any) => {
          this.estadosArray = data!;
        },
        error: () => {},
      });
  }

  openDetailShow(id: number): void {
    this.nextPageService.setTempNextSearch(true);
    this.router.navigate(['/collection-points/detail', id]);
  }

  getCnaDescription(cnae: any) {
    return cnae.clase + ' - ' + cnae.descripcion;
  }

  getCnaeObjectQuery(searchterm: string) {
    return {
      descripcion: `or(ci(${searchterm}))`,
      clase: `or(ci(${searchterm}))`,
    };
  }

  canAccessAll(list: any) {
    const resultView = this.authService.can('ptosrecogida', 'view');
    const resultDelete = this.authService.can('ptosrecogida', 'delete');
    const resultUpdateCont = this.authService.can(
      'ptosrecogida',
      'update-contract'
    );
    const resultUpdateComplete = this.authService.can(
      'ptosrecogida',
      'update-complete'
    );
    const resultUpdateDown = this.authService.can(
      'ptosrecogida',
      'update-unregister'
    );

    const listPermission = list.map((objeto: any) => {
      const estadoDelete =
        objeto.estado && [1, 2, 3, 7].includes(objeto.estado.id);
      const estadoUpdateCont =
        objeto.estado && (objeto.estado.id === 2 || objeto.estado.id === 7);
      const estadocompletado = objeto.estado && objeto.estado.id === 4;
      const estadobaja = objeto.estado && objeto.estado.id === 5;

      return {
        ...objeto,
        canAccessView: resultView,
        canAccessDelete: resultDelete && estadoDelete,
        canAccessUpdateCont: resultUpdateCont && estadoUpdateCont,
        canAccessComplete: resultUpdateComplete && estadocompletado,
        canAccessDown: resultUpdateDown && estadobaja,
      };
    });

    return listPermission;
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.searchForm.controls[controlName]?.touched ||
        this.searchForm.controls[controlName]?.dirty) &&
      this.searchForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  getComboComunidad() {
    this.comunidadControllerService
      .listarComunidades3({}, { page: 0, size: 500, sort: [] })
      .subscribe({
        next: (data: any) => {
          this.comunidadArray = data.datos!;
          this.filteredComunidades = this.comunidadArray;
        },
        error: () => {},
      });
  }

  openModalPreliminary() {
    const dialogRef = this.dialog.open(CollectionPointsPreliminaryComponent, {
      width: '950px',
      maxWidth: '95%',
      maxHeight: '95vh',
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.searchData();
      }
    });
  }

  canAccessCreate(): boolean {
    return this.authService.can('ptosrecogida', 'create-alta');
  }

  getPtoAsociados(element?: any) {
    return this.ptoRecogidaGestorControllerService.findAllPtoRecogidaGestor(
      {
        ptoRecogida: { id: element.id },
      },
      { page: 0, size: 10 }
    );
  }

  getPtoCodigoLer(element?: any) {
    return this.ptoRecogidaCodigoControllerService.findAllPtoRecogidaCodigo(
      {
        ptoRecogida: { id: element.id },
      },
      { page: 0, size: 10 }
    );
  }

  ptoRecogidaCompletado(element?: any) {
    this.puntoRecogidaControllerService
      .actualizarEstadoPtoRecogida(element.id, 5)
      .subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.searchData();
          this.snackBarSrv.showSnackBar(
            'Se ha completado correctamente el Punto de Recogida',
            'success'
          );
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          console.log(err);
        },
      });
  }

  verificarCompletado(element?: any) {
    this.spinnerSrv.loadingShow();
    zip(this.getPtoAsociados(element), this.getPtoCodigoLer(element)).subscribe(
      {
        next: ([ptoAsociadosResponse, codigoLerResponse]) => {
          this.codigoLerArray = codigoLerResponse?.datos || [];

          if (this.codigoLerArray.length > 0) {
            this.ptoRecogidaCompletado(element);
          } else {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'No se puede completar el Punto de Recogida porque debe disponer de, al menos, un código LER informado',
              'error'
            );
          }
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
        },
      }
    );
  }

  onlyNumberKey(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Control'];

    if (event.ctrlKey && (event.key === 'c' || event.key === 'v')) return;

    // Permitir números y teclas adicionales definidas
    if (!/^[0-9]$/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
}
