import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ValidateService {
  numericPattern: string = '^[0-9]+$';
 
  constructor() {}
}
