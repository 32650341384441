/*
 * Public API Surface of packaging-reuse
 */

import { DocumControllerService } from './api-docum';
import { SolicitudEnvaseControllerService } from './api-sddrabierto/api/solicitudEnvaseController.service';

///////////// Exportar api de Poseedores  //////////////////
// export * from './api-poseedores/index';
export * as ApiPoseedores from './api-poseedores/index';
export { ApiModule as ApiModulePoseedores } from './api-poseedores/api.module';

export {
  TarifaPoseedoresControllerService,
  PoseedorControllerService,
  PoseedorEstadoControllerService,
  GestoresResiduosControllerService,
  GestoresResiduosEstadosControllerService,
  FacturaPoseedorControllerService,
  FacturaPoseedorEstadosControllerService,
  ResiduoEnvaseControllerService,
  OperacionGestionControllerService,
  AportacionDIPoseedorControllerService,
  AportacionDIEstadoControllerService,
  CodigoOperacionControllerService,
} from './api-poseedores/api/api';

export {
  TarifaPoseedoresPayloadDTO,
  TarifaPoseedoresDTO,
  TarifaPoseedoresFilterDTO,
  PageDTOTarifaPoseedoresDTO,
  CodigoLerDTO,
  CodigoLerFilterDTO,
  PoseedorPayloadDTO,
  PoseedorDTO,
  PoseedorFilterDTO,
  PageDTOPoseedorDTO,
  PoseedorEstadoDTO,
  GestionResiduosFilterDTO,
  GestoresResiduosDTO,
  PageDTOGestoresResiduosDTO,
  FacturaEstadoDTO,
  FacturaPoseedorFilterDTO,
  GestoresResiduosGeneralPayloadDTO,
  ResiduoEnvasePayloadDTO,
  OperacionGestionFilterDTO,
  PageDTOOperacionGestionDTO,
  AportacionDIPoseedorPayloadDTO,
  AportacionDIPoseedorDTO,
  AportacionDIPoseedorFilterDTO,
  ResiduoEnvaseFilterDTO,
  SolicitudNimaGestoresDTO,
  SolicitudNimaPoseedorDTO,
  SolicitudCodigoLerDTO,
  AportacionDIEstadoDTO,
  CodigoOperacionFilterDTO,
  PageDTOCodigoOperacionDTO,
  SolicitudPoseedorPrevisionDTO,
  PoseedorAdheridoPayloadDTO,
  PoseedorCreatePayloadDTO,
  DescripcionEnvaseFilterDTO,
  DescripcionEnvaseDTO,
  FacturaDTO,
  PageDTOFacturaDTO
} from './api-poseedores/model/models';

///////////// Exportar api de Adheridos  //////////////////
export * from './api-adheridos/index';
export * as ApiAhderidos from './api-adheridos/index';

export { AportacionDiAdheridoControllerService } from './api-adheridos/api/api';

export { SolicitudGestoresDTO } from './api-adheridos/model/models';

//////////// Exportar api de User management //////////////
export * as ApiUserMgmt from './api-usermanagement/index';
export { ApiModule as ApiModuleUserMgmt } from './api-usermanagement/api.module';
export {
  KeycloakRoleControllerService,
  UserManagementControllerService,
} from './api-usermanagement/api/api';
export {
  EntidadAsociadaDTO,
  EntidadAsociadaFilterDTO,
  KeycloakRoleDTO,
  KeycloakUserDTO,
  KeycloakUserFilterDTO,
  KeycloakUserPayloadDTO,
  PageDTOKeycloakUserDTO,
  UserRoleEntityDTO,
  UserRoleEntityFilterDTO,
  KeycloackUserRoleDTO,
  PageDTOKeycloackUserRoleDTO
} from './api-usermanagement/model/models';

//////////// Exportar api de Core //////////////
export * as ApiCore from './api-core/index';
export { ApiModule as ApiModuleCore } from './api-core/api.module';

export {
  AgrupacionMaterialControllerService,
  CategoriaEnvaseControllerService,
  CnaeControllerService,
  CodigoPostalControllerService,
  ComunidadControllerService,
  ContactoControllerService,
  DireccionControllerService,
  GrupoEnvaseControllerService,
  PaisControllerService,
  ProvinciaControllerService,
  TipoContactoControllerService,
  TipoDireccionControllerService,
  TipoDocumentoControllerService,
  TipoEnvasesControllerService,
  TipoViaControllerService,
  ComposicionMaterialControllerService,
  CodigoLerControllerService
} from './api-core/api/api';

export {
  AgrupacionMaterialDTO,
  AgrupacionMaterialFilterDTO,
  CategoriaEnvaseModel,
  CnaeDTO,
  CnaeFilterDTO,
  CnaeKey,
  CnaeModel,
  CodigoPostalDTO,
  CodigoPostalEmbeddable,
  CodigoPostalFilterDTO,
  ComposicionMaterialDTO,
  ComposicionMaterialFilterDTO,
  ComunidadDTO,
  ComunidadFiltroDTO,
  ContactoModel,
  DireccionModel,
  GrupoEnvaseDTO,
  GrupoEnvaseFiltroDTO,
  PageContactoModel,
  PageDTOAgrupacionMaterialDTO,
  PageDTOCnaeDTO,
  PageDTOCodigoPostalDTO,
  PageDTOComposicionMaterialDTO,
  PageDTOComunidadDTO,
  PageDTOGrupoEnvaseDTO,
  PageDTOPaisDTO,
  PageDTOProvinciaDTO,
  PageDTOTipoEnvasesDTO,
  PageDTOTipoViaDTO,
  PageDireccionModel,
  PageTipoContactoModel,
  PageTipoDireccionModel,
  PaisDTO,
  PaisFiltroDTO,
  PaisModel,
  ProvinciaDTO,
  ProvinciaEmbeddable,
  ProvinciaFiltroDTO,
  TipoContactoModel,
  TipoDireccionModel,
  TipoDocumentoModel,
  TipoEnvasesDTO,
  TipoEnvasesFilterDTO,
  TipoViaDTO,
  TipoViaFiltroDTO,
  TipoViaModel,
  PageDTOCodigoLerDTO,
} from './api-core/model/models';

export * from './lib/api-rest.module';

export * as ApiDocum from './api-docum/index';
export { ApiModule as ApiModuleDocum } from './api-docum/api.module';

export {
  DocumControllerService,
  PlantillaDocumControllerService,
  DocumentoInteresControllerService
} from './api-docum/api/api';

export { DocumentoModel, PlantillaDocumModel, DocumentoInteresModel } from './api-docum/model/models';

export * as ApiPuntosrecogida from './api-puntosrecogida/index';
export { ApiModule as ApiModulePuntosrecogida } from './api-puntosrecogida/api.module';

export {
  GestorPtoRecogidaCodigoControllerService,
  GestorPtoRecogidaControllerService,
  HelloWorldControllerService,
  PuntoRecogidaControllerService,
  PtoRecogidaGestorControllerService,
  PtoRecogidaEstadoControllerService,
  GestorPtoRecogidaEstadoControllerService,
  SolicitudControllerService,
  SolicitudEstadoControllerService,
  TarifaPtoRecogidaControllerService,
  AcondicionamientoControllerService,
  PtoRecogidaCodigoControllerService,
  FacturaPtoRecogidaControllerService,
  OperacionesTratamientoControllerService
} from './api-puntosrecogida/api/api';

export {
  GestorPtoRecogidaCreatePayloadDTO,
  GestorPtoRecogidaDTO,
  GestorPtoRecogidaFilterDTO,
  GestorPtoRecogidaPayloadDTO,
  PageDTOGestorPtoRecogidaDTO,
  Pageable,
  GestorPtoRecogidaCodigoFilterDTO,
  GestorPtoRecogidaCodigoPayloadDTO,
  PageDTOGestorPtoRecogidaCodigoDTO,
  GestorPtoRecogidaCodigoDTO,
  FacturaPRDTO,
  PageDTOFacturaPtoRecogidaDTO,
  PageDTOPtoRecogidaDTO,
  PtoRecogidaDTO,
  PtoRecogidaFilterDTO,
  PtoRecogidaPayloadDTO,
  PageDTOTarifaPtoRecogidaDTO,
  TarifaPtoRecogidaFilterDTO,
  TarifaPtoRecogidaDTO,
  TarifaPtoRecogidaPayloadDTO,
  PtoRecogidaCodigoPayloadDTO,
  PtoRecogidaCodigoFilterDTO,
  PtoRecogidaCreatePayloadDTO,
  PtoRecogidaGestorPayloadDTO,
  PtoRecogidaGestorFilterDTO,
  SolicitudFilterDTO,
  PageDTOSolicitudDTO,
  SolicitudCreatePayloadDTO,
  FacturaPtoRecogidaFilterDTO,
} from './api-puntosrecogida/model/models';

//////////// Exportar api de Comunicaciones //////////////
export * as ApiComunicaciones from './api-comunicaciones/index';
export { ApiModule as ApiModuleComunicaciones } from './api-comunicaciones/api.module';
export {
  ComunicacionControllerService,
  ComunicacionEstadoControllerService,
  ComunicacionHistorialControllerService
} from './api-comunicaciones/api/api';
export {
  ComunicacionDTO,
  ComunicacionFilterDTO,
  PageDTOComunicacionDTO,
  ComunicacionEstadoDTO,
  ComunicacionHistorialFilterDTO,
  ComunicacionPayloadDTO,
  ComunicacionHistorialPayloadDTO
} from './api-comunicaciones/model/models';

//////////// Exportar Api de SDDR Abierto //////////////
export * as ApiSDDRCA from './api-sddrabierto/index';
export { ApiModule as ApiModuleSDDRCA } from './api-sddrabierto/api.module';
export {
  SolicitudEnvaseControllerService,
  SolicitudEnvaseEstadoControllerService,
  SolicitudFacturaExportacionControllerService,
  SolicitudInformeRetiradaControllerService,
  SolicitudInformeRetiradaDocumControllerService,
  SolicitudRetiradaControllerService,
  SolicitudRetiradaEstadoControllerService,
  SolicitudReutilizacionControllerService,
  SolicitudReutilizacionEstadoControllerService,
  SubTipoEnvaseControllerService,
  TarifaGestorSddrcaControllerService,
  SolicitudReutilizacionAcreditacionControllerService,
  FacturaSddrcaControllerService,
  AportacionDisddrcaControllerService,
} from './api-sddrabierto/api/api';
export {
  PageDTOSolicitudReutilizacionDTO,
  SolicitudReutilizacionDTO,
  SolicitudReutilizacionFilterDTO,
  PageDTOSolicitudReutilizacionEstadoDTO,
  SolicitudReutilizacionEstadoFilterDTO,
  PageDTOSubTipoEnvaseDTO,
  SubTipoEnvaseFilterDTO,
  PageDTOTarifaGestorSDDRCADTO,
  TarifaGestorSDDRCADTO,
  TarifaGestorSDDRCAFilterDTO,
  TarifaGestorSDDRCAPayloadDTO,
  UsuarioSDDRCaDTO,
  SubTipoEnvaseDTO,
  SolicitudReutilizacionEstadoDTO,
  PageDTOSolicitudRetiradaDTO,
  PageDTOSolicitudRetiradaEstadoDTO,
  SolicitudRetiradaEstadoDTO,
  SolicitudRetiradaDTO,
  SolicitudRetiradaEstadoFilterDTO,
  SolicitudRetiradaFilterDTO,
  PageDTOSolicitudEnvaseDTO,
  SolicitudEnvaseDTO,
  SolicitudEnvaseFilterDTO,
  SolicitudEnvaseEstadoDTO,
  SolicitudInformeRetiradaDocumFilterDTO,
  SolicitudReutilizacionPayloadDTO,
  SolicitudFacturaExportacionPayloadDTO,
  SolicitudReutilizacionAcreditacionPayloadDTO,
  SolicitudReutilizacionCompletaPayloadDTO,
  SolicitudReutilizacionAcreditacionFilterDTO,
  PageDTOSolicitudReutilizacionAcreditacionDTO,
  SolicitudFacturaExportacionFilterDTO,
  SolicitudFacturaExportacionDTO,
  SolicitudReutilizacionAcreditacionDTO,
  AdheridoFacturaDTO,
  AportacionDISDDRCADTO,
  AportacionDISDDRCAFilterDTO,
  AportacionDISDDRCAPayloadDTO,
  FacturaSDDRCAFilterDTO,
  PageDTOAportacionDISDDRCADTO,
  SolicitudEnvasePayloadDTO,
  SolicitudInformeRetiradaCompletoPayloadDTO,
  SolicitudRetiradaCompletaPayloadDTO,
  PageDTOFacturaSddrcaDTO,
  FacturaSddrcaDTO,
  SolicitudRetiradaPayloadDTO,
  SolicitudInformeRetiradaDTO,
  SolicitudInformeRetiradaDocumDTO,
  SolicitudInformeRetiradaDocumPayloadDTO,
  SolicitudInformeRetiradaPayloadDTO
} from './api-sddrabierto/model/models';

//////////// Exportar Api de Acuerdos con Gestores //////////////
export * as ApiAcuerdosGestores from './api-acuerdosgestores/index';
export { ApiModule as ApiModuleAcuerdosGestores } from './api-acuerdosgestores/api.module';
export {
  AportacionDiAcuerdosGestoresControllerService,
  GestoresConAcuerdoControllerService,
  FacturaGestorAcuerdoControllerService,
  TarifaGestoresControllerService
} from './api-acuerdosgestores/api/api';
export {
  AportacionDIAcuerdosGestoresDTO,
  AportacionDIAcuerdosGestoresFilterDTO,
  AportacionDIAcuerdosGestoresPayloadDTO,
  ContactoDTO,
  DireccionDTO,
  DocumentoDTO,
  FacturaAGDTO,
  FacturaGestorAcuerdoFilterDTO,
  FacturaRecordDTO,
  GestoresConAcuerdoFilterDTO,
  GestoresResiduosEstadosDTO,
  GestorPtoRecogidaFacturaDTO,
  GestorResiduoReference,
  IdEntityDTO,
  IdEntityFilterDTO,
  OperacionGestionDTO,
  PageDTOAportacionDIAcuerdosGestoresDTO,
  PageDTOAGFacturaDTO,
  PageDTOTarifaGestoresDTO,
  PageInfoDTO,
  ResiduosGestionadosDTO,
  TarifaGestoresDTO,
  TarifaGestoresFilterDTO,
  TarifaGestoresPayloadDTO,
  TipoContactoDTO,
  TipoDireccionDTO,
  TipoDocumentoDTO,
} from './api-acuerdosgestores/model/models';
