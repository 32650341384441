/**
 * Poseedores API
 * Poseedores API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GestionResiduosFilterDTO } from './gestionResiduosFilterDTO';
import { PoseedorFilterDTO } from './poseedorFilterDTO';
import { IdEntityFilterDTO } from './idEntityFilterDTO';


export interface AportacionDIPoseedorFilterDTO { 
    fechaAlta?: Array<string>;
    creadoPor?: Array<string>;
    fechaModificacion?: Array<string>;
    modificadoPor?: Array<string>;
    poseedor?: PoseedorFilterDTO;
    nimaGestor?: Array<string>;
    gestorResiduo?: GestionResiduosFilterDTO;
    fechaRecogida?: Array<string>;
    fechaAceptacion?: Array<string>;
    documentoIdentificacion?: Array<string>;
    estado?: IdEntityFilterDTO;
    origen?: AportacionDIPoseedorFilterDTO.OrigenEnum;
}
export namespace AportacionDIPoseedorFilterDTO {
    export type OrigenEnum = 'ADHERIDO' | 'POSEEDOR' | 'SDDRCA' | 'GESTORESACUERDO';
    export const OrigenEnum = {
        Adherido: 'ADHERIDO' as OrigenEnum,
        Poseedor: 'POSEEDOR' as OrigenEnum,
        Sddrca: 'SDDRCA' as OrigenEnum,
        Gestoresacuerdo: 'GESTORESACUERDO' as OrigenEnum
    };
}


