import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import {
  UsuarioSDDRCaDTO,
  SolicitudRetiradaDTO,
  SolicitudReutilizacionControllerService,
  ComunidadControllerService,
  SubTipoEnvaseDTO,
  SolicitudRetiradaControllerService,
  TipoEnvasesControllerService,
  SubTipoEnvaseControllerService,
  SubTipoEnvaseFilterDTO,
  GestoresResiduosControllerService,
  GestionResiduosFilterDTO,
  SolicitudRetiradaCompletaPayloadDTO,
  SolicitudFacturaExportacionPayloadDTO,
  SolicitudRetiradaPayloadDTO,
  FacturaDTO,
  DocumControllerService,
  SolicitudInformeRetiradaControllerService,
  SolicitudInformeRetiradaDTO,
  SolicitudFacturaExportacionDTO,
  SolicitudFacturaExportacionControllerService,
  SolicitudInformeRetiradaDocumControllerService,
  SolicitudInformeRetiradaDocumDTO,
  SolicitudInformeRetiradaDocumPayloadDTO,
  SolicitudInformeRetiradaPayloadDTO,
  SolicitudInformeRetiradaCompletoPayloadDTO
} from 'api-rest';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { DatePipe } from '@angular/common';
import { DownloadService } from '../../../services/download.service';
import { zip } from 'rxjs';
import { ValidateService } from '../../../services/validate.service';
import Swal from 'sweetalert2';
import { ParseCurrencyPipe } from '../../../share/pipe/ParseCurrency.pipe';
import { FormatCurrencyPipe } from '../../../share/pipe/FormatCurrency.pipe';

@Component({
  selector: 'app-open-sddr-requests-withdrawn-detail',
  templateUrl: './open-sddr-requests-withdrawn-detail.component.html',
  styleUrls: ['./open-sddr-requests-withdrawn-detail.component.scss'],
})
export class OpenSddrRequestsWithdrawnDetailComponent implements OnInit {
  datosSolicitudForm: FormGroup;
  informeRetiradaForm: FormGroup;
  facturaForm: FormGroup;
  informacionSolicitud: FormGroup;
  documentoInformeForm: FormGroup;
  acondicionadorForm!: FormGroup;
  pageNumber: number = 0;
  pageSize: number = 100;
  titulo: any;
  solicitudId!: number;
  solicitud: any;
  informeRetirada!: SolicitudInformeRetiradaDTO;
  paisesEndpoint = this.environment.urlBackCore + 'api/v1/core/countries';
  tiposUsusariosSddr!: UsuarioSDDRCaDTO[];
  selectedIndex = 0;

  flagDisabledForm: boolean = false;
  updateList: boolean = false;
  flagSearch: boolean = false;
  comunidadArray: any[] = [];
  gestorArray: any[] = [];
  estadosArray: any[] = [];
  ptoArray: any[] = [];
  gridDocumentos: Array<any> = [];
  gridFacturas: Array<any> = [];
  gridAcondicionadores: Array<any> = [];
  adjuntosBlobFacturas: Array<File> = [];
  adjuntosBlobDocumentos: Array<File> = [];
  hayDocumento: boolean = true;
  hayFactura: boolean = true;
  verBuscador: boolean = false;
  soloLecturaDatos!: boolean;
  soloLecturaInforme!: boolean;

  facturaBlob: File | undefined;
  documentoBlob: File | undefined;

  headArrayFacturas: any[] = [
    {
      Head: 'Nº Factura',
      FieldName: 'numFactura',
    },
    {
      Head: 'Documento',
      FieldName: 'documento',
      Attribute: 'name',
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        }, {
          nameButton: 'download',
          icon: 'download',
          toolTip: 'Descargar',
          show: { params: 'canAccessDownload', accion: 'download' },
        }
      ],
      width: '10',
      permanent: true,
    },
  ];

  headArrayDocumentos: any[] = [
    {
      Head: 'Nombre',
      FieldName: 'nombre',
    },
    {
      Head: 'Documento',
      FieldName: 'documento',
      Attribute: 'name',
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        }, {
          nameButton: 'download',
          icon: 'download',
          toolTip: 'Descargar',
          show: { params: 'canAccessDownload', accion: 'download' },
        }
      ],
      width: '10',
      permanent: true,
    },
  ];

  headArrayAcondicionadores: any[] = [
    {
      Head: 'Razón social',
      FieldName: 'razonSocial',
    },
    {
      Head: 'Dirección',
      FieldName: 'domicilios',
      renderValue: (item: any) => {
        //Se muestra la dirección física
        const direccion = item?.domicilios?.find((x: any) => x?.tipoDireccion?.id === 5);
        return direccion?.fullAddress;
      }
    },
    {
      Head: 'Teléfono',
      FieldName: 'contactos',
      renderValue: (item: any) => {
        //Se muestra contacto tipo gestión
        const contacto = item?.contactos?.find((x: any) => x?.tipo_contacto?.id === 1);
        return contacto?.numero_telefono;
      }
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'select',
          icon: 'check_circle',
          toolTip: 'Seleccionar'
        },
      ],
      width: '2',
      permanent: true,
    },
  ];

  @ViewChild('fileInputA') fileInputA: any;
  tipoEnvaseArray: any = [];
  subtipoEnvaseArray!: SubTipoEnvaseDTO[];

  constructor(
    private formBuilder: FormBuilder,
    @Inject('environment') private environment: any,
    private dialogRef: MatDialogRef<OpenSddrRequestsWithdrawnDetailComponent>,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private datePipe: DatePipe,
    private solicitudRetiradaCtrlSrv: SolicitudRetiradaControllerService,
    private tipoEnvasesControllerService: TipoEnvasesControllerService,
    private subtipoEnvaseControllerService: SubTipoEnvaseControllerService,
    private solicitudReutilizacionCtrlSrv: SolicitudReutilizacionControllerService,
    private validateSrv: ValidateService,
    private comunidadControllerService: ComunidadControllerService,
    private getoresResiduosCtrlSrv: GestoresResiduosControllerService,
    private parseCurrencyPipe: ParseCurrencyPipe,
    private formatCurrencyPipe: FormatCurrencyPipe,
    private documCtrlSrv: DocumControllerService,
    private downloadSrv: DownloadService,
    private solicitudInformacionRetiradaCtrlSrv: SolicitudInformeRetiradaControllerService,
    private solicitudFacturaExportacionCtrlSrv: SolicitudFacturaExportacionControllerService,
    private solicitudInformacionRetiradaDocumCtrlSrv: SolicitudInformeRetiradaDocumControllerService
  ) {
    this.solicitudId = this.data.solicitudId;
    this.soloLecturaDatos = this.data.soloLecturaDatos;
    this.soloLecturaInforme = this.data.soloLecturaInforme;

    this.datosSolicitudForm = this.formBuilder.group({
      usuarioSddr: [{ value: null, disabled: this.soloLecturaDatos }, [Validators.required]],
      tipoEnvase: [
        { value: null, disabled: this.soloLecturaDatos },
        { validators: Validators.compose([Validators.required]) },
      ],
      subtipoEnvase: [
        { value: null, disabled: this.soloLecturaDatos },
        { validators: Validators.compose([Validators.required]) },
      ],
      unidadesRetirar: [{ value: null, disabled: this.soloLecturaDatos }, {
        validators: Validators.compose([
          Validators.required,
          Validators.maxLength(13)
        ])
      }],
      pesoRetirar: [{ value: null, disabled: this.soloLecturaDatos }, {
        validators: Validators.compose([Validators.maxLength(13)])
      }],
      aceptacionRetirada: [
        { value: null, disabled: this.soloLecturaDatos },
        { validators: Validators.compose([Validators.required]) },
      ],
      acondicionador: [
        { value: null, disabled: this.soloLecturaDatos },
        { validators: Validators.compose([Validators.required]) },
      ],
      acondicionadorId: [{ value: null, disabled: this.soloLecturaDatos }],
      loteEnvase: [{ value: null, disabled: this.soloLecturaDatos }, {
        validators: Validators.compose([Validators.maxLength(255)])
      }],
      observacionesRetirada: [{ value: null, disabled: this.soloLecturaDatos }, {
        validators: Validators.compose([Validators.maxLength(255)])
      }],
      autofactura: [{ value: null, disabled: this.soloLecturaDatos }],
    });

    this.facturaForm = this.formBuilder.group({
      importeDepositoUnidad: [
        { value: null, disabled: this.soloLecturaDatos },
        { validators: Validators.compose([Validators.required, Validators.maxLength(255)]) },
      ],
      nFacturaDeposito: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(this.validateSrv.numericPattern),
            Validators.maxLength(255)
          ])
        },
      ],
    });

    this.informacionSolicitud = this.formBuilder.group({
      usuarioSolicitud: [{ value: null, disabled: true }],
      fechaSolicitud: [{ value: null, disabled: true }],
      estadoSolicitud: [{ value: null, disabled: true }],
    });

    this.informeRetiradaForm = this.formBuilder.group({
      usuarioSddrCa: [{ value: null, disabled: true }],
      verificacion: [
        { value: null, disabled: this.soloLecturaInforme },
        { validators: Validators.compose([Validators.required]) },
      ],
      udEnvases: [{ value: null, disabled: true }],
      kgEnvases: [{ value: null, disabled: true }],
      udRetirados: [
        { value: null, disabled: this.soloLecturaInforme },
        { validators: Validators.compose([Validators.required, Validators.maxLength(13)]) },
      ],
      kgRetirados: [
        { value: null, disabled: this.soloLecturaInforme },
        { validators: Validators.compose([Validators.maxLength(13)]) },
      ],
      udAptos: [
        { value: null, disabled: this.soloLecturaInforme },
        { validators: Validators.compose([Validators.required, Validators.maxLength(13)]) },
      ],
      kgAptos: [{ value: null, disabled: this.soloLecturaInforme }, {
        validators: Validators.compose([Validators.maxLength(13)])
      }],
      udNoAptos: [
        { value: null, disabled: this.soloLecturaInforme },
        { validators: Validators.compose([Validators.required, Validators.maxLength(13)]) },
      ],
      kgNoAptos: [
        { value: null, disabled: this.soloLecturaInforme },
        { validators: Validators.compose([Validators.required, Validators.maxLength(13)]) },
      ],
      envasesSalenSddr: [
        { value: null, disabled: this.soloLecturaInforme },
        { validators: Validators.compose([Validators.required]) },
      ],
      envasesMantienenSddr: [
        { value: null, disabled: this.soloLecturaInforme },
        { validators: Validators.compose([Validators.required]) },
      ],
    });

    this.documentoInformeForm = this.formBuilder.group({
      nombre: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(255)]) }],
    });
    this.acondicionadorForm = this.formBuilder.group({
      razonSocial: [{ value: null, disabled: false }, { validators: Validators.compose([Validators.required, Validators.maxLength(255)]) }],
      comunidadAutonoma: [{ value: null, disabled: false }],
      nima: [{ value: null, disabled: false }, { validators: Validators.compose([Validators.required, Validators.maxLength(10)]) }]
    });
  }

  ngOnInit(): void {
    this.cargarCombos();
    if (this.solicitudId) {
      this.titulo = 'Detalle de solicitud de retirada – ID: ' + this.solicitudId!;
      this.cargarSolicitud(this.solicitudId);
    } else {
      this.titulo = 'Alta solicitud de retirada';
    }
  }

  cargarSolicitud(idSolicitud: number): void {
    this.spinnerSrv.loadingShow();
    this.solicitudRetiradaCtrlSrv.getById1(idSolicitud)
      .subscribe({
        next: (response: SolicitudRetiradaDTO) => {
          this.spinnerSrv.loadingHide();
          this.solicitud = response;
          this.cargarDatosGenerales(this.solicitud);
          if (this.solicitud?.estado?.id > 1) {
            this.selectedIndex = 1;
            this.cargarInforme();
          }
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  cargarDatosGenerales(solicitud?: SolicitudRetiradaDTO) {
    this.datosSolicitudForm.patchValue({
      tipoEnvase: solicitud?.tipoEnvase?.id,
      subtipoEnvase: solicitud?.subTipoEnvase?.id,
      unidadesRetirar: this.formatCurrencyPipe.transform(solicitud?.cantidadRetirarUd),
      pesoRetirar: this.formatCurrencyPipe.transform(solicitud?.cantidadRetirarKg),
      aceptacionRetirada: solicitud?.aceptacionCondiciones,
      loteEnvase: solicitud?.loteEnvase,
      acondicionador: solicitud?.gestorAcondicionador?.razonSocial,
      acondicionadorId: solicitud?.gestorAcondicionador?.id,
      observacionesRetirada: solicitud?.observaciones
    });

    if (this.solicitud?.poseedor) {
      if (!this.solicitudId || this.solicitud?.estado?.id === 1) {
        this.datosSolicitudForm.get('usuarioSddr')?.setValue(this.solicitud?.poseedor?.id + 'POSEEDOR');
      } else {
        this.datosSolicitudForm.get('usuarioSddr')?.setValue(solicitud?.poseedor?.razonSocial);
      }
      this.informacionSolicitud.get('usuarioSolicitud')?.setValue(solicitud?.poseedor?.razonSocial);
    } else if (this.solicitud?.adherido) {
      if (!this.solicitudId || this.solicitud?.estado?.id === 1) {
        this.datosSolicitudForm.get('usuarioSddr')?.setValue(this.solicitud?.adherido?.id + 'ADHERIDO');
      } else {
        this.datosSolicitudForm.get('usuarioSddr')?.setValue(solicitud?.adherido?.razonSocial);
      }
      this.informacionSolicitud.get('usuarioSolicitud')?.setValue(solicitud?.adherido?.razonSocial);
    }
    this.facturaForm.get('importeDepositoUnidad')?.setValue(this.formatCurrencyPipe.transform(solicitud?.importeDeposito));
    this.informacionSolicitud.get('fechaSolicitud')?.setValue(this.datePipe.transform(solicitud?.fechaSolicitud, 'dd/MM/yyyy'));
    this.informacionSolicitud.get('estadoSolicitud')?.setValue(solicitud?.estado?.descripcion);

    this.seleccionTipoEnvase();
    this.obtenerFacturas();
  }
  obtenerFacturas() {
    this.spinnerSrv.loadingShow();
    this.solicitudFacturaExportacionCtrlSrv.findAll2({ solicitud: this.solicitudId, tipoSolicitud: 'RETIRADA' }, { page: 0, size: 100 })
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          let facturas = response.datos;


          facturas.forEach((fac: SolicitudFacturaExportacionDTO) => {
            this.gridFacturas.push({
              id: fac?.id,
              key: fac?.id,
              numFactura: fac?.numFacturaDeposito,
              datosDoc: fac?.documento,
              documento: { name: fac?.documento?.nombre }
            });
          });
          this.gridFacturas = this.canAccessAllFacturas([...this.gridFacturas]);
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error obtener las facturas.',
            'error'
          );
        },
      });
  }
  cargarInforme(): void {
    this.spinnerSrv.loadingShow();
    this.solicitudInformacionRetiradaCtrlSrv.findAll1({ solicitudRetirada: { id: this.solicitudId } }, { page: 0, size: 1 })
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          this.informeRetirada = response?.datos[0]!;
          this.cargarDatosInforme(this.informeRetirada);
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  cargarDatosInforme(informe: SolicitudInformeRetiradaDTO): void {
    this.informeRetiradaForm.patchValue({
      verificacion: informe?.usuarioSddrca,
      udEnvases: this.solicitud?.cantidadRetirarUd,
      kgEnvases: this.solicitud?.cantidadRetirarKg,
      udRetirados: this.formatCurrencyPipe.transform(informe?.envasesRetiradosUd),
      kgRetirados: this.formatCurrencyPipe.transform(informe?.envasesRetiradosKg),
      udAptos: this.formatCurrencyPipe.transform(informe?.envasesAptosUd),
      kgAptos: this.formatCurrencyPipe.transform(informe?.envasesAptosKg),
      udNoAptos: this.formatCurrencyPipe.transform(informe?.envasesNoAptosUd),
      kgNoAptos: this.formatCurrencyPipe.transform(informe?.envasesNoAptosKg),
      envasesSalenSddr: informe?.envasesSalidaSddr,
      envasesMantienenSddr: informe?.envasesDentroSddr
    });
    if (this.solicitud?.poseedor) {
      this.informeRetiradaForm.get('usuarioSddrCa')?.setValue(this.solicitud?.poseedor?.razonSocial);
    } else if (this.solicitud?.adherido) {
      this.informeRetiradaForm.get('usuarioSddrCa')?.setValue(this.solicitud?.adherido?.razonSocial);
    }
    if (informe?.id) {
      this.obtenerDocumentos(informe?.id);
    }
  }
  obtenerDocumentos(idInformeRetirada: number) {
    this.spinnerSrv.loadingShow();
    this.solicitudInformacionRetiradaDocumCtrlSrv.findAll({ solicitudInformeRetirada: { id: idInformeRetirada } }, { page: 0, size: 100 })
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          let documentos = response.datos;
          documentos.forEach((doc: SolicitudInformeRetiradaDocumDTO) => {
            this.gridDocumentos.push({
              id: doc.id,
              key: doc?.id,
              nombre: doc?.nombre,
              datosDoc: doc?.documento,
              documento: { name: doc?.documento?.nombre }
            });
          });
          this.gridDocumentos = this.canAccessAllDocumentos([...this.gridDocumentos]);
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error obtener los documentos.',
            'error'
          );
        },
      });
  }
  cargarCombos(): void {
    const tipoEnvaseFilter = { sddrca: true };
    this.spinnerSrv.loadingShow();
    zip(
      this.tipoEnvasesControllerService.findAll(tipoEnvaseFilter, { page: 0, size: 100 }),
      this.solicitudReutilizacionCtrlSrv.findAllUsuariosAsociados(),
      this.comunidadControllerService.listarComunidades3({}, { page: 0, size: 500, sort: [] })

    ).subscribe({
      next: (responses: any[]) => {
        this.spinnerSrv.loadingHide();
        this.tipoEnvaseArray = responses[0].datos;
        this.tiposUsusariosSddr = responses[1];
        this.comunidadArray = responses[2].datos!;

      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
      },
    });
  }
  seleccionTipoEnvase() {
    const subtipoEnvaseFilter: SubTipoEnvaseFilterDTO = { tipoEnvase: { id: this.datosSolicitudForm.get('tipoEnvase')?.value, sddrca: true } };
    this.spinnerSrv.loadingShow();
    this.subtipoEnvaseControllerService.listarSubTipoEnvases(subtipoEnvaseFilter, { page: 0, size: 100 })
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          this.subtipoEnvaseArray = response.datos
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }
  controlHasError(formGroup: FormGroup, controlName: string, errorName: string) {
    return (
      formGroup.controls[controlName].hasError(errorName) &&
      formGroup.controls[controlName].touched
    );
  }

  attachAdjuntoDocumentos(archivo: File | null): void {
    if (archivo != null) {
      this.hayDocumento = true;
      if (archivo.size === 0) {
        this.snackBarSrv.showSnackBar(
          'No se puede subir un fichero vacío',
          'error'
        );
      } else if (archivo.size > 5 * 1024 * 1024) { // 5 MB
        this.snackBarSrv.showSnackBar(
          'El fichero supera el tamaño máximo permitido de 5 MB',
          'error'
        );
      } else {
        this.adjuntosBlobDocumentos.push(archivo);
      }
    } else {
      this.hayDocumento = false;
    }
  }
  anadirDocumento() {
    if (this.documentoInformeForm.get('nombre')?.invalid) {
      this.documentoInformeForm.get('nombre')?.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Revisar el campo Tipo documento',
        'error'
      );
      return;
    }
    if (this.adjuntosBlobDocumentos?.length == 0) {
      this.hayDocumento = false;
      this.snackBarSrv.showSnackBar(
        'Revisar el campo Documento',
        'error'
      );
      return;
    }
    const obj = {
      id: -1,
      key: Math.random(),
      nombre: this.documentoInformeForm.get('nombre')?.value,
      documento: this.adjuntosBlobDocumentos[0],
    };

    this.gridDocumentos.unshift(obj);
    this.gridDocumentos = this.canAccessAllDocumentos([...this.gridDocumentos]);
    this.documentoInformeForm.get('nombre')?.setValue(null);
    this.documentoInformeForm.get('nombre')?.markAsPristine();
    this.documentoInformeForm.get('nombre')?.markAsUntouched();
    this.adjuntosBlobDocumentos = [];

  }

  addFactura(): void {
    if (this.facturaForm.get('nFacturaDeposito')?.invalid) {
      this.facturaForm.get('nFacturaDeposito')?.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Revisar el campo Número de factura depósito',
        'error'
      );
      return;
    }
    if (this.adjuntosBlobFacturas?.length == 0) {
      this.hayFactura = false;
      this.snackBarSrv.showSnackBar(
        'Revisar el campo Documento',
        'error'
      );
      return;
    }
    const obj = {
      key: Math.random(),
      id: -1,
      numFactura: this.facturaForm.get('nFacturaDeposito')?.value,
      documento: this.adjuntosBlobFacturas[0],
    };
    this.gridFacturas.unshift(obj);
    this.gridFacturas = this.canAccessAllFacturas([...this.gridFacturas]);
    this.facturaForm.get('nFacturaDeposito')?.setValue(null);
    this.facturaForm.get('nFacturaDeposito')?.markAsPristine();
    this.facturaForm.get('nFacturaDeposito')?.markAsUntouched();
    this.adjuntosBlobFacturas = [];
  }
  attachAdjuntoFacturas(archivo: File | null): void {
    if (archivo != null) {
      this.hayFactura = true;
      if (archivo.size === 0) {
        this.snackBarSrv.showSnackBar(
          'No se puede subir un fichero vacío',
          'error'
        );
      } else if (archivo.size > 5 * 1024 * 1024) { // 5 MB
        this.snackBarSrv.showSnackBar(
          'El fichero supera el tamaño máximo permitido de 5 MB',
          'error'
        );
      } else {
        this.adjuntosBlobFacturas.push(archivo);
      }
    } else {
      this.hayFactura = false;
    }
  }
  descartarDoc(archivo: Blob) {
    this.adjuntosBlobDocumentos = this.adjuntosBlobDocumentos.filter(adjBlob => adjBlob !== archivo);
  }
  descartarFac(archivo: Blob) {
    this.adjuntosBlobFacturas = this.adjuntosBlobFacturas.filter(adjBlob => adjBlob !== archivo);
  }
  actionFacturas(event: any) {
    if (event.nameButton == 'delete') {
      this.confirmacionEliminarFactura(event);
    } else if (event.nameButton == 'download') {
      this.descargarFactura(event);
    }
  }
  descargarFactura(doc: any) {
    this.spinnerSrv.loadingShow();
    this.solicitudFacturaExportacionCtrlSrv.downloadDoc1(doc?.id!)
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha descargado la factura correctamente',
            'success'
          );
          this.downloadSrv.downloadBlob(response, doc?.documento?.name!);
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al descargar la factura',
            'error'
          );
        },
      });
  }
  confirmacionEliminarFactura(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar la factura?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.snackBarSrv.showSnackBar(
          `Se ha eliminando correctamente.`,
          'success'
        );

        this.gridFacturas = this.gridFacturas.filter(
          (objeto) => objeto.key !== element.key
        );

      }
    });
  }

  actionDocumentos(event: any) {
    if (event.nameButton == 'delete') {
      this.confirmacionEliminarDocumento(event);
    } else if (event.nameButton == 'download') {
      this.descargarDocInforme(event);
    }
  }

  descargarDocInforme(doc: any) {
    this.spinnerSrv.loadingShow();
    this.solicitudInformacionRetiradaDocumCtrlSrv.downloadDoc(doc?.id!)
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha descargado el documento correctamente',
            'success'
          );
          this.downloadSrv.downloadBlob(response, doc?.documento?.name!);
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al descargar el documento',
            'error'
          );
        },
      });

  }
  confirmacionEliminarDocumento(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar el documento?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.snackBarSrv.showSnackBar(
          `Se ha eliminando correctamente.`,
          'success'
        );
        this.gridDocumentos = this.gridDocumentos.filter(
          (objeto) => objeto.key !== element.key
        );
      }
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  toogleBuscador() {
    this.verBuscador = !this.verBuscador;
  }

  actionAcondicionadores(event: any) {
    if (event.nameButton == 'select') {
      this.datosSolicitudForm.get('acondicionador')?.setValue(event?.razonSocial);
      this.datosSolicitudForm.get('acondicionadorId')?.setValue(event?.id);
      this.verBuscador = false;
    }
  }

  buscarAcondicionadores(): void {
    const body: GestionResiduosFilterDTO = {
      comunidadAutonoma: { id: this.acondicionadorForm.get('comunidadAutonoma')?.value },
      nima: [this.acondicionadorForm.get('nima')?.value],
      razonSocial: this.acondicionadorForm.get('razonSocial')?.value
    }
    this.spinnerSrv.loadingShow();
    this.getoresResiduosCtrlSrv.getAcondicionador(body, { page: 0, size: 100 })
      .subscribe({
        next: (respuesta: any) => {
          this.spinnerSrv.loadingHide();
          this.flagSearch = true;
          this.gridAcondicionadores = respuesta.datos;
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  guardarBorrador(): void {
    if (!this.esFormularioValido()) {
      this.snackBarSrv.showSnackBar(
        'Complete los campos obligatorios.',
        'error'
      );
    } else if (!this.cantidadRetirarValido()) {
      this.snackBarSrv.showSnackBar(
        'Revise la cantidad mínima de solicitud de retirada: para envases de tipo IBC = 5 y para tipo Pallets = 10',
        'error'
      );
    } else if (this.gridFacturas?.length == 0) {
      this.snackBarSrv.showSnackBar(
        'Rellene al menos una factura',
        'error'
      );
    } else {
      const payloadFacturas = this.obtenerPayloadFacturas();
      const payloadCompleto: SolicitudRetiradaCompletaPayloadDTO = {
        solicitudRetiradaPayload: this.obtenerPayloadSolicitud(),
        payloadFacExportacionList: payloadFacturas.payloadFacturas,
      }

      let request: any;
      if (this.solicitudId) {
        request = this.solicitudRetiradaCtrlSrv.editById2(this.solicitudId, payloadCompleto, payloadFacturas.facturasBlob)
      } else {
        request = this.solicitudRetiradaCtrlSrv.create2(payloadCompleto, payloadFacturas.facturasBlob);
      }
      this.spinnerSrv.loadingShow();
      request
        .subscribe({
          next: (value: any) => {
            this.spinnerSrv.loadingHide();
            this.dialogRef.close(true);
            this.snackBarSrv.showSnackBar(
              'Se ha guardado la solicitud de retirada correctamente.',
              'success'
            );
          },
          error: (error: any) => {
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al guardar la solicitud de retirada ',
              'error'
            );
            this.spinnerSrv.loadingHide();
          },
        });

    }
  }
  guardarEnviar(): void {
    if (!this.esFormularioValido()) {
      this.snackBarSrv.showSnackBar(
        'Complete los campos obligatorios.',
        'error'
      );
    } else if (!this.cantidadRetirarValido()) {
      this.snackBarSrv.showSnackBar(
        'Revise la cantidad mínima de solicitud de retirada: para envases de tipo IBC = 5 y para tipo Pallets = 10',
        'error'
      );
    } else if (this.gridFacturas?.length == 0) {
      this.snackBarSrv.showSnackBar(
        'Rellene al menos una factura',
        'error'
      );
    } else {
      const payloadFacturas = this.obtenerPayloadFacturas();
      const payloadCompleto: SolicitudRetiradaCompletaPayloadDTO = {
        solicitudRetiradaPayload: this.obtenerPayloadSolicitud(),
        payloadFacExportacionList: payloadFacturas.payloadFacturas,
      }
      let request: any;
      if (this.solicitudId) {
        request = this.solicitudRetiradaCtrlSrv.editByIdAndSend1(this.solicitudId, payloadCompleto, payloadFacturas.facturasBlob)
      } else {
        request = this.solicitudRetiradaCtrlSrv.saveAndSend1(payloadCompleto, payloadFacturas.facturasBlob);
      }
      this.spinnerSrv.loadingShow();
      request
        .subscribe({
          next: (value: any) => {
            this.spinnerSrv.loadingHide();
            this.dialogRef.close(true);
            this.snackBarSrv.showSnackBar(
              'Se ha guardado y enviado la solicitud de retirada correctamente.',
              'success'
            );
          },
          error: (error: any) => {
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al guardar y enviado la solicitud de retirada ',
              'error'
            );
            this.spinnerSrv.loadingHide();
          },
        });

    }
  }
  esFormularioValido(): boolean {
    let valid = false;
    this.datosSolicitudForm.markAllAsTouched();
    this.facturaForm.get('importeDepositoUnidad')?.markAllAsTouched();
    if (this.datosSolicitudForm.valid && this.facturaForm.get('importeDepositoUnidad')?.valid) {
      valid = true;
    }
    return valid;
  }
  cantidadRetirarValido(): boolean {
    let valid = false;
    //id=29 -> pallet /  id=28 ->IBC
    if ((this.datosSolicitudForm.get('tipoEnvase')?.value === 29 && this.parseCurrencyPipe.transform(this.datosSolicitudForm.get('unidadesRetirar')?.value) >= 10) ||
      (this.datosSolicitudForm.get('tipoEnvase')?.value === 28 && this.parseCurrencyPipe.transform(this.datosSolicitudForm.get('unidadesRetirar')?.value) >= 5)) {
      valid = true;
    }
    return valid;
  }
  udValidos(): boolean {
    let valid = false;
    const udAptos = this.parseCurrencyPipe.transform(this.informeRetiradaForm.get('udAptos')?.value);
    const kgAptos = this.parseCurrencyPipe.transform(this.informeRetiradaForm.get('kgAptos')?.value);
    const udNoAptos = this.parseCurrencyPipe.transform(this.informeRetiradaForm.get('udNoAptos')?.value);
    const udRetirados = this.parseCurrencyPipe.transform(this.informeRetiradaForm.get('udRetirados')?.value);
    if (udRetirados === (udAptos + udNoAptos)) {
      valid = true;
    }
    return valid;
  }
  kgValidos(): boolean {
    let valid = false;
    if (this.informeRetiradaForm.get('kgAptos')?.value && this.informeRetiradaForm.get('kgNoAptos')?.value && this.informeRetiradaForm.get('kgRetirados')?.value) {
      const kgAptos = this.parseCurrencyPipe.transform(this.informeRetiradaForm.get('kgAptos')?.value);
      const kgNoAptos = this.parseCurrencyPipe.transform(this.informeRetiradaForm.get('kgNoAptos')?.value);
      const kgRetirados = this.parseCurrencyPipe.transform(this.informeRetiradaForm.get('kgRetirados')?.value);
      if (kgRetirados === (kgAptos + kgNoAptos)) {
        valid = true;
      }
    } else {
      valid = true;
    }
    return valid;
  }
  guardarInforme(): void {
    this.informeRetiradaForm.markAllAsTouched();
    if (!this.informeRetiradaForm.valid) {
      this.snackBarSrv.showSnackBar(
        'Complete los campos obligatorios.',
        'error'
      );
    } else if (!this.udValidos()) {
      this.snackBarSrv.showSnackBar(
        'La suma de envases aptos y no aptos debe coincidir con el total de envases retirados',
        'error'
      );
    } else if (!this.kgValidos()) {
      this.snackBarSrv.showSnackBar(
        'La suma de los Kg de envases aptos y no aptos debe coincidir con el total de kg de envases retirados',
        'error'
      );
    } else if (this.gridDocumentos?.length == 0) {
      this.snackBarSrv.showSnackBar(
        'Rellene al menos una documento',
        'error'
      );
    } else {
      const payloadDocumentos = this.obtenerPayloadDocumentos();
      const payloadCompleto: SolicitudInformeRetiradaCompletoPayloadDTO = {
        informeRetiradaPayloadDTO: this.obtenerPayloadInforme(),
        payloadInformeRetiradaDocumList: payloadDocumentos.payloadDocumentos,
      }
      let request: any;
      if (this.informeRetirada?.id) {
        request = this.solicitudInformacionRetiradaCtrlSrv.edityById(this.informeRetirada?.id, payloadCompleto, payloadDocumentos.documentosBlob);
      } else {
        request = this.solicitudInformacionRetiradaCtrlSrv.create3(payloadCompleto, payloadDocumentos.documentosBlob);
      }

      this.spinnerSrv.loadingShow();
      request
        .subscribe({
          next: (value: any) => {
            this.spinnerSrv.loadingHide();
            this.dialogRef.close(true);
            this.snackBarSrv.showSnackBar(
              'Se ha guardado el informe de retirada correctamente.',
              'success'
            );
          },
          error: (error: any) => {
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al guardar el informe de retirada ',
              'error'
            );
            this.spinnerSrv.loadingHide();
          },
        });
    }
  }
  guardarEnviarInforme(): void {
    this.informeRetiradaForm.markAllAsTouched();
    if (!this.informeRetiradaForm.valid) {
      this.snackBarSrv.showSnackBar(
        'Complete los campos obligatorios.',
        'error'
      );
    } else if (!this.udValidos()) {
      this.snackBarSrv.showSnackBar(
        'La suma de envases aptos y no aptos debe coincidir con el total de envases retirados',
        'error'
      );
    } else if (!this.kgValidos()) {
      this.snackBarSrv.showSnackBar(
        'La suma de los Kg de envases aptos y no aptos debe coincidir con el total de kg de envases retirados',
        'error'
      );
    } else if (this.gridDocumentos?.length == 0) {
      this.snackBarSrv.showSnackBar(
        'Rellene al menos una documento',
        'error'
      );
    } else {
      const payloadDocumentos = this.obtenerPayloadDocumentos();
      const payloadCompleto: SolicitudInformeRetiradaCompletoPayloadDTO = {
        informeRetiradaPayloadDTO: this.obtenerPayloadInforme(),
        payloadInformeRetiradaDocumList: payloadDocumentos.payloadDocumentos,
      }
      let request: any;
      if (this.informeRetirada?.id) {
        request = this.solicitudInformacionRetiradaCtrlSrv.edityByIdAndSend(this.informeRetirada?.id, payloadCompleto, payloadDocumentos.documentosBlob);
      } else {
        request = this.solicitudInformacionRetiradaCtrlSrv.saveAndSend2(payloadCompleto, payloadDocumentos.documentosBlob);
      }

      this.spinnerSrv.loadingShow();
      request
        .subscribe({
          next: (value: any) => {
            this.spinnerSrv.loadingHide();
            this.dialogRef.close(true);
            this.snackBarSrv.showSnackBar(
              'Se ha guardado y enviado el informe de retirada correctamente.',
              'success'
            );
          },
          error: (error: any) => {
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al guardar y enviar el informe de retirada ',
              'error'
            );
            this.spinnerSrv.loadingHide();
          },
        });
    }

  }

  obtenerPayloadSolicitud(): SolicitudRetiradaPayloadDTO {
    let payload: SolicitudRetiradaPayloadDTO = {
      tipoEnvase: { id: this.datosSolicitudForm.get('tipoEnvase')?.value },
      subTipoEnvase: { id: this.datosSolicitudForm.get('subtipoEnvase')?.value },
      cantidadRetirarUd: this.parseCurrencyPipe.transform(this.datosSolicitudForm.get('unidadesRetirar')?.value),
      cantidadRetirarKg: this.parseCurrencyPipe.transform(this.datosSolicitudForm.get('pesoRetirar')?.value),
      aceptacionCondiciones: this.datosSolicitudForm.get('aceptacionRetirada')?.value,
      gestorAcondicionador: { id: this.datosSolicitudForm.get('acondicionadorId')?.value },
      loteEnvase: this.datosSolicitudForm.get('loteEnvase')?.value,
      observaciones: this.datosSolicitudForm.get('observacionesRetirada')?.value,
      importeDeposito: this.parseCurrencyPipe.transform(this.facturaForm.get('importeDepositoUnidad')?.value)
    }
    if (this.datosSolicitudForm.get('usuarioSddr')?.value) {
      const usuario = this.tiposUsusariosSddr.find((x: UsuarioSDDRCaDTO) => (x.id! + x.tipoUsuario!) === this.datosSolicitudForm.get('usuarioSddr')?.value);
      if (usuario?.tipoUsuario === 'POSEEDOR') {
        payload.poseedor = { id: usuario?.id }
      } else if (usuario?.tipoUsuario === 'ADHERIDO') {
        payload.adherido = { id: usuario?.id }
      }
    }
    return payload;
  }
  obtenerPayloadFacturas(): any {
    let payloadFacturas: SolicitudFacturaExportacionPayloadDTO[] = [];
    let facturasBlob: Array<Blob> = [];
    this.gridFacturas.forEach(factura => {
      payloadFacturas.push({
        id: factura?.id,
        numFacturaDeposito: factura?.numFactura,
        tipoSolicitud: 'RETIRADA',
        solicitud: this.solicitudId
      });
      if (factura?.id === -1) {
        facturasBlob.push(factura?.documento);
      }

    });
    return { payloadFacturas: payloadFacturas, facturasBlob: facturasBlob };
  }

  obtenerPayloadDocumentos(): any {
    let payloadDocumentos: SolicitudInformeRetiradaDocumPayloadDTO[] = [];
    let documentosBlob: Array<Blob> = [];

    this.gridDocumentos.forEach(documento => {
      payloadDocumentos.push({
        id: documento?.id,
        solicitudInformeRetirada: { id: this.informeRetirada?.id },
        nombre: documento?.nombre
      });
      if (documento?.id === -1) {
        documentosBlob.push(documento?.documento)
      }
    });
    return { payloadDocumentos: payloadDocumentos, documentosBlob: documentosBlob };
  }
  obtenerPayloadInforme(): SolicitudInformeRetiradaPayloadDTO {
    let payload: SolicitudInformeRetiradaPayloadDTO = {
      solicitudRetirada: { id: this.solicitudId },
      usuarioSddrca: this.informeRetiradaForm.get('verificacion')?.value,
      envasesRetiradosUd: this.parseCurrencyPipe.transform(this.informeRetiradaForm.get('udRetirados')?.value),
      envasesRetiradosKg: this.parseCurrencyPipe.transform(this.informeRetiradaForm.get('kgRetirados')?.value),
      envasesAptosUd: this.parseCurrencyPipe.transform(this.informeRetiradaForm.get('udAptos')?.value),
      envasesAptosKg: this.parseCurrencyPipe.transform(this.informeRetiradaForm.get('kgAptos')?.value),
      envasesNoAptosUd: this.parseCurrencyPipe.transform(this.informeRetiradaForm.get('udNoAptos')?.value),
      envasesNoAptosKg: this.parseCurrencyPipe.transform(this.informeRetiradaForm.get('kgNoAptos')?.value),
      envasesSalidaSddr: this.informeRetiradaForm.get('envasesSalenSddr')?.value,
      envasesDentroSddr: this.informeRetiradaForm.get('envasesMantienenSddr')?.value,
    }

    return payload;
  }
  descargarAutofactura(autofactura: FacturaDTO) {
    if (autofactura?.documentoAutofactura?.id) {
      let nombre = autofactura?.documentoAutofactura?.nombre ? autofactura?.documentoAutofactura?.nombre : 'autofactura.pdf'
      this.spinnerSrv.loadingShow();
      this.documCtrlSrv.downloadDoc(autofactura?.documentoAutofactura?.id)
        .subscribe({
          next: (response) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Se ha descargado la autofactura correctamente',
              'success'
            );
            this.downloadSrv.downloadBlob(response, nombre);
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al descargar la autofactura',
              'error'
            );
          },
        });
    }
  }

  canAccessGuardar(): boolean {
    return this.authService.can('sddr-ca-solicitudes-retirada-detalle', 'access-b-datos-guardar');
  }
  canAccessGuardarEnviar(): boolean {
    return this.authService.can('sddr-ca-solicitudes-retirada-detalle', 'access-b-datos-guardar-enviar');
  }
  canAccessGuardarInforme(): boolean {
    return this.authService.can('sddr-ca-solicitudes-retirada-detalle', 'access-b-informe-guardar');
  }
  canAccessGuardarEnviarInforme(): boolean {
    return this.authService.can('sddr-ca-solicitudes-retirada-detalle', 'access-b-informe-guardar-enviar');
  }
  canAccessEditDatos(): boolean {
    return this.authService.can('sddr-ca-solicitudes-retirada-detalle', 'edit-datos');
  }
  canAccessEditInforme(): boolean {
    return this.authService.can('sddr-ca-solicitudes-retirada-detalle', 'edit-informe');
  }
  canAccessAllDocumentos(list: any) {
    const resultDelete = this.canAccessEditInforme();
    console.log('can access editar informe', resultDelete);
    console.log('estado solicitud', this.solicitud?.estado?.id);
    console.log(this.solicitudId)
    const listPermission = list.map((objeto: any) => {
      return {
        ...objeto,
        canAccessDelete: resultDelete && (this.solicitud?.estado?.id === 2 || !this.solicitudId),
        canAccessDownload: objeto?.id !== -1
      };
    });
    return listPermission;
  }
  canAccessAllFacturas(list: any) {
    const resultDelete = this.canAccessEditDatos();
    const listPermission = list.map((objeto: any) => {
      return {
        ...objeto,
        canAccessDelete: resultDelete && (this.solicitud?.estado?.id === 1 || !this.solicitudId),
        canAccessDownload: objeto?.id !== -1
      };
    });
    return listPermission;
  }
  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.acondicionadorForm.get(formName)?.setValue(null);
  }
  showClearButton(formName: string): boolean {
    return this.acondicionadorForm.get(formName)?.value !== null;
  }
  onTabChanged(event: any): void {
    this.selectedIndex = event?.index;
  }
}
