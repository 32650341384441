/**
 * SDRR-Abierto API
 * SDRR-Abierto API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { GestionResiduosFilterDTO } from './gestionResiduosFilterDTO';
import { IdEntityFilterDTO } from './idEntityFilterDTO';


export interface AportacionDISDDRCAFilterDTO { 
    fechaAlta?: Array<string>;
    creadoPor?: Array<string>;
    fechaModificacion?: Array<string>;
    modificadoPor?: Array<string>;
    gestorResiduo?: GestionResiduosFilterDTO;
    fechaRecogida?: Array<string>;
    fechaAceptacion?: Array<string>;
    documentoIdentificacion?: string;
    estado?: IdEntityFilterDTO;
    solicitudRetirada?: IdEntityFilterDTO;
    origen?: AportacionDISDDRCAFilterDTO.OrigenEnum;
    razonSocialGestor?: string;
}
export namespace AportacionDISDDRCAFilterDTO {
    export type OrigenEnum = 'ADHERIDO' | 'POSEEDOR' | 'SDDRCA' | 'GESTORESACUERDO';
    export const OrigenEnum = {
        Adherido: 'ADHERIDO' as OrigenEnum,
        Poseedor: 'POSEEDOR' as OrigenEnum,
        Sddrca: 'SDDRCA' as OrigenEnum,
        Gestoresacuerdo: 'GESTORESACUERDO' as OrigenEnum
    };
}


