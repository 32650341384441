<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="bg-second">
  <div class="container mt-cab-fixed">
    <div class="pre-content">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">Adheridos</li>
          <li class="breadcrumb-item"><a href="#/attached-app/packaging-declaration-search">Búsqueda declaración
              envases</a></li>
          <li class="breadcrumb-item active">Detalle declaración envases</li>
        </ol>
      </nav>
      <div class="d-flex content-title">
        <div class="d-flex">
          <h2 class="title-pag">Detalle declaración envases</h2>
          <span class="info-version">{{infoAdheridoVersion}}</span>
        </div>
        <div *ngIf="!declaracionAdherido?.reqActEconomica && hayEnvases"
          style="padding-right: 10px; display: flex; flex-direction: row;">
          <div>
            <label style="font-size: larger;">
              <mat-icon class="show-icon">warning_amber</mat-icon>
            </label>

          </div>
          <div style="margin-left:5px">
            <label class="info-warning"> Requiere actualización económica</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- FIN MIGAS DE PAN Y TÍTULO -->
<div class="container container-info">
  <div class="info" [ngStyle]="{'padding-bottom': (centrosDeclaracion?.length == 0) ? '30px' : '50px'}">
    <div>
      <label class="info-label">Id. adherido: </label>
      <span class="info-des"> {{declaracionAdherido?.adherido?.id}}</span>
    </div>
    <div>
      <label class="info-label">Cod. declaración: </label>
      <span class="info-des"> {{declaracionAdherido?.codigoDeclaracion}}</span>
    </div>
    <div>
      <label class="info-label">Razón social adherido: </label>
      <span class="info-des"> {{declaracionAdherido?.adherido?.razonSocial}}</span>
    </div>

    <div>
      <label class="info-label">Documento identidad: </label>
      <span class="info-des"> {{declaracionAdherido?.adherido?.codigoDocumento}}</span>
    </div>
    <div>
      <label class="info-label">Estado: </label>
      <span class="info-des"> {{declaracionAdherido?.estadoDeclaracion?.descripcion}}</span>
    </div>
    <div>
      <label class="info-label">Estimación: </label>
      <span class="info-des"> {{declaracionAdherido?.estimacion ? 'Sí': 'No'}}</span>
    </div>

  </div>
  <span class="info-label" *ngIf="centrosDeclaracion?.length==0 && (canAccessNewCenter() && canAccessEditAllGlobal())"
    style="display: flex;justify-content: end;
    padding-right: 73px;">Añada sus centros de envasado y categoría del envase para comenzar con la declaración</span>
  <div class="container-tabs" style="margin-top: -63px;">
    <mat-tab-group class="tabs" [selectedIndex]="selectedIndex">
      <mat-tab *ngIf="canAccessGeneralData()">
        <ng-template class="datos-generales" mat-tab-label>
          <div matTooltip="DATOS GENERALES">
            DATOS GENERALES
          </div>
        </ng-template>
        <div class="content-tabs">
          <lib-package-general-data [declaracionAdherido]="declaracionAdherido" [centrosDeclaracion]="centrosDeclaracion" [centrosEnvases] = "centrosEnvases"
            (actualizarDeclaracion)="actualizarCambio($event)">
          </lib-package-general-data>
        </div>
      </mat-tab>
      <ng-container *ngIf="canAccessLinkCenter()">
        <mat-tab *ngFor="let centro of centrosDeclaracion">
          <ng-template mat-tab-label>
            <div class="tab-ellipsis"
              [matTooltip]="centro?.centroEnvasado?.denominacion +' - '+ centro?.categoriaEnvase?.descripcion">
              {{centro?.centroEnvasado?.denominacion +' - '+ centro?.categoriaEnvase?.descripcion}}
            </div>
          </ng-template>
          <div class="content-tabs">
            <lib-package-center [centro]="centro" [declaracionAdherido]="declaracionAdherido"
              [comunidadesAutonomas]="comunidadesAutonomas" (actualizarDeclaracion)="actualizarCambio($event)" (actualizarEnvases)="actualizarEnvasesCentros($event)">
            </lib-package-center>
          </div>
        </mat-tab>
      </ng-container>
    </mat-tab-group>

    <div style="float:right" *ngIf="canAccessNewCenter() && canAccessEditAllGlobal()">
      <button type="button" (click)="abrirModalCentro()" class="btn btn-primary" matTooltip="Añadir centro de envasado y categoría del envase"
        style="margin-left: -66px;margin-top: 35px; padding: 8px;">
        <mat-icon aria-hidden="false" aria-label="Añadir" fontIcon="add"></mat-icon>
      </button>
    </div>
  </div>
</div>
