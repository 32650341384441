import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { Adherido, Contacto } from '../../../models/adherido';
import { ContactDialogComponent } from '../contact-dialog/contact-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import swal from 'sweetalert2';
import { AttachedService } from '../../../services/attached.service';
import { SpinnerService } from '../../../services/spinner.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'lib-detail-contact-data',
  templateUrl: './detail-contact-data.component.html',
  styleUrls: ['./detail-contact-data.component.scss'],
})
export class DetailContactDataComponent implements OnChanges {
  @Input() adheridoInfo!: Adherido;
  @Output() public refrescarAdheridoEvent = new EventEmitter<any>();
  contactos: Contacto[] = [];

  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  gridArray: Contacto[] = [];

  headArray: any = [
    {
      Head: 'Requerida',
      FieldName: 'requerido',
      check: true,
      disabled: true,
    },
    {
      Head: 'Tipo contacto',
      FieldName: 'tipo_contacto',
      Attribute: 'descripcionCorta',
      Tooltip: true,
    },
    {
      Head: 'Tipo entidad',
      renderValue: (value: any) => value.persona ? 'Persona' : 'Empresa'
    },
    {
      Head: 'Nombre/Razón social',
      FieldName: 'fullName',
      Tooltip: true,
    },
    {
      Head: 'Email',
      FieldName: 'email',
      Tooltip: true,
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Detalle',
          show: { params: 'canAccessView', accion: 'view' },
        },
        // { 'nameButton': "edit", "icon": "edit_square", 'toolTip': 'Editar', 'show': { 'params': 'canAccessListEdit', 'accion': 'edit' } },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
      ],
      width: '8',
      permanent: true,
    },
  ];

  constructor(
    private dialog: MatDialog,
    private attachedSrv: AttachedService,
    private spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.adheridoInfo) {
      this.contactos = this.adheridoInfo?.contactos;
      this.gridArray = this.canAccessAll(this.contactos);
    }
  }

  canAccessAll(list: any) {
    const resultListShow = this.authService.can(
      'adheridos-contactos',
      'access'
    );
    const resultListEdit = this.authService.can(
      'adheridos-contactos',
      'update'
    );
    const resultDelete = this.authService.can('adheridos-contactos', 'delete');

    const listPermission = list.map((objeto: any) => {
      const estadoUpdatedContact =
        this.adheridoInfo.estado &&
        [2, 3, 4, 5, 6].includes(this.adheridoInfo.estado!.id);
      const estadoEliminar =
        this.adheridoInfo.estado &&
        [1, 2, 3, 4, 5, 6].includes(this.adheridoInfo.estado!.id) &&
        (!objeto.requerido || objeto.requerido === null);
      return {
        ...objeto,
        canAccessView: resultListShow,
        canAccessListEdit: resultListEdit && estadoUpdatedContact,
        canAccessDelete: resultDelete && estadoEliminar,
      };
    });

    return listPermission;
  }

  canAccessExportar(): boolean {
    return this.authService.can('adheridos-contactos', 'access-b-exportar');
  }

  canAccessCreateContact(): boolean {
    return this.authService.can('adheridos-contactos', 'create');
  }

  openModalContact(element?: any): void {
    const dialogRef = this.dialog.open(ContactDialogComponent, {
      width: '950px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: {
        contacto: element,
        adherido: this.adheridoInfo,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.refrescarAdheridoEvent.emit(true);
      }
    });
  }

  checked(item: any) {}

  action(item: any) {
    if (item.nameButton == 'view') {
      this.openModalContact(item);
    } else if (item.nameButton == 'edit') {
      this.openModalContact(item);
    } else if (item.nameButton == 'delete') {
      this.confirmacionEliminar(item);
    }
  }
  confirmacionEliminar(element?: Contacto): void {
    swal
      .fire({
        text: '¿Desea eliminar el contacto?',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.eliminarContacto(element);
        }
      });
  }
  eliminarContacto(element?: Contacto) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.attachedSrv.eliminarContacto(element.id).subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'El contacto se ha eliminado correctamente',
            'success'
          );
          this.refrescarAdheridoEvent.emit(true);
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al eliminar el contacto',
            'error'
          );
          console.log(err);
        },
      });
    }
  }

  renderPage(event: number) {
    this.pageNumber = event;
  }

  canViewRol(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }
}
