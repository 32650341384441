import {Component, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators,} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AttachedService} from '../../../services/attached.service';
import {EmpusaAuthenticationService} from '@empusa/empusa-core';
import {SnackbarService} from '../../../services/snackbar.service';
import {SpinnerService} from '../../../services/spinner.service';
import Swal from 'sweetalert2';
import {DownloadService} from '../../../services/download.service';
import {
  DeclaracionAdheridoDTO,
  DeclaracionCentroDTO, DeclaracionEnvaseAnteriorFilterDTO,
  DeclaracionEnvaseControllerService,
  DeclaracionEnvaseDTO,
  DeclaracionEnvasePayloadDTO, TipoEnvasesDTO,
} from 'api-rest';
import {DocumentService} from '../../../services/document.service';
import {ParseCurrencyPipe} from '../../../share/components/pipe/ParseCurrency.pipe';
import {FormatCurrencyPipe} from '../../../share/components/pipe/FormatCurrency.pipe';
import {concatMap, Observable, takeUntil, tap} from 'rxjs';

export interface MappedResponse<T> {
  totalElements: number;
  recordsFiltered: number;
  datos: T[];
}


@Component({
  selector: 'lib-package-dialog',
  templateUrl: './package-dialog.component.html',
  styleUrls: ['./package-dialog.component.scss'],
})
export class PackageDialogComponent implements OnInit {
  @ViewChild('fileInputAcuerdo') fileInputAcuerdo: any;
  @ViewChild('fileInputCertificado') fileInputCertificado: any;

  nombreArchivoAcuerdo: string = '';
  nombreArchivoCertificado: string = '';
  fileAcuerdo: File | undefined;
  fileCertificado: File | undefined;
  flagDisabledForm: boolean = false;
  titulo = '';

  updateList: boolean = false;
  tiposEnvasesEndpoint: string;
  composicionMaterialEndpoint =
    this.environment.urlBackCore + 'api/v1/core/composicionMaterial';

  tipologiaArray: any = [
    {id: 'PRIMARIO', descripcion: 'Primario o envase de venta'},
    {id: 'SECUNDARIO', descripcion: 'Envase secundario o colectivo'},
    {id: 'TERCIARIO', descripcion: 'Terciario o envase de transporte'},
  ];

  tipoElementoArray: any = [
    {id: 'TAPONES', descripcion: 'Tapón'},
    {id: 'ETIQUETAS', descripcion: 'Etiqueta'},
    {id: 'OTROS_ELEMENTOS', descripcion: 'Otro elemento'},
  ];

  formGroup!: FormGroup;
  materialFormGroup!: FormGroup;
  otherElementFormGroup!: FormGroup;
  actionFormGroup!: FormGroup;
  acuerdosClientesFormGroup!: FormGroup;
  certificadoSDDRAbiertoFormGroup!: FormGroup;
  declaracionEnvase!: DeclaracionEnvaseDTO;
  nuevoEnvase: boolean = false;
  campoOblig: boolean = false;
  declaracionCentro: DeclaracionCentroDTO;
  declaracionAdherido: any;

  filteredTipologiaArray: any[] = [];
  peligrosidadOptions: any[] = [];

  material: any[] = [];
  gridMaterial: any[] = [];
  gridMaterialOtros: any[] = [];
  otherElement: any[] = [];
  tapon: any[] = [];

  calculatingPesoTotal: boolean = false;
  calculatingPesoUni: boolean = false;
  flagSeguirAlta: boolean = false;

  headArrayMaterial: any[] = [
    {
      Head: 'Material de composición',
      FieldName: 'composicionMaterial',
      Attribute: 'descripcion',
      Tooltip: true,
      Maxtext: 60,
    },
    {
      Head: 'Peso unitario (Kg)',
      FieldName: 'pesoUnitario',
    },
    {
      Head: 'Peso Total (Kg)',
      FieldName: 'pesoTotal',
    },
    {
      Head: '% reciclado',
      FieldName: 'porcReciclado',
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: (row: any) => this.canAccessEditAllGlobal(),
        },
      ],
      width: '2',
      permanent: true,
    },
  ];

  headArrayOtherElement: any[] = [
    {
      Head: 'Tipo elemento',
      FieldName: 'tipoElemento',
      renderValue: (item: any) => {
        const elementoEncontrado = this.tipoElementoArray.find(
          (elemento: any) => elemento.id === item.tipoElemento
        );
        return elementoEncontrado ? elementoEncontrado.descripcion : '';
      },
    },
    {
      Head: 'Material otro elemento',
      FieldName: 'composicionMaterial',
      Attribute: 'descripcion',
      Tooltip: true,
      Maxtext: 60,
    },
    {
      Head: 'Peso unitario (Kg)',
      FieldName: 'pesoUnitario',
    },
    {
      Head: 'Peso total (Kg)',
      FieldName: 'pesoTotal',
    },
    {
      Head: '% reciclado',
      FieldName: 'porcReciclado',
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: (row: any) => this.canAccessEditAllGlobal(),
        },
      ],
      width: '2',
      permanent: true,
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<PackageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private attachedSrv: AttachedService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    @Inject('environment') private environment: any,
    private declaracionEnvaseControllerService: DeclaracionEnvaseControllerService,
    private snackBarSrv: SnackbarService,
    public spinnerSrv: SpinnerService,
    private spinnerService: SpinnerService,
    private downloadService: DownloadService,
    private documentService: DocumentService,
    private parseCurrencyPipe: ParseCurrencyPipe,
    private formatCurrencyPipe: FormatCurrencyPipe
  ) {
    this.declaracionEnvase = this.data.item;
    this.declaracionCentro = this.data.declaracionCentro;
    this.nuevoEnvase = this.data.nuevoEnvase;
    this.declaracionAdherido = this.data.declaracionAdherido;

    if (this.isCategoriaSDDRAbierto()) {
      this.tiposEnvasesEndpoint = this.environment.urlBackCore + 'api/v1/core/tipoEnvases?sddrca=true&sort=orden,asc';
    } else {
      this.tiposEnvasesEndpoint = this.environment.urlBackCore + 'api/v1/core/tipoEnvases?sort=orden,asc';
    }


    let validacionesVidaUtil: Validators[] = [];
    let validacionesCicloReutilizacion: Validators[] = [];

    if (!this.isCategoriaSoloUso()) {
      validacionesVidaUtil = [
        Validators.required,
        Validators.pattern('^[0-9]*$'),
        Validators.min(1),
        Validators.max(40),
      ];
      validacionesCicloReutilizacion = [
        Validators.pattern('^[0-9]*$'),
        Validators.min(1),
        Validators.max(100),
      ];
    }

    this.formGroup = this.formBuilder.group({
      peligrosidad: [{value: null, disabled: false}, [Validators.required]],
      denominacion: [{value: null, disabled: false}],
      tipoEnvase: [{value: null, disabled: false}, [Validators.required]],
      tipologia: [{value: null, disabled: false}, [Validators.required]],
      pesoUnitario: [{value: null, disabled: true}],
      nUnidades: [{value: '1', disabled: false}, [Validators.required]],
      unidadesCirculacion: [{value: '0', disabled: false}, [Validators.required],],
      unidadesNuevas: [{value: '1', disabled: false}, [Validators.required]],
      totalUds: [{value: '1', disabled: true}],
      totalEnvasesAnterior: [{value: '0', disabled: false}],
      unidadesResiduos: [{value: 0, disabled: false}, [Validators.required]],
      unidadesNoRetornadas: [{value: 0, disabled: false}],
      unidadesExportadas: [{value: 0, disabled: false}],
      unidadesEnvaseResiduo: [{value: 0, disabled: false}],
      udsCirculacionInfo: [{value: 0, disabled: true}],
      pesoTotal: [{value: null, disabled: true}],
      vidaUtil: [{value: null, disabled: false}, validacionesVidaUtil],
      cicloReutilizacion: [{value: null, disabled: false}, validacionesCicloReutilizacion],
      contribucionAnnoCurso: [{value: null, disabled: true}],
      despositoAnnoCurso: [{value: null, disabled: true}],
      contribucionAnnoAnt: [{value: null, disabled: true}],
      depositoAnnoAnt: [{value: null, disabled: true}],
      tarifaRapAnnoCurso: [{value: null, disabled: true}],
      tarifaRapAnnoAnt: [{value: null, disabled: true}],
      tarifaDepositoAnnoCurso: [{value: null, disabled: true}],
      tarifaDepositoAnnoAnt: [{value: null, disabled: true}],
    });

    this.materialFormGroup = this.formBuilder.group({
      composicionMaterial: [null, Validators.required],
      pesoUnitario: [null, Validators.required],
      pesoTotal: [null, Validators.required],
      porcReciclado: [0, Validators.required],
    });

    this.otherElementFormGroup = this.formBuilder.group({
      tipoElemento: [null],
      composicionMaterialOther: [null, Validators.required],
      pesoUnitario: [null, Validators.required],
      pesoTotal: [null, Validators.required],
      porcReciclado: [0, Validators.required],
    });

    this.actionFormGroup = this.formBuilder.group({
      seguirAlta: [{value: null, disabled: false}],
    });

    this.acuerdosClientesFormGroup = this.formBuilder.group({
      tipoAcuerdo: [{value: null, disabled: false}, [Validators.required]],
      importeDeposito: [
        {value: null, disabled: false},
        [Validators.required],
      ],
      fabricanteProductor: [
        {value: null, disabled: false},
        [Validators.required],
      ],
      prestadorServicios: [
        {value: null, disabled: false},
        [Validators.required],
      ],
      simboloSddrcerrado: [
        {value: null, disabled: false},
        [Validators.required],
      ],
      envasesSddrcerrado: [
        {value: null, disabled: false},
        [Validators.required],
      ],
    });

    this.certificadoSDDRAbiertoFormGroup = this.formBuilder.group({});

    this.acuerdosClientesFormGroup
      .get('tipoAcuerdo')
      ?.valueChanges.subscribe((value) => {
      if (value === true) {
        this.acuerdosClientesFormGroup
          .get('importeDeposito')
          ?.setValidators(Validators.required);
        this.fileAcuerdo = undefined;
        this.nombreArchivoAcuerdo = '';
      } else {
        this.acuerdosClientesFormGroup.get('importeDeposito')?.clearValidators();
        this.acuerdosClientesFormGroup.get('importeDeposito')?.setValue(null);
      }
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  public filterList(data: any) {
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'tipoEnvase': {
        return this.formGroup.get('tipoEnvase') as FormControl;
      }
      case 'composicionMaterial': {
        return this.materialFormGroup.get('composicionMaterial') as FormControl;
      }
      case 'composicionMaterialOther': {
        return this.otherElementFormGroup.get('composicionMaterialOther') as FormControl;
      }
      default: {
        return this.formGroup.get('') as FormControl;
      }
    }
  }

  ngOnInit(): void {
    this.isCategoriaSDDRCerrado()
      ? this.formGroup.get('nUnidades')?.disable()
      : this.formGroup.get('nUnidades')?.enable();

    if (!this.canAccessEditAllGlobal()) {
      this.formGroup.disable();
      this.materialFormGroup.disable();
      this.otherElementFormGroup.disable();
      this.acuerdosClientesFormGroup.disable();
    }


    this.valueChangeUnidades(this.materialFormGroup);
    this.valueChangeUnidades(this.otherElementFormGroup);
    this.valueChangePesoUni(this.materialFormGroup);
    this.valueChangePesoTotal(this.materialFormGroup);
    this.valueChangePesoUni(this.otherElementFormGroup);
    this.valueChangePesoTotal(this.otherElementFormGroup);

    this.valueChangeUnidadesCirculacion(this.formGroup);
    this.valueChangeUnidadesNuevas(this.formGroup);

    this.valueChangeTipoEnvase();
    this.valueChangeTipologia();
    this.valueChangePeligrosidad();

    this.valueChangeInfoSDDRCerrado();

    if (this.nuevoEnvase) {
      this.titulo = 'Alta de envases';
    } else {
      this.titulo = 'Edición de envases';
      this.cargarDatosDeclaracionEnvase();
    }

    if ([3, 4, 5, 6].includes(this.declaracionCentro?.categoriaEnvase?.id!)) {
      this.formGroup
        .get('cicloReutilizacion')
        ?.setValidators([Validators.required]);
      this.formGroup.get('cicloReutilizacion')?.updateValueAndValidity();
      this.campoOblig = true;
    }
  }

  cargarDatosDeclaracionEnvase() {
    this.formGroup.get('denominacion')?.setValue(this.declaracionEnvase.denominacion);
    this.formGroup.get('tipoEnvase')?.setValue(this.declaracionEnvase.tipoEnvase?.descripcion);

    if (this.declaracionEnvase.tipoEnvase) {
      this.initTipologias();
    }
    setTimeout(() => {
      this.formGroup.get('tipologia')?.setValue(this.declaracionEnvase.tipologia);
      this.formGroup.get('peligrosidad')?.setValue(this.declaracionEnvase.peligrosidad);
    }, 150);

    this.formGroup.get('pesoUnitario')?.setValue(this.declaracionEnvase.pesoUnitario);
    this.formGroup.get('nUnidades')?.setValue(
      this.formatCurrencyPipe.transform(this.declaracionEnvase.unidadesTotales));
    this.formGroup.get('unidadesCirculacion')?.setValue(
      this.formatCurrencyPipe.transform(this.declaracionEnvase.unidadesCirculacion));
    this.formGroup.get('unidadesNuevas')?.setValue(
      this.formatCurrencyPipe.transform(this.declaracionEnvase.unidadesNuevas));
    this.formGroup.get('unidadesResiduos')?.setValue(
      this.formatCurrencyPipe.transform(this.declaracionEnvase.unidadesResiduos));
    this.formGroup.get('unidadesNoRetornadas')?.setValue(
      this.formatCurrencyPipe.transform(this.declaracionEnvase.unidadesNoRetornadas));
    this.formGroup.get('unidadesExportadas')?.setValue(
      this.formatCurrencyPipe.transform(this.declaracionEnvase.unidadesExportadas));
    this.formGroup.get('unidadesEnvaseResiduo')?.setValue(
      this.formatCurrencyPipe.transform(this.declaracionEnvase.unidadesEnvaseResiduo));
    this.formGroup.get('pesoTotal')?.setValue(this.declaracionEnvase.pesoTotal);
    this.formGroup.get('vidaUtil')?.setValue(this.declaracionEnvase.vidaUtil);
    this.formGroup.get('cicloReutilizacion')?.setValue(this.declaracionEnvase.ciclosReutilizacion);
    this.formGroup.get('contribucionAnnoCurso')?.setValue(
      this.formatCurrencyPipe.transform(this.declaracionEnvase.contriRapAnioCurso));
    this.formGroup.get('despositoAnnoCurso')?.setValue(
      this.formatCurrencyPipe.transform(this.declaracionEnvase.depoRapAnioCurso));
    this.formGroup.get('contribucionAnnoAnt')?.setValue(this.formatCurrencyPipe.transform(
      this.declaracionEnvase.contriRapAnioAnterior));
    this.formGroup.get('depositoAnnoAnt')?.setValue(
      this.formatCurrencyPipe.transform(this.declaracionEnvase.depoRapAnioAnterior));

    this.formGroup.get('totalEnvasesAnterior')?.setValue(
      this.formatCurrencyPipe.transform(this.declaracionEnvase.totalEnvasesAnterior));
    this.formGroup.get('tarifaRapAnnoCurso')?.setValue(
      this.formatCurrencyPipe.transform(this.declaracionEnvase.tarifaRapAnioCurso));
    this.formGroup.get('tarifaRapAnnoAnt')?.setValue(
      this.formatCurrencyPipe.transform(this.declaracionEnvase.tarifaRapAnioAnterior));
    this.formGroup.get('tarifaDepositoAnnoCurso')?.setValue(
      this.formatCurrencyPipe.transform(this.declaracionEnvase.tarifaDepoAnioCurso));
    this.formGroup.get('tarifaDepositoAnnoAnt')?.setValue(
      this.formatCurrencyPipe.transform(this.declaracionEnvase.tarifaDepoAnioAnterior));

    this.gridMaterial = this.declaracionEnvase.envaseMaterial!.filter(
      (envaseMaterial) => envaseMaterial.tipoElemento === null
    );
    this.gridMaterial = this.convertShowGrid(this.gridMaterial);
    this.gridMaterialOtros = this.declaracionEnvase.envaseMaterial!.filter(
      (envaseMaterial) => envaseMaterial.tipoElemento !== null
    );
    this.gridMaterialOtros = this.convertShowGrid(this.gridMaterialOtros);
    this.acuerdosClientesFormGroup.get('tipoAcuerdo')?.setValue(this.declaracionEnvase.acuerdoDeposito);
    this.acuerdosClientesFormGroup.get('importeDeposito')?.setValue(
      this.formatCurrencyPipe.transform(this.declaracionEnvase.importeDeposito));
    this.acuerdosClientesFormGroup.get('fabricanteProductor')?.setValue(this.declaracionEnvase.fabricanteProductor);
    this.acuerdosClientesFormGroup.get('prestadorServicios')?.setValue(this.declaracionEnvase.prestadorServicios);
    this.acuerdosClientesFormGroup.get('simboloSddrcerrado')?.setValue(this.declaracionEnvase.simboloSddrcerrado);
    this.acuerdosClientesFormGroup.get('envasesSddrcerrado')?.setValue(this.declaracionEnvase.envasesSddrcerrado);

    this.nombreArchivoAcuerdo =
      this.declaracionEnvase.docModeloCesion !== null
        ? this.declaracionEnvase.docModeloCesion?.nombre!
        : '';
    this.nombreArchivoCertificado =
      this.declaracionEnvase.docCertificadoReutilizables !== null
        ? this.declaracionEnvase.docCertificadoReutilizables?.nombre!
        : '';
  }

  valueChangePesoTotal(dataFormGroup: FormGroup) {
    dataFormGroup.get('pesoUnitario')?.valueChanges.subscribe((val) => {
      if (typeof val === 'string' && val.includes(',')) {
        const [integerPart, decimalPart] = val.split(',');
        if (decimalPart.length > 5) {
          return;
        }
      }

      if (!isNaN(parseFloat(val)) && !this.calculatingPesoTotal) {
        this.calculatingPesoTotal = true;

        const valAsNumber = this.parseCurrencyPipe.transform(val);

        let nUnidades = this.parseCurrencyPipe.transform(
          this.formGroup.get('nUnidades')?.value
        );
        if (!isNaN(nUnidades)) {
          if (!nUnidades) {
            this.formGroup
              .get('nUnidades')
              ?.setValue('1', {emitEvent: false});
            nUnidades = 1;
          }
          const calcPesoTotal = nUnidades * valAsNumber;

          const formattedValue =
            this.formatCurrencyPipe.transform(calcPesoTotal);

          dataFormGroup
            .get('pesoTotal')
            ?.setValue(formattedValue, {emitEvent: false});
        }

        this.calculatingPesoTotal = false;
      }
    });
  }

  valueChangePesoUni(dataFormGroup: FormGroup) {
    dataFormGroup.get('pesoTotal')?.valueChanges.subscribe((val) => {
      if (typeof val === 'string' && val.includes(',')) {
        const [integerPart, decimalPart] = val.split(',');
        if (decimalPart.length > 5) {
          return;
        }
      }
      if (!isNaN(parseFloat(val)) && !this.calculatingPesoUni) {
        this.calculatingPesoUni = true;

        const valAsNumber = this.parseCurrencyPipe.transform(val);

        let nUnidades = this.parseCurrencyPipe.transform(
          this.formGroup.get('nUnidades')?.value
        );

        if (!isNaN(nUnidades)) {
          if (!nUnidades) {
            this.formGroup
              .get('nUnidades')
              ?.setValue('1', {emitEvent: false});
            nUnidades = 1;
          }
          const calcPesoTotal = valAsNumber / nUnidades;

          const formattedValue =
            this.formatCurrencyPipe.transform(calcPesoTotal);

          dataFormGroup
            .get('pesoUnitario')
            ?.setValue(formattedValue, {emitEvent: false});
        }

        this.calculatingPesoUni = false;
      }
    });
  }

  valueChangeUnidadesCirculacion(dataFormGroup: FormGroup) {
    this.formGroup.get('unidadesCirculacion')?.valueChanges.subscribe((val) => {
      if (this.isCategoriaSDDRCerrado()) {
        const unidadesNuevasVal = dataFormGroup.get('unidadesNuevas')?.value;
        this.updateTotalUnidades(dataFormGroup, val, unidadesNuevasVal);
      }
    });
  }

  valueChangeUnidadesNuevas(dataFormGroup: FormGroup) {
    this.formGroup.get('unidadesNuevas')?.valueChanges.subscribe((val) => {
      if (this.isCategoriaSDDRCerrado()) {
        const unidadesCirculacionVal = dataFormGroup.get('unidadesCirculacion')?.value;
        this.updateTotalUnidades(dataFormGroup, unidadesCirculacionVal, val);
      }
    });
  }

  private updateTotalUnidades(dataFormGroup: FormGroup, unidadesCirculacionVal: any, unidadesNuevasVal: any) {
    // Si los valores son null o están vacíos, asignamos como 0
    const unidadesCirculacion = unidadesCirculacionVal ? this.parseCurrencyPipe.transform(unidadesCirculacionVal) : 0;
    const unidadesNuevas = unidadesNuevasVal ? this.parseCurrencyPipe.transform(unidadesNuevasVal) : 0;

    if (unidadesCirculacion !== null && unidadesNuevas !== null) {
      dataFormGroup.get('nUnidades')?.setValue(this.formatCurrencyPipe.transform(unidadesNuevas));
      const totalUds: number = unidadesNuevas + unidadesCirculacion;
      dataFormGroup.get('totalUds')?.setValue(this.formatCurrencyPipe.transform(totalUds));
    } else {
      dataFormGroup.get('totalUds')?.setValue(this.formatCurrencyPipe.transform(0));
    }
  }

  valueChangeUnidades(dataFormGroup: FormGroup) {
    this.formGroup.get('nUnidades')?.valueChanges.subscribe((val) => {
      const dataPesoTotal = dataFormGroup.get('pesoTotal')?.value;
      const dataPesoUnitario = dataFormGroup.get('pesoUnitario')?.value;

      if (val === '0') {
        this.formGroup.get('nUnidades')?.setValue('1', {emitEvent: false});
      }

      if (val && (dataPesoTotal || dataPesoUnitario)) {
        dataFormGroup.get('pesoTotal')?.setValue(null, {emitEvent: false});
        dataFormGroup.get('pesoUnitario')?.setValue(null, {emitEvent: false});
      }

      if (this.gridMaterial.length > 0 && val) {
        this.gridMaterial = this.gridMaterial.map((objeto) => {
          const nUnidades = this.parseCurrencyPipe.transform(val) || 1;
          const pesoUnitario = this.parseCurrencyPipe.transform(
            objeto.pesoUnitario
          );
          const pesoTotal = nUnidades * pesoUnitario;
          return {
            ...objeto,
            pesoTotal: this.formatCurrencyPipe.transform(pesoTotal),
          };
        });
        this.calcularRecuentoMap(this.gridMaterialOtros, this.gridMaterial);
        this.actualizarInfoEconomica();
      }

      if (this.gridMaterialOtros.length > 0 && val) {
        this.gridMaterialOtros = this.gridMaterialOtros.map((objeto) => {
          const nUnidades = this.parseCurrencyPipe.transform(val) || 1;
          const pesoUnitario = this.parseCurrencyPipe.transform(
            objeto.pesoUnitario
          );
          const pesoTotal = nUnidades * pesoUnitario;
          return {
            ...objeto,
            pesoTotal: this.formatCurrencyPipe.transform(pesoTotal),
          };
        });
        this.calcularRecuentoMap(this.gridMaterialOtros, this.gridMaterial);
        this.actualizarInfoEconomica();
      }
    });
  }

  convertToPoint(value: string | number): number {
    if (typeof value === 'string') {
      return parseFloat(value.replace(',', '.'));
    }
    return value;
  }

  formatearA5Decimales(valor: number): string {
    const factor = Math.pow(10, 5);
    const truncatedValue = Math.floor(valor * factor) / factor;
    return truncatedValue.toLocaleString('es-ES', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 5,
    });
  }

  addMaterial() {
    if (this.materialFormGroup.invalid) {
      this.materialFormGroup.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
      return;
    }

    if (this.gridMaterial.length >= 5) {
      this.snackBarSrv.showSnackBar(
        'Puede introducir, como máximo, hasta 5 materiales de composición',
        'error'
      );
      return;
    }

    const dataObjtable = {
      id: Math.random(),
      composicionMaterial: this.materialFormGroup.get('composicionMaterial')?.value,
      pesoUnitario: this.materialFormGroup.get('pesoUnitario')?.value,
      pesoTotal: this.materialFormGroup.get('pesoTotal')?.value,
      porcReciclado: this.materialFormGroup.get('porcReciclado')?.value,
    };
    this.gridMaterial.push(dataObjtable);
    this.gridMaterial = [...this.gridMaterial];
    this.materialFormGroup.reset({porcReciclado: 0});
    this.calcularRecuentoMap(this.gridMaterialOtros, this.gridMaterial);
    this.actualizarInfoEconomica();
  }

  guardar() {
    let datosCorrectos = this.validarDatos();

    if (!datosCorrectos) {
      return;
    } else if (this.gridMaterial.length > 5) {
      this.snackBarSrv.showSnackBar(
        'Puede introducir, como máximo, hasta 5 materiales de composición',
        'error'
      );
      return;
    }

    const objeto: DeclaracionEnvasePayloadDTO = this.obtenerDeclaracionEnvase();

    this.spinnerService.loadingShow();

    this.actualizarTarifaGuardar(objeto)
      .pipe(
        concatMap(() => {
          if (!this.nuevoEnvase) {
            let body = Object.assign(
              {id: this.declaracionEnvase?.id},
              objeto
            );
            return this.declaracionEnvaseControllerService.editById3(
              this.declaracionEnvase.id!,
              body,
              this.fileAcuerdo!,
              this.fileCertificado!
            );
          } else {
            return this.declaracionEnvaseControllerService.create3(
              objeto,
              this.fileAcuerdo!,
              this.fileCertificado!
            );
          }
        })
      )
      .subscribe({
        next: () => {
          this.spinnerService.loadingHide();
          this.snackBarSrv.showSnackBar(
            'El envase se ha actualizado correctamente',
            'success'
          );

          if (
            this.nuevoEnvase &&
            this.actionFormGroup.get('seguirAlta')?.value
          ) {
            this.formGroup.reset({
              nUnidades: '1',
              unidadesCirculacion: '0',
              unidadesNuevas: '1',
              totalUds: '1',
              totalEnvasesAnterior: '0',
              unidadesResiduos: 0,
              unidadesNoRetornadas: 0,
              unidadesExportadas: 0,
              unidadesEnvaseResiduo: 0,
            });

            this.formGroup.get('pesoUnitario')?.setValue(null);
            this.formGroup.get('pesoTotal')?.setValue(null);
            this.formGroup.get('peligrosidad')?.setValue(null);

            this.materialFormGroup.reset({porcReciclado: 0});
            this.otherElementFormGroup.reset({porcReciclado: 0});
            this.gridMaterial = [];
            this.gridMaterialOtros = [];
            this.flagSeguirAlta = true;
            this.snackBarSrv.showSnackBar(
              'Se ha guardado correctamente el envase',
              'success'
            );
          } else {
            this.dialogRef.close(true);
          }
          this.gridMaterial = this.convertShowGrid(this.gridMaterial);
          this.gridMaterialOtros = this.convertShowGrid(this.gridMaterialOtros);
        },
        error: (error) => {
          this.spinnerService.loadingHide();
          if (error?.error?.detail) {
            this.snackBarSrv.showSnackBar(error.error.detail, 'error');
          } else {
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al guardar la declaración de envase',
              'error'
            );
          }
        },
      });
  }

  actualizarInfoEconomica() {

    if (!(this.formGroup.get('tipologia')?.value !== null && this.formGroup.get('tipologia')?.value !== undefined
      && this.formGroup.get('tipoEnvase')?.value !== null && this.formGroup.get('tipoEnvase')?.value !== undefined
      && this.formGroup.get('peligrosidad')?.value !== null && this.formGroup.get('peligrosidad')?.value !== undefined
      && this.gridMaterial.length > 0)) {
      return;
    }

    const objeto: DeclaracionEnvasePayloadDTO = this.obtenerDeclaracionEnvase();

    this.spinnerService.loadingShow();

    this.declaracionEnvaseControllerService
      .calcularTarifas(this.declaracionAdherido.id, [objeto])
      .subscribe({
        next: (res) => {
          if (res?.length > 0) {
            let declaracionCalculada = res[0];
            this.formGroup.get('contribucionAnnoCurso')
              ?.setValue(this.formatCurrencyPipe.transform(declaracionCalculada.contriRapAnioCurso));
            this.formGroup.get('despositoAnnoCurso')
              ?.setValue(this.formatCurrencyPipe.transform(declaracionCalculada.depoRapAnioCurso));
            this.formGroup.get('contribucionAnnoAnt')
              ?.setValue(this.formatCurrencyPipe.transform(declaracionCalculada.contriRapAnioAnterior));
            this.formGroup.get('depositoAnnoAnt')
              ?.setValue(this.formatCurrencyPipe.transform(declaracionCalculada.depoRapAnioAnterior));
            this.formGroup.get('tarifaRapAnnoCurso')
              ?.setValue(this.formatCurrencyPipe.transform(declaracionCalculada.tarifaRapAnioCurso));
            this.formGroup.get('tarifaRapAnnoAnt')
              ?.setValue(this.formatCurrencyPipe.transform(declaracionCalculada.tarifaRapAnioAnterior));
            this.formGroup.get('tarifaDepositoAnnoCurso')
              ?.setValue(this.formatCurrencyPipe.transform(declaracionCalculada.tarifaDepoAnioCurso));
            this.formGroup.get('tarifaDepositoAnnoAnt')
              ?.setValue(this.formatCurrencyPipe.transform(declaracionCalculada.tarifaDepoAnioAnterior));
          }
          this.spinnerService.loadingHide();
        },
        error: (error) => {
          if (error?.error?.detail) {
            this.snackBarSrv.showSnackBar(error.error.detail, 'error');
          } else {
            this.snackBarSrv.showSnackBar('Ha ocurrido un error en el cálculo de la tarifa', 'error');
          }
          this.spinnerService.loadingHide();
        },
      });
    this.gridMaterial = this.convertShowGrid(this.gridMaterial);
    this.gridMaterialOtros = this.convertShowGrid(this.gridMaterialOtros);
  }

  actualizarTarifaGuardar(objeto: any): Observable<any> {
    this.spinnerService.loadingShow();

    return this.declaracionEnvaseControllerService
      .calcularTarifas(this.declaracionAdherido.id, [objeto])
      .pipe(
        tap({
          next: (res) => {
            if (res?.length > 0) {
              let declaracionCalculada = res[0];
              objeto.contriRapAnioCurso = declaracionCalculada.contriRapAnioCurso;
              objeto.depoRapAnioCurso = declaracionCalculada.depoRapAnioCurso;
              objeto.contriRapAnioAnterior = declaracionCalculada.contriRapAnioAnterior;
              objeto.depoRapAnioAnterior = declaracionCalculada.depoRapAnioAnterior;
            }
            this.spinnerService.loadingHide();
          },
          error: (error) => {
            if (error?.error?.detail) {
              this.snackBarSrv.showSnackBar(error.error.detail, 'error');
            } else {
              this.snackBarSrv.showSnackBar(
                'Ha ocurrido un error en el cálculo de la tarifa', 'error'
              );
            }
            this.spinnerService.loadingHide();
          },
        })
      );
  }

  addOtrosMaterial() {
    if (this.otherElementFormGroup.invalid) {
      this.otherElementFormGroup.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
      return;
    }

    const dataObjtable = {
      id: Math.random(),
      tipoElemento: this.otherElementFormGroup.get('tipoElemento')?.value,
      composicionMaterial: this.otherElementFormGroup.get(
        'composicionMaterialOther'
      )?.value,
      pesoUnitario: this.otherElementFormGroup.get('pesoUnitario')?.value,

      pesoTotal: this.otherElementFormGroup.get('pesoTotal')?.value,
      porcReciclado: this.otherElementFormGroup.get('porcReciclado')?.value,
    };
    this.gridMaterialOtros.push(dataObjtable);
    this.gridMaterialOtros = [...this.gridMaterialOtros];
    this.otherElementFormGroup.reset({porcReciclado: 0});
    this.calcularRecuentoMap(this.gridMaterialOtros, this.gridMaterial);
    this.actualizarInfoEconomica();
  }

  private calcularRecuentoMap(
    arrayMapMaterial: any[],
    arrayMapMaterialOtros: any[],
    pesoUnitario: string = 'pesoUnitario',
    pesoTotal: string = 'pesoTotal'
  ): void {
    let sumaTotalPesoUnitario = 0;
    let sumaTotalPesoTotal = 0;

    arrayMapMaterial.forEach((objeto: any) => {
      if (typeof objeto[pesoUnitario] === 'string') {
        const valWithPoint = this.parseCurrencyPipe.transform(
          objeto[pesoUnitario]
        );
        sumaTotalPesoUnitario += valWithPoint;
      } else if (!isNaN(objeto[pesoUnitario])) {
        sumaTotalPesoUnitario += objeto[pesoUnitario];
      }

      if (typeof objeto[pesoTotal] === 'string') {
        const valWithPoint = this.parseCurrencyPipe.transform(
          objeto[pesoTotal]
        );
        sumaTotalPesoTotal += valWithPoint;
      } else if (!isNaN(objeto[pesoTotal])) {
        sumaTotalPesoTotal += objeto[pesoTotal];
      }
    });

    arrayMapMaterialOtros.forEach((objeto: any) => {
      if (typeof objeto[pesoUnitario] === 'string') {
        const valWithPoint = this.parseCurrencyPipe.transform(
          objeto[pesoUnitario]
        );
        sumaTotalPesoUnitario += valWithPoint;
      } else if (!isNaN(objeto[pesoUnitario])) {
        sumaTotalPesoUnitario += objeto[pesoUnitario];
      }

      if (typeof objeto[pesoTotal] === 'string') {
        const valWithPoint = this.parseCurrencyPipe.transform(
          objeto[pesoTotal]
        );
        sumaTotalPesoTotal += valWithPoint;
      } else if (!isNaN(objeto[pesoTotal])) {
        sumaTotalPesoTotal += objeto[pesoTotal];
      }
    });

    this.formGroup.controls[pesoUnitario]?.setValue(
      this.formatCurrencyPipe.transform(sumaTotalPesoUnitario)
    );
    this.formGroup.controls[pesoTotal]?.setValue(
      this.formatCurrencyPipe.transform(sumaTotalPesoTotal)
    );
  }

  controlHasErrorSelect(dataFormGroup: FormGroup, controlName: string) {
    if (
      (dataFormGroup.controls[controlName]?.touched ||
        dataFormGroup.controls[controlName]?.dirty) &&
      dataFormGroup.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  public rightHolder(dataFormGroup: FormGroup, controlName: string) {
    let cl2 = '';
    if (
      (dataFormGroup.controls[controlName]?.touched ||
        dataFormGroup.controls[controlName]?.dirty) &&
      dataFormGroup.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  confirmacionEliminarMaterial(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar el material?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.snackBarSrv.showSnackBar(
          `Se ha eliminando correctamente`,
          'success'
        );
        this.gridMaterial = this.gridMaterial.filter(
          (objeto) => objeto.id !== element.id
        );
        this.calcularRecuentoMap(this.gridMaterialOtros, this.gridMaterial);
        this.actualizarInfoEconomica();
      }
    });
  }

  confirmacionEliminarOtros(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar el elemento de envase?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.snackBarSrv.showSnackBar(
          `Se ha eliminando correctamente`,
          'success'
        );
        this.gridMaterialOtros = this.gridMaterialOtros.filter(
          (objeto) => objeto.id !== element.id
        );
        this.calcularRecuentoMap(this.gridMaterialOtros, this.gridMaterial);
        this.actualizarInfoEconomica();
      }
    });
  }

  private validarDatos() {
    let datosCorrectos = true;
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.invalid) {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
      datosCorrectos = false;
    } else if (this.gridMaterial.length == 0) {
      this.snackBarSrv.showSnackBar(
        'Añadir mínimo un Material de Composición',
        'error'
      );
      datosCorrectos = false;
    } else if (
      this.isCategoriaSDDRCerrado() &&
      this.acuerdosClientesFormGroup.invalid
    ) {
      this.acuerdosClientesFormGroup.markAllAsTouched();
      this.acuerdosClientesFormGroup.updateValueAndValidity();
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
      datosCorrectos = false;
    } else if (this.isCategoriaSDDRCerrado()) {
      if (!this.acuerdosClientesFormGroup.get('tipoAcuerdo')?.value) {
        if (this.nombreArchivoAcuerdo === '') {
          this.snackBarSrv.showSnackBar(
            'Debes adjuntar un Modelo Acuerdo de Cesión',
            'error'
          );
          return false;
        }
      }
      if (this.nombreArchivoCertificado === '') {
        this.snackBarSrv.showSnackBar(
          'Debes adjuntar un certificado de envases reutilizables',
          'error'
        );
        return false;
      }
    } else if (this.isCategoriaSDDRAbierto()) {
      if (this.nombreArchivoCertificado === '') {
        this.snackBarSrv.showSnackBar(
          'Debes adjuntar un certificado de envases reutilizables',
          'error'
        );
        return false;
      }
    }
    return datosCorrectos;
  }

  private obtenerDeclaracionEnvase() {
    let tipoEnvase = this.formGroup.get('tipoEnvase')?.value;

    this.gridMaterial = this.convertParseGrid(this.gridMaterial);
    this.gridMaterialOtros = this.convertParseGrid(this.gridMaterialOtros);

    const objeto: DeclaracionEnvasePayloadDTO | any = {
      declaracionCentro: this.declaracionCentro,
      tipoEnvase:
        typeof tipoEnvase === 'string'
          ? this.declaracionEnvase.tipoEnvase
          : this.formGroup.get('tipoEnvase')?.value,
      tipologia: this.formGroup.get('tipologia')?.value,
      peligrosidad: this.formGroup.get('peligrosidad')?.value,
      denominacion: this.formGroup.get('denominacion')?.value,
      pesoUnitario: this.convertToPointObj(
        this.formGroup.get('pesoUnitario')?.value
      ),
      unidadesTotales: this.isCategoriaSDDRCerrado()
        ? this.convertToPointObj(this.formGroup.get('totalUds')?.value)
        : this.convertToPointObj(this.formGroup.get('nUnidades')?.value),
      pesoTotal: this.convertToPointObj(this.formGroup.get('pesoTotal')?.value),
      vidaUtil: this.formGroup.get('vidaUtil')?.value,
      ciclosReutilizacion: this.formGroup.get('cicloReutilizacion')?.value,
      contriRapAnioCurso: this.convertToPointObj(
        this.formGroup.get('contribucionAnnoCurso')?.value
      ),
      depoRapAnioCurso: this.convertToPointObj(
        this.formGroup.get('despositoAnnoCurso')?.value
      ),
      contriRapAnioAnterior: this.convertToPointObj(
        this.formGroup.get('contribucionAnnoAnt')?.value
      ),
      depoRapAnioAnterior: this.convertToPointObj(
        this.formGroup.get('depositoAnnoAnt')?.value
      ),
      envaseMaterial: this.gridMaterial.concat(this.gridMaterialOtros),
      acuerdoDeposito: this.acuerdosClientesFormGroup.get('tipoAcuerdo')?.value,
      acuerdoCesion: !this.acuerdosClientesFormGroup.get('tipoAcuerdo')?.value,
      importeDeposito:
        this.acuerdosClientesFormGroup.get('tipoAcuerdo')?.value === true
          ? this.convertToPointObj(
            this.acuerdosClientesFormGroup.get('importeDeposito')?.value
          )
          : null,
      fabricanteProductor: this.acuerdosClientesFormGroup.get('fabricanteProductor')
        ?.value,
      prestadorServicios:
      this.acuerdosClientesFormGroup.get('prestadorServicios')?.value,
      simboloSddrcerrado:
      this.acuerdosClientesFormGroup.get('simboloSddrcerrado')?.value,
      envasesSddrcerrado:
      this.acuerdosClientesFormGroup.get('envasesSddrcerrado')?.value,
      unidadesCirculacion: this.convertToPointObj(
        this.formGroup.get('unidadesCirculacion')?.value
      ),
      unidadesNuevas: this.convertToPointObj(
        this.formGroup.get('unidadesNuevas')?.value
      ),
      unidadesResiduos: this.convertToPointObj(
        this.formGroup.get('unidadesResiduos')?.value
      ),
      unidadesNoRetornadas: this.convertToPointObj(
        this.formGroup.get('unidadesNoRetornadas')?.value
      ),
      unidadesExportadas: this.convertToPointObj(
        this.formGroup.get('unidadesExportadas')?.value
      ),
      unidadesEnvaseResiduo: this.convertToPointObj(
        this.formGroup.get('unidadesEnvaseResiduo')?.value
      ),
      docModeloCesion: this.declaracionEnvase.docModeloCesion,
      docCertificadoReutilizables:
      this.declaracionEnvase.docCertificadoReutilizables,
    };

    return objeto;
  }

  convertParseGrid(grid: any[]) {
    return grid.map((item) => ({
      ...item,
      pesoUnitario: this.convertToPointObj(item.pesoUnitario),
      pesoTotal: this.convertToPointObj(item.pesoTotal),
    }));
  }

  convertShowGrid(grid: any[]) {
    return grid.map((item) => ({
      ...item,
      pesoUnitario: this.formatCurrencyPipe.transform(item.pesoUnitario),
      pesoTotal: this.formatCurrencyPipe.transform(item.pesoTotal),
    }));
  }

  convertToPointObj(value: any): number {
    if (typeof value === 'string') {
      const normalizedValue = value.replace(/\./g, '').replace(',', '.');
      return parseFloat(normalizedValue);
    } else if (typeof value === 'number') {
      return value;
    } else {
      return 0;
    }
  }

  cerrar(): void {
    if (this.flagSeguirAlta) {
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close(null);
    }
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }

  controlHasErrorAcuerdosConClientes(controlName: string, errorName: string) {
    return (
      this.acuerdosClientesFormGroup.controls[controlName].hasError(errorName) &&
      this.acuerdosClientesFormGroup.controls[controlName].touched
    );
  }

  actionMaterial(event: any) {
    if (event.nameButton == 'delete') {
      this.confirmacionEliminarMaterial(event);
    }
  }

  actionMaterialOtros(event: any) {
    if (event.nameButton == 'delete') {
      this.confirmacionEliminarOtros(event);
    }
  }

  isOrdinaria(): boolean {
    let dae: DeclaracionAdheridoDTO | undefined = this.declaracionCentro?.declaracionAdherido;
    return dae?.version !== 'IN';
  }

  isCategoriaSoloUso(): boolean {
    return [1, 2].includes(this.declaracionCentro?.categoriaEnvase?.id!);
  }

  isCategoriaSDDRCerrado(): boolean {
    return [3, 4].includes(this.declaracionCentro?.categoriaEnvase?.id!);
  }

  isCategoriaSDDRAbierto(): boolean {
    return [5, 6].includes(this.declaracionCentro?.categoriaEnvase?.id!);
  }

  canAccessEditAllGlobal(): boolean {
    return (
      this.authService.can('dec_envases', 'update') &&
      this.declaracionAdherido?.estadoDeclaracion?.id == 0
    );
  }

  adjuntarCertificado() {
    this.fileInputCertificado.nativeElement.click();
  }

  adjuntarAcuerdo() {
    this.fileInputAcuerdo.nativeElement.click();
  }

  handleFileInputAcuerdo(event: any) {
    this.fileAcuerdo = event.target.files[0];
    if (this.fileAcuerdo) {
      this.nombreArchivoAcuerdo = this.fileAcuerdo.name;
      this.snackBarSrv.showSnackBar(
        'Se adjuntó el archivo correctamente.',
        'success'
      );
    } else {
      this.snackBarSrv.showSnackBar(
        'Por favor, seleccione un archivo.',
        'error'
      );
    }
  }

  handleFileInputCertificado(event: any) {
    this.fileCertificado = event.target.files[0];
    if (this.fileCertificado) {
      this.nombreArchivoCertificado = this.fileCertificado.name;
      this.snackBarSrv.showSnackBar(
        'Se adjuntó el archivo correctamente.',
        'success'
      );
    } else {
      this.snackBarSrv.showSnackBar(
        'Por favor, seleccione un archivo.',
        'error'
      );
    }
  }

  descargar(documento: any) {
    this.spinnerSrv.loadingShow();
    this.documentService.descargarDocumento(documento.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Se ha descargado el documento correctamente',
          'success'
        );
        this.downloadService.downloadBlob(response, documento.nombre);
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  valueChangeTipoEnvase() {
    this.formGroup.get('tipoEnvase')?.valueChanges
      .subscribe((envase: any) => {
        if (envase) {
          this.filteredTipologiaArray = [];
          this.formGroup.get('tipologia')?.setValue(null);
          this.formGroup.get('peligrosidad')?.setValue(null);

          if (this.isCategoriaSDDRAbierto()) {
            if (envase?.descripcion === 'IBC, GRG') {
              this.filteredTipologiaArray.push(this.tipologiaArray.find((t: any) => t.id === "PRIMARIO"))
            }
            if (envase?.descripcion === 'Pallet, palet, paleta') {
              this.filteredTipologiaArray.push(this.tipologiaArray.find((t: any) => t.id === "TERCIARIO"))
            }
          } else {

            const tipologias: string[] = ['tipologia1', 'tipologia2', 'tipologia3'];

            // Filtra las tipologías que no sean null o undefined
            this.filteredTipologiaArray = tipologias.map((key: string) => {
              return this.tipologiaArray.find((t: any) => t.id === envase[key]);
            }).filter(Boolean);
          }

          if (this.filteredTipologiaArray.length === 1) {
            this.formGroup.get('tipologia')?.setValue(this.filteredTipologiaArray[0].id);
          }
        } else {
          this.filteredTipologiaArray = [];
        }
        this.actualizarInfoEconomica();
        this.updateEnvasesAnterior();
      });
  }

  updateEnvasesAnterior() {

    let tipoEnvase = this.formGroup.get('tipoEnvase')?.value;
        let tipoEnvaseId = typeof tipoEnvase === 'string'
          ? this.declaracionEnvase.tipoEnvase?.id
          : this.formGroup.get('tipoEnvase')?.value?.id;

    let filtro: DeclaracionEnvaseAnteriorFilterDTO = {
      declaracionAdherido: this.declaracionAdherido?.id,
      centro: this.declaracionCentro.centroEnvasado?.id,
      categoriaEnvase: this.declaracionCentro?.categoriaEnvase?.id,
      tipoEnvases: {id: tipoEnvaseId},
      peligrosidad: this.formGroup.get('peligrosidad')?.value,
      tipologia: this.formGroup.get('tipologia')?.value
    };
    console.log(filtro);

    // Verificar si algún campo es null o undefined
    if (filtro.declaracionAdherido === null || filtro.declaracionAdherido === undefined ||
      filtro.centro === null || filtro.centro === undefined ||
      filtro.categoriaEnvase === null || filtro.categoriaEnvase === undefined ||
      filtro.tipoEnvases === null || filtro.tipoEnvases === undefined ||
      filtro.peligrosidad === null || filtro.peligrosidad === undefined ||
      filtro.tipologia === null || filtro.tipologia === undefined) {
      return;
    }

    this.declaracionEnvaseControllerService.totalEnvasesAnterior(filtro)
      .subscribe({
        next: (res) => {
          console.log(res);
          this.formGroup.get('totalEnvasesAnterior')?.setValue(res);
        }
      });
  }

  initTipologias() {
    let envase: TipoEnvasesDTO | undefined = this.declaracionEnvase.tipoEnvase;
    if (envase != undefined) {
      this.filteredTipologiaArray = [];
      this.formGroup.get('tipologia')?.setValue(null);
      this.formGroup.get('peligrosidad')?.setValue(null);

      if (this.isCategoriaSDDRAbierto()) {
        if (envase?.descripcion === 'IBC, GRG') {
          this.filteredTipologiaArray.push(this.tipologiaArray.find((t: any) => t.id === "PRIMARIO"))
        }
        if (envase?.descripcion === 'Pallet, palet, paleta') {
          this.filteredTipologiaArray.push(this.tipologiaArray.find((t: any) => t.id === "TERCIARIO"))
        }
      } else {
        if (envase?.tipologia1) {
          this.filteredTipologiaArray.push(this.tipologiaArray.find((t: any) => t.id === envase?.tipologia1))
        }
        if (envase?.tipologia2) {
          this.filteredTipologiaArray.push(this.tipologiaArray.find((t: any) => t.id === envase?.tipologia2))
        }
        if (envase?.tipologia3) {
          this.filteredTipologiaArray.push(this.tipologiaArray.find((t: any) => t.id === envase?.tipologia3))
        }
      }
      if (this.filteredTipologiaArray.length === 1) {
        this.formGroup.get('tipologia')?.setValue(this.filteredTipologiaArray[0].id);
      }
    } else {
      this.filteredTipologiaArray = [];
    }
  }

  valueChangeTipologia() {
    this.formGroup.get('tipologia')?.valueChanges
      .subscribe((selectedTipologia: string) => {
        // Si valor es PRIMARIO, cargar Si/No
        if (selectedTipologia === 'PRIMARIO') {
          this.peligrosidadOptions = [
            {value: true, label: 'Si'},
            {value: false, label: 'No'}
          ];
          this.formGroup.get('peligrosidad')?.setValue(null);
        } else {
          this.peligrosidadOptions = [
            {value: false, label: 'No'}
          ];
          this.formGroup.get('peligrosidad')?.setValue(false);
        }

        this.actualizarInfoEconomica();
        this.updateEnvasesAnterior();
      });
  }

  valueChangePeligrosidad(){
    this.formGroup.get('peligrosidad')?.valueChanges
      .subscribe((val: string) => {
        this.actualizarInfoEconomica();
        this.updateEnvasesAnterior();
      });
  }

  valueChangeInfoSDDRCerrado() {
    this.formGroup.get('totalEnvasesAnterior')?.valueChanges.subscribe(() => this.updateUdsCirculacionInfo());
    this.formGroup.get('unidadesResiduos')?.valueChanges.subscribe(() => this.updateUdsCirculacionInfo());
    this.formGroup.get('unidadesNoRetornadas')?.valueChanges.subscribe(() => this.updateUdsCirculacionInfo());
    this.formGroup.get('unidadesExportadas')?.valueChanges.subscribe(() => this.updateUdsCirculacionInfo());
    this.formGroup.get('unidadesEnvaseResiduo')?.valueChanges.subscribe(() => this.updateUdsCirculacionInfo());
  }

  updateUdsCirculacionInfo() {
    let totalEnvasesAnterior = this.formGroup.get('totalEnvasesAnterior')?.value;
    const unidadesResiduos = this.formGroup.get('unidadesResiduos')?.value ?? 0;
    const unidadesNoRetornadas = this.formGroup.get('unidadesNoRetornadas')?.value ?? 0;
    const unidadesExportadas = this.formGroup.get('unidadesExportadas')?.value ?? 0;
    const unidadesEnvaseResiduo = this.formGroup.get('unidadesEnvaseResiduo')?.value ?? 0;

    const sumaUds: number = parseInt(unidadesResiduos) + parseInt(unidadesNoRetornadas) + parseInt(unidadesExportadas) + parseInt(unidadesEnvaseResiduo);
    const udsCirculacionTotal: number = parseInt(totalEnvasesAnterior) - sumaUds;

    const formattedValue = isNaN(udsCirculacionTotal) ? '0' : this.formatCurrencyPipe.transform(udsCirculacionTotal);
    this.formGroup.get('udsCirculacionInfo')?.setValue(formattedValue);
  }

  onInputChange(controlName: string, event: any): void {
    setTimeout(() => {
      const value = event.target.value;
      const cleanValue = value.replace('€', '').trim();
      const numericValue = parseFloat(cleanValue) || 0;
      this.formGroup.get(controlName)?.setValue(numericValue);
    }, 250);
  }
}
