<form [formGroup]="formGroup">
  <div class="row-fields">
    <!--<div class="field field-50">
      <label for="iban">IBAN cuenta adherido</label>
      <mat-form-field appearance="outline">
        <input formControlName="iban" id="iban" placeholder="IBAN cuenta adherido" matInput type="text" maxlength="34">
        <mat-error *ngIf="controlHasError('iban', 'pattern')">Formato incorrecto</mat-error>
          <mat-error *ngIf="controlHasError( 'iban', 'maxlength')">Máximo 34 caracteres</mat-error>
      </mat-form-field>
    </div>-->
    <div class="field field-50">
      <label for="numPedido">Número pedido</label>
      <mat-form-field appearance="outline">
        <input formControlName="numPedido" id="numPedido" placeholder="Número pedido" matInput type="text"
          maxlength="255">
        <mat-error *ngIf="controlHasError( 'numPedido', 'maxlength')">Máximo 255 caracteres</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row-fields">
    <div class="field field-33">
      <label for="fechaInicio">Fecha inicio</label>
      <mat-form-field appearance="outline">
        <input formControlName="fechaInicio" id="fechaInicio" matInput type="text" readonly>
      </mat-form-field>
    </div>
    <div class="field field-33">
      <label for="fechaFin">Fecha finalización</label>
      <mat-form-field appearance="outline">
        <input formControlName="fechaFin" id="fechaFin" matInput type="text" readonly>
      </mat-form-field>
    </div>
    <div class="field field-33">
      <label for="fechaRechazo">Fecha rechazo</label>
      <mat-form-field appearance="outline">
        <input formControlName="fechaRechazo" id="fechaRechazo" matInput type="text" readonly>
      </mat-form-field>
    </div>
  </div>
</form>
<p style="color:#004A9A; font-weight: bold; margin-top: 20px;">Resumen económico</p>

<div class="info pt-3">
  <table class="table">
    <thead>
      <tr>
        <th>Totalizados</th>
        <th>Año de los datos ({{anoDatosAnterior}})</th>
        <th>Año en curso ({{anoDatosCurso}})</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="color1"><strong>Envases un solo uso.</strong> Contribución Cuotas RAP</td>
        <td class="color1">{{declaracionAdherido?.contriRapAnioAnteriorSoloUso | commaDecimal}} €</td>
        <td class="color1">{{declaracionAdherido?.contriRapAnioCursoSoloUso | commaDecimal}} €</td>
      </tr>
      <tr>
        <td class="color2"><strong>SDDR Circuito Cerrado.</strong> Cuota anual</td>
        <td class="color2">{{declaracionAdherido?.contriRapAnioAnteriorCerrado | commaDecimal}} €</td>
        <td class="color2">{{declaracionAdherido?.contriRapAnioCursoCerrado | commaDecimal}} €</td>
      </tr>
      <tr>
        <td class="color2"><strong>SDDR Circuito Cerrado.</strong> Contribución Fianzas </td>
        <td class="color2">{{declaracionAdherido?.depoRapAnioAnteriorCerrado | commaDecimal}} €</td>
        <td class="color2">{{declaracionAdherido?.depoRapAnioCursoCerrado | commaDecimal}} €</td>
      </tr>
      <tr>
        <td class="color3"><strong>SDDR Circuito Abierto.</strong> Contribución Cuotas RAP</td>
        <td class="color3">{{declaracionAdherido?.contriRapAnioAnteriorAbierto | commaDecimal}} €</td>
        <td class="color3">{{declaracionAdherido?.contriRapAnioCursoAbierto | commaDecimal}} €</td>
      </tr>
      <tr>
        <td class="color3"><strong>SDDR Circuito Abierto.</strong> Contribución Depósitos </td>
        <td class="color3">{{declaracionAdherido?.depoRapAnioAnteriorAbierto | commaDecimal}} €</td>
        <td class="color3">{{declaracionAdherido?.depoRapAnioCursoAbierto | commaDecimal}} €</td>
      </tr>
    </tbody>
  </table>

  <div class="banner">
    Cuando la contribución anual total de todos los tipos de categorías de envases declarados incluyendo todos los
    conceptos sea inferior a 600 €, se facturará la contribución mínima anual de 600 €/año aprobada por la Asamblea
    General de ENVALORA celebrada el 21 de octubre 2024.
  </div>

</div>
<div class="button-cont">
  <button type="button" *ngIf="canAccessFinalizar()" class="btn btn-outline-primary margin-left-button"
    data-dismiss="modal" (click)="buttonFinalizarDeclaracion()" aria-label="Finalizar Declaración"><em
      class="fas fa-check mr-2"></em> Finalizar Declaración</button>
  <button type="button" *ngIf="canAccessEditAllGlobal()" class="btn btn-outline-primary margin-left-button"
    data-dismiss="modal" (click)="buttonEconomico()" aria-label="Actualizar resumen económico"><em
      class="fas fa-sync mr-2"></em> Actualizar resumen económico</button>
  <button type="button" class="btn btn-outline-primary margin-left-button" data-dismiss="modal"
    (click)="buttonCancelar()" aria-label="Salir">Salir</button>
  <button type="button" *ngIf="canAccessEditAllGlobal()" class="btn btn-primary margin-left-button" data-dismiss="modal"
    (click)="guardar()" aria-label="Guardar">Guardar</button>
</div>