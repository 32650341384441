/**
 * Comunicaciones API
 * Comunicaciones API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocumentoDTO } from './documentoDTO';
import { ComunicacionEstadoDTO } from './comunicacionEstadoDTO';
import { KeycloakUserDTO } from './keycloakUserDTO';


export interface ComunicacionDTO { 
    fechaAlta?: string;
    creadoPor?: string;
    fechaModificacion?: string;
    modificadoPor?: string;
    id?: number;
    tipo?: ComunicacionDTO.TipoEnum;
    prioridad?: ComunicacionDTO.PrioridadEnum;
    categoria?: ComunicacionDTO.CategoriaEnum;
    empresa?: string;
    fechaIncidencia?: string;
    horaIncidencia?: string;
    asunto?: string;
    descripcion?: string;
    user?: KeycloakUserDTO;
    documentoIncidencia?: DocumentoDTO;
    contactoSoporte?: boolean;
    comentarios?: string;
    estado?: ComunicacionEstadoDTO;
}
export namespace ComunicacionDTO {
    export type TipoEnum = 'INCIDENCIA' | 'SUGERENCIA' | 'CONSULTA';
    export const TipoEnum = {
        Incidencia: 'INCIDENCIA' as TipoEnum,
        Sugerencia: 'SUGERENCIA' as TipoEnum,
        Consulta: 'CONSULTA' as TipoEnum
    };
    export type PrioridadEnum = 'BAJA' | 'MEDIA' | 'ALTA';
    export const PrioridadEnum = {
        Baja: 'BAJA' as PrioridadEnum,
        Media: 'MEDIA' as PrioridadEnum,
        Alta: 'ALTA' as PrioridadEnum
    };
    export type CategoriaEnum = 'ADHERIDOS' | 'DAE' | 'POSEEDORES' | 'GEST_FINALES' | 'PTO_RECOGIDA' | 'SDDR_CC' | 'SDDR_CA' | 'AC_GESTORES';
    export const CategoriaEnum = {
        Adheridos: 'ADHERIDOS' as CategoriaEnum,
        Dae: 'DAE' as CategoriaEnum,
        Poseedores: 'POSEEDORES' as CategoriaEnum,
        GestFinales: 'GEST_FINALES' as CategoriaEnum,
        PtoRecogida: 'PTO_RECOGIDA' as CategoriaEnum,
        SddrCc: 'SDDR_CC' as CategoriaEnum,
        SddrCa: 'SDDR_CA' as CategoriaEnum,
        AcGestores: 'AC_GESTORES' as CategoriaEnum
    };
}


