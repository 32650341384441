<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
 <!-- MIGAS DE PAN Y TÍTULO -->
  <div class="bg-second-migas">
    <div class="container mt-cab-fixed">
      <div class="pre-content">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">Adheridos</li>
            <li class="breadcrumb-item active"><a href="index.html">Búsqueda de Adheridos</a></li>
          </ol>
        </nav>
        <div class="content-title">
          <h2 class="title-pag">Búsqueda de adheridos</h2>
          <button *ngIf="canAccessPreliminaryDischarge()" type="button" class="btn btn-primary" data-toggle="modal"
            (click)="openModalPreliminaryRegistration()"><span class="icon-add"></span>Alta Preliminar</button>
        </div>
      </div>
    </div>
  </div>
  <!-- FIN MIGAS DE PAN Y TÍTULO -->
  <!-- CONTENIDO -->

  <div class="container-form">
    <!-- Formulario de búsqueda -->
    <form class="container form-search py-3 padding-form" [formGroup]="searchForm">
      <div class="row form">
        <div class="col-sm flex-grow-0">
          <label for="identificador">Id. Adherido</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline">
              <input type="text" matInput formControlName="identificador" id="identificador" placeholder="Identificador"
                value="" maxlength="10" (keydown)="onlyNumberKey($event)">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-0">
          <label for="numRegistro">Nº de registro de producto</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline">
              <input type="text" matInput formControlName="numRegistro" id="numRegistro"
                placeholder="Nº de registro de producto" value="" maxlength="18">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-0">
          <label for="nombreGrupo">Nombre grupo empresarial</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input type="text" matInput formControlName="nombreGrupo" id="nombreGrupo"
                placeholder="Nombre grupo empresarial" value="">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-2">
          <label for="autonomia">Comunidad autónoma (social)</label>
          <div class="custom-mat-field">
            <lib-ge-select-pagination  (selectedModel)="selectedModel()"
              [endPointFilter]="comunidadesEndpoint" (filteredList)="filterList($event)" [updateList]="updateList"
              [disabled]="flagDisabledForm" [placeHolder]="'--Comunidad Autónoma--'"
              [formControlNameSelect]="getFormControl('autonomia')" [objQueryName]="'nombre'" descripcion="nombre">
            </lib-ge-select-pagination>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-0">
          <label for="documentType">Tipo documento</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline">
              <mat-select formControlName="documentType" id="documentType" placeholder="--CIF--">
                <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.id">
                  {{tipo.name}}
                </mat-option>
              </mat-select>
              <span *ngIf="showClearButton('documentType')" tabindex="0" class="ng-clear-wrapper"
                (click)="clearSelection($event,'documentType')">
                <span aria-hidden="true" class="ng-clear">×</span>
              </span>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-0">
          <label for="document">Documento</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline">
              <input type="text" matInput formControlName="document" id="document" placeholder="Documento" value="">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-0">
          <label for="rsocial">Razón Social</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <input type="text" matInput formControlName="rsocial" id="rsocial" placeholder="Razón Social" value="">
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm">
          <label for="cifra">Cifra de negocio</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <mat-select formControlName="cifra" id="cifra" placeholder="--Cifra de negocio--">
                <mat-option *ngFor="let tipo of cifrasNegocio" [value]="tipo.id">
                  {{tipo.descripcion}}
                </mat-option>
              </mat-select>
              <span *ngIf="showClearButton('cifra')" tabindex="0" class="ng-clear-wrapper"
                (click)="clearSelection($event,'cifra')">
                <span aria-hidden="true" class="ng-clear">×</span>
              </span>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-2">
          <label for="cnae">CNAE</label>
          <div class="custom-mat-field">
            <lib-ge-select-pagination (selectedModel)="selectedModelCnae()" [endPointFilter]="cnaeEndpoint"
              [multiple]=true (filteredList)="filterList($event)" [updateList]="updateList" [descripcion]="getCnaDescription"
              [disabled]="flagDisabledForm" [formControlNameSelect]="getFormControl('cnae')" [objQueryName]="getCnaeObjectQuery"
              [placeHolder]="'--CNAE Principal o Secundario--'" [customClass]="rightHolder('cnae')">
            </lib-ge-select-pagination>
          </div>
        </div>
        <div class="col-sm">
          <label for="estado">Estado</label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <mat-select formControlName="estado" id="estado" placeholder="--Estado--">
                <mat-option *ngFor="let estado of estados" [value]="estado.id">
                  {{estado.descripcion_corta}}
                </mat-option>
              </mat-select>
              <span *ngIf="showClearButton('estado')" tabindex="0" class="ng-clear-wrapper"
                (click)="clearSelection($event,'estado')">
                <span aria-hidden="true" class="ng-clear">×</span>
              </span>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="button-group">
        <button type="button" class="btn btn-outline-primary" (click)="clearSearcher()">Limpiar</button>
        <button type="button" class="btn btn-primary" (click)="searchData()">Buscar</button>
      </div>
    </form>
    <!-- FIN Formulario de búsqueda -->
  </div>
  <div class="bg-second-table-info pt-3 pb-5 " >
    <div *ngIf="flagSearch; else messageClear" class="container">
      <div class="actions-table">
        <div class=""><span class="align-middle tit-table">{{totalElements}} registros
            encontrados</span></div>
        <div class="text-right">
          <div class="d-inline">
            <div class="dropdown show">
              <a class="btn btn-outline-primary" role="button" aria-haspopup="true" aria-expanded="false"
                (click)="exportarCsv()">
                <span>Exportar</span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="card-tabla-proy">
        <div class="card-body">
          <div class="datatable-wrapper datatable-loading no-footer sortable searchable fixed-columns">
            <div class="datatable-container table-responsive">
              <!-- TABLA Listado con resultados de búsqueda -->
              <lib-ge-table [headArray]="headArray" [gridArray]="gridArray" (checked)="checked($event)"
                (actionButton)="action($event)"></lib-ge-table>
              <div class="d-flex pb-2 flex-row justify-content-center align-items-center">
                <div class="d-flex mt-2">
                  <ngb-pagination [pageSize]="pageSize" [collectionSize]="totalElements" [(page)]="pageNumber"
                    (pageChange)="renderPage($event)" [maxSize]="5" aria-label="Default pagination" size="sm">
                  </ngb-pagination>
                </div>

                <div class="mt-2 selectPage">
                  <label for="itemsPerPage">Mostrar:</label>
                  <select id="itemsPerPage" [(ngModel)]="selectedItemsPerPage" (change)="onItemsPerPageChange()">
                    <option *ngFor="let option of itemsPerPageOptions" [value]="option">{{ option }}</option>
                  </select>
                </div>
              </div>
              <!-- FIN TABLA Listado con resultados de búsqueda -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #messageClear>
        <div class="mensaje-container">
          <p class="mensaje-linea">No hay resultados de búsqueda, por favor seleccione, al menos, un criterio en el
            formulario,</p>
          <p class="mensaje-linea">o cree un nuevo adherido en "Alta Preliminar"</p>
        </div>
    </ng-template>
  </div>


  <!-- FIN CONTENIDO -->

