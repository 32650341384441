/**
 * Docum API
 * Docum API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DocumentoInteresModel } from './documentoInteresModel';


export interface DocumentoInteresRoleModel { 
    id?: number;
    documentoInteres?: DocumentoInteresModel;
    role?: DocumentoInteresRoleModel.RoleEnum;
}
export namespace DocumentoInteresRoleModel {
    export type RoleEnum = 'AD_ADHERIDO' | 'ADM_COMUNICACIONES' | 'AG_GESTOR' | 'AG_POSEEDOR' | 'DE_ADHERIDO' | 'PO_GESTOR' | 'PO_POSEEDOR' | 'PR_GESTOR' | 'PR_PUNTORECOGIDA' | 'SDDR_CC' | 'SDDR_CA' | 'PO_APORT_AUTOFACT' | 'AD_ENV_CONS' | 'AD_ENV_GEST' | 'AG_ENV_CONS' | 'AG_ENV_GEST' | 'DE_ENV_CONS' | 'DE_ENV_GEST' | 'PO_ENV_CONS' | 'PO_ENV_GEST' | 'PR_ENV_CONS' | 'PR_ENV_GEST' | 'SDDR_CA_ENV_CONS' | 'SDDR_CA_ENV_GEST' | 'SDDR_CC_ENV_CONS' | 'SDDR_CC_ENV_GEST';
    export const RoleEnum = {
        AdAdherido: 'AD_ADHERIDO' as RoleEnum,
        AdmComunicaciones: 'ADM_COMUNICACIONES' as RoleEnum,
        AgGestor: 'AG_GESTOR' as RoleEnum,
        AgPoseedor: 'AG_POSEEDOR' as RoleEnum,
        DeAdherido: 'DE_ADHERIDO' as RoleEnum,
        PoGestor: 'PO_GESTOR' as RoleEnum,
        PoPoseedor: 'PO_POSEEDOR' as RoleEnum,
        PrGestor: 'PR_GESTOR' as RoleEnum,
        PrPuntorecogida: 'PR_PUNTORECOGIDA' as RoleEnum,
        SddrCc: 'SDDR_CC' as RoleEnum,
        SddrCa: 'SDDR_CA' as RoleEnum,
        PoAportAutofact: 'PO_APORT_AUTOFACT' as RoleEnum,
        AdEnvCons: 'AD_ENV_CONS' as RoleEnum,
        AdEnvGest: 'AD_ENV_GEST' as RoleEnum,
        AgEnvCons: 'AG_ENV_CONS' as RoleEnum,
        AgEnvGest: 'AG_ENV_GEST' as RoleEnum,
        DeEnvCons: 'DE_ENV_CONS' as RoleEnum,
        DeEnvGest: 'DE_ENV_GEST' as RoleEnum,
        PoEnvCons: 'PO_ENV_CONS' as RoleEnum,
        PoEnvGest: 'PO_ENV_GEST' as RoleEnum,
        PrEnvCons: 'PR_ENV_CONS' as RoleEnum,
        PrEnvGest: 'PR_ENV_GEST' as RoleEnum,
        SddrCaEnvCons: 'SDDR_CA_ENV_CONS' as RoleEnum,
        SddrCaEnvGest: 'SDDR_CA_ENV_GEST' as RoleEnum,
        SddrCcEnvCons: 'SDDR_CC_ENV_CONS' as RoleEnum,
        SddrCcEnvGest: 'SDDR_CC_ENV_GEST' as RoleEnum
    };
}


