import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import {
  GestorPtoRecogidaControllerService,
  PuntoRecogidaControllerService,
} from 'api-rest';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import swal from 'sweetalert2';
import { CollectionPointsManagersGeneralDataComponent } from '../collection-points-managers-general-data/collection-points-managers-general-data.component';
import { CollectionPointsManagersAddressDataComponent } from '../collection-points-managers-address-data/collection-points-managers-address-data.component';
import { switchMap } from 'rxjs';
import { NextPageService } from '../../../services/nextPage.service';

@Component({
  selector: 'app-collection-points-managers-detail',
  templateUrl: './collection-points-managers-detail.component.html',
  styleUrls: ['./collection-points-managers-detail.component.scss'],
})
export class CollectionPointsManagersDetailComponent implements OnInit {
  @ViewChild(CollectionPointsManagersGeneralDataComponent, { static: false })
  childCollectionPointsManagersGeneralDataComponent:
    | CollectionPointsManagersGeneralDataComponent
    | undefined;
  @ViewChild(CollectionPointsManagersAddressDataComponent, { static: false })
  childCollectionPointsManagersAddressDataComponent:
    | CollectionPointsManagersAddressDataComponent
    | undefined;

  selectedMenu = 1;
  lastMenu = 1;
  id!: number;

  puntoRecogidaInfo!: any;
  estadoPuntoRecogida!: number | undefined;

  flagRuta: boolean = false;

  menuEnum: any = {
    1: 'ptosrecogida-gestor-detalle',
    2: 'ptosrecogida-gestor-contactos',
    3: 'ptosrecogida-gestor-direcciones',
    4: 'ptosrecogida-gestor-codigos',
    5: 'ptosrecogida-gestor-pdr',
    6: 'ptosrecogida-gestor-documentos',
  };

  pendientePuntoRecogida: boolean = false;
  pendienteEnvalora: boolean = false;
  pendienteCompletado: boolean = false;

  textoEstado: string = '';

  datosCargados = false;

  constructor(
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    public spinnerSrv: SpinnerService,
    private router: Router,
    private snackBarSrv: SnackbarService,
    private route: ActivatedRoute,
    private gestorPtoRecogidaControllerService: GestorPtoRecogidaControllerService,
    private nextPageService: NextPageService
  ) {
    this.route.params.subscribe((params) => {
      this.id = +params['id']; // (+) converts string 'id' to a number
    });
  }

  ngOnInit() {
    this.flagRuta = this.nextPageService.getTempNextSearch;
    this.spinnerSrv.loadingShow();
    this.gestorPtoRecogidaControllerService
      .getByIdGestorPtoRecogida(this.id)
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.puntoRecogidaInfo = response;
          this.datosCargados = true;
          this.estadoPuntoRecogida = this.puntoRecogidaInfo?.estado?.id;
          switch (this.estadoPuntoRecogida) {
            case 1:
              this.pendientePuntoRecogida = true;
              break;
            case 2:
              this.pendienteEnvalora = true;
              break;
            case 3:
              this.pendienteCompletado = true;
              break;
          }
          this.textoEstado = this.puntoRecogidaInfo?.estado?.descripcion!;
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al cargar el gestor del punto recogida',
            'error'
          );
        },
      });
  }

  loadNumMenu(num: number): void {
    this.selectedMenu = num;
    if (this.lastMenu < num) {
      this.lastMenu = num;
    }
  }

  canViewRol(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

  refrescar(menu: number): void {
    this.selectedMenu = menu;
    this.spinnerSrv.loadingShow();
    this.gestorPtoRecogidaControllerService
      .getByIdGestorPtoRecogida(this.id)
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.puntoRecogidaInfo = response;
          this.pendientePuntoRecogida = false;
          this.pendienteEnvalora = false;
          this.pendienteCompletado = false;
          this.estadoPuntoRecogida = this.puntoRecogidaInfo?.estado?.id;
          if (this.canViewRol('PR-GESTOR')) {
            if (this.selectedMenu == 1 && this.estadoPuntoRecogida == 1) {
              this.siguiente();
            }
          }
          switch (this.estadoPuntoRecogida) {
            case 1:
              this.pendientePuntoRecogida = true;
              break;
            case 2:
              this.pendienteEnvalora = true;
              break;
            case 3:
              this.pendienteCompletado = true;
              break;
          }
          this.textoEstado = this.puntoRecogidaInfo?.estado?.descripcion!;
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al cargar el gestor',
            'error'
          );
        },
      });
  }

  siguiente(): void {
    let aux = this.selectedMenu;
    if (aux + 1 <= 4) {
      this.selectedMenu = aux + 1;
    }
  }

  continuarName(menu: number): string {
    switch (menu) {
      case 1:
        return this.authService.can(
          this.menuEnum[menu],
          'access-b-guardar-continuar'
        ) && this.estadoPuntoRecogida == 1
          ? 'Guardar y Continuar '
          : 'Guardar';
        break;
      case 2:
        return this.authService.can(
          this.menuEnum[menu],
          'access-b-continuar'
        ) && this.estadoPuntoRecogida == 1
          ? 'Continuar'
          : '';
        break;
      default:
        return '';
        break;
    }
  }

  canAccessContinuarAll(menu?: number): boolean {
    switch (menu) {
      case 1:
        return (
          this.authService.can(
            this.menuEnum[menu],
            'access-b-guardar-continuar'
          ) && this.estadoPuntoRecogida == 1
        );
      case 2:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-continuar') &&
          this.estadoPuntoRecogida == 1
        );
      default:
        return false;
    }
  }

  canAccessGuardar(menu?: number) {
    switch (menu) {
      case 1:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-guardar') &&
          this.estadoPuntoRecogida &&
          [1, 2, 3].includes(this.estadoPuntoRecogida)
        );
        break;
      default:
        return false;
        break;
    }
  }

  guardar(idMenu?: number) {
    switch (idMenu) {
      case 1:
        if (
          this.canAccessContinuarAll(idMenu) ||
          this.canAccessGuardar(idMenu)
        ) {
          this.childCollectionPointsManagersGeneralDataComponent?.guardar();
        }
        break;
      case 2:
        if (this.verificarObligatorioContactos()) {
          this.snackBarSrv.showSnackBar(
            'Debe completar los datos en los contactos requeridos',
            'error'
          );
        } else {
          this.snackBarSrv.showSnackBar('Contactos correctos', 'success');
          this.siguiente();
        }
        break;
      default:
        break;
    }
  }
  verificarObligatorioContactos() {
    const objetoCampoObligatorios = {
      tipo_contacto: true,
      persona: true,
      nombre: true,
      apellido1: true,
      tipo_documento: false,
      codigo_documento: false,
      tipo_telefono: true,
      prefijoPais: true,
      numero_telefono: true,
      email: true,
      recibir_comunicaciones: true,
      recibir_comunicaciones_contrato: true,
    };

    if (this.puntoRecogidaInfo?.contactos?.length != 0) {
      const tieneCamposObligatoriosVacios = this.puntoRecogidaInfo?.contactos
        ?.filter((objeto: any) => objeto.requerido)
        .map((item: any) => {
          if (item?.tipo_contacto?.id === 3 || item?.tipo_contacto?.id === 4) {
            objetoCampoObligatorios.tipo_documento = true;
            objetoCampoObligatorios.codigo_documento = true;
          } else {
            objetoCampoObligatorios.tipo_documento = false;
            objetoCampoObligatorios.codigo_documento = false;
          }
          if(item?.persona == true){
            objetoCampoObligatorios.apellido1 = true;
          }else{
            objetoCampoObligatorios.apellido1 = false;
          }
          const data = Object.entries(objetoCampoObligatorios).some(
            ([campo, obligatorio]) =>
              obligatorio && (item[campo] === '' || item[campo] === null)
          );
          return data;
        })
        .some((resultado: any) => resultado);
      return tieneCamposObligatoriosVacios;
    } else {
      return false;
    }
  }

  verificarObligatorioDirecciones() {
    // Define un objeto con los campos obligatorios y los inicializa
    const objetoCampoObligatorios = {
      provincia: false,
      codigoPostal: false,
      municipio: false,
      tipoDireccion: false,
      tipoVia: false,
      nombreVia: false,
      numero: false,
      pais: true,
    };

    // Verifica si hay domicilios
    if (this.puntoRecogidaInfo?.domicilios?.length != 0) {
      // Filtra los domicilios requeridos y luego verifica si algún campo obligatorio está vacío
      const tieneCamposObligatoriosVacios = this.puntoRecogidaInfo?.domicilios
        ?.filter((objeto: any) => objeto.requerido) // Filtra los domicilios requeridos
        .map((item: any) => {
          // Si el país es igual a 69, los campos provincia, codigoPostal y municipio son obligatorios
          if (item.pais?.id === 69) {
            objetoCampoObligatorios.provincia = true;
            objetoCampoObligatorios.codigoPostal = true;
            objetoCampoObligatorios.municipio = true;
            objetoCampoObligatorios.nombreVia = true;
            objetoCampoObligatorios.numero = true;
          } else {
            objetoCampoObligatorios.provincia = false;
            objetoCampoObligatorios.codigoPostal = false;
            objetoCampoObligatorios.municipio = false;
            objetoCampoObligatorios.nombreVia = false;
            objetoCampoObligatorios.numero = false;
          }
          return Object.entries(objetoCampoObligatorios).some(
            ([campo, obligatorio]) =>
              obligatorio && (item[campo] === '' || item[campo] === null)
          );
        })
        .some((resultado: any) => resultado); // Verifica si alguno de los resultados es verdadero
      return tieneCamposObligatoriosVacios; // Devuelve si hay campos obligatorios vacíos
    } else {
      return false; // Si no hay domicilios, devuelve falso
    }
  }

  cancelar() {
    swal
      .fire({
        text: '¿Desea cancelar esta acción?. Regresará al buscador.',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.router.navigate(['/collection-points/manager-search']);
        }
      });
  }

  canAccessCancelar(menu: number) {
    switch (menu) {
      case 1:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-cancelar') &&
          this.estadoPuntoRecogida &&
          [1, 2, 3, 4].includes(this.estadoPuntoRecogida)
        );
        break;
      case 2:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-cancelar') &&
          this.estadoPuntoRecogida &&
          [1, 2, 3, 4].includes(this.estadoPuntoRecogida)
        );
        break;
      case 3:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-cancelar') &&
          this.estadoPuntoRecogida &&
          [1, 2, 3, 4].includes(this.estadoPuntoRecogida)
        );
        break;
      case 4:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-cancelar') &&
          this.estadoPuntoRecogida &&
          [1, 2, 3, 4].includes(this.estadoPuntoRecogida)
        );
        break;
      case 5:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-cancelar') &&
          this.estadoPuntoRecogida &&
          [1, 2, 3, 4].includes(this.estadoPuntoRecogida)
        );
        break;
      default:
        return false;
        break;
    }
  }

  canAccessVolver(): boolean | any {
    return (
      this.authService.can('ptosrecogida-gestor-detalle', 'access-b-volver') &&
      this.estadoPuntoRecogida &&
      [5].includes(this.estadoPuntoRecogida)
    );
  }
  canAccessDocumentos(): boolean {
    return this.authService.can('ptosrecogida-gestor-documentos', 'access');
  }

  canAccessCodeLer(): boolean {
    return this.authService.can('ptosrecogida-gestor-codigos', 'access');
  }

  canAccessManagerData(): boolean {
    return this.authService.can('ptosrecogida-gestor-pdr', 'access');
  }

  volver() {
    this.router.navigate(['/collection-points/search']);
  }

  anterior(): void {
    let aux = this.selectedMenu;
    if (aux - 1 > 0) {
      this.selectedMenu = aux - 1;
    }
    if (this.selectedMenu === 1) {
      // this.childHoldersGeneralDataCmp?.inicializar();
    }
  }

  canAccessAnteriorAll(menu: number): boolean {
    switch (menu) {
      case 2:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-anterior') &&
          this.estadoPuntoRecogida == 1
        );
        break;
      case 3:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-anterior') &&
          this.estadoPuntoRecogida == 1
        );
        break;
      default:
        return false;
        break;
    }
  }

  canAccessFinalizar(menu: number): boolean {
    switch (menu) {
      case 3:
        return (
          this.authService.can(this.menuEnum[menu], 'access-b-continuar') &&
          this.estadoPuntoRecogida == 1
        );
        break;
      default:
        return false;
        break;
    }
  }

  mensajeValidacion() {
    this.snackBarSrv.showSnackBar(
      'Para poder desplazarse de una pantalla a otra deberá hacerlo mediante los botones de la parte inferior.'
    );
  }

  finalizar() {
    if (this.verificarObligatorioDirecciones()) {
      this.snackBarSrv.showSnackBar(
        'Debe completar los datos en las direcciones requeridas',
        'error'
      );
    } else {
      this.gestorPtoRecogidaControllerService
        .editByIdGestorPtoRecogida(this.id, this.puntoRecogidaInfo)
        .pipe(
          switchMap(() =>
            this.gestorPtoRecogidaControllerService.actualizarEstadoGestorPtoRecogida(
              this.id,
              2
            )
          )
        )
        .subscribe({
          next: () => {
            this.snackBarSrv.showSnackBar(
              'Proceso finalizado correctamente. Los datos serán revisado por Envalora en los próximos días.',
              'success'
            );
            this.router.navigate(['/initial-page']);
          },
          error: () => {
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al finalizar el registro del puntos de recogida',
              'error'
            );
          },
        });
    }
  }
}
