import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ValidateService } from '../../../services/validate.service';
import { SpinnerService } from '../../../services/spinner.service';
import { MatDialog } from '@angular/material/dialog';
import { ComunicationDialogComponent } from '../comunication-dialog/comunication-dialog.component';
import { ComunicacionControllerService, ComunicacionEstadoControllerService, ComunicacionEstadoDTO, ComunicacionFilterDTO, PageDTOComunicacionDTO, DocumControllerService } from 'api-rest';
import { Combo, ComboService } from '../../../services/combo.service';
import moment from 'moment';
import { DatePipe } from '@angular/common';
import { SnackbarService } from '../../../services/snackbar.service';
import { DownloadService } from '../../../services/download.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import Swal from 'sweetalert2';

@Component({
  selector: 'comunications-comunications-search',
  templateUrl: './comunications-search.component.html',
  styleUrls: ['./comunications-search.component.scss']
})
export class ComunicationsSearchComponent implements OnInit {
  formSearch!: FormGroup;
  tipos!: Combo[];
  estados!: ComunicacionEstadoDTO[];
  categorias!: Combo[];
  prioridades!: Combo[];
  pageNumber: number = 0;
  pageSize: number = 10;
  totalElements: number = 0;
  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  totalSearch: any = 0;

  headArray: any = [
    {
      Head: 'Nº Registro',
      FieldName: 'id'
    },
    {
      Head: 'Email',
      FieldName: 'user',
      Attribute: 'email'
    },
    {
      Head: 'Tipo',
      FieldName: 'tipo',
      renderValue: (item: any) => this.tipos.find(x => item.tipo === x.id)?.name
    },
    {
      Head: 'Asunto',
      FieldName: 'asunto'
    },
    {
      Head: 'Estado',
      FieldName: 'estado',
      Attribute: 'descripcion'
    },
    {
      Head: 'Fecha',
      FieldName: 'fechaAlta',
      renderValue: (item: any) => this.datePipe.transform(item.fechaAlta, 'dd/MM/yyyy')
    },
    {
      Head: 'Prioridad',
      FieldName: 'prioridad',
      renderValue: (item: any) => this.prioridades.find(x => item.prioridad === x.id)?.name
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'view',
          icon: 'open_in_new',
          toolTip: 'Detalle'
        },
        {
          nameButton: 'cancel',
          icon: 'cancel',
          toolTip: 'Cancelar solicitud',
          show: { params: 'canAccessCancel', accion: 'cancel' }
        },
        {
          nameButton: 'donwload',
          icon: 'download',
          toolTip: 'Descargar archivo',
          show: { params: 'canAccessDownload', accion: 'donwload' }
        }
      ],
      width: '10.2',
      permanent: true,
    },
  ];
  gridArray: any[] = [];

  constructor(
    public spinnerSrv: SpinnerService,
    private validateSrv: ValidateService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private comunicacionControllerSrv: ComunicacionControllerService,
    private comunicacionEstadoControllerSrv: ComunicacionEstadoControllerService,
    private datePipe: DatePipe,
    private comboSrv: ComboService,
    private snackBarSrv: SnackbarService,
    private downloadSrv: DownloadService,
    private documControllerSrv: DocumControllerService,
    @Inject('EmpusaAuthenticationService') private authService: EmpusaAuthenticationService) {
    this.formSearch = this.formBuilder.group({
      numRegistro: [{ value: null, disabled: false }],
      email: [{ value: null, disabled: false }],
      fechaDesde: [{ value: null, disabled: false }],
      fechaHasta: [{ value: null, disabled: false }],
      tipo: [{ value: null, disabled: false }],
      estado: [{ value: null, disabled: false }],
      prioridad: [{ value: null, disabled: false }],
      categoria: [{ value: null, disabled: false }],
      asunto: [{ value: null, disabled: false }]
    });
  }

  ngOnInit(): void {
    this.obtenerEstados();
    this.categorias = this.comboSrv.getCategoriasComs();
    this.tipos = this.comboSrv.getTiposComunicaciones();
    this.prioridades = this.comboSrv.getTiposPrioridadesComs();
    if (!this.canAccessComunicacionesFilter()) {
      //Si el usuario no tiene acceso al listado, se precarga la búsqueda de sus comunicaciones
      this.buscar();
    }

  }

  obtenerEstados(): void {
    this.spinnerSrv.loadingShow();
    this.comunicacionEstadoControllerSrv.finddAllComunicacionEstado()
      .subscribe({
        next: (response: Array<ComunicacionEstadoDTO>) => {
          this.spinnerSrv.loadingHide();
          this.estados = response;
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        }
      });
  }

  limpiar(): void {
    this.formSearch.reset();
    this.flagSearch = false;
    this.gridArray = [];

  }
  buscar(): void {
    let filtroComunicacion: ComunicacionFilterDTO;
    if (!this.canAccessComunicacionesFilter()) {
      //Si el usuario no tiene acceso al listado, se piden sus comunicaciones
      filtroComunicacion = {
        email: this.authService.user?.userMail
      };
    } else {
      //Si el usuario tiene acceso al listado se cargan los datos del buscador
      filtroComunicacion = this.getBackendFilter();
    }
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    this.spinnerSrv.loadingShow();
    this.comunicacionControllerSrv.findAllComunicacion1(filtroComunicacion, { page: page, size: this.pageSize }).subscribe({
      next: (data: PageDTOComunicacionDTO) => {
        this.spinnerSrv.loadingHide();
        if (data) {
          this.flagSearch = data.datos?.length != 0 ? true : false;
          this.gridArray = this.canAccessAll(data.datos);
          this.totalElements = data.paginacion?.totalElements ?? 0;
          this.totalSearch = this.gridArray.length;
        }
      },
      error: (error: any) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al buscar las comunicaciones',
          'error'
        );
      },
    });
  }
  getBackendFilter() {
    let fechaIncidencia: Array<string> = [];
    let fechaDesde = this.formSearch.get('fechaDesde')?.value;
    let fechaHasta = this.formSearch.get('fechaHasta')?.value;

    if (fechaDesde) {
      let formatDateDesde = moment(new Date(fechaDesde)).format('YYYY-MM-DD');
      formatDateDesde = `gte(${formatDateDesde})`;
      fechaIncidencia.push(formatDateDesde);
    }
    if (fechaHasta) {
      let formatDateHasta = moment(
        new Date(this.formSearch.get('fechaHasta')?.value)
      ).format('YYYY-MM-DD');
      if (fechaDesde) {
        formatDateHasta = `and(lte(${formatDateHasta}))`;
      } else {
        formatDateHasta = `lte(${formatDateHasta})`;
      }
      fechaIncidencia.push(formatDateHasta);
    }

    const backendFilter: any = {
      fechaIncidencia: fechaIncidencia,
      id: this.formSearch.get('numRegistro')?.value,
      email: this.formSearch.get('email')?.value,
      tipo: this.formSearch.get('tipo')?.value,
      estado: this.formSearch.get('estado')?.value,
      prioridad: this.formSearch.get('prioridad')?.value,
      categoria: this.formSearch.get('categoria')?.value,
      asunto: this.formSearch.get('asunto')?.value !== null ? [`ci(${this.formSearch.get('asunto')?.value})`] : []
    };


    return backendFilter;
  }
  exportarCsv(): void {
    let filtroComunicacion: ComunicacionFilterDTO;
    if (!this.canAccessComunicacionesFilter()) {
      //Si el usuario no tiene acceso al listado, se piden sus comunicaciones
      filtroComunicacion = {
        email: this.authService.user?.userMail
      };
    } else {
      //Si el usuario tiene acceso al listado se cargan los datos del buscador
      filtroComunicacion = this.getBackendFilter();
    }

    this.spinnerSrv.loadingShow();
    this.comunicacionControllerSrv.exportComunicacion(filtroComunicacion, 'response')
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          const filename = this.downloadSrv.getFileName(
            response.headers,
            'Exportacion_Tarifa_SDDR_Cerrado.csv'
          );
          this.downloadSrv.downloadBlob(response.body!, filename!);
        },
        error: (err: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al exportar las comunicaciones',
            'error'
          );
        },
      });
  }

  action(item: any) {
    if (item.nameButton == 'view') {
      this.abrirModalComunicacion(item);
    } else if (item.nameButton == 'cancel') {
      this.confirmacionCancelar(item)
    } else if (item.nameButton == 'donwload') {
      this.descargar(item?.documentoIncidencia)
    }
  }

  confirmacionCancelar(element?: any): void {
    Swal.fire({
      text: `¿Desea cancelar la comunicación?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.cancelarSolicitud(element);
      }
    });
  }

  // Descargar fichero de otros datos
  descargar(element: any) {
    this.spinnerSrv.loadingShow();
    this.documControllerSrv.downloadDoc(element.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Se ha descargado el documento correctamente',
          'success'
        );
        this.downloadSrv.downloadBlob(response, element.nombre);
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al descargar el documento',
          'error'
        );
      },
    });
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formSearch.controls[controlName]?.hasError(errorName) &&
      this.formSearch.controls[controlName]?.touched
    );
  }
  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.formSearch.get(formName)?.setValue(null);
  }
  showClearButton(formName: string): boolean {
    return this.formSearch.get(formName)?.value !== null;
  }
  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.buscar();
  }

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.buscar();
    }
  }
  abrirModalComunicacion(comunicacion?: any): void {
    let soloLectura: boolean = true;
    // Modal en modo edición si es ADM-COMUNICACIONES y el estado es 4, o si no es ADM-COMUNICACIONES y el estado es 3
    if (comunicacion && ((comunicacion?.estado === 4 && this.isAdmComunicaciones()) || (comunicacion?.estado === 3 && !this.isAdmComunicaciones()))) {
      soloLectura = false;
    } else if (!comunicacion) {
      soloLectura = false;
    }
    soloLectura = false;
    const dialogRef = this.dialog.open(
      ComunicationDialogComponent,
      {
        width: '1050px',
        maxWidth: '95%',
        maxHeight: '95vh',
        data: {
          comunicacion: comunicacion,
          soloLectura: soloLectura,
          estados: this.estados
        },
        disableClose: true
      }
    );
    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.buscar();
        }
      }
    );
  }

  cancelarSolicitud(comunicacion?: any): void {
    this.comunicacionControllerSrv.cancelar(comunicacion.id)
      .subscribe({
        next: (res) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'La comunicación se ha cancelado correctamente.',
            'success'
          );
          this.buscar();
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al cancelar la comunicación',
            'error'
          );
        }
      });
  }

  canAccessAll(list: any) {
    const listPermission = list.map((objeto: any) => {
      const stateCanCancel = objeto?.estado?.id === 1 ? true : false;
      const hayFichero = objeto?.documentoIncidencia?.id ? true : false;
      return {
        ...objeto,
        canAccessCancel: !this.isAdmComunicaciones() && stateCanCancel,
        canAccessDownload: hayFichero
      };
    });
    return listPermission;
  }



  canAccessComunicacionesFilter(): boolean {
    return this.authService.can('comunicaciones', 'filter');
  }
  canAccessComunicacionesExportar(): boolean {
    return this.authService.can('comunicaciones', 'update-exportar');
  }
  canAccessComunicacionesView(): boolean {
    return this.authService.can('comunicaciones', 'view');
  }
  //TODO ojo estos van ligados a estados
  canAccessComunicacionesUpdate(): boolean {
    return this.authService.can('comunicaciones', 'update');
  }
  canAccessComunicacionesCancel(): boolean {
    return this.authService.can('comunicaciones', 'update-cancel');
  }

  isAdmComunicaciones(): boolean {
    return this.authService.hasCurrentUserRole('ADM-COMUNICACIONES');
  }

}
