import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import {
  DocumControllerService,
  FacturaDTO,
  SolicitudFacturaExportacionControllerService,
  SolicitudFacturaExportacionDTO,
  SolicitudFacturaExportacionPayloadDTO,
  SolicitudReutilizacionAcreditacionControllerService,
  SolicitudReutilizacionAcreditacionDTO,
  SolicitudReutilizacionAcreditacionPayloadDTO,
  SolicitudReutilizacionCompletaPayloadDTO,
  SolicitudReutilizacionControllerService,
  SolicitudReutilizacionDTO,
  SolicitudReutilizacionPayloadDTO,
  SubTipoEnvaseControllerService,
  SubTipoEnvaseDTO,
  SubTipoEnvaseFilterDTO,
  TipoEnvasesControllerService,
  TipoEnvasesDTO,
  UsuarioSDDRCaDTO,
  AdheridoControllerService
} from 'api-rest';
import { Combo, ComboService } from '../../../services/combo.service';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { SpinnerService } from '../../../services/spinner.service';
import { ValidateService } from '../../../services/validate.service';
import { zip } from 'rxjs';
import Swal from 'sweetalert2';
import moment from 'moment';
import { ParseCurrencyPipe } from '../../../share/pipe/ParseCurrency.pipe';
import { FormatCurrencyPipe } from '../../../share/pipe/FormatCurrency.pipe';


@Component({
  selector: 'open-sddr-open-ssdr-reuse-dialog',
  templateUrl: './open-ssdr-reuse-dialog.component.html',
  styleUrls: ['./open-ssdr-reuse-dialog.component.scss']
})
export class OpenSsdrReuseDialogComponent implements OnInit {
  formGroup!: FormGroup;
  formGroupFacturas!: FormGroup;
  formGroupDocumentos!: FormGroup;
  formGroupInfoSolicitud!: FormGroup;
  solicitud: any;
  solicitudId!: number;
  soloLectura: boolean;
  titulo: string = '';
  tiposEnvases!: TipoEnvasesDTO[];
  subtiposEnvases!: SubTipoEnvaseDTO[];
  usosEnvases!: Combo[];
  lugaresReutilizacion!: any[];
  adjuntosBlobFacturas: Array<File> = [];
  adjuntosBlobDocumentos: Array<File> = [];
  gridDocumentos: Array<any> = [];
  gridFacturas: Array<any> = [];
  hayDocumento: boolean = true;
  hayFactura: boolean = true;
  tiposUsusariosSddr!: UsuarioSDDRCaDTO[];
  headArrayDocumentos: any[] = [
    {
      Head: 'Tipo documento',
      FieldName: 'tipoDocumento',
    },
    {
      Head: 'Documento',
      FieldName: 'documento',
      Attribute: 'name'
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        }, {
          nameButton: 'download',
          icon: 'download',
          toolTip: 'Descargar',
          show: { params: 'canAccessDownload', accion: 'download' },
        }
      ],
      width: '10',

      permanent: true,
    },
  ];
  headArrayFacturas: any[] = [
    {
      Head: 'Número de factura depósito',
      FieldName: 'numFactura',
    },
    {
      Head: 'Documento',
      FieldName: 'documento',
      Attribute: 'name'
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        }, {
          nameButton: 'download',
          icon: 'download',
          toolTip: 'Descargar',
          show: { params: 'canAccessDownload', accion: 'download' },
        },
      ],
      width: '10',

      permanent: true,
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<OpenSsdrReuseDialogComponent>,
    public spinnerSrv: SpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private comboSrv: ComboService,
    private snackBarSrv: SnackbarService,
    private downloadSrv: DownloadService,
    private tipoEnvasesCtrSrv: TipoEnvasesControllerService,
    private subtipoEnvaseCtrSrv: SubTipoEnvaseControllerService,
    @Inject('EmpusaAuthenticationService') private authService: EmpusaAuthenticationService,
    private validateSrv: ValidateService,
    private solicitudReutilizacionCtrlSrv: SolicitudReutilizacionControllerService,
    private parseCurrencyPipe: ParseCurrencyPipe,
    private formatCurrencyPipe: FormatCurrencyPipe,
    private solicitudReutilizacionAcreditacionCtrlSrv: SolicitudReutilizacionAcreditacionControllerService,
    private solicitudFacturaExportacionCtrlSrv: SolicitudFacturaExportacionControllerService,
    private documCtrlSrv: DocumControllerService,
    private adheridoCtrlSrv: AdheridoControllerService
  ) {
    this.solicitudId = this.data.solicitudId;
    this.soloLectura = this.data.soloLectura;
    this.formGroup = this.formBuilder.group({
      usuarioSddr: [{ value: null, disabled: this.soloLectura }, [Validators.required]],
      tipoEnvase: [{ value: null, disabled: this.soloLectura }, [Validators.required]],
      //subtipoEnvase: [{ value: null, disabled: this.soloLectura }, [Validators.required]],
      numEnvases: [{ value: null, disabled: this.soloLectura }, {
        validators: Validators.compose([
          Validators.required,
          Validators.maxLength(255)
        ])
      }],
      usoEnvase: [{ value: null, disabled: this.soloLectura }, [Validators.required]],
      loteEnvase: [{ value: null, disabled: this.soloLectura } ], //,[Validators.maxLength(255)]
      aceptaCondiciones: [{ value: null, disabled: this.soloLectura }],
      numRegistroProductor: [{ value: null, disabled: this.soloLectura }, [this.formatoValidator()]],
      aceptaExportacion: [{ value: null, disabled: this.soloLectura }],
      reutilizar: [{ value: null, disabled: this.soloLectura }],
      aceptaDevolucion: [{ value: null, disabled: this.soloLectura }],
      certificadoPago: [{ value: null, disabled: this.soloLectura }],
      autorizacionEnvalora: [{ value: null, disabled: this.soloLectura }],
    });
    this.formGroupFacturas = this.formBuilder.group({
      importeDeposito: [{ value: null, disabled: true }],
      numFacturaDeposito: [{ value: null, disabled: this.soloLectura }],
    });
    this.formGroupDocumentos = this.formBuilder.group({
      tipoDocumento: [{ value: null, disabled: this.soloLectura }],
    });
    this.formGroupInfoSolicitud = this.formBuilder.group({
      usuario: [{ value: null, disabled: true }],
      fecha: [{ value: null, disabled: true }],
      estado: [{ value: null, disabled: true }]
    });
  }
  ngOnInit(): void {
    this.cargarCombos();

    if (this.solicitudId) {
      this.titulo = 'Detalle de solicitud de reutilización – ID: ' + this.solicitudId!;
      this.cargarSolicitud(this.solicitudId);
    } else {
      this.titulo = 'Alta solicitud de reutilización';
    }
  }
  cargarSolicitud(idSolicitud: number): void {
    this.spinnerSrv.loadingShow();
    this.solicitudReutilizacionCtrlSrv.getById(idSolicitud)
      .subscribe({
        next: (response: SolicitudReutilizacionDTO) => {
          this.spinnerSrv.loadingHide();
          this.solicitud = response;
          this.cargarDatos(this.solicitud);
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  cargarDatos(solicitud: SolicitudReutilizacionDTO): void {
    this.formGroup.patchValue({
      tipoEnvase: solicitud?.tipoEnvase?.id,
      //subtipoEnvase: solicitud?.subTipoEnvase?.id,
      numEnvases: this.formatCurrencyPipe.transform(solicitud?.numeroEnvases),
      usoEnvase: solicitud?.usoEnvase,
      loteEnvase: solicitud?.loteEnvase
    });
    if (this.solicitud?.poseedor) {
      this.formGroup.get('usuarioSddr')?.setValue(this.solicitud?.poseedor?.id + 'POSEEDOR');
      this.cargarComboUsoEnvase(this.solicitud?.poseedor, false);
    } else if (this.solicitud?.adherido) {
      this.formGroup.get('usuarioSddr')?.setValue(this.solicitud?.adherido?.id + 'ADHERIDO');
      this.cargarComboUsoEnvase(this.solicitud?.adherido, true);
    }
    this.seleccionUsoEnvase();
    this.seleccionTipoEnvase();
    if (this.formGroup.get('usoEnvase')?.value === 'REUTILIZACION_EXPORTACION') {
      this.formGroup.get('aceptaExportacion')?.setValue(solicitud?.exportacion);
      this.formGroup.get('aceptaDevolucion')?.setValue(solicitud?.condicionesDevolucion);
      this.formGroup.get('reutilizar')?.setValue(solicitud?.lugarReutilizacion);
      //this.formGroupFacturas.get('importeDeposito')?.setValue(this.formatCurrencyPipe.transform(solicitud?.importeDeposito));
      this.formGroup.get('certificadoPago')?.setValue(solicitud?.certificadoPago);
      this.formGroup.get('autorizacionEnvalora')?.setValue(solicitud?.autorizacionEnvalora);
      this.obtenerDocumentosFacturas();

    } else if (this.formGroup.get('usoEnvase')?.value === 'REUTILIZACION_EN_ESPAÑA_SDDRCA') {
      this.formGroup.get('aceptaCondiciones')?.setValue(solicitud?.aceptacionCondiciones);
      this.formGroup.get('numRegistroProductor')?.setValue(solicitud?.numRegistroProductor);
    }
    if (this.solicitud?.estado?.id > 1) {
      let usuario!: any;
      if (this.solicitud?.adherido) {
        usuario = this.solicitud.adherido;
      } else if (this?.solicitud?.poseedor) {
        usuario = this.solicitud.poseedor
      }
      let fecha = "";
      if (solicitud?.fechaAlta) {
        fecha = moment(new Date(solicitud?.fechaAlta)).format('DD/MM/YYYY');
      }
      this.formGroupInfoSolicitud.patchValue({
        usuario: usuario?.razonSocial,
        fecha: fecha,
        estado: solicitud?.estado?.descripcion
      });
    }

  }
  seleccionTipoEnvase() {
    const subtipoEnvaseFilter: SubTipoEnvaseFilterDTO = { tipoEnvase: { id: this.formGroup.get('tipoEnvase')?.value, sddrca: true } };
    this.spinnerSrv.loadingShow();

    this.subtipoEnvaseCtrSrv.listarSubTipoEnvases(subtipoEnvaseFilter, { page: 0, size: 100 })
      .subscribe({
        next: (response: any) => {
          this.spinnerSrv.loadingHide();
          this.subtiposEnvases = response.datos
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });

    this.solicitudReutilizacionCtrlSrv.getTarifaPorEnvase(this.formGroup.get('tipoEnvase')?.value)
    .subscribe({
      next: (response: any) => {
        this.spinnerSrv.loadingHide();
        this.formGroupFacturas.get('importeDeposito')?.setValue(this.formatCurrencyPipe.transform(response?.depositoUnidad));
      },
      error: (error: any) => {
        this.spinnerSrv.loadingHide();
      },
    });

  }

  obtenerDocumentosFacturas() {
    this.spinnerSrv.loadingShow();
    zip(
      this.solicitudReutilizacionAcreditacionCtrlSrv.findAll({ solicitudReutilizacion: this.solicitudId }, { page: 0, size: 100 }),
      this.solicitudFacturaExportacionCtrlSrv.findAll2({ solicitud: this.solicitudId, tipoSolicitud: 'REUTILIZACION' }, { page: 0, size: 100 })
    ).subscribe({
      next: (responses: any[]) => {
        this.spinnerSrv.loadingHide();
        let documentos = responses[0].datos;
        let facturas = responses[1].datos;
        documentos.forEach((doc: SolicitudReutilizacionAcreditacionDTO) => {
          this.gridDocumentos.push({
            id: doc.id,
            key: doc?.id,
            tipoDocumento: doc?.tipoDocumento,
            datosDoc: doc?.documento,
            documento: { name: doc?.documento?.nombre }
          });
        });

        facturas.forEach((fac: SolicitudFacturaExportacionDTO) => {
          this.gridFacturas.push({
            id: fac?.id,
            key: fac?.id,
            numFactura: fac?.numFacturaDeposito,
            datosDoc: fac?.documento,
            documento: { name: fac?.documento?.nombre }
          });
        });
        this.gridFacturas = this.canAccessAll([...this.gridFacturas]);
        this.gridDocumentos = this.canAccessAll([...this.gridDocumentos]);
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error obtener las facturas y documentos.',
          'error'
        );
      },
    });
  }

  cargarCombos(): void {
    //this.usosEnvases = this.comboSrv.getUsosEnvases();
    this.lugaresReutilizacion = this.comboSrv.getReutilizacionesEnvases();
    const tipoEnvaseFilter = { sddrca: true };
    //const subtipoEnvaseFilter: SubTipoEnvaseFilterDTO = { tipoEnvase: { sddrca: true } };
    this.spinnerSrv.loadingShow();
    zip(
      this.tipoEnvasesCtrSrv.findAll(tipoEnvaseFilter, { page: 0, size: 100 }),
      //this.subtipoEnvaseCtrSrv.listarSubTipoEnvases(subtipoEnvaseFilter, { page: 0, size: 100 }),
      this.solicitudReutilizacionCtrlSrv.findAllUsuariosAsociados()
    ).subscribe({
      next: (responses: any[]) => {
        this.spinnerSrv.loadingHide();
        this.tiposEnvases = responses[0].datos;
        //this.subtiposEnvases = responses[1].datos;
        this.tiposUsusariosSddr = responses[1];
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
      },
    });
  }


  cerrar(): void {
    this.spinnerSrv.loading = 0;
    this.dialogRef.close(null);
  }
  obtenerPayloadSolicitud(): SolicitudReutilizacionPayloadDTO {
    let payload: SolicitudReutilizacionPayloadDTO = {
      tipoEnvase: { id: this.formGroup.get('tipoEnvase')?.value },
      //subTipoEnvase: { id: this.formGroup.get('subtipoEnvase')?.value },
      numeroEnvases: this.parseCurrencyPipe.transform(this.formGroup.get('numEnvases')?.value),
      loteEnvase: this.formGroup.get('loteEnvase')?.value,
      usoEnvase: this.formGroup.get('usoEnvase')?.value,
      aceptacionCondiciones: this.formGroup.get('aceptaCondiciones')?.value || false,
      numRegistroProductor: this.formGroup.get('numRegistroProductor')?.value,
      exportacion: this.formGroup.get('aceptaExportacion')?.value,
      lugarReutilizacion: this.formGroup.get('reutilizar')?.value,
      condicionesDevolucion: this.formGroup.get('aceptaDevolucion')?.value,
      certificadoPago: this.formGroup.get('certificadoPago')?.value,
      autorizacionEnvalora: this.formGroup.get('autorizacionEnvalora')?.value,
      importeDeposito: this.parseCurrencyPipe.transform(this.formGroupFacturas.get('importeDeposito')?.value)
    }
    if (this.formGroup.get('usuarioSddr')?.value) {
      const usuario = this.tiposUsusariosSddr.find((x: UsuarioSDDRCaDTO) => (x.id! + x.tipoUsuario!) === this.formGroup.get('usuarioSddr')?.value);
      if (usuario?.tipoUsuario === 'POSEEDOR') {
        payload.poseedor = { id: usuario?.id }
      } else if (usuario?.tipoUsuario === 'ADHERIDO') {
        payload.adherido = { id: usuario?.id }
      }
    }
    return payload;
  }
  obtenerPayloadFacturas(): any {
    let payloadFacturas: SolicitudFacturaExportacionPayloadDTO[] = [];
    let facturasBlob: Array<Blob> = [];
    this.gridFacturas.forEach(factura => {
      payloadFacturas.push({
        id: factura?.id,
        numFacturaDeposito: factura?.numFactura,
        tipoSolicitud: 'REUTILIZACION',
        solicitud: this.solicitudId
      });
      if (factura?.id === -1) {
        facturasBlob.push(factura?.documento);
      }

    });
    return { payloadFacturas: payloadFacturas, facturasBlob: facturasBlob };
  }

  obtenerPayloadDocumentos(): any {
    let payloadDocumentos: SolicitudReutilizacionAcreditacionPayloadDTO[] = [];
    let documentosBlob: Array<Blob> = [];

    this.gridDocumentos.forEach(documento => {
      payloadDocumentos.push({
        id: documento?.id,
        solicitudReutilizacion: this.solicitudId,
        tipoDocumento: documento.tipoDocumento
      });
      if (documento?.id === -1) {
        documentosBlob.push(documento?.documento)
      }

    });
    return { payloadDocumentos: payloadDocumentos, documentosBlob: documentosBlob };
  }

  guardarBorrador(): void {
    if (!this.esFormularioValido()) {
      this.snackBarSrv.showSnackBar(
        'Complete los campos obligatorios.',
        'error'
      );
    //} else if (!this.documentosFacturasValidas()) {
    //  this.snackBarSrv.showSnackBar(
    //    'Rellene al menos una factura y un documento de acreditación',
    //    'error'
    //  );
    } else {
      const payloadFacturas = this.obtenerPayloadFacturas();
      const payloadDocumentos = this.obtenerPayloadDocumentos();
      const payloadCompleto: SolicitudReutilizacionCompletaPayloadDTO = {
        solicitudReutilizacionPayload: this.obtenerPayloadSolicitud(),
        payloadFacExportacionList: payloadFacturas.payloadFacturas,
        payloadAcreditacionList: payloadDocumentos.payloadDocumentos
      }
      let request: any;
      if (this.solicitudId) {
        request = this.solicitudReutilizacionCtrlSrv.editById1(this.solicitudId, payloadCompleto, payloadFacturas.facturasBlob, payloadDocumentos.documentosBlob)
      } else {
        request = this.solicitudReutilizacionCtrlSrv.create1(payloadCompleto, payloadFacturas.facturasBlob, payloadDocumentos.documentosBlob);
      }
      this.spinnerSrv.loadingShow();
      request
        .subscribe({
          next: (value: any) => {
            this.spinnerSrv.loadingHide();
            this.dialogRef.close(true);
            this.snackBarSrv.showSnackBar(
              'Se ha guardado el borrador de la solicitud de reutilización correctamente.',
              'success'
            );
          },
          error: (error: any) => {
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al guardar el borrador de la solicitud de reutilización ',
              'error'
            );
            this.spinnerSrv.loadingHide();
          },
        });

    }
  }

  guardarEnviar(): void {
    if (!this.esFormularioValido()) {
      this.snackBarSrv.showSnackBar(
        'Complete los campos obligatorios.',
        'error'
      );
    //} else if (!this.documentosFacturasValidas()) {
    //  this.snackBarSrv.showSnackBar(
    //    'Rellene al menos una factura y un documento de acreditación',
    //    'error'
    //  );
    } else {
      const payloadFacturas = this.obtenerPayloadFacturas();
      const payloadDocumentos = this.obtenerPayloadDocumentos();
      const payloadCompleto: SolicitudReutilizacionCompletaPayloadDTO = {
        solicitudReutilizacionPayload: this.obtenerPayloadSolicitud(),
        payloadFacExportacionList: payloadFacturas.payloadFacturas,
        payloadAcreditacionList: payloadDocumentos.payloadDocumentos
      }
      let request: any;
      if (this.solicitudId) {
        request = this.solicitudReutilizacionCtrlSrv.editByIdAndSend(this.solicitudId, payloadCompleto, payloadFacturas.facturasBlob, payloadDocumentos.documentosBlob);
      } else {
        request = this.solicitudReutilizacionCtrlSrv.saveAndSend(payloadCompleto, payloadFacturas.facturasBlob, payloadDocumentos.documentosBlob);
      }
      this.spinnerSrv.loadingShow();
      request
        .subscribe({
          next: (value: any) => {
            this.spinnerSrv.loadingHide();
            this.dialogRef.close(true);
            this.snackBarSrv.showSnackBar(
              'Se ha guardado y enviado la solicitud de reutilización correctamente.',
              'success'
            );
          },
          error: (error: any) => {
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al guardar y enviar la solicitud de reutilización ',
              'error'
            );
            this.spinnerSrv.loadingHide();
          },
        });
    }
  }
  esFormularioValido(): boolean {
    let valid = false;
    this.formGroup.markAllAsTouched();
    //this.formGroupFacturas.get('importeDeposito')?.markAllAsTouched();
    if (this.formGroup.valid) { // && this.formGroupFacturas.get('importeDeposito')?.valid
      valid = true;
    }
    return valid;
  }

  documentosFacturasValidas(): boolean {
    let valid = true;
    if (this.formGroup.get('usoEnvase')?.value === 'REUTILIZACION_EXPORTACION' && (this.gridDocumentos?.length == 0 || this.gridFacturas?.length == 0)) {
      valid = false;
    }
    return valid;
  }

  controlHasError(controlName: string, errorName: string, form: FormGroup) {
    return (
      form.controls[controlName]?.hasError(errorName) &&
      form.controls[controlName]?.touched
    );
  }

  attachAdjuntoFacturas(archivo: File | null): void {
    if (archivo != null) {
      this.hayFactura = true;
      if (archivo.size === 0) {
        this.snackBarSrv.showSnackBar(
          'No se puede subir un fichero vacío',
          'error'
        );
      } else if (archivo.size > 5 * 1024 * 1024) { // 5 MB
        this.snackBarSrv.showSnackBar(
          'El fichero supera el tamaño máximo permitido de 5 MB',
          'error'
        );
      } else {
        this.adjuntosBlobFacturas.push(archivo);
      }
    } else {
      this.hayFactura = false;
    }
  }
  descartarDoc(archivo: Blob) {
    this.adjuntosBlobDocumentos = this.adjuntosBlobDocumentos.filter(adjBlob => adjBlob !== archivo);
  }
  descartarFac(archivo: Blob) {
    this.adjuntosBlobFacturas = this.adjuntosBlobFacturas.filter(adjBlob => adjBlob !== archivo);
  }
  seleccionUsoEnvase() {
    if (this.formGroup.get('usoEnvase')?.value === 'REUTILIZACION_EXPORTACION') {
      this.formGroupFacturas.get('importeDeposito')?.setValidators(Validators.compose([
        Validators.maxLength(255)
      ]));
      this.formGroupFacturas.get('numFacturaDeposito')?.setValidators(Validators.compose([
        Validators.required,
        Validators.pattern(this.validateSrv.numericPattern),
        Validators.maxLength(255)
      ]));
      this.formGroupDocumentos.get('tipoDocumento')?.setValidators(Validators.compose([
        Validators.required,
        Validators.maxLength(255)
      ]));
      //this.formGroup.get('aceptaExportacion')?.setValidators([Validators.required]);
      this.formGroup.get('aceptaDevolucion')?.setValidators([Validators.required]);
      this.formGroup.get('certificadoPago')?.setValidators([Validators.required]);
      this.formGroup.get('autorizacionEnvalora')?.setValidators([Validators.required]);
      //this.formGroup.get('aceptaCondiciones')?.clearValidators();
      //this.formGroup.get('aceptaCondiciones')?.updateValueAndValidity();

    } else if (this.formGroup.get('usoEnvase')?.value === 'REUTILIZACION_EN_ESPAÑA_SDDRCA') {
      this.formGroupFacturas.clearValidators();
      this.formGroupDocumentos.clearValidators();
      //this.formGroup.get('aceptaCondiciones')?.setValidators([Validators.required]);
      this.formGroup.get('aceptaExportacion')?.clearValidators();
      this.formGroup.get('certificadoPago')?.clearValidators();
      this.formGroup.get('autorizacionEnvalora')?.clearValidators();
      this.formGroup.get('aceptaDevolucion')?.clearValidators();
      this.formGroup.get('aceptaExportacion')?.updateValueAndValidity();
      this.formGroup.get('autorizacionEnvalora')?.updateValueAndValidity();
      this.formGroup.get('certificadoPago')?.updateValueAndValidity();
      this.formGroup.get('aceptaDevolucion')?.updateValueAndValidity();
      this.formGroupFacturas.get('importeDeposito')?.clearValidators();
      this.formGroupFacturas.get('numFacturaDeposito')?.clearValidators();
      this.formGroupFacturas.get('importeDeposito')?.updateValueAndValidity();
      this.formGroupFacturas.get('numFacturaDeposito')?.updateValueAndValidity();
      this.formGroupDocumentos.get('tipoDocumento')?.clearValidators();
      this.formGroupDocumentos.get('tipoDocumento')?.updateValueAndValidity();

    } else {
      this.formGroupFacturas.clearValidators();
      this.formGroupDocumentos.clearValidators();
      //this.formGroup.get('aceptaCondiciones')?.clearValidators();
      this.formGroup.get('aceptaExportacion')?.clearValidators();
      this.formGroup.get('certificadoPago')?.clearValidators();
      this.formGroup.get('autorizacionEnvalora')?.clearValidators();
      this.formGroup.get('aceptaDevolucion')?.clearValidators();
      //this.formGroup.get('aceptaCondiciones')?.updateValueAndValidity();
      this.formGroup.get('aceptaExportacion')?.updateValueAndValidity();
      this.formGroup.get('certificadoPago')?.updateValueAndValidity();
      this.formGroup.get('autorizacionEnvalora')?.updateValueAndValidity();
      this.formGroup.get('aceptaDevolucion')?.updateValueAndValidity();
      this.formGroupFacturas.get('importeDeposito')?.clearValidators();
      this.formGroupFacturas.get('numFacturaDeposito')?.clearValidators();
      this.formGroupFacturas.get('importeDeposito')?.updateValueAndValidity();
      this.formGroupFacturas.get('numFacturaDeposito')?.updateValueAndValidity();
      this.formGroupDocumentos.get('tipoDocumento')?.clearValidators();
      this.formGroupDocumentos.get('tipoDocumento')?.updateValueAndValidity();
    }
    
    if(this.formGroup.get('usoEnvase')?.value === 'REUTILIZACION_EN_ESPAÑA_SDDRCA' ||
      this.formGroup.get('usoEnvase')?.value === 'REUTILIZACION_EXPORTACION' ||
      this.formGroup.get('usoEnvase')?.value === 'REUTILIZACION_EN_ESPAÑA_SDDRCC' ||
      this.formGroup.get('usoEnvase')?.value === 'REUTILIZACION_OTROS_SISTEMAS_SIRAP'){

      this.formGroup.get('aceptaCondiciones')?.setValidators([Validators.required]);
      this.formGroup.get('aceptaCondiciones')?.updateValueAndValidity();
    }else{
      this.formGroup.get('aceptaCondiciones')?.clearValidators();
      this.formGroup.get('aceptaCondiciones')?.updateValueAndValidity();
    }
  }

  anadirFactura() {
    if (this.formGroupFacturas.get('numFacturaDeposito')?.invalid) {
      this.formGroupFacturas.get('numFacturaDeposito')?.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Revisar el campo Número de factura depósito',
        'error'
      );
      return;
    }
    if (this.adjuntosBlobFacturas?.length == 0) {
      this.hayFactura = false;
      this.snackBarSrv.showSnackBar(
        'Revisar el campo Documento',
        'error'
      );
      return;
    }
    const obj = {
      key: Math.random(),
      id: -1,
      numFactura: this.formGroupFacturas.get('numFacturaDeposito')?.value,
      documento: this.adjuntosBlobFacturas[0],
    };
    this.gridFacturas.unshift(obj);
    this.gridFacturas = this.canAccessAll([...this.gridFacturas]);
    this.formGroupFacturas.get('numFacturaDeposito')?.setValue(null);
    this.formGroupFacturas.get('numFacturaDeposito')?.markAsPristine();
    this.formGroupFacturas.get('numFacturaDeposito')?.markAsUntouched();
    this.adjuntosBlobFacturas = [];
  }

  attachAdjuntoDocumentos(archivo: File | null): void {
    if (archivo != null) {
      this.hayDocumento = true;
      if (archivo.size === 0) {
        this.snackBarSrv.showSnackBar(
          'No se puede subir un fichero vacío',
          'error'
        );
      } else if (archivo.size > 5 * 1024 * 1024) { // 5 MB
        this.snackBarSrv.showSnackBar(
          'El fichero supera el tamaño máximo permitido de 5 MB',
          'error'
        );
      } else {
        this.adjuntosBlobDocumentos.push(archivo);
      }
    } else {
      this.hayDocumento = false;
    }
  }
  actionFacturas(event: any) {
    if (event.nameButton == 'delete') {
      this.confirmacionEliminarFactura(event);
    } else if (event.nameButton == 'download') {
      this.descargar(event);
    }
  }
  confirmacionEliminarFactura(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar la factura?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.snackBarSrv.showSnackBar(
          `Se ha eliminando correctamente.`,
          'success'
        );

        this.gridFacturas = this.gridFacturas.filter(
          (objeto) => objeto.key !== element.key
        );

      }
    });
  }

  anadirDocumento() {
    if (this.formGroupDocumentos.get('tipoDocumento')?.invalid) {
      this.formGroupDocumentos.get('tipoDocumento')?.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Revisar el campo Tipo documento',
        'error'
      );
      return;
    }
    if (this.adjuntosBlobDocumentos?.length == 0) {
      this.hayDocumento = false;
      this.snackBarSrv.showSnackBar(
        'Revisar el campo Documento',
        'error'
      );
      return;
    }
    const obj = {
      id: -1,
      key: Math.random(),
      tipoDocumento: this.formGroupDocumentos.get('tipoDocumento')?.value,
      documento: this.adjuntosBlobDocumentos[0],
    };
    this.gridDocumentos.unshift(obj);
    this.gridDocumentos = this.canAccessAll([...this.gridDocumentos]);
    this.formGroupDocumentos.get('tipoDocumento')?.setValue(null);
    this.formGroupDocumentos.get('tipoDocumento')?.markAsPristine();
    this.formGroupDocumentos.get('tipoDocumento')?.markAsUntouched();
    this.adjuntosBlobDocumentos = [];

  }

  actionDocumentos(event: any) {
    if (event.nameButton == 'delete') {
      this.confirmacionEliminarDocumento(event);
    } else if (event.nameButton == 'download') {
      this.descargar(event);
    }
  }

  confirmacionEliminarDocumento(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar el documento?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.snackBarSrv.showSnackBar(
          `Se ha eliminando correctamente.`,
          'success'
        );
        this.gridDocumentos = this.gridDocumentos.filter(
          (objeto) => objeto.key !== element.key
        );
      }
    });
  }

  descargarAutofactura(autofactura: FacturaDTO) {
    if (autofactura?.documentoAutofactura?.id) {
      let nombre = autofactura?.documentoAutofactura?.nombre ? autofactura?.documentoAutofactura?.nombre : 'prefactura.pdf'
      this.descargarDocumento(autofactura?.documentoAutofactura?.id, nombre)
    }
  }
  descargar(doc: any) {
    this.descargarDocumento(doc.datosDoc?.id!, doc?.documento?.name!)
  }
  descargarDocumento(id: number, nombre: string) {
    this.spinnerSrv.loadingShow();
    this.documCtrlSrv.downloadDoc(id)
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha descargado el documento correctamente',
            'success'
          );
          this.downloadSrv.downloadBlob(response, nombre);
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al descargar el documento',
            'error'
          );
        },
      });
  }
  formatoValidator() {
    return (control: FormControl) => {
      const valor = control.value;
      if (!valor) {
        return null;
      }
      const regex = /^ENV\/\d{4}\/\d{9}$/;
      if (!regex.test(valor)) {
        return { formatoInvalido: true };
      }

      if (valor.length !== 18) {
        return { longitudIncorrecta: true };
      }

      return null;
    };
  }

  canAccessAll(list: any) {
    const resultDelete = this.canAccessEdit();
    const listPermission = list.map((objeto: any) => {
      return {
        ...objeto,
        canAccessDelete: resultDelete && (this.solicitud?.estado?.id === 1 || !this.solicitudId),
        canAccessDownload: objeto?.id !== -1
      };
    });
    return listPermission;
  }
  canAccessEdit(): boolean {
    return this.authService.can('sddr-ca-solicitudes-reutilizacion-detalle', 'edit');
  }
  canAccessGuardar(): boolean {
    return this.authService.can('sddr-ca-solicitudes-reutilizacion-detalle', 'access-b-guardar');
  }
  canAccessGuardarEnviar(): boolean {
    return this.authService.can('sddr-ca-solicitudes-reutilizacion-detalle', 'access-b-guardar-enviar');
  }
  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.formGroup.get(formName)?.setValue(null);
  }
  showClearButton(formName: string): boolean {
    return this.formGroup.get(formName)?.value !== null;
  }

  obtenerNombreReducido(nombre: string): string {
    if (nombre.length > 20) {
      return nombre.substring(0, 20) + '...';  // Truncamos a los 20 caracteres
    }
    return nombre;
  }

  seleccionUsuarioSDDR(){
    if(this.formGroup.get("usuarioSddr")?.value){
      const usuario = this.tiposUsusariosSddr.find((x: UsuarioSDDRCaDTO) => (x.id! + x.tipoUsuario!) === this.formGroup.get('usuarioSddr')?.value);
      const isAdherido = usuario?.tipoUsuario === 'ADHERIDO';
      this.cargarComboUsoEnvase(usuario, isAdherido);
    }else{
      this.usosEnvases = [];
    }
  }

  cargarComboUsoEnvase(usuario:any, isAdherido:boolean){
    const allUsosEnvases = this.comboSrv.getUsosEnvases();
    //opción REUTILIZACION_EN_ESPAÑA_SDDRCC únicamente habilitada para los adheridos que han declarado el envase en cuestión en el SDDR Cerrado de ENVALORA, con devolución de depósitos para esos envases concretos.
    if (isAdherido) {
      this.adheridoCtrlSrv.tieneDecalradoSddrCerrado(usuario.id).subscribe({
        next: (resp) => {
          if(resp){
            this.usosEnvases = allUsosEnvases;
          }else{
            this.usosEnvases = allUsosEnvases.filter(x => x.id != 'REUTILIZACION_EN_ESPAÑA_SDDRCC');
            if(this.formGroup.get('usoEnvase')?.value && this.formGroup.get('usoEnvase')?.value == 'REUTILIZACION_EN_ESPAÑA_SDDRCC'){
              this.formGroup.get('usoEnvase')?.setValue(null);
            }
          }
        },
        error: (err) => {
          console.log(err);
          this.snackBarSrv.showSnackBar("Ha ocurrido un error al obtener información del adherido", "error");
        },
      });
    }else{
      //Es Poseedor
      this.usosEnvases = allUsosEnvases.filter(x => x.id != 'REUTILIZACION_EN_ESPAÑA_SDDRCC');
      if(this.formGroup.get('usoEnvase')?.value && this.formGroup.get('usoEnvase')?.value == 'REUTILIZACION_EN_ESPAÑA_SDDRCC'){
        this.formGroup.get('usoEnvase')?.setValue(null);
      }
    }
  }
}
