import { NgModule } from '@angular/core';
import {OpenSddrComponent} from '../pages/open-sddr.component';
import { RouterModule } from '@angular/router';
import {CommonModule, DatePipe} from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MaterialModule } from './material.module';
import {
  OpenSddrRequestsWithdrawnSearchComponent
} from "../pages/components-open-sddr-requests-withdrawn/open-sddr-requests-withdrawn-search/open-sddr-requests-withdrawn-search.component";
import {ApiCore, ApiDocum} from "api-rest";
import {NgbPaginationModule, NgbTooltipModule} from "@ng-bootstrap/ng-bootstrap";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {EmpusaSpinnerComponent} from "@empusa/empusa-core";
import {NgxMatSelectSearchModule} from "ngx-mat-select-search";
import {CustomDatePickerModule} from "./custom-date-picker.module";
import {GeTableComponent} from "../share/ge-table.component";
import {MatInputModule} from "@angular/material/input";
import {GetAttributePipe} from "../share/pipe/get-attribute.pipe";
import {GeSelectPaginationComponent} from "../share/ge-select-pagination/ge-select-pagination.component";
import {NgSelectModule} from "@ng-select/ng-select";
import { OpenSddrReuseSearchComponent } from '../pages/components-open-sddr-reuse/open-sddr-reuse-search/open-sddr-reuse-search.component';
import { OpenSsdrReuseDialogComponent } from '../pages/components-open-sddr-reuse/open-ssdr-reuse-dialog/open-ssdr-reuse-dialog.component';
import { OpenSddrRequestsWithdrawnDetailComponent } from '../pages/components-open-sddr-requests-withdrawn/open-sddr-requests-withdrawn-detail/open-sddr-requests-withdrawn-detail.component';
import { OpenSddrContributionsDisDeclineDialogComponent } from '../pages/components-open-sddr-contributions-dis/open-sddr-contributions-dis-cancel-dialog/open-sddr-contributions-dis-cancel-dialog.component';
import { TwoDigitDecimalNumberDirective } from '../share/utils/TwoDigitDecimalNumberDirective.directive';
import { NumericPointDirective } from '../share/utils/NumericPoint.directive';
import {
  OpenSddrRemainSearchComponent
} from "../pages/components-open-sddr-remain/open-sddr-remain-search/open-sddr-remain-search.component";
import {
  OpenSddrContributionsDisComponent
} from "../pages/components-open-sddr-contributions-dis/open-sddr-contributions-dis/open-sddr-contributions-dis.component";
import {
  OpenSddrContributionsDisDialogComponent
} from "../pages/components-open-sddr-contributions-dis/open-sddr-contributions-dis-dialog/open-sddr-contributions-dis-dialog.component";
import {
  OpenSddrContributionsDisSelectionDialogComponent
} from "../pages/components-open-sddr-contributions-dis/open-sddr-contributions-dis-selection-dialog/open-sddr-contributions-dis-selection-dialog.component";
import {
  OpenSddrSelfInvoicesSearchComponent
} from "../pages/components-open-sddr-invoices-self/open-sddr-self-invoices-search/open-sddr-self-invoices-search.component";
import {
  OpenSddrInvoiceNewDocumentDialogComponent
} from "../pages/components-open-sddr-invoices-self/open-sddr-invoice-document-dialog/open-sddr-invoice-document-dialog.component";
import { OpenSddrRemainDialogComponent } from '../pages/components-open-sddr-remain/open-sddr-remain-dialog/open-sddr-remain-dialog.component';
import {
  OpenSddrInvoiceRejectDialogComponent
} from "../pages/components-open-sddr-invoices-self/open-sddr-invoice-reject-dialog/open-sddr-invoice-reject-dialog.component";
import { OpenSsdrReuseCertificateDialogComponent } from '../pages/components-open-sddr-reuse/open-ssdr-reuse-certificate-dialog/open-ssdr-reuse-certificate-dialog.component';

export const CoreConfigFactory = () =>
  new ApiCore.Configuration(<ApiCore.ConfigurationParameters>{});

export const CoreBasePathFactory = (environment: any) => {
  const basePath = environment.urlBackCore;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const DocumConfigFactory = () =>
  new ApiDocum.Configuration(<ApiDocum.ConfigurationParameters>{});

export const DocumBasePathFactory = (environment: any) => {
  const basePath = environment.urlBackDocum;
  return basePath?.endsWith('/') ? basePath.slice(0, -1) : basePath;
};

export const explorerRoutes = [
  {
    path: '',
    component: OpenSddrComponent,
    children: [
      { path: 'reuse-search', component: OpenSddrReuseSearchComponent },
      {path: 'requests-withdrawn-search', component: OpenSddrRequestsWithdrawnSearchComponent},
      {path: 'requests-remain-search', component: OpenSddrRemainSearchComponent},
      { path: 'contributions-dis-search', component: OpenSddrContributionsDisComponent },
      { path: 'invoices-search', component: OpenSddrSelfInvoicesSearchComponent },
    ]
  },
];

@NgModule({
  declarations: [
    OpenSddrComponent,
    OpenSddrRequestsWithdrawnSearchComponent,
    GeTableComponent,
    GetAttributePipe,
    GeSelectPaginationComponent,
    OpenSddrReuseSearchComponent,
    OpenSsdrReuseDialogComponent,
    NumericPointDirective,
    TwoDigitDecimalNumberDirective,
    OpenSddrRemainSearchComponent,
    OpenSddrRequestsWithdrawnDetailComponent,
    OpenSddrContributionsDisComponent,
    OpenSddrContributionsDisDialogComponent,
    OpenSddrContributionsDisSelectionDialogComponent,
    OpenSddrSelfInvoicesSearchComponent,
    OpenSddrInvoiceNewDocumentDialogComponent,
    OpenSddrRemainDialogComponent,
    OpenSddrContributionsDisDeclineDialogComponent,
    OpenSddrInvoiceRejectDialogComponent,
    OpenSsdrReuseCertificateDialogComponent
  ],
  imports: [
    CommonModule,
    TranslateModule,
    MatFormFieldModule,
    RouterModule.forChild(explorerRoutes),
    NgbTooltipModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    NgSelectModule,
    ApiDocum.ApiModule.forRoot(DocumConfigFactory),
    RouterModule.forChild(explorerRoutes),
    MaterialModule,
    EmpusaSpinnerComponent,
    CustomDatePickerModule,
    NgxMatSelectSearchModule,
    FormsModule,
    MatInputModule,
    ReactiveFormsModule,
    TranslateModule
  ],
  exports: [TranslateModule, OpenSddrComponent],
  providers: [
    DatePipe,
    {
      provide: ApiDocum.BASE_PATH,
      useFactory: DocumBasePathFactory,
      deps: ['environment'],
    },
  ],
})
export class OpenSddrHomeModule {
  constructor() {
   let compilationVersion = ""
    console.log('open-sddr version: ' + compilationVersion);
  }
}
