import {Component, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {SnackbarService} from '../../../services/snackbar.service';
import {SpinnerService} from '../../../services/spinner.service';
import {Combo, ComboService} from '../../../services/combo.service';
import {
  ComunicacionControllerService,
  ComunicacionDTO,
  ComunicacionEstadoDTO,
  ComunicacionHistorialControllerService,
  ComunicacionHistorialFilterDTO,
  ComunicacionHistorialPayloadDTO,
  ComunicacionPayloadDTO,
  DocumControllerService,
  KeycloakUserFilterDTO,
  PageDTOKeycloakUserDTO,
  UserManagementControllerService
} from 'api-rest';
import moment from 'moment';
import {DatePipe} from '@angular/common';
import {DownloadService} from '../../../services/download.service';
import {EmpusaAuthenticationService} from '@empusa/empusa-core';


@Component({
  selector: 'comunications-comunication-dialog',
  templateUrl: './comunication-dialog.component.html',
  styleUrls: ['./comunication-dialog.component.scss'],
})
export class ComunicationDialogComponent {
  titulo: String = '';
  comunicacion: ComunicacionDTO;
  soloLectura: boolean;
  datosForm!: FormGroup;
  infoUsuarioForm!: FormGroup;
  estadosForm!: FormGroup;
  otrosForm!: FormGroup;

  tipos!: Combo[];
  estados!: ComunicacionEstadoDTO[];
  categorias!: Combo[];
  prioridades!: Combo[];
  adjuntosEstadosBlob: Array<File> = [];
  adjuntosOtrosBlob: Array<File> = [];
  verCambioEstado: boolean = false;
  nueva: boolean = false;

  gridArray: any[] = [];

  headArray: any = [
    {
      Head: 'Fecha',
      FieldName: 'fechaAlta',
      renderValue: (item: any) => this.datePipe.transform(item.fechaAlta, 'dd/MM/yyyy')

    },
    {
      Head: 'Estado',
      FieldName: 'estado',
      Attribute: 'descripcion'
    },
    {
      Head: 'Comentarios',
      FieldName: 'comentarios'
    },
    {
      'Head': 'Archivo', 'FieldName': 'actions', 'buttons':
        [
          {
            nameButton: 'donwload',
            icon: 'download',
            toolTip: 'Descargar archivo',
            show: { params: 'canAccessDownload', accion: 'donwload' }
          }
        ], 'width': '8', permanent: true
    },
  ];


  constructor(
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ComunicationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBarSrv: SnackbarService,
    public spinnerSrv: SpinnerService,
    private comboSrv: ComboService,
    private comunicacionHistorialCtrlSrv: ComunicacionHistorialControllerService,
    private datePipe: DatePipe,
    private comunicacionControllerSrv: ComunicacionControllerService,
    private documControllerSrv: DocumControllerService,
    private downloadSrv: DownloadService,
    @Inject('EmpusaAuthenticationService') private authService: EmpusaAuthenticationService,
    private userService: UserManagementControllerService
  ) {
    this.comunicacion = this.data.comunicacion;
    this.soloLectura = this.data.soloLectura;
    this.estados = this.data.estados;

    this.datosForm = this.formBuilder.group({
      tipo: [{ value: null, disabled: this.soloLectura }, { validators: Validators.compose([Validators.required]) }],
      fechaCreacion: [{ value: null, disabled: true }],
      fechaModificacion: [{ value: null, disabled: true }],
      prioridad: [{ value: null, disabled: this.soloLectura }],
      categoria: [{ value: null, disabled: this.soloLectura }],
      fechaIncidencia: [{ value: null, disabled: this.soloLectura }],
      horaIncidencia: [{ value: null, disabled: this.soloLectura }],
      asunto: [{ value: null, disabled: this.soloLectura }, { validators: Validators.compose([Validators.required]) }],
      descripcion: [{ value: null, disabled: this.soloLectura }, { validators: Validators.compose([Validators.required]) }],
      contactoPrevio: [{ value: null, disabled: this.soloLectura }, { validators: Validators.compose([Validators.required]) }],
    });
    this.infoUsuarioForm = this.formBuilder.group({
      email: [{ value: null, disabled: true }],
      //telefono: [{ value: null, disabled: true }],
      perfil: [{ value: null, disabled: true }]
    });
    this.estadosForm = this.formBuilder.group({
      estado: [{ value: null, disabled: true }],
      comentario: [{ value: null, disabled: this.soloLectura }, { validators: Validators.compose([Validators.required]) }],
      nuevoEstado: [{ value: null, disabled: this.soloLectura }, { validators: Validators.compose([Validators.required]) }]
    });
    this.otrosForm = this.formBuilder.group({
      comentariosAdicionales: [{ value: null, disabled: this.soloLectura }]
    });
  }
  ngOnInit(): void {
    this.categorias = this.comboSrv.getCategoriasComs();
    this.tipos = this.comboSrv.getTiposComunicaciones();
    this.prioridades = this.comboSrv.getTiposPrioridadesComs();

    if (this.comunicacion) {
      this.titulo = 'Detalle de comunicación – Nº Registro: ' + this.comunicacion?.id!;
      this.cargarComunicacion();
      this.obtenerUsuario();
      this.deshabilitarCampos();
      this.obtenerHistorialEstados();
    } else {
      this.titulo = 'Nueva comunicación';
      this.nueva = true;
    }
  }

  deshabilitarCampos(): void {
    this.datosForm.disable();
    this.otrosForm.disable();
  }

  obtenerHistorialEstados(): void {
    let filter: ComunicacionHistorialFilterDTO = {
      comunicacion: { id: [this.comunicacion?.id?.toString()!] }
    }
    this.spinnerSrv.loadingShow();
    this.comunicacionHistorialCtrlSrv.findAllComunicacion(filter, { page: 0, size: 100 })
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.gridArray = this.canAccessAll(response.datos!)
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      })
  }

  obtenerUsuario(): void {
    // Se pide la información del usuario para obtener el perfil
    if (this.comunicacion?.user?.email) {
      const payload: KeycloakUserFilterDTO = { email: [this.comunicacion?.user?.email] };
      this.userService
        .getUsuarios(payload, { page: 0, size: 1 })
        .subscribe({
          next: (respuesta: PageDTOKeycloakUserDTO) => {
            if (respuesta && respuesta?.datos && respuesta?.datos?.length > 0) {
              this.infoUsuarioForm.controls['perfil'].setValue(respuesta?.datos[0]?.activeUserRolesEntitiesDetail);
            }
          }
        });
    }
  }

  cargarComunicacion() {
    this.datosForm.patchValue({
        tipo: this.comunicacion?.tipo,
        prioridad: this.comunicacion?.prioridad,
        categoria: this.comunicacion?.categoria,
        fechaIncidencia: this.comunicacion?.fechaIncidencia,
        horaIncidencia: this.comunicacion?.horaIncidencia,
        asunto: this.comunicacion?.asunto,
        descripcion: this.comunicacion?.descripcion,
        contactoPrevio: this.comunicacion?.contactoSoporte,
      }
    );
    if (this.comunicacion?.fechaModificacion) {
      this.datosForm.controls['fechaModificacion'].setValue(moment(new Date(this.comunicacion?.fechaModificacion)).format('DD/MM/yyyy'));
    }
    if (this.comunicacion?.fechaAlta) {
      this.datosForm.controls['fechaCreacion'].setValue(moment(new Date(this.comunicacion?.fechaAlta)).format('DD/MM/yyyy'));
    }

    this.infoUsuarioForm.patchValue({
      email: this.comunicacion?.user?.email
    });
    this.estadosForm.patchValue({
      estado: this.comunicacion?.estado?.descripcion
    });
    this.otrosForm.patchValue({
      comentariosAdicionales: this.comunicacion?.comentarios,
    });
  }


  cerrar(): void {
    this.dialogRef.close(null);
  }

  actualizarValidaciones(tipoIncidencia: string): void {
    if(tipoIncidencia ==='INCIDENCIA'){
      this.datosForm?.controls['prioridad'].setValidators(Validators.compose([Validators.required]));
      this.datosForm?.controls['categoria'].setValidators(Validators.compose([Validators.required]));
      this.datosForm?.controls['fechaIncidencia'].setValidators(Validators.compose([Validators.required]));
    } else{
      this.datosForm?.controls['prioridad'].clearValidators();
      this.datosForm?.controls['categoria'].clearValidators();
      this.datosForm?.controls['fechaIncidencia'].clearValidators();
    }

    this.datosForm?.controls['prioridad'].updateValueAndValidity();
    this.datosForm?.controls['categoria'].updateValueAndValidity();
    this.datosForm?.controls['fechaIncidencia'].updateValueAndValidity();
    console.log(this.datosForm)
  }

  guardar(): void {
    this.datosForm.markAllAsTouched();
    this.otrosForm.markAllAsTouched();

    if (this.datosForm.valid && this.otrosForm.valid) {

      let body: ComunicacionPayloadDTO = {
        tipo: this.datosForm.get('tipo')?.value,
        prioridad: this.datosForm.get('prioridad')?.value,
        categoria: this.datosForm.get('categoria')?.value,
        fechaIncidencia: this.datosForm.get('fechaIncidencia')?.value,
        horaIncidencia: this.datosForm.get('horaIncidencia')?.value,
        asunto: this.datosForm.get('asunto')?.value,
        descripcion: this.datosForm.get('descripcion')?.value,
        comentarios: this.otrosForm.get('comentariosAdicionales')?.value,
        contactoSoporte: this.datosForm.get('contactoPrevio')?.value
      }

      let adjuntoBlob!: Blob;
      if (this.adjuntosOtrosBlob?.length > 0) {
        adjuntoBlob = this.adjuntosOtrosBlob[0]
      }

      this.spinnerSrv.loadingShow();
      this.comunicacionControllerSrv.createComunicacion1(body, adjuntoBlob)
        .subscribe({
          next: (response) => {
            this.spinnerSrv.loadingHide();
            this.dialogRef.close(true);
            this.snackBarSrv.showSnackBar(
              'La creación de la comunicación se ha realizado correctamente',
              'success'
            );
          },
          error: () => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al crear la comunicacación',
              'error'
            );
          },
        })

    } else {
      this.snackBarSrv.showSnackBar(
        'Complete los campos obligatorios',
        'error'
      );
    }

  }
  cambiarEstado(): void {
    this.estadosForm.markAllAsTouched();
    if (this.estadosForm.valid) {
      this.estadosForm.get('comentario')?.value;
      this.estadosForm.get('nuevoEstado')?.value;
      let body: ComunicacionHistorialPayloadDTO = {
        comunicacion: { id: this.comunicacion.id },
        estado: this.estadosForm.get('nuevoEstado')?.value,
        comentarios: this.estadosForm.get('comentario')?.value

      }
      let adjuntoBlob!: Blob;
      if (this.adjuntosEstadosBlob?.length > 0) {
        adjuntoBlob = this.adjuntosEstadosBlob[0]
      }

      this.spinnerSrv.loadingShow();
      this.comunicacionHistorialCtrlSrv.createComunicacion(body, adjuntoBlob)
        .subscribe({
          next: (response) => {
            this.spinnerSrv.loadingHide();
            this.dialogRef.close(true);
            this.snackBarSrv.showSnackBar(
              'El cambio de estado se ha realizado correctamente',
              'success'
            );
          },
          error: () => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al realizar el cambio de estado',
              'error'
            );
          },
        });
    }
  }

  controlHasError(form: FormGroup, controlName: string, errorName: string) {
    return (
      form.controls[controlName]?.hasError(errorName) &&
      form.controls[controlName]?.touched
    );
  }

  attachAdjuntoEstado(archivo: File | null): void {
    if (archivo != null) {
      if (archivo.size === 0) {
        this.snackBarSrv.showSnackBar(
          'No se puede subir un fichero vacío',
          'error'
        );
      } else if (archivo.size > 5 * 1024 * 1024) { // 5 MB
        this.snackBarSrv.showSnackBar(
          'El fichero supera el tamaño máximo permitido de 5 MB',
          'error'
        );
      } else {
        this.adjuntosEstadosBlob.push(archivo);
      }
    }
  }

  detachAdjuntoEstado(archivo: Blob): void {
    this.adjuntosEstadosBlob = this.adjuntosEstadosBlob.filter(
      (adjBlob) => adjBlob !== archivo
    );
  }
  attachAdjuntoOtros(archivo: File | null): void {
    if (archivo != null) this.adjuntosOtrosBlob.push(archivo);
  }
  detachAdjuntoOtros(archivo: Blob): void {
    this.adjuntosOtrosBlob = this.adjuntosOtrosBlob.filter(
      (adjBlob) => adjBlob !== archivo
    );
  }

  action(item: any) {
    if (item.nameButton == 'donwload') {
      this.descargar(item?.documento);

    }
  }
  toogleEstado() {
    this.verCambioEstado = !this.verCambioEstado;
  }

  //Aparecerá el botón de descargar si el estado tiene un fichero asociado
  canAccessAll(list: any) {
    const listPermission = list.map((objeto: any) => {
      const hayFichero = objeto?.documento?.id ? true : false;
      return {
        ...objeto,
        canAccessDownload: hayFichero
      };
    });
    return listPermission;
  }

  // Descargar fichero de otros datos
  descargar(element: any) {
    this.spinnerSrv.loadingShow();
    this.documControllerSrv.downloadDoc(element.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Se ha descargado el documento correctamente',
          'success'
        );
        this.downloadSrv.downloadBlob(response, element.nombre);
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al descargar el documento',
          'error'
        );
      },
    });
  }

  isAdmComunicaciones(): boolean {
    return this.authService.hasCurrentUserRole('ADM-COMUNICACIONES');
  }

  obtenerEstados(): Array<ComunicacionEstadoDTO> {
    if (this.isAdmComunicaciones()) {
      const pteUsuario = this.estados.find(x => x.id === 3);
      //ADM-COMUNICACIONES
      let estados = this.estados.filter(x => this.comunicacion?.estado?.id! < x?.id!);
      if (this.comunicacion?.estado?.id === 4) {
        estados.unshift(pteUsuario!)
      } else if (this.comunicacion?.estado?.id! < 3) {
        estados = estados.filter(x => x.id !== 3);
      }
      return estados;
    } else {
      //USUARIOS FINALES
      return this.estados.filter(x => x.id === 4 || x.id === 5);
    }
  }

  mostrarCambioEstado(): boolean {
    let mostrar = false;
    if (this.isAdmComunicaciones() && (this.comunicacion?.estado?.id !== 3 && this.comunicacion?.estado?.id !== 6)) {
      mostrar = true;
    } if (!this.isAdmComunicaciones() && this.comunicacion?.estado?.id === 3) {
      mostrar = true;
    }
    return mostrar;
  }

}
