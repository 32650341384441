import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SpinnerService } from '../../../services/spinner.service';
import { DownloadService } from '../../../services/download.service';
import { SnackbarService } from '../../../services/snackbar.service';
import {
  AportacionDIPoseedorDTO,
  AportacionDIPoseedorPayloadDTO,
  AportacionDIEstadoControllerService,
  AportacionDIPoseedorControllerService,
  OperacionGestionControllerService,
  ResiduoEnvaseFilterDTO,
  SolicitudCodigoLerDTO,
  SolicitudNimaGestoresDTO,
  SolicitudNimaPoseedorDTO,
  AportacionDIEstadoDTO,
  PoseedorControllerService,
  GestoresResiduosControllerService,
  CodigoOperacionFilterDTO,
} from 'api-rest';
import { Observable, Subscription, tap } from 'rxjs';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { DocumentService } from '../../../services/document.service';

@Component({
  selector: 'app-holders-contributions-dis-dialog',
  templateUrl: './holders-contributions-dis-dialog.component.html',
  styleUrls: ['./holders-contributions-dis-dialog.component.scss'],
})
export class HoldersContributionsDisDialogComponent implements OnInit {
  estadoArray: AportacionDIEstadoDTO[] = [];
  codigoLerArray: any[] = [];
  documentoArray: any[] = [];
  residuoArray: any[] = [];
  descripcionEnvaseArray: any[] = [];
  gestionArray: any[] = [];
  nimaPoseedorArray: any[] = [];
  nimaGestorArray: any[] = [];
  entityRoles: any[] = [];

  modelForm!: FormGroup;
  subscriptions: Subscription[] = [];
  roleEntities: any[] = [];
  documento: File | undefined;
  titulo: any;
  isGestor = false;
  isPoseedor = false;
  edicion = false;
  alta = false;
  estado: Number = 1;

  pageNumber: number = 0;
  pageSize: number = 100;
  recordsTotal: number = 0;

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private dialogRef: MatDialogRef<HoldersContributionsDisDialogComponent>,
    private estadoAportacionesService: AportacionDIEstadoControllerService,
    private aportacionesDiService: AportacionDIPoseedorControllerService,
    private gestorResiduos: GestoresResiduosControllerService,
    private poseedorService: PoseedorControllerService,
    private downloadService: DownloadService,
    private documentService: DocumentService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    @Inject(MAT_DIALOG_DATA) public aportacion: AportacionDIPoseedorDTO | any,
    private operacionGestionControllerService: OperacionGestionControllerService
  ) {
    this.isGestor = aportacion.isGestor;
    this.isPoseedor = aportacion.isPoseedor;

    this.inicializarForm();
  }

  ngOnInit() {
    this.spinnerSrv.loadingShow();
    let peticionInicial;
    if (this.aportacion.poseedor) {
      peticionInicial = this.buscarInicialPoseedor();
    } else {
      peticionInicial = this.buscarInicialGestor();
    }
    peticionInicial.subscribe({
      next: () => {
        this.cargaInicial();
        this.spinnerSrv.loadingHide();
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  inicializarForm() {
    this.aportacion.fechaAceptacion =
      this.aportacion.fechaAceptacion ?? new Date().toISOString();
    this.modelForm = this.formBuilder.group({
      id: [{ value: this.aportacion?.id }],
      documentoIdentificacion: [
        { value: this.aportacion?.documentoIdentificacion, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(255)]) },
      ],
      fechaAceptacion: [
        { value: this.aportacion?.fechaAceptacion, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      estado: [{ value: this.aportacion?.estado, disabled: false }],

      poseedor: [
        { value: this.aportacion?.poseedor, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      nimaGestor: [
        { value: this.aportacion?.gestorResiduo, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],

      autofactura: [{ value: null, disabled: false }],
      documento: [{ value: this.aportacion?.documento, disabled: false }],

      codigoLer: [
        { value: this.aportacion?.codigoLer, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      gestorResiduo: [
        { value: this.aportacion?.gestorResiduo, disabled: false },
      ],
      operacionGestion: [
        { value: this.aportacion?.operacionGestion, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],

      cantidadGestionada: [
        { value: this.aportacion?.cantidadGestionada, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
    });

    const sub = this.modelForm.controls[
      'fechaAceptacion'
    ].valueChanges.subscribe((val) => {
      this.buscarNimaGestor(val, this.modelForm.value?.poseedor);
      this.buscarNimaPoseedor(val, this.modelForm.value?.nimaGestor);
      this.buscaCodigoLer(
        val,
        this.modelForm.value?.gestorResiduo,
        this.modelForm.value?.poseedor
      );
      this.gestionArray = [];
    });
    const sub2 = this.modelForm.controls['poseedor'].valueChanges.subscribe(
      (val) => {
        this.buscarNimaGestor(this.modelForm.value?.fechaAceptacion, val);
        this.buscaCodigoLer(
          this.modelForm.value?.fechaAceptacion,
          this.modelForm.value?.gestorResiduo,
          val
        );
      }
    );
    const sub3 = this.modelForm.controls['nimaGestor'].valueChanges.subscribe(
      (val) => {
        this.buscarNimaPoseedor(this.modelForm.value?.fechaAceptacion, val);
        this.buscaCodigoLer(
          this.modelForm.value?.fechaAceptacion,
          val,
          this.modelForm.value?.poseedor
        );
      }
    );
    this.subscriptions.push(sub);
    this.subscriptions.push(sub2);
    this.subscriptions.push(sub3);

    this.valueChangeCodigoLer();
  }
  cargaInicial() {
    this.roleEntities = (this.authService.user as any).roleEntities;
    if (this.aportacion?.id) {
      this.titulo = 'Editar aportación de DI';
      this.buscarOperacionesGestion();
      this.edicion = true;
    } else {
      this.titulo = 'Alta de aportación de DI';
      this.edicion = false;
    }

    if (!this.canSaveAll()) {
      this.modelForm.disable();
    }

    this.estadoAportacionesService
      .findAll14()
      .subscribe((estados) => (this.estadoArray = estados));
    const rolesGestor = this.roleEntities?.filter(
      (roleEntity) => roleEntity.role == 'PO-GESTOR'
    );
    const rolesPoseedor = this.roleEntities?.filter(
      (roleEntity) => roleEntity.role == 'PO-POSEEDOR'
    );
    this.buscarNimaGestor(
      this.modelForm.value?.fechaAceptacion,
      this.aportacion.poseedor
    );
    this.buscarNimaPoseedor(
      this.modelForm.value?.fechaAceptacion,
      this.aportacion.gestorResiduo
    );
    this.buscaCodigoLer(
      this.modelForm.value?.fechaAceptacion,
      this.aportacion.gestorResiduo,
      this.aportacion.poseedor
    );

    this.buscarGestorResiduos();

    this.updateDisableField('documentoIdentificacion');
    this.updateDisableField('fechaAceptacion');
    this.updateDisableField('cantidadGestionada');
  }

  buscarInicialGestor(): Observable<any> {
    return this.gestorResiduos
      .getById3(this.aportacion?.gestorResiduo?.id)
      .pipe(
        tap((res) => {
          this.aportacion.gestorResiduo = res;
        })
      );
  }

  buscarInicialPoseedor(): Observable<any> {
    return this.poseedorService
      .getById2(this.aportacion?.poseedor?.id)
      .pipe(tap((res) => (this.aportacion.poseedor = res)));
  }

  updateDisableField(field: any): void {
    if (this.aportacion.estado) {
      if (!this.canAccessEditEstado()) {
        this.modelForm.get(field)?.disable();
      }
    }
  }

  buscarNimaGestor(fechaAceptacion: any, poseedor: any) {
    const filtroNimaGestor: SolicitudNimaGestoresDTO = {
      fechaAceptacion: fechaAceptacion,
      poseedor: poseedor,
    };
    this.spinnerSrv.loadingShow();
    this.aportacionesDiService.nimaGestores(filtroNimaGestor).subscribe({
      next: (res) => {
        this.nimaGestorArray = res;
        this.setItemSelected('nimaGestor', 'id', this.nimaGestorArray);
        this.spinnerSrv.loadingHide();
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  buscarNimaPoseedor(fechaAceptacion: any, nimaGestor: any) {
    const filtroNimaPoseedor: SolicitudNimaPoseedorDTO = {
      fechaAceptacion: fechaAceptacion,
      gestor: nimaGestor,
    };
    this.spinnerSrv.loadingShow();
    this.aportacionesDiService.nimaPoseedores(filtroNimaPoseedor).subscribe({
      next: (res) => {
        this.nimaPoseedorArray = res;
        this.setItemSelected('poseedor', 'id', this.nimaPoseedorArray);
        this.spinnerSrv.loadingHide();
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  buscaCodigoLer(fechaAceptacion: any, gestorResiduo: any, poseedor: any) {
    if (fechaAceptacion && gestorResiduo && poseedor) {
      const filtroNimaPoseedor: SolicitudCodigoLerDTO = {
        fechaAceptacion: fechaAceptacion,
        gestor: gestorResiduo,
        poseedor: poseedor,
      };
      this.spinnerSrv.loadingShow();
      this.aportacionesDiService.codigoLer(filtroNimaPoseedor).subscribe({
        next: (res) => {
          this.codigoLerArray = res;
          this.setItemSelected('codigoLer', 'id', this.codigoLerArray);
          this.spinnerSrv.loadingHide();
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
    } else {
      this.codigoLerArray = [];
    }
  }

  buscarGestorResiduos() {
    const filtroResiduo: ResiduoEnvaseFilterDTO = {};
    this.spinnerSrv.loadingShow();
    this.gestorResiduos
      .findAll8(filtroResiduo, { page: 0, size: 1000 })
      .subscribe({
        next: (res) => {
          this.residuoArray = res?.datos ?? [];
          this.setItemSelected('gestorResiduo', 'id', this.residuoArray);
          this.updateDisableField('gestorResiduo');
          this.spinnerSrv.loadingHide();
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  valueChangeCodigoLer() {
    this.modelForm.get('codigoLer')?.valueChanges.subscribe((value: any) => {
      if (value) {
        this.buscarOperacionesGestion();
      } else {
        this.gestionArray = [];
      }
    });
  }

  buscarOperacionesGestion() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.operacionGestionControllerService
      .findByCodigoOperacion(backendFilter, { page: page, size: size })
      .subscribe({
        next: (res) => {
          if (res) {
            this.gestionArray = res?.datos ?? [];
            this.setItemSelected('operacionGestion', 'id', this.gestionArray);
            this.updateDisableField('operacionGestion');
          }
          this.spinnerSrv.loadingHide();
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  private getBackendFilter() {
    const backendFilter: CodigoOperacionFilterDTO = {
      codigoLer: [this.modelForm.get('codigoLer')?.value?.id],
      poseedores: true
    };
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    return { backendFilter, page, size: this.pageSize };
  }

  private setItemSelected(
    controlName: string,
    propertyName: string,
    items: any[]
  ) {
    let selectedItem = null;
    if (this.modelForm.value[controlName]) {
      if (
        typeof this.modelForm.value[controlName] == 'object' &&
        this.modelForm.value[controlName][propertyName]
      ) {
        selectedItem = items.find(
          (item) =>
            this.modelForm.value[controlName][propertyName] ==
            item[propertyName]
        );
      } else {
        selectedItem = items.find(
          (item) => this.modelForm.value[controlName] == item[propertyName]
        );
      }
    }
    this.modelForm.controls[controlName].patchValue(selectedItem, {
      emitEvent: false,
    });
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  saveData(estadoId: any) {
    this.modelForm.markAllAsTouched();
    this.modelForm.updateValueAndValidity();

    if (this.aportacion?.documento == null) {
      if (this.documento == undefined || this.documento == null) {
        this.snackBarSrv.showSnackBar('Adjunte documento', 'error');
        return;
      }
    }

    if (this.modelForm.valid) {
      const formValue = this.modelForm.value;
      const aportacionPayload: AportacionDIPoseedorPayloadDTO = formValue;
      aportacionPayload.gestorResiduo = formValue.nimaGestor;
      if (aportacionPayload.estado !== undefined && estadoId !== null) {
        aportacionPayload.estado!.id = estadoId;
      }
      let request, successMsg: string, errorMsg: string;
      if (this.aportacion?.id) {
        request = this.aportacionesDiService.editById6(
          this.aportacion.id,
          aportacionPayload,
          this.documento
        );
        successMsg = 'La aportación DI se ha editado correctamente';
        errorMsg = 'Ha ocurrido un error al editar la aportación DI';
      } else {
        if (!this.documento) {
          this.snackBarSrv.showSnackBar(
            'Debe seleccionar un documento',
            'error'
          );
          return;
        }

        request = this.aportacionesDiService.create6(
          aportacionPayload,
          this.documento
        );
        successMsg = 'La aportación DI se ha creado correctamente';
        errorMsg = 'Ha ocurrido un error al crear la nueva aportación DI';
      }

      this.spinnerSrv.loadingShow();
      request.subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.dialogRef.close(true);
          this.snackBarSrv.showSnackBar(successMsg, 'success');
        },
        error: () => {
          this.aportacion.estado.id = 1;
          this.spinnerSrv.loadingHide();
        },
      });
    }
  }

  showClearButton(formName: string): boolean {
    return this.modelForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.modelForm.get(formName)?.setValue(null);
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.modelForm.controls[controlName].hasError(errorName) &&
      this.modelForm.controls[controlName].touched
    );
  }

  attachAdjunto(archivo: File | null): void {
    if (archivo) {
      if (archivo.size === 0) {
        this.snackBarSrv.showSnackBar(
          'No se puede subir un fichero vacío',
          'error'
        );
      } else if (archivo.size > 5 * 1024 * 1024) { // 5 MB
        this.snackBarSrv.showSnackBar(
          'El fichero supera el tamaño máximo permitido de 5 MB',
          'error'
        );
      } else {
        this.documento = archivo;
      }
    }
  }

  descargar(documento: any) {
    this.spinnerSrv.loadingShow();
    this.documentService.descargarDocumento(documento.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Se ha descargado el documento correctamente',
          'success'
        );
        this.downloadService.downloadBlob(response, documento.nombre);
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }

  canAccessEditEstado(): boolean {
    return this.aportacion?.estado?.id === 1;
  }

  canAccessEditByRol(): boolean {
    return this.authService.can('poseedores-di-detalle', 'access-update');
  }

  canSaveAll(): boolean {
    if (this.canAccessEditByRol() && this.canAccessEditEstado()) {
      if (
        this.edicion &&
        this.aportacion.creadoPor == this.authService.getCurrentUserMail()
      ) {
        return true;
      } else if (!this.edicion) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
