import { HttpClient, HttpContext, HttpHeaders, HttpParameterCodec, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, filter, retry, map } from 'rxjs';
import { Adherido, Contacto, listAdheridos } from '../models/adherido';
import { Tramo_cuota } from '../models/tramo_cuota';
import { Sectores } from '../models/sectores';
import { CNAE } from '../models/locations';
import { Domicilio } from '../models/domicilio';
import { CodigoPostal } from '../models/combo';
import { GetCentroEnvasado } from '../models/get_centro_envasado';
import { PostCentroEnvasado } from '../models/post_centro_envasado';
import { FormGroup } from '@angular/forms';
@Injectable({
  providedIn: 'root'
})
export class AttachedService {

  URL_ADHERIDOS: string = this.environment.urlBackAdheridos;
  URL_CORE: string = this.environment.urlBackCore;
  URL_DOCUM: string = this.environment.urlBackDocum;

  searchForm: FormGroup | undefined;

  private tempRutaSearch: boolean = false;

  get getTempRutaSearch() {
    return this.tempRutaSearch
  }


  setTempRutaSearch(data: any) {
    this.tempRutaSearch = data;
  }

  static readonly CENTRO_ENVASADO: string =
    'api/v1/adheridos/centros-envasado';


  constructor(
    @Inject('environment') private environment: any,
    private httpClient: HttpClient,
  ) { }


  getAdheridos(pageNumber: number, pageSize: number, identificador?: string, numRegistro?: string, nombreGrupo?: string, autonomia?: string, documentType?: string, document?: string, rsocial?: string, cifra?: string, cnae?: string, estado?: string): Observable<listAdheridos> {
    let fullRoute = `${this.URL_ADHERIDOS}api/v1/adheridos/adheridos?page=${pageNumber - 1}&size=${pageSize}`;
    // Función auxiliar para agregar parámetros si están definidos y no son nulos

    const addParam = (paramName: string, paramValue: string) => {
      if (paramValue !== null && paramValue !== undefined && paramValue !== '') {
        fullRoute += `&${paramName}=${encodeURIComponent(paramValue)}`;
      }
    };

    // Agregar parámetros a la URL si tienen datos
    addParam('id', identificador!);
    addParam('registroProductor', numRegistro!);
    addParam('nombreGrupo', nombreGrupo!);
    addParam('comunidadAutonoma', autonomia!);
    addParam('tipoDocumento', documentType!);
    addParam('codigoDocumento', document!);
    addParam('razonSocial', rsocial!);
    addParam('cifraNegocio', cifra!);
    addParam('cnae', cnae!);
    addParam('estado', estado!);


    return this.httpClient.get<listAdheridos>(fullRoute).pipe(
      retry(1),
      filter((v) => v !== null)
    );
  }

  createAdherido(data: Adherido) {
    const url = `${this.URL_ADHERIDOS}api/v1/adheridos/adheridos/alta-preliminar`;

    return this.httpClient.post(url, data);
  }

  getContactos(): Observable<any> {
    const fullRoute = `${this.URL_CORE}api/v1/core/contactos?page=0&size=10`;
    return this.httpClient.get<any>(fullRoute).pipe(
      retry(1),
      filter((v) => v !== null)
    );
  }


  putContactos(id: number, body: Contacto): Observable<any> {
    const fullRoute = `${this.URL_CORE}api/v1/core/contactos/` + id;
    return this.httpClient.put(fullRoute, body).pipe(
      retry(1),
      filter((v) => v !== null)
    )
  }

  eliminarContacto(id: number) {
    const url = `${this.URL_CORE}api/v1/core/contactos/${id}`;
    return this.httpClient.delete<any>(url);
  }

  getTramos_cuota(): Observable<Tramo_cuota[]> {
    const fullRoute = `${this.URL_ADHERIDOS}api/v1/adheridos/tramos-cuota`;
    return this.httpClient.get<any>(fullRoute).pipe(
      retry(1),
      filter((v) => v !== null)
    )
  }

  getSectores(): Observable<Sectores[]> {
    const fullRoute = `${this.URL_ADHERIDOS}api/v1/adheridos/sectores`;
    return this.httpClient.get<any>(fullRoute).pipe(
      retry(1),
      filter((v) => v !== null)
    )
  }

  getAdheridoPorId(id: number): Observable<Adherido> {
    const fullRoute = `${this.URL_ADHERIDOS}api/v1/adheridos/adheridos/` + id;
    return this.httpClient.get<any>(fullRoute).pipe(
      map((resp: any) => {
        resp?.domicilios?.forEach((domicilio: any) => {
          let tipoVia = domicilio?.tipoVia && domicilio?.tipoVia !== null && domicilio?.tipoVia?.nombre && domicilio?.tipoVia?.nombre !== null ? domicilio?.tipoVia?.nombre : '';
          let nombreVia = domicilio?.nombreVia && domicilio?.nombreVia !== null ? domicilio?.nombreVia : '';
          let numero = domicilio?.numero && domicilio?.numero !== null ? domicilio?.numero : '';
          let bloque = domicilio?.bloque && domicilio?.bloque !== null ? domicilio?.bloque : '';
          let escalera = domicilio?.escalera && domicilio?.escalera !== null ? domicilio?.escalera : '';
          let piso = domicilio?.piso && domicilio?.piso !== null ? domicilio?.piso : '';
          let puerta = domicilio?.puerta && domicilio?.puerta !== null ? domicilio?.puerta : '';
          let municipio = domicilio?.municipio && domicilio?.municipio !== null ? domicilio?.municipio : '';
          let cp = domicilio?.codigoPostal && domicilio?.codigoPostal !== null && domicilio?.codigoPostal?.codigoPostal && domicilio?.codigoPostal?.codigoPostal !== null ? domicilio?.codigoPostal?.codigoPostal : '';
          domicilio.direccionCompleta = tipoVia + ' ' + nombreVia + ' ' + numero + ' ' + bloque + ' ' + escalera + ' ' + piso + ' ' + puerta + ' ' + municipio + ' ' + cp;
        });
        return resp;
      })
    )
  }

  putAdherido(id: number, body: Adherido): Observable<any> {
    const fullRoute = `${this.URL_ADHERIDOS}api/v1/adheridos/adheridos/` + id;
    return this.httpClient.put(fullRoute, body).pipe(
      retry(1),
      filter((v) => v !== null)
    )
  }

  deleteAdherido(id: string) {
    const url = `${this.URL_ADHERIDOS}api/v1/adheridos/adheridos/${id}`;

    return this.httpClient.delete<any>(url).pipe(
      retry(1),
      filter((v) => v !== null)
    );
  }

  bajaAdherido(id: string) {
    const url = `${this.URL_ADHERIDOS}api/v1/adheridos/adheridos/${id}/baja`;

    return this.httpClient.put<any>(url, id).pipe(
      retry(1),
      filter((v) => v !== null)
    );
  }
  getEstadosAdheridos() {
    const url = `${this.URL_ADHERIDOS}api/v1/adheridos/adherido-estados`;
    return this.httpClient.get<any>(url).pipe(
      retry(1),
      filter((v) => v !== null)
    )
  }
  getTiposCnae(): Observable<any> {
    const fullRoute = `${this.URL_CORE}api/v1/core/cnae`;
    return this.httpClient.get<any>(fullRoute).pipe(
      retry(1),
      filter((v) => v !== null)
    )
  }
  getDirecciones(): Observable<any> {
    const fullRoute = `${this.URL_CORE}api/v1/core/direcciones`;
    return this.httpClient.get<any>(fullRoute).pipe(
      retry(1),
      filter((v) => v !== null)
    )
  }

  createDireccion(data: Domicilio) {
    const url = `${this.URL_CORE}api/v1/core/direcciones`;
    return this.httpClient.post(url, data);
  }

  putDireccion(id: number, body: Domicilio) {
    const url = `${this.URL_CORE}api/v1/core/direcciones/${id}`;
    return this.httpClient.put(url, body).pipe(
      retry(1),
      filter((v) => v !== null)
    )
  }

  eliminarDireccion(id: number) {
    const url = `${this.URL_CORE}api/v1/core/direcciones/${id}`;
    return this.httpClient.delete<any>(url);
  }

  getTiposDireccion(): Observable<any> {
    const fullRoute = `${this.URL_CORE}api/v1/core/tipos-direccion`;
    return this.httpClient.get<any>(fullRoute).pipe(
      retry(1),
      filter((v) => v !== null)
    )
  }
  getTiposContacto(): Observable<any> {
    const fullRoute = `${this.URL_CORE}api/v1/core/tipos-contacto`;
    return this.httpClient.get<any>(fullRoute).pipe(
      retry(1),
      filter((v) => v !== null)
    )
  }
  getTiposVia(): Observable<any> {
    const fullRoute = `${this.URL_CORE}api/v1/core/via-types`;
    return this.httpClient.get<any>(fullRoute).pipe(
      retry(1),
      filter((v) => v !== null)
    )
  }
  getTiposDocumento(): Observable<any> {
    const fullRoute = `${this.URL_CORE}api/v1/core/tipos-documento`;
    return this.httpClient.get<any>(fullRoute).pipe(
      retry(1),
      filter((v) => v !== null)
    )
  }

  getProvincias(): Observable<any> {
    const fullRoute = `${this.URL_CORE}api/v1/core/provinces`;
    return this.httpClient.get<any>(fullRoute).pipe(
      retry(1),
      filter((v) => v !== null)
    )
  }


  getProvinciasByCp(
    pageNumber: number,
    pageSize: number
  ): Observable<any> {
    const fullRoute = `${this.URL_CORE}api/v1/core/provinces`
    return this.httpClient.get<any>(
      `${fullRoute}?page=${pageNumber}&size=${pageSize}`);
  }


  getCodigosPostales(): Observable<CodigoPostal> {
    const fullRoute = `${this.URL_CORE}api/v1/core/postalcodes`;
    return this.httpClient.get<any>(fullRoute).pipe(
      retry(1),
      filter((v) => v !== null)
    )
  }

  getCategoriasEnvases(): Observable<any> {
    const fullRoute = `${this.URL_CORE}api/v1/core/packaging-category`;
    return this.httpClient.get<any>(fullRoute).pipe(
      retry(1),
      filter((v) => v !== null)
    )
  }
  getPaises(): Observable<any> {
    const fullRoute = `${this.URL_CORE}api/v1/core/countries`;
    return this.httpClient.get<any>(fullRoute).pipe(
      retry(1),
      filter((v) => v !== null)
    )
  }

  getDocumentos(idEntidad: any): Observable<any> {
    const fullRoute = `${this.URL_DOCUM}api/v1/docum/entity-type/adherido/entity/${idEntidad}`;
    return this.httpClient.get<any>(fullRoute).pipe(
      retry(1),
      filter((v) => v !== null)
    )
  }

  httpDownloadOptions: Object = {
    headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' }),
    responseType: 'blob'
  }
  descargarDocumento(id: number): Observable<any> {
    const fullRoute = `${this.URL_DOCUM}api/v1/docum/download/${id}`;
    return this.httpClient.get<any>(fullRoute, this.httpDownloadOptions);
  }

  putDocumento(file: File, id: number, containerName: string, entityId: number, documentType: number, entityType: string, fileName: string): Observable<any> {
    const fullRoute = `${this.URL_DOCUM}api/v1/docum/${id}/upload?entityId=${entityId}&entityType=${entityType}&containerName=${containerName}&documentType=${documentType}&fileName=${fileName}`;
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.put(
      fullRoute,
      formData,
      { responseType: 'text' }
    )
  }

  postDocumento(file: File, containerName: string, documentType: number, documentDesc: string, entityId: number, entityType: string, fileName: string): Observable<any> {
    const fullRoute = `${this.URL_DOCUM}api/v1/docum/upload?entityId=${entityId}&entityType=${entityType}&containerName=${containerName}&documentType=${documentType}&documentDesc=${documentDesc}&fileName=${fileName}`;
    const formData: FormData = new FormData();
    formData.append('file', file);
    return this.httpClient.post(
      fullRoute,
      formData,
      { responseType: 'text' }
    )
  }

  eliminarDocumento(id: number): Observable<any> {
    const fullRoute = `${this.URL_DOCUM}api/v1/docum/${id}`;
    return this.httpClient.delete<any>(fullRoute)
  }

  // generarContrato(id: number): Observable<any> {
  //   const fullRoute = `${this.URL_ADHERIDOS}api/v1/adheridos/contrato/${id}`;
  //   return this.httpClient.get<any>(fullRoute, this.httpDownloadOptions);
  // }
  // generarFacturaProforma(id: number): Observable<any> {
  //   const fullRoute = `${this.URL_ADHERIDOS}api/v1/adheridos/factura/${id}`;
  //   return this.httpClient.get<any>(fullRoute, this.httpDownloadOptions);
  // }
  /**
   * @deprecated
   * @returns
   */
  exportarAdheridoExcel(): Observable<any> {
    const fullRoute = `${this.URL_ADHERIDOS}api/v1/adheridos/report/xls`;
    return this.httpClient.get<any>(fullRoute, this.httpDownloadOptions);
  }

  public exportCSV(filtro: any, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>> {
    if (filtro === null || filtro === undefined) {
      throw new Error('Required parameter filtro was null or undefined when calling exportCSV.');
    }

    const encoder = new CustomHttpParameterCodec();

    let localVarQueryParameters = new HttpParams({ encoder: encoder });

    if (filtro !== undefined && filtro !== null) {
      localVarQueryParameters = this.addToHttpParams(localVarQueryParameters,
        <any>filtro, 'filtro');
    }
    let localVarHeaders = new HttpHeaders();

    let localVarCredential: string | undefined;

    let localVarHttpHeaderAcceptSelected: string | undefined = '*/*';
    localVarHeaders = localVarHeaders.set('Accept', localVarHttpHeaderAcceptSelected);


    let localVarHttpContext = new HttpContext();

    const fullRoute = `${this.URL_ADHERIDOS}api/v1/adheridos/adheridos/export/csv`;
    return this.httpClient.request('get', `${fullRoute}`,
      {
        context: localVarHttpContext,
        params: localVarQueryParameters,
        responseType: "blob",
        headers: localVarHeaders,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
  exportarAdheridoPdf(): Observable<any> {
    const fullRoute = `${this.URL_ADHERIDOS}api/v1/adheridos/report/pdf`;
    return this.httpClient.get<any>(fullRoute, this.httpDownloadOptions);
  }

  getEndpointCentroEnvasado(
    pageNumber: number,
    pageSize: number
  ): Observable<GetCentroEnvasado> {
    return this.httpClient.get<GetCentroEnvasado>(
      this.URL_ADHERIDOS + `${AttachedService.CENTRO_ENVASADO}?page=${pageNumber}&size=${pageSize}`);
  }

  postEndpointCentroEnvasado(data: PostCentroEnvasado, idAdherido?: number) {
    let idEntidadParam = idAdherido ? `?entityId=${idAdherido}` : '';
    return this.httpClient.post(this.URL_ADHERIDOS + `${AttachedService.CENTRO_ENVASADO}${idEntidadParam}`, data);
  }

  putEndpointCentroEnvasado(id: number, data: GetCentroEnvasado) {
    return this.httpClient.put(this.URL_ADHERIDOS + `${AttachedService.CENTRO_ENVASADO}/${id}`, data);
  }

  deleteEndpointCentroEnvasado(id: number) {
    return this.httpClient.delete(this.URL_ADHERIDOS + `${AttachedService.CENTRO_ENVASADO}/${id}`)
  }

  private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
    if (typeof value === "object" && value instanceof Date === false) {
      httpParams = this.addToHttpParamsRecursive(httpParams, value);
    } else {
      httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
    }
    return httpParams;
  }

  private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
    if (value == null) {
      return httpParams;
    }

    if (typeof value === "object") {
      if (Array.isArray(value)) {
        (value as any[]).forEach(elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
      } else if (value instanceof Date) {
        if (key != null) {
          httpParams = httpParams.append(key, (value as Date).toISOString().substring(0, 10));
        } else {
          throw Error("key may not be null if value is Date");
        }
      } else {
        Object.keys(value).forEach(k => httpParams = this.addToHttpParamsRecursive(
          httpParams, value[k], key != null ? `${key}.${k}` : k));
      }
    } else if (key != null) {
      httpParams = httpParams.append(key, value);
    } else {
      throw Error("key may not be null if value is not object or array");
    }
    return httpParams;
  }

}

export class CustomHttpParameterCodec implements HttpParameterCodec {
  encodeKey(k: string): string {
    return encodeURIComponent(k);
  }
  encodeValue(v: string): string {
    return encodeURIComponent(v);
  }
  decodeKey(k: string): string {
    return decodeURIComponent(k);
  }
  decodeValue(v: string): string {
    return decodeURIComponent(v);
  }
}
