import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Combo, Combo2 } from '../../../models/combo';
import { ValidateService } from '../../../services/validate.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { CombosService } from '../../../services/combos.service';
import { SnackbarService } from '../../../services/snackbar.service';
import {
  ContactoControllerService,
  ContactoModel,
  GestoresResiduosControllerService,
  TipoContactoControllerService,
  GestoresResiduosGeneralPayloadDTO,
  GestorPtoRecogidaControllerService,
} from 'api-rest';

export interface MappedResponse<T> {
  totalElements: number;
  recordsFiltered: number;
  datos: T[];
}

@Component({
  selector: 'app-collection-points-managers-contact-dialog',
  templateUrl: './collection-points-managers-contact-dialog.component.html',
  styleUrls: ['./collection-points-managers-contact-dialog.component.scss'],
})
export class CollectionPointsManagersContactDialogComponent implements OnInit {
  contactosEndpoint = this.environment.urlBackCore + 'api/v1/core/contactos';
  paisesEndpoint = this.environment.urlBackCore + 'api/v1/core/countries';
  contacto: ContactoModel;
  titulo = '';
  puntoRecogidaInfo!: any;

  formGroup!: FormGroup;
  direccion: any;
  tiposDirecciones: Combo2[] = [];
  contactos: any[] = [];
  paises: any[] = [];
  provincias: any[] = [];
  codigosPostales: any[] = [];
  municipios: any[] = [];
  tiposVia: any[] = [];
  tiposContactos: any;
  updateList: boolean = false;
  flagUploadCopy: boolean = false;
  tiposDocumento: Combo[] = [];
  tiposTelefono: Combo[] = [];
  organos: Combo[] = [];
  personType: boolean | undefined = true;
  copiarContacto: any[] = [];
  paisPrefijo!: any;
  flagFirmante: boolean = false;
  flagFacturacion: boolean = false;
  flagRadioNo: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CollectionPointsManagersContactDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private validateSrv: ValidateService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    @Inject('environment') private environment: any,
    public comboSrv: CombosService,
    private snackBarSrv: SnackbarService,
    private tipoContactoControllerService: TipoContactoControllerService,
    private contactoControllerService: ContactoControllerService,
    private gestorPtoRecogidaControllerService: GestorPtoRecogidaControllerService
  ) {
    this.contacto = this.data.contacto;
    this.puntoRecogidaInfo = this.data.puntoRecogidaInfo;
    this.formGroup = this.formBuilder.group({
      tipoContacto: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            Validators.required,
          ]),
        },
      ],
      copiaContacto: [{ value: null, disabled: false }],
      requerido: [{ value: null, disabled: false }],
      persona: [
        { value: false, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      nombre: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(50)]) },
      ],
      apellido1: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(50)]) },
      ],
      apellido2: [{ value: null, disabled: false },
        {validators: Validators.compose([Validators.maxLength(50)])}
      ],
      documentType: [{ value: null, disabled: false}],
      document: [
        {value: null, disabled: false},
        {validators: Validators.compose([Validators.maxLength(15)])}
      ],
      tipoTelefono: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      prefijoPais: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      telefono: [
        { value: null, disabled: false }, Validators.compose([Validators.required, this.telefonoValidator.bind(this),Validators.maxLength(15)])
      ],
      recibirComunicaciones: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      recibirComunicacionesContrato: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      acreditacionPoderes: [{ value: null, disabled: false }],
      email: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(this.validateSrv.emailPattern),
            Validators.maxLength(100)
          ]),
        },
      ],
      organoParticipa: [{ value: null, disabled: false }],
    });
  }

  ngOnInit(): void {
    if (this.contacto) {
      this.titulo = 'Editar contacto';
      this.cargarContactos(this.contacto, true);
    } else {
      this.titulo = 'Nuevo contacto';
      this.formGroup.get('persona')?.setValue(true);
    }
    this.getTiposContactos();
    this.tiposTelefono = this.comboSrv.getTiposTelefono();
    this.tiposDocumento = this.comboSrv.getTiposDocumentoIdentidad();
    this.organos = this.comboSrv.getOrganos();

    this.copiarContacto = this.puntoRecogidaInfo?.contactos?.filter(
      (objeto: any) => objeto.nombre !== null && objeto.nombre !== ''
    );
    if (
      !this.authService.can('ptosrecogida-gestor-contactos', 'update') &&
      this.puntoRecogidaInfo?.estado?.id == 4
    ) {
      this.formGroup.disable();
    }

    this.formGroup.get('tipoTelefono')?.valueChanges.subscribe(() => {
      this.formGroup.get('telefono')?.updateValueAndValidity();
    });

    this.formGroup.get('prefijoPais')?.valueChanges.subscribe(() => {
      this.formGroup.get('telefono')?.updateValueAndValidity();
    });

    this.cargarFirmante();
  }

  canAccessUpdate(): boolean {
    const resultAuth =
      this.authService.can('ptosrecogida-gestor-contactos', 'update') &&
      this.puntoRecogidaInfo?.estado?.id != 4;

    if (!resultAuth) {
      this.formGroup.disable();
    }

    return resultAuth;
  }

  getTiposContactos(): void {
    this.tipoContactoControllerService
      .listarTiposContacto({
        page: 0,
        size: 500,
        sort: [],
      })
      .subscribe({
        next: (response) => {
          this.tiposContactos = response.content;
        },
        error: (err) => {
          console.log(err);
        },
      });
  }

  onTipoEntidadChange(): void {
    const persona = this.formGroup.get('persona')?.value;
    const documentType = this.formGroup.get('documentType')?.value;

    if (this.formGroup) {
      const nombreControl = this.formGroup.get('nombre');
      const apellido1Control = this.formGroup.get('apellido1');
      const apellido2Control = this.formGroup.get('apellido2');
      const documentTypeControl = this.formGroup.get('documentType');

      if (
        nombreControl &&
        apellido1Control &&
        apellido2Control &&
        documentTypeControl
      ) {
        // Restablecer la visibilidad
        nombreControl?.enable();
        apellido1Control?.enable();
        apellido2Control?.enable();
        documentTypeControl?.enable();
        // documentTypeControl?.reset();
        this.personType = persona;

        // Ocultar y cambiar validación según la opción seleccionada
        if (!persona) {
          // Empresa
          apellido1Control?.disable();
          apellido2Control?.disable();
          documentTypeControl?.setValue('NIF');
          documentTypeControl?.disable();
          this.tipoDocumentoSel('NIF');
        } else {
          this.tipoDocumentoSel(documentType);
        }
        nombreControl?.updateValueAndValidity();
        apellido1Control?.updateValueAndValidity();
        apellido2Control?.updateValueAndValidity();
      }
    }
  }

  cargarContactos(contacto: ContactoModel, editar: boolean): void {
    if (!contacto) return;

    this.personType = contacto.persona;
    this.formGroup.patchValue({
      persona: contacto?.persona,
      nombre: contacto?.nombre,
      apellido1: contacto?.apellido1,
      apellido2: contacto?.apellido2,
      documentType: contacto?.tipo_documento,
      document: contacto?.codigo_documento,
      tipoTelefono: contacto?.tipo_telefono,
      prefijoPais: contacto?.codigoPais?.prefijoNombre,
      telefono: contacto?.numero_telefono,
      recibirComunicaciones: contacto?.recibir_comunicaciones,
      recibirComunicacionesContrato: contacto?.recibir_comunicaciones_contrato,
      acreditacionPoderes: contacto?.acreditacion_poderes,
      organoParticipa: contacto?.organo_participa,
      email: contacto?.email,
    });

    this.paisPrefijo = contacto?.codigoPais;
    // Si no es edición no se cargan requerido ni tipo de contacto
    if (editar) {
      this.formGroup.get('requerido')?.setValue(contacto?.requerido);
      this.formGroup.get('tipoContacto')?.setValue(contacto?.tipo_contacto?.id);
    }
    this.validacionCargaDatos(contacto);
  }

  validacionCargaDatos(contacto: ContactoModel) {
    this.formGroup.get('documentType')?.clearValidators();
    this.formGroup.get('document')?.clearValidators();
    if (contacto?.requerido && contacto?.tipo_contacto?.id !== 5) {
      this.formGroup.get('tipoContacto')?.disable();
    }

    if (
      contacto?.tipo_contacto?.id === 3 ||
      contacto?.tipo_contacto?.id === 4
    ) {
      // Si tipo contacto firmante o reperesentante autorizado tipo documento y documento, son obligatorios
      this.formGroup.get('documentType')?.setValidators([Validators.required]);
      this.formGroup.get('document')?.setValidators([Validators.required]);
    } else {
      // en cualquier otro caso, no serán obligatorios
      this.formGroup.get('documentType')?.clearValidators();
      this.formGroup.get('document')?.clearValidators();
    }

    if (contacto?.tipo_documento === 'NIF') {
      if (
        this.formGroup.get('tipoContacto')?.value === 3 ||
        this.formGroup.get('tipoContacto')?.value === 4
      ) {
        this.formGroup
          .get('document')
          ?.setValidators([
            Validators.required,
            Validators.pattern(this.validateSrv.nifPattern),
          ]);
      } else {
        this.formGroup
          .get('document')
          ?.setValidators([Validators.pattern(this.validateSrv.nifPattern)]);
      }
    }
    if (contacto?.tipo_documento === 'DNI') {
      if (
        this.formGroup.get('tipoContacto')?.value === 3 ||
        this.formGroup.get('tipoContacto')?.value === 4
      ) {
        this.formGroup
          .get('document')
          ?.setValidators([
            Validators.required,
            Validators.pattern(this.validateSrv.dniPattern),
          ]);
      } else {
        this.formGroup
          .get('document')
          ?.setValidators([Validators.pattern(this.validateSrv.dniPattern)]);
      }
    }
    if (contacto?.tipo_documento === 'NIE') {
      if (
        this.formGroup.get('tipoContacto')?.value === 3 ||
        this.formGroup.get('tipoContacto')?.value === 4
      ) {
        this.formGroup
          .get('document')
          ?.setValidators([
            Validators.required,
            Validators.pattern(this.validateSrv.niePattern),
          ]);
      } else {
        this.formGroup
          .get('document')
          ?.setValidators([Validators.pattern(this.validateSrv.niePattern)]);
      }
    }

    if ([1, 2, 5].includes(this.formGroup.get('tipoContacto')?.value)) {
      this.flagFacturacion = true;
      this.flagRadioNo = false;
      this.formGroup.get('acreditacionPoderes')?.clearValidators();
      this.formGroup.get('acreditacionPoderes')?.updateValueAndValidity();
    }

    if ([3, 4].includes(this.formGroup.get('tipoContacto')?.value)) {
      this.flagRadioNo = true;
      this.flagFirmante = true;
      this.formGroup
        .get('acreditacionPoderes')
        ?.setValidators([Validators.required]);
      this.formGroup.get('acreditacionPoderes')?.updateValueAndValidity();

      if (!this.formGroup.get('acreditacionPoderes')?.value) {
        this.formGroup.get('acreditacionPoderes')?.reset();
      }
    }

    if (!this.formGroup.get('persona')?.value) {
      this.formGroup.get('apellido1')?.disable();
    }

    if (this.formGroup.get('tipoContacto')?.value === 3) {
      this.formGroup.get('documentType')?.setValidators([Validators.required]);
      this.formGroup.get('document')?.setValidators([Validators.required]);
    }

    this.formGroup.get('documentType')?.updateValueAndValidity();
    this.formGroup.get('document')?.updateValueAndValidity();

    this.formGroup.get('telefono')?.updateValueAndValidity();

    this.formGroup.markAllAsTouched();

    this.onTipoEntidadChange();
  }

  telefonoValidator(control: AbstractControl): { [key: string]: any } | null {
    const tipoTelefono = this.formGroup?.get('tipoTelefono')?.value;
    const prefijoPais = this.formGroup?.get('prefijoPais')?.value;

    const phonePattern =
      /^(\+?\d{1,3})?[-. (]?(\d{1,4})[-. )]?(\d{1,4})[-. ]?(\d{1,4})[-. ]?(\d{1,9})$/;
    const isGeneralValid = phonePattern.test(control.value);

    if (!isGeneralValid) {
      return { invalidPhoneFormat: true };
    }
    if (
      prefijoPais?.prefijo == '34' ||
      (typeof prefijoPais === 'string' && prefijoPais?.toUpperCase() == '34 (ESPAÑA)')
    ) {
      if (tipoTelefono === 'FIJO') {
        const valid = /^[89][0-9]{8}$/.test(control.value);
        return valid ? null : { invalidTelefonoFijo: true };
      } else if (tipoTelefono === 'MOVIL') {
        const valid = /^[67][0-9]{8}$/.test(control.value);
        return valid ? null : { invalidTelefonoMovil: true };
      }
    }

    return null;
  }

  cargarFirmante() {
    this.formGroup.get('tipoContacto')?.valueChanges.subscribe((value: any) => {
      if ([3, 4].includes(value)) {
        this.flagFirmante = true;
        this.formGroup
          .get('acreditacionPoderes')
          ?.setValidators([Validators.required]);
        this.formGroup.get('acreditacionPoderes')?.updateValueAndValidity();
      } else {
        this.flagFirmante = false;
        this.formGroup.get('acreditacionPoderes')?.clearValidators();
        this.formGroup.get('acreditacionPoderes')?.updateValueAndValidity();
      }
      if ([1, 2].includes(value)) {
        this.flagFacturacion = true;
      } else {
        this.flagFacturacion = false;
      }

      if (![3, 4].includes(value)) {
        this.flagRadioNo = false;
      } else {
        this.flagRadioNo = true;
        if (!this.formGroup.get('acreditacionPoderes')?.value) {
          this.formGroup.get('acreditacionPoderes')?.reset();
        }
      }
    });
  }

  guardar() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.valid) {
      let contacto = this.tiposContactos.find(
        (x: any) => x.id === this.formGroup.get('tipoContacto')?.value
      );

      const editContacto: any = {
        id: this.contacto?.id,
        tipo_contacto: contacto,
        requerido: this.formGroup.get('requerido')?.value,
        persona: this.formGroup.get('persona')?.value,
        nombre: this.formGroup.get('nombre')?.value,
        apellido1: this.formGroup.get('persona')?.value ? this.formGroup.get('apellido1')?.value : null,
        apellido2: this.formGroup.get('persona')?.value ? this.formGroup.get('apellido2')?.value : null,
        tipo_documento: this.formGroup.get('documentType')?.value,
        codigo_documento: this.formGroup.get('document')?.value,
        tipo_telefono: this.formGroup.get('tipoTelefono')?.value,
        numero_telefono: this.formGroup.get('telefono')?.value,
        email: this.formGroup.get('email')?.value,
        recibir_comunicaciones: this.formGroup.get('recibirComunicaciones')
          ?.value,
        recibir_comunicaciones_contrato: this.formGroup.get(
          'recibirComunicacionesContrato'
        )?.value,
        organo_participa: this.formGroup.get('organoParticipa')?.value,
        acreditacion_poderes: this.formGroup.get('acreditacionPoderes')?.value,
      };
      // Si es un string es porque no he modificado el valor que me ha llegado del servicio
      let prefijo = this.formGroup.get('prefijoPais')?.value;
      if (typeof prefijo === 'string') {
        editContacto.codigoPais = this.paisPrefijo;
      } else {
        editContacto.codigoPais = this.formGroup.get('prefijoPais')?.value;
      }

      if (this.contacto) {
        this.contactoControllerService
          .actualizarContacto(this.contacto.id!, editContacto)
          .subscribe({
            next: () => this.handleSuccess(this.contacto),
            error: () => this.handleError(),
          });
      } else {
        this.puntoRecogidaInfo.contactos.push(editContacto);
        this.gestorPtoRecogidaControllerService
          .editByIdGestorPtoRecogida(
            this.puntoRecogidaInfo.id!,
            this.puntoRecogidaInfo
          )
          .subscribe({
            next: () => this.handleSuccess(editContacto),
            error: () => this.handleError(),
          });
      }
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  cpSeleccionado(idCp: number) {
    const cpSeleccionado = this.codigosPostales.find((x) => x.id === idCp);
    this.formGroup.get('municipio')?.setValue(cpSeleccionado?.municipio);
  }
  cerrar(): void {
    this.dialogRef.close(null);
  }
  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }

  selectedModel(selectForm: any) {
    switch (selectForm) {
      case 'copiaContacto': {
        this.cargarContactos(
          this.formGroup.controls['copiaContacto']?.value,
          false
        );
        break;
      }
      case 'prefijoPais': {
        this.paisPrefijo = this.formGroup.controls['prefijoPais']?.value;
        break;
      }
      default: {
        break;
      }
    }
  }

  filterList(data: any, selectForm: any) {
    switch (selectForm) {
      case 'copiaContacto': {
        return this.formGroup.get('copiaContacto') as FormControl;
        break;
      }
      case 'prefijoPais': {
        return this.formGroup.get('prefijoPais') as FormControl;
        break;
      }
      default: {
        return this.formGroup.get('') as FormControl;
        break;
      }
    }
  }
  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'copiaContacto': {
        return this.formGroup.get('copiaContacto') as FormControl;
        break;
      }
      case 'prefijoPais': {
        return this.formGroup.get('prefijoPais') as FormControl;
        break;
      }
      default: {
        return this.formGroup.get('') as FormControl;
        break;
      }
    }
  }
  defaultMapResponse<T>(response: any): MappedResponse<T> {
    return <MappedResponse<T>>{
      totalElements: response.totalElements,
      recordsFiltered: response.totalPages,
      datos: response.content,
    };
  }
  tipoDocumentoSel(idTipoDoc: string) {
    this.formGroup.get('document')?.clearValidators();
    if (idTipoDoc === 'NIF') {
      if (
        this.formGroup.get('tipoContacto')?.value == 3 ||
        this.formGroup.get('tipoContacto')?.value == 4
      ) {
        this.formGroup
          .get('document')
          ?.setValidators([
            Validators.required,
            Validators.pattern(this.validateSrv.nifPattern),
          ]);
      } else {
        this.formGroup
          .get('document')
          ?.setValidators([Validators.pattern(this.validateSrv.nifPattern)]);
      }
    } else if (idTipoDoc === 'DNI') {
      if (
        this.formGroup.get('tipoContacto')?.value == 3 ||
        this.formGroup.get('tipoContacto')?.value == 4
      ) {
        this.formGroup
          .get('document')
          ?.setValidators([
            Validators.required,
            Validators.pattern(this.validateSrv.dniPattern),
          ]);
      } else {
        this.formGroup
          .get('document')
          ?.setValidators([Validators.pattern(this.validateSrv.dniPattern)]);
      }
    } else if (idTipoDoc === 'NIE') {
      if (
        this.formGroup.get('tipoContacto')?.value == 3 ||
        this.formGroup.get('tipoContacto')?.value == 4
      ) {
        this.formGroup
          .get('document')
          ?.setValidators([
            Validators.required,
            Validators.pattern(this.validateSrv.niePattern),
          ]);
      } else {
        this.formGroup
          .get('document')
          ?.setValidators([Validators.pattern(this.validateSrv.niePattern)]);
      }
    }

    this.formGroup.get('document')?.updateValueAndValidity();
  }

  tipoContactoSel(idTipoContacto?: number | null) {
    if (idTipoContacto === 3 || idTipoContacto === 4) {
      // Si tipo contacto firmante o reperesentante autorizado tipo documento y documento, son obligatorios
      this.formGroup.get('documentType')?.setValidators([Validators.required]);
      this.formGroup.get('document')?.setValidators([Validators.required]);
    } else {
      // en cualquier otro caso, no serán obligatorios
      this.formGroup.get('documentType')?.clearValidators();
      this.formGroup.get('document')?.clearValidators();
    }
    this.formGroup.get('documentType')?.updateValueAndValidity();
    this.formGroup.get('document')?.updateValueAndValidity();

    if ([1, 2, 5].includes(this.formGroup.get('tipoContacto')?.value)) {
      this.flagFacturacion = true;
      this.formGroup.get('acreditacionPoderes')?.clearValidators();
      this.formGroup.get('acreditacionPoderes')?.updateValueAndValidity();
    } else {
      this.formGroup
        .get('acreditacionPoderes')
        ?.setValidators([Validators.required]);
      this.formGroup.get('acreditacionPoderes')?.updateValueAndValidity();
      this.formGroup.get('acreditacionPoderes')?.markAllAsTouched();
    }
  }
  controlHasErrorSelect(controlName: string) {
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }
  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }
  public handleSuccess(contacto: ContactoModel) {
    const message =
      contacto?.tipo_contacto?.id == 1
        ? 'Los cambios de datos de contactos no afectan a los usuarios de acceso a la plataforma. Si se requiere dar de baja a usuarios de su entidad o dar de alta nuevos, debe ponerse en contacto con el departamento de administración de Envalora'
        : 'Datos de contacto guardados correctamente';
    this.snackBarSrv.showSnackBar(message, 'success');
    this.dialogRef.close(true);
  }

  public handleError() {
    this.snackBarSrv.showSnackBar(
      'Ha ocurrido un error al guardar los datos del contacto',
      'error'
    );
  }

  showClearButton(formName: string): boolean {
    return this.formGroup.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.formGroup.get(formName)?.setValue(null);
  }
}
