export * from './agrupacionMaterialController.service';
import { AgrupacionMaterialControllerService } from './agrupacionMaterialController.service';
export * from './categoriaEnvaseController.service';
import { CategoriaEnvaseControllerService } from './categoriaEnvaseController.service';
export * from './cnaeController.service';
import { CnaeControllerService } from './cnaeController.service';
export * from './codigoLerController.service';
import { CodigoLerControllerService } from './codigoLerController.service';
export * from './codigoPostalController.service';
import { CodigoPostalControllerService } from './codigoPostalController.service';
export * from './composicionMaterialController.service';
import { ComposicionMaterialControllerService } from './composicionMaterialController.service';
export * from './comunidadController.service';
import { ComunidadControllerService } from './comunidadController.service';
export * from './contactoController.service';
import { ContactoControllerService } from './contactoController.service';
export * from './direccionController.service';
import { DireccionControllerService } from './direccionController.service';
export * from './entityController.service';
import { EntityControllerService } from './entityController.service';
export * from './grupoEnvaseController.service';
import { GrupoEnvaseControllerService } from './grupoEnvaseController.service';
export * from './paisController.service';
import { PaisControllerService } from './paisController.service';
export * from './prefacturacionHistoricoController.service';
import { PrefacturacionHistoricoControllerService } from './prefacturacionHistoricoController.service';
export * from './provinciaController.service';
import { ProvinciaControllerService } from './provinciaController.service';
export * from './tipoContactoController.service';
import { TipoContactoControllerService } from './tipoContactoController.service';
export * from './tipoDireccionController.service';
import { TipoDireccionControllerService } from './tipoDireccionController.service';
export * from './tipoDocumentoController.service';
import { TipoDocumentoControllerService } from './tipoDocumentoController.service';
export * from './tipoEnvasesController.service';
import { TipoEnvasesControllerService } from './tipoEnvasesController.service';
export * from './tipoViaController.service';
import { TipoViaControllerService } from './tipoViaController.service';
export const APIS = [AgrupacionMaterialControllerService, CategoriaEnvaseControllerService, CnaeControllerService, CodigoLerControllerService, CodigoPostalControllerService, ComposicionMaterialControllerService, ComunidadControllerService, ContactoControllerService, DireccionControllerService, EntityControllerService, GrupoEnvaseControllerService, PaisControllerService, PrefacturacionHistoricoControllerService, ProvinciaControllerService, TipoContactoControllerService, TipoDireccionControllerService, TipoDocumentoControllerService, TipoEnvasesControllerService, TipoViaControllerService];
