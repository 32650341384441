/**
 * Comunicaciones API
 * Comunicaciones API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IdEntityFilterDTO } from './idEntityFilterDTO';


export interface ComunicacionFilterDTO { 
    fechaAlta?: Array<string>;
    creadoPor?: Array<string>;
    fechaModificacion?: Array<string>;
    modificadoPor?: Array<string>;
    email?: string;
    tipo?: ComunicacionFilterDTO.TipoEnum;
    estado?: IdEntityFilterDTO;
    prioridad?: ComunicacionFilterDTO.PrioridadEnum;
    categoria?: ComunicacionFilterDTO.CategoriaEnum;
    fechaIncidencia?: Array<string>;
    asunto?: Array<string>;
}
export namespace ComunicacionFilterDTO {
    export type TipoEnum = 'INCIDENCIA' | 'SUGERENCIA' | 'CONSULTA';
    export const TipoEnum = {
        Incidencia: 'INCIDENCIA' as TipoEnum,
        Sugerencia: 'SUGERENCIA' as TipoEnum,
        Consulta: 'CONSULTA' as TipoEnum
    };
    export type PrioridadEnum = 'BAJA' | 'MEDIA' | 'ALTA';
    export const PrioridadEnum = {
        Baja: 'BAJA' as PrioridadEnum,
        Media: 'MEDIA' as PrioridadEnum,
        Alta: 'ALTA' as PrioridadEnum
    };
    export type CategoriaEnum = 'ADHERIDOS' | 'DAE' | 'POSEEDORES' | 'GEST_FINALES' | 'PTO_RECOGIDA' | 'SDDR_CC' | 'SDDR_CA' | 'AC_GESTORES';
    export const CategoriaEnum = {
        Adheridos: 'ADHERIDOS' as CategoriaEnum,
        Dae: 'DAE' as CategoriaEnum,
        Poseedores: 'POSEEDORES' as CategoriaEnum,
        GestFinales: 'GEST_FINALES' as CategoriaEnum,
        PtoRecogida: 'PTO_RECOGIDA' as CategoriaEnum,
        SddrCc: 'SDDR_CC' as CategoriaEnum,
        SddrCa: 'SDDR_CA' as CategoriaEnum,
        AcGestores: 'AC_GESTORES' as CategoriaEnum
    };
}


