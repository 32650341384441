<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title font">{{titulo}}</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrar()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form class="form-modal py-3" [formGroup]="formGroup">
            <div class="row-fields">
                <div class="field field-grow">
                    <label for="usuarioGestor">Usuario gestor <span class="oblig">*</span></label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <mat-select formControlName="usuarioGestor" id="usuarioGestor"
                            placeholder="-- Usuario gestor --">
                            <mat-option *ngFor="let gestor of listaGestores" [value]="gestor.id">
                                {{ gestor?.razonSocial }} - {{gestor?.centro}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="controlHasError('usuarioGestor', 'required', formGroup)">Campo obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="field field-25">
                    <div style="display: flex; align-items: center;">
                        <label for="idRetirada">ID. Solicitud Retirada <span class="oblig">*</span></label>
                        <div class="tool-help"
                            matTooltip="Haga clic en la lupa para cargar la solicitud de retirada con el identificador proporcionado"
                            data-placement="top" title="">
                            <span>?</span>
                        </div>
                    </div>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <input type="number" matInput formControlName="idRetirada" id="idRetirada"
                            placeholder="Pulse en la lupa para buscar" value="" (keypress)="filtroNumeros($event)" />
                        <mat-error *ngIf="controlHasError('idRetirada', 'required', formGroup)">Campo
                            obligatorio
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="field field-5" *ngIf="!soloLectura">
                    <button type="button" class="btn btn-outline-primary" data-dismiss="modal"
                        (click)="buscarSolicitudRetirada(true)" aria-label="buscar por Identificador"
                        style="margin-top:25px"><span>
                            <mat-icon>search</mat-icon>
                        </span></button>
                </div>
            </div>
            <div class="row-fields">
                <div class="field field-19">
                    <label for="tipoEnvase">Tipo de envase</label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <input type="text" matInput formControlName="tipoEnvase" id="tipoEnvase" value="" />
                    </mat-form-field>
                </div>
                <!-- <div class="field field-19">
                    <label for="subtipoEnvase">Subtipo de envase</label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <input type="text" matInput formControlName="subtipoEnvase" id="subtipoEnvase" value="" />
                    </mat-form-field>
                </div> -->
                <div class="field field-19" *ngIf="false">
                    <label for="loteEnvase">Lote del envase</label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <input type="text" matInput formControlName="loteEnvase" id="loteEnvase" value="" />
                    </mat-form-field>
                </div>
                <div class="field field-19">
                    <label for="reutilizarUd">Envases aptos reutilizar (ud)</label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <input type="text" matInput formControlName="reutilizarUd" id="reutilizarUd" value="" />
                    </mat-form-field>
                </div>
                <div class="field field-19">
                    <label for="reutilizarKg">Envases aptos reutilizar (kg)</label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <input type="text" matInput formControlName="reutilizarKg" id="reutilizarKg" value="" />
                    </mat-form-field>
                </div>
            </div>
            <div class="title-group">Envases que se mantienen en SDDR Abierto de Envalora </div>
            <div class="row-fields">
                <div class="field field-19">
                    <label for="unidades">Unidades <span class="oblig">*</span></label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <input type="text" matInput formControlName="unidades" id="unidades" value="" appNumericPoint
                            maxlength="13" />
                        <mat-error *ngIf="controlHasError('unidades', 'required', formGroup)">Campo
                            obligatorio</mat-error>
                        <mat-error *ngIf="controlHasError('unidades', 'maxlength', formGroup)">Máximo 10
                            caracteres</mat-error>

                    </mat-form-field>
                </div>
                <div class="field field-19">
                    <label for="importeTotal">Importe total (€)</label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <input type="text" matInput formControlName="importeTotal" id="importeTotal" value=""
                            readonly />
                    </mat-form-field>
                </div>
                <div class="field field-grow">
                    <div class="tool-div">
                        <label for="acreditaInformar">Acredita que va informar a su cliente que los envases forman parte
                            del SDDR Abierto <span class="oblig">*</span></label>
                        <div class="tool-help"
                            matTooltip="Se compromete a pagar a ENVALORA el mismo importe de depósito que ha sido facturado a su cliente, a informar a su cliente que estos envases están cubiertos por Envalora y no deben ser declarados a ningún SCRAP ni al Miterd en la declaración de envases"
                            data-placement="top" title="">
                            <span>?</span>
                        </div>
                    </div>
                    <div class="field field-100">
                        <mat-radio-group formControlName="acreditaInformar" id="acreditaInformar"
                            class="custom-mat-form-radio">
                            <mat-radio-button color="primary" [value]="true">Sí</mat-radio-button>
                        </mat-radio-group>
                        <mat-error *ngIf="controlHasError('acreditaInformar', 'required', formGroup)">Campo
                            obligatorio</mat-error>
                    </div>
                </div>
            </div>
        </form>
        <!--si estado solicitud >1-->
        <form *ngIf="solicitud?.estado?.id>1" class="form-modal py-3" [formGroup]="infoSolicitudForm">
            <div class="title-group">Información solicitud </div>
            <div class="row-fields">
                <div class="field field-35">
                    <label for="usuarioSolicitud">Usuario </label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <input type="text" matInput formControlName="usuarioSolicitud" id="usuarioSolicitud" value="" />
                    </mat-form-field>
                </div>
                <div class="field field-grow">
                    <label for="fechaSolicitud">Fecha de solicitud</label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <input type="text" matInput formControlName="fechaSolicitud" id="fechaSolicitud" value="" />
                    </mat-form-field>
                </div>
                <div class="field field-35">
                    <label for="estadoSolicitud">Estado </label>
                    <mat-form-field class="custom-mat-form-field" appearance="outline">
                        <input type="text" matInput formControlName="estadoSolicitud" id="estadoSolicitud" value="">
                    </mat-form-field>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer actions">
        <div class="note"><span>* Campos Obligatorios</span></div>
        <div class="button-group">
            <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
                aria-label="Cancelar">Cancelar</button>
            <button type="button" *ngIf="canAccessGuardar()&&(solicitud?.estado?.id===1 || !solicitudId)"
                class="btn btn-primary" data-dismiss="modal" aria-label="Guardar" (click)="guardar()">Guardar</button>
            <button type="button" *ngIf="canAccessGuardarEnviar()&&(solicitud?.estado?.id===1 || !solicitudId)"
                class="btn btn-primary" data-dismiss="modal" aria-label="Guardar" (click)="guardarEnviar()">Guardar y
                enviar</button>
        </div>
    </div>
</div>