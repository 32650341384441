import { Injectable } from "@angular/core";

export interface Combo {
  id: string | number,
  name: string
}

@Injectable({
  providedIn: 'root'
})
export class ComboService {
  // Tipos de comunicaciones
  getTiposComunicaciones(): Combo[] {
    return [
      { id: 'INCIDENCIA', name: 'Incidencia' },
      { id: 'SUGERENCIA', name: 'Sugerencia' },
      { id: 'CONSULTA', name: 'Consulta' },
    ];
  }
  // Tipos de teléfono
  getTiposPrioridadesComs(): Combo[] {
    return [
      { id: 'BAJA', name: 'Baja' },
      { id: 'MEDIA', name: 'Media' },
      { id: 'ALTA', name: 'Alta' }
    ];
  }
  getCategoriasComs(): Combo[] {
    return [
      { id: 'ADHERIDOS', name: 'Adheridos' },
      { id: 'DAE', name: 'DAE' },
      { id: 'POSEEDORES', name: 'Poseedores finales' },
      { id: 'GEST_FINALES', name: 'Gestores finales' },
      { id: 'PTO_RECOGIDA', name: 'Puntos de Recogida' },
      { id: 'SDDR_CC', name: 'SDDR Cerrado' },
      { id: 'SDDR_CA', name: 'SDDR Abierto' },
      { id: 'AC_GESTORES', name: 'Acuerdos con gestores' },
    ]
  }
}


