<div class="modal-content modal-envases">
  <empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
  <div class="modal-header">
    <h3 class="modal-title font">Detalle de material de composición</h3>
    <button type="button" (click)="cerrar()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- Modal Body -->
  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
    <form class="form-modal py-3" [formGroup]="materialFormGroup">

      <div class="row form" *ngIf="isOtherMaterial">
        <div class="col-sm flex-grow-0">
          <label for="elem1">Tipo elemento</label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-field-select-tipo-elemento">
              <mat-select formControlName="tipoElemento" id="tipoElemento" placeholder="">
                <mat-option *ngFor="let tipo of tipoElementoArray" [value]="tipo.id">
                  {{ tipo.descripcion }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="row form">
        <div class="col-sm flex-grow-3" style="max-width: 40%; min-width: 40%;">
          <label for="material">Material de composición <span class="oblig">*</span></label>
          <div class="custom-mat-form-field-width">
            <lib-ge-select-pagination class="custom-mat-form-field-width"
                                      [endPointFilter]="composicionMaterialEndpoint"
                                      (filteredList)="filterList($event)" [updateList]="updateList"
                                      [formControlNameSelect]="getFormControl()"
                                      [placeHolder]="'Material'"
                                      [objQueryName]="'descripcion'"
                                      [customClass]="rightHolder(materialFormGroup,'composicionMaterial')"
                                      [disabled]="flagDisabledForm">
            </lib-ge-select-pagination>
          </div>
        </div>
        <div class="col-sm flex-grow-0">
          <label for="pesoMaterial">Peso unitario (Kg) <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-field-select">
              <input formControlName="pesoUnitario" placeholder="(Kg)" id="pesoUnitario" matInput type="text"
                     appNumericPointCommaEs>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-0">
          <label for="pesoTotalMaterial">Peso total (Kg) <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-field-select">
              <input formControlName="pesoTotal" id="pesoTotal" placeholder="(Kg)" matInput type="text"
                     appNumericPointCommaEs>
            </mat-form-field>
          </div>
        </div>
        <div class="col-sm flex-grow-0">
          <label for="recibidoMaterial">% Reciclado <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field appearance="outline" class="custom-mat-field-select">
              <input formControlName="porcReciclado" id="porcReciclado" placeholder="" matInput type="number"
                     min="0"
                     max="100">
            </mat-form-field>
          </div>
        </div>
      </div>


    

    </form>
  </div>
  <div class="modal-footer actions d-flex justify-content-end align-items-center">
    <div class="note mr-auto">* Campos Obligatorios</div>


    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
              aria-label="Cancelar">Cancelar
      </button>
      <button type="button" class="btn btn-primary" data-dismiss="modal"
              (click)="guardar()" aria-label="Guardar">Guardar
      </button>
    </div>
  </div>

</div>
