<!-- Formulario -->
<form [formGroup]="datosGeneralesForm">
  <div class="row form" style="justify-content: end;" *ngIf="adheridoInfo?.estado?.id == 6">
    <div class="imagen-fondo" (click)="certificadoPeriodico()" matTooltip="Obtener certificado periódico">
    </div>
  </div>
  <div class="row form">
    <div class="col-sm flex-grow-1" style="flex-basis: 66px;">
      <label for="tipoemp">Tipo empresa <span class="oblig">*</span></label>
      <mat-form-field appearance="outline" class="custom-mat-form-field-width">
        <mat-select formControlName="tipoEmpresa" id="tipoemp" placeholder="Tipo empresa">
          <mat-option *ngFor="let tipo of tiposEmpresa" [value]="tipo.id">
            {{tipo.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="controlHasError('tipoEmpresa', 'required')">Campo obligatorio</mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm" style="flex-grow: 4;">
      <label>Razón social <span class="oblig">*</span></label>
      <mat-form-field appearance="outline" class="custom-mat-form-field-width">
        <input formControlName="razonSocial" id="rsocial" placeholder="Razón Social" matInput type="text" maxlength="150">
        <mat-error *ngIf="controlHasError('razonSocial', 'required')">Campo obligatorio</mat-error>
        <mat-error *ngIf="controlHasError( 'razonSocial', 'maxlength')">Máximo 150 caracteres</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row form">
    <div class="col-sm flex-grow-1">
      <label for="tipodoc">Tipo documento <span class="oblig">*</span></label>
      <mat-form-field appearance="outline" class="custom-mat-form-field-width">
        <mat-select formControlName="tipoDocumento" (selectionChange)="tipoDocumentoSel($event.value)" id="tipodoc"
          placeholder="Tipo documento">
          <mat-option *ngFor="let tipo of tiposDocumento" [value]="tipo.id">
            {{tipo.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="controlHasError('tipoDocumento', 'required')">Campo obligatorio</mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm flex-grow-1">
      <label for="numdoc">Documento <span class="oblig">*</span></label>
      <mat-form-field appearance="outline" class="custom-mat-form-field-width">
        <input formControlName="codigoDocumento" id="numdoc" placeholder="Documento" matInput type="text"
          oninput="this.value = this.value.toUpperCase()" maxlength="15">
        <mat-error *ngIf="controlHasError('codigoDocumento', 'required')">Campo obligatorio</mat-error>
        <mat-error *ngIf="controlHasError('codigoDocumento', 'pattern')">Formato incorrecto</mat-error>
        <mat-error *ngIf="controlHasError( 'codigoDocumento', 'maxlength')">Máximo 15 caracteres</mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm flex-grow-1">
      <label for="grupemp">Pertenencia a grupo empresarial <span class="oblig">*</span></label>
      <mat-radio-group formControlName="grupoEmpresarial" id="grupemp" class="custom-mat-form-radio"  (change)="grupoEmpresarialChange()">
        <mat-radio-button [value]="true">Sí</mat-radio-button>
        <mat-radio-button [value]="false">No</mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="controlHasError('grupoEmpresarial', 'required')">Campo obligatorio</mat-error>
    </div>
    <div class="col-sm flex-grow-1">
      <ng-container *ngIf="datosGeneralesForm.controls['grupoEmpresarial'].value">
        <label for="nombreGrupo">Nombre grupo empresarial  <span class="oblig">*</span></label>
        <mat-form-field appearance="outline" class="custom-mat-form-field-width">
          <input formControlName="nombreGrupo" id="nombreGrupo" placeholder="Nombre grupo empresarial" matInput type="text" maxlength="255">
          <mat-error *ngIf="controlHasError('nombreGrupo', 'required')">Campo obligatorio</mat-error>
          <mat-error *ngIf="controlHasError( 'nombreGrupo', 'maxlength')">Máximo 255 caracteres</mat-error>
        </mat-form-field>
      </ng-container>
    </div>
  </div>
  <div class="row form">
    <div class="col-sm flex-grow-1" style="max-width: 50%;">
      <label for="cnaeprin">CNAE principal <span class="oblig">*</span></label>
      <div class="custom-mat-field">
        <lib-ge-select-pagination class="lib-ge-select" [endPointFilter]="cnaeEndpoint"
          [descripcion]="getCnaDescription" (filteredList)="filterList($event)" [updateList]="updateList"
          [disabled]="flagDisabledForm" [objQueryName]="getCnaeObjectQuery" [placeHolder]="'--CNAE Principal--'"
          [formControlNameSelect]="getFormControl('cnaePrincipal')" [customClass]="rightHolder('cnaePrincipal')">
        </lib-ge-select-pagination>
        <div class="ge-select-error">
          <mat-error class="ge-select-mat-error" *ngIf="selPaginatorHasError('cnaePrincipal')">Campo obligatorio
          </mat-error>
        </div>
      </div>
    </div>
    <div class="col-sm flex-grow-1" style="max-width: 50%;">
      <label for="cnae">CNAE secundario</label>
      <div class="custom-mat-field">
        <lib-ge-select-pagination class="lib-ge-select" [endPointFilter]="cnaeEndpoint"
          [descripcion]="getCnaDescription" (filteredList)="filterList($event)" [updateList]="updateList"
          [disabled]="flagDisabledForm" [objQueryName]="getCnaeObjectQuery"
          [formControlNameSelect]="getFormControl('cnaeSecundario')" [placeHolder]="'--CNAE Secundario--'">
        </lib-ge-select-pagination>
        <div class="ge-select-error">
        </div>
      </div>
    </div>
  </div>
  <div class="row form">
    <div class="col-sm flex-grow-1">
      <label for="tipotlf">Tipo tlf. empresa <span class="oblig">*</span></label>
      <mat-form-field appearance="outline" class="custom-mat-form-field-width">
        <mat-select formControlName="tipoTlfEmpresa" id="tipotlf" placeholder="Tipo tlf. empresa">
          <mat-option *ngFor="let tipo of tiposTelefono" [value]="tipo.id">
            {{tipo.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="controlHasError('tipoTlfEmpresa', 'required')">Campo obligatorio</mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm flex-grow-1">
      <label for="prefij">Prefijo país <span class="oblig">*</span></label>
      <div class="custom-mat-field-select">
        <lib-ge-select-pagination [endPointFilter]="paisesEndpoint" (filteredList)="filterList($event)"
          [updateList]="updateList" [formControlNameSelect]="getFormControl('prefijoPais')"
          [placeHolder]="'Prefijo pais'" [objQueryName]="'prefijo'" descripcion="prefijoNombre" [defaultValue]=69 [selectDefaultValue]="true"
          [customClass]="rightHolder('prefijoPais')" [disabled]="flagDisabledForm" (selectedModel)="selectedPrefijoPais($event)">
        </lib-ge-select-pagination>
        <div *ngIf="controlHasErrorSelect('prefijoPais')" class="mat-error">
          Campo obligatorio
        </div>
      </div>
    </div>
    <div class="col-sm flex-grow-1">
      <label for="numtlf">Nº tlf. empresa <span class="oblig">*</span></label>
      <mat-form-field appearance="outline" class="custom-mat-form-field-width">
        <input formControlName="tlfEmpresa" id="numtlf" placeholder="Nº tlf. empresa" matInput type="text" maxlength="15">
        <mat-error *ngIf="controlHasError('tlfEmpresa', 'required')">Campo obligatorio</mat-error>
        <mat-error
          *ngIf="!controlHasError('tlfEmpresa', 'required') && controlHasError('tlfEmpresa', 'invalidTelefonoFijo')">
          Formato incorrecto para tipo Fijo con prefijo 34.
        </mat-error>
        <mat-error *ngIf="!controlHasError('tlfEmpresa', 'required') && !controlHasError('tlfEmpresa', 'invalidTelefonoFijo') &&
           controlHasError('tlfEmpresa', 'invalidTelefonoMovil')">
          Formato incorrecto para tipo Movil con prefijo 34.
        </mat-error>
        <mat-error *ngIf="controlHasError( 'tlfEmpresa', 'maxlength')">Máximo 15 caracteres</mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm flex-grow-1">
    </div>
  </div>
  <div class="row form pt-2">
    <div class="col-sm flex-grow-1" *ngIf="false">
      <label for="emailemp">Email empresa</label>
      <mat-form-field appearance="outline">
        <input formControlName="email" id="emailemp" placeholder="Email empresa" matInput type="text" maxlength="100">
        <mat-error *ngIf="controlHasError('email', 'pattern')">Formato incorrecto</mat-error>
        <mat-error *ngIf="controlHasError( 'email', 'maxlength')">Máximo 100 caracteres</mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm flex-grow-1">
      <label for="pagweb">Página Web</label>
      <mat-form-field appearance="outline">
        <input formControlName="web" id="pagweb" placeholder="Página Web" matInput type="text" maxlength="100">
        <mat-error *ngIf="controlHasError( 'web', 'maxlength')">Máximo 100 caracteres</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row form">
    <div class="col-sm flex-grow-1">
      <label for="prodcts">Tipo uso envases <span class="oblig">*</span></label>
      <mat-form-field appearance="outline">
        <mat-select formControlName="tipoUso" id="prodcts" placeholder="Tipo uso envases">
          <mat-option *ngFor="let tipo of categoriasEnvases" [value]="tipo.id">
            {{tipo.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="controlHasError('tipoUso', 'required')">Campo obligatorio</mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm flex-grow-1">
      <div style="display: flex; align-items: center;">
        <label for="cifraneg">Cifra de negocio <span class="oblig">*</span></label>
        <div class="tool-help"
          matTooltip="Indicar la cifra de negocio en España. Servirá para calcular su cuota de adhesión (no aplicable para Adheridos previamente asociados a Envalora)"
          data-placement="top" title="">
          <span>?</span>
        </div>
      </div>
      <mat-form-field appearance="outline">
        <mat-select formControlName="cifraNegocio" id="cifraneg" placeholder="Cifra de negocio">
          <mat-option *ngFor="let tipo of cifrasNegocio" [value]="tipo.id">
            {{tipo.descripcion}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="controlHasError('cifraNegocio', 'required')">Campo obligatorio</mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm flex-grow-1">
      <div style="display: flex; align-items: center;">
        <label for="planemp">Categoría de los envases <span class="oblig">* </span></label>
        <div class="tool-help" matTooltip="Dato informativo - No vinculante - Se puede ajustar en la declaración"
          data-placement="top" title="">
          <span>?</span>
        </div>
      </div>
      <mat-form-field appearance="outline">
        <mat-select formControlName="categoriaEnvases" id="categ" placeholder="Categoría de los envases">
          <mat-option *ngFor="let tipo of tiposPuestaEnMercado" [value]="tipo.id">
            {{tipo.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="controlHasError('categoriaEnvases', 'required')">Campo obligatorio</mat-error>
      </mat-form-field>
    </div>
    <div class="col-sm flex-grow-1">
      <label for="numregprod">Nº de registro de productor <span class="oblig">*</span></label>
      <mat-form-field appearance="outline">
        <input formControlName="numRegistroProductor" id="numregprod" placeholder="ENV/AAAA/XXXXXXXXX" matInput
               type="text" maxlength="18"
               (input)="agregarBarraAutomaticamente($event)" (focus)="agregarPrefijoSiEstaVacio($event)">
        <mat-error *ngIf="controlHasError('numRegistroProductor', 'required')">Campo obligatorio</mat-error>
        <mat-error *ngIf="datosGeneralesForm.get('numRegistroProductor')?.hasError('formatoInvalido')">Formato requerido: "ENV/AAAA/XXXXXXXXX"
        </mat-error>
        <mat-error *ngIf="datosGeneralesForm.get('numRegistroProductor')?.hasError('longitudIncorrecta')">La longitud
          del número de registro debe ser de 16 caracteres
        </mat-error>

      </mat-form-field>
    </div>
  </div>
  <div class="row form">
    <div *ngIf="canAccessAsociado()" class="col-sm flex-grow-1">
      <label for="asocia">Es asociado<span class="oblig">*</span></label>
      <mat-radio-group formControlName="asociado" id="asocia" class="custom-mat-form-radio">
        <mat-radio-button [value]="true">Sí</mat-radio-button>
        <mat-radio-button [value]="false">No</mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="controlHasError('asociado', 'required')">Campo obligatorio</mat-error>
    </div>
    <div *ngIf="canAccessFabricante()" class="col-sm flex-grow-1">
      <div style="display: flex; align-items: center;">
        <label for="fabrica">Es fabricante de... <span class="oblig">*</span></label>
        <div class="tool-help" matTooltip="Es fabricante de envases que asume RAP" data-placement="top" title="">
          <span>?</span>
        </div>
      </div>

      <mat-radio-group formControlName="fabricante" id="fabrica" class="custom-mat-form-radio">
        <mat-radio-button [value]="true">Sí</mat-radio-button>
        <mat-radio-button [value]="false">No</mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="controlHasError('fabricante', 'required')">Campo obligatorio</mat-error>
    </div>
    <div *ngIf="canAccessPlan()" class="col-sm flex-grow-1">
      <div style="display: flex; align-items: center;">
        <label for="planemp">Plan empresarial... <span class="oblig">*</span></label>
        <div class="tool-help" matTooltip="Plan empresarial de prevención" data-placement="top" title="">
          <span>?</span>
        </div>
      </div>
      <mat-radio-group formControlName="planEmpresarial" id="planemp" class="custom-mat-form-radio">
        <mat-radio-button [value]="true">Sí</mat-radio-button>
        <mat-radio-button [value]="false">No</mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="controlHasError('planEmpresarial', 'required')">Campo obligatorio</mat-error>
    </div>
    <div *ngIf="canAccessAdenda()" class="col-sm flex-grow-1">
      <label for="adenda">Con Adenda <span class="oblig">*</span></label>
      <mat-radio-group formControlName="conAdenda" id="adenda" class="custom-mat-form-radio" (change)="changeAdenda()">
        <mat-radio-button [value]="true">Sí</mat-radio-button>
        <mat-radio-button [value]="false">No</mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="controlHasError('conAdenda', 'required')">Campo obligatorio</mat-error>
    </div>
    <div class="col-sm flex-grow-2"
      *ngIf="this.datosGeneralesForm.get('conAdenda')?.value === true || canAccessAdenda()">
      <label for="descadenda">Descripción con Adenda <span class="oblig" *ngIf="this.datosGeneralesForm.get('conAdenda')?.value === true">*</span></label>
      <mat-form-field appearance="outline">
        <input formControlName="descripcionAdenda" id="descadenda" placeholder="Descripción con Adenda" matInput
          type="text" maxlength="100">
        <mat-error *ngIf="controlHasError( 'descripcionAdenda', 'maxlength')">Máximo 100 caracteres</mat-error>
        <mat-error *ngIf="controlHasError('descripcionAdenda', 'required')">Campo obligatorio</mat-error>
      </mat-form-field>
    </div>
  </div>
  <div class="row form">
    <div class="col-sm flex-grow-2">
      <label for="descactiv">Breve descripción de la actividad</label>
      <div class="d-flex align-items-center">
        <mat-form-field appearance="outline">
          <input formControlName="descripcionActividad" id="descactiv" placeholder="Breve descripción de la actividad"
            matInput type="text" maxlength="500">
          <mat-error *ngIf="controlHasError( 'descripcionActividad', 'maxlength')">Máximo 500 caracteres</mat-error>
        </mat-form-field>
        <div class="tool-help mb-4"
          matTooltip="Breve descripción de su actividad como productor de producto de envases sujeto a RAP"
          data-placement="top" title=""><span>?</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row form">
    <div class="col-sm flex-grow-1" *ngIf="canShowScrapOrigen()">
        <label for="descactiv">Scrap Origen</label>
        <div class="d-flex align-items-center">
          <mat-form-field appearance="outline">
            <mat-select formControlName="scrapOrigen" id="scrapOrigen" placeholder="Scrap origen">
              <mat-option *ngFor="let scrap of scrapsOrigen" [value]="scrap.descripcion">
                {{scrap.descripcion}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
    </div>
    <div class="col-sm flex-grow-1" *ngIf="canShowScrapDestino()">
        <label for="descactiv">Scrap Destino</label>
        <div class="d-flex align-items-center">
          <mat-form-field appearance="outline">
            <mat-select formControlName="scrapDestino" id="scrapDestino" placeholder="Scrap destino">
              <mat-option *ngFor="let scrap of scrapsDestino" [value]="scrap.descripcion">
                {{scrap.descripcion}}
              </mat-option>
            </mat-select>
          </mat-form-field>
      </div>
    </div>
  </div>
  <div class="row form">
    <div class="col-sm flex-grow-1">
      <div style="display: flex; align-items: center;">
        <label for="remitirInformacion">Consiento recibir comunicaciones sobre otras actividades de terceros y de ENVALORA <span class="oblig">*</span></label>
        <div class="tool-help" matTooltip="La base jurídica para el tratamiento de los datos personales con finalidades de envío de comunicaciones es el consentimiento prestado por LA EMPRESA al confirmar esta afirmación. 
        Además, los datos personales de personas de contacto también podrán ser usados para enviarles comunicaciones sobre otras actividades de ENVALORA o de terceros colaboradores con el SCRAP por consentimiento prestado por LA EMPRESA mediante esta confirmación." data-placement="top" title="">
          <span>?</span>
        </div>
      </div>
      <mat-radio-group formControlName="remitirInformacion" id="remitirInformacion" class="custom-mat-form-radio">
        <mat-radio-button [value]="true">Sí</mat-radio-button>
        <mat-radio-button [value]="false">No</mat-radio-button>
      </mat-radio-group>
      <mat-error *ngIf="controlHasError('remitirInformacion', 'required')">Campo obligatorio</mat-error>
    </div>
  </div>
  <div class="row form">
    <div *ngIf="canAccessFechas()" class="col-sm flex-grow-1">
      <label for="fechaFirmaContrato">Fecha firma contrato </label>
      <mat-form-field appearance="outline">
        <input formControlName="fechaFirmaContrato" id="fechaFirmaContrato" matInput type="text" readonly>
      </mat-form-field>
    </div>
    <div *ngIf="canAccessFechas()" class="col-sm flex-grow-1">
      <label for="fechaAdhesionFinalizada">Fecha adhesión finalizada</label>
      <mat-form-field appearance="outline">
        <input formControlName="fechaAdhesionFinalizada" id="fechaAdhesionFinalizada" matInput type="text" readonly>
      </mat-form-field>
    </div>
    <div *ngIf="canAccessFechas()" class="col-sm flex-grow-1">
      <label for="fechaBaja">Fecha baja</label>
      <mat-form-field appearance="outline">
        <input matInput [matDatepicker]="datepicker" formControlName="fechaBaja">
        <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker>
        </mat-datepicker>
        <mat-error *ngIf="controlHasError('fechaBaja', 'matDatepickerParse')">Formato incorrecto</mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="canAccessFechas()" class="col-sm flex-grow-0">
      <label for="fechaBajaEnvanet">Fecha baja Envanet</label>
      <mat-form-field appearance="outline">
        <input formControlName="fechaBajaEnvanet" id="fechaBajaEnvanet" matInput type="text" readonly>
      </mat-form-field>
    </div>
  </div>

</form>
