import { Injectable, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'parseCurrency',
})
@Injectable({
  providedIn: 'root',
})
export class ParseCurrencyPipe implements PipeTransform {
  transform(value: string): number {
    if (!value || typeof value !=='string') {
      return 0;
    }
    return parseFloat(value?.replace(/\./g, '')?.replace(',', '.'));
  }
}
