import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { CombosService } from '../../../services/combos.service';
import { ValidateService } from '../../../services/validate.service';
import { Combo } from '../../../models/combo';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import Swal from 'sweetalert2';
import {
  GestorPtoRecogidaControllerService,
  GestorPtoRecogidaPayloadDTO,
} from 'api-rest';

@Component({
  selector: 'app-collection-points-managers-preliminary',
  templateUrl: './collection-points-managers-preliminary.component.html',
  styleUrls: ['./collection-points-managers-preliminary.component.scss'],
})
export class CollectionPointsManagersPreliminaryComponent implements OnInit {
  dataForm: FormGroup;
  tiposDocumento: Combo[] = [];
  pageNumber: number = 0;
  pageSize: number = 100;

  paisesEndpoint = this.environment.urlBackCore + 'api/v1/core/countries';

  flagDisabledForm: boolean = false;
  updateList: boolean = false;

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    private validateSrv: ValidateService,
    private dialogRef: MatDialogRef<CollectionPointsManagersPreliminaryComponent>,
    private comboSrv: CombosService,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private gestorPtoRecogidaControllerService: GestorPtoRecogidaControllerService
  ) {
    this.dataForm = this.formBuilder.group({
      razonSocial: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(255)]) },
      ],
      denominacion: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(255)]) },
      ],
      documentType: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      documentNumber: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(this.validateSrv.documentNumber),
            Validators.maxLength(15)
          ]),
        },
      ],

      nima: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(this.validateSrv.tenDigits),
            Validators.maxLength(10)
          ]),
        },
        ,
      ],
      inscrip1: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(255)]) },
      ],

      inscrip2: [{ value: null, disabled: false },
        { validators: Validators.compose([Validators.maxLength(255)]) }
      ],
      documentTypeContact: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      documentNumberContact: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(this.validateSrv.documentNumber),
            Validators.maxLength(15)
          ]),
        },
      ],

      nombre: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(50)]) },
      ],
      apellido1: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required, Validators.maxLength(50)]) },
      ],
      apellido2: [{ value: null, disabled: false },
        {validators: Validators.compose([Validators.maxLength(50)])}
      ],
      email: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(this.validateSrv.emailPattern),
            Validators.maxLength(100)
          ]),
        },
      ],
      tlfType: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      countryCode: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      tlfNumber: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            this.telefonoValidator.bind(this),
            Validators.maxLength(15)
          ]),
        },
      ],
    });
  }

  ngOnInit(): void {
    this.tiposDocumento = this.comboSrv.getTiposDocumentoIdentidad();

    this.dataForm.get('tlfType')?.valueChanges.subscribe(() => {
      this.dataForm.get('tlfNumber')?.updateValueAndValidity();
    });

    this.dataForm.get('countryCode')?.valueChanges.subscribe(() => {
      this.dataForm.get('tlfNumber')?.updateValueAndValidity();
    });
  }

  telefonoValidator(control: AbstractControl): { [key: string]: any } | null {
    const tipoTelefono = this.dataForm?.get('tlfType')?.value;
    const prefijoPais = this.dataForm?.get('countryCode')?.value;

    const phonePattern =
      /^(\+?\d{1,3})?[-. (]?(\d{1,4})[-. )]?(\d{1,4})[-. ]?(\d{1,4})[-. ]?(\d{1,9})$/;
    const isGeneralValid = phonePattern.test(control.value);

    if (!isGeneralValid) {
      return { invalidPhoneFormat: true };
    }

    if (
      prefijoPais?.prefijo == '34' ||
      (typeof prefijoPais === 'string' && prefijoPais?.toUpperCase() == '34 (ESPAÑA)')
    ) {
      if (tipoTelefono === 'FIJO') {
        const valid = /^[89][0-9]{8}$/.test(control.value);
        return valid ? null : { invalidTelefonoFijo: true };
      } else if (tipoTelefono === 'MOVIL') {
        const valid = /^[67][0-9]{8}$/.test(control.value);
        return valid ? null : { invalidTelefonoMovil: true };
      }
    }

    return null;
  }

  tipoDocumentoSel(idTipoDoc: string) {
    this.dataForm.get('documentNumber')?.clearValidators();
    if (idTipoDoc === 'NIF') {
      this.dataForm
        .get('documentNumber')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.nifPattern),
        ]);
    } else if (idTipoDoc === 'DNI') {
      this.dataForm
        .get('documentNumber')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.dniPattern),
        ]);
    } else if (idTipoDoc === 'NIE') {
      this.dataForm
        .get('documentNumber')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.niePattern),
        ]);
    }
    this.dataForm.get('documentNumber')?.updateValueAndValidity();
    this.dataForm.get('documentNumber')?.markAsTouched();
  }

  tipoDocumentoSelContact(idTipoDoc: string) {
    this.dataForm.get('documentNumberContact')?.clearValidators();
    if (idTipoDoc === 'NIF') {
      this.dataForm
        .get('documentNumberContact')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.nifPattern),
        ]);
    } else if (idTipoDoc === 'DNI') {
      this.dataForm
        .get('documentNumberContact')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.dniPattern),
        ]);
    } else if (idTipoDoc === 'NIE') {
      this.dataForm
        .get('documentNumberContact')
        ?.setValidators([
          Validators.required,
          Validators.pattern(this.validateSrv.niePattern),
        ]);
    }
    this.dataForm.get('documentNumberContact')?.updateValueAndValidity();
    this.dataForm.get('documentNumberContact')?.markAsTouched();
  }

  public filterList(data: any) {}

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'countryCode': {
        return this.dataForm.get('countryCode') as FormControl;
        break;
      }
      default: {
        return this.dataForm.get('') as FormControl;
        break;
      }
    }
  }

  saveData() {
    this.dataForm.markAllAsTouched();
    this.dataForm.updateValueAndValidity();

    if (this.dataForm.valid) {
      const objPuntosRecogidaFilter: GestorPtoRecogidaPayloadDTO = {
        razonSocial: this.dataForm.get('razonSocial')?.value,
        denominacion: this.dataForm.get('denominacion')?.value,
        tipoDocumento: this.dataForm.get('documentType')?.value,
        codigoDocumento: this.dataForm.get('documentNumber')?.value,
        nima: this.dataForm.get('nima')?.value,
        numInscripcionRegitro1: this.dataForm.get('inscrip1')?.value,
        numInscripcionRegistro2: this.dataForm.get('inscrip2')?.value,
        contacto: {
          nombre: this.dataForm.get('nombre')?.value,
          apellido1: this.dataForm.get('apellido1')?.value,
          apellido2: this.dataForm.get('apellido2')?.value,
          email: this.dataForm.get('email')?.value,
          tipo_documento: this.dataForm.get('documentTypeContact')?.value,
          codigo_documento: this.dataForm.get('documentNumberContact')?.value,
          tipoTelefono: this.dataForm.get('tlfType')?.value,
          numeroTelefono: this.dataForm.get('tlfNumber')?.value,
          codigoPais: this.dataForm.get('countryCode')?.value,
        },
      };

      this.performSave(objPuntosRecogidaFilter);
    } else {
      this.snackBarSrv.showSnackBar(
        'Debe completar los campos obligatorios',
        'error'
      );
    }
  }

  performSave(data: GestorPtoRecogidaPayloadDTO) {
    this.spinnerSrv.loadingShow();
    this.gestorPtoRecogidaControllerService
      .createGestorPtoRecogida(data)
      .subscribe({
        next: (value: any) => {
          this.spinnerSrv.loadingHide();
          this.dialogRef.close(true);
          this.snackBarSrv.showSnackBar(
            'Alta preliminar realizada correctamente',
            'success'
          );
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.dataForm.controls[controlName].hasError(errorName) &&
      this.dataForm.controls[controlName].touched
    );
  }

  controlHasErrorSelect(controlName: string) {
    if (
      (this.dataForm.controls[controlName]?.touched ||
        this.dataForm.controls[controlName]?.dirty) &&
      this.dataForm.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.dataForm.controls[controlName]?.touched ||
        this.dataForm.controls[controlName]?.dirty) &&
      this.dataForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  confirmacion(titulo: string, callBack: Function): void {
    Swal.fire({
      text: titulo,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        callBack();
      }
    });
  }

  selPaginatorHasError(controlName: string) {
    if (
      (this.dataForm.controls[controlName]?.touched ||
        this.dataForm.controls[controlName]?.dirty) &&
      this.dataForm.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }
}
