import {
  EventEmitter,
  Inject,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { SimpleChanges } from '@angular/core';
import { Component, Input } from '@angular/core';
import {
  ComunidadDTO,
  DeclaracionAdheridoControllerService,
  DeclaracionAdheridoDTO,
  DeclaracionCentroControllerService,
  DeclaracionCentroDTO,
  DeclaracionCentroFilterDTO,
  SectorControllerService,
  SectorModel,
} from 'api-rest';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import { CategoryCenterDialogComponent } from '../category-center-dialog/category-center-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { UploadCenterDialogComponent } from '../upload-center-dialog/upload-center-dialog.component';
import { PackageCenterPackagingComponent } from '../package-center-packaging/package-center-packaging.component';

@Component({
  selector: 'lib-package-center',
  templateUrl: './package-center.component.html',
  styleUrls: ['./package-center.component.scss'],
})
export class PackageCenterComponent implements OnChanges, OnInit {
  @Input() declaracionAdherido!: DeclaracionAdheridoDTO;
  @Input() centro!: DeclaracionCentroDTO;
  @Input() comunidadesAutonomas!: ComunidadDTO[];
  @Output() actualizarDeclaracion: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() actualizarEnvases: EventEmitter<any> =
    new EventEmitter<any>();
  @ViewChild(PackageCenterPackagingComponent) packageCenterList:
    | PackageCenterPackagingComponent
    | undefined;
  flagImportar: boolean = false;
  matTooltipImport: string =
    'Para realizar la importación de envases la lista de envases debe de estar vacía.';

  constructor(
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private router: Router,
    private centroService: DeclaracionCentroControllerService,
    @Inject('EmpusaAuthenticationService')
    public authService: EmpusaAuthenticationService
  ) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.declaracionAdherido && this.centro) {
    }
  }

  abrirModalCentro(): void {
    const dialogRef = this.dialog.open(CategoryCenterDialogComponent, {
      width: '950px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: {
        declaracionAdherido: this.declaracionAdherido,
        centroData: this.centro,
        nuevoCentro: false,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.centro = result;
      }
    });
  }

  onActualizarDeclaracion() {
    this.actualizarDeclaracion.emit(true);
  }

  confirmacionEliminarCentro(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar el centro de envasado ${this.centro?.centroEnvasado?.denominacion} - ${this.centro?.categoriaEnvase?.descripcion}?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.spinnerSrv.loadingShow();
        this.centroService.deleteById4(this.centro?.id!).subscribe({
          next: (data: any) => {
            this.spinnerSrv.loadingHide();
            this.actualizarDeclaracion.emit(true);
            this.snackBarSrv.showSnackBar(
              'Se ha eliminando correctamente el centro envasado',
              'success'
            );
            this.actualizarEnvases.emit({idCentro: this.centro?.id, numEnvases: 0});
          },
          error: (error: any) => {
            this.snackBarSrv.showSnackBar(
              `Ha ocurrido un error al eliminar el centro de envasado`,
              'error'
            );
            this.spinnerSrv.loadingHide();
          },
        });
      }
    });
  }

  updateUploadCenter() {
    const dialogRef = this.dialog.open(UploadCenterDialogComponent, {
      width: '950px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: {
        declaracionAdherido: this.declaracionAdherido,
        declaracionCentro: this.centro,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.packageCenterList?.buscar();
        this.actualizarDeclaracion.emit(true);
      }
    });
  }

  canAccessEditCenter(): boolean {
    return this.authService.can('dec_envases-centro', 'update');
  }

  canAccessDeleteCenter(): boolean {
    return this.authService.can('dec_envases-centro', 'delete');
  }

  canAccessUpdateFile(): boolean {
    return this.authService.can('dec_envases-centro', 'update-carga');
  }

  canAccessEnvases(): boolean {
    return this.authService.can('dec_envases-envases', 'access');
  }

  canAccessComunidades(): boolean {
    return this.authService.can('dec_envases-ccaa', 'access');
  }

  canAccessSectores(): boolean {
    return this.authService.can('dec_envases-sectores', 'access');
  }

  mostarImportar(event: any) {
    this.flagImportar = true; // event;
    if (this.flagImportar) {
      this.matTooltipImport = 'Importar declaración de envases';
    }
  }

  actualizarEnvasesCentro(event:any){
    //Manda actualizacion de si tiene o no envases el centro
    this.actualizarEnvases.emit(event);
  }

  canAccessEditAllGlobal(): boolean {
    return (
      this.authService.can('dec_envases', 'update') &&
      this.declaracionAdherido?.estadoDeclaracion?.id == 0
    );
  }

  validacionImportar(): boolean {
    const valor = [1, 2].includes(this.centro.categoriaEnvase?.id!)
    && this.declaracionAdherido.estadoDeclaracion?.id === 0 ? true : false;

    return valor;
  }

  buttonSalir() {
    Swal.fire({
      text: '¿Desea volver al buscador?',
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.router.navigate(['/attached-app/packaging-declaration-search']);
      }
    });
  }
}
