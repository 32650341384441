import { Component, Inject, OnInit } from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { SpinnerService } from '../../services/spinner.service';
import { DownloadService } from '../../services/download.service';
import { SnackbarService } from '../../services/snackbar.service';
import {
  FacturaEstadoDTO,
  FacturaGestorAcuerdoFilterDTO,
  FacturaPoseedorEstadosControllerService,
  FacturaGestorAcuerdoControllerService
} from 'api-rest';
import { DocumentService } from '../../services/document.service';
import moment from 'moment';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { DatePipe } from '@angular/common';
import { ManagersAgreementsInvoiceNewDocumentDialogComponent } from '../managers-agreements-invoice-document-dialog/managers-agreements-invoice-document-dialog.component';
import { ManagersAgreementsInvoiceRejectDialogComponent } from '../managers-agreements-invoice-reject-dialog/managers-agreements-invoice-reject-dialog.component';

@Component({
  selector: 'app-managers-agreements-self-invoices-search',
  templateUrl: './managers-agreements-self-invoices-search.component.html',
  styleUrls: ['./managers-agreements-self-invoices-search.component.scss'],
})
export class ManagersAgreementsSelfInvoicesSearchComponent implements OnInit {

  listaGestores = this.environment.urlBackCore + 'api/v1/acuerdos-gestores/gestores/byUser';

  defaultGestoresParameters = { 'gestoresResiduosEstado.id': 2, 'acuerdoEnvalora': true };
  updateList: boolean = false;

  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  totalElements: number = 0;
  totalSearch: number = 0;

  gestorArray: any[] = [];
  estadoArray: any[] = [];

  headArray: any = [
    {
      Head: 'ID. Gestor',
      FieldName: 'id',
    },
    {
      Head: 'Gestor',
      FieldName: 'gestoresResiduos',
      Attribute: 'razonSocial',
    },
    {
      Head: 'Documento',
      FieldName: 'gestoresResiduos',
      Attribute: 'codigoDocumento',
    },
    {
      Head: 'NIMA del gestor',
      FieldName: 'gestoresResiduos',
      Attribute: 'nima',
    },
    {
      Head: 'Número de prefactura',
      FieldName: 'documentoAutofactura',
      renderValue: (item: any) =>
        item.fechaAutofactura !== null ? item.id : '',
    },
    {
      Head: 'Fecha de prefactura',
      FieldName: 'fechaMAutofactura',
    },
    {
      Head: 'Número factura gestor ',
      FieldName: 'numeroFactura',
    },
    {
      Head: 'Fecha factura',
      FieldName: 'fechaMFactura',
    },
    {
      Head: 'Estado ',
      FieldName: 'estado',
      renderValue: (item: any) =>
        item.estado?.descripcion
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Eliminar registro',
          show: { params: 'canAccessDelete' },
        },
        {
          nameButton: 'add_invoice',
          icon: 'upload_file',
          toolTip: 'Adjuntar factura',
          show: { params: 'canAccessUpInvoice' },
        },
        {
          nameButton: 'menu',
          array: [
            {
              nameButton: 'download_invoice',
              name: 'Descargar factura',
              show: { params: 'canAccessDonwloadInvoice' },
            },
            {
              nameButton: 'download_self_invoice',
              name: 'Descargar prefactura',
              show: { params: 'canAccessDonwloadSelfInvoice' },
            },
            {
              nameButton: 'accept_invoice',
              name: 'Aceptar factura',
              show: { params: 'canAccessAcceptInvoice' },
            },
            {
              nameButton: 'reject_invoice',
              name: 'Rechazar',
              show: { params: 'canRejectInvoice' },
            },
          ],
        },
      ],
      permanent: true,
    },
  ];
  gridArray: any[] = [];

  searchForm: FormGroup;

  constructor(
    @Inject('environment') private environment: any,
    @Inject('EmpusaAuthenticationService')
    public authService: EmpusaAuthenticationService | any,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private facturaGestorAcuerdoControllerService: FacturaGestorAcuerdoControllerService,
    private facturaPoseedorEstadosControllerService: FacturaPoseedorEstadosControllerService,
    private downloadService: DownloadService,
    private documentService: DocumentService,
    private snackBarSrv: SnackbarService,
    private datePipe: DatePipe
  ) {
    this.searchForm = this.formBuilder.group({
      gestor: [{ value: null, disabled: false }],
      idgestor: [{ value: null, disabled: false }],
      codigo_documento: [{ value: null, disabled: false }],
      razon_social: [{ value: null, disabled: false }],
      nima_gestor: [{ value: null, disabled: false }],
      numero_autoFactura: [{ value: null, disabled: false }, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      fecha_autoFactura: [{ value: null, disabled: false }],
      numero_facturaGestor: [{ value: null, disabled: false }, [Validators.pattern('^[0-9]+$'), Validators.maxLength(10)]],
      fecha_facturaGestor: [{ value: null, disabled: false }],
      estado: [{ value: null, disabled: false }],
    });
  }

  ngOnInit() {
    this.comboEstados();
    // this.getGestores();
  }

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.searchData();
    }
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'gestor': {
        return this.searchForm.get('gestor') as FormControl;
      }
      default: {
        return this.searchForm.get('') as FormControl;
      }
    }
  }

  getGestorDescription(obj: any) {
    return obj?.razonSocial;
  }

  getGestorObjectQuery(searchterm: string) {
    return {
      descripcion: searchterm,
      'estado.id': 4,
    };
  }

  exportarExcel() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.facturaGestorAcuerdoControllerService
      .exportCSV2(backendFilter, 'response')
      .subscribe((res: any) => {
        const filename = this.downloadService.getFileName(
          res.headers,
          'Exportacion_Factura_Gestor.csv'
        );
        this.downloadService.downloadCSV(res.body!, filename!);
      });
  }

  clearSearcher() {
    this.searchForm.reset();
    this.flagSearch = false;
    this.gridArray = [];
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.searchForm.controls[controlName].hasError(errorName) &&
      this.searchForm.controls[controlName].touched
    );
  }

  private getBackendFilter() {
    let gestorIds;
    if (
      this.authService.user?.roleEntities?.length > 0 &&
      this.canViewResource('AG-GESTOR')
    ) {
      gestorIds = this.authService.user?.roleEntities.map(
        (gestorRol: any) => gestorRol.entity as number
      );
    }
    const {
      gestor,
      idgestor,
      razon_social,
      nima_gestor,
      numero_autoFactura,
      fecha_autoFactura,
      numero_facturaGestor,
      fecha_facturaGestor,
      estado,
      codigo_documento,
    } = this.searchForm.controls;

    const formatDate = (date: any) => {
      if (date && date.value) {
        return moment(new Date(date.value)).format('DD/MM/YYYY');
      }
      return undefined;
    };

    const selectGestores = this.searchForm.get('gestor')?.value;

    const backendFilter: FacturaGestorAcuerdoFilterDTO = {
      gestoresResiduos: {
        id: gestor.value != null
          ? selectGestores?.map((objeto: any) =>
            objeto.id !== undefined ? objeto.id : objeto)
          : gestorIds,

        razonSocial: razon_social?.value,
        nima: nima_gestor?.value,
        codigoDocumento: codigo_documento?.value,
      },
      id: Number(idgestor?.value) || Number(gestor?.value) || undefined,
      numeroAutofactura: numero_autoFactura?.value,
      fechaAutofactura: formatDate(fecha_autoFactura),
      numeroFactura: Number(numero_facturaGestor?.value) || undefined,
      fechaFactura: formatDate(fecha_facturaGestor),
      estado: estado?.value,
    };

    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    return { backendFilter, page, size: this.pageSize };
  }

  searchData() {
    if (this.searchForm.invalid) {
      this.searchForm.markAllAsTouched();
      return;
    }
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.facturaGestorAcuerdoControllerService
      .findAll1(backendFilter, {
        page: page,
        size: size,
        sort: ['id,desc'],
      })
      .subscribe({
        next: (data: any) => {
          if (data) {
            this.spinnerSrv.loadingHide();
            this.flagSearch = data.datos?.length != 0 ? true : false;
            this.gridArray = this.canAccessAll(data.datos) ?? [];
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.totalSearch = this.gridArray.length;

            //seleccionar por defecto gestores en el combo de seleccion multiple
            let gestoresSel = [];
            gestoresSel.push(this.gridArray[0].gestoresResiduos);
            console.log(gestoresSel);
            this.searchForm.get('gestor')?.setValue(gestoresSel);
          }
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  action(element: any) {
    if (element.nameButton == 'view') {
    } else if (element.nameButton == 'delete') {
      this.confirmacionEliminar(element);
    } else if (element.nameButton == 'add_invoice') {
      this.openModalUploadInvoice(element);
    } else if (element.nameButton == 'download_invoice') {
      this.descargar(element.documento);
    } else if (element.nameButton == 'download_self_invoice') {
      this.descargar(element.documentoAutofactura);
    } else if (element.nameButton == 'accept_invoice') {
      this.confirmacionCambioEstado(element.id, 3);
    }else if(element.nameButton == 'reject_invoice'){
      this.openModalMotivoRechazo(element);
    }
  }

  confirmacionEliminar(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar la prefactura?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.eliminarInvoices(element);
      }
    });
  }

  eliminarInvoices(element?: any) {
    if (element?.id) {
      this.spinnerSrv.loadingShow();
      this.facturaGestorAcuerdoControllerService.deleteById1(element.id).subscribe({
        next: () => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            `El registro de factura se ha eliminado correctamente`,
            'success'
          );
          this.gridArray = this.gridArray.filter(
            (objeto) => objeto.id !== element.id
          );
          this.flagSearch = this.gridArray.length != 0 ? true : false;
        },
        error: (err: { message: any }) => {
          this.spinnerSrv.loadingHide();
        },
      });
    }
  }

  comboEstados() {
    this.facturaPoseedorEstadosControllerService.findAll11().subscribe({
      next: (data: Array<FacturaEstadoDTO>) => {
        this.estadoArray = data!;
      },
      error: () => { },
    });
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.searchForm.controls[controlName]?.touched ||
        this.searchForm.controls[controlName]?.dirty) &&
      this.searchForm.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }

  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  openModalUploadInvoice(element?: any): void {
    const dialogRef = this.dialog.open(
      ManagersAgreementsInvoiceNewDocumentDialogComponent,
      {
        width: '650px',
        maxWidth: '95%',
        maxHeight: '95vh',
        data: {
          itemOpen: element,
        },
        disableClose: true,
      }
    );
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.searchData();
      }
    });
  }

  descargar(documento: any) {
    if(documento?.id) {
      this.spinnerSrv.loadingShow();
      this.documentService.descargarDocumento(documento.id).subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Se ha descargado el documento correctamente',
          'success'
        );
        this.downloadService.downloadBlob(response, documento.nombre);
      },
      error: () => {
        this.spinnerSrv.loadingHide();
      },
    });
  }
  }

  confirmacionCambioEstado(id?: any, estadoId?: any): void {
    Swal.fire({
      text: `¿Desea cambiar el estado del registro por Aceptada por Envalora?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.actualizarEstado(id, estadoId);
      }
    });
  }

  actualizarEstado(id?: any, estadoId?: any) {
    if (id && estadoId) {
      this.spinnerSrv.loadingShow();
      this.facturaGestorAcuerdoControllerService
        .actualizarEstado(id, estadoId)
        .subscribe({
          next: () => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              `El registro de factura se ha actualizado correctamente`,
              'success'
            );
            this.searchData();
          },
          error: (err: { message: any }) => {
            this.spinnerSrv.loadingHide();
          },
        });
    }
  }

  canViewResource(rol: string): boolean {
    return this.authService.hasCurrentUserRole(rol);
  }

  canAccessExport(): boolean {
    return this.authService.can('gestores-acuerdo-facturas', 'update-exportar');
  }

  canAccessEnvalora(): boolean {
    return this.canViewResource('AG-ENV-GEST') || this.canViewResource('AG-ENV-CONS');
  }

  canAccessAll(list: any) {
    const resultDelete = this.authService.can('gestores-acuerdo-facturas', 'delete');
    const resultUpFactura = this.authService.can(
      'gestores-acuerdo-facturas',
      'update'
    );
    const resultDownloadAutofactura = this.authService.can(
      'gestores-acuerdo-facturas',
      'download-autofactura'
    );
    const resultDownloadFactura = this.authService.can(
      'gestores-acuerdo-facturas',
      'download-factura'
    );
    const resultUpdateAccept = this.authService.can(
      'gestores-acuerdo-facturas',
      'update-accept'
    );
    const resultReject = this.authService.can(
      'gestores-acuerdo-facturas',
      'reject-factura'
    );

    const listPermission = list.map((objeto: any) => {
      const estado1 = objeto.estado && objeto.estado.id === 1;
      const estado2 = objeto.estado && objeto.estado.id === 2;
      const estado3 = objeto.estado && objeto.estado.id === 3;
      const estado4 = objeto.estado && objeto.estado.id === 4;

      let formattedDate =
        objeto.fechaFactura != null
          ? this.datePipe.transform(objeto.fechaFactura, 'dd/MM/yyyy')
          : null;
      objeto.fechaMFactura = formattedDate;

      let formattedDateM =
        objeto.fechaAutofactura != null
          ? this.datePipe.transform(objeto.fechaAutofactura, 'dd/MM/yyyy')
          : null;
      objeto.fechaMAutofactura = formattedDateM;
      return {
        ...objeto,
        canAccessDelete: resultDelete && estado1,
        canAccessUpInvoice: resultUpFactura && (estado1 || estado4),
        canAccessDonwloadInvoice: resultDownloadFactura && (estado2 || estado3) && objeto.documento,
        canAccessDonwloadSelfInvoice: resultDownloadAutofactura && objeto.documentoAutofactura,
        canAccessAcceptInvoice: resultUpdateAccept && estado2,
        canRejectInvoice: resultReject && estado2
      };
    });

    return listPermission;
  }

  onlyNumberKey(event: KeyboardEvent) {
    const allowedKeys = ['Backspace', 'ArrowLeft', 'ArrowRight', 'Tab', 'Delete', 'Control'];

    if (event.ctrlKey && (event.key === 'c' || event.key === 'v')) return;

    // Permitir números y teclas adicionales definidas
    if (!/^[0-9]$/.test(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
  openModalMotivoRechazo(item:any){
      const dialogRef = this.dialog.open(
        ManagersAgreementsInvoiceRejectDialogComponent,
        {
          width: '750px',
          maxWidth: '95%',
          maxHeight: '95vh',
          data: {
            info: item,
          },
          disableClose: true,
        }
      );
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.searchData();
        }
      });
    }
}
