<empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
<div class="modal-content">
    <div class="modal-header">
        <h3 class="modal-title font">{{titulo}}</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrar()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body" style="height: 70vh; overflow-y: auto;">
        <mat-tab-group>
            <mat-tab label="DATOS COMUNICACIÓN">
                <form [formGroup]="datosForm" class="cont-tab">
                    <div class="row-fields">
                        <div class="field field-33">
                            <label for="tipo">Tipo <span class="oblig">*</span></label>
                            <mat-form-field appearance="outline">
                                <mat-select formControlName="tipo" id="tipo" placeholder="Tipo" (selectionChange)="actualizarValidaciones($event.value)">
                                    <mat-option *ngFor="let tipo of tipos" [value]="tipo.id">
                                        {{ tipo.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="controlHasError(datosForm, 'tipo', 'required')">Campo obligatorio
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="field field-33" *ngIf="this.datosForm.get('tipo')?.value ==='INCIDENCIA'">
                            <label for="prioridad">Prioridad <span class="oblig">*</span></label>
                            <mat-form-field appearance="outline">
                                <mat-select formControlName="prioridad" id="prioridad" placeholder="Prioridad">
                                    <mat-option *ngFor="let prioridad of prioridades" [value]="prioridad.id">
                                        {{ prioridad.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="controlHasError(datosForm, 'prioridad', 'required')">Campo
                                    obligatorio
                                </mat-error>
                            </mat-form-field>
                        </div>
                        <div class="field field-33" *ngIf="this.datosForm.get('tipo')?.value ==='INCIDENCIA'">

                        </div>
                    </div>
                    <div class="row-fields" *ngIf="this.datosForm.get('tipo')?.value ==='INCIDENCIA'">
                        
                        <div class="field field-50" *ngIf="this.datosForm.get('tipo')?.value ==='INCIDENCIA'">
                            <label for="empresa">Empresa <span class="oblig">*</span></label>
                            <mat-form-field appearance="outline">
                                <mat-select formControlName="empresa" id="empresa" placeholder="Empresa" (selectionChange)="onChangeEmpresa($event)">
                                    <mat-option *ngFor="let empresa of empresas" [value]="empresa.id">
                                        {{ empresa.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="controlHasError(datosForm, 'empresa', 'required')">Campo
                                    obligatorio
                                </mat-error>
                            </mat-form-field>
                        </div>

                        <div class="field field-grow" >
                            <label for="categoria">Categoría <span class="oblig">*</span></label>
                            <mat-form-field appearance="outline">
                                <mat-select formControlName="categoria" id="categoria" placeholder="Categoría">
                                    <mat-option *ngFor="let categoria of categoriasFiltered" [value]="categoria.id">
                                        {{ categoria.name }}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="controlHasError(datosForm, 'categoria', 'required')">Campo
                                    obligatorio
                                </mat-error>
                            </mat-form-field>
                        </div>


                    </div>

                    <div class="row-fields" *ngIf="this.datosForm.get('tipo')?.value ==='INCIDENCIA'">

                        <div class="field field-25">
                            <label for="fechaIncidencia">Fecha incidencia <span class="oblig">*</span></label>
                            <mat-form-field appearance="outline">
                                <input matInput [matDatepicker]="datepickerFechaIncidencia"
                                    formControlName="fechaIncidencia">
                                <mat-datepicker-toggle matIconSuffix [for]="datepickerFechaIncidencia">
                                </mat-datepicker-toggle>
                                <mat-datepicker #datepickerFechaIncidencia>
                                </mat-datepicker>
                                <mat-error *ngIf="controlHasError(datosForm, 'fechaIncidencia', 'required')">Campo
                                    obligatorio</mat-error>
                                <mat-error *ngIf="controlHasError(datosForm,'fechaIncidencia', 'matDatepickerParse')">
                                    Formato incorrecto</mat-error>

                            </mat-form-field>
                        </div>
                        <div class="field field-25" *ngIf="this.datosForm.get('tipo')?.value ==='INCIDENCIA'">
                            <label for="horaIncidencia">Hora incidencia </label>
                            <mat-form-field appearance="outline">
                                <input matInput [ngxMatTimepicker]="timepicker" [format]="24"
                                    formControlName="horaIncidencia">
                                <span *ngIf="!soloLectura">
                                    <mat-icon matSuffix (click)="timepicker.open()">
                                        schedule
                                    </mat-icon>
                                </span>
                                <ngx-mat-timepicker #timepicker [cancelBtnTmpl]="cancelBtn"
                                    [confirmBtnTmpl]="confirmBtn">
                                </ngx-mat-timepicker>
                            </mat-form-field>
                        </div>
                        <div class="field field-25">
                            <label for="fechaCreacion" *ngIf="!nueva">Fecha creación </label>
                            <mat-form-field appearance="outline" *ngIf="!nueva">
                                <input formControlName="fechaCreacion" id="fechaCreacion" matInput type="text">
                            </mat-form-field>
                        </div>
                        <div class="field field-25">
                            <label for="fechaModificacion" *ngIf="!nueva">Fecha última modificación </label>
                            <mat-form-field appearance="outline" *ngIf="!nueva">
                                <input formControlName="fechaModificacion" id="fechaModificacion" matInput type="text">
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row-fields">

                    </div>
                    <div class="row-fields">
                        <div class="field field-100">
                            <label for="asunto">Asunto <span class="oblig">*</span></label>
                            <mat-form-field appearance="outline">
                                <input formControlName="asunto" id="asunto" matInput type="text" maxlength="255" placeholder="Asunto" >
                                <mat-error *ngIf="controlHasError(datosForm, 'asunto', 'required')">Campo
                                    obligatorio</mat-error>
                              <mat-error *ngIf="controlHasError(datosForm, 'asunto', 'maxlength')">Máximo 255 caracteres</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row-fields">
                        <div class="field field-100">
                            <label for="descripcion">Descripción <span class="oblig">*</span></label>
                            <mat-form-field appearance="outline" class="textarea-field">
                                <textarea class="form-control" matInput formControlName="descripcion" id="descripcion"
                                    placeholder="Descripción" rows="4" ></textarea>
                                <mat-error *ngIf="controlHasError(datosForm, 'descripcion', 'required')">Campo
                                    obligatorio</mat-error>
                              <!--<mat-error *ngIf="controlHasError(datosForm, 'descripcion', 'maxlength')">Máximo 255 caracteres</mat-error>-->
                            </mat-form-field>
                        </div>
                    </div>
                  <div class="row-fields">
                    <div class="field field-100">
                      <label for="contactoPrevio">¿Ha contactado previamente con nuestro soporte sobre este
                        asunto? <span class="oblig">*</span>
                      </label>
                      <mat-radio-group formControlName="contactoPrevio" id="contactoPrevio"
                                       class="custom-mat-form-radio">
                        <mat-radio-button color="primary" [value]="true">Sí</mat-radio-button>
                        <mat-radio-button color="primary" [value]="false">No</mat-radio-button>
                      </mat-radio-group>
                      <mat-error *ngIf="controlHasError(datosForm, 'contactoPrevio', 'required')">Campo
                        obligatorio
                      </mat-error>
                    </div>
                  </div>
                </form>
            </mat-tab>
            <mat-tab label="INFORMACIÓN USUARIO" *ngIf="!nueva">
                <form [formGroup]="infoUsuarioForm" class="cont-tab">
                    <div class="row-fields">
                        <div class="field field-grow">
                            <label for="email">Email </label>
                            <mat-form-field appearance="outline">
                                <input formControlName="email" id="email" matInput type="text">
                            </mat-form-field>
                        </div>
                        <!--div class="field field-33">
                            <label for="telefono">Teléfono</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="telefono" id="telefono" matInput type="text">
                            </mat-form-field>
                        </div-->
                    </div>
                    <div class="row-fields">
                        <div class="field field-100">
                            <label for="perfil">Perfil</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="perfil" id="perfil" matInput type="text">
                            </mat-form-field>
                        </div>
                    </div>

                </form>
            </mat-tab>
            <mat-tab label="CAMBIOS DE ESTADO" *ngIf="!nueva">
                <form [formGroup]="estadosForm" class="cont-tab">
                    <div class="row-fields">
                        <div class="field field-33">
                            <label for="estado">Estado actual</label>
                            <mat-form-field appearance="outline">
                                <input formControlName="estado" id="estado" matInput type="text">
                            </mat-form-field>
                        </div>
                      <div class="field field-33" style="margin-top: 8px">
                        <span *ngIf="!soloLectura && mostrarCambioEstado()" class="cambiar-estado" (click)="toogleEstado();">
                          <mat-icon>edit</mat-icon> Estado de la comunicación
                          <div *ngIf="!this.isAdmComunicaciones()" class="tool-help"
                               matTooltip='Debe cambiar el estado a "Pendiente Envalora" para continuar con la comunicación o a "Finalizada" si desea cerrarla'
                               data-placement="top" title="">
                            <span>?</span>
                          </div>
                        </span>
                      </div>

                      <div class="field field-33"></div>
                    </div>
                    <div class="nuevo-estado" *ngIf="verCambioEstado && !soloLectura">
                        <div class="row-fields">
                            <div class="field field-50">
                                <label for="nuevoEstado">Nuevo estado <span class="oblig">*</span></label>
                                <mat-form-field appearance="outline">
                                    <mat-select formControlName="nuevoEstado" id="nuevoEstado"
                                        placeholder="Nuevo estado">
                                        <mat-option *ngFor="let estado of obtenerEstados()" [value]="estado">
                                            {{ estado.descripcion }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="controlHasError(estadosForm, 'nuevoEstado', 'required')">Campo
                                        obligatorio</mat-error>
                                </mat-form-field>
                            </div>
                            <div class="field field-100">
                                <label for="comentario">Comentarios <span class="oblig">*</span></label>
                                <mat-form-field appearance="outline" class="textarea-field">
                                    <!--input formControlName="comentario" id="comentario" matInput type="text"
                                        placeholder="Comentario"-->

                                        <textarea class="form-control" matInput formControlName="comentario"
                                        id="comentario" placeholder="Comentarios"
                                        rows="3" ></textarea>
                                    <mat-error *ngIf="controlHasError(estadosForm, 'comentario', 'required')">Campo
                                        obligatorio</mat-error>
                                 <!-- <mat-error *ngIf="controlHasError(datosForm, 'comentario', 'maxlength')">Máximo 255 caracteres</mat-error>-->
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="attach">
                            <div>
                                <button mat-button aria-labe="Adjuntar archivo" class="adjuntar-btn" type="button"
                                    (click)="file.click()" [disabled]="adjuntosEstadosBlob!.length>0">
                                    Adjuntar archivo
                                </button>
                                <label class="sr-only" for="adjuntar">Adjuntar archivo </label>
                                <input #file type="file"
                                    (change)="file && file.files && attachAdjuntoEstado(file.files[0])" class="hide"
                                    id="adjuntar" name="adjuntar">
                            </div>
                            <div>
                                <div fxLayout="row" fxLayoutAlign="space-between center"
                                    *ngFor="let archivo of adjuntosEstadosBlob" class="centrar archivo">
                                    <div>
                                        <span class="nombre-archivo">{{archivo?.name}} <mat-icon
                                                (click)="detachAdjuntoEstado(archivo); file.value=''"
                                                class="borrar-archivo-icon">
                                                close</mat-icon></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row-fields">
                            <div class="field field-100">
                                <div class="buttons-cont">
                                    <button type="button" class="btn btn-primary" (click)="cambiarEstado()"
                                        aria-label="Cambiar estado">Cambiar estado</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div style="margin-top: 20px" *ngIf="gridArray?.length!>0">
                    <div class="datatable-container table-responsive overflow-table table-detail-width-100">
                        <lib-ge-table [headArray]="headArray" [gridArray]="gridArray" (actionButton)="action($event)">
                        </lib-ge-table>
                    </div>
                </div>
            </mat-tab>
            <mat-tab label="OTROS DATOS">
                <form [formGroup]="otrosForm" class="cont-tab">
                    <div class="attach" style="padding-bottom: 20px;" *ngIf="nueva">
                        <div>
                            <button mat-button aria-labe="Adjuntar archivo" class="adjuntar-btn" type="button"
                                (click)="fileOtros.click()" [disabled]="adjuntosOtrosBlob!.length>0 || soloLectura">
                                Adjuntar archivo
                            </button>
                            <label class="sr-only" for="adjuntar">Adjuntar archivo </label>
                            <input #fileOtros type="file"
                                (change)="fileOtros && fileOtros.files && attachAdjuntoOtros(fileOtros.files[0])"
                                class="hide" id="adjuntar" name="adjuntar">
                        </div>
                        <div>
                            <div fxLayout="row" fxLayoutAlign="space-between center"
                                *ngFor="let archivo of adjuntosOtrosBlob" class="centrar archivo">
                                <div>
                                    <span class="nombre-archivo">{{archivo?.name}} <mat-icon
                                            (click)="detachAdjuntoOtros(archivo); fileOtros.value=''"
                                            class="borrar-archivo-icon">
                                            close</mat-icon></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="attach" style="padding-bottom: 20px;"
                        *ngIf="!nueva && comunicacion?.documentoIncidencia">
                        <div>
                            <div fxLayout="row" fxLayoutAlign="space-between center" class="centrar archivo">
                                <div>
                                    <span>Archivo: </span>
                                    <span class="nombre-archivo">{{comunicacion?.documentoIncidencia?.nombre}} <mat-icon
                                            (click)="descargar(comunicacion?.documentoIncidencia);"
                                            class="descargar-archivo">
                                            download</mat-icon></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row-fields">
                        <div class="field field-100">
                            <label for="comentariosAdicionales">Comentarios adicionales </label>
                            <mat-form-field appearance="outline" class="textarea-field">
                                <textarea class="form-control" matInput formControlName="comentariosAdicionales"
                                    id="comentariosAdicionales" placeholder="Comentarios adicionales"
                                    rows="3"></textarea>

                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </mat-tab>
        </mat-tab-group>
    </div>
    <div class="modal-footer actions">
        <div class="note"><span *ngIf="nueva">* Campos Obligatorios</span></div>
        <div class="button-group">
            <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
                aria-label="Cancelar">Cancelar</button>
            <button *ngIf="nueva" type="button" class="btn btn-primary" data-dismiss="modal" (click)="guardar()"
                aria-label="Guardar">Guardar y enviar</button>
        </div>
    </div>
</div>

<!--Plantilla botones de timepicker-->
<ng-template #cancelBtn>
    <button class="btn btn-outline-primary" style="margin-right: 10px;" aria-label="CANCELAR"
        type="button">CANCELAR</button>
</ng-template>
<ng-template #confirmBtn>
    <button aria-label="GUARDAR" class="btn btn-primary" type="button">ACEPTAR</button>
</ng-template>
