/**
 * SDRR-Abierto API
 * SDRR-Abierto API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SolicitudFacturaExportacionFilterDTO { 
    solicitud?: number;
    tipoSolicitud?: SolicitudFacturaExportacionFilterDTO.TipoSolicitudEnum;
}
export namespace SolicitudFacturaExportacionFilterDTO {
    export type TipoSolicitudEnum = 'REUTILIZACION' | 'RETIRADA';
    export const TipoSolicitudEnum = {
        Reutilizacion: 'REUTILIZACION' as TipoSolicitudEnum,
        Retirada: 'RETIRADA' as TipoSolicitudEnum
    };
}


