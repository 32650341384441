import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  CodigoOperacionControllerService,
  CodigoOperacionFilterDTO,
  OperacionGestionControllerService,
  PoseedorControllerService,
  PoseedorDTO,
  ResiduoEnvaseControllerService,
  ResiduoEnvasePayloadDTO,
} from 'api-rest';
import { SpinnerService } from '../../../services/spinner.service';
import { SnackbarService } from '../../../services/snackbar.service';
import Swal from 'sweetalert2';
import { ValidateService } from '../../../services/validate.service';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { Router } from '@angular/router';
import { Subscription, catchError, forkJoin, map, of, zip } from 'rxjs';
//import { ResiduoEnvaseControllerService, ResiduoEnvasePayloadDTO } from 'dist/api-rest/api-poseedores';

@Component({
  selector: 'lib-holders-waste-forecast',
  templateUrl: './holders-waste-forecast.component.html',
  styleUrls: ['./holders-waste-forecast.component.scss'],
})
export class HoldersWasteForecastComponent implements OnChanges, OnInit {
  @Input() poseedorInfo!: PoseedorDTO | any;
  @Output() public refrescarPoseedorEvent = new EventEmitter<any>();

  formGroup!: FormGroup;
  tiposCodigosLer: any[] = [];
  operacionesGestion: any[] = [];
  suministraInfoList = [
    { id: 'Gestor', name: 'Gestor' },
    { id: 'Poseedor', name: 'Poseedor' },
  ];
  anios: any[] = [];
  codigosLerEndpoint =
    this.environment.urlBackCore + 'api/v1/core/codigo-ler/filtro-combo?poseedores=true';
  updateList: boolean = false;
  updateListOpera: boolean = false;
  flagDisabledForm: boolean = false;
  anioActual!: number;
  nimaAsignadoEnvalora:string = '9999999999';
  grid: any[] = [];

  pageNumber: number = 0;
  pageSize: number = 100;
  recordsTotal: number = 0;
  gestionArray: any[] = [];

  headArray: any[] = [
    {
      Head: 'Año',
      FieldName: 'anioDatos',
    },
    {
      Head: 'Código LER y descripción de ENVALORA',
      FieldName: 'descripcionMCodLer',
      Tooltip: true,
    },
    {
      Head: 'Operación gestión específica',
      FieldName: 'operacionMGestion',
      Tooltip: true,
    },
    {
      Head: '',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'warning',
          icon: 'warning',
          toolTip:
            'NIMA de gestor no existe en la base de datos de gestores de ENVALORA',
          show: (row: any) => this.showWarning(row),
        },
      ],
    },
    {
      Head: 'NIMA del gestor',
      FieldName: 'nimaGestor',
    },
    {
      Head: 'Previsión cantidad anual generada (Kg)',
      FieldName: 'previsionCantidadGenerada',
    },
    {
      Head: '¿Quién suministra información de gestión?',
      FieldName: 'suministradorInformacion',
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
      ],
      width: '2',
      permanent: true,
    },
  ];
  constructor(
    private formBuilder: FormBuilder,
    @Inject('environment') private environment: any,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    private poseedorControllerSrv: PoseedorControllerService,
    private validateSrv: ValidateService,
    private router: Router,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    private residuoEnvaseControllerSrv: ResiduoEnvaseControllerService,
    private codigoOperacionControllerService: CodigoOperacionControllerService,
    private operacionGestionControllerService: OperacionGestionControllerService
  ) {
    this.formGroup = this.formBuilder.group({
      anio: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      codigoLer: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
      operacionGestion: [{ value: null, disabled: false }],
      previsionAnual: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(this.validateSrv.twoDecimals),
          ]),
        },
      ],
      nima: [
        { value: null, disabled: false },
        {
          validators: Validators.compose([
            Validators.required,
            Validators.pattern(this.validateSrv.tenDigits),
            Validators.maxLength(10)
          ]),
        },
      ],
      suministraInfo: [
        { value: null, disabled: false },
        { validators: Validators.compose([Validators.required]) },
      ],
    });
  }
  ngOnInit(): void {
    this.valueChangeCodigoLer();
    this.anioActual = new Date().getFullYear();
    const limiteInferior = 2025;
    for (let i = this.anioActual - 3; i <= this.anioActual + 1; i++) {
      if (i >= limiteInferior) {
        this.anios.push({ id: i });
      }
    }
    this.formGroup
      .get('anio')
      ?.setValue(this.anioActual === 2024 ? 2025 : this.anioActual);
    this.anioSelected();
  }

  valueChangeCodigoLer() {
    this.formGroup.get('codigoLer')?.valueChanges.subscribe((value: any) => {
      if (value) {
        this.buscarOperacionesGestion();
      } else {
        this.gestionArray = [];
      }
    });
  }

  buscarOperacionesGestion() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.codigoOperacionControllerService
      .findAll12(backendFilter, { page: page, size: size })
      .subscribe({
        next: (res) => {
          this.spinnerSrv.loadingHide();
          if (res) {
            this.gestionArray = res?.datos ?? [];
          }
        },
        error: () => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  private getBackendFilter() {
    const backendFilter: CodigoOperacionFilterDTO = {
      codigoLer: [this.formGroup.get('codigoLer')?.value?.id],
      poseedores: true
    };
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    return { backendFilter, page, size: this.pageSize };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.poseedorInfo) {
      this.anioSelected();
    }
  }
  getLerDescription(ler: any) {
    return ler?.tipoCodigo + ' - ' + ler?.descripcion;
  }

  getOperacionDescription(op: any) {
    return op?.descripcion;
  }
  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'codigoLer': {
        return this.formGroup.get('codigoLer') as FormControl;
        break;
      }
      case 'operacionGestion': {
        return this.formGroup.get('operacionGestion') as FormControl;
        break;
      }
      default: {
        return this.formGroup.get('') as FormControl;
        break;
      }
    }
  }
  getObjectQuery(searchterm: string) {
    return {
      descripcion: searchterm
    };
  }

  public rightHolder(controlName: string) {
    let cl2 = '';
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      cl2 = 'error-focus-input';
    } else {
      cl2 = 'success-focus-input';
    }
    return `${cl2}`;
  }
  selPaginatorHasError(controlName: string) {
    if (
      (this.formGroup.controls[controlName]?.touched ||
        this.formGroup.controls[controlName]?.dirty) &&
      this.formGroup.controls[controlName]?.errors
    ) {
      return true;
    } else {
      return false;
    }
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.formGroup.controls[controlName].hasError(errorName) &&
      this.formGroup.controls[controlName].touched
    );
  }
  addPrevision(): void {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      this.snackBarSrv.showSnackBar(
        'Revisar los errores en los campos ',
        'error'
      );
      return;
    }
    if(!this.validarDatosNimaPrefijado()){
      return;
    }
    const existe = this.grid.find(
      (x) =>
        x.anioDatos === this.formGroup.get('anio')?.value &&
        x.codigoLer?.id === this.formGroup.get('codigoLer')?.value?.id &&
        x.operacionGestion?.id ===
          this.formGroup.get('operacionGestion')?.value?.id &&
        x.nimaGestor === this.formGroup.get('nima')?.value &&
        x.previsionCantidadGenerada ==
          this.formGroup.get('previsionAnual')?.value &&
        x.suministradorInformacion ===
          this.formGroup.get('suministraInfo')?.value
    );
    if (existe) {
      this.snackBarSrv.showSnackBar(
        'Ya existe la previsión de residuos de envases introducida',
        'error'
      );
    } else {
      let previsionAnual;
      if (this.formGroup.get('previsionAnual')?.value) {
        previsionAnual = Number.parseFloat(
          this.formGroup.get('previsionAnual')?.value
        );
      }
      let obj: ResiduoEnvasePayloadDTO = {
        anioDatos: this.formGroup.get('anio')?.value,
        codigoLer: this.formGroup.get('codigoLer')?.value,
        operacionGestion: this.formGroup.get('operacionGestion')?.value,
        nimaGestor: this.formGroup.get('nima')?.value,
        previsionCantidadGenerada: previsionAnual,
        suministradorInformacion: this.formGroup.get('suministraInfo')?.value,
        poseedor: this.poseedorInfo,
      };
      this.guardarPrevision(obj);

      this.formGroup.patchValue({
        codigoLer: null,
        operacionGestion: null,
        nima: null,
        previsionAnual: null,
        suministraInfo: null,
      });
      this.formGroup.markAsPristine();
      this.formGroup.markAsUntouched();
    }
  }
  guardarPrevision(residuo: ResiduoEnvasePayloadDTO | any): void {
    this.spinnerSrv.loadingShow();
    this.residuoEnvaseControllerSrv.create3(residuo).subscribe({
      next: (response: PoseedorDTO) => {
        this.spinnerSrv.loadingHide();
        this.residuoEnvaseControllerSrv
          .comprobarNimaGestor(response.id!)
          .subscribe({
            next: (resultado: any) => {
              residuo.existeNima = resultado;
              if (resultado === false) {
                this.snackBarSrv.showSnackBar(
                  'Este gestor no está registrado en ENVANET. Le recomendamos que le informe de que debe registrarse, en caso de delegarle el suministro de información por la correcta gestión de sus envases.',
                  'error'
                );
              }
              this.refrescarPoseedorEvent.emit(true);
            },
            error: (error: any) => {},
          });
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al guardar la previsión de residuos',
          'error'
        );
      },
    });
  }

  action(event: any) {
    if (event.nameButton == 'delete') {
      this.confirmacionEliminarPrevision(event);
    }
  }
  confirmacionEliminarPrevision(element?: any): void {
    Swal.fire({
      text: `¿Desea eliminar la previsión de residuos?`,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        this.spinnerSrv.loadingShow();
        this.residuoEnvaseControllerSrv.deleteById3(element.id).subscribe({
          next: (response: PoseedorDTO) => {
            this.spinnerSrv.loadingHide();
            this.refrescarPoseedorEvent.emit(true);
            this.snackBarSrv.showSnackBar(
              `Se ha eliminando correctamente.`,
              'success'
            );
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al eliminar la previsión de residuos',
              'error'
            );
          },
        });
      }
    });
  }

  anioSelected(): void {
    if (
      this.poseedorInfo?.residuosEnvase &&
      this.poseedorInfo?.residuosEnvase?.length > 0
    ) {
      let observables: any[] = [];

      let residuosFiltrados = this.poseedorInfo?.residuosEnvase.filter(
        (x: any) => x.anioDatos == this.formGroup.get('anio')?.value
      );
      if (residuosFiltrados?.length > 0) {
        residuosFiltrados.forEach((residuo: any) => {
          let observable = this.residuoEnvaseControllerSrv
            .comprobarNimaGestor(residuo.id)
            .pipe(
              map((resultado: any) => {
                residuo.existeNima = resultado;
                return residuo;
              }),
              catchError((error: any) => {
                console.error(
                  `Error comprobando Nima para residuo con id ${residuo.id}:`,
                  error
                );
                residuo.existeNima = false;
                return of(residuo);
              })
            );
          observables.push(observable);
        });

        zip(...observables).subscribe((residuosActualizados: any[]) => {
          this.grid = this.canAccessAll(residuosActualizados);
        });
      } else {
        this.grid = [];
      }
    }
  }

  showWarning(row: any) {
    return !row.existeNima;
  }

  canAccessDelete(): boolean {
    return this.authService.can('poseedores-prevision', 'delete');
  }
  canAccessCreate(): boolean {
    return this.authService.can('poseedores-prevision', 'create');
  }

  canAccessAll(list: any) {
    const resultDelete = this.canAccessDelete();

    const listPermission = list.map((objeto: any) => {
      let descripcionMCodLer =
        objeto.codigoLer.tipoCodigo + ' ' + objeto.codigoLer.descripcion;
        let operacionMGestion = (objeto.operacionGestion ? objeto.operacionGestion?.codigo +
          ' ' +
          objeto.operacionGestion?.descripcion : '')
      return {
        ...objeto,
        operacionMGestion: operacionMGestion,
        descripcionMCodLer: descripcionMCodLer,
        canAccessDelete: resultDelete,
      };
    });

    return listPermission;
  }

  finalizar() {
    if (this.grid?.length > 0) {
      this.spinnerSrv.loadingShow();
      this.poseedorControllerSrv
        .finalizarDetalle(this.poseedorInfo.id!)
        .subscribe({
          next: (response: PoseedorDTO) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Proceso finalizado correctamente. Los datos serán revisados por Envalora y, en los próximos días, recibirá un correo electrónico con instrucciones para la firma del acuerdo.',
              'success'
            );
            this.router.navigate(['/initial-page']);
          },
          error: (err) => {
            this.spinnerSrv.loadingHide();
            this.snackBarSrv.showSnackBar(
              'Ha ocurrido un error al finalizar.',
              'error'
            );
          },
        });
    } else {
      this.snackBarSrv.showSnackBar(
        'Es necesario informar de, al menos, una previsión de residuos de envases para el año en curso',
        'error'
      );
    }
  }

  validarDatosNimaPrefijado(){
    if(this.poseedorInfo.nima === this.nimaAsignadoEnvalora){
      //No puede registrar previsiones con códigos LER 15 01 10 o 15 01 11.
      if(this.formGroup.get('codigoLer')?.value.tipoCodigo == '15 01 10' || this.formGroup.get('codigoLer')?.value.tipoCodigo == '15 01 10*' 
        || this.formGroup.get('codigoLer')?.value.tipoCodigo == '15 01 11' || this.formGroup.get('codigoLer')?.value.tipoCodigo == '15 01 11*'){
        this.snackBarSrv.showSnackBar(
          `Si el NIMA del poseedor es ${this.nimaAsignadoEnvalora} no puede registrar previsiones con códigos LER  15 01 10 o 15 01 11.`,
          'error'
        );
        return false;
      }
      //No puede superar previsiones anuales de 1.000 t/año. 
      let previsionAnual = 0;
      if (this.formGroup.get('previsionAnual')?.value) {
        previsionAnual = Number.parseFloat( this.formGroup.get('previsionAnual')?.value);
      }
      //Sumar el resto de previsiones existentes para el año seleccionado
      let residuosFiltrados = this.poseedorInfo?.residuosEnvase.filter(
        (x: any) => x.anioDatos == this.formGroup.get('anio')?.value
      );
      if (residuosFiltrados?.length > 0) {
        residuosFiltrados.forEach((residuo: any) => {
          previsionAnual += residuo.previsionCantidadGenerada;
        });
      } 
      
      if(previsionAnual > 1000000){
        this.snackBarSrv.showSnackBar(
          `Si el NIMA del poseedor es ${this.nimaAsignadoEnvalora} no puede superar previsiones anuales de 1.000 t/año.`,
          'error'
        );
        return false;
      }

    }

    return true;
  }

}
