import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor() { }
  
  downloadBlob(file: Blob, fileName: string) {
    var a = document.createElement("a");
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  downloadCSV(file: Blob, fileName: string) {
    var reader = new FileReader();
    reader.onload = function(event) {
      var data = event.target?.result;      
      var link = document.createElement("a");
      link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(data as string));
      link.setAttribute("download", fileName);
      link.click();
    };
    reader.readAsText(file);
  }

  getFileName(headers: HttpHeaders, defaultFileName?: string) {
    const contentDispositionHeader: string | null = headers.get('Content-Disposition');        
    // Extraer el nombre de archivo del encabezado Content-Disposition
    let filename: string | null = null;
    if (contentDispositionHeader) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDispositionHeader);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename ?? defaultFileName;
  }
  b64toBlob(b64Data: string, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    return new Blob(byteArrays, { type: contentType });
  }

  b64toFile(b64Data: string, fileName: string, contentType = '', sliceSize = 512): File {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, { type: contentType });
  
    // Convertimos el Blob en un File con el nombre deseado
    return new File([blob], fileName, { type: contentType });
  }
}
