/**
 * Core API
 * Core API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserRoleDTO } from './userRoleDTO';
import { EntidadAsociadaDTO } from './entidadAsociadaDTO';


export interface UserRoleEntityDTO { 
    entityType: UserRoleEntityDTO.EntityTypeEnum;
    role: UserRoleDTO;
    entity?: EntidadAsociadaDTO;
    enabled?: boolean;
}
export namespace UserRoleEntityDTO {
    export type EntityTypeEnum = 'ADMINISTRACION' | 'ADHERIDO' | 'POSEEDOR' | 'GESTOR_ENVALORA' | 'GESTOR_POSEEDOR' | 'PUNTO_RECOGIDA' | 'GESTOR_PUNTO_RECOGIDA' | 'COMUNICACION' | 'ACUERDOS_GESTORES' | 'USUARIO';
    export const EntityTypeEnum = {
        Administracion: 'ADMINISTRACION' as EntityTypeEnum,
        Adherido: 'ADHERIDO' as EntityTypeEnum,
        Poseedor: 'POSEEDOR' as EntityTypeEnum,
        GestorEnvalora: 'GESTOR_ENVALORA' as EntityTypeEnum,
        GestorPoseedor: 'GESTOR_POSEEDOR' as EntityTypeEnum,
        PuntoRecogida: 'PUNTO_RECOGIDA' as EntityTypeEnum,
        GestorPuntoRecogida: 'GESTOR_PUNTO_RECOGIDA' as EntityTypeEnum,
        Comunicacion: 'COMUNICACION' as EntityTypeEnum,
        AcuerdosGestores: 'ACUERDOS_GESTORES' as EntityTypeEnum,
        Usuario: 'USUARIO' as EntityTypeEnum
    };
}


