<div class="modal-content">
  <empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
  <div class="modal-header">
    <h3 class="modal-title font">{{titulo}}</h3>
    <button type="button" (click)="closeDialog()" class="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">
    <!-- Formulario Centros y Categorias-->
    <div class="d-flex flex-column m-4 ">
      <button type="button" class="btn btn-outline-primary mb-2" data-dismiss="modal" (click)="descargar()"
        aria-label="descargar">
        <em class="fas fa-download mr-2"></em> Descargar plantilla
      </button>
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="adjuntar()"
        aria-label="adjuntar">
        <em class="fas fa-paperclip mr-2"></em> Adjuntar archivo
      </button>
      <input type="file" #fileInput style="display:none;" (change)="handleFileInput($event)" accept=".xlsx" />
      <div class="d-flex justi-end mt-3">
        <span *ngIf="nombreArchivo" class="text-muted padding-text-upd">{{ nombreArchivo }} </span>
        <mat-icon *ngIf="nombreArchivo" class="ml-2 color-custom">check_circle</mat-icon>
      </div>
    </div>

    <!-- FIN Centros y Categorias -->
  </div>
  <div class="modal-footer actions">
    <div class="note">* Adjuntar archivo: Solo permite ficheros .xlsx</div>
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="closeDialog()"
        aria-label="Cancelar">Cancelar</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="Importar()"
        aria-label="Guardar">Importar</button>
    </div>
  </div>
</div>

