import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AportacionDIEstadoControllerService } from './api/aportacionDIEstadoController.service';
import { AportacionDIPoseedorControllerService } from './api/aportacionDIPoseedorController.service';
import { CodigoOperacionControllerService } from './api/codigoOperacionController.service';
import { FacturaPoseedorControllerService } from './api/facturaPoseedorController.service';
import { FacturaPoseedorEstadosControllerService } from './api/facturaPoseedorEstadosController.service';
import { GestoresResiduosControllerService } from './api/gestoresResiduosController.service';
import { GestoresResiduosEstadosControllerService } from './api/gestoresResiduosEstadosController.service';
import { OperacionGestionControllerService } from './api/operacionGestionController.service';
import { PoseedorAdheridoControllerService } from './api/poseedorAdheridoController.service';
import { PoseedorControllerService } from './api/poseedorController.service';
import { PoseedorEstadoControllerService } from './api/poseedorEstadoController.service';
import { ResiduoEnvaseControllerService } from './api/residuoEnvaseController.service';
import { ResiduosGestionadosControllerService } from './api/residuosGestionadosController.service';
import { TarifaPoseedoresControllerService } from './api/tarifaPoseedoresController.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: [
    AportacionDIEstadoControllerService,
    AportacionDIPoseedorControllerService,
    CodigoOperacionControllerService,
    FacturaPoseedorControllerService,
    FacturaPoseedorEstadosControllerService,
    GestoresResiduosControllerService,
    GestoresResiduosEstadosControllerService,
    OperacionGestionControllerService,
    PoseedorAdheridoControllerService,
    PoseedorControllerService,
    PoseedorEstadoControllerService,
    ResiduoEnvaseControllerService,
    ResiduosGestionadosControllerService,
    TarifaPoseedoresControllerService ]
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
