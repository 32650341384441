import { Component, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  ApiUserMgmt,
  KeycloakRoleControllerService,
  UserRoleEntityDTO,
  KeycloakRoleDTO,
  KeycloakUserDTO,
  EntidadAsociadaDTO,
  KeycloakUserFilterDTO,
  PageDTOKeycloakUserDTO,
  UserManagementControllerService,
} from 'api-rest';
import { ComboService, Combo } from '../../../services/combo.service';
import { DownloadService } from '../../../services/download.service';
import {
  UserDetailModalComponent,
  UserDetailModalData,
} from '../../components/user-detail-modal/user-detail-modal.component';
import { MappedResponse } from '../../../share/components/ge-select-pagination/ge-select-pagination.component';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { HttpHeaders } from '@angular/common/http';
import { SnackbarService } from '../../../services/snackbar.service';

@Component({
  selector: 'lib-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
})
export class UserSearchComponent implements OnDestroy {
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;
  roles: KeycloakRoleDTO[] = [];
  adheridosEndpoint =
    this.environment.urlBackAdheridos + 'api/v1/adheridos/adheridos';
  defaultFilter: boolean = true;
  userTypes: Combo[] = [];
  UserTypeEnum = UserRoleEntityDTO.EntityTypeEnum;

  headArray: any = [
    {
      Head: 'Nombre',
      FieldName: 'firstName',
    },
    {
      Head: 'Apellidos',
      FieldName: 'lastName',
    },
    {
      Head: 'Email',
      FieldName: 'email',
    },
    {
      Head: 'Roles por Entidad',
      FieldName: 'activeUserRolesEntitiesDetail',
      Tooltip: true,
    },
    {
      Head: 'Activo',
      FieldName: 'enabled',
      renderValue: (item: KeycloakUserDTO) => (item.enabled ? 'SI' : 'NO'),
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        { nameButton: 'edit', icon: 'open_in_new', toolTip: 'Editar' },
        // { 'nameButton': "delete", "icon": "delete", 'toolTip': 'Borrar' },
        {
          nameButton: 'menu',
          array: [
            {
              nameButton: 'reset-password',
              icon: 'cached',
              toolTip: 'Reestablecer contraseña',
              name: 'Reestablecer contraseña',
            },
            {
              nameButton: 'enable',
              icon: 'how_to_reg',
              toolTip: 'Habilitar usuario',
              name: 'Habilitar usuario',
              show: (item: KeycloakUserDTO) => !item.enabled,
            },
            {
              nameButton: 'disable',
              icon: 'person_off',
              toolTip: 'Deshabilitar usuairo',
              name: 'Deshabilitar usuario',
              show: (item: KeycloakUserDTO) => item.enabled,
            },
          ],
        },
      ],
      width: '8',
      permanent: true,
    },
  ];
  gridArray: KeycloakUserDTO[] = [];

  searchForm: FormGroup;
  subscriptions: Subscription[] = [];
  flagSearch: boolean = false;
  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;

  constructor(
    @Inject('environment') private environment: any,
    private userService: UserManagementControllerService,
    private roleService: KeycloakRoleControllerService,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public comboService: ComboService,
    private downloadService: DownloadService,
    private snackbarService: SnackbarService
  ) {
    this.searchForm = this.formBuilder.group({
      firstName: [{ value: null, disabled: false }],
      lastName: [{ value: null, disabled: false }],
      email: [{ value: null, disabled: false }],
      userRole: [{ value: null, disabled: false }],
      userType: [{ value: null, disabled: false }],
      entidadAsociada: [{ value: null, disabled: false }],
      enabled: [{ value: null, disabled: false }],
    });
  }

  ngOnInit(): void {
    this.userTypes = this.comboService.getUserTypes();
    this.roleService.getRealRoles().subscribe((res) => (this.roles = res));

    const subscription = this.searchForm.controls[
      'userType'
    ].valueChanges.subscribe((val) => this.onUserTypeChange(val));
    this.subscriptions.push(subscription);
    this.onUserTypeChange();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  onUserTypeChange(val?: UserRoleEntityDTO.EntityTypeEnum) {
    this.searchForm.controls['entidadAsociada'].patchValue(null);
    if (val == this.UserTypeEnum.Adherido) {
      this.searchForm.controls['entidadAsociada'].enable();
    } else {
      this.searchForm.controls['entidadAsociada'].disable();
    }
  }

  renderPage(event: number) {
    this.pageNumber = event;
    this.getUsers();
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  checked(item: any) {}

  searchData() {
    this.pageNumber = 1;
    this.getUsers();
  }

  getUsers() {
    const { backendFilter, page, size } = this.getBackendFilter();

    this.userService
      .getUsuarios(backendFilter, {
        page: page,
        size: size,
        sort: ['firstName,asc'],
      })
      .pipe()
      .subscribe({
        next: (data: PageDTOKeycloakUserDTO) => {
          if (data) {
            this.gridArray = data.datos ?? [];
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
          }
          this.flagSearch = this.gridArray.length > 0;
        },
        error: (error) => {
          // TODO: Mostrar error
        },
      });
  }

  private getBackendFilter() {
    const textFilter = (value: any) =>
      value?.trim() ? [`ci(${value?.trim()})`] : undefined;
    const filter = this.searchForm.value;

    // if (filter.entidadAsociada) {
    //   filter.entidadAsociada = {
    //     id: filter.entidadAsociada.id
    //   };
    // }

    if (filter.enabled == false) {
      delete filter.enabled;
    }

    const backendFilter: KeycloakUserFilterDTO = {
      firstName: textFilter(filter.firstName),
      lastName: textFilter(filter.lastName),
      email: textFilter(filter.email),
      enabled: filter.enabled,
    };

    if (filter.userRole) {
      backendFilter.userRolesEntities = {
        role: {
          name: filter.userRole,
        },
        enabled: true,
      };
    }

    if (filter.userType) {
      backendFilter.userRolesEntities = backendFilter.userRolesEntities ?? {
        enabled: true,
      };
      backendFilter.userRolesEntities.entityType = filter.userType;
    }

    if (filter.entidadAsociada?.id) {
      backendFilter.userRolesEntities = backendFilter.userRolesEntities ?? {
        enabled: true,
      };
      backendFilter.userRolesEntities.entity =
        backendFilter.userRolesEntities.entity ?? {};
      backendFilter.userRolesEntities.entity.id = filter.entidadAsociada.id;
    }

    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    const params = {
      filtro: backendFilter,
      page: page,
      size: this.pageSize,
    };
    return { backendFilter, page, size: this.pageSize };
  }

  action(item: any) {
    if (item.nameButton == 'edit') {
      this.openModalUser(item, true);
    } else if (item.nameButton == 'reset-password') {
      this.confirmacion(
        `¿Desea re-establecer la contraseña del usuario ${item.firstName} ${item.lastName}?`,
        () => this.resetPassword(item)
      );
    } else if (item.nameButton == 'enable') {
      this.confirmacion(
        `¿Desea habilitar el usuario ${item.firstName} ${item.lastName}?`,
        () => this.habilitarUsuario(item)
      );
    } else if (item.nameButton == 'disable') {
      this.confirmacion(
        `¿Desea deshabilitar el usuario ${item.firstName} ${item.lastName}?`,
        () => this.deshabilitarUsuario(item)
      );
    } else if (item.nameButton == 'delete') {
      this.confirmacion(
        `¿Desea eliminar el usuario ${item.firstName} ${item.lastName}?`,
        () => this.eliminarUsuario(item)
      );
    }
  }

  openModalUser(element?: any, editMode = false): void {
    const dialogRef = this.dialog.open(UserDetailModalComponent, {
      width: '950px',
      maxWidth: '95%',
      maxHeight: '95vh',
      data: <UserDetailModalData>{
        user: element,
        editMode,
      },
      disableClose: true,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.getUsers();
      }
    });
  }

  confirmacion(titulo: string, callBack: Function): void {
    Swal.fire({
      text: titulo,
      icon: 'question',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
      allowOutsideClick: false,
    }).then((result: any) => {
      if (result.isConfirmed) {
        callBack();
      }
    });
  }

  habilitarUsuario(user: KeycloakUserDTO) {
    this.userService.habilitarPorId(user.keycloakId!).subscribe({
      next: (response) => {
        this.snackbarService.showSnackBar(
          'El usuario se ha habilitado correctamente',
          'success'
        );
        this.getUsers();
      },
      error: (err) => {
        this.snackbarService.showSnackBar(
          'Ha ocurrido un error al habilitar el usuario',
          'error'
        );
      },
    });
  }

  deshabilitarUsuario(user: KeycloakUserDTO) {
    this.userService.deshabilitarPorId(user.keycloakId!).subscribe({
      next: (response) => {
        this.snackbarService.showSnackBar(
          'El usuario se ha deshabilitado correctamente',
          'success'
        );
        this.getUsers();
      },
      error: (err) => {
        this.snackbarService.showSnackBar(
          'Ha ocurrido un error al deshabilitar el usuario',
          'error'
        );
      },
    });
  }

  eliminarUsuario(item: any) {
    this.userService.eliminarUsuarioPorId(item.keycloakId).subscribe({
      next: (response) => {
        this.snackbarService.showSnackBar(
          'El usuario se ha eliminado correctamente',
          'success'
        );
        this.getUsers();
      },
      error: (err) => {
        this.snackbarService.showSnackBar(
          'Ha ocurrido un error al eliminar el usuario',
          'error'
        );
      },
    });
  }

  private resetPassword(item: any) {
    this.userService.restorePassword(item.keycloakId).subscribe({
      next: (response) => {
        this.snackbarService.showSnackBar(
          'La contraseña del usuario se ha re-establecido correctamente',
          'success'
        );
        this.getUsers();
      },
      error: (err) => {
        this.snackbarService.showSnackBar(
          'Ha ocurrido un error al intentar re-establecer la contraseña del usuario',
          'error'
        );
      },
    });
  }

  clearSearcher() {
    this.searchForm.reset();
  }

  exportarExcel() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.userService.exportCSV(backendFilter, 'response').subscribe((res) => {
      const filename = this.downloadService.getFileName(
        res.headers,
        'Exportacion_Adheridos.csv'
      );
      this.downloadService.downloadCSV(res.body!, filename!);
    });
  }

  exportarPdf() {
    // TODO: Implementar export excel
  }

  mapAdheridosReponse(response: any): MappedResponse<any> {
    return {
      datos: response.content,
      recordsFiltered: response.recordsTotal,
      totalElements: response.totalElements,
    };
  }

  getEntidadAsociadoFormControl(): FormControl<any> {
    return this.searchForm.controls['entidadAsociada'] as FormControl<any>;
  }

  separarCamelCase(texto: string) {
    return texto.replace(/([a-z])([A-Z])/g, '$1 $2');
  }

}
