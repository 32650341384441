import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { EmpusaAuthenticationService } from '@empusa/empusa-core';
import { DownloadService } from '../../../services/download.service';
import { MatDialog } from '@angular/material/dialog';
import { SpinnerService } from '../../../services/spinner.service';
import swal from 'sweetalert2';
import { SnackbarService } from '../../../services/snackbar.service';
import {
  DocumControllerService,
  DocumentoModel,
  GestoresResiduosDTO,
} from 'api-rest';
import { WasteManagersDetailDocumentDialogComponent } from '../waste-managers-detail-document-dialog/waste-managers-detail-document-dialog.component';
import moment from 'moment';
import { DocumentService } from '../../../services/document.service';

@Component({
  selector: 'lib-waste-managers-detail-document',
  templateUrl: './waste-managers-detail-document.component.html',
  styleUrls: ['./waste-managers-detail-document.component.scss'],
})
export class WasteManagersDetailDocumentComponent implements OnInit {
  @Input() gestorInfo!: GestoresResiduosDTO;
  @Output() public refrescarGestorEvent = new EventEmitter<any>();

  gridArray: any = [];
  headArray: any = [
    {
      Head: 'Tipo',
      FieldName: 'tipoDocumento',
      Attribute: 'descripcion',
    },
    {
      Head: 'Descripción',
      FieldName: 'descripcion',
      Tooltip: true,
      Maxtext: 30,
    },
    {
      Head: 'Fecha Creación',
      FieldName: 'fechaCreacion',
      Tooltip: true,
    },
    {
      Head: 'Fecha Ult. Modificación',
      FieldName: 'fechaModificacion',
    },
    {
      Head: 'Modificado por',
      FieldName: 'usuarioModificacion',
      Tooltip: true,
    },
    {
      Head: 'Acciones',
      FieldName: 'actions',
      buttons: [
        {
          nameButton: 'donwload',
          icon: 'download',
          toolTip: 'Descargar',
          show: { params: 'canAccessDonwload', accion: 'donwload' },
        },
        {
          nameButton: 'upload',
          icon: 'upload',
          toolTip: 'Adjuntar',
          show: { params: 'canAccessUpload', accion: 'upload' },
        },
        {
          nameButton: 'delete',
          icon: 'delete',
          toolTip: 'Borrar',
          show: { params: 'canAccessDelete', accion: 'delete' },
        },
      ],
      width: '8',
      permanent: true,
    },
  ];

  documentos!: Array<DocumentoModel>;

  containerPoseedores: string = this.environment.documentContainerHolders;

  constructor(
    @Inject('environment') private environment: any,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService,
    private downloadSrv: DownloadService,
    public spinnerSrv: SpinnerService,
    private snackBarSrv: SnackbarService,
    public dialog: MatDialog,
    private documControllerService: DocumControllerService,
    private documentService: DocumentService
  ) { }

  ngOnInit(): void {
    this.cargarDocumentos();
  }

  cargarDocumentos() {
    this.spinnerSrv.loadingShow();
    this.documControllerService
      .listDocs('GESTOR_POSEEDOR', this.gestorInfo?.id!)
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.documentos = response;
          this.gridArray = this.canAccessAll(response).filter((doc: DocumentoModel) =>
            [1, 2, 3, 4, 5, 13].includes(doc.tipoDocumento?.id!)
          );
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          console.log(err);
        },
      });
  }

  abrirNuevoDocumento() {
    const dialogRef = this.dialog.open(
      WasteManagersDetailDocumentDialogComponent,
      {
        width: '950px',
        maxWidth: '95%',
        maxHeight: '95vh',
        data: {
          gestorInfo: this.gestorInfo,
        },
        disableClose: true,
      }
    );

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.cargarDocumentos();
      }
    });
  }

  action(item: any) {
    if (item.nameButton == 'donwload') {
      this.descargar(item);
    } else if (item.nameButton == 'upload') {
      this.openFileSelector(item);
    } else if (item.nameButton == 'delete') {
      this.confirmacionEliminar(item);
    }
  }

  openFileSelector(item: any) {
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.style.display = 'none';
    fileInput.addEventListener('change', (event) =>
      this.handleFileSelection(event, item)
    );
    document.body.appendChild(fileInput);
    fileInput.click();
    document.body.removeChild(fileInput);
  }

  handleFileSelection(event: any, item: any) {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      this.subir(selectedFile, item);
    }
  }

  subir(archivo: any, element: any): void {
    let id = this.gestorInfo.id! ?? null;
    this.spinnerSrv.loadingShow();
    this.documControllerService
      .updateDoc(
        element.id,
        archivo?.name,
        archivo,
        this.containerPoseedores,
        element.tipoDocumento.id,
        id,
        'GESTOR_POSEEDOR'
      )
      .subscribe({
        next: (response) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Se ha subido correctamente el documento',
            'success'
          );
          this.cargarDocumentos();
        },
        error: (err) => {
          this.spinnerSrv.loadingHide();
          this.snackBarSrv.showSnackBar(
            'Ha ocurrido un error al subir el documento',
            'error'
          );
          console.log(err);
        },
      });
  }

  confirmacionEliminar(element?: any): void {
    swal
      .fire({
        text: '¿Desea eliminar Documento?',
        icon: 'question',
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: 'Sí',
        denyButtonText: 'No',
        allowOutsideClick: false,
      })
      .then((result: any) => {
        if (result.isConfirmed) {
          this.eliminarDocumento(element);
        }
      });
  }

  eliminarDocumento(element: any) {
    this.spinnerSrv.loadingShow();
    this.documControllerService.deleteDoc(element.id).subscribe({
      next: (response) => {
        this.snackBarSrv.showSnackBar(
          'Se ha eliminado correctamente el documento',
          'success'
        );
        this.spinnerSrv.loadingHide();
        this.cargarDocumentos();
      },
      error: (err) => {
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al eliminar el documento',
          'error'
        );
        this.spinnerSrv.loadingHide();
        console.log(err);
      },
    });
  }

  canAccessAll(list: any) {
    const descargarView = this.authService.can(
      'poseedores-gestor-documentos',
      'update'
    );

    const deleteView = this.authService.can(
      'poseedores-gestor-documentos',
      'delete'
    );

    const downloadView = this.authService.can(
      'poseedores-gestor-documentos',
      'download'
    );
    const listPermission = list.map((objeto: any) => {
      let fechaFormato = moment(new Date(objeto.fechaCreacion)).format(
        'DD/MM/YYYY'
      );
      let fechaModificacion = moment(new Date(objeto.fechaModificacion)).format(
        'DD/MM/YYYY'
      );
      objeto.fechaCreacion = fechaFormato;
      objeto.fechaModificacion = fechaModificacion;
      if (objeto.descripcion === 'null') {
        objeto.descripcion = '';
      }
      let hayFichero = objeto?.contenedor ? true : false;
      return {
        ...objeto,
        canAccessUpload: descargarView && !hayFichero,
        canAccessDonwload: downloadView,
        canAccessDelete: deleteView,
      };
    });

    return listPermission;
  }

  descargar(element: any) {
    this.spinnerSrv.loadingShow();
    this.documentService.descargarDocumento(element.id).subscribe({
      next: (response) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Se ha descargado el documento correctamente',
          'success'
        );
        this.downloadSrv.downloadBlob(response, element.nombre);
      },
      error: (err) => {
        this.spinnerSrv.loadingHide();
        this.snackBarSrv.showSnackBar(
          'Ha ocurrido un error al descargar el documento',
          'error'
        );
        console.log(err);
      },
    });
  }
}
