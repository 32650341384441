<div class="modal-content">
  <empusa-core-spinner *ngIf="spinnerSrv.loading" [overlay]="true"></empusa-core-spinner>
  <div class="modal-header">
    <h3 class="modal-title font" *ngIf="newItem; else editTarifaTitle">Nuevo documento de interés</h3>
    <ng-template #editTarifaTitle>
      <h3 class="modal-title font">Edición de documento de interés</h3>
    </ng-template>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cerrar()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" style="max-height: 70vh; overflow-y: auto;">

    <form class="form-search py-3" [formGroup]="formGroup">
      <div class="row-fields">
        <div class="field field-100">
          <label for="name">Nombre <span class="oblig">*</span></label>
          <mat-form-field appearance="outline">
            <input formControlName="name" id="name" placeholder="Nombre" matInput type="text" maxlength="255">
            <mat-error *ngIf="controlHasError('name', 'maxlength')">Máximo 255 caracteres</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row-fields"> <!--row form-->
        <div class="field field-100">
          <label for="rol">Roles <span class="oblig">*</span></label>
          <div class="custom-mat-field">
            <mat-form-field class="custom-mat-form-field" appearance="outline">
              <mat-select formControlName="rol" id="rol" placeholder="-- Roles --" multiple>
                <mat-option *ngFor="let rol of roles" [value]="rol">
                  {{ rol.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="controlHasError('rol', 'required')">Campo obligatorio</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      

      <div class="col-sm flex-grow-1">
        <label for="documento">Documento <span class="oblig">*</span></label>
        <div class="custom-mat-field">
          <div class="attach">
              <input #file type="file" (change)="file && file.files && attachAdjunto(file.files[0])" class="hide"
                id="adjuntar" name="adjuntar">
              <mat-icon (click)="file.click()" class="ml-2 color-custom pointer-cursor"
                matTooltip="Adjuntar documento">upload_file</mat-icon>
            
            <div>
              <div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="documento != undefined"
                class="centrar archivo">
                <div><span class="sprite-icon ico-adjuntar"></span><span class="nombre-archivo">{{documento.name}}
                  </span>
                </div>
              </div>
              <div fxLayout="row" fxLayoutAlign="space-between center"
                *ngIf="(this.documentoInteres?.documento != undefined && documento === undefined)" class="centrar archivo">
                <div><span class="sprite-icon ico-adjuntar"></span><span
                    class="nombre-archivo">{{this.documentoInteres?.filename}}
                  </span>
                  <mat-icon (click)="descargar()" class="ml-2 color-custom pointer-cursor"
                    matTooltip="Descargar">
                    download</mat-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </form>

  </div>
  <div class="modal-footer actions">
    <div class="note"><span *ngIf="editMode">* Campos Obligatorios</span></div>
    <div class="button-group">
      <button type="button" class="btn btn-outline-primary" data-dismiss="modal" (click)="cerrar()"
        aria-label="Cancelar">Cancelar</button>
      <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="guardar()"
        aria-label="Guardar">Guardar</button>
    </div>
  </div>
</div>
