import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup,} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {
  GestionResiduosFilterDTO,
  GestoresResiduosControllerService,
  GestoresResiduosDTO,
  PageDTOGestoresResiduosDTO,
  ProvinciaControllerService,
} from 'api-rest';
import {EmpusaAuthenticationService} from '@empusa/empusa-core';
import {SpinnerService} from "../../services/spinner.service";
import {DownloadService} from "../../services/download.service";
import {SnackbarService} from "../../services/snackbar.service";

@Component({
  selector: 'app-managers-registered-search',
  templateUrl: './managers-registered-search.component.html',
  styleUrls: ['./managers-registered-search.component.scss']
})
export class ManagersRegisteredSearchComponent implements OnInit {
  pageNumber: number = 0;
  pageSize: number = 10;
  recordsTotal: number = 0;

  updateList: boolean = false;
  cnaeEndpoint = this.environment.urlBackCore + 'api/v1/core/cnae';

  itemsPerPageOptions: number[] = [10, 15, 20];
  selectedItemsPerPage: number = 10;
  flagSearch: boolean = false;
  totalElements: number = 0;
  totalSearch: number = 0;

  provinciaArray: any[] = [];
  estadoArray: any[] = [];

  headArray: any = [
    {
      Head: 'NIMA',
      FieldName: 'nima',
    },
    {
      Head: 'Razón Social',
      FieldName: 'razonSocial',
    },
    {
      Head: 'Denominación del centro',
      FieldName: 'centro',
    },
    {
      Head: 'Provincia (dirección física)',
      FieldName: 'provinciaFisica',
      Attribute: 'nombre',
    },
    {
      Head: 'Dirección física',
      renderValue: (item: any) => item?.domicilios?.[0]?.fullAddress,
    },
    {
      Head: 'Teléfono',
      renderValue: (item: any) => item?.contactos?.[0]?.numero_telefono,
    },
    {
      Head: 'Email',
      renderValue: (item: any) => item?.contactos?.[0]?.email,
    },
    {
      Head: 'Autorización de peligroso',
      FieldName: 'autorizacionPeligrosos',
    },
  ];
  gridArray: Array<GestoresResiduosDTO> = [];

  estadosArray: any = [];

  searchForm: FormGroup;

  constructor(
    @Inject('environment') private environment: any,
    private formBuilder: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    public spinnerSrv: SpinnerService,
    private downloadService: DownloadService,
    private snackBarSrv: SnackbarService,
    private gestoresResiduosCtrSrv: GestoresResiduosControllerService,
    private provinciaControllerService: ProvinciaControllerService,
    @Inject('EmpusaAuthenticationService')
    private authService: EmpusaAuthenticationService
  ) {
    this.searchForm = this.formBuilder.group({
      provincia_fisica: [{ value: null, disabled: false }],
      razon_social: [{ value: null, disabled: false }],
      nima: [{ value: null, disabled: false }],
      auto_peligrosidad: [{ value: null, disabled: false }],
      gestores: [{ value: null, disabled: false }],
      acondicionadores: [{ value: null, disabled: false }],

    });
  }

  ngOnInit() {
    this.getComboProvincia();
  }

  renderPage(event: number) {
    this.pageNumber = event;
    if (this.flagSearch) {
      this.searchData();
    }
  }

  exportarExcel() {
    const { backendFilter } = this.getBackendFilter();
    this.gestoresResiduosCtrSrv
      .registradosExportCSV(backendFilter, 'response')
      .subscribe((res) => {
        const filename = this.downloadService.getFileName(
          res.headers,
          'Exportacion_gestores_registrados.csv'
        );
        this.downloadService.downloadCSV(res.body!, filename!);
      });
  }

  clearSearcher() {
    this.searchForm.reset();
    this.flagSearch = false;
    this.gridArray = [];
    this.pageNumber = 0;
  }

  getFormControl(selectForm: any): FormControl {
    switch (selectForm) {
      case 'cnae': {
        return this.searchForm.get('cnae') as FormControl;
        break;
      }
      default: {
        return this.searchForm.get('') as FormControl;
        break;
      }
    }
  }

  controlHasError(controlName: string, errorName: string) {
    return (
      this.searchForm.controls[controlName].hasError(errorName) &&
      this.searchForm.controls[controlName].touched
    );
  }

  private getBackendFilter() {
    let backendFilter: any = {
      nima: this.searchForm.get('nima')?.value,
      razonSocial: this.searchForm.get('razon_social')?.value,
      provincia: this.searchForm.get('provincia_fisica')?.value,
    };
    if(this.searchForm.get('gestores')?.value){
      backendFilter.gestorResiduos = this.searchForm.get('gestores')?.value
    }
    if(this.searchForm.get('acondicionadores')?.value){
      backendFilter.acondicionadorEnvase = this.searchForm.get('acondicionadores')?.value
    }
    if(this.searchForm.get('auto_peligrosidad')?.value){
      backendFilter.autorizacionPeligrosos = this.searchForm.get('auto_peligrosidad')?.value
    }
    const page = this.pageNumber <= 1 ? 0 : this.pageNumber - 1;

    return { backendFilter, page, size: this.pageSize };
  }

  searchButton(){
    this.pageNumber = 0;
    this.searchData();
  }

  searchData() {
    const { backendFilter, page, size } = this.getBackendFilter();
    this.spinnerSrv.loadingShow();
    this.gestoresResiduosCtrSrv
      .findAll8(backendFilter, {
        page: page,
        size: size,
        sort: ['id,desc'],
      })
      .subscribe({
        next: (data: PageDTOGestoresResiduosDTO) => {
          if (data) {
            this.spinnerSrv.loadingHide();
            this.flagSearch = data.datos?.length != 0;
            this.gridArray = this.canAccessAll(data.datos);
            this.recordsTotal = data.paginacion?.totalElements ?? 0;
            this.totalSearch = this.gridArray.length;
          }
        },
        error: (error: any) => {
          this.spinnerSrv.loadingHide();
        },
      });
  }

  onItemsPerPageChange() {
    this.pageSize = this.selectedItemsPerPage;
    this.searchData();
  }

  canAccessAll(list: any) {
    return list.map((objeto: any) => {
      return {
        ...objeto,
        canAccessView: this.authService.can('gestores-registrados', 'view')
      };
    });
  }

  showClearButton(formName: string): boolean {
    return this.searchForm.get(formName)?.value !== null;
  }

  clearSelection(event: Event, formName: string) {
    event.stopPropagation();
    this.searchForm.get(formName)?.setValue(null);
  }

  getComboProvincia() {
    this.provinciaControllerService
      .listarComunidades1({}, { page: 0, size: 500, sort: [] })
      .subscribe({
        next: (data: any) => {
          this.provinciaArray = data.datos!;
        },
        error: () => {},
      });
  }
}
