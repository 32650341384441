/**
 * Adheridos API
 * Adheridos API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { BusquedaAdheridoDTO } from './busquedaAdheridoDTO';
import { IdEntityFilterDTO } from './idEntityFilterDTO';


export interface AportacionDIAdheridoFilterDTO { 
    fechaAlta?: Array<string>;
    creadoPor?: Array<string>;
    fechaModificacion?: Array<string>;
    modificadoPor?: Array<string>;
    adherido?: BusquedaAdheridoDTO;
    nimaGestor?: Array<string>;
    fechaRecogida?: Array<string>;
    fechaAceptacion?: Array<string>;
    documentoIdentificacion?: Array<string>;
    estado?: IdEntityFilterDTO;
    origen?: AportacionDIAdheridoFilterDTO.OrigenEnum;
}
export namespace AportacionDIAdheridoFilterDTO {
    export type OrigenEnum = 'ADHERIDO' | 'POSEEDOR' | 'SDDRCA' | 'GESTORESACUERDO';
    export const OrigenEnum = {
        Adherido: 'ADHERIDO' as OrigenEnum,
        Poseedor: 'POSEEDOR' as OrigenEnum,
        Sddrca: 'SDDRCA' as OrigenEnum,
        Gestoresacuerdo: 'GESTORESACUERDO' as OrigenEnum
    };
}


