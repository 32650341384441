/**
 * Comunicaciones API
 * Comunicaciones API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserRoleDTO } from './userRoleDTO';
import { EntidadAsociadaDTO } from './entidadAsociadaDTO';


export interface UserRoleEntityDTO { 
    entityType: UserRoleEntityDTO.EntityTypeEnum;
    role: UserRoleDTO;
    entity?: EntidadAsociadaDTO;
    enabled?: boolean;
}
export namespace UserRoleEntityDTO {
    export type EntityTypeEnum = 'ADMINISTRACION' | 'ADHERIDO' | 'POSEEDOR' | 'GESTOR' | 'GESTOR_POSEEDOR' | 'DISTRIBUIDOR' | 'PUNTO_RECOGIDA' | 'GESTOR_PUNTO_RECOGIDA' | 'REACONDICIONADOR' | 'APORTACION_DI_POSEEDOR' | 'APORTACION_DI_ADHERIDO' | 'APORTACION_DI_GESTOR' | 'SOLICITUDES_PR' | 'COMUNICACION' | 'ACUERDOS_GESTORES';
    export const EntityTypeEnum = {
        Administracion: 'ADMINISTRACION' as EntityTypeEnum,
        Adherido: 'ADHERIDO' as EntityTypeEnum,
        Poseedor: 'POSEEDOR' as EntityTypeEnum,
        Gestor: 'GESTOR' as EntityTypeEnum,
        GestorPoseedor: 'GESTOR_POSEEDOR' as EntityTypeEnum,
        Distribuidor: 'DISTRIBUIDOR' as EntityTypeEnum,
        PuntoRecogida: 'PUNTO_RECOGIDA' as EntityTypeEnum,
        GestorPuntoRecogida: 'GESTOR_PUNTO_RECOGIDA' as EntityTypeEnum,
        Reacondicionador: 'REACONDICIONADOR' as EntityTypeEnum,
        AportacionDiPoseedor: 'APORTACION_DI_POSEEDOR' as EntityTypeEnum,
        AportacionDiAdherido: 'APORTACION_DI_ADHERIDO' as EntityTypeEnum,
        AportacionDiGestor: 'APORTACION_DI_GESTOR' as EntityTypeEnum,
        SolicitudesPr: 'SOLICITUDES_PR' as EntityTypeEnum,
        Comunicacion: 'COMUNICACION' as EntityTypeEnum,
        AcuerdosGestores: 'ACUERDOS_GESTORES' as EntityTypeEnum
    };
}


