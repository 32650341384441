/**
 * Poseedores API
 * Poseedores API.
 *
 * The version of the OpenAPI document: 1.0-SNAPSHOT
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TipoDocumentoDTO } from './tipoDocumentoDTO';


export interface DocumentoDTO { 
    id?: number;
    nombre?: string;
    descripcion?: string;
    contenedor?: string;
    url?: string;
    idEntidad?: number;
    tipoEntidad?: DocumentoDTO.TipoEntidadEnum;
    tipoDocumento?: TipoDocumentoDTO;
    fechaCreacion?: string;
    fechaModificacion?: string;
    usuarioModificacion?: string;
}
export namespace DocumentoDTO {
    export type TipoEntidadEnum = 'ADMINISTRACION' | 'ADHERIDO' | 'POSEEDOR' | 'GESTOR_ENVALORA' | 'GESTOR_POSEEDOR' | 'PUNTO_RECOGIDA' | 'GESTOR_PUNTO_RECOGIDA' | 'COMUNICACION' | 'ACUERDOS_GESTORES' | 'USUARIO';
    export const TipoEntidadEnum = {
        Administracion: 'ADMINISTRACION' as TipoEntidadEnum,
        Adherido: 'ADHERIDO' as TipoEntidadEnum,
        Poseedor: 'POSEEDOR' as TipoEntidadEnum,
        GestorEnvalora: 'GESTOR_ENVALORA' as TipoEntidadEnum,
        GestorPoseedor: 'GESTOR_POSEEDOR' as TipoEntidadEnum,
        PuntoRecogida: 'PUNTO_RECOGIDA' as TipoEntidadEnum,
        GestorPuntoRecogida: 'GESTOR_PUNTO_RECOGIDA' as TipoEntidadEnum,
        Comunicacion: 'COMUNICACION' as TipoEntidadEnum,
        AcuerdosGestores: 'ACUERDOS_GESTORES' as TipoEntidadEnum,
        Usuario: 'USUARIO' as TipoEntidadEnum
    };
}


